import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import $ from "jquery";
import { Select2 } from "select2";
import Footer from "../Footer";
import NavBar from "../MainDashboard/NavBar";
import SideUl from "../MainDashboard/SideUl";
import MainSidebar from "../MainDashboard/MainSidebar";
import { useMemo } from "react";
import {
  AddInvoiceApi,
  CheckItemApi,
  GetAllDiscountApi,
  GetAllTaxApi,
  GetInvoices,
  GetProductDeviceApi,
  GetRecieptApi,
  GetSubUserProfileApi,
  GettAllPaymentApi,
  baseurl,
  customerAddApi,
  customerGetApi,
  getProfileApi,
  uploadImageApi,
} from "../Utility/Api";
import "react-phone-number-input/style.css";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { Link, useNavigate } from "react-router-dom";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import Swal from "sweetalert2";
import ProductModal from "../MainDashboard/ProductModal";
import DeviceModal from "../MainDashboard/DeviceModal";
import moment from "moment";
import Ticket from "../MainDashboard/TicketModal";
import { flatMap } from "lodash";
import FreeTrail from "../MainDashboard/FreeTrail";
import Draggable from "react-draggable";

const CashRegister = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [images, setImages] = useState("");
  const [email, setEmail] = useState("");

  const [address, setAddress] = useState("");
  const [country, setCountry] = useState("");
  const [city, SetCity] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [state, setState] = useState("");
  const [error, setError] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [showModal1, setShowModal1] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [data, setData] = useState([]);
  const [message, setMessage] = useState("");
  const [validstate, setValidState] = useState(false);
  const [selectedState, setSelectedState] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchTermProduct, setSearchTermProduct] = useState("");
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [matched, setMatched] = useState([]);
  const [imageMsg, setImageMsg] = useState("");
  const [showModal3, setShowModal3] = useState(false);
  const [showModal4, setShowModal4] = useState(false);
  const [showModal5, setShowModal5] = useState(false);
  const [showModal6, setShowModal6] = useState(false);
  const [showModal8, setShowModal8] = useState(false);
  const [showModal9, setShowModal9] = useState(false);
  const [showModal11, setShowModal11] = useState(false);
  const [allProducts, setAllProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState([]);
  const [RetrieveSaleProduct, setRetrieveSaleProduct] = useState([]);
  const [isRetrieveListActive, setRetrieveListActive] = useState(false);
  const [InVoiceData, setInVoiceData] = useState([]);
  const [editedProductIndex, setEditedProductIndex] = useState(null);
  const [note, setNote] = useState(selectedProduct.map(() => 0));
  const [customerId, setCustomerId] = useState("");
  const [descriptionNote, setDescriptionNote] = useState("");
  const [changeDue, setChangeDue] = useState("");
  const [parkNote, setParkNote] = useState("");
  const [showModal7, setShowModal7] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectRetrieveProduct, setSelectedRetrieveProduct] = useState([]);
  const [retrieveState, setRetrieveState] = useState(false);
  const [inputValue, setInputValue] = useState(0);
  const [productState, setProductState] = useState(false);
  const [paymentState, setPaymnetState] = useState(false);
  const [customerState, setCustomerState] = useState(false);
  const [retrieveProductState, setRetrieveProductState] = useState(false);
  const [customerid, setCustomerid] = useState("");
  const [invoiceId, setInvoiceID] = useState("");
  const [invoiceId1, setInvoiceID1] = useState("");
  const [countrycode, setCountryCode] = useState({});
  const [adminState, setAdminState] = useState("");
  const [allPaymentOptions, setAllPaymentOptions] = useState([]);
  const [selectedCategory2, setSelectedCategory2] = useState([]);
  const [discounts, setDiscounts] = useState(
    Array(selectedProduct.length).fill(10)
  );
  const [unitCosts, setUnitCosts] = useState(selectedProduct.map(() => 0));
  const [receivedQuantities, setReceivedQuantities] = useState(
    selectedProduct.map(() => 0)
  );
  const [purchaseQuantities, setPurchaseQuantities] = useState(
    selectedProduct.map(() => 0)
  );
  const [selectedPaymentType, setSelectedPaymentType] = useState("");
  const [selectedPaymentTypeId, setSelectedPaymentTypeId] = useState("");
  const [paymentMode, setPaymentMode] = useState([]);
  const [layoutList, setLayoutList] = useState([]);
  const [layoutValue, setLayoutValue] = useState();
  const [alldiscount, setAllDiscount] = useState([]);
  const [alltax, setAllTax] = useState([]);
  const [productByCategory, setProductByCategory] = useState([]);
  const [companyData, setCompanyData] = useState([]);
  const [printType, setPrintType] = useState();
  const [reciept, setReciept] = useState(false);
  const [title, setTitle] = useState("");
  const [logo, setLogo] = useState("");
  const [isLogo, setIsLogo] = useState(false);
  const [logoSize, setLogoSize] = useState(0);
  const [logoPosition, setLogoPosition] = useState(0);
  const [header, setHeader] = useState("");
  const [displySales, setDisplaySales] = useState(false);
  const [footer, setFooter] = useState("");
  const [note1, setNote1] = useState(false);
  const [displayBarcode, setDisplayBarcode] = useState(false);
  const [displayNote, setDisplayNote] = useState(false);
  const [states, setStates] = useState(false);
  const [selectedOption, setSelectedOption] = useState({
    prodname: 0,
    prodname_sku: 0,
    sku: 0,
  });
  const [customerDetails1, setCustomerDetails1] = useState({
    fullname: 0,
    email: 0,
    phone: 0,
    address: 0,
  });
  const [recieptId, setRecieptId] = useState("");
  const [InvoiceData1, setInVoiceData1] = useState({});
  const [data1, setData1] = useState([]);
  const [dataUser, setDataUser] = useState([]);
  const [permission, setPermission] = useState([]);
  const [payment, setPayment] = useState([]);
  const [Items, setItems] = useState([]);
  const [ItemData, setItemData] = useState([]);
  const [defaultTax, setDefaultTax] = useState(null);
  const [totalCost, setTotalCost] = useState("");
  // const [leftMargin, setLeftMargin] = useState(0);
  // const [rightMargin, setRightMargin] = useState(0);
  const [leftMargin, setLeftMargin] = useState("0px");
  const [rightMargin, setRightMargin] = useState("0px");
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [filteredData, setFilteredData] = useState([]);
  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [totalSalesQty, setTotalSalesQty] = useState(0);
  const [gstShow, setGstShow] = useState();
  const [originalUnitCosts, setOriginalUnitCosts] = useState([]);
  const [originalReceivedQuantities, setOriginalReceivedQuantities] = useState(
    []
  );
  const [originalNotes, setOriginalNotes] = useState([]);
  const [originalDiscounts, setOriginalDiscounts] = useState([]);

  const handleScroll = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop ===
      document.documentElement.offsetHeight
    ) {
      if (hasMore && !loading1) {
        setPage((prevPage) => prevPage + 1);
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [hasMore, loading1]);

  useEffect(() => {
    setData([]);
    setFilteredData([]);
    setPage(1);
    GetCustomer(1, searchTerm);
  }, [searchTerm]);

  useEffect(() => {
    if (page > 1) {
      GetCustomer(page, searchTerm);
    }
  }, [page]);
  const [ImageOnOff, setImageOnOff] = useState();

  const handleListProduct = () => {
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${baseurl}getAllCashRegisterLayout?type=1`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log("layoutssss", result);
        //setLayoutList(result?.data?.layout);
        if (result?.data?.layout?.length > 0) {
          setLayoutList(result?.data?.layout);
          setLayoutValue(result?.data?.layoutButton);
          setCompanyData(result?.company);
          setImageOnOff(result?.data?.imageButton);
          if (
            (result?.company?.subscriptionStatus === null ||
              result?.company?.subscriptionStatus === "cancelled") &&
            new Date() > new Date(result?.comapany?.planDate)
          ) {
            navigate("/settings", { state: { activeState: 1 } });
            localStorage.setItem(
              "substatus",
              result?.company?.subscriptionStatus
            );
          }
        }
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    handleListProduct();
  }, []);

  const resetFormValues = () => {
    setUnitCosts([...originalUnitCosts]); // Restore original unit costs
    setReceivedQuantities([...originalReceivedQuantities]); // Restore original received quantities
    setNote([...originalNotes]); // Restore original notes
    setDiscounts([...originalDiscounts]); // Restore original discounts
  };

  // const handleEdit = (index) => {
  //   // console.log("handel edit", index);
  //   setEditedProductIndex(index);
  //   setUnitCosts((prevUnitCosts) => [
  //     ...prevUnitCosts,
  //     index?.sellingPrice || 0,
  //   ]);
  //   setReceivedQuantities((prevReceivedQuantities) => [
  //     ...prevReceivedQuantities,
  //     index?.receivedQuantities || 0,
  //   ]);
  //   setPurchaseQuantities((prevPurchaseQuantities) => {
  //     const newPurchaseQuantities = [...prevPurchaseQuantities];
  //     newPurchaseQuantities[index] =
  //       selectedProduct[index]?.purchaseQuantity || 0;
  //     return newPurchaseQuantities;
  //   });

  //   setNote((prevNote) => {
  //     const newNote = [...prevNote];
  //     newNote[index] = selectedProduct[index]?.note || 0;
  //     return newNote;
  //   });
  //   setShowModal(true);
  // };

  // const handleEdit = (index) => {
  //   setOriginalUnitCosts([...unitCosts]);
  //   setOriginalReceivedQuantities([...receivedQuantities]);
  //   setOriginalNotes([...note]);
  //   setOriginalDiscounts([...discounts]);

  //   setEditedProductIndex(index);
  //   setUnitCosts((prevUnitCosts) => {
  //     const updatedCosts = [...prevUnitCosts];
  //     updatedCosts[index] = selectedProduct[index]?.unitCost || 0;
  //     return updatedCosts;
  //   });
  //   setReceivedQuantities((prevReceivedQuantities) => {
  //     const updatedQuantities = [...prevReceivedQuantities];
  //     updatedQuantities[index] = selectedProduct[index]?.receivedQuantity || 0;
  //     return updatedQuantities;
  //   });
  //   setNote((prevNote) => {
  //     const newNote = [...prevNote];
  //     newNote[index] = selectedProduct[index]?.note || "";
  //     return newNote;
  //   });
  //   setShowModal(true);
  // };

  // const handleEdit = (index) => {
  //   setOriginalUnitCosts([...unitCosts]);
  //   setOriginalReceivedQuantities([...receivedQuantities]);
  //   setOriginalNotes([...note]);
  //   setOriginalDiscounts([...discounts]);
  //   setEditedProductIndex(index);

  //   // Update unit cost for the selected product
  //   setUnitCosts((prevUnitCosts) => {
  //     const updatedCosts = [...prevUnitCosts];
  //     updatedCosts[index] = selectedProduct[index]?.sellingPrice || 0;
  //     return updatedCosts;
  //   });

  //   // Update received quantities for the selected product
  //   setReceivedQuantities((prevReceivedQuantities) => {
  //     const updatedQuantities = [...prevReceivedQuantities];
  //     updatedQuantities[index] =
  //       selectedProduct[index]?.receivedQuantities || 1; // Set default to 1 if undefined
  //     return updatedQuantities;
  //   });

  //   // Update purchase quantities for the selected product
  //   setPurchaseQuantities((prevPurchaseQuantities) => {
  //     const updatedPurchaseQuantities = [...prevPurchaseQuantities];
  //     updatedPurchaseQuantities[index] =
  //       selectedProduct[index]?.purchaseQuantity || 0;
  //     return updatedPurchaseQuantities;
  //   });

  //   // Update note for the selected product
  //   setNote((prevNote) => {
  //     const updatedNote = [...prevNote];
  //     updatedNote[index] = selectedProduct[index]?.note || "";
  //     return updatedNote;
  //   });

  //   // Open the modal to allow editing
  //   setShowModal(true);
  // };
  const handleEdit = (index) => {
    setOriginalUnitCosts([...unitCosts]);
    setOriginalReceivedQuantities([...receivedQuantities]);
    setOriginalNotes([...note]);
    setOriginalDiscounts([...discounts]);
    // Store the index of the product being edited
    setEditedProductIndex(index);

    // Update unit cost for the selected product
    setUnitCosts((prevUnitCosts) => {
      const updatedCosts = [...prevUnitCosts];
      updatedCosts[index] =
        prevUnitCosts[index] || selectedProduct[index]?.sellingPrice || 0; // Use value from state first
      return updatedCosts;
    });

    // Update received quantities for the selected product
    setReceivedQuantities((prevReceivedQuantities) => {
      const updatedQuantities = [...prevReceivedQuantities];
      updatedQuantities[index] =
        prevReceivedQuantities[index] ||
        selectedProduct[index]?.receivedQuantities ||
        1; // Use value from state first
      return updatedQuantities;
    });

    // Update purchase quantities for the selected product
    setPurchaseQuantities((prevPurchaseQuantities) => {
      const updatedPurchaseQuantities = [...prevPurchaseQuantities];
      updatedPurchaseQuantities[index] =
        prevPurchaseQuantities[index] ||
        selectedProduct[index]?.purchaseQuantity ||
        0;
      return updatedPurchaseQuantities;
    });

    // Update note for the selected product
    setNote((prevNote) => {
      const updatedNote = [...prevNote];
      updatedNote[index] =
        prevNote[index] || selectedProduct[index]?.note || "";
      return updatedNote;
    });

    // Open the modal to allow editing
    setShowModal(true);
  };

  // const handleEdit = (index) => {
  //   // Save original values
  //   setOriginalUnitCosts([...unitCosts]);
  //   setOriginalReceivedQuantities([...receivedQuantities]);
  //   setOriginalNotes([...note]);
  //   setOriginalDiscounts([...discounts]);

  //   // Set values for editing
  //   setEditedProductIndex(index);
  //   setUnitCosts((prevUnitCosts) => [
  //     ...prevUnitCosts,
  //     selectedProduct[index]?.unitCost || 0,
  //   ]);
  //   setReceivedQuantities((prevReceivedQuantities) => [
  //     ...prevReceivedQuantities,
  //     selectedProduct[index]?.receivedQuantities || 0,
  //   ]);
  //   setNote((prevNote) => {
  //     const newNote = [...prevNote];
  //     newNote[index] = selectedProduct[index]?.note || "";
  //     return newNote;
  //   });
  //   setShowModal(true);
  // };

  // const CheckItem = (itemId, quantity) => {
  //   let local = localStorage.getItem("token");
  //   const myHeaders = new Headers();
  //   myHeaders.append("Content-Type", "application/json");
  //   myHeaders.append("Authorization", `Bearer ${local}`);

  //   const requestBody = JSON.stringify({
  //     itemId: itemId,
  //     quantity: quantity,
  //   });

  //   const requestOptions = {
  //     method: "POST",
  //     headers: myHeaders,
  //     body: requestBody,
  //     redirect: "follow",
  //   };
  //   console.log("checkitem", requestBody);
  //   return fetch(CheckItemApi, requestOptions)
  //     .then((response) => response.json())
  //     .then((result) => {
  //       console.log("CheckItem API response:", result);
  //       return result; // You can handle the response further if needed
  //     })
  //     .catch((error) => {
  //       console.error("CheckItem API error:", error);
  //       throw error; // Propagate the error to the caller
  //     });
  // };

  // const handleEdit = async (index) => {
  //   try {
  //     // Get selected product details
  //     const product = selectedProduct[index];
  //     const itemId = product.id;
  //     const receivedQuantity = product.receivedQuantities || 0;

  //     // Perform quantity check with CheckItem API
  //     const quantityToCheck = product.inventory;
  //     const checkItemResult = await CheckItem(itemId, quantityToCheck);

  //     // Handle the result from the API (e.g., show error message if quantity is invalid)
  //     if (checkItemResult.valid === false) {
  //       alert("Invalid quantity entered. Please adjust the quantity.");
  //       return; // Stop further execution if quantity is invalid
  //     }

  //     // Update state and show modal for editing
  //     setEditedProductIndex(index);

  //     // Update other state values based on the selected product
  //     setUnitCosts((prevUnitCosts) => [
  //       ...prevUnitCosts,
  //       product.sellingPrice || 0,
  //     ]);
  //     setReceivedQuantities((prevReceivedQuantities) => [
  //       ...prevReceivedQuantities,
  //       receivedQuantity,
  //     ]);
  //     setPurchaseQuantities((prevPurchaseQuantities) => {
  //       const newPurchaseQuantities = [...prevPurchaseQuantities];
  //       newPurchaseQuantities[index] = product.purchaseQuantity || 0;
  //       return newPurchaseQuantities;
  //     });
  //     setNote((prevNote) => {
  //       const newNote = [...prevNote];
  //       newNote[index] = product.note || "";
  //       return newNote;
  //     });

  //     setShowModal(true); // Show the edit modal
  //   } catch (error) {
  //     console.error("Error handling edit:", error);
  //     // Handle error, e.g., display an error message to the user
  //     alert("An error occurred while processing the edit. Please try again.");
  //   }
  // };

  const handleRetrieveClick = () => {
    setRetrieveListActive(true);
  };

  const handlePopupClose = () => {
    setRetrieveListActive(false);
  };
  const handlePopupCloseCustomer = () => {
    setShowModal1(false);
  };

  const handleUpdate = (selectedArray) => {
    if (editedProductIndex !== null) {
      selectedArray((prevProducts) => {
        const updatedProducts = [...prevProducts];
        updatedProducts[editedProductIndex] = {
          ...updatedProducts[editedProductIndex],
          unitCost: unitCosts[editedProductIndex],
          receivedQuantity: receivedQuantities[editedProductIndex],
          purchaseQuantity: purchaseQuantities[editedProductIndex],
          note: note[editedProductIndex],
        };

        return updatedProducts;
      });
      setEditedProductIndex(null);
      setShowModal(false);
    }
  };

  // const handleUpdate = (selectedArray) => {
  //   if (editedProductIndex !== null) {
  //     selectedArray((prevProducts) => {
  //       const updatedProducts = [...prevProducts];
  //       updatedProducts[editedProductIndex] = {
  //         ...updatedProducts[editedProductIndex],
  //         unitCost: unitCosts[editedProductIndex],
  //         receivedQuantity: receivedQuantities[editedProductIndex],
  //         purchaseQuantity: purchaseQuantities[editedProductIndex],
  //         note: note[editedProductIndex],
  //       };

  //       return updatedProducts;
  //     });
  //     setEditedProductIndex(null);
  //     setShowModal(false);
  //   }
  // };

  // const calculateDiscountedTotal = (unitCost, receivedQty, discount) => {
  //   const total = unitCost * receivedQty;
  //   const discountedTotal = total * (1 - discount / 100);

  //   return discountedTotal >= 0 ? `${discountedTotal.toFixed(2)}` : "$0";
  // };
  const calculateDiscountedTotal = (unitCost, receivedQty, discount) => {
    const total = unitCost * receivedQty;
    if (!discount) {
      return total.toFixed(2);
    }
    const discountedTotal = total * (1 - discount / 100);

    return discountedTotal >= 0 ? `${discountedTotal.toFixed(2)}` : "$0";
  };

  const calculateOverallTotal = () => {
    let overallTotal = 0;

    selectedProduct.forEach((_, index) => {
      const discountedTotal = calculateDiscountedTotal(
        unitCosts[index],
        receivedQuantities[index],
        discounts[index]
      );

      overallTotal += parseFloat(discountedTotal);
    });
    selectRetrieveProduct.forEach((product, index) => {
      product.itemIds.forEach((item, itemIndex) => {
        const arrayIndex = selectedProduct.length + index + itemIndex;
        const discountedTotal = calculateDiscountedTotal(
          unitCosts[arrayIndex],
          receivedQuantities[arrayIndex],
          discounts[arrayIndex]
        );
        overallTotal += parseFloat(discountedTotal);
      });
    });

    return overallTotal >= 0 ? overallTotal.toFixed(2) : "0.00";
  };

  const overallTotal = calculateOverallTotal();
  // console.log(overallTotal);

  const handleIncrement = (index) => {
    setReceivedQuantities((prevQuantities) => {
      const newQuantities = [...prevQuantities];
      newQuantities[index] = parseInt(newQuantities[index] || 0) + 1;
      return newQuantities;
    });
    setEditedProductIndex(index);
  };

  const handleDecrement = (index) => {
    setReceivedQuantities((prevQuantities) => {
      const newQuantities = [...prevQuantities];
      newQuantities[index] = Math.max((newQuantities[index] || 0) - 1, 0);

      // If quantity reaches 0, remove the product and related states
      if (newQuantities[index] === 0) {
        setSelectedProduct((prevProducts) => {
          const updatedProducts = [...prevProducts];
          updatedProducts.splice(index, 1);
          return updatedProducts;
        });

        setUnitCosts((prevUnitCosts) => {
          const updatedCosts = [...prevUnitCosts];
          updatedCosts.splice(index, 1);
          return updatedCosts;
        });

        newQuantities.splice(index, 1); // Remove the quantity at the index
      }

      return newQuantities;
    });

    // Fetch all products again if needed
    GetAllProducts();
  };

  // const handleDecrement = (index) => {
  //   setReceivedQuantities((prevQuantities) => {
  //     const newQuantities = [...prevQuantities];
  //     newQuantities[index] = Math.max((newQuantities[index] || 0) - 1, 0);
  //     return newQuantities;
  //   });

  //   // Remove product if quantity reaches 0
  //   if (receivedQuantities[index] === 1) {
  //     setSelectedProduct((prevProducts) => {
  //       const updatedProducts = [...prevProducts];
  //       updatedProducts.splice(index, 1);
  //       return updatedProducts;
  //     });
  //     setReceivedQuantities((prevQuantities) => {
  //       const updatedQuantities = [...prevQuantities];
  //       updatedQuantities.splice(index, 1);
  //       return updatedQuantities;
  //     });
  //   }
  //   setUnitCosts((prevUnitCosts) =>
  //     prevUnitCosts.filter((_, i) => i !== index)
  //   );
  //   GetAllProducts();
  // };

  const handleSearchTermChange = (event) => {
    const term = event.target.value;
    setSearchTerm(term);
    const lowerCaseSearchTerm = term.toLowerCase();
    setShowModal1(true);
    setCustomerState(true);

    const matchedCustomer = data.find(
      (customer) =>
        customer?.firstName.toLowerCase().includes(lowerCaseSearchTerm) ||
        customer?.email.toLowerCase().includes(lowerCaseSearchTerm) ||
        customer?.lastName.toLowerCase().includes(lowerCaseSearchTerm)
    );
    setMatched(matchedCustomer);
    setSelectedCustomer(matchedCustomer);
  };

  const handleSearchTermChangeProduct = (e) => {
    setSearchTermProduct(e.target.value);
    setShowModal2(true);
    setProductState(true);
  };

  const handleCustomerSelection = (customer) => {
    setSearchTerm(`${customer.firstName}`);
    setSelectedCustomer(customer);
    setEmail(customer?.email);
    setShowModal1(false);
  };
  const [totalAveraSale, setTotalAveraSale] = useState("");

  // const handleCustomerSelectionProduct = (customer, selectedIMEI) => {
  //   if (customer?.allowOverSelling === 0 && customer.inventory <= 0) {
  //     Swal.fire("This product is not available");
  //   } else {
  //     setSelectedProduct((prevSelectedProducts) => {
  //       const imeiAlreadyExists = prevSelectedProducts.some((product) =>
  //         (product.imeiNumber || []).includes(selectedIMEI)
  //       );

  //       const productIndex = prevSelectedProducts.findIndex(
  //         (product) => product?.id === customer?.id
  //       );

  //       if (productIndex !== -1) {
  //         const updatedProducts = [...prevSelectedProducts];
  //         const existingProduct = updatedProducts[productIndex];

  //         updatedProducts[productIndex] = {
  //           ...existingProduct,
  //           quantity: (existingProduct.quantity || 1) + 1,
  //           totalPrice:
  //             (existingProduct.totalPrice || 0) +
  //             (existingProduct.sellingPrice || 0),
  //           imeiNumber: [
  //             ...new Set([...(existingProduct.imeiNumber || []), selectedIMEI]),
  //           ],
  //         };

  //         return updatedProducts;
  //       } else {
  //         return [
  //           ...prevSelectedProducts,
  //           {
  //             ...customer,
  //             quantity: 1,
  //             totalPrice: customer?.sellingPrice || 0,
  //             imeiNumber: [selectedIMEI],
  //           },
  //         ];
  //       }
  //     });

  //     setUnitCosts((prevUnitCosts) => {
  //       const productIndex = prevUnitCosts.findIndex(
  //         (cost, index) => selectedProduct[index]?.id === customer?.id
  //       );

  //       if (productIndex !== -1) {
  //         return prevUnitCosts;
  //       } else {
  //         return [...prevUnitCosts, customer?.sellingPrice || 0];
  //       }
  //     });

  //     setReceivedQuantities((prevReceivedQuantities) => {
  //       const productIndex = prevReceivedQuantities.findIndex(
  //         (quantity, index) => selectedProduct[index]?.id === customer?.id
  //       );

  //       if (productIndex !== -1) {
  //         const updatedQuantities = [...prevReceivedQuantities];
  //         updatedQuantities[productIndex] += 1;
  //         return updatedQuantities;
  //       } else {
  //         return [...prevReceivedQuantities, 1];
  //       }
  //     });

  //     setAllProducts((prevData) =>
  //       prevData.filter(
  //         (item) =>
  //           !(item?.imeiNumber && item?.imeiNumber.includes(selectedIMEI))
  //       )
  //     );

  //     setSearchTermProduct("");
  //     setShowModal2(false);
  //     setProductState(false);
  //   }
  // };

  // const handleCustomerSelectionProduct = (customer, selectedIMEI) => {
  //   // console.log("searchproduct", customer);
  //   // console.log("selectedIMEI", selectedIMEI); // Log the selected IMEI number

  //   if (customer?.allowOverSelling === 0 && customer.inventory <= 0) {
  //     Swal.fire("This product is not available");
  //   } else {
  //     setSelectedProduct((prevSelectedProducts) => {
  //       // Check if the selected IMEI already exists in the selected products
  //       const imeiAlreadyExists = prevSelectedProducts.some((product) =>
  //         (product.imeiNumber || []).includes(selectedIMEI)
  //       );

  //       const productIndex = prevSelectedProducts.findIndex(
  //         (product) => product?.id === customer?.id
  //       );

  //       if (productIndex !== -1) {
  //         // Product already selected, update its quantity, total price, and IMEI numbers
  //         const updatedProducts = [...prevSelectedProducts];
  //         const existingProduct = updatedProducts[productIndex];

  //         updatedProducts[productIndex] = {
  //           ...existingProduct,
  //           quantity: (existingProduct.quantity || 1) + 1,
  //           totalPrice:
  //             (existingProduct.totalPrice ||
  //               existingProduct?.sellingPrice ||
  //               0) + (existingProduct?.sellingPrice || 0),
  //           imeiNumber: [
  //             ...new Set([...(existingProduct.imeiNumber || []), selectedIMEI]),
  //           ], // Combine IMEI numbers and ensure uniqueness
  //         };

  //         return updatedProducts;
  //       } else {
  //         // New product selection
  //         return [
  //           ...prevSelectedProducts,
  //           {
  //             ...customer,
  //             quantity: 1,
  //             totalPrice: customer?.sellingPrice || 0,
  //             imeiNumber: [selectedIMEI], // Add IMEI number for the new product
  //           },
  //         ];
  //       }
  //     });

  //     setUnitCosts((prevUnitCosts) => {
  //       const productIndex = prevUnitCosts.findIndex(
  //         (cost, index) => selectedProduct[index]?.id === customer?.id
  //       );

  //       if (productIndex !== -1) {
  //         // Product already selected, unit cost remains the same
  //         return prevUnitCosts;
  //       } else {
  //         // New product selection
  //         return [...prevUnitCosts, customer?.sellingPrice || 0];
  //       }
  //     });

  //     setReceivedQuantities((prevReceivedQuantities) => {
  //       const productIndex = prevReceivedQuantities.findIndex(
  //         (quantity, index) => selectedProduct[index]?.id === customer?.id
  //       );

  //       if (productIndex !== -1) {
  //         // Product already selected, update its received quantity
  //         const updatedQuantities = [...prevReceivedQuantities];
  //         updatedQuantities[productIndex] += 1;
  //         return updatedQuantities;
  //       } else {
  //         // New product selection
  //         return [...prevReceivedQuantities, 1];
  //       }
  //     });

  //     // Remove the selected product with the specific IMEI number from all products list
  //     setAllProducts((prevData) =>
  //       prevData.filter(
  //         (item) => !item?.imeiNumber || item?.imeiNumber !== selectedIMEI
  //       )
  //     );

  //     setSearchTermProduct("");
  //     setShowModal2(false);
  //     setProductState(false);
  //   }
  // };

  const handleCustomerSelectionProduct = (customer, selectedIMEI) => {
    if (customer?.allowOverSelling === 0 && customer.inventory <= 0) {
      Swal.fire("This product is not available");
    } else {
      setSelectedProduct((prevSelectedProducts) => {
        const imeiAlreadyExists = prevSelectedProducts.some((product) =>
          (product.imeiNumber || []).includes(selectedIMEI)
        );

        const productIndex = prevSelectedProducts.findIndex(
          (product) => product?.id === customer?.id
        );

        if (productIndex !== -1) {
          const updatedProducts = [...prevSelectedProducts];
          const existingProduct = updatedProducts[productIndex];

          updatedProducts[productIndex] = {
            ...existingProduct,
            quantity: (existingProduct.quantity || 1) + 1,
            totalPrice:
              (existingProduct.totalPrice ||
                existingProduct?.sellingPrice ||
                0) + (existingProduct?.sellingPrice || 0),
            imeiNumber: [
              ...new Set([...(existingProduct.imeiNumber || []), selectedIMEI]),
            ],
          };

          return updatedProducts;
        } else {
          return [
            ...prevSelectedProducts,
            {
              ...customer,
              quantity: 1,
              totalPrice: customer?.sellingPrice || 0,
              imeiNumber: [selectedIMEI],
            },
          ];
        }
      });

      setUnitCosts((prevUnitCosts) => {
        const productIndex = prevUnitCosts.findIndex(
          (cost, index) => selectedProduct[index]?.id === customer?.id
        );

        if (productIndex !== -1) {
          return prevUnitCosts;
        } else {
          return [...prevUnitCosts, customer?.sellingPrice || 0];
        }
      });

      setReceivedQuantities((prevReceivedQuantities) => {
        const productIndex = prevReceivedQuantities.findIndex(
          (quantity, index) => selectedProduct[index]?.id === customer?.id
        );

        if (productIndex !== -1) {
          const updatedQuantities = [...prevReceivedQuantities];
          updatedQuantities[productIndex] += 1;
          return updatedQuantities;
        } else {
          return [...prevReceivedQuantities, 1];
        }
      });

      setAllProducts((prevData) =>
        prevData.filter(
          (item) => !item?.imeiNumber || item?.imeiNumber !== selectedIMEI
        )
      );

      setSearchTermProduct("");
      setShowModal2(false);
      setProductState(false);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      if (filteredData1?.length > 0) {
        handleCustomerSelectionProduct(filteredData1[0]);
      }
    }
  };
  // const handleCustomerSelectionProductPark = (customer) => {
  //   console.log("parkk", customer);
  //   if (customer?.allowOverSelling === 0 && customer?.inventory <= 0) {
  //     Swal.fire("This product is not available");
  //   } else {
  //     const isProductSelected = selectRetrieveProduct.some(
  //       (product) => product?.id === customer?.id
  //     );

  //     if (!isProductSelected) {
  //       setCustomerid(customer?.customer?.id);
  //       setInvoiceID(customer?.id);

  //       setSelectedRetrieveProduct((prevSelectedProducts) => [
  //         ...prevSelectedProducts,
  //         customer,
  //       ]);
  //       setRetrieveState(true);
  //       // setSelectedCustomer((prevSelectedCustomers) => [
  //       //   ...prevSelectedCustomers,
  //       //   customer?.customer,
  //       // ]);

  //       setReceivedQuantities((prevReceivedQuantities) => [
  //         ...prevReceivedQuantities,
  //         customer?.items.map((item) => item?.quantity || 0),
  //       ]);
  //       setUnitCosts((prevUnitCosts) => [
  //         ...prevUnitCosts,
  //         customer?.items.map((item) => item?.unitCost || 0),
  //       ]);
  //       setDiscounts((prevDiscounts) => [
  //         ...prevDiscounts,
  //         customer?.items.map((item) => item?.discount || 0),
  //       ]);
  //       // console.log("retrieve", customer);
  //     }
  //   }
  //   setRetrieveListActive(false);
  //   //setShowModal2(false);
  // };

  // const handleCustomerSelectionProductPark = (customer, selectedImei) => {
  //   console.log("parkk", customer);
  //   console.log("selectedimei", selectedImei);
  //   if (customer?.allowOverSelling === 0 && customer?.inventory <= 0) {
  //     Swal.fire("This product is not available");
  //   } else {
  //     const isProductSelected = selectRetrieveProduct.some(
  //       (product) => product?.id === customer?.id
  //     );

  //     if (!isProductSelected) {
  //       setCustomerid(customer?.user?.id);
  //       setInvoiceID(customer?.id);

  //       setSelectedRetrieveProduct((prevSelectedProducts) => [
  //         ...prevSelectedProducts,
  //         customer,
  //       ]);
  //       setRetrieveState(true);

  //       const newQuantities = [];
  //       const newSellingPrices = [];
  //       const newDiscounts = [];
  //       const newTotalCosts = [];

  //       customer.items.forEach((item) => {
  //         const selectedItem = customer.itemIds.find(
  //           (itemId) => itemId.id === item.itemId
  //         );

  //         if (selectedItem) {
  //           newQuantities.push(item.quantity || 0);
  //           newSellingPrices.push(selectedItem.sellingPrice || 0);
  //           newDiscounts.push(item.discount || 0);
  //           newTotalCosts.push(item.total || 0);
  //         }
  //       });

  //       setReceivedQuantities((prevReceivedQuantities) => [
  //         ...prevReceivedQuantities,
  //         ...newQuantities,
  //       ]);
  //       setUnitCosts((prevSellingPrices) => [
  //         ...prevSellingPrices,
  //         ...newSellingPrices,
  //       ]);
  //       setDiscounts((prevDiscounts) => [...prevDiscounts, ...newDiscounts]);
  //       setTotalCost(
  //         (prevTotalCost) => prevTotalCost + (customer?.totalCost || 0)
  //       );

  //       setTotalAveraSale(
  //         (prevTotalAveraSale) =>
  //           prevTotalAveraSale + (customer?.totalSalesCost || 0)
  //       );
  //     }
  //   }
  //   setRetrieveListActive(false);
  // };

  const handleCustomerSelectionProductPark = (customer, selectedImei) => {
    // console.log("parkk", customer);
    // console.log("selectedimei", selectedImei);
    if (customer?.allowOverSelling === 0 && customer?.inventory <= 0) {
      Swal.fire("This product is not available");
    } else {
      const isProductSelected = selectRetrieveProduct.some(
        (product) => product?.id === customer?.id
      );

      if (!isProductSelected) {
        setCustomerid(customer?.customer?.id);
        setInvoiceID(customer?.id);

        setSelectedRetrieveProduct((prevSelectedProducts) => [
          ...prevSelectedProducts,
          {
            ...customer,
            selectedImeiNumbers: selectedImei, // Add the selected IMEI numbers here
          },
        ]);
        setRetrieveState(true);

        const newQuantities = [];
        const newSellingPrices = [];
        const newDiscounts = [];
        const newTotalCosts = [];

        customer.items.forEach((item) => {
          const selectedItem = customer.itemIds.find(
            (itemId) => itemId.id === item.itemId
          );

          if (selectedItem) {
            newQuantities.push(item.quantity || 0);
            newSellingPrices.push(item.unitCost || 0);
            newDiscounts.push(item.discount || 0);
            newTotalCosts.push(item.total || 0);
          }
        });

        setReceivedQuantities((prevReceivedQuantities) => [
          ...prevReceivedQuantities,
          ...newQuantities,
        ]);
        setUnitCosts((prevSellingPrices) => [
          ...prevSellingPrices,
          ...newSellingPrices,
        ]);
        setDiscounts((prevDiscounts) => [...prevDiscounts, ...newDiscounts]);
        setTotalCost(
          (prevTotalCost) => prevTotalCost + (customer?.totalCost || 0)
        );

        setTotalAveraSale(
          (prevTotalAveraSale) =>
            prevTotalAveraSale + (customer?.totalSalesCost || 0)
        );
      }
    }
    setRetrieveListActive(false);
  };

  // const filteredData = data?.filter((item) => {
  //   const lowerCaseSearchTerm = searchTerm.toLowerCase();

  //   return (
  //     item?.firstName.toLowerCase().includes(lowerCaseSearchTerm) ||
  //     item?.email.toLowerCase().includes(lowerCaseSearchTerm) ||
  //     item?.phoneNumber.toLowerCase().includes(lowerCaseSearchTerm)
  //   );
  // });

  // const filteredData1 = allProducts?.filter((item) => {
  //   const lowerCaseSearchTerm = searchTermProduct.toLowerCase();

  //   return (
  //     item?.title.toLowerCase().includes(lowerCaseSearchTerm) ||
  //     item?.brand?.name.toLowerCase().includes(lowerCaseSearchTerm) ||
  //     item?.skuCode.toString().includes(lowerCaseSearchTerm.toLowerCase())
  //   );
  // });

  // const filteredData1 = allProducts?.filter((item) => {
  //   const lowerCaseSearchTerm = searchTermProduct.toLowerCase();

  //   return (
  //     item?.title?.toLowerCase().includes(lowerCaseSearchTerm) ||
  //     item?.brand?.name?.toLowerCase().includes(lowerCaseSearchTerm) ||
  //     (item?.skuCode &&
  //       item?.skuCode.toString().toLowerCase().includes(lowerCaseSearchTerm))
  //   );
  // });

  // const filteredData1 = allProducts?.filter((item) => {
  //   const lowerCaseSearchTerm = searchTermProduct.toLowerCase();
  //   if (!lowerCaseSearchTerm) {
  //     return true;
  //   }

  //   // Check for title match
  //   const titleMatch = item?.title?.toLowerCase() === lowerCaseSearchTerm;

  //   // Check for brand name match
  //   const brandMatch = item?.brand?.name?.toLowerCase() === lowerCaseSearchTerm;

  //   // Check for SKU code match
  //   const skuCodeMatch =
  //     item?.skuCode &&
  //     item?.skuCode.toString().toLowerCase() === lowerCaseSearchTerm;

  //   // Return true if any match is found
  //   return titleMatch || brandMatch || skuCodeMatch;
  // });
  const filteredData1 = useMemo(() => {
    const lowerCaseSearchTerm = searchTermProduct.toLowerCase();

    if (!lowerCaseSearchTerm) {
      return allProducts; // Return all products when the search term is empty
    }

    return allProducts.filter((item) => {
      const lowerCaseItemTitle = item?.title?.toLowerCase() || "";
      const lowerCaseItemBrandName = item?.brand?.name?.toLowerCase() || "";
      const lowerCaseItemSkuCode =
        item?.skuCode?.toString().toLowerCase() || "";

      return (
        lowerCaseItemTitle.includes(lowerCaseSearchTerm) ||
        lowerCaseItemBrandName.includes(lowerCaseSearchTerm) ||
        lowerCaseItemSkuCode.includes(lowerCaseSearchTerm)
      );
    });
  }, [allProducts, searchTermProduct]);
  // console.log("filterdData", filteredData1);

  const Validation = () => {
    let error = {};
    let reg = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    // let reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+*!=])(?=.*\d).{6,}$/;
    // const isValid = /^[0-9]+$/.test(value);
    const regex = /^[A-Za-z ]+$/;

    const isNumeric = /^[0-9]+$/;

    // if (!email) {
    //   error.email = "Email is required.";
    // } else if (!reg.test(email)) {
    //   error.email = "Invalid email .";
    // }
    if (email && !reg.test(email)) {
      error.email = "Invalid email.";
    } else if (email && isDisposableEmail(email)) {
      error.email =
        "Please use a valid email address. Temporary email addresses are not allowed.";
    }
    if (!firstName) {
      error.firstName = "First Name Is Required";
    } else if (!regex.test(firstName)) {
      error.firstName = "First Name Is Invalid";
    }

    if (Object.keys(error).length === 0) {
      // console.log(Object.keys(error).length);
      CustomerApi();
    }
    return error;
  };

  const navigate = useNavigate();

  const CustomerApi = () => {
    document
      .querySelectorAll(".modal-backdrop")
      .forEach((el) => el.classList.remove("modal-backdrop"));
    let local = localStorage.getItem("token");
    let userid = localStorage.getItem("userId");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);
    myHeaders.append(
      "Cookie",
      "connect.sid=s%3AyaHFql-YNXNgdQlka4nRvpWL-iJLSdB-.%2B6wW6d812YXYU4ihAgIwRTFu54mu6VtFKEcdK50W57A"
    );

    var raw = JSON.stringify({
      firstName: firstName,
      lastName: lastName,
      email: email || "",
      phoneNumber: phoneNo, //phoneNo ? phoneNo.replace(/^\+/, "") : "",
      profileImage: images,
      country: country,
      streetAddress: address,
      state: state,
      countryCode: String(countrycode.country_code),
      zipCode: zipcode,
      city: city,
      mainUserId: userid,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(customerAddApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log(result);
        if (result.status === true) {
          setShowModal(false);
          setMessage(result?.message);
          navigate("/customerList");
          GetCustomer();
          setFirstName("");
          setLastName("");
          setImages("");
          setAddress("");
          setPhoneNo("");
          setCountry("");
          SetCity("");
          setZipcode("");
          setState("");
          setEmail("");
          setMessage("");
          setValidState(false);
        } else {
          setMessage(result?.message);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const SubmitCus = () => {
    setError(Validation());
  };

  const HandleDelete = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete image",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#c76cff",
      cancelButtonColor: "#efefef",
      confirmButtonText: "Yes, delete it!",
      confirmButtonText: "<span style='color: white;'>Yes, delete it!</span>",
      cancelButtonText: "<span style='color: black;'>cancel!</span>",
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      customClass: {
        container: "sweet-alert2-container-zindex",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Deleted!",
          text: "Your file has been deleted.",
          icon: "success",
          confirmButtonColor: "#c76cff",
          customClass: {
            container: "sweet-alert2-container-zindex",
          },
        }).then(() => {
          setImages("");
        });
      }
    });
  };
  const handleImages = (e) => {
    var file = e.target.files[0];

    if (file) {
      const minSizeInBytes = 5000; // 5 KB
      const maxSizeInBytes = 1024 * 1024; // 1 MB

      if (file.size < minSizeInBytes) {
        // console.error("Image size is below the minimum allowed size (5 KB)");
        setImageMsg("Image size is below the minimum allowed size (5 KB)");
        e.target.value = null;
        return;
      }
      let size = file.type.replace("/", ".");
      var blob = file.slice(0, file.size, file.type);
      const newName = new Date().getTime() + size;
      var newFile = new File([blob], newName, { type: file.type });

      UploadImage(newFile);
    }
  };

  const UploadImage = async (newFile) => {
    var formdata = new FormData();
    formdata.append("file", newFile);
    formdata.append("type", "customers-images");

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(uploadImageApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log(result);
        // setImage("");
        setImages(result?.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const handleModalClose = () => {
    //console.log("helllo");
    setShowModal(false);
  };

  useEffect(() => {
    GetReciept();
    GetPaymentOptions();
    GetAllTax();
    GetAllDiscount();
    let localAdmin = localStorage.getItem("admin");
    setAdminState(localAdmin);
    // GetCustomer();
    GetAllProducts();
    handleGetInVoices();

    let localPerminssion = JSON.parse(localStorage.getItem("permission"));

    if (localAdmin === "0" && localPerminssion.length) {
      setAdminState(localAdmin);
      setPermission(localPerminssion);

      GetSubUserProfile();
    }
    GetProfile();

    // fetch(
    //   "https://ip-api.io/api/json?api_key=fabc32c1-943d-43c7-8a13-ea82cd6ac052"
    // )
    fetch(
      "https://ip-api.io/api/json?api_key=fabc32c1-943d-43c7-8a13-ea82cd6ac052"
    )
      .then((response) => response.json())
      .then((json) => {
        setCountryCode(json);
      });
  }, []);

  const GetCustomer = (page, searchTerm) => {
    let local = localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${baseurl}getAllCustomer?pageSize=10&page=${page}&keyword=${searchTerm}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        // console.log(result);
        if (result.status === true) {
          // setData(result?.data);
          // setFilteredData((prevData) =>
          //   Array.isArray(prevData)
          //     ? [...prevData, ...result?.data]
          //     : result?.data
          // );
          setFilteredData((prevData) => {
            const combinedData = Array.isArray(prevData)
              ? [...prevData, ...result?.data]
              : result?.data;

            const uniqueData = Array.from(
              new Set(combinedData.map((item) => item.id))
            ).map((id) => combinedData.find((item) => item.id === id));

            return uniqueData;
          });

          setData((prevData) =>
            Array.isArray(prevData)
              ? [...prevData, ...result.data]
              : result.data
          );
          // setFilteredData((prevData) =>
          //   Array.isArray(prevData)
          //     ? [...prevData, ...result?.data]
          //     : result?.data
          // );
          setHasMore(result?.data?.length > 0);
          // setData1(result?.data);
          // setSelectedCustomer(result?.data);
        } else {
          setHasMore(false);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const cancleValues = () => {
    // console.log("hh");
    setImages("");
    setEmail("");
    setError("");
    setFirstName("");
    setLastName("");
    setAddress("");
    setState("");
    setCountry("");
    SetCity("");
    setPhoneNo("");
    setZipcode("");
    setValidState(false);
    setMessage("");
  };

  const GetAllProducts = () => {
    // setLoading(true);
    let local = localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);
    myHeaders.append(
      "Cookie",
      "connect.sid=s%3AJ0pT5xJJsG8rHqot1A6txMupaTPvbUM2.LHRBUtee7s0DQmEebl5p1hig5dbcABIB0m4VWSzru%2Fo"
    );

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(GetProductDeviceApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === true) {
          console.log(
            "resultproduct",
            result?.data.filter((item) => item?.activeInactive === 1)
          );
          // setAllProducts(result?.data);
          setAllProducts(
            result?.data.filter((item) => item?.activeInactive === 1)
          );
          //setLoading(false);
          //setSelectedProduct(result?.data);
          setRetrieveSaleProduct(
            result?.data.filter((item) => item?.activeInactive === 0)
          );
          // console.log(
          //   "parkedddataproducts",
          //   result?.data.filter((item) => item?.activeInactive === 0)
          // );
        } else {
          //setLoading(false);
        }
      })
      .catch((error) => {
        console.log("error", error);
        //setLoading(false);
      });
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      const dropdown = document.querySelector(".searchproductdisplycstm");

      if (dropdown && !dropdown.contains(event.target)) {
        setShowModal2(false);
      }
    };

    window.addEventListener("click", handleOutsideClick);

    return () => {
      window.removeEventListener("click", handleOutsideClick);
    };
  }, [setShowModal2]);

  // const handleDelete = (index) => {
  //   setSelectedProduct((prevProducts) => {
  //     const updatedProducts = [...prevProducts];
  //     updatedProducts.splice(index, 1);
  //     return updatedProducts;
  //   });
  //   setReceivedQuantities((prevQuantities) => {
  //     const updatedQuantities = [...prevQuantities];
  //     updatedQuantities.splice(index, 1);
  //     return updatedQuantities;
  //   });

  //   setPaymnetState(false);
  //   setPaymentMode([]);
  //   GetAllProducts();
  // };

  // const handleDelete = (index) => {
  //   setSelectedProduct((prevProducts) => {
  //     const updatedProducts = [...prevProducts];
  //     updatedProducts.splice(index, 1);
  //     return updatedProducts;
  //   });
  //   // setSelectedProduct((prevSelectedProducts) => {
  //   //   const productToRemove = prevSelectedProducts[index];
  //   //   const updatedProducts = prevSelectedProducts.filter(
  //   //     (product, i) => i !== index
  //   //   );

  //   //   // Add the removed product back to filteredData1
  //   //   setAllProducts((prevData) => [...prevData, productToRemove]);

  //   //   return updatedProducts;
  //   // });

  //   setUnitCosts((prevUnitCosts) =>
  //     prevUnitCosts.filter((_, i) => i !== index)
  //   );
  //   setReceivedQuantities((prevQuantities) =>
  //     prevQuantities.filter((_, i) => i !== index)
  //   );
  //   setPaymnetState(false);
  //   setPaymentMode([]);
  // };

  const handleDelete = (index) => {
    setSelectedProduct((prevProducts) => {
      const updatedProducts = [...prevProducts];
      updatedProducts.splice(index, 1);
      return updatedProducts;
    });

    setUnitCosts((prevUnitCosts) => {
      const updatedCosts = [...prevUnitCosts];
      updatedCosts.splice(index, 1);
      return updatedCosts;
    });

    setReceivedQuantities((prevQuantities) => {
      const updatedQuantities = [...prevQuantities];
      updatedQuantities.splice(index, 1);
      return updatedQuantities;
    });

    setPaymnetState(false);
    setPaymentMode([]);
    GetAllProducts();
  };

  // const handleDelete = (index) => {
  //   setSelectedProduct((prevProducts) => {
  //     const updatedProducts = [...prevProducts];
  //     updatedProducts.splice(index, 1);
  //     return updatedProducts;
  //   });

  //   setUnitCosts((prevUnitCosts) => {
  //     const updatedCosts = [...prevUnitCosts];
  //     updatedCosts.splice(index, 1);
  //     return updatedCosts;
  //   });

  //   setReceivedQuantities((prevQuantities) => {
  //     const updatedQuantities = [...prevQuantities];
  //     updatedQuantities.splice(index, 1);
  //     return updatedQuantities;
  //   });

  //   setPaymnetState(false);
  //   setPaymentMode([]);
  //   GetAllProducts();
  // };

  const handleDeleteRetrieve = (index) => {
    setSelectedRetrieveProduct((prevProducts) => {
      const updatedProducts = [...prevProducts];
      updatedProducts.splice(index, 1);
      return updatedProducts;
    });
    setReceivedQuantities((prevQuantities) => {
      const updatedQuantities = [...prevQuantities];
      updatedQuantities.splice(index, 1);
      return updatedQuantities;
    });

    // setUnitCosts((prevUnitCosts) => {
    //   const updatedCosts = [...prevUnitCosts];
    //   updatedCosts.splice(index, 1);
    //   return updatedCosts;
    // });
    setPaymnetState(false);
    setPaymentMode([]);
  };
  const calculateTotalSellingPrice = () => {
    return selectedProduct.reduce((total, product, index) => {
      const sellingPrice = Number(product?.sellingPrice) || 0;
      const unitCost = Number(unitCosts[index]);
      const priceToAdd =
        unitCost !== undefined && unitCost !== null ? unitCost : sellingPrice;

      return total + priceToAdd;
    }, 0);
  };

  // const calculateTotalSellingPrice = () => {
  //   return selectedProduct.reduce((total, product, index) => {
  //     const sellingPrice = Number(product?.sellingPrice) || 0;
  //     const unitCost = Number(unitCosts[index]) || 0;

  //     // If the unitCost is edited (non-zero), use it, otherwise use sellingPrice
  //     const priceToAdd = unitCost !== 0 ? unitCost : sellingPrice;

  //     return total + priceToAdd;
  //   }, 0);
  // };
  // const calculateTotalSellingPrice = () => {
  //   return selectedProduct.reduce((total, product,index) => {
  //     return total + (product?.sellingPrice || 0);
  //   }, 0);
  // }
  const Totalcosts = calculateTotalSellingPrice();
  // console.log("totalcost", Totalcosts);

  const calculateDiscountedAverageCost = (
    sellingPrice,
    receivedQty,
    discount
  ) => {
    const total = sellingPrice * receivedQty;
    if (!discount) {
      return total;
    }
    const discountedTotal = total * (1 - discount / 100);
    return discountedTotal >= 0 ? discountedTotal : 0;
  };

  // const calculateTotalAverageCost = () => {
  //   return selectedProduct.reduce((total, product, index) => {
  //     const productAverageCost = product?.sellingPrice || 0;
  //     const productQuantity = receivedQuantities[index] || 1;
  //     console.log("productQuantity", productQuantity);
  //     console.log("productAverageCost", productAverageCost);
  //     return total + productAverageCost * productQuantity;
  //   }, 0);
  // };
  const calculateTotalAverageCost = () => {
    return selectedProduct.reduce((total, product, index) => {
      const sellingPrice = Number(product?.sellingPrice) || 0;
      const unitCost = Number(unitCosts[index]) || 0;
      const priceToUse =
        unitCost !== undefined && unitCost !== null ? unitCost : sellingPrice;
      const productQuantity = Number(receivedQuantities[index]) || 1;
      //console.log("productQuantity", productQuantity);
      //console.log("priceToUse", priceToUse);
      return total + priceToUse * productQuantity;
    }, 0);
  };

  const totalAverageCost = calculateTotalAverageCost();
  //console.log("totalsales", totalAverageCost);

  const calculateTotalSalesAverageCost = () => {
    return selectedProduct.reduce((total, product, index) => {
      const isDevice = product?.productCategoryId === 2;
      const productCost = isDevice
        ? product?.unitCost
        : product?.averageCost || 0;
      const productQuantity = receivedQuantities[index] || 1;

      return total + productCost * productQuantity;
    }, 0);
  };

  const totalAverageSalesCost = calculateTotalSalesAverageCost();
  // console.log("totalAverageSalesCost", totalAverageSalesCost);

  const calculateTotalSalesAverageCost2 = () => {
    return selectRetrieveProduct.reduce((total, product, index) => {
      const isDevice = product?.productCategoryId === 2;
      const productCost = isDevice
        ? product?.unitCost
        : product?.averageCost || 0;
      const productQuantity = receivedQuantities[index] || 1;

      return total + productCost * productQuantity;
    }, 0);
  };

  const totalAverageSalesCost3 = calculateTotalSalesAverageCost2();
  //console.log("totalAverageSalesCost", totalAverageSalesCost);

  const calculateTotalSalesQuantity = () => {
    return receivedQuantities.reduce(
      (total, quantity) => total + Number(quantity),
      0
    );
  };

  // const calculateTotalSalesQuantity = () => {
  //   return receivedQuantities.reduce((total, quantity) => total + quantity, 0);
  // };
  const totalSalesQuantity = calculateTotalSalesQuantity();

  // console.log("Total Sales Quantity:", totalSalesQuantity);

  const updateTotalSalesQuantity = () => {
    const total = calculateTotalSalesQuantity();
    setTotalSalesQty(total);
  };

  const calculateTotalSalesQuantityRet = () => {
    return receivedQuantities.reduce(
      (total, quantity) => total + Number(quantity),
      0
    );
  };

  const totalSalesQuantityRet = calculateTotalSalesQuantityRet();
  // console.log("totalSalesQuantityRet", totalSalesQuantityRet);

  const updateTotalSalesQuantityRet = () => {
    const total = calculateTotalSalesQuantityRet();
    setTotalSalesQty(total);
  };

  const calculateRetrieveTotalCost = () => {
    return selectRetrieveProduct?.reduce((total, product) => {
      const productTotal = product?.items?.reduce((subtotal, item) => {
        return subtotal + (item?.unitCost || 0);
      }, 0);
      return total + productTotal;
    }, 0);
  };

  const calculateRetrieveTotalSalesCost = () => {
    let totalSalesCost = 0;
    selectRetrieveProduct.forEach((product, index) => {
      product.itemIds.forEach((item, itemIndex) => {
        const arrayIndex = selectedProduct.length + index + itemIndex;
        totalSalesCost +=
          parseFloat(unitCosts[arrayIndex] || 0) *
          receivedQuantities[arrayIndex];
      });
    });

    return totalSalesCost >= 0 ? totalSalesCost.toFixed(2) : "0.00";
  };

  const retrieveTotalCost = calculateRetrieveTotalCost();
  // console.log("retrieveTotalCost", retrieveTotalCost);
  const retrieveTotalSalesCost = calculateRetrieveTotalSalesCost();
  //console.log("retrieveTotalSalesCost", retrieveTotalSalesCost);

  const AddInvoicePark = () => {
    setLoading(true);
    document
      .querySelectorAll(".modal-backdrop")
      .forEach((el) => el.classList.remove("modal-backdrop"));
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);

    let totalCosts = 0; // Initialize totalCosts
    let TotalQty = 0;
    const itemsData = selectedProduct.map((item, index) => {
      const itemId = item?.id;
      const selectedIMEINumbers = item?.imeiNumber || [];
      let filteredImeiNumbers = [];

      try {
        const parsedImeiArr = JSON.parse(item?.imeiNumberArr || "[]");
        filteredImeiNumbers = Array.isArray(parsedImeiArr)
          ? parsedImeiArr.filter((imei) => selectedIMEINumbers.includes(imei))
          : [];
      } catch (error) {
        console.error("Error parsing IMEI array: ", error);
        filteredImeiNumbers = [];
      }

      const Unitcost = Number(unitCosts?.[index] || 0);
      totalCosts += Unitcost;
      const TQty = Number(receivedQuantities[index]);
      TotalQty += TQty;

      return {
        discount: Number(discounts?.[index]) || 0,
        quantity: Number(receivedQuantities?.[index]) || 0,
        unitCost: Unitcost,
        itemId: itemId,
        note: note?.[index] || "",
        total: Number(
          calculateDiscountedTotal(
            unitCosts?.[index],
            receivedQuantities?.[index],
            discounts?.[index] || 0
          )
        ),
        averageCost: item?.averageCost || 0,
        imeiNumber: selectedIMEINumbers.join(","), // Combine selected IMEI numbers into a single string
        imeiNumberArr: filteredImeiNumbers, // IMEI numbers array
      };
    });
    // const itemsData = Object.values(
    //   selectedProduct.reduce((acc, item, index) => {
    //     const itemId = item.id;
    //     const selectedIMEINumbers = item?.imeiNumber;
    //     const filteredImeiNumbers =
    //       JSON.parse(item?.imeiNumberArr)?.filter((imei) =>
    //         selectedIMEINumbers.includes(imei)
    //       ) || [];
    //     const Unitcost = Number(unitCosts[index]);
    //     totalCosts += Unitcost;
    //     const TQty = Number(receivedQuantities[index]);
    //     TotalQty += TQty;
    //     if (!acc[itemId]) {
    //       acc[itemId] = {
    //         discount: Number(discounts[index]) || 0,
    //         quantity: Number(receivedQuantities[index]),
    //         unitCost: Number(unitCosts[index]),
    //         itemId: item.id,
    //         note: note[index] ? note[index] : "",
    //         total: Number(
    //           calculateDiscountedTotal(
    //             unitCosts[index],
    //             receivedQuantities[index],
    //             discounts[index] || 0
    //           )
    //         ),
    //         averageCost: item?.averageCost,
    //         imeiNumber: "",
    //         imeiNumberArr: filteredImeiNumbers,
    //       };
    //     } else {
    //       acc[itemId].quantity += Number(receivedQuantities[index]);
    //       acc[itemId].total += Number(
    //         calculateDiscountedTotal(
    //           unitCosts[index],
    //           receivedQuantities[index],
    //           discounts[index] || 0
    //         )
    //       );
    //       acc[itemId].imeiNumber.push(...filteredImeiNumbers);
    //     }

    //     return acc;
    //   }, {})
    // );

    const dueData = selectedProduct.map((item) => {
      return {
        itemId: item.id,
        paymentType: selectedPaymentType,
        date: moment(new Date()).format("YYYY-MM-DD"),
        time: moment(new Date()).format("HH:mm:ss"),
        payAmount: Number(inputValue) || 0,
      };
    });

    // console.log("itemsss", dueData);

    const raw = JSON.stringify({
      activeInactive: 0,
      itemIds: selectedProduct.map((item) => item.id),
      items: itemsData,
      customerId: selectedCustomer?.id ? selectedCustomer?.id : null,
      subTotal: overallTotal,
      discount: discount, //dis.toFixed(2),
      tax: tax, //taxAmount.toFixed(2),
      grandTotal: overallTotal1.toFixed(2),
      paymentType: selectedPaymentTypeId,
      isCompleted: 1,
      note: descriptionNote ? descriptionNote : "",
      due: changeDue ? changeDue : 0,
      payment: dueData,
      parkNote: parkNote ? parkNote : "",
      isAdmin: adminState,
      totalCost: totalCosts.toFixed(2), // Number(Totalcosts.toFixed(2)),
      // totalCost: Number(Totalcosts.toFixed(2)),
      totalSalesCost: overallTotal, //Number(totalAverageCost.toFixed(2)),
      totalSalesQuantity: TotalQty, //totalSalesQuantity,
      taxAmount: taxAmount.toFixed(2),
      averageSalesCost: Number(totalAverageSalesCost.toFixed(2)),
      discountAmount: Number(discountAmount),
      invoiceStatus: 1,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    console.log("parkadd", raw);
    fetch(AddInvoiceApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log("parkedResult", result);
        if (result?.status === true) {
          Swal.fire({
            title: "Your Product is Parked Successfully!",
            customClass: {
              container: "custom-swal-container",

              confirmButton: "custom-swal-confirm-button",
            },
          });
          GetAllProducts();
          updateTotalSalesQuantity();
          setTotalSalesQty(0);
          handleGetInVoices();
          setSelectedProduct([]);
          setSelectedRetrieveProduct([]);
          setPaymentMode([]);
          setPaymnetState(false);
          setSelectedCustomer({});
          // setShowModal9(false);
          setSelectedState(false);
          setDiscounts([]);
          setLoading(false);
          setReceivedQuantities([]);
          setSelectedOptions("");
          setSelectedPaymentType("");
          setSelectedPaymentTypeId("");
          setSelectedCategory2("");
          setDiscount(0);
          // navigate("/invoicelist");
          document
            .querySelectorAll(".modal-backdrop")
            .forEach((el) => el.classList.remove("modal-backdrop"));
          setShowModal7(false);
        } else {
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };

  const AddInvoice = () => {
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);
    let totalCosts = 0; // Initialize totalCosts
    let TotalQty = 0;
    const itemsData = selectedProduct.map((item, index) => {
      const itemId = item?.id;
      const selectedIMEINumbers = item?.imeiNumber || [];
      let filteredImeiNumbers = [];

      try {
        const parsedImeiArr = JSON.parse(item?.imeiNumberArr || "[]");
        filteredImeiNumbers = Array.isArray(parsedImeiArr)
          ? parsedImeiArr.filter((imei) => selectedIMEINumbers.includes(imei))
          : [];
      } catch (error) {
        console.error("Error parsing IMEI array: ", error);
        filteredImeiNumbers = [];
      }

      const Unitcost = Number(unitCosts?.[index] || 0);
      totalCosts += Unitcost;
      const TQty = Number(receivedQuantities[index]);
      TotalQty += TQty;

      return {
        discount: Number(discounts?.[index]) || 0,
        quantity: Number(receivedQuantities?.[index]) || 0,
        unitCost: Unitcost,
        itemId: itemId,
        note: note?.[index] || "",
        total: Number(
          calculateDiscountedTotal(
            unitCosts?.[index],
            receivedQuantities?.[index],
            discounts?.[index] || 0
          )
        ),
        averageCost: item?.averageCost || 0,
        imeiNumber: selectedIMEINumbers.join(","), // Combine selected IMEI numbers into a single string
        imeiNumberArr: filteredImeiNumbers, // IMEI numbers array
      };
    });
    // const itemsData = Object.values(
    //   selectedProduct.reduce((acc, item, index) => {
    //     const itemId = item.id;
    //     const selectedIMEINumbers = item?.imeiNumber;
    //     const filteredImeiNumbers =
    //       JSON.parse(item?.imeiNumberArr)?.filter((imei) =>
    //         selectedIMEINumbers.includes(imei)
    //       ) || [];
    //     const Unitcost = Number(unitCosts[index]);
    //     totalCosts += Unitcost;

    //     const TQty = Number(receivedQuantities[index]);
    //     TotalQty += TQty;

    //     if (!acc[itemId]) {
    //       acc[itemId] = {
    //         discount: Number(discounts[index]) || 0,
    //         quantity: Number(receivedQuantities[index]),
    //         unitCost: Number(unitCosts[index]),
    //         itemId: item.id,
    //         note: note[index] ? note[index] : "",
    //         total: Number(
    //           calculateDiscountedTotal(
    //             unitCosts[index],
    //             receivedQuantities[index],
    //             discounts[index] || 0
    //           )
    //         ),
    //         averageCost: item?.averageCost,
    //         imeiNumber: "",
    //         imeiNumberArr: filteredImeiNumbers,
    //       };
    //       // console.log("filter", filteredImeiNumbers);
    //     } else {
    //       acc[itemId].quantity += Number(receivedQuantities[index]);
    //       acc[itemId].total += Number(
    //         calculateDiscountedTotal(
    //           unitCosts[index],
    //           receivedQuantities[index],
    //           discounts[index] || 0
    //         )
    //       );
    //       acc[itemId].imeiNumber.push(...filteredImeiNumbers);
    //     }

    //     return acc;
    //   }, {})
    // );

    // console.log("itemsdad", itemsData);
    const dueData = selectedProduct.map((item) => {
      return {
        itemId: item.id,
        paymentType: selectedPaymentType,
        date: moment(new Date()).format("YYYY-MM-DD"),
        time: moment(new Date()).format("HH:mm:ss"),
        payAmount: Number(inputValue) || 0,
      };
    });
    const dis = parseFloat(overallTotal - discountedSubtotal);
    // console.log("itemsss", dueData);

    const raw = JSON.stringify({
      activeInactive: 1,
      itemIds: selectedProduct.map((item) => item.id),
      items: itemsData,
      customerId: selectedCustomer?.id ? selectedCustomer?.id : null,
      subTotal: overallTotal,
      discount: discount, //dis.toFixed(2),
      tax: tax, //taxAmount.toFixed(2),
      grandTotal: overallTotal1.toFixed(2),
      paymentType: selectedPaymentTypeId,
      isCompleted: 1,
      note: descriptionNote,
      due: changeDue ? changeDue : 0,
      payment: paymentMode,
      parkNote: parkNote ? parkNote : "",
      isAdmin: adminState,
      totalCost: totalCosts.toFixed(2), // Number(Totalcosts.toFixed(2)),
      totalSalesCost: overallTotal, //Number(totalAverageCost.toFixed(2)),
      totalSalesQuantity: TotalQty, //totalSalesQuantity,
      taxAmount: taxAmount.toFixed(2),
      averageSalesCost: Number(totalAverageSalesCost.toFixed(2)),
      discountAmount: Number(discountAmount),
      invoiceStatus: 1,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    console.log("addinvoice", raw);
    fetch(AddInvoiceApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log("resultAdd", result);
        if (result?.status === true) {
          setShowModal9(true);
          setInvoiceID1(result?.data?.id);
          GetInvoiceId(result?.data?.id);
          GetAllProducts();
          updateTotalSalesQuantity();
          setTotalSalesQty(0);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleGetInVoices = () => {
    let local = localStorage.getItem("token");
    // console.log(local);
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(GetInvoices, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log("retvierve data", result);
        if (result.status === true) {
          setInVoiceData(
            result?.data.filter((item) => item?.activeInactive === 0).reverse()
          );
          // console.log(
          //   "retrieveData",
          //   result?.data.filter((item) => item?.activeInactive === 0).reverse()
          // );
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const [discount, setDiscount] = useState(0);
  const [tax, setTax] = useState(0);

  const handleDiscountChange = (event) => {
    const selectedDiscount = parseInt(event.target.value, 10);
    setDiscount(selectedDiscount);
  };

  const handleTaxChange = (event) => {
    const selectedTax = parseInt(event.target.value, 10);
    setTax(selectedTax);
  };

  const discountedSubtotal = overallTotal * (1 - discount / 100);

  const discountAmount = parseFloat(overallTotal - discountedSubtotal).toFixed(
    2
  );
  // console.log("discountAmount", discountAmount);

  const taxAmount = discountedSubtotal * (tax / 100);
  const overallTotal1 = discountedSubtotal + taxAmount;

  // const overallTotal1 = overallTotal1;
  const [errormsg, setErrormsg] = useState("");
  const maxDescriptionLength = 300;

  const handleDescriptionChange = (e) => {
    const newDescription = e.target.value;

    if (newDescription.length <= maxDescriptionLength) {
      setDescriptionNote(newDescription);
      setErrormsg("");
    } else if (newDescription.length > maxDescriptionLength) {
      setTimeout(() => {
        setErrormsg("Allow Maximum 300 Characters");
      }, 2000);
    }
  };

  const handleDescriptionChangeNote = (e) => {
    const newDescription = e.target.value;

    if (newDescription.length <= maxDescriptionLength) {
      setNote((prevUnitCosts) => {
        const newUnitCosts = [...prevUnitCosts];
        newUnitCosts[editedProductIndex] = newDescription;
        return newUnitCosts;
      });
      // setDescriptionNote(newDescription);
      setErrormsg("");
    } else if (newDescription.length > maxDescriptionLength) {
      setTimeout(() => {
        setErrormsg("Allow Maximum 300 Characters");
      }, 2000);
    }
  };

  const handleDescriptionChangepark = (e) => {
    const newDescription = e.target.value;

    if (newDescription.length <= maxDescriptionLength) {
      setParkNote(newDescription);
      setErrormsg("");
    } else if (newDescription.length > maxDescriptionLength) {
      setTimeout(() => {
        setErrormsg("Allow Maximum 300 Characters");
      }, 2000);
    }
  };
  const [dueAmount, setDueAmount] = useState();
  const [remainingAmount, setRemainingAmount] = useState(overallTotal1);

  const Due = parseFloat(overallTotal1) - parseFloat(inputValue);

  // console.log("fgfg", Due);
  useEffect(() => {
    if (overallTotal1 !== null) {
      setInputValue(overallTotal1.toFixed(2));
    }
  }, [overallTotal1]);

  const [paymentTotal, setPaymentTotal] = useState();
  const [ErrorAmount, setErrorAmount] = useState(false);

  const handlePay = () => {
    if (selectedProduct.length === 0) {
      Swal.fire("Please select a product first.");
      return;
    }

    if (inputValue > overallTotal1) {
      // Calculate change due

      const changes = inputValue - overallTotal1;
      // Swal.fire(`Change due: $${changes.toFixed(2)}`);

      setChangeDue(changes.toFixed(2));
    }

    const payAmount = Number(inputValue) || 0;

    if (payAmount === 0) {
      // Swal.fire("Your payment is complete.");
      return;
    }
    setPaymentTotal(payAmount);
    const paymentData = {
      itemId: selectedProduct.map((item) => item.id),
      paymentType: selectedPaymentType,
      date: moment(new Date()).format("YYYY-MM-DD"),
      time: moment(new Date()).format("HH:mm:ss"),
      payAmount: payAmount,
    };

    // Update payment mode
    setPaymentMode((prevPaymentMode) => [...prevPaymentMode, paymentData]);

    // Update total payment
    setTotalPayment((prevTotalPayment) => prevTotalPayment + payAmount);

    // Update remaining amount and input value
    const newRemainingAmount = overallTotal1 - totalPayment - payAmount;

    setRemainingAmount(newRemainingAmount);
    setInputValue(
      newRemainingAmount !== undefined ? newRemainingAmount.toFixed(2) : ""
    );

    // Check if the total payment equals the overall total
    if (totalPayment + payAmount == overallTotal1.toFixed(2)) {
      setErrorAmount(true);
    }

    // Optionally set payment state
    setPaymnetState(true);
  };

  const handlePayRet = () => {
    if (selectRetrieveProduct.length === 0) {
      Swal.fire("Please select a product first.");
      return;
    }

    if (inputValue > overallTotal1) {
      // Calculate change due

      const changes = inputValue - overallTotal1;
      // Swal.fire(`Change due: $${changes.toFixed(2)}`);

      setChangeDue(changes.toFixed(2));
    }

    const payAmount = Number(inputValue) || 0;

    if (payAmount === 0) {
      // Swal.fire("Your payment is complete.");
      return;
    }
    setPaymentTotal(payAmount);
    // const paymentData =
    //   selectRetrieveProduct.length > 0
    //     ? selectRetrieveProduct.map((item) => ({
    //         itemId: item.id,
    //         paymentType: selectedPaymentType,
    //         date: moment(new Date()).format("YYYY-MM-DD"),
    //         time: moment(new Date()).format("HH:mm:ss"),
    //         payAmount: Number(inputValue) || 0,
    //       }))
    //     : [];
    const paymentData = {
      itemId: selectRetrieveProduct.flatMap((product) =>
        product.itemIds.map((item) => item.id)
      ),
      paymentType: selectedPaymentType,
      date: moment(new Date()).format("YYYY-MM-DD"),
      time: moment(new Date()).format("HH:mm:ss"),
      payAmount: payAmount,
    };

    // Update payment mode
    setPaymentMode((prevPaymentMode) => [...prevPaymentMode, paymentData]);

    // Update total payment
    setTotalPayment((prevTotalPayment) => prevTotalPayment + payAmount);

    // Update remaining amount and input value
    const newRemainingAmount = overallTotal1 - totalPayment - payAmount;

    setRemainingAmount(newRemainingAmount);
    setInputValue(
      newRemainingAmount !== undefined ? newRemainingAmount.toFixed(2) : ""
    );

    // Check if the total payment equals the overall total
    if (totalPayment + payAmount == overallTotal1.toFixed(2)) {
      setErrorAmount(true);
    }

    // Optionally set payment state
    setPaymnetState(true);
  };

  const [totalPayment, setTotalPayment] = useState(0);

  useEffect(() => {
    const totalPayment = paymentMode.reduce(
      (total, payment) => total + payment.payAmount,
      0
    );
    setTotalPayment(totalPayment);
  }, [paymentMode]);

  const UpdateInvoice = () => {
    setLoading(true);
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);
    let totalCosts = 0; // Initialize totalCosts

    const itemsData = Object.values(
      selectRetrieveProduct.reduce((acc, product, index) => {
        product.items.forEach((item) => {
          const itemId = item.itemId; // Directly access the itemId from the items array
          const selectedIMEINumbers = product?.selectedImeiNumbers || [];

          // Collect all IMEI numbers from the current item's imeiNumberArr
          const allImeiNumbers = item.imeiNumberArr || [];
          const filteredImeiNumbers = allImeiNumbers.filter((imei) =>
            selectedIMEINumbers.includes(imei)
          );
          const Unitcost = Number(item.unitCost);
          totalCosts += Unitcost;
          if (!acc[itemId]) {
            acc[itemId] = {
              discount: Number(discounts[index]) || 0,
              quantity: Number(item.quantity), // Quantity from the current item
              unitCost: Number(item.unitCost), // Unit cost from the current item
              itemId: itemId,
              note: note[index] ? note[index] : "",
              total: Number(
                calculateDiscountedTotal(
                  item.unitCost,
                  item.quantity,
                  discounts[index] || 0
                )
              ),
              averageCost: item.averageCost, // Get the average cost of the current item
              imeiNumber: selectedIMEINumbers.join(", "),
              imeiNumberArr: filteredImeiNumbers, // Use the filtered IMEI numbers
            };
          } else {
            acc[itemId].quantity += Number(item.quantity);
            acc[itemId].total += Number(
              calculateDiscountedTotal(
                item.unitCost,
                item.quantity,
                discounts[index] || 0
              )
            );
            acc[itemId].imeiNumber += `, ${selectedIMEINumbers.join(", ")}`;
            acc[itemId].imeiNumberArr.push(...filteredImeiNumbers);
          }
        });

        return acc;
      }, {})
    );

    // const itemsData = Object.values(
    //   selectRetrieveProduct.reduce((acc, item, index) => {
    //     const itemId = item.itemIds.flatMap((item) => item.id); //item?.itemIds[0].id;
    //     const selectedIMEINumbers = item?.selectedImeiNumbers || [];

    //     // Collect all IMEI numbers from the items array
    //     const allImeiNumbers = item.items.flatMap(
    //       (item) => item.imeiNumberArr || []
    //     );
    //     const filteredImeiNumbers = allImeiNumbers.filter((imei) =>
    //       selectedIMEINumbers.includes(imei)
    //     );

    //     // console.log("selectedIMEINumbers", selectedIMEINumbers);
    //     // console.log("allImeiNumbers", allImeiNumbers);
    //     // console.log("filteredImeiNumbers", filteredImeiNumbers);

    //     if (!acc[itemId]) {
    //       acc[itemId] = {
    //         discount: Number(discounts[index]) || 0,
    //         quantity: Number(receivedQuantities[index]),
    //         unitCost: Number(unitCosts[index]),
    //         itemId: itemId,
    //         note: note[index] ? note[index] : "",
    //         total: Number(
    //           calculateDiscountedTotal(
    //             unitCosts[index],
    //             receivedQuantities[index],
    //             discounts[index] || 0
    //           )
    //         ),
    //         averageCost: item?.averageCost,
    //         imeiNumber: selectedIMEINumbers.join(", "),
    //         imeiNumberArr: allImeiNumbers, // Ensure it's a single array
    //       };
    //     } else {
    //       acc[itemId].quantity += Number(receivedQuantities[index]);
    //       acc[itemId].total += Number(
    //         calculateDiscountedTotal(
    //           unitCosts[index],
    //           receivedQuantities[index],
    //           discounts[index] || 0
    //         )
    //       );
    //       acc[itemId].imeiNumber += `, ${selectedIMEINumbers.join(", ")}`;
    //       acc[itemId].imeiNumberArr.push(...filteredImeiNumbers);
    //     }

    //     return acc;
    //   }, {})
    // );

    const dis = parseFloat(overallTotal - discountedSubtotal);
    const raw = JSON.stringify({
      itemIds: selectRetrieveProduct.flatMap((product) =>
        product.itemIds.map((item) => item.id)
      ),
      items: itemsData,
      customerId: customerid ? customerid : selectedCustomer?.id,
      subTotal: overallTotal,
      discount: discount, //dis.toFixed(2),
      tax: tax, //taxAmount.toFixed(2),
      grandTotal: overallTotal1.toFixed(2),
      paymentType: selectedPaymentTypeId,
      isCompleted: 1,
      note: descriptionNote ? descriptionNote : "",
      due: changeDue ? changeDue : 0,
      parkNote: "",
      payment: paymentMode,
      activeInactive: 1,
      isAdmin: adminState,
      totalCost: totalCosts.toFixed(2), //Number(retrieveTotalCost),
      totalSalesCost: overallTotal, //Number(retrieveTotalSalesCost),
      totalSalesQuantity: totalSalesQuantityRet,
      taxAmount: taxAmount.toFixed(2),
      averageSalesCost: Number(totalAverageSalesCost3.toFixed(2)),
      discountAmount: Number(discountAmount),
      invoiceStatus: 1,
    });

    const requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    console.log("updateraw", raw);
    // setLoading(false);
    fetch(`${baseurl}updateInvoice/${invoiceId}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log("updateinvoice", result);
        if (result?.status === true) {
          navigate("/invoicelist");
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error(error);

        setLoading(false);
      });
  };

  // const UpdateInvoice = () => {
  //   setLoading(true);
  //   let local = localStorage.getItem("token");
  //   const myHeaders = new Headers();
  //   myHeaders.append("Content-Type", "application/json");
  //   myHeaders.append("Authorization", `Bearer ${local}`);
  //   const itemsData = selectRetrieveProduct.flatMap((product, productIndex) => {
  //     return product.items.map((item, itemIndex) => {
  //       const arrayIndex = productIndex + itemIndex;

  //       return {
  //         discount: Number(discounts[arrayIndex] || 0),
  //         quantity: Number(receivedQuantities[arrayIndex] || 1),
  //         unitCost: Number(unitCosts[arrayIndex] || 0),
  //         itemId: item.itemId,
  //         note: note[arrayIndex] ? note[arrayIndex] : "",
  //         total: Number(
  //           calculateDiscountedTotal(
  //             unitCosts[arrayIndex],
  //             receivedQuantities[arrayIndex],
  //             discounts[arrayIndex] || 0
  //           )
  //         ),
  //         averageCost: product.averageCost,
  //         imeiNumber: product.imeiNumber ? product.imeiNumber : null,
  //       };
  //     });
  //   });

  //   const dis = parseFloat(overallTotal - discountedSubtotal);
  //   const raw = JSON.stringify({
  //     itemIds: selectRetrieveProduct.flatMap((product) =>
  //       product.items.map((item) => item.id)
  //     ),
  //     items: itemsData,
  //     customerId: customerid ? customerid : selectedCustomer?.id,
  //     subTotal: overallTotal,
  //     discount: discount, //dis.toFixed(2),
  //     tax: tax, //taxAmount.toFixed(2),
  //     grandTotal: overallTotal1.toFixed(2),
  //     paymentType: selectedPaymentTypeId,
  //     isCompleted: 1,
  //     note: descriptionNote ? descriptionNote : "",
  //     due: changeDue ? changeDue : 0,
  //     parkNote: "",
  //     payment: paymentMode,
  //     activeInactive: 1,
  //     isAdmin: adminState,
  //     totalCost: Number(retrieveTotalCost),
  //     totalSalesCost: Number(retrieveTotalSalesCost),
  //     totalSalesQuantity: totalSalesQuantity,
  //     taxAmount: taxAmount,
  //   });

  //   const requestOptions = {
  //     method: "PUT",
  //     headers: myHeaders,
  //     body: raw,
  //     redirect: "follow",
  //   };
  //   console.log("updateraw", raw);
  //   fetch(`${baseurl}updateInvoice/${invoiceId}`, requestOptions)
  //     .then((response) => response.json())
  //     .then((result) => {
  //       console.log("updateinvoice", result);
  //       if (result?.status === true) {
  //         navigate("/invoicelist");
  //         setLoading(false);
  //       } else {
  //         setLoading(false);
  //       }
  //     })
  //     .catch((error) => {
  //       console.error(error);

  //       setLoading(false);
  //     });
  // };

  // const CheckItem = () => {
  //   let local = localStorage.getItem("token");
  //   const myHeaders = new Headers();
  //   myHeaders.append("Content-Type", "application/json");
  //   myHeaders.append("Authorization", `Bearer ${local}`);

  //   const raw = JSON.stringify({
  //     itemId: 10,
  //     quantity: 50,
  //   });

  //   const requestOptions = {
  //     method: "POST",
  //     headers: myHeaders,
  //     body: raw,
  //     redirect: "follow",
  //   };

  //   fetch(CheckItemApi, requestOptions)
  //     .then((response) => response.json())
  //     .then((result) => {
  //       console.log(result);
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //     });
  // };

  const renderProductRows = (products, indexPrefix, isRetrieve = false) => {
    return products.map((item, index) => (
      <React.Fragment
        key={`${isRetrieve ? "retrieve" : "product"}_${indexPrefix + index}`}
      >
        {isRetrieve ? (
          item.itemIds.map((ite, i) => (
            <tr
              key={`${isRetrieve ? "retrieve" : "product"}_${
                indexPrefix + index
              }_${i}`}
            >
              <td className="itemnocstm">
                <span className="item_no">#{index + 1}</span>
              </td>
              <td>
                <Link to="/editproduct" state={{ data: ite }}>
                  <a href="#">
                    {ite?.title}
                    <span>({ite?.skuCode})</span>
                  </a>
                </Link>
              </td>
              <td
                className={ite?.inventory <= 0 ? "red-text" : ""}
                style={{ color: ite?.inventory <= 0 ? "red" : "" }}
              >
                {ite?.inventory}
              </td>
              <td>
                <div className="quantity_btn">
                  <a
                    href="#"
                    className="qty_minus"
                    onClick={() => handleDecrement(i)}
                  >
                    <img src="assets/img/minus-icon.webp" alt="" />
                  </a>
                  <input
                    type="text"
                    className="qty_count"
                    value={receivedQuantities[i] || 1}
                    readOnly
                  />
                  <a
                    href="#"
                    className="qty_plus"
                    onClick={() => handleIncrement(i)}
                  >
                    <img src="assets/img/plus-icon.webp" alt="" />
                  </a>
                </div>
              </td>
              {unitCosts[i] ? <td>${unitCosts[i]}</td> : <td>$0.00</td>}
              {unitCosts[i] && receivedQuantities[i] ? (
                <td>
                  {calculateDiscountedTotal(
                    unitCosts[i],
                    receivedQuantities[i],
                    discounts[i] || 0
                  )}
                </td>
              ) : (
                <td>$ 0.00</td>
              )}
              <td>
                <div className="action_btn">
                  <a
                    href="#"
                    data-toggle="modal"
                    data-target="#editinfoModal"
                    className="edit_btn"
                    onClick={() => handleEdit(i)}
                  >
                    <img src="assets/img/edit-icon.webp" alt="" />
                  </a>
                  <a
                    href="#"
                    className="delete_btn"
                    onClick={
                      isRetrieve
                        ? () => handleDeleteRetrieve(i)
                        : () => handleDelete(i)
                    }
                  >
                    <img src="assets/img/delete-icon.webp" alt="" />
                  </a>
                </div>
              </td>
            </tr>
          ))
        ) : (
          <tr>
            <td className="itemnocstm">
              <span className="item_no">#{index + 1}</span>
            </td>
            <td>
              <Link to="/editproduct" state={{ data: item }}>
                <a href="#">
                  {item?.title} <span>({item?.skuCode})</span>
                </a>
              </Link>
            </td>
            <td
              className={item?.inventory <= 0 ? "red-text" : ""}
              style={{ color: item?.inventory <= 0 ? "red" : "" }}
            >
              {item?.inventory}
            </td>
            <td>
              <div className="quantity_btn">
                <a
                  href="#"
                  className="qty_minus"
                  onClick={() => handleDecrement(index)}
                >
                  <img src="assets/img/minus-icon.webp" alt="" />
                </a>
                <input
                  type="text"
                  className="qty_count"
                  value={receivedQuantities[index] || 1}
                  readOnly
                />
                {/* {item?.productCategoryId === 2 &&
                item?.imeiNumber !== "" &&
                item?.imeiNumberArr !== null ? (
                  <a
                    href="#"
                    className="qty_plus"
                    // onClick={() => handleIncrement(index)}
                  >
                    <img src="assets/img/plus-icon.webp" alt="" />
                  </a>
                ) : (
                  <a
                    href="#"
                    className="qty_plus"
                    onClick={() => handleIncrement(index)}
                    style={{
                      pointerEvents:
                        item?.productCategoryId === 2 &&
                        item?.imeiNumber !== "" &&
                        item?.imeiNumberArr !== null
                          ? "none"
                          : "auto",
                      opacity:
                        item?.productCategoryId === 2 &&
                        item?.imeiNumber !== "" &&
                        item?.imeiNumberArr !== null
                          ? 0.5
                          : 1,
                    }}
                  >
                    <img src="assets/img/plus-icon.webp" alt="" />
                  </a>
                )} */}
                <a
                  href="#"
                  className="qty_plus"
                  onClick={() => handleIncrement(index)}
                  style={{
                    pointerEvents:
                      item?.productCategoryId === 2 &&
                      item?.imeiNumber !== "" &&
                      item?.imeiNumberArr !== null
                        ? "none"
                        : "auto",
                    opacity:
                      item?.productCategoryId === 2 &&
                      item?.imeiNumber !== "" &&
                      item?.imeiNumberArr !== null
                        ? 0.5
                        : 1,
                  }}
                >
                  <img src="assets/img/plus-icon.webp" alt="" />
                </a>
              </div>
            </td>
            {unitCosts[index] ? <td>${unitCosts[index]}</td> : <td>$0.00</td>}
            {unitCosts[index] && receivedQuantities[index] ? (
              <td>
                {calculateDiscountedTotal(
                  unitCosts[index],
                  receivedQuantities[index],
                  discounts[index] || 0
                )}
              </td>
            ) : (
              <td>$ 0.00</td>
            )}
            <td>
              <div className="action_btn">
                <a
                  href="#"
                  data-toggle="modal"
                  data-target="#editinfoModal"
                  className="edit_btn"
                  onClick={() => handleEdit(index)}
                  style={{
                    pointerEvents:
                      item?.productCategoryId === 2 &&
                      item?.imeiNumber !== "" &&
                      item?.imeiNumberArr !== null
                        ? "none"
                        : "auto",
                    opacity:
                      item?.productCategoryId === 2 &&
                      item?.imeiNumber !== "" &&
                      item?.imeiNumberArr !== null
                        ? 0.5
                        : 1,
                  }}
                >
                  <img src="assets/img/edit-icon.webp" alt="" />
                </a>

                <a
                  href="#"
                  className="delete_btn"
                  onClick={
                    isRetrieve
                      ? () => handleDeleteRetrieve(index)
                      : () => handleDelete(index)
                  }
                >
                  <img src="assets/img/delete-icon.webp" alt="" />
                </a>
              </div>
            </td>
          </tr>
        )}
      </React.Fragment>
    ));
  };

  // const renderProductRows = (products, isRetrieve = false) => {
  //   return products.flatMap((product, productIndex) =>
  //     product.itemIds.map((itemId, itemIdIndex) => {
  //       // Find the corresponding item in the items array
  //       const correspondingItem = product.items.find(
  //         (item) => item.itemId === itemId.id
  //       );

  //       // If no corresponding item is found, skip rendering this row
  //       if (!correspondingItem) return null;

  //       return (
  //         <tr
  //           key={`${
  //             isRetrieve ? "retrieve" : "product"
  //           }_${productIndex}_${itemIdIndex}`}
  //         >
  //           <td className="itemnocstm">
  //             <span className="item_no">#{productIndex + 1}</span>
  //           </td>
  //           <td>
  //             <Link state={{ data: itemId }}>
  //               <a href="#">
  //                 {itemId?.title}
  //                 <span>({itemId?.skuCode})</span>
  //               </a>
  //             </Link>
  //           </td>
  //           <td
  //             className={itemId?.inventory <= 0 ? "red-text" : ""}
  //             style={{ color: itemId?.inventory <= 0 ? "red" : "" }}
  //           >
  //             {itemId?.inventory}
  //           </td>
  //           <td>
  //             <div className="quantity_btn">
  //               <a
  //                 href="#"
  //                 className="qty_minus"
  //                 onClick={() =>
  //                   handleDecrement(productIndex, itemIdIndex, isRetrieve)
  //                 }
  //               >
  //                 <img src="assets/img/minus-icon.webp" alt="" />
  //               </a>
  //               <input
  //                 type="text"
  //                 className="qty_count"
  //                 value={correspondingItem.quantity || 1}
  //                 readOnly
  //               />
  //               <a
  //                 href="#"
  //                 className="qty_plus"
  //                 onClick={() =>
  //                   handleIncrement(productIndex, itemIdIndex, isRetrieve)
  //                 }
  //               >
  //                 <img src="assets/img/plus-icon.webp" alt="" />
  //               </a>
  //             </div>
  //           </td>
  //           <td>${correspondingItem.unitCost || 0.0}</td>
  //           <td>
  //             {correspondingItem.unitCost && correspondingItem.quantity
  //               ? calculateDiscountedTotal(
  //                   correspondingItem.unitCost,
  //                   correspondingItem.quantity,
  //                   correspondingItem.discount || 0
  //                 )
  //               : "$0.00"}
  //           </td>
  //           <td>
  //             <div className="action_btn">
  //               <a
  //                 href="#"
  //                 data-toggle="modal"
  //                 data-target="#editinfoModal"
  //                 className="edit_btn"
  //                 onClick={() =>
  //                   handleEdit(productIndex, itemIdIndex, isRetrieve)
  //                 }
  //               >
  //                 <img src="assets/img/edit-icon.webp" alt="" />
  //               </a>
  //               <a
  //                 href="#"
  //                 className="delete_btn"
  //                 onClick={
  //                   isRetrieve
  //                     ? () => handleDeleteRetrieve(productIndex, itemIdIndex)
  //                     : () => handleDelete(productIndex, itemIdIndex)
  //                 }
  //               >
  //                 <img src="assets/img/delete-icon.webp" alt="" />
  //               </a>
  //             </div>
  //           </td>
  //         </tr>
  //       );
  //     })
  //   );
  // };

  const GetPaymentOptions = () => {
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(GettAllPaymentApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        const allOptions = result?.data.reverse();

        // Find default payment options
        const defaultOptions = allOptions.filter(
          (option) => option.defaultPaymentOption === 1
        );

        // Set the default options as selected
        setSelectedCategory2(defaultOptions);
        setSelectedOptions(
          defaultOptions.length > 0 ? defaultOptions[0].name : ""
        );

        // Combine default options with the full payment list to show them in the dropdown
        setAllPaymentOptions(allOptions);

        setSelectedPaymentTypeId(defaultOptions.map((option) => option.id));
        setSelectedPaymentType(defaultOptions.map((option) => option.name));
      })

      //   const allOptions = result?.data.reverse();

      //   // Filter out the default payment option and set selected category options
      //   const filteredOptions = allOptions.filter(
      //     (option) => option.defaultPaymentOption === 1
      //   );
      //   setSelectedCategory2(filteredOptions);

      //   // Set the selected options, payment type IDs, and payment types
      //   setSelectedOptions(filteredOptions.map((option) => option.name));
      //   setSelectedPaymentTypeId(filteredOptions.map((option) => option.id));
      //   setSelectedPaymentType(filteredOptions.map((option) => option.name));

      //   // Filter out options that are either the default payment option or in the selected category
      //   const filteredPaymentOptions = allOptions.filter((option) => {
      //     const isDefaultPaymentOption =
      //       option.name === filteredOptions.map((option) => option.name);
      //     const isSelectedCategoryOption = filteredOptions.some(
      //       (selectedItem) => selectedItem.name === option.name
      //     );
      //     return !isDefaultPaymentOption && !isSelectedCategoryOption;
      //   });

      //   // Set all payment options to the filtered list
      //   setAllPaymentOptions(filteredPaymentOptions);
      //   // setAllPaymentOptions(result?.data.reverse());

      //   // const filteredOptions = result?.data.filter(
      //   //   (option) => option.defaultPaymentOption === 1
      //   // );
      //   // setSelectedCategory2(filteredOptions);
      //   // setSelectedOptions(filteredOptions.map((option) => option?.name));
      //   // setSelectedPaymentTypeId(filteredOptions.map((option) => option?.id));
      //   // setSelectedPaymentType(filteredOptions.map((option) => option?.name));
      // })
      .catch((error) => {
        console.error(error);
      });
  };
  // const handleBrandChange = (value) => {
  //   setSelectedCategory2([value]);
  //   setSelectedPaymentTypeId(value?.id);
  //   setSelectedPaymentType(value?.name);
  // };

  const handleBrandChange = (value) => {
    setSelectedCategory2([value]);
    setSelectedPaymentTypeId(value?.id);
    setSelectedPaymentType(value?.name);
    setIsPaymentOptionSelected(true);
  };
  const [defaultDiscount, setDefaultDiscount] = useState(null);

  const GetAllDiscount = () => {
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(GetAllDiscountApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setAllDiscount(result?.data);
        const defaultTaxItem = result?.data?.find(
          (item) => item?.defaultTax === 1
        );
        setDefaultDiscount(defaultTaxItem || null);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const GetAllTax = () => {
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(GetAllTaxApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // setAllTax(result?.data);
        // setAllTax(result?.data || []);
        const defaultTaxItem = result?.data?.find(
          (item) => item?.defaultTax === 1
        );
        if (defaultTaxItem) {
          setTax(defaultTaxItem.rate);
        } else {
          setTax(0);
        }
        setDefaultTax(defaultTaxItem || null);
        // Filter out the default tax item from the list
        const filteredTaxItems = result?.data.filter(
          (item) => item?.defaultTax !== 1
        );
        setAllTax(filteredTaxItems);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const [showlist, setShowlist] = useState(false);

  const GetPrductbyCategory = (id) => {
    setLoading2(true);
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);
    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${baseurl}/getItemByCategory/${id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log("setProductByCategory", result);
        //setLayoutList(result?.data?.layout);
        if (result?.status === true) {
          setProductByCategory(result?.data);
          setLoading2(false);
        } else {
          // console.log(result?.message);
          setLoading2(false);
        }
      })
      .catch((error) => {
        console.error(error);
        setLoading2(false);
      });
  };
  const [showModal10, setShowModal10] = useState(false);
  const [categortProduct, setCategoryProduct] = useState({});
  const [isPaymentOptionSelected, setIsPaymentOptionSelected] = useState(false);

  const handleClick = (item) => {
    handleCustomerSelectionProduct(item?.item);
  };

  const handleCategoryClick = async (e, item) => {
    e.preventDefault(); // Prevent the default anchor link behavior

    // Clear the current product list
    setProductByCategory([]);

    // Fetch the new product list
    await GetPrductbyCategory(item?.itemId);

    // Set the category product and open the modal
    setCategoryProduct(item);
    setShowModal10(true);
  };

  const GetInvoiceId = (id) => {
    // setLoading(true);
    let local = localStorage.getItem("token");
    //console.log(local);
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${baseurl}getInvoiceById/${id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true) {
          setInVoiceData1(result?.data[0]);

          setPayment(result?.data[0]?.payment);
          setItems(result?.data[0]?.items);
          setItemData(result?.data[0]?.itemIds);
          // setLoading(false);
        } else {
          //setLoading(false);
        }
      })
      .catch((error) => {
        console.error(error);
        // setLoading(false);
      });
  };

  const EmailSend = () => {
    setLoading(true);
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      name: selectedCustomer?.firstName,
      email: selectedCustomer?.email,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${baseurl}sendInvoiceMail/${invoiceId1}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === true) {
          setLoading(false);
          setSelectedProduct([]);
          setSelectedRetrieveProduct([]);
          setPaymentMode([]);
          setPaymnetState(false);
          setSelectedCustomer({});
          setShowModal9(false);
          setSelectedState(false);
          setDiscounts([]);
          updateTotalSalesQuantity();
          setTotalSalesQty(0);
          setReceivedQuantities([]);
          // setSelectedOptions("");
          // setSelectedPaymentType("");
          // setSelectedPaymentTypeId("");
          // setSelectedCategory2("");
          setDiscount(0);
          setUnitCosts([]);
          // Swal.fire("Receipt sent to email successfully! ");
          Swal.fire({
            title: "Receipt sent to email successfully!!",
            customClass: {
              container: "custom-swal-container",

              confirmButton: "custom-swal-confirm-button",
            },
          });
        } else {
          setLoading(false);
          Swal.fire({
            title: result.message,
            customClass: {
              container: "custom-swal-container",

              confirmButton: "custom-swal-confirm-button",
            },
          });
        }
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };

  const EmailSend1 = () => {
    setLoading(true);
    document
      .querySelectorAll(".modal-backdrop")
      .forEach((el) => el.classList.remove("modal-backdrop"));

    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      name: selectedCustomer?.firstName ? selectedCustomer?.firstName : "user",
      email: email,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${baseurl}sendInvoiceMail/${invoiceId1}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === true) {
          setLoading(false);
          // Swal.fire("Email Receipt Successfully! ");
          Swal.fire({
            title: "Receipt sent to email successfully!!",
            customClass: {
              container: "custom-swal-container",
              confirmButton: "custom-swal-confirm-button",
            },
          });
          setShowModal11(false);

          setSelectedProduct([]);
          setSelectedRetrieveProduct([]);
          setPaymentMode([]);
          setPaymnetState(false);
          setSelectedCustomer({});
          setShowModal9(false);
          setSelectedState(false);
          setDiscounts([]);
          updateTotalSalesQuantity();
          setTotalSalesQty(0);
          setReceivedQuantities([]);
          // setSelectedOptions("");
          // setSelectedPaymentType("");
          // setSelectedPaymentTypeId("");
          // setSelectedCategory2("");
          setDiscount(0);
          setUnitCosts([]);
          document
            .querySelectorAll(".modal-backdrop")
            .forEach((el) => el.classList.remove("modal-backdrop"));
          setEmail("");
        } else {
          setLoading(false);
          Swal.fire({
            title: result.message,
            customClass: {
              container: "custom-swal-container",
              confirmButton: "custom-swal-confirm-button",
            },
          });
          setEmail("");
          setShowModal11(false);
          document
            .querySelectorAll(".modal-backdrop")
            .forEach((el) => el.classList.remove("modal-backdrop"));
        }
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };

  const OnSubmit = () => {
    setError(ValidationEmail());
  };

  const isDisposableEmail = (email) => {
    const domain = email.split("@")[1];
    return disposableEmailDomains.includes(domain);
  };

  const disposableEmailDomains = [
    // "gmail.com",
    // "yahoo.com",
    // "outlook.com",
    // "hotmail.com",
    // "aol.com",
    // "icloud.com",
    // "mail.com",
    // "yandex.com",
    // "protonmail.com",
    // "zoho.com",
    // "gmx.com",
    // "ymail.com",
    // "comcast.net",
    // "me.com",
    // "msn.com",
    // "live.com",
    // "sbcglobal.net",
    // "verizon.net",
    // "att.net",
    // "cox.net",
    // "smartitventures.com",
    // Add more domains as needed

    "mailinator.com",
    "guerrillamail.com",
    "10minutemail.com",
    "temp-mail.org",
    "yopmail.com",
    "getnada.com",
    "trashmail.com",
    "fakeinbox.com",
    "throwawaymail.com",
    "maildrop.cc",
    "mytemp.email",
    "tempmailo.com",
    "dispostable.com",
    "mintemail.com",
    "airmail.com",
  ];

  const ValidationEmail = () => {
    let error = {};
    let reg = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    // let reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

    if (!email) {
      error.email = "Email is required.";
    } else if (!reg.test(email)) {
      error.email = "Invalid email .";
    } else if (isDisposableEmail(email)) {
      error.email =
        "Please use a valid email address. Temporary email addresses are not allowed.";
    }
    if (Object.keys(error).length === 0) {
      EmailSend1();
    }
    return error;
  };

  const printSetup = () => {
    Swal.fire("You have not  Setup Receipt Configuration ");

    setSelectedProduct([]);
    setSelectedRetrieveProduct([]);
    setPaymentMode([]);
    setPaymnetState(false);
    setSelectedCustomer({});
    setShowModal9(false);
    setSelectedState(false);
    setDiscounts([]);
    updateTotalSalesQuantity();
    setTotalSalesQty(0);
    setReceivedQuantities([]);
    // setSelectedOptions("");
    // setSelectedPaymentType("");
    // setSelectedPaymentTypeId("");
    // setSelectedCategory2("");
    setDiscount(0);
    setUnitCosts([]);
    updateTotalSalesQuantity();
    setTotalSalesQty(0);
    document
      .querySelectorAll(".modal-backdrop")
      .forEach((el) => el.classList.remove("modal-backdrop"));
  };
  const printTable = () => {
    const printContents = document.querySelector(".reciepttable").innerHTML;
    const originalContents = document.body.innerHTML;
    // const printWindow = window.open("", "_blank");
    const width = 800;
    const height = 600;
    const left = (window.innerWidth - width) / 2;
    const top = (window.innerHeight - height) / 2;

    // Open a new window with centered position
    const printWindow = window.open(
      "",
      "_blank",
      `width=${width},height=${height},top=${top},left=${left}`
    );
    if (!printWindow) {
      Swal.fire(
        "Unable to open print window. Please check your pop-up settings."
      );
      return;
    }

    printWindow.document.open();
    printWindow.document.write(`
    <html>
     <head>
     <style>
     @media print {
  @page {
    margin: 0; /* Remove default margins to ensure there's no space at the top and bottom */
  }
  body {
    /* Remove margins on body as well */
    margin-left: ${leftMargin}px;
    margin-right: ${rightMargin}px;
     height:auto
  }
  /* Hide browser print headers and footers */
  header,
  footer {
    display: none;
  }
}
     </style>

     </head>
      <body>${printContents}</body>
    </html>
  `);
    printWindow.document.close();
    setTimeout(() => {
      printWindow.print();
      printWindow.close();
    }, 200); // Adjust the delay as needed
    // printWindow.print();
    // printWindow.close();
    setSelectedProduct([]);
    setSelectedRetrieveProduct([]);
    setPaymentMode([]);
    setPaymnetState(false);
    setSelectedCustomer({});
    setShowModal9(false);
    setSelectedState(false);
    setDiscounts([]);
    updateTotalSalesQuantity();
    setTotalSalesQty(0);
    setReceivedQuantities([]);
    // setSelectedOptions("");
    // setSelectedPaymentType("");
    // setSelectedPaymentTypeId("");
    // setSelectedCategory2("");
    setDiscount(0);
    setUnitCosts([]);
    updateTotalSalesQuantity();
    setTotalSalesQty(0);
    document
      .querySelectorAll(".modal-backdrop")
      .forEach((el) => el.classList.remove("modal-backdrop"));
  };

  const [gstNumber, setSGTNumber] = useState("");
  const GetReciept = () => {
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(GetRecieptApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === true) {
          if (!result.data) {
            setReciept(true);
          } else {
            setStates(true);
            const data = result?.data;
            setRecieptId(data?.id);
            setPrintType(data.type);
            setTitle(data.receiptTitle);
            setLogo(data?.logo);
            setIsLogo(data.isLogo === 1);
            setLogoSize(data.logoSize);
            setLogoPosition(data.logoPosition);
            setHeader(data.headerInfo);
            setDisplaySales(data.displaySalesPersonName === 1);
            setFooter(data.footerInfo);
            setNote1(data.displayPublicNote === 1);
            setDisplayBarcode(data.displayBarcode === 1);
            setDisplayNote(data.note === 1);
            setImageMsg("");
            setStates(data.activeInactive === 1);
            setSGTNumber(data?.gst);
            setLeftMargin(data?.leftMargin || "10mm");
            setRightMargin(data?.rightMargin || "10mm");
            setGstShow(data?.gstButton);
            setSelectedOption({
              prodname: data.items.name === 1 ? 1 : 0,
              prodname_sku:
                data.items.name === 1 && data.items.sku === 1 ? 1 : 0,
              sku: data.items.sku === 1 ? 1 : 0,
            });

            // Set customer details based on API response
            setCustomerDetails1({
              fullname: data.customer.name === 1 ? 1 : 0,
              email: data.customer.email === 1 ? 1 : 0,
              phone: data.customer.phoneNumber === 1 ? 1 : 0,
              address: data.customer.address === 1 ? 1 : 0,
            });
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const GetProfile = () => {
    let local = localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(getProfileApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true) {
          setData1(result?.data);
          // setStatus(
          //   result?.data?.comapany?.subscriptionStatus === "canceled" ||
          //     result?.data?.comapany?.subscriptionStatus === null ||
          //     result?.data?.comapany?.subscriptionStatus === "expired"
          // );

          // if (
          //   result?.data?.comapany?.subscriptionStatus === "canceled" ||
          //   result?.data?.comapany?.subscriptionStatus === null
          // ) {
          //   navigate("/settings", { state: { activeState: 1 } });
          // } else if (
          //   result?.data?.comapany?.subscriptionStatus === "complete"
          // ) {
          //   setSub(2);
          // } else {
          //   setSub(1);
          // }
        } else {
          console.log(result.message);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const GetSubUserProfile = () => {
    let local = localStorage.getItem("token");
    //console.log(local);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(GetSubUserProfileApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        //console.log("subuser", result);
        if (result.status === true) {
          setDataUser(result?.data);
        } else {
          console.log(result.message);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const handleDeletePayment = (index) => {
    setPaymentMode((prevPaymentMode) => {
      const updatedPaymentMode = [...prevPaymentMode];
      const [removedPayment] = updatedPaymentMode.splice(index, 1);
      updatePaymentTotals(removedPayment.payAmount);
      return updatedPaymentMode;
    });
  };

  const updatePaymentTotals = (amount) => {
    setTotalPayment((prevTotalPayment) => prevTotalPayment - amount);
    setRemainingAmount((prevRemainingAmount) => {
      const newRemainingAmount = prevRemainingAmount + amount;
      setInputValue(newRemainingAmount.toFixed(2));
      return newRemainingAmount;
    });
  };

  const [selectedOptions, setSelectedOptions] = useState("");

  // const handleOptionChange = (e) => {
  //   const selectedValue = e.target.value;
  //   setSelectedOptions(selectedValue);
  //   const selectedPayment = allPaymentOptions.find(
  //     (item) => item.name === selectedValue
  //   );
  //   handleBrandChange(selectedPayment);
  // };

  const handleOptionChange = (e) => {
    const selectedValue = e.target.value;
    setSelectedOptions(selectedValue);

    const selectedPayment = allPaymentOptions.find(
      (item) => item.name === selectedValue
    );
    handleBrandChange(selectedPayment);
  };

  const handlePaymentClick = () => {
    if (selectedOptions.length === 0 || selectedPaymentType === "") {
      Swal.fire("Please select a payment option");
    } else if (selectedProduct.length > 0) {
      handlePay();
    } else {
      handlePayRet();
    }
  };
  const [isFreeTrial, setIsFreeTrial] = useState(false);

  useEffect(() => {
    const substatus = localStorage.getItem("substatus");
    const planDate = localStorage.getItem("planDate");

    if (
      substatus === "null" ||
      substatus === "canceled" ||
      substatus === "expired"
    ) {
      setIsFreeTrial(true);
    }
  }, []);

  const [isSidebarVisible, setSidebarVisible] = useState(false);

  const toggleSidebar = () => {
    setSidebarVisible(!isSidebarVisible);
    localStorage.setItem("sidebar", !isSidebarVisible);
  };

  useEffect(() => {
    const savedSidebarState = localStorage.getItem("sidebar");
    if (savedSidebarState) {
      setSidebarVisible(JSON.parse(savedSidebarState));
    }
  }, []);
  const [isFreeTrialVisible, setFreeTrialVisible] = useState(true);

  const toggleFreeTrial = () => {
    setFreeTrialVisible(!isFreeTrialVisible);
  };

  const sidebarStyleone = {
    overflow: "auto",
    scrollbarWidth: "none",
    msOverflowStyle: "none",
    // position: 'sticky',

    marginTop: isFreeTrialVisible ? "0px" : "0px",
  };

  const navbarStyle = {
    // top: isFreeTrialVisible ? "" : "px",
  };
  const mainContentStyle = {
    // marginTop: isFreeTrialVisible ? "132px" : "92px",
  };

  useEffect(() => {
    if (isFreeTrial) {
      // toggleSidebar(false);
      toggleFreeTrial(false);
    } else {
      // toggleSidebar(true);
      toggleFreeTrial(true);
    }
  }, [isFreeTrial]);

  const HandleRefresh = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to reset transactions",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#c76cff",
      cancelButtonColor: "#efefef",
      confirmButtonText: "Yes, reset it!",
      confirmButtonText: "<span style='color: white;'>Yes, reset it!</span>",
      cancelButtonText: "<span style='color: black;'>cancel!</span>",
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      customClass: {
        container: "sweet-alert2-container-zindex",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Deleted!",
          text: "Your data has been deleted.",
          icon: "success",
          confirmButtonColor: "#c76cff",
          customClass: {
            container: "sweet-alert2-container-zindex",
          },
        }).then(() => {
          setSelectedProduct([]);
          setSelectedRetrieveProduct([]);
          window.location.reload();
          // UpdateCustomer(id);
        });
      }
    });
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      const sidebarElement = document.querySelector(".main-sidebar");
      const toggleButtonElement = document.querySelector(".sidebar-arrow a");

      if (
        sidebarElement &&
        !sidebarElement.contains(event.target) &&
        toggleButtonElement &&
        !toggleButtonElement.contains(event.target)
      ) {
        const mediaQuery = window.matchMedia("(max-width: 1024px)");
        if (mediaQuery.matches) {
          setSidebarVisible(false);
        }
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  return (
    <>
      <div className={isSidebarVisible ? "sidebar-mini" : ""}>
        <div
          className={`${
            isFreeTrial
              ? "main-wrapper devicelistcstmtableres customclswrapper"
              : "main-wrapper devicelistcstmtableres "
          }`}
          // ref={sidebarRef}
        >
          <Helmet>
            <title>Efficient Cash Management | Toolbox Register</title>
            <meta
              name="description"
              content="Track sales and manage cash seamlessly with our Cash Register tool. Stay organized and in control of your financial transactions."
            />
          </Helmet>
          {loading ? (
            <div className="landing_loader">
              <div className="innerloader">
                <img src="img/spin-1s-156px.gif" alt="" />
              </div>
            </div>
          ) : null}
          {isFreeTrial ? (
            <>
              {localStorage.getItem("substatus") === "null" ||
              localStorage.getItem("substatus") === "canceled" ||
              localStorage.getItem("substatus") === "expired" ? (
                <>
                  <div className="app-container-trial">
                    <FreeTrail />
                  </div>

                  <div style={sidebarStyleone}>
                    <nav className="navbar navbar-expand-lg main-navbar sticky">
                      <div className="sidebar-arrow">
                        <a
                          href="#"
                          data-toggle="sidebar"
                          onClick={(e) => {
                            toggleSidebar();

                            e.preventDefault();
                          }}
                        >
                          <img src="assets/img/sidebar-toggle.webp" alt="" />
                        </a>
                      </div>
                      <div className="header-title mr-auto">
                        <h4>Cash Register</h4>
                      </div>
                      <SideUl />
                    </nav>
                    <MainSidebar />
                    <div className="main-content" style={mainContentStyle}>
                      <div className="row">
                        <div className="col-lg-5 col-md-12">
                          <div className="search_btn">
                            <div className="search_field">
                              <div
                                className="top_cus_btn srch_customer_btn searchproductdisplycstm"
                                onClick={() => setShowModal2(true)}
                              >
                                <img
                                  className="productimgcustom"
                                  src="assets/img/search-icon.webp"
                                  alt=""
                                />
                                <input
                                  type="text"
                                  className=" form-control inputproductdisplaycst"
                                  placeholder="Search Product"
                                  value={searchTermProduct}
                                  onChange={handleSearchTermChangeProduct}
                                  onClick={() => {
                                    setProductState(!productState);

                                    setSearchTermProduct("");
                                  }}
                                  onKeyDown={handleKeyDown}
                                />

                                {showModal2 === true &&
                                productState === true ? (
                                  <>
                                    <div
                                      className={`searchcustomer_list seacrhproductlistcustm ${
                                        showModal2 ? "active" : ""
                                      }`}
                                    >
                                      <div className="srchcustmer_title common_title">
                                        <h4>Search Product </h4>
                                      </div>

                                      <div className="customer_list">
                                        <ul>
                                          {filteredData1.length ? (
                                            <>
                                              {filteredData1?.map((item) => (
                                                <li key={item.id}>
                                                  <Link
                                                    onClick={() => {
                                                      handleCustomerSelectionProduct(
                                                        item,
                                                        item?.imeiNumber
                                                      );
                                                      setCustomerId(
                                                        item?.userId
                                                      );
                                                    }}
                                                    key={item?.id}
                                                    className="linkcustm"
                                                  >
                                                    <div
                                                      key={item?.id}
                                                      className="custmer_img"
                                                    >
                                                      {item?.image ? (
                                                        <img
                                                          src={item?.image}
                                                          alt=""
                                                        />
                                                      ) : (
                                                        <img
                                                          src="assets/img/customer-info-img.webp"
                                                          alt=""
                                                        />
                                                      )}
                                                    </div>
                                                    <span>
                                                      {item?.title}
                                                      <small>
                                                        {item?.skuCode}
                                                      </small>
                                                      <small
                                                        style={{
                                                          color: "#0000ff",
                                                        }}
                                                      >
                                                        {item?.imeiNumber}
                                                      </small>
                                                    </span>
                                                  </Link>
                                                </li>
                                              ))}
                                            </>
                                          ) : (
                                            <div className="text-center">
                                              <h5>No data available</h5>
                                            </div>
                                          )}
                                        </ul>
                                      </div>
                                    </div>{" "}
                                  </>
                                ) : null}
                              </div>
                            </div>
                            <div className="new_btn cus_btn">
                              <a
                                href=""
                                data-toggle="modal"
                                data-target="#newproductModal"
                                onClick={() => setShowModal3(true)}
                              >
                                <i className="fa fa-plus"></i> New
                              </a>
                            </div>
                          </div>
                          <div className="serv_type">
                            <ul>
                              <li>
                                <a
                                  href=""
                                  data-toggle="modal"
                                  data-target="#newserviceModal"
                                  onClick={() => setShowModal8(true)}
                                >
                                  Repair
                                </a>
                              </li>
                              <li>
                                <a
                                  href=""
                                  data-toggle="modal"
                                  data-target="#newserviceModal"
                                >
                                  --
                                </a>
                              </li>
                              <li>
                                <a
                                  href=""
                                  data-toggle="modal"
                                  data-target="#newserviceModal"
                                >
                                  --
                                </a>
                              </li>
                              <li>
                                <a
                                  href=""
                                  data-toggle="modal"
                                  data-target="#newserviceModal"
                                >
                                  --
                                </a>
                              </li>
                            </ul>
                          </div>
                          <div
                            className="prod_type"
                            style={{
                              maxHeight:
                                layoutList.length > 16 ? "400px" : "auto",
                              overflowY:
                                layoutList.length > 16 ? "auto" : "visible",
                            }}
                          >
                            {layoutValue === 1 ? (
                              <ul>
                                {layoutList.length > 0 ? (
                                  <>
                                    {layoutList.map((item, index) => (
                                      <>
                                        {item?.isCategory === 0 ? (
                                          <li
                                            className="cus_prod_tag"
                                            key={index}
                                            style={{ cursor: "pointer" }}
                                          >
                                            <a
                                              onClick={() => handleClick(item)}
                                            >
                                              <div
                                              // className=" selectcustmproduct"
                                              >
                                                <div
                                                  className=" tag_strip classcolorstyle"
                                                  style={{
                                                    background: item?.colorCode,
                                                  }}
                                                ></div>
                                                <span>{item?.name}</span>
                                              </div>
                                            </a>
                                          </li>
                                        ) : (
                                          <li
                                            className="cus_prod_tag"
                                            key={index}
                                            style={{ cursor: "pointer" }}
                                            // onClick={() => setShowModal10(true)}
                                          >
                                            <a
                                              href="#"
                                              data-toggle="modal"
                                              data-target="#casesModal"
                                              onClick={(e) =>
                                                handleCategoryClick(e, item)
                                              }
                                            >
                                              <div
                                              // className="selectcustmproduct"
                                              >
                                                <div
                                                  className="tag_strip classcolorstyle"
                                                  style={{
                                                    background: item?.colorCode,
                                                  }}
                                                ></div>
                                                <span>{item?.name}</span>
                                              </div>
                                            </a>
                                          </li>
                                        )}
                                      </>
                                    ))}
                                    {/* {layoutList.map((item, index) => (
                          <li
                            key={index}
                            onClick={() => {
                              if (item?.isCategory === 0) {
                                handleCustomerSelectionProduct(item?.item);
                              } else {
                                setShowModal10(true);
                                GetPrductbyCategory(item?.itemId);
                                setCategoryProduct(item);
                                console.log("product", item?.itemId);
                              }
                            }}
                          >
                            {item?.isCategory === 0 ? (
                              <>
                                <a>
                                  <div className="selectcustmproduct">
                                    <div
                                      className="classcolorstyle"
                                      style={{ background: item?.colorCode }}
                                    ></div>
                                    <span>{item?.name}</span>
                                  </div>
                                </a>
                              </>
                            ) : (
                              <>
                                {" "}
                                <a
                                  href=""
                                  data-toggle="modal"
                                  data-target="#casesModal"
                                >
                                  <div className="selectcustmproduct">
                                    <div
                                      className="classcolorstyle"
                                      style={{ background: item?.colorCode }}
                                    ></div>
                                    <span>{item?.name}</span>
                                  </div>
                                </a>
                              </>
                            )}
                          </li>
                        ))} */}
                                  </>
                                ) : (
                                  <div className="cash_register_setup ">
                                    <h4>Set Up Cash Register Layout Keys</h4>
                                    <Link
                                      to="/settings"
                                      state={{ activeState: 6 }}
                                    >
                                      <button className="setup_btn">
                                        Setup
                                      </button>
                                    </Link>
                                  </div>
                                )}
                              </ul>
                            ) : (
                              <div className="cash_register_setup ">
                                <Link to="/settings" state={{ activeState: 6 }}>
                                  <h4>Set Up Cash Register Layout Keys</h4>
                                  <button className="setup_btn">Setup</button>
                                </Link>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-7 col-md-12">
                          <div className="topbar_btns">
                            <div className="top_cus_btn retrieve_btn">
                              <a href="#" onClick={handleRetrieveClick}>
                                Retrieve Sales
                              </a>
                              {isRetrieveListActive && (
                                <>
                                  <div
                                    // className="retrieve_list"
                                    className={`retrieve_list ${
                                      isRetrieveListActive ? "active" : ""
                                    }`}
                                  >
                                    <div className="retrieve_title common_title">
                                      <h4>Retrieve Sales</h4>
                                      <a
                                        className="popup_close"
                                        href="#"
                                        onClick={handlePopupClose}
                                      >
                                        <span className="close_icon"></span>
                                      </a>
                                    </div>
                                    <div className="retre_list">
                                      <ul>
                                        {InVoiceData.map((item) => (
                                          <React.Fragment key={item.id}>
                                            {item.itemIds.map((itemId) => (
                                              <li
                                                key={itemId.id}
                                                onClick={() => {
                                                  handleCustomerSelectionProductPark(
                                                    item,
                                                    item?.items.map(
                                                      (item) =>
                                                        item.imeiNumberArr
                                                    )
                                                  );
                                                }}
                                              >
                                                <span>
                                                  #{itemId.id}
                                                  <small>
                                                    {item.customer?.firstName ||
                                                      "--"}{" "}
                                                    {item.customer?.lastName ||
                                                      "--"}
                                                  </small>
                                                </span>
                                                <a href="#">
                                                  <img
                                                    src="assets/img/arrow-right-icon.svg"
                                                    alt=""
                                                  />
                                                </a>
                                              </li>
                                            ))}
                                          </React.Fragment>
                                        ))}
                                        {/* {InVoiceData.map((item) => (

                              <>
                                <li
                                  onClick={() => {
                                    handleCustomerSelectionProductPark(item);
                                  }}
                                >
                                  <span>
                                    #{item.items.map((ite) => ite?.itemId)}
                                    <small>
                                      {item?.customer?.firstName}
                                      {"  "}
                                      {item?.customer?.lastName}
                                    </small>
                                  </span>
                                  <a href="">
                                    <img
                                      src="assets/img/arrow-right-icon.webp"
                                      alt=""
                                    />
                                  </a>
                                </li>
                              </>
                            ))} */}
                                      </ul>
                                    </div>
                                  </div>
                                </>
                              )}
                            </div>
                            <div
                              className="top_cus_btn parksale_btn"
                              onClick={() => {
                                if (
                                  !selectedProduct ||
                                  selectedProduct?.length === 0
                                ) {
                                  Swal.fire("First select a Product");
                                } else {
                                  setShowModal7(true);
                                }
                              }}
                            >
                              <a
                                href="#"
                                data-toggle="modal"
                                data-target="#parksaleModal"
                              >
                                Park Sales
                              </a>
                            </div>
                            <div className="top_cus_btn moreaction_btn">
                              <a href="#">More Actions</a>
                              <div className="moreaction_list">
                                <div className="moreact_list">
                                  <ul>
                                    <li>
                                      <span>Create Quote</span>
                                      <a href="">
                                        <img
                                          src="assets/img/arrow-right-icon.svg"
                                          alt=""
                                        />
                                      </a>
                                    </li>
                                    <li>
                                      <span>Acquisition</span>
                                      <a href="">
                                        <img
                                          src="assets/img/arrow-right-icon.svg"
                                          alt=""
                                        />
                                      </a>
                                    </li>
                                    <li>
                                      <span>Discard Sale</span>
                                      <a href="">
                                        <img
                                          src="assets/img/arrow-right-icon.svg"
                                          alt=""
                                        />
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                            {selectedState === true ? (
                              <>
                                <div className="top_cus_btn edit_customer_btn customercustmeditcss customerserachboxcash">
                                  <span className="cuslabel">Customer:</span>
                                  <div className="customer_info">
                                    <div className="info_label">
                                      <h5>{selectedCustomer?.firstName}</h5>
                                      <p>
                                        <span>{selectedCustomer?.email}</span>
                                        {""}
                                        <span>
                                          {selectedCustomer?.phoneNumber}
                                        </span>
                                      </p>
                                    </div>
                                    <div className="info_edit">
                                      <Link
                                        to="/CustomerDetail"
                                        state={{ data: selectedCustomer }}
                                      >
                                        <img
                                          src="assets/img/cust-edit-icon.webp"
                                          alt=""
                                        />
                                      </Link>
                                    </div>
                                  </div>
                                </div>

                                <div
                                  className="top_cus_btn delete_customer_btn"
                                  onClick={() => {
                                    setSearchTerm("");
                                    setSelectedState(false);
                                    setShowModal1(false);
                                  }}
                                >
                                  <a href="#">
                                    <img
                                      src="assets/img/cust-trash-icon.webp"
                                      alt=""
                                    />
                                  </a>
                                </div>
                              </>
                            ) : (
                              <>
                                <div className="top_cus_btn srch_customer_btn customerserachboxcash">
                                  <a
                                    href="#"
                                    onClick={() => setShowModal1(true)}
                                  >
                                    <img
                                      src="assets/img/search-icon.webp"
                                      alt=""
                                    />
                                    <input
                                      type="text"
                                      placeholder="Search Customer"
                                      value={searchTerm}
                                      onChange={handleSearchTermChange}
                                      className="inputdeviceformcustmbox"
                                    />
                                  </a>

                                  <div
                                    className={`searchcustomer_list ${
                                      showModal1 ? "active" : ""
                                    }`}
                                  >
                                    <div className="srchcustmer_title common_title">
                                      <h4>
                                        Search Customer{" "}
                                        <a
                                          href="#"
                                          data-toggle="modal"
                                          data-target="#addcustomerModal"
                                          onClick={() => setShowModal(true)}
                                        >
                                          Add New
                                        </a>
                                      </h4>
                                      <a
                                        className="popup_close"
                                        onClick={handlePopupCloseCustomer}
                                      >
                                        <span className="close_icon"></span>
                                      </a>
                                    </div>
                                    <div className="customer_list">
                                      <ul>
                                        {filteredData ? (
                                          <>
                                            {filteredData?.length ? (
                                              <>
                                                {filteredData?.map((item) => (
                                                  <li key={item.id}>
                                                    <Link
                                                      onClick={() => {
                                                        handleCustomerSelection(
                                                          item
                                                        );
                                                        setSelectedState(true);
                                                      }}
                                                      key={item.id}
                                                      className="linkcustm"
                                                    >
                                                      <div
                                                        key={item.id}
                                                        className="custmer_img"
                                                      >
                                                        {item?.profileImage ? (
                                                          <img
                                                            src={
                                                              item?.profileImage
                                                            }
                                                            alt=""
                                                          />
                                                        ) : (
                                                          <img
                                                            src="assets/img/customer-info-img.webp"
                                                            alt=""
                                                          />
                                                        )}
                                                      </div>
                                                      <span>
                                                        {item?.firstName}{" "}
                                                        {item?.lastName}{" "}
                                                        <small>
                                                          {item?.email}
                                                        </small>
                                                        <small>
                                                          {item?.phoneNumber}
                                                        </small>
                                                      </span>

                                                      <img
                                                        className="customeraddeditcstmimg"
                                                        src="assets/img/arrow-right-icon.svg"
                                                        alt=""
                                                      />
                                                    </Link>
                                                  </li>
                                                ))}
                                              </>
                                            ) : (
                                              <div className="text-center">
                                                <h5>No data available</h5>
                                              </div>
                                            )}
                                          </>
                                        ) : (
                                          <>
                                            {data.length ? (
                                              <>
                                                {data?.map((item) => (
                                                  <li key={item.id}>
                                                    <Link
                                                      onClick={() => {
                                                        handleCustomerSelection(
                                                          item
                                                        );
                                                        setSelectedState(true);
                                                      }}
                                                      key={item.id}
                                                      className="linkcustm"
                                                    >
                                                      <div
                                                        key={item.id}
                                                        className="custmer_img"
                                                      >
                                                        {item.profileImage ? (
                                                          <img
                                                            src={
                                                              item.profileImage
                                                            }
                                                            alt=""
                                                          />
                                                        ) : (
                                                          <img
                                                            src="assets/img/customer-info-img.webp"
                                                            alt=""
                                                          />
                                                        )}
                                                      </div>
                                                      <span>
                                                        {item?.firstName}{" "}
                                                        {item?.lastName}{" "}
                                                        <small>
                                                          {item?.email}
                                                        </small>
                                                        <small>
                                                          {item?.phoneNumber}
                                                        </small>
                                                      </span>

                                                      <img
                                                        className="customeraddeditcstmimg"
                                                        src="assets/img/arrow-right-icon.svg"
                                                        alt=""
                                                      />
                                                    </Link>
                                                  </li>
                                                ))}
                                              </>
                                            ) : (
                                              <div className="text-center">
                                                <h5>No data available</h5>
                                              </div>
                                            )}
                                          </>
                                        )}
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </>
                            )}

                            {selectedState === true ? null : (
                              <>
                                <div
                                  className="top_cus_btn new_customer_btn"
                                  onClick={() => setShowModal(true)}
                                >
                                  <a
                                    href=""
                                    data-toggle="modal"
                                    data-target="#addcustomerModal"
                                  >
                                    <i className="fa fa-plus"></i> New Customer
                                  </a>
                                </div>
                              </>
                            )}
                          </div>
                          <div className="cus_table cash_register_table">
                            <div className="table-responsive">
                              <table className="table">
                                <thead>
                                  <tr>
                                    <th colSpan="2">ITEM(s)</th>
                                    <th>In stock</th>
                                    <th>Qty.</th>
                                    <th>Unit Price</th>
                                    <th>Total Price</th>
                                    <th>Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {selectedProduct.length > 0 ? (
                                    <>
                                      {renderProductRows(selectedProduct, 1)}

                                      {[
                                        ...Array(
                                          Math.max(
                                            3 - selectedProduct.length,
                                            0
                                          )
                                        ),
                                      ].map((_, index) => (
                                        <tr key={`default_${index}`}>
                                          <td className="itemnocstm">
                                            <span className="item_no ">
                                              #
                                              {selectedProduct.length +
                                                index +
                                                1}
                                            </span>
                                          </td>
                                          <td>
                                            <a href="#">
                                              <span></span>
                                            </a>
                                          </td>
                                          <td></td>
                                          <td>
                                            <div className="quantity_btn"></div>
                                          </td>
                                          <td></td>
                                          <td></td>
                                          <td>
                                            <div className="action_btn">
                                              {/* <a
                                                href="#"
                                                data-toggle="modal"
                                                data-target="#editinfoModal"
                                                className="edit_btn"
                                              >
                                                <img
                                                  src="assets/img/edit-icon.webp"
                                                  alt=""
                                                />
                                              </a>
                                              <a
                                                href="#"
                                                className="delete_btn"
                                              >
                                                <img
                                                  src="assets/img/delete-icon.webp"
                                                  alt=""
                                                />
                                              </a> */}
                                            </div>
                                          </td>
                                        </tr>
                                      ))}
                                    </>
                                  ) : retrieveState === true ? (
                                    selectRetrieveProduct.length > 0 ? (
                                      renderProductRows(
                                        selectRetrieveProduct,
                                        1,
                                        true
                                      )
                                    ) : (
                                      <>
                                        {[...Array(3)].map((_, index) => (
                                          <tr key={`default_${index}`}>
                                            <td className="itemnocstm">
                                              <span className="item_no ">
                                                #{index + 1}
                                              </span>
                                            </td>
                                            <td>
                                              <a href="#">
                                                <span></span>
                                              </a>
                                            </td>
                                            <td></td>
                                            <td>
                                              <div className="quantity_btn">
                                                {/* <a
                                                  href="#"
                                                  className="qty_minus"
                                                >
                                                  <img
                                                    src="assets/img/minus-icon.webp"
                                                    alt=""
                                                  />
                                                </a>
                                                <input
                                                  type="text"
                                                  className="qty_count"
                                                  value=""
                                                />
                                                <a
                                                  href="#"
                                                  className="qty_plus"
                                                >
                                                  <img
                                                    src="assets/img/plus-icon.webp"
                                                    alt=""
                                                  />
                                                </a> */}
                                              </div>
                                            </td>
                                            <td></td>
                                            <td></td>
                                            <td>
                                              <div className="action_btn">
                                                {/* <a
                                                  href="#"
                                                  data-toggle="modal"
                                                  data-target="#editinfoModal"
                                                  className="edit_btn"
                                                >
                                                  <img
                                                    src="assets/img/edit-icon.webp"
                                                    alt=""
                                                  />
                                                </a>
                                                <a
                                                  href="#"
                                                  className="delete_btn"
                                                >
                                                  <img
                                                    src="assets/img/delete-icon.webp"
                                                    alt=""
                                                  />
                                                </a> */}
                                              </div>
                                            </td>
                                          </tr>
                                        ))}
                                      </>
                                    )
                                  ) : (
                                    <>
                                      {[...Array(3)].map((_, index) => (
                                        <tr key={`default_${index}`}>
                                          <td className="itemnocstm">
                                            <span className="item_no ">
                                              #{index + 1}
                                            </span>
                                          </td>
                                          <td>
                                            <a href="#">
                                              <span></span>
                                            </a>
                                          </td>
                                          <td></td>
                                          <td>
                                            <div className="quantity_btn">
                                              {/* <a href="#" className="qty_minus">
                                                <img
                                                  src="assets/img/minus-icon.webp"
                                                  alt=""
                                                />
                                              </a>
                                              <input
                                                type="text"
                                                className="qty_count"
                                                value=""
                                              />
                                              <a href="#" className="qty_plus">
                                                <img
                                                  src="assets/img/plus-icon.webp"
                                                  alt=""
                                                />
                                              </a> */}
                                            </div>
                                          </td>
                                          <td></td>
                                          <td></td>
                                          <td>
                                            <div className="action_btn">
                                              {/* <a
                                                href="#"
                                                data-toggle="modal"
                                                data-target="#editinfoModal"
                                                className="edit_btn"
                                              >
                                                <img
                                                  src="assets/img/edit-icon.webp"
                                                  alt=""
                                                />
                                              </a>
                                              <a
                                                href="#"
                                                className="delete_btn"
                                              >
                                                <img
                                                  src="assets/img/delete-icon.webp"
                                                  alt=""
                                                />
                                              </a> */}
                                            </div>
                                          </td>
                                        </tr>
                                      ))}
                                    </>
                                  )}
                                </tbody>

                                <tfoot>
                                  <tr className="price_tr">
                                    <td colSpan="3">Sub Total</td>
                                    <td></td>
                                    {overallTotal ? (
                                      <td colSpan="3">${overallTotal}</td>
                                    ) : (
                                      <td colSpan="3">$0.00</td>
                                    )}
                                  </tr>
                                  <tr className="price_tr">
                                    <td colSpan="3">
                                      <span>Discount:</span>
                                      <select
                                        className="tble_select"
                                        onChange={handleDiscountChange}
                                        value={discount}
                                      >
                                        <option value={0}>0%</option>
                                        {alldiscount?.map((item) => (
                                          <>
                                            {" "}
                                            <option
                                              key={item?.id}
                                              value={item?.rate}
                                            >
                                              {item?.rate}%
                                            </option>
                                          </>
                                        ))}
                                        {/* <option value={0}>0%</option>
                            <option value={10}>10%</option>
                            <option value={20}>20%</option> */}
                                      </select>
                                    </td>
                                    <td></td>
                                    <td colSpan="3">
                                      $
                                      {parseFloat(
                                        overallTotal - discountedSubtotal
                                      ).toFixed(2)}
                                    </td>
                                  </tr>
                                  <tr className="price_tr">
                                    <td colSpan="3">
                                      <span>Tax:</span>
                                      <select
                                        className="tble_select"
                                        onChange={handleTaxChange}
                                        value={tax}
                                      >
                                        {defaultTax ? (
                                          <option value={defaultTax.rate}>
                                            {defaultTax.name} {defaultTax.rate}%
                                          </option>
                                        ) : (
                                          <option value={0}>Tax 0%</option>
                                        )}
                                        {alltax?.map((item) => (
                                          <>
                                            {" "}
                                            <option value={item?.rate}>
                                              {item?.name} {item?.rate}%
                                            </option>
                                          </>
                                        ))}
                                      </select>
                                    </td>
                                    <td></td>
                                    <td colSpan="3">${taxAmount.toFixed(2)}</td>
                                  </tr>
                                  <tr className="grand_total">
                                    <td colSpan="3">Grand Total</td>
                                    <td></td>
                                    {overallTotal1 ? (
                                      <td colSpan="3">
                                        ${overallTotal1.toFixed(2)}
                                      </td>
                                    ) : (
                                      <td colSpan="3">$0.00</td>
                                    )}
                                  </tr>
                                </tfoot>
                              </table>
                            </div>

                            <div className="payment_method">
                              {paymentState === true ? (
                                <>
                                  <div className="payment_mode">
                                    <div className="inner_payment_mode">
                                      <div className="mode_title">
                                        <span>Payment Made</span>
                                      </div>
                                      <div className="mode_table">
                                        <table>
                                          <thead>
                                            <tr>
                                              <th>Time</th>
                                              <th>Type</th>
                                              <th>Amount</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {paymentMode.map(
                                              (payment, index) => (
                                                <>
                                                  <tr key={index}>
                                                    <td>
                                                      {moment(
                                                        new Date()
                                                      ).format(
                                                        "DD/MM/YYYY hh:mm a"
                                                      )}
                                                    </td>
                                                    <td>
                                                      <b>
                                                        {payment.paymentType}
                                                      </b>
                                                    </td>
                                                    <td>
                                                      <b>
                                                        ${payment.payAmount}
                                                      </b>
                                                    </td>
                                                    <td>
                                                      <a
                                                        href="#"
                                                        className="delete_btn"
                                                        onClick={() =>
                                                          handleDeletePayment(
                                                            index
                                                          )
                                                        }
                                                      >
                                                        <img
                                                          src="assets/img/delete-icon.webp"
                                                          alt=""
                                                        />
                                                      </a>
                                                    </td>
                                                  </tr>
                                                </>
                                              )
                                            )}
                                            <tr>
                                              <td colSpan={3}>Total</td>
                                              <td>
                                                ${totalPayment.toFixed(2)}
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                    <div className="pending_amt">
                                      <span>
                                        (Due: ${remainingAmount.toFixed(2)})
                                      </span>
                                    </div>
                                  </div>
                                </>
                              ) : null}

                              <div className="inner_payment_method">
                                <div className="cash_dropdown payment_col">
                                  <select
                                    className="form-control cashpaymentcustmcss"
                                    value={selectedOptions}
                                    onChange={handleOptionChange}
                                  >
                                    {allPaymentOptions.length > 0 ? (
                                      allPaymentOptions.map((item) => (
                                        <option key={item.id} value={item.name}>
                                          {item.name}
                                        </option>
                                      ))
                                    ) : (
                                      <option value="">Payment Options</option>
                                    )}
                                  </select>
                                  {/* <select
                                    className="form-control cashpaymentcustmcss"
                                    value={selectedOptions}
                                    onChange={handleOptionChange}
                                  >
                                    {selectedCategory2.length > 0 ? (
                                      selectedCategory2?.map((item, index) => (
                                        <option
                                          key={item?.id}
                                          value={item?.name}
                                        >
                                          {item?.name}
                                        </option>
                                      ))
                                    ) : (
                                      <option value="">Payment Options</option>
                                    )}
                                    {allPaymentOptions.map((item) => (
                                      <option key={item.id} value={item.name}>
                                        {item.name}
                                      </option>
                                    ))}
                                  </select> */}
                                  <span className="angle_down">
                                    <img
                                      src="assets/img/angledown-icon.webp"
                                      alt=""
                                    />
                                  </span>
                                </div>

                                <input
                                  type="number"
                                  value={inputValue}
                                  className="price_value payment_col no-arrow-input"
                                  style={{
                                    fontWeight: 700,
                                    color: "black",
                                    fontSize: 18,
                                  }}
                                  onChange={(e) => {
                                    setInputValue(e.target.value);
                                  }}
                                />

                                {(inputValue !== 0 &&
                                  selectedProduct.length > 0) ||
                                selectRetrieveProduct.length > 0 ? (
                                  <div className="pay_btn payment_col">
                                    <a
                                      onClick={handlePaymentClick}
                                      style={{ cursor: "pointer" }}
                                    >
                                      Pay ${inputValue}
                                      {/* <a
                          onClick={() => {
                            if (selectedProduct?.length > 0) {
                              handlePay();
                            } else {
                              handlePayRet();
                            }
                          }}
                          //disabled={totalPayment === overallTotal1}
                        > */}
                                    </a>{" "}
                                  </div>
                                ) : (
                                  <div className=" pay_btn payment_col ">
                                    <a
                                      style={{ cursor: "pointer" }}
                                      className="apaybtncstm"
                                      href="#"
                                      onClick={() => {
                                        Swal.fire(
                                          "Please Select Product First"
                                        );
                                      }}
                                    >
                                      Pay $0.00
                                    </a>{" "}
                                  </div>
                                )}
                              </div>
                              {ErrorAmount === true ? (
                                <span
                                  style={{
                                    fontSize: "15px",
                                    color: "red",
                                    fontWeight: "600",
                                    marginTop: "20px",
                                    textAlign: "center",
                                  }}
                                >
                                  Amount Should{" "}
                                  <i className="fa-solid fa-greater-than"></i> 0
                                </span>
                              ) : null}
                            </div>
                            <div className="complete_pross_btn">
                              {totalPayment.toFixed(2) ==
                              overallTotal1.toFixed(2) ? (
                                <>
                                  {" "}
                                  <a
                                    href="#"
                                    data-toggle="modal"
                                    data-target="#paymentModal"
                                    className="complete_btn1"
                                    // className={`complete_btn ${
                                    //   totalPayment === overallTotal1 ? "complete_btn1" : ""
                                    // }`}
                                    onClick={(e) => {
                                      setErrorAmount(false);
                                      e.preventDefault();
                                      if (selectedProduct?.length > 0) {
                                        setShowModal9(true);
                                        AddInvoice();
                                      } else if (
                                        selectRetrieveProduct.length > 0
                                      ) {
                                        UpdateInvoice();
                                      } else {
                                        Swal.fire(
                                          "Please Select Product First"
                                        );
                                      }
                                    }}
                                  >
                                    Complete
                                  </a>
                                  <a
                                    href=""
                                    className="close_btn"
                                    onClick={() => {
                                      setSelectedProduct([]);
                                      setSelectedRetrieveProduct([]);
                                    }}
                                  >
                                    <img
                                      src="assets/img/close-icon.webp"
                                      alt=""
                                    />
                                  </a>
                                </>
                              ) : (
                                <>
                                  <a
                                    href="#"
                                    // data-toggle="modal"
                                    // data-target="#paymentModal"
                                    className="complete_btn"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      if (selectedProduct?.length > 0) {
                                        // setShowModal9(true);
                                        // AddInvoice();
                                      } else if (
                                        selectRetrieveProduct.length > 0
                                      ) {
                                        // UpdateInvoice();
                                      } else {
                                        Swal.fire(
                                          "Please Select Product First"
                                        );
                                      }
                                    }}
                                  >
                                    Complete
                                  </a>
                                  <a
                                    href=""
                                    className="close_btn"
                                    // onClick={() => {
                                    //   setSelectedProduct([]);
                                    //   setSelectedRetrieveProduct([]);
                                    // }}
                                  >
                                    <img
                                      src="assets/img/close-icon.webp"
                                      alt=""
                                    />
                                  </a>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="bottom_btns">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="bottom_links">
                              <ul>
                                <li>
                                  <a href="#">--</a>
                                </li>
                                <li>
                                  <a href="#">--</a>
                                </li>
                                <li
                                  onClick={() => {
                                    if (
                                      selectedProduct.length > 0 ||
                                      selectRetrieveProduct.length > 0
                                    ) {
                                      setShowModal6(true);
                                      setDescriptionNote("");
                                    } else {
                                      setShowModal6(false);
                                      setDescriptionNote("");
                                    }
                                  }}
                                >
                                  <a
                                    href="#"
                                    data-toggle="modal"
                                    data-target="#parksaleModal1"
                                  >
                                    Note
                                  </a>
                                </li>
                                <li>
                                  <a href="#">Signature</a>
                                </li>

                                <li>
                                  <a
                                    href="#"
                                    onClick={() => {
                                      HandleRefresh();

                                      // setSelectedProduct([]);
                                      // setSelectedRetrieveProduct([]);
                                    }}
                                  >
                                    Start Over
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <div>
                  <nav className="navbar navbar-expand-lg main-navbar sticky">
                    <div className="sidebar-arrow">
                      <a
                        href="#"
                        data-toggle="sidebar"
                        onClick={(e) => {
                          toggleSidebar();

                          e.preventDefault();
                        }}
                      >
                        <img src="assets/img/sidebar-toggle.webp" alt="" />
                      </a>
                    </div>
                    <div className="header-title mr-auto">
                      <h4>Cash Register</h4>
                    </div>
                    <SideUl />
                  </nav>
                  <MainSidebar />
                  <div className="main-content">
                    <div className="row">
                      <div className="col-lg-5 col-md-12">
                        <div className="search_btn">
                          <div className="search_field">
                            <div
                              className="top_cus_btn srch_customer_btn searchproductdisplycstm"
                              onClick={() => setShowModal2(true)}
                            >
                              <img
                                className="productimgcustom"
                                src="assets/img/search-icon.webp"
                                alt=""
                              />
                              <input
                                type="text"
                                className=" form-control inputproductdisplaycst"
                                placeholder="Search Product"
                                value={searchTermProduct}
                                onChange={handleSearchTermChangeProduct}
                                onClick={() => {
                                  setProductState(!productState);

                                  setSearchTermProduct("");
                                }}
                                onKeyDown={handleKeyDown}
                              />

                              {showModal2 === true && productState === true ? (
                                <>
                                  <div
                                    className={`searchcustomer_list seacrhproductlistcustm ${
                                      showModal2 ? "active" : ""
                                    }`}
                                  >
                                    <div className="srchcustmer_title common_title">
                                      <h4>Search Product </h4>
                                    </div>

                                    <div className="customer_list">
                                      <ul>
                                        {filteredData1.length ? (
                                          <>
                                            {filteredData1?.map((item) => (
                                              <li key={item.id}>
                                                <Link
                                                  onClick={() => {
                                                    handleCustomerSelectionProduct(
                                                      item,
                                                      item?.imeiNumber
                                                    );
                                                    setCustomerId(item?.userId);
                                                  }}
                                                  key={item?.id}
                                                  className="linkcustm"
                                                >
                                                  <div
                                                    key={item?.id}
                                                    className="custmer_img"
                                                  >
                                                    {item?.image ? (
                                                      <img
                                                        src={item?.image}
                                                        alt=""
                                                      />
                                                    ) : (
                                                      <img
                                                        src="assets/img/customer-info-img.webp"
                                                        alt=""
                                                      />
                                                    )}
                                                  </div>
                                                  <span>
                                                    {item?.title}
                                                    <small>
                                                      {item?.skuCode}
                                                    </small>
                                                    <small
                                                      style={{
                                                        color: "#0000ff",
                                                      }}
                                                    >
                                                      {item?.imeiNumber}
                                                    </small>
                                                  </span>
                                                </Link>
                                              </li>
                                            ))}
                                          </>
                                        ) : (
                                          <div className="text-center">
                                            <h5>No data available</h5>
                                          </div>
                                        )}
                                      </ul>
                                    </div>
                                  </div>{" "}
                                </>
                              ) : null}
                            </div>
                          </div>
                          <div className="new_btn cus_btn">
                            <a
                              href=""
                              data-toggle="modal"
                              data-target="#newproductModal"
                              onClick={() => setShowModal3(true)}
                            >
                              <i className="fa fa-plus"></i> New
                            </a>
                          </div>
                        </div>
                        <div className="serv_type">
                          <ul>
                            <li>
                              <a
                                href=""
                                data-toggle="modal"
                                data-target="#newserviceModal"
                                onClick={() => setShowModal8(true)}
                              >
                                Repair
                              </a>
                            </li>
                            <li>
                              <a
                                href=""
                                data-toggle="modal"
                                data-target="#newserviceModal"
                              >
                                --
                              </a>
                            </li>
                            <li>
                              <a
                                href=""
                                data-toggle="modal"
                                data-target="#newserviceModal"
                              >
                                --
                              </a>
                            </li>
                            <li>
                              <a
                                href=""
                                data-toggle="modal"
                                data-target="#newserviceModal"
                              >
                                --
                              </a>
                            </li>
                          </ul>
                        </div>
                        <div
                          className="prod_type"
                          style={{
                            maxHeight:
                              layoutList.length > 16 ? "400px" : "auto",
                            overflowY:
                              layoutList.length > 16 ? "auto" : "visible",
                          }}
                        >
                          {layoutValue === 1 ? (
                            <ul>
                              {layoutList.length > 0 ? (
                                <>
                                  {layoutList.map((item, index) => (
                                    <li
                                      className="cus_prod_tag"
                                      key={index}
                                      style={{ cursor: "pointer" }}
                                    >
                                      {item?.isCategory === 0 ? (
                                        <a onClick={() => handleClick(item)}>
                                          <div
                                          // className=" selectcustmproduct"
                                          >
                                            <div
                                              className=" tag_strip classcolorstyle"
                                              style={{
                                                background: item?.colorCode,
                                              }}
                                            ></div>
                                            <span>{item?.name}</span>
                                          </div>
                                        </a>
                                      ) : (
                                        <a
                                          href="#"
                                          data-toggle="modal"
                                          data-target="#casesModal"
                                          onClick={(e) =>
                                            handleCategoryClick(e, item)
                                          }
                                        >
                                          <div
                                          // className="selectcustmproduct"
                                          >
                                            <div
                                              className="tag_strip classcolorstyle"
                                              style={{
                                                background: item?.colorCode,
                                              }}
                                            ></div>
                                            <span>{item?.name}</span>
                                          </div>
                                        </a>
                                      )}
                                    </li>
                                  ))}
                                  {/* {layoutList.map((item, index) => (
                          <li
                            key={index}
                            onClick={() => {
                              if (item?.isCategory === 0) {
                                handleCustomerSelectionProduct(item?.item);
                              } else {
                                setShowModal10(true);
                                GetPrductbyCategory(item?.itemId);
                                setCategoryProduct(item);
                                console.log("product", item?.itemId);
                              }
                            }}
                          >
                            {item?.isCategory === 0 ? (
                              <>
                                <a>
                                  <div className="selectcustmproduct">
                                    <div
                                      className="classcolorstyle"
                                      style={{ background: item?.colorCode }}
                                    ></div>
                                    <span>{item?.name}</span>
                                  </div>
                                </a>
                              </>
                            ) : (
                              <>
                                {" "}
                                <a
                                  href=""
                                  data-toggle="modal"
                                  data-target="#casesModal"
                                >
                                  <div className="selectcustmproduct">
                                    <div
                                      className="classcolorstyle"
                                      style={{ background: item?.colorCode }}
                                    ></div>
                                    <span>{item?.name}</span>
                                  </div>
                                </a>
                              </>
                            )}
                          </li>
                        ))} */}
                                </>
                              ) : (
                                <div className="cash_register_setup ">
                                  <h4>Set Up Cash Register Layout Keys</h4>
                                  <Link
                                    to="/settings"
                                    state={{ activeState: 6 }}
                                  >
                                    <button className="setup_btn">Setup</button>
                                  </Link>
                                </div>
                              )}
                            </ul>
                          ) : (
                            <div className="cash_register_setup ">
                              <Link to="/settings" state={{ activeState: 6 }}>
                                <h4>Set Up Cash Register Layout Keys</h4>
                                <button className="setup_btn">Setup</button>
                              </Link>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-7 col-md-12">
                        <div className="topbar_btns">
                          <div className="top_cus_btn retrieve_btn">
                            <a href="#" onClick={handleRetrieveClick}>
                              Retrieve Sales
                            </a>
                            {isRetrieveListActive && (
                              <>
                                <div
                                  // className="retrieve_list"
                                  className={`retrieve_list ${
                                    isRetrieveListActive ? "active" : ""
                                  }`}
                                >
                                  <div className="retrieve_title common_title">
                                    <h4>Retrieve Sales</h4>
                                    <a
                                      className="popup_close"
                                      href="#"
                                      onClick={handlePopupClose}
                                    >
                                      <span className="close_icon"></span>
                                    </a>
                                  </div>
                                  <div className="retre_list">
                                    <ul>
                                      {InVoiceData.map((item) => (
                                        <React.Fragment key={item.id}>
                                          {item.itemIds.map((itemId) => (
                                            <li
                                              key={itemId.id}
                                              onClick={() => {
                                                handleCustomerSelectionProductPark(
                                                  item,
                                                  item?.items.map(
                                                    (item) => item.imeiNumberArr
                                                  )
                                                );
                                              }}
                                            >
                                              <span>
                                                #{itemId.id}
                                                <small>
                                                  {item.customer?.firstName ||
                                                    "--"}{" "}
                                                  {item.customer?.lastName ||
                                                    "--"}
                                                </small>
                                              </span>
                                              <a href="#">
                                                <img
                                                  src="assets/img/arrow-right-icon.svg"
                                                  alt=""
                                                />
                                              </a>
                                            </li>
                                          ))}
                                        </React.Fragment>
                                      ))}
                                      {/* {InVoiceData.map((item) => (

                              <>
                                <li
                                  onClick={() => {
                                    handleCustomerSelectionProductPark(item);
                                  }}
                                >
                                  <span>
                                    #{item.items.map((ite) => ite?.itemId)}
                                    <small>
                                      {item?.customer?.firstName}
                                      {"  "}
                                      {item?.customer?.lastName}
                                    </small>
                                  </span>
                                  <a href="">
                                    <img
                                      src="assets/img/arrow-right-icon.webp"
                                      alt=""
                                    />
                                  </a>
                                </li>
                              </>
                            ))} */}
                                    </ul>
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                          <div
                            className="top_cus_btn parksale_btn"
                            onClick={() => {
                              if (
                                !selectedProduct ||
                                selectedProduct.length === 0
                              ) {
                                Swal.fire("First select a Product");
                              } else {
                                setShowModal7(true);
                              }
                            }}
                          >
                            <a
                              href="#"
                              data-toggle="modal"
                              data-target="#parksaleModal"
                            >
                              Park Sales
                            </a>
                          </div>
                          <div className="top_cus_btn moreaction_btn">
                            <a href="#">More Actions</a>
                            <div className="moreaction_list">
                              <div className="moreact_list">
                                <ul>
                                  <li>
                                    <span>Create Quote</span>
                                    <a href="">
                                      <img
                                        src="assets/img/arrow-right-icon.svg"
                                        alt=""
                                      />
                                    </a>
                                  </li>
                                  <li>
                                    <span>Acquisition</span>
                                    <a href="">
                                      <img
                                        src="assets/img/arrow-right-icon.svg"
                                        alt=""
                                      />
                                    </a>
                                  </li>
                                  <li>
                                    <span>Discard Sale</span>
                                    <a href="">
                                      <img
                                        src="assets/img/arrow-right-icon.svg"
                                        alt=""
                                      />
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          {selectedState === true ? (
                            <>
                              <div className="top_cus_btn edit_customer_btn customercustmeditcss customerserachboxcash">
                                <span className="cuslabel">Customer:</span>
                                <div className="customer_info">
                                  <div className="info_label">
                                    <h5>{selectedCustomer?.firstName}</h5>
                                    <p>
                                      <span>{selectedCustomer?.email}</span>
                                      {""}
                                      <span>
                                        {selectedCustomer?.phoneNumber}
                                      </span>
                                    </p>
                                  </div>
                                  <div className="info_edit">
                                    <Link
                                      to="/CustomerDetail"
                                      state={{ data: selectedCustomer }}
                                    >
                                      <img
                                        src="assets/img/cust-edit-icon.webp"
                                        alt=""
                                      />
                                    </Link>
                                  </div>
                                </div>
                              </div>

                              <div
                                className="top_cus_btn delete_customer_btn"
                                onClick={() => {
                                  setSearchTerm("");
                                  setSelectedState(false);
                                  setShowModal1(false);
                                }}
                              >
                                <a href="#">
                                  <img
                                    src="assets/img/cust-trash-icon.webp"
                                    alt=""
                                  />
                                </a>
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="top_cus_btn srch_customer_btn customerserachboxcash">
                                <a href="#" onClick={() => setShowModal1(true)}>
                                  <img
                                    src="assets/img/search-icon.webp"
                                    alt=""
                                  />
                                  <input
                                    type="text"
                                    placeholder="Search Customer"
                                    value={searchTerm}
                                    onChange={handleSearchTermChange}
                                    className="inputdeviceformcustmbox"
                                  />
                                </a>

                                <div
                                  className={`searchcustomer_list ${
                                    showModal1 ? "active" : ""
                                  }`}
                                >
                                  <div className="srchcustmer_title common_title">
                                    <h4>
                                      Search Customer{" "}
                                      <a
                                        href="#"
                                        data-toggle="modal"
                                        data-target="#addcustomerModal"
                                        onClick={() => setShowModal(true)}
                                      >
                                        Add New
                                      </a>
                                    </h4>
                                    <a
                                      className="popup_close"
                                      onClick={handlePopupCloseCustomer}
                                    >
                                      <span className="close_icon"></span>
                                    </a>
                                  </div>
                                  <div className="customer_list">
                                    <ul>
                                      {filteredData ? (
                                        <>
                                          {filteredData.length ? (
                                            <>
                                              {filteredData?.map((item) => (
                                                <li key={item.id}>
                                                  <Link
                                                    onClick={() => {
                                                      handleCustomerSelection(
                                                        item
                                                      );
                                                      setSelectedState(true);
                                                    }}
                                                    key={item.id}
                                                    className="linkcustm"
                                                  >
                                                    <div
                                                      key={item.id}
                                                      className="custmer_img"
                                                    >
                                                      {item?.profileImage ? (
                                                        <img
                                                          src={
                                                            item?.profileImage
                                                          }
                                                          alt=""
                                                        />
                                                      ) : (
                                                        <img
                                                          src="assets/img/customer-info-img.webp"
                                                          alt=""
                                                        />
                                                      )}
                                                    </div>
                                                    <span>
                                                      {item?.firstName}{" "}
                                                      {item?.lastName}{" "}
                                                      <small>
                                                        {item?.email}
                                                      </small>
                                                      <small>
                                                        {item?.phoneNumber}
                                                      </small>
                                                    </span>

                                                    <img
                                                      className="customeraddeditcstmimg"
                                                      src="assets/img/arrow-right-icon.svg"
                                                      alt=""
                                                    />
                                                  </Link>
                                                </li>
                                              ))}
                                            </>
                                          ) : (
                                            <div className="text-center">
                                              <h5>No data available</h5>
                                            </div>
                                          )}
                                        </>
                                      ) : (
                                        <>
                                          {data.length ? (
                                            <>
                                              {data?.map((item) => (
                                                <li key={item.id}>
                                                  <Link
                                                    onClick={() => {
                                                      handleCustomerSelection(
                                                        item
                                                      );
                                                      setSelectedState(true);
                                                    }}
                                                    key={item.id}
                                                    className="linkcustm"
                                                  >
                                                    <div
                                                      key={item.id}
                                                      className="custmer_img"
                                                    >
                                                      {item.profileImage ? (
                                                        <img
                                                          src={
                                                            item.profileImage
                                                          }
                                                          alt=""
                                                        />
                                                      ) : (
                                                        <img
                                                          src="assets/img/customer-info-img.webp"
                                                          alt=""
                                                        />
                                                      )}
                                                    </div>
                                                    <span>
                                                      {item?.firstName}{" "}
                                                      {item?.lastName}{" "}
                                                      <small>
                                                        {item?.email}
                                                      </small>
                                                      <small>
                                                        {item?.phoneNumber}
                                                      </small>
                                                    </span>

                                                    <img
                                                      src="assets/img/arrow-right-icon.svg"
                                                      alt=""
                                                    />
                                                  </Link>
                                                </li>
                                              ))}
                                            </>
                                          ) : (
                                            <div className="text-center">
                                              <h5>No data available</h5>
                                            </div>
                                          )}
                                        </>
                                      )}
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}

                          {selectedState === true ? null : (
                            <>
                              <div
                                className="top_cus_btn new_customer_btn"
                                onClick={() => setShowModal(true)}
                              >
                                <a
                                  href=""
                                  data-toggle="modal"
                                  data-target="#addcustomerModal"
                                >
                                  <i className="fa fa-plus"></i> New Customer
                                </a>
                              </div>
                            </>
                          )}
                        </div>
                        <div className="cus_table cash_register_table">
                          <div className="table-responsive">
                            <table className="table">
                              <thead>
                                <tr>
                                  <th colSpan="2">ITEM(s)</th>
                                  <th>In stock</th>
                                  <th>Qty.</th>
                                  <th>Unit Price</th>
                                  <th>Total Price</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {selectedProduct.length > 0 ? (
                                  <>
                                    {renderProductRows(selectedProduct, 1)}

                                    {[
                                      ...Array(
                                        Math.max(3 - selectedProduct.length, 0)
                                      ),
                                    ].map((_, index) => (
                                      <tr key={`default_${index}`}>
                                        <td className="itemnocstm">
                                          <span className="item_no ">
                                            #
                                            {selectedProduct.length + index + 1}
                                          </span>
                                        </td>
                                        <td>
                                          <a href="#">
                                            <span></span>
                                          </a>
                                        </td>
                                        <td></td>
                                        <td>
                                          <div className="quantity_btn"></div>
                                        </td>
                                        <td></td>
                                        <td></td>
                                        <td>
                                          <div className="action_btn">
                                            {/* <a
                                              href="#"
                                              data-toggle="modal"
                                              data-target="#editinfoModal"
                                              className="edit_btn"
                                            >
                                              <img
                                                src="assets/img/edit-icon.webp"
                                                alt=""
                                              />
                                            </a>
                                            <a href="#" className="delete_btn">
                                              <img
                                                src="assets/img/delete-icon.webp"
                                                alt=""
                                              />
                                            </a> */}
                                          </div>
                                        </td>
                                      </tr>
                                    ))}
                                  </>
                                ) : retrieveState === true ? (
                                  selectRetrieveProduct.length > 0 ? (
                                    renderProductRows(
                                      selectRetrieveProduct,
                                      1,
                                      true
                                    )
                                  ) : (
                                    <>
                                      {[...Array(3)].map((_, index) => (
                                        <tr key={`default_${index}`}>
                                          <td className="itemnocstm">
                                            <span className="item_no ">
                                              #{index + 1}
                                            </span>
                                          </td>
                                          <td>
                                            <a href="#">
                                              <span></span>
                                            </a>
                                          </td>
                                          <td></td>
                                          <td>
                                            <div className="quantity_btn">
                                              {/* <a href="#" className="qty_minus">
                                                <img
                                                  src="assets/img/minus-icon.webp"
                                                  alt=""
                                                />
                                              </a>
                                              <input
                                                type="text"
                                                className="qty_count"
                                                value=""
                                              />
                                              <a href="#" className="qty_plus">
                                                <img
                                                  src="assets/img/plus-icon.webp"
                                                  alt=""
                                                />
                                              </a> */}
                                            </div>
                                          </td>
                                          <td></td>
                                          <td></td>
                                          <td>
                                            <div className="action_btn">
                                              {/* <a
                                                href="#"
                                                data-toggle="modal"
                                                data-target="#editinfoModal"
                                                className="edit_btn"
                                              >
                                                <img
                                                  src="assets/img/edit-icon.webp"
                                                  alt=""
                                                />
                                              </a>
                                              <a
                                                href="#"
                                                className="delete_btn"
                                              >
                                                <img
                                                  src="assets/img/delete-icon.webp"
                                                  alt=""
                                                />
                                              </a> */}
                                            </div>
                                          </td>
                                        </tr>
                                      ))}
                                    </>
                                  )
                                ) : (
                                  <>
                                    {[...Array(3)].map((_, index) => (
                                      <tr key={`default_${index}`}>
                                        <td className="itemnocstm">
                                          <span className="item_no ">
                                            #{index + 1}
                                          </span>
                                        </td>
                                        <td>
                                          <a href="#">
                                            <span></span>
                                          </a>
                                        </td>
                                        <td></td>
                                        <td>
                                          <div className="quantity_btn">
                                            {/* <a href="#" className="qty_minus">
                                              <img
                                                src="assets/img/minus-icon.webp"
                                                alt=""
                                              />
                                            </a>
                                            <input
                                              type="text"
                                              className="qty_count"
                                              value=""
                                            />
                                            <a href="#" className="qty_plus">
                                              <img
                                                src="assets/img/plus-icon.webp"
                                                alt=""
                                              />
                                            </a> */}
                                          </div>
                                        </td>
                                        <td></td>
                                        <td></td>
                                        <td>
                                          <div className="action_btn">
                                            {/* <a
                                              href="#"
                                              data-toggle="modal"
                                              data-target="#editinfoModal"
                                              className="edit_btn"
                                            >
                                              <img
                                                src="assets/img/edit-icon.webp"
                                                alt=""
                                              />
                                            </a>
                                            <a href="#" className="delete_btn">
                                              <img
                                                src="assets/img/delete-icon.webp"
                                                alt=""
                                              />
                                            </a> */}
                                          </div>
                                        </td>
                                      </tr>
                                    ))}
                                  </>
                                )}
                              </tbody>

                              <tfoot>
                                <tr className="price_tr">
                                  <td colSpan="3">Sub Total</td>
                                  <td></td>
                                  {overallTotal ? (
                                    <td colSpan="3">${overallTotal}</td>
                                  ) : (
                                    <td colSpan="3">$0.00</td>
                                  )}
                                </tr>
                                <tr className="price_tr">
                                  <td colSpan="3">
                                    <span>Discount:</span>
                                    <select
                                      className="tble_select"
                                      onChange={handleDiscountChange}
                                      value={discount}
                                    >
                                      <option value={0}>0%</option>
                                      {alldiscount?.map((item) => (
                                        <>
                                          {" "}
                                          <option
                                            key={item?.id}
                                            value={item?.rate}
                                          >
                                            {item?.rate}%
                                          </option>
                                        </>
                                      ))}
                                      {/* <option value={0}>0%</option>
                            <option value={10}>10%</option>
                            <option value={20}>20%</option> */}
                                    </select>
                                  </td>
                                  <td></td>
                                  <td colSpan="3">
                                    $
                                    {parseFloat(
                                      overallTotal - discountedSubtotal
                                    ).toFixed(2)}
                                  </td>
                                </tr>
                                <tr className="price_tr">
                                  <td colSpan="3">
                                    <span>Tax:</span>
                                    <select
                                      className="tble_select"
                                      onChange={handleTaxChange}
                                      value={tax}
                                    >
                                      {defaultTax ? (
                                        <option value={defaultTax.rate}>
                                          {defaultTax.name} {defaultTax.rate}%
                                        </option>
                                      ) : (
                                        <option value={0}>Tax 0%</option>
                                      )}
                                      {alltax?.map((item) => (
                                        <>
                                          {" "}
                                          <option value={item?.rate}>
                                            {item?.name} {item?.rate}%
                                          </option>
                                        </>
                                      ))}
                                    </select>
                                  </td>
                                  <td></td>
                                  <td colSpan="3">${taxAmount.toFixed(2)}</td>
                                </tr>
                                <tr className="grand_total">
                                  <td colSpan="3">Grand Total</td>
                                  <td></td>
                                  {overallTotal1 ? (
                                    <td colSpan="3">
                                      ${overallTotal1.toFixed(2)}
                                    </td>
                                  ) : (
                                    <td colSpan="3">$0.00</td>
                                  )}
                                </tr>
                              </tfoot>
                            </table>
                          </div>

                          <div className="payment_method">
                            {paymentState === true ? (
                              <>
                                <div className="payment_mode">
                                  <div className="inner_payment_mode">
                                    <div className="mode_title">
                                      <span>Payment Made</span>
                                    </div>
                                    <div className="mode_table">
                                      <table>
                                        <thead>
                                          <tr>
                                            <th>Time</th>
                                            <th>Type</th>
                                            <th>Amount</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {paymentMode.map((payment, index) => (
                                            <>
                                              <tr key={index}>
                                                <td>
                                                  {moment(new Date()).format(
                                                    "DD/MM/YYYY hh:mm a"
                                                  )}
                                                </td>
                                                <td>
                                                  <b>{payment.paymentType}</b>
                                                </td>
                                                <td>
                                                  <b>${payment.payAmount}</b>
                                                </td>
                                                <td>
                                                  <a
                                                    href="#"
                                                    className="delete_btn"
                                                    onClick={() =>
                                                      handleDeletePayment(index)
                                                    }
                                                  >
                                                    <img
                                                      src="assets/img/delete-icon.webp"
                                                      alt=""
                                                    />
                                                  </a>
                                                </td>
                                              </tr>
                                            </>
                                          ))}
                                          <tr>
                                            <td colSpan={3}>Total</td>
                                            <td>${totalPayment.toFixed(2)}</td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                  <div className="pending_amt">
                                    <span>
                                      (Due: ${remainingAmount.toFixed(2)})
                                    </span>
                                  </div>
                                </div>
                              </>
                            ) : null}

                            <div className="inner_payment_method">
                              <div className="cash_dropdown payment_col">
                                <select
                                  className="form-control cashpaymentcustmcss"
                                  value={selectedOptions}
                                  onChange={handleOptionChange}
                                >
                                  {allPaymentOptions.length > 0 ? (
                                    allPaymentOptions.map((item) => (
                                      <option key={item.id} value={item.name}>
                                        {item.name}
                                      </option>
                                    ))
                                  ) : (
                                    <option value="">Payment Options</option>
                                  )}
                                </select>
                                {/* <select
                                  className="form-control cashpaymentcustmcss"
                                  value={selectedOptions}
                                  onChange={handleOptionChange}
                                >
                                  {selectedCategory2.length > 0 ? (
                                    selectedCategory2?.map((item, index) => (
                                      <option key={item?.id} value={item?.name}>
                                        {item?.name}
                                      </option>
                                    ))
                                  ) : (
                                    <option value="">Payment Options</option>
                                  )}
                                  {allPaymentOptions.map((item) => (
                                    <option key={item.id} value={item.name}>
                                      {item.name}
                                    </option>
                                  ))}
                                </select> */}
                                <span className="angle_down">
                                  <img
                                    src="assets/img/angledown-icon.webp"
                                    alt=""
                                  />
                                </span>
                              </div>

                              <input
                                type="number"
                                value={inputValue}
                                className="price_value payment_col no-arrow-input"
                                style={{
                                  fontWeight: 700,
                                  color: "black",
                                  fontSize: 18,
                                }}
                                onChange={(e) => {
                                  setInputValue(e.target.value);
                                }}
                              />

                              {(inputValue !== 0 &&
                                selectedProduct.length > 0) ||
                              selectRetrieveProduct.length > 0 ? (
                                <div className="pay_btn payment_col">
                                  <a
                                    onClick={handlePaymentClick}
                                    style={{ cursor: "pointer" }}
                                  >
                                    Pay ${inputValue}
                                    {/* <a
                          onClick={() => {
                            if (selectedProduct?.length > 0) {
                              handlePay();
                            } else {
                              handlePayRet();
                            }
                          }}
                          //disabled={totalPayment === overallTotal1}
                        > */}
                                  </a>{" "}
                                </div>
                              ) : (
                                <div className=" pay_btn payment_col ">
                                  <a
                                    style={{ cursor: "pointer" }}
                                    className="apaybtncstm"
                                    href="#"
                                    onClick={() => {
                                      Swal.fire("Please Select Product First");
                                    }}
                                  >
                                    Pay $0.00
                                  </a>{" "}
                                </div>
                              )}
                            </div>
                            {ErrorAmount === true ? (
                              <span
                                style={{
                                  fontSize: "15px",
                                  color: "red",
                                  fontWeight: "600",
                                  marginTop: "20px",
                                  textAlign: "center",
                                }}
                              >
                                Amount Should{" "}
                                <i className="fa-solid fa-greater-than"></i> 0
                              </span>
                            ) : null}
                          </div>
                          <div className="complete_pross_btn">
                            {totalPayment.toFixed(2) ==
                            overallTotal1.toFixed(2) ? (
                              <>
                                {" "}
                                <a
                                  href="#"
                                  data-toggle="modal"
                                  data-target="#paymentModal"
                                  className="complete_btn1"
                                  // className={`complete_btn ${
                                  //   totalPayment === overallTotal1 ? "complete_btn1" : ""
                                  // }`}
                                  onClick={(e) => {
                                    setErrorAmount(false);
                                    e.preventDefault();
                                    if (selectedProduct?.length > 0) {
                                      setShowModal9(true);
                                      AddInvoice();
                                    } else if (
                                      selectRetrieveProduct.length > 0
                                    ) {
                                      UpdateInvoice();
                                    } else {
                                      Swal.fire("Please Select Product First");
                                    }
                                  }}
                                >
                                  Complete
                                </a>
                                <a
                                  href=""
                                  className="close_btn"
                                  onClick={() => {
                                    setSelectedProduct([]);
                                    setSelectedRetrieveProduct([]);
                                  }}
                                >
                                  <img
                                    src="assets/img/close-icon.webp"
                                    alt=""
                                  />
                                </a>
                              </>
                            ) : (
                              <>
                                <a
                                  href="#"
                                  // data-toggle="modal"
                                  // data-target="#paymentModal"
                                  className="complete_btn"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    if (selectedProduct?.length > 0) {
                                      // setShowModal9(true);
                                      // AddInvoice();
                                    } else if (
                                      selectRetrieveProduct.length > 0
                                    ) {
                                      // UpdateInvoice();
                                    } else {
                                      Swal.fire("Please Select Product First");
                                    }
                                  }}
                                >
                                  Complete
                                </a>
                                <a
                                  href=""
                                  className="close_btn"
                                  // onClick={() => {
                                  //   setSelectedProduct([]);
                                  //   setSelectedRetrieveProduct([]);
                                  // }}
                                >
                                  <img
                                    src="assets/img/close-icon.webp"
                                    alt=""
                                  />
                                </a>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bottom_btns">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="bottom_links">
                            <ul>
                              <li>
                                <a href="#">--</a>
                              </li>
                              <li>
                                <a href="#">--</a>
                              </li>
                              <li
                                onClick={() => {
                                  if (
                                    selectedProduct.length > 0 ||
                                    selectRetrieveProduct.length > 0
                                  ) {
                                    setShowModal6(true);
                                    setDescriptionNote("");
                                  } else {
                                    setShowModal6(false);
                                    setDescriptionNote("");
                                  }
                                }}
                              >
                                <a
                                  href="#"
                                  data-toggle="modal"
                                  data-target="#parksaleModal1"
                                >
                                  Note
                                </a>
                              </li>
                              <li>
                                <a href="#">Signature</a>
                              </li>

                              <li>
                                <a
                                  href="#"
                                  onClick={() => {
                                    HandleRefresh();
                                    // setSelectedProduct([]);
                                    // setSelectedRetrieveProduct([]);
                                  }}
                                >
                                  Start Over
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          ) : (
            <div style={sidebarStyleone}>
              <nav className="navbar navbar-expand-lg main-navbar sticky">
                <div className="sidebar-arrow">
                  <a
                    href="#"
                    data-toggle="sidebar"
                    onClick={(e) => {
                      toggleSidebar();

                      e.preventDefault();
                    }}
                  >
                    <img src="assets/img/sidebar-toggle.webp" alt="" />
                  </a>
                </div>
                <div className="header-title mr-auto">
                  <h4>Cash Register</h4>
                </div>
                <SideUl />
              </nav>
              <MainSidebar />
              <div className="main-content" style={mainContentStyle}>
                <div className="row">
                  <div className="col-lg-5 col-md-12">
                    <div className="search_btn">
                      <div className="search_field">
                        <div
                          className="top_cus_btn srch_customer_btn searchproductdisplycstm"
                          onClick={() => setShowModal2(true)}
                        >
                          <img
                            className="productimgcustom"
                            src="assets/img/search-icon.webp"
                            alt=""
                          />
                          <input
                            type="text"
                            className=" form-control inputproductdisplaycst"
                            placeholder="Search Product"
                            value={searchTermProduct}
                            onChange={handleSearchTermChangeProduct}
                            onClick={() => {
                              setProductState(!productState);

                              setSearchTermProduct("");
                            }}
                            onKeyDown={handleKeyDown}
                          />

                          {showModal2 === true && productState === true ? (
                            <>
                              <div
                                className={`searchcustomer_list seacrhproductlistcustm ${
                                  showModal2 ? "active" : ""
                                }`}
                              >
                                <div className="srchcustmer_title common_title">
                                  <h4>Search Product </h4>
                                </div>

                                <div className="customer_list">
                                  <ul>
                                    {filteredData1.length ? (
                                      <>
                                        {filteredData1?.map((item) => (
                                          <li key={item.id}>
                                            <Link
                                              onClick={() => {
                                                handleCustomerSelectionProduct(
                                                  item,
                                                  item?.imeiNumber
                                                );
                                                setCustomerId(item?.userId);
                                              }}
                                              key={item?.id}
                                              className="linkcustm"
                                            >
                                              <div
                                                key={item?.id}
                                                className="custmer_img"
                                              >
                                                {item?.image ? (
                                                  <img
                                                    src={item?.image}
                                                    alt=""
                                                  />
                                                ) : (
                                                  <img
                                                    src="assets/img/customer-info-img.webp"
                                                    alt=""
                                                  />
                                                )}
                                              </div>
                                              <span>
                                                {item?.title}
                                                <small>{item?.skuCode}</small>
                                                <small
                                                  style={{
                                                    color: "#0000ff",
                                                  }}
                                                >
                                                  {item?.imeiNumber}
                                                </small>
                                              </span>
                                            </Link>
                                          </li>
                                        ))}
                                      </>
                                    ) : (
                                      <div className="text-center">
                                        <h5>No data available</h5>
                                      </div>
                                    )}
                                  </ul>
                                </div>
                              </div>{" "}
                            </>
                          ) : null}
                        </div>
                      </div>
                      <div className="new_btn cus_btn">
                        <a
                          href=""
                          data-toggle="modal"
                          data-target="#newproductModal"
                          onClick={() => setShowModal3(true)}
                        >
                          <i className="fa fa-plus"></i> New
                        </a>
                      </div>
                    </div>
                    <div className="serv_type">
                      <ul>
                        <li>
                          <a
                            href=""
                            data-toggle="modal"
                            data-target="#newserviceModal"
                            onClick={() => setShowModal8(true)}
                          >
                            Repair
                          </a>
                        </li>
                        <li>
                          <a
                            href=""
                            data-toggle="modal"
                            data-target="#newserviceModal"
                          >
                            --
                          </a>
                        </li>
                        <li>
                          <a
                            href=""
                            data-toggle="modal"
                            data-target="#newserviceModal"
                          >
                            --
                          </a>
                        </li>
                        <li>
                          <a
                            href=""
                            data-toggle="modal"
                            data-target="#newserviceModal"
                          >
                            --
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div
                      className="prod_type"
                      style={{
                        maxHeight: layoutList.length > 16 ? "400px" : "auto",
                        overflowY: layoutList.length > 16 ? "auto" : "visible",
                      }}
                    >
                      {layoutValue === 1 ? (
                        <ul>
                          {layoutList.length > 0 ? (
                            <>
                              {layoutList.map((item, index) => (
                                <li
                                  className="cus_prod_tag"
                                  key={index}
                                  style={{ cursor: "pointer" }}
                                >
                                  {item?.isCategory === 0 ? (
                                    <a
                                      onClick={() => {
                                        handleClick(item);
                                      }}
                                    >
                                      <div
                                      // className=" selectcustmproduct"
                                      >
                                        <div
                                          className=" tag_strip classcolorstyle"
                                          style={{
                                            background: item?.colorCode,
                                          }}
                                        ></div>
                                        <span>{item?.name}</span>
                                      </div>
                                    </a>
                                  ) : (
                                    <a
                                      href="#"
                                      data-toggle="modal"
                                      data-target="#casesModal"
                                      onClick={(e) => {
                                        handleCategoryClick(e, item);
                                      }}
                                    >
                                      <div
                                      //  className="selectcustmproduct"
                                      >
                                        <div
                                          className="tag_strip classcolorstyle"
                                          style={{
                                            background: item?.colorCode,
                                          }}
                                        ></div>
                                        <span>{item?.name}</span>
                                      </div>
                                    </a>
                                  )}
                                </li>
                              ))}
                            </>
                          ) : (
                            <div className="cash_register_setup ">
                              <h4>Set Up Cash Register Layout Keys</h4>
                              <Link to="/settings" state={{ activeState: 6 }}>
                                <button className="setup_btn">Setup</button>
                              </Link>
                            </div>
                          )}
                        </ul>
                      ) : (
                        <div className="cash_register_setup ">
                          <Link to="/settings" state={{ activeState: 6 }}>
                            <h4>Set Up Cash Register Layout Keys</h4>
                            <button className="setup_btn">Setup</button>
                          </Link>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-7 col-md-12">
                    <div className="topbar_btns">
                      <div className="top_cus_btn retrieve_btn">
                        <a href="#" onClick={handleRetrieveClick}>
                          Retrieve Sales
                        </a>
                        {isRetrieveListActive && (
                          <>
                            <div
                              // className="retrieve_list"
                              className={`retrieve_list ${
                                isRetrieveListActive ? "active" : ""
                              }`}
                            >
                              <div className="retrieve_title common_title">
                                <h4>Retrieve Sales</h4>
                                <a
                                  className="popup_close"
                                  href="#"
                                  onClick={handlePopupClose}
                                >
                                  <span className="close_icon"></span>
                                </a>
                              </div>
                              <div className="retre_list">
                                <ul>
                                  {/* {InVoiceData.map((item) => (
                                    <React.Fragment key={item.id}>
                                      {item.itemIds.map((itemId) => (
                                        <li
                                          key={itemId.id}
                                          onClick={() => {
                                            handleCustomerSelectionProductPark(
                                              item
                                            );
                                          }}
                                        >
                                          <span>
                                            #{itemId.id}
                                            <small>
                                              {item.customer?.firstName || "--"}{" "}
                                              {item.customer?.lastName || "--"}
                                            </small>
                                          </span>
                                          <a href="#">
                                            <img
                                              src="assets/img/arrow-right-icon.svg"
                                              alt=""
                                            />
                                          </a>
                                        </li>
                                      ))}
                                    </React.Fragment>
                                  ))} */}
                                  {InVoiceData.map((item) => (
                                    <>
                                      <li
                                        key={item.id}
                                        onClick={() => {
                                          // console.log("retrieveitem", item);
                                          // console.log(
                                          //   "imeinumberret",
                                          //   item?.items.map(
                                          //     (item) => item.imeiNumberArr
                                          //   )
                                          // );
                                          handleCustomerSelectionProductPark(
                                            item,
                                            item?.items.map(
                                              (item) => item.imeiNumberArr
                                            )
                                          );
                                        }}
                                      >
                                        <span>
                                          #{item?.id}
                                          {/* {item.items.map((ite) => ite?.itemId)} */}
                                          <small>
                                            {item?.customer?.firstName}
                                            {"  "}
                                            {item?.customer?.lastName}
                                          </small>
                                        </span>
                                        <a href="">
                                          <img
                                            src="assets/img/arrow-right-icon.webp"
                                            alt=""
                                          />
                                        </a>
                                      </li>
                                    </>
                                  ))}
                                </ul>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                      <div
                        className="top_cus_btn parksale_btn"
                        onClick={() => {
                          if (
                            !selectedProduct ||
                            selectedProduct.length === 0
                          ) {
                            Swal.fire("First select a Product");
                          } else {
                            setShowModal7(true);
                          }
                        }}
                      >
                        <a
                          href="#"
                          data-toggle="modal"
                          data-target="#parksaleModal"
                        >
                          Park Sales
                        </a>
                      </div>
                      <div className="top_cus_btn moreaction_btn">
                        <a href="#">More Actions</a>
                        <div className="moreaction_list">
                          <div className="moreact_list">
                            <ul>
                              <li>
                                <span>Create Quote</span>
                                <a href="">
                                  <img
                                    src="assets/img/arrow-right-icon.svg"
                                    alt=""
                                  />
                                </a>
                              </li>
                              <li>
                                <span>Acquisition</span>
                                <a href="">
                                  <img
                                    src="assets/img/arrow-right-icon.svg"
                                    alt=""
                                  />
                                </a>
                              </li>
                              <li>
                                <span>Discard Sale</span>
                                <a href="">
                                  <img
                                    src="assets/img/arrow-right-icon.svg"
                                    alt=""
                                  />
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      {selectedState === true ? (
                        <>
                          <div className="top_cus_btn edit_customer_btn customercustmeditcss customerserachboxcash">
                            <span className="cuslabel">Customer:</span>
                            <div className="customer_info">
                              <div className="info_label">
                                <h5>{selectedCustomer?.firstName}</h5>
                                <p>
                                  <span>{selectedCustomer?.email}</span>
                                  {""}
                                  <span>{selectedCustomer?.phoneNumber}</span>
                                </p>
                              </div>
                              <div className="info_edit">
                                <Link
                                  to="/CustomerDetail"
                                  state={{ data: selectedCustomer }}
                                >
                                  <img
                                    src="assets/img/cust-edit-icon.webp"
                                    alt=""
                                  />
                                </Link>
                              </div>
                            </div>
                          </div>

                          <div
                            className="top_cus_btn delete_customer_btn"
                            onClick={() => {
                              setSearchTerm("");
                              setSelectedState(false);
                              setShowModal1(false);
                            }}
                          >
                            <a href="#">
                              <img
                                src="assets/img/cust-trash-icon.webp"
                                alt=""
                              />
                            </a>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="top_cus_btn srch_customer_btn customerserachboxcash">
                            <a href="#" onClick={() => setShowModal1(true)}>
                              <img src="assets/img/search-icon.webp" alt="" />
                              <input
                                type="text"
                                placeholder="Search Customer"
                                value={searchTerm}
                                onChange={handleSearchTermChange}
                                className="inputdeviceformcustmbox"
                              />
                            </a>

                            <div
                              className={`searchcustomer_list ${
                                showModal1 ? "active" : ""
                              }`}
                            >
                              <div className="srchcustmer_title common_title">
                                <h4>
                                  Search Customer{" "}
                                  <a
                                    href="#"
                                    data-toggle="modal"
                                    data-target="#addcustomerModal"
                                    onClick={() => setShowModal(true)}
                                  >
                                    Add New
                                  </a>
                                </h4>
                                <a
                                  className="popup_close"
                                  onClick={handlePopupCloseCustomer}
                                >
                                  <span className="close_icon"></span>
                                </a>
                              </div>
                              <div className="customer_list">
                                <ul>
                                  {filteredData ? (
                                    <>
                                      {filteredData.length ? (
                                        <>
                                          {filteredData?.map((item) => (
                                            <li key={item.id}>
                                              <Link
                                                onClick={() => {
                                                  handleCustomerSelection(item);
                                                  setSelectedState(true);
                                                }}
                                                key={item.id}
                                                className="linkcustm"
                                              >
                                                <div
                                                  key={item.id}
                                                  className="custmer_img"
                                                >
                                                  {item?.profileImage ? (
                                                    <img
                                                      src={item?.profileImage}
                                                      alt=""
                                                    />
                                                  ) : (
                                                    <img
                                                      src="assets/img/customer-info-img.webp"
                                                      alt=""
                                                    />
                                                  )}
                                                </div>
                                                <span>
                                                  {item?.firstName}{" "}
                                                  {item?.lastName}{" "}
                                                  <small>{item?.email}</small>
                                                  <small>
                                                    {item?.phoneNumber}
                                                  </small>
                                                </span>

                                                <img
                                                  className="customeraddeditcstmimg"
                                                  src="assets/img/arrow-right-icon.svg"
                                                  alt=""
                                                />
                                              </Link>
                                            </li>
                                          ))}
                                        </>
                                      ) : (
                                        <div className="text-center">
                                          <h5>No data available</h5>
                                        </div>
                                      )}
                                    </>
                                  ) : (
                                    <>
                                      {data.length ? (
                                        <>
                                          {data?.map((item) => (
                                            <li key={item.id}>
                                              <Link
                                                onClick={() => {
                                                  handleCustomerSelection(item);
                                                  setSelectedState(true);
                                                }}
                                                key={item.id}
                                                className="linkcustm"
                                              >
                                                <div
                                                  key={item.id}
                                                  className="custmer_img"
                                                >
                                                  {item.profileImage ? (
                                                    <img
                                                      src={item.profileImage}
                                                      alt=""
                                                    />
                                                  ) : (
                                                    <img
                                                      src="assets/img/customer-info-img.webp"
                                                      alt=""
                                                    />
                                                  )}
                                                </div>
                                                <span>
                                                  {item?.firstName}{" "}
                                                  {item?.lastName}{" "}
                                                  <small>{item?.email}</small>
                                                  <small>
                                                    {item?.phoneNumber}
                                                  </small>
                                                </span>

                                                <img
                                                  className="customeraddeditcstmimg"
                                                  src="assets/img/arrow-right-icon.svg"
                                                  alt=""
                                                />
                                              </Link>
                                            </li>
                                          ))}
                                        </>
                                      ) : (
                                        <div className="text-center">
                                          <h5>No data available</h5>
                                        </div>
                                      )}
                                    </>
                                  )}
                                </ul>
                              </div>
                            </div>
                          </div>
                        </>
                      )}

                      {selectedState === true ? null : (
                        <>
                          <div
                            className="top_cus_btn new_customer_btn"
                            onClick={() => setShowModal(true)}
                          >
                            <a
                              href=""
                              data-toggle="modal"
                              data-target="#addcustomerModal"
                            >
                              <i className="fa fa-plus"></i> New Customer
                            </a>
                          </div>
                        </>
                      )}
                    </div>
                    <div className="cus_table cash_register_table">
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th colSpan="2">ITEM(s)</th>
                              <th>In stock</th>
                              <th>Qty.</th>
                              <th>Unit Price</th>
                              <th>Total Price</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {selectedProduct.length > 0 ? (
                              <>
                                {renderProductRows(selectedProduct, 1)}

                                {[
                                  ...Array(
                                    Math.max(3 - selectedProduct.length, 0)
                                  ),
                                ].map((_, index) => (
                                  <tr key={`default_${index}`}>
                                    <td className="itemnocstm">
                                      <span className="item_no ">
                                        #{selectedProduct.length + index + 1}
                                      </span>
                                    </td>
                                    <td>
                                      <a href="#">
                                        <span></span>
                                      </a>
                                    </td>
                                    <td></td>
                                    <td>
                                      <div className="quantity_btn">
                                        {/* <a href="#" className="qty_minus">
                                          <img
                                            src="assets/img/minus-icon.webp"
                                            alt=""
                                          />
                                        </a>
                                        <input
                                          type="text"
                                          className="qty_count"
                                          value=""
                                        />
                                        <a href="#" className="qty_plus">
                                          <img
                                            src="assets/img/plus-icon.webp"
                                            alt=""
                                          />
                                        </a> */}
                                      </div>
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td>
                                      <div className="action_btn">
                                        {/* <a
                                          href="#"
                                          data-toggle="modal"
                                          data-target="#editinfoModal"
                                          className="edit_btn"
                                        >
                                          <img
                                            src="assets/img/edit-icon.webp"
                                            alt=""
                                          />
                                        </a>
                                        <a href="#" className="delete_btn">
                                          <img
                                            src="assets/img/delete-icon.webp"
                                            alt=""
                                          />
                                        </a> */}
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                              </>
                            ) : retrieveState === true ? (
                              selectRetrieveProduct.length > 0 ? (
                                renderProductRows(
                                  selectRetrieveProduct,
                                  1,
                                  true
                                )
                              ) : (
                                <>
                                  {[...Array(3)].map((_, index) => (
                                    <tr key={`default_${index}`}>
                                      <td className="itemnocstm">
                                        <span className="item_no ">
                                          #{index + 1}
                                        </span>
                                      </td>
                                      <td>
                                        <a href="#">
                                          <span></span>
                                        </a>
                                      </td>
                                      <td></td>
                                      <td>
                                        <div className="quantity_btn">
                                          {/* <a href="#" className="qty_minus">
                                            <img
                                              src="assets/img/minus-icon.webp"
                                              alt=""
                                            />
                                          </a>
                                          <input
                                            type="text"
                                            className="qty_count"
                                            value=""
                                          />
                                          <a href="#" className="qty_plus">
                                            <img
                                              src="assets/img/plus-icon.webp"
                                              alt=""
                                            />
                                          </a> */}
                                        </div>
                                      </td>
                                      <td></td>
                                      <td></td>
                                      <td>
                                        <div className="action_btn">
                                          {/* <a
                                            href="#"
                                            data-toggle="modal"
                                            data-target="#editinfoModal"
                                            className="edit_btn"
                                          >
                                            <img
                                              src="assets/img/edit-icon.webp"
                                              alt=""
                                            />
                                          </a>
                                          <a href="#" className="delete_btn">
                                            <img
                                              src="assets/img/delete-icon.webp"
                                              alt=""
                                            />
                                          </a> */}
                                        </div>
                                      </td>
                                    </tr>
                                  ))}
                                </>
                              )
                            ) : (
                              <>
                                {[...Array(3)].map((_, index) => (
                                  <tr key={`default_${index}`}>
                                    <td className="itemnocstm">
                                      <span className="item_no ">
                                        #{index + 1}
                                      </span>
                                    </td>
                                    <td>
                                      <a href="#">
                                        <span></span>
                                      </a>
                                    </td>
                                    <td></td>
                                    <td>
                                      <div className="quantity_btn">
                                        {/* <a href="#" className="qty_minus">
                                          <img
                                            src="assets/img/minus-icon.webp"
                                            alt=""
                                          />
                                        </a>
                                        <input
                                          type="text"
                                          className="qty_count"
                                          value=""
                                        />
                                        <a href="#" className="qty_plus">
                                          <img
                                            src="assets/img/plus-icon.webp"
                                            alt=""
                                          />
                                        </a> */}
                                      </div>
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td>
                                      <div className="action_btn">
                                        {/* <a
                                          href="#"
                                          data-toggle="modal"
                                          data-target="#editinfoModal"
                                          className="edit_btn"
                                        >
                                          <img
                                            src="assets/img/edit-icon.webp"
                                            alt=""
                                          />
                                        </a>
                                        <a href="#" className="delete_btn">
                                          <img
                                            src="assets/img/delete-icon.webp"
                                            alt=""
                                          />
                                        </a> */}
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                              </>
                            )}
                          </tbody>

                          <tfoot>
                            <tr className="price_tr">
                              <td colSpan="3">Sub Total</td>
                              <td></td>
                              {overallTotal ? (
                                <td colSpan="3">${overallTotal}</td>
                              ) : (
                                <td colSpan="3">$0.00</td>
                              )}
                            </tr>
                            <tr className="price_tr">
                              <td colSpan="3">
                                <span>Discount:</span>
                                <select
                                  className="tble_select"
                                  onChange={handleDiscountChange}
                                  value={discount}
                                >
                                  <option value={0}>0%</option>
                                  {alldiscount?.map((item) => (
                                    <>
                                      {" "}
                                      <option key={item?.id} value={item?.rate}>
                                        {item?.rate}%
                                      </option>
                                    </>
                                  ))}
                                  {/* <option value={0}>0%</option>
                            <option value={10}>10%</option>
                            <option value={20}>20%</option> */}
                                </select>
                              </td>
                              <td></td>
                              <td colSpan="3">
                                $
                                {parseFloat(
                                  overallTotal - discountedSubtotal
                                ).toFixed(2)}
                              </td>
                            </tr>
                            <tr className="price_tr">
                              <td colSpan="3">
                                <span>Tax:</span>
                                <select
                                  className="tble_select"
                                  onChange={handleTaxChange}
                                  value={tax}
                                >
                                  {defaultTax ? (
                                    <option value={defaultTax.rate}>
                                      {defaultTax.name} {defaultTax.rate}%
                                    </option>
                                  ) : (
                                    <option value={0}>Tax 0%</option>
                                  )}
                                  {alltax?.map((item, index) => (
                                    <>
                                      {" "}
                                      <option key={index} value={item?.rate}>
                                        {item?.name} {item?.rate}%
                                      </option>
                                    </>
                                  ))}
                                </select>
                              </td>
                              <td></td>
                              <td colSpan="3">${taxAmount.toFixed(2)}</td>
                            </tr>
                            <tr className="grand_total">
                              <td colSpan="3">Grand Total</td>
                              <td></td>
                              {overallTotal1 ? (
                                <td colSpan="3">${overallTotal1.toFixed(2)}</td>
                              ) : (
                                <td colSpan="3">$0.00</td>
                              )}
                            </tr>
                          </tfoot>
                        </table>
                      </div>

                      <div className="payment_method">
                        {paymentState === true ? (
                          <>
                            <div className="payment_mode">
                              <div className="inner_payment_mode">
                                <div className="mode_title">
                                  <span>Payment Made</span>
                                </div>
                                <div className="mode_table">
                                  <table>
                                    <thead>
                                      <tr>
                                        <th>Time</th>
                                        <th>Type</th>
                                        <th>Amount</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {paymentMode.map((payment, index) => (
                                        <>
                                          <tr key={index}>
                                            <td>
                                              {moment(new Date()).format(
                                                "DD/MM/YYYY hh:mm a"
                                              )}
                                            </td>
                                            <td>
                                              <b>{payment.paymentType}</b>
                                            </td>
                                            <td>
                                              <b>${payment.payAmount}</b>
                                            </td>
                                            <td>
                                              <a
                                                href="#"
                                                className="delete_btn"
                                                onClick={() =>
                                                  handleDeletePayment(index)
                                                }
                                              >
                                                <img
                                                  src="assets/img/delete-icon.webp"
                                                  alt=""
                                                />
                                              </a>
                                            </td>
                                          </tr>
                                        </>
                                      ))}
                                      <tr>
                                        <td colSpan={3}>Total</td>
                                        <td>${totalPayment.toFixed(2)}</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                              <div className="pending_amt">
                                <span>
                                  (Due: ${remainingAmount.toFixed(2)})
                                </span>
                              </div>
                            </div>
                          </>
                        ) : null}

                        <div className="inner_payment_method">
                          <div className="cash_dropdown payment_col">
                            <select
                              className="form-control cashpaymentcustmcss"
                              value={selectedOptions}
                              onChange={handleOptionChange}
                            >
                              {allPaymentOptions.length > 0 ? (
                                allPaymentOptions.map((item) => (
                                  <option key={item.id} value={item.name}>
                                    {item.name}
                                  </option>
                                ))
                              ) : (
                                <option value="">Payment Options</option>
                              )}
                            </select>
                            {/* <select
                              className="form-control cashpaymentcustmcss"
                              value={selectedOptions}
                              onChange={handleOptionChange}
                            >
                              {selectedCategory2.length > 0 ? (
                                selectedCategory2?.map((item, index) => (
                                  <option key={item?.id} value={item?.name}>
                                    {item?.name}
                                  </option>
                                ))
                              ) : (
                                <option value="">Payment Options</option>
                              )}
                              {allPaymentOptions.map((item) => (
                                <option key={item.id} value={item.name}>
                                  {item.name}
                                </option>
                              ))}
                            </select> */}
                            <span className="angle_down">
                              <img
                                src="assets/img/angledown-icon.webp"
                                alt=""
                              />
                            </span>
                          </div>

                          <input
                            type="number"
                            value={inputValue}
                            className="price_value payment_col no-arrow-input"
                            style={{
                              fontWeight: 700,
                              color: "black",
                              fontSize: 18,
                            }}
                            onChange={(e) => {
                              setInputValue(e.target.value);
                            }}
                          />

                          {(inputValue !== 0 && selectedProduct.length > 0) ||
                          selectRetrieveProduct.length > 0 ? (
                            <div className="pay_btn payment_col">
                              <a
                                onClick={handlePaymentClick}
                                style={{ cursor: "pointer" }}
                              >
                                Pay ${inputValue}
                                {/* <a
                          onClick={() => {
                            if (selectedProduct?.length > 0) {
                              handlePay();
                            } else {
                              handlePayRet();
                            }
                          }}
                          //disabled={totalPayment === overallTotal1}
                        > */}
                              </a>{" "}
                            </div>
                          ) : (
                            <div className=" pay_btn payment_col ">
                              <a
                                style={{ cursor: "pointer" }}
                                className="apaybtncstm"
                                href="#"
                                onClick={() => {
                                  Swal.fire("Please Select Product First");
                                }}
                              >
                                Pay $0.00
                              </a>{" "}
                            </div>
                          )}
                        </div>
                        {ErrorAmount === true ? (
                          <span
                            style={{
                              fontSize: "15px",
                              color: "red",
                              fontWeight: "600",
                              marginTop: "20px",
                              textAlign: "center",
                            }}
                          >
                            Amount Should{" "}
                            <i className="fa-solid fa-greater-than"></i> 0
                          </span>
                        ) : null}
                      </div>
                      <div className="complete_pross_btn">
                        {totalPayment.toFixed(2) == overallTotal1.toFixed(2) ? (
                          <>
                            {" "}
                            <a
                              href="#"
                              data-toggle="modal"
                              data-target="#paymentModal"
                              className="complete_btn1"
                              // className={`complete_btn ${
                              //   totalPayment === overallTotal1 ? "complete_btn1" : ""
                              // }`}
                              onClick={(e) => {
                                setErrorAmount(false);
                                e.preventDefault();
                                if (selectedProduct?.length > 0) {
                                  setShowModal9(true);
                                  AddInvoice();
                                } else if (selectRetrieveProduct.length > 0) {
                                  UpdateInvoice();
                                } else {
                                  Swal.fire("Please Select Product First");
                                }
                              }}
                            >
                              Complete
                            </a>
                            <a
                              href=""
                              className="close_btn"
                              onClick={() => {
                                setSelectedProduct([]);
                                setSelectedRetrieveProduct([]);
                              }}
                            >
                              <img src="assets/img/close-icon.webp" alt="" />
                            </a>
                          </>
                        ) : (
                          <>
                            <a
                              href="#"
                              // data-toggle="modal"
                              // data-target="#paymentModal"
                              className="complete_btn"
                              onClick={(e) => {
                                e.preventDefault();
                                if (selectedProduct?.length > 0) {
                                  // setShowModal9(true);
                                  // AddInvoice();
                                } else if (selectRetrieveProduct.length > 0) {
                                  // UpdateInvoice();
                                } else {
                                  Swal.fire("Please Select Product First");
                                }
                              }}
                            >
                              Complete
                            </a>
                            <a
                              href=""
                              className="close_btn"
                              // onClick={() => {
                              //   setSelectedProduct([]);
                              //   setSelectedRetrieveProduct([]);
                              // }}
                            >
                              <img src="assets/img/close-icon.webp" alt="" />
                            </a>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bottom_btns">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="bottom_links">
                        <ul>
                          <li>
                            <a href="#">--</a>
                          </li>
                          <li>
                            <a href="#">--</a>
                          </li>
                          <li
                            onClick={() => {
                              if (
                                selectedProduct.length > 0 ||
                                selectRetrieveProduct.length > 0
                              ) {
                                setShowModal6(true);
                                setDescriptionNote("");
                              } else {
                                setShowModal6(false);
                                setDescriptionNote("");
                              }
                            }}
                          >
                            <a
                              href="#"
                              data-toggle="modal"
                              data-target="#parksaleModal1"
                            >
                              Note
                            </a>
                          </li>
                          <li>
                            <a href="#">Signature</a>
                          </li>

                          <li>
                            <a
                              href="#"
                              onClick={() => {
                                HandleRefresh();
                                // setSelectedProduct([]);
                                // setSelectedRetrieveProduct([]);
                              }}
                            >
                              Start Over
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* <!-- Main Content --> */}

          <Footer />
        </div>

        {showModal === true ? (
          <div
            className="modal common_modal addcustomer_modal"
            id="addcustomerModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="addcustomerModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="addcustomerModalLabel">
                    Add New Customer
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => {
                      handleModalClose();
                      cancleValues();
                    }}
                  >
                    <span className="close_icon"></span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="custom_form customer_form">
                    <form className="cus_form">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <div className="upload_files">
                              {images ? (
                                <img
                                  className="uploaded_img"
                                  src={images}
                                  alt=""
                                />
                              ) : (
                                <img
                                  style={{ display: "none" }}
                                  className="uploaded_img"
                                  src=""
                                  alt=""
                                />
                              )}
                              <div className="file_upload">
                                <input
                                  type="file"
                                  name="files"
                                  accept="image/*"
                                  // onChange={handleChange2}
                                  onChange={handleImages}
                                />
                                <div className="fileedit_icon">
                                  <img
                                    src="assets/img/file-edit-icon.webp"
                                    alt=""
                                  />
                                </div>
                              </div>
                              <div className="filetrash_icon">
                                <a
                                  href="#"
                                  // class="trigger-btn"
                                  // data-toggle="modal"
                                  onClick={() => {
                                    if (images) {
                                      HandleDelete();
                                    }
                                  }}
                                >
                                  <img
                                    src="assets/img/file-trash-icon.webp"
                                    alt=""
                                  />
                                </a>
                              </div>
                            </div>
                            {imageMsg && (
                              <span className="customvalidationdesignlogin">
                                {imageMsg}
                              </span>
                            )}
                          </div>
                        </div>

                        <div className="col-md-12">
                          <h5>Credentials</h5>
                        </div>
                        <div className="col-md-6">
                          <div className="form_column col_half">
                            <div className="customform">
                              <div className="form-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  name="first_name"
                                  placeholder="First Name"
                                  value={firstName}
                                  onChange={(e) => {
                                    setFirstName(e.target.value.trim());
                                    if (error.firstName) {
                                      setError({ ...error, firstName: "" });
                                    }
                                  }}
                                />
                                {error.firstName && (
                                  <span className="customvalidationdesignlogin">
                                    {error.firstName}
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form_column col_half">
                            <div className="customform">
                              <div className="form-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  name="last_name"
                                  placeholder="Last Name"
                                  value={lastName}
                                  onChange={(e) => {
                                    setLastName(e.target.value.trim());
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form_column col_half">
                            <div className="customform">
                              <div className="form-group">
                                <input
                                  type="email"
                                  className="form-control"
                                  name="email"
                                  placeholder="Email Address"
                                  value={email}
                                  onChange={(e) => {
                                    setEmail(e.target.value.trim());
                                  }}
                                />
                                {error.email && (
                                  <span className="customvalidationdesignlogin">
                                    {error.email}
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form_column col_half">
                            <div className="customform">
                              <div className="form-group">
                                <PhoneInput
                                  className="form-control"
                                  placeholder="Enter phone number"
                                  international
                                  countryCallingCodeEditable={false}
                                  //limitMaxLength={10}
                                  value={phoneNo}
                                  onChange={(val) => {
                                    setPhoneNo(val);
                                    // if (error.phoneNo) {
                                    //   setError({ ...error, phoneNo: "" });
                                    // }
                                    setValidState(true);
                                  }}
                                  //   onKeyup={setValidState(true)}
                                  // defaultCountry="IN"
                                  defaultCountry={countrycode.country_code}
                                  //defaultCountry="IN"
                                  // error={
                                  //   value
                                  //     ? isValidPhoneNumber(value)
                                  //       ? undefined
                                  //       : "Invalid phone number"
                                  //     : "Phone number required"
                                  // }
                                />

                                {validstate === true ? (
                                  <span className="customvalidationdesignlogin">
                                    {phoneNo && isValidPhoneNumber(phoneNo)
                                      ? ""
                                      : "Invalid phone number"}
                                  </span>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12">
                          <h5>Address</h5>
                        </div>
                        <div className="col-md-12">
                          <div className="form_column col_full">
                            <div className="customform">
                              <div className="form-group">
                                <textarea
                                  className="form-control"
                                  name="address"
                                  placeholder="Street Address"
                                  value={address}
                                  onChange={(e) => {
                                    setAddress(e.target.value);
                                  }}
                                ></textarea>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form_column col_half">
                            <div className="customform">
                              <div className="form-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  name="country"
                                  placeholder="Country"
                                  value={country}
                                  onChange={(e) => {
                                    setCountry(e.target.value.trim());
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form_column col_half">
                            <div className="customform">
                              <div className="form-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  name="city"
                                  placeholder="City"
                                  value={city}
                                  onChange={(e) => {
                                    SetCity(e.target.value.trim());
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form_column col_half">
                            <div className="customform">
                              <div className="form-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  name="state"
                                  placeholder="State / Province"
                                  value={state}
                                  onChange={(e) => {
                                    setState(e.target.value.trim());
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form_column col_half">
                            <div className="customform">
                              <div className="form-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  name="zipcode"
                                  placeholder="Zip Code"
                                  value={zipcode}
                                  onChange={(e) => {
                                    setZipcode(e.target.value.trim());
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        {message && (
                          <span className="customvalidationdesignlogin">
                            {message}
                          </span>
                        )}
                      </div>
                    </form>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary default_btn"
                    data-dismiss="modal"
                    onClick={() => {
                      handleModalClose();
                      cancleValues();
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary theme_btn"
                    onClick={() => SubmitCus()}
                  >
                    Save Customer
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : null}

        {showModal6 === true ? (
          <>
            <div
              className="modal common_modal parksale_modal"
              id="parksaleModal1"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="parksaleModalLabel"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-dialog-centered"
                role="document"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="parksaleModalLabel">
                      Note
                    </h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={() => {
                        setShowModal6(false);
                        setDescriptionNote("");

                        document
                          .querySelectorAll(".modal-backdrop")
                          .forEach((el) =>
                            el.classList.remove("modal-backdrop")
                          );
                      }}
                    >
                      <span className="close_icon"></span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <div className="custom_form parksale_form">
                      <form action="" method="" className="cus_form">
                        <div className="form_row">
                          <div className="form_column col_full">
                            <div className="form-group">
                              <textarea
                                className="form-control"
                                name="address"
                                placeholder="Add Note"
                                value={descriptionNote}
                                onChange={handleDescriptionChange}
                              ></textarea>{" "}
                              {errormsg && (
                                <span
                                  style={{
                                    fontSize: "12px",
                                    color: "red",
                                  }}
                                >
                                  {errormsg}
                                </span>
                              )}
                              <span className="span_note">
                                Max Characters 300
                              </span>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-primary theme_btn"
                      onClick={() => {
                        setShowModal6(false);
                        //setDescriptionNote("");

                        document
                          .querySelectorAll(".modal-backdrop")
                          .forEach((el) =>
                            el.classList.remove("modal-backdrop")
                          );
                      }}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : null}

        {showModal7 === true ? (
          <>
            <div
              className="modal common_modal parksale_modal"
              id="parksaleModal"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="parksaleModalLabel"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-dialog-centered"
                role="document"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="parksaleModalLabel">
                      Park Sale
                    </h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={() => {
                        setShowModal7(false);

                        document
                          .querySelectorAll(".modal-backdrop")
                          .forEach((el) =>
                            el.classList.remove("modal-backdrop")
                          );
                      }}
                    >
                      <span className="close_icon"></span>
                    </button>
                    <p>
                      Sale can be parked, and retrieved at a later point.
                      <br /> Add a note for the reason of parking.
                    </p>
                  </div>
                  <div className="modal-body">
                    <div className="custom_form parksale_form">
                      <form action="" method="" className="cus_form">
                        <div className="form_row">
                          <div className="form_column col_full">
                            <div className="form-group">
                              <textarea
                                className="form-control"
                                name="address"
                                placeholder="Add Note"
                                value={parkNote}
                                onChange={(e) => handleDescriptionChangepark(e)}
                              ></textarea>
                              <span className="span_note">
                                Max Characters 300
                              </span>
                              {errormsg && (
                                <span
                                  style={{
                                    fontSize: "12px",
                                    color: "red",
                                  }}
                                >
                                  {errormsg}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-primary theme_btn"
                      onClick={() => {
                        AddInvoicePark();
                        setSelectedProduct([]);

                        // setShowModal7(false);

                        // document
                        //   .querySelectorAll(".modal-backdrop")
                        //   .forEach((el) => el.classList.remove("modal-backdrop"));
                      }}
                    >
                      Park
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : null}

        {showModal3 === true ? (
          <>
            <div
              className="modal common_modal newproduct_modal"
              id="newproductModal"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="newproductModalLabel"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-dialog-centered"
                role="document"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="newproductModalLabel">
                      New Product
                    </h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={() => {
                        {
                          setShowModal3(false);

                          document
                            .querySelectorAll(".modal-backdrop")
                            .forEach((el) =>
                              el.classList.remove("modal-backdrop")
                            );
                        }
                      }}
                    >
                      <span className="close_icon"></span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <div className="product_list">
                      <div
                        className="product_col"
                        onClick={() => {
                          setShowModal4(true);
                          setShowModal3(false);
                        }}
                      >
                        <a
                          href="#"
                          data-toggle="modal"
                          data-target="#createproductModal"
                        >
                          <h5>Standard</h5>
                          <p>
                            Most Physical product and services including parts,
                            except devices with Imei/ESN
                          </p>
                        </a>
                      </div>
                      <div
                        className="product_col"
                        onClick={() => {
                          setShowModal5(true);
                          setShowModal3(false);
                        }}
                      >
                        <a
                          href="#"
                          data-toggle="modal"
                          data-target="#createdeviceModal"
                        >
                          <h5>Devices</h5>
                          <p>Products with serial, IMEI, ESN, MEID</p>
                        </a>
                      </div>
                      <div className="product_col coming_product">
                        <a href="#">
                          <img src="assets/img/comingsoon-img.webp" alt="" />
                          <h5>Coming Soon...</h5>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : null}
        {showModal === true ? (
          <>
            <div
              className="modal common_modal editinfo_modal"
              id="editinfoModal"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="editinfoModalLabel"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-dialog-centered"
                role="document"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="editinfoModalLabel">
                      Edit Information
                    </h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={() => {
                        resetFormValues(); // Reset form values
                        setShowModal(false); // Close the modal
                      }}
                    >
                      <span className="close_icon"></span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <div className="custom_form editinfo_form">
                      <form action="" method="" className="cus_form">
                        <div className="form_row">
                          <div className="form_column col_three">
                            <div className="form_field price_field">
                              <div className="form-group">
                                <label>Unit Price</label>
                                <input
                                  type="number"
                                  className="form-control no-arrow-input"
                                  name="unit_price"
                                  placeholder="Enter Price"
                                  value={
                                    unitCosts[editedProductIndex] === 0
                                      ? ""
                                      : unitCosts[editedProductIndex]
                                  }
                                  onChange={(e) =>
                                    setUnitCosts((prevUnitCosts) => {
                                      const newUnitCosts = [...prevUnitCosts];
                                      newUnitCosts[editedProductIndex] =
                                        e.target.value;
                                      return newUnitCosts;
                                    })
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          {/* <div class="form_column col_three">
                          <div class="form_field quantity_field">
                            <div class="form-group">
                              <label>Purchase Quantity</label>
                              <input
                                type="number"
                                class="form-control no-arrow-input"
                                name="quantity"
                                placeholder="Enter Quantity"
                                value={
                                  purchaseQuantities[editedProductIndex] === 0
                                    ? ""
                                    : purchaseQuantities[editedProductIndex]
                                }
                                onChange={(e) =>
                                  setPurchaseQuantities(
                                    (prevPurchaseQuantities) => {
                                      const newPurchaseQuantities = [
                                        ...prevPurchaseQuantities,
                                      ];
                                      newPurchaseQuantities[
                                        editedProductIndex
                                      ] = e.target.value;
                                      return newPurchaseQuantities;
                                    }
                                  )
                                }
                              />
                            </div>
                          </div>
                        </div> */}
                          <div className="form_column col_three">
                            <div className="form_field quantity_field">
                              <div className="form-group">
                                <label> Sales Quantity</label>
                                <input
                                  type="number"
                                  className="form-control no-arrow-input"
                                  name="quantity"
                                  placeholder="Enter Quantity"
                                  value={
                                    receivedQuantities[editedProductIndex] === 0
                                      ? receivedQuantities
                                      : receivedQuantities[editedProductIndex]
                                  }
                                  onChange={(e) =>
                                    setReceivedQuantities(
                                      (prevReceivedQuantities) => {
                                        const newReceivedQuantities = [
                                          ...prevReceivedQuantities,
                                        ];
                                        newReceivedQuantities[
                                          editedProductIndex
                                        ] = e.target.value;
                                        return newReceivedQuantities;
                                      }
                                    )
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          <div className="form_column col_three">
                            <div className="form_field discount_field">
                              <div className="form-group">
                                <label>Discount</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="discount"
                                  placeholder="Enter Discount"
                                  value={discounts[editedProductIndex]}
                                  onChange={(e) => {
                                    const newDiscounts = [...discounts];
                                    newDiscounts[editedProductIndex] =
                                      e.target.value;
                                    setDiscounts(newDiscounts);
                                  }}
                                />
                                <div className="price_type">
                                  <h5>%</h5>
                                  {/* <select
                                  value={discounts[editedProductIndex]}
                                  onChange={(e) => {
                                    const newDiscounts = [...discounts];
                                    newDiscounts[editedProductIndex] = parseInt(
                                      e.target.value,
                                      10
                                    );
                                    setDiscounts(newDiscounts);
                                  }}
                                >
                                  <option value={10}>%</option>
                                </select> */}
                                  {/* <img
                                  src="assets/img/angledown-icon.webp"
                                  alt=""
                                /> */}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="form_row">
                            <div className="form_column col_full">
                              <div className="form_field note_field">
                                <div className="form-group">
                                  {/* <label>Note:</label> */}
                                  <textarea
                                    className="form-control"
                                    name="note"
                                    placeholder="Enter your note"
                                    value={
                                      note[editedProductIndex] === 0
                                        ? ""
                                        : note[editedProductIndex]
                                    }
                                    onChange={
                                      handleDescriptionChangeNote
                                      // setNote((prevUnitCosts) => {
                                      //   const newUnitCosts = [...prevUnitCosts];
                                      //   newUnitCosts[editedProductIndex] =
                                      //     e.target.value;
                                      //   return newUnitCosts;
                                      // })
                                    }
                                  ></textarea>
                                  <span className="span_note">
                                    Max Characters 300
                                  </span>
                                  {errormsg && (
                                    <span
                                      style={{
                                        fontSize: "12px",
                                        color: "red",
                                      }}
                                    >
                                      {errormsg}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary default_btn"
                      data-dismiss="modal"
                      // onClick={() => setShowModal(false)}
                      onClick={() => {
                        resetFormValues(); // Reset form values
                        setShowModal(false); // Close the modal
                      }}
                    >
                      Cancel
                    </button>
                    {selectedProduct.length ? (
                      <button
                        type="button"
                        className="btn btn-primary theme_btn"
                        data-dismiss="modal"
                        onClick={() => handleUpdate(setSelectedProduct)}
                      >
                        Update Selected Product
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-primary theme_btn"
                        data-dismiss="modal"
                        onClick={() => handleUpdate(setSelectedRetrieveProduct)}
                      >
                        Update Retrieved Product
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : null}
        {showModal8 === true ? (
          <div
            className="modal common_modal newservice_modal"
            id="newserviceModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="newserviceModalLabel"
            aria-hidden="true"
          >
            <Ticket />
          </div>
        ) : null}

        {showModal4 === true ? (
          <>
            <div
              className="modal common_modal createproduct_modal"
              id="createproductModal"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="createproductModalLabel"
              aria-hidden="true"
            >
              <ProductModal />
            </div>
          </>
        ) : null}

        {showModal5 === true ? (
          <>
            <div
              className="modal common_modal createdevice_modal"
              id="createdeviceModal"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="createdeviceModalLabel"
              aria-hidden="true"
            >
              <DeviceModal />
            </div>
          </>
        ) : null}

        {showModal10 === true ? (
          <>
            {" "}
            <div
              className="modal common_modal cases_modal"
              id="casesModal"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="casesModalLabel"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-dialog-centered"
                role="document"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="casesModalLabel">
                      Products
                    </h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={() => {
                        setShowModal10(false);

                        setProductByCategory([]);
                      }}
                    >
                      <span className="close_icon"></span>
                    </button>
                    {/* {loading2 ? (
                      <p>Loading...</p>
                    ) : productByCategory.length > 0 ? (
                      <p>Select Product:</p>
                    ) : (
                      <p>No Products available</p>
                    )} */}
                    {/* {productByCategory.length > 0 ? (
                      <p> Select Product:</p>
                    ) : (
                      <p>No Products available</p>
                    )} */}
                    <p> Select Product:</p>
                  </div>
                  {loading2 ? <p>Loading...</p> : null}
                  <div className="modal-body">
                    <div className="cases_list">
                      <ul>
                        {productByCategory?.map((item, index) => (
                          <li
                            onClick={() => {
                              handleCustomerSelectionProduct(
                                item,
                                item?.imeiNumber
                              );
                              setShowModal10(false);
                              setProductByCategory([]);
                              document
                                .querySelectorAll(".modal-backdrop")
                                .forEach((el) =>
                                  el.classList.remove("modal-backdrop")
                                );
                            }}
                          >
                            <a href="#">
                              {ImageOnOff === 1 ? (
                                item?.image ? (
                                  <img src={item?.image} alt="" />
                                ) : (
                                  <img
                                    src="assets/img/casemodal-img.png"
                                    alt=""
                                  />
                                )
                              ) : null}{" "}
                              <span>{item?.title}</span>
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : null}
        {showModal9 === true ? (
          <>
            <div
              className={`modal common_modal payment_modal ${
                showModal9 ? "active" : ""
              }`}
              id="paymentModal"
              tabIndex={-1}
              role="dialog"
              aria-labelledby="paymentModalLabel"
              aria-hidden="true"
              data-backdrop="static" // Prevent closing when clicking outside
              data-keyboard="false" // Prevent closing when pressing escape
            >
              <div
                className="modal-dialog modal-dialog-centered"
                role="document"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={() => {
                        setSelectedProduct([]);
                        setSelectedRetrieveProduct([]);
                        setPaymentMode([]);
                        setPaymnetState(false);
                        setSelectedCustomer({});
                        setShowModal9(false);
                        setSelectedState(false);
                        setDiscounts([]);
                        updateTotalSalesQuantity();
                        setTotalSalesQty(0);
                        setReceivedQuantities([]);
                        // setSelectedOptions("");
                        // setSelectedPaymentType("");
                        // setSelectedPaymentTypeId("");
                        // setSelectedCategory2("");
                        setDiscount(0);
                        setUnitCosts([]);
                        document
                          .querySelectorAll(".modal-backdrop")
                          .forEach((el) =>
                            el.classList.remove("modal-backdrop")
                          );
                      }}
                    >
                      <span className="close_icon" />
                    </button>
                  </div>
                  <div className="modal-body">
                    <div className="payment_receipt_btns">
                      <div className="receipt_btn">
                        <div className="receipt_col colhalf">
                          {selectedCustomer?.firstName &&
                          selectedCustomer?.email ? (
                            <>
                              <a
                                href="#"
                                className="recept_btn recpt_email_btn"
                                onClick={() => {
                                  EmailSend();
                                }}
                              >
                                Email
                              </a>
                            </>
                          ) : (
                            <>
                              <a
                                href="#"
                                data-toggle="modal"
                                data-target="#parksaleModal"
                                className="recept_btn recpt_email_btn"
                                onClick={() => {
                                  setShowModal11(true);
                                  //  EmailSend();
                                }}
                              >
                                Email
                              </a>
                            </>
                          )}
                        </div>
                        <div className="receipt_col colhalf">
                          <a
                            href="#"
                            data-dismiss="modal"
                            aria-label="Close"
                            className="recept_btn recpt_noreceipt_btn"
                            onClick={() => {
                              setSelectedProduct([]);
                              setSelectedRetrieveProduct([]);
                              setPaymentMode([]);
                              setPaymnetState(false);
                              setSelectedCustomer({});
                              setShowModal9(false);
                              setSelectedState(false);
                              setDiscounts([]);
                              updateTotalSalesQuantity();
                              setTotalSalesQty(0);
                              setReceivedQuantities([]);
                              setUnitCosts([]);
                              // setSelectedOptions("");
                              // setSelectedPaymentType("");
                              // setSelectedPaymentTypeId("");
                              // setSelectedCategory2("");
                              setDiscount(0);
                              // setSelectedProduct([]);
                              // setSelectedRetrieveProduct([]);
                              // setPaymentMode([]);
                              // setPaymnetState(false);
                              // setSelectedCustomer({});
                              // setShowModal9(false);
                              // setSelectedState(false);
                              // setDiscounts([]);
                              document
                                .querySelectorAll(".modal-backdrop")
                                .forEach((el) =>
                                  el.classList.remove("modal-backdrop")
                                );
                            }}
                          >
                            No Receipt
                          </a>
                        </div>

                        <div className="receipt_col colfull">
                          {printType === 2 || reciept === true ? (
                            <a
                              href="#"
                              className="recept_btn recpt_print_btn"
                              onClick={printSetup}
                            >
                              Print Receipt
                            </a>
                          ) : (
                            <a
                              href="#"
                              className="recept_btn recpt_print_btn"
                              onClick={printTable}
                            >
                              Print Receipt
                            </a>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : null}

        {showModal11 === true ? (
          <>
            <div
              className="modal common_modal parksale_modal"
              id="parksaleModal"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="parksaleModalLabel"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-dialog-centered"
                role="document"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="parksaleModalLabel">
                      Email
                    </h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={() => {
                        setShowModal11(false);
                        setEmail("");

                        document
                          .querySelectorAll(".modal-backdrop")
                          .forEach((el) =>
                            el.classList.remove("modal-backdrop")
                          );
                      }}
                    >
                      <span className="close_icon"></span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <div className="custom_form parksale_form">
                      <form className="cus_form">
                        <div className="form_row">
                          <div className="form_column col_full">
                            <div className="form-group">
                              <input
                                type="email"
                                className="form-control"
                                name="address"
                                placeholder="Enter Email"
                                value={email}
                                onChange={(e) => {
                                  setEmail(e.target.value.trim());
                                  if (error.email) {
                                    setError({ ...error, email: "" });
                                  }
                                }}
                              />
                              {error.email && (
                                <span className="customvalidationdesignlogin">
                                  {error.email}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-primary theme_btn"
                      onClick={() => {
                        OnSubmit();
                      }}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : null}
        <div style={{ display: "none" }}>
          <div className="reciepttable">
            <div
              style={{
                width: "80mm",
                // margin: "auto",
                // padding: "auto",
                fontFamily: "Arial, sans-serif",
                height: "auto",
                fontSize: 12,
                // leftMargin: `${leftMargin}px`,
                // rightMargin: `${rightMargin}px`,
              }}
              border={0}
            >
              <div>
                <div>
                  <div>
                    <div
                      style={{ width: "100%", textAlign: "center" }}
                      border={0}
                    >
                      <div>
                        <div>
                          {isLogo && logo ? (
                            <div
                              style={{
                                textAlign:
                                  logoPosition === 0 ? "left" : "center",
                                marginBottom: 25,
                              }}
                            >
                              <img
                                src={logo}
                                alt="Logo"
                                style={{
                                  width: logoSize === 1 ? 300 : 50,
                                  height: logoSize === 1 ? 110 : 55,
                                  // objectFit: "cover",
                                  // display: "block",
                                  margin: "0 auto",
                                }}
                              />
                            </div>
                          ) : null}
                        </div>

                        <div>
                          <div
                            style={{
                              fontSize: 12,
                              fontWeight: 700,
                              color: "#000",
                            }}
                          >
                            {title ? title : "--"}
                          </div>
                        </div>
                        <div>
                          <div
                            style={{
                              fontSize: 12,
                              fontWeight: 500,
                              marginTop: 20,
                              color: "#000",
                            }}
                          >
                            {header
                              ? header.trim().replace(/\n/g, "<br>")
                              : "--"}
                            {/* <pre
                              style={{
                                fontSize: 12,
                                fontWeight: 500,
                                color: "#000",
                                //marginTop: 20,
                              }}
                            >
                              {header ? header : "--"}
                            </pre> */}
                          </div>
                        </div>
                        <div>
                          <div style={{ padding: "8px 0px" }} />
                        </div>
                        <div></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div>
                    <div style={{ width: "100%" }} border={0}>
                      <div>
                        <div>
                          <div
                            style={{
                              fontSize: 12,
                              fontWeight: 400,

                              color: "#000",
                            }}
                          >
                            <span style={{ fontWeight: 700 }}>Sales Ref:</span>{" "}
                            #{InvoiceData1?.id}
                          </div>
                        </div>
                        <div>
                          <div
                            style={{
                              fontSize: 12,
                              fontWeight: 400,
                              //lineHeight: 20,
                              color: "#000",
                            }}
                          >
                            <span
                              style={{
                                fontWeight: 700,
                              }}
                            >
                              Customer:
                            </span>{" "}
                            {customerDetails1.fullname === 1 &&
                            InvoiceData1?.customer?.firstName
                              ? `${InvoiceData1?.customer?.firstName} 
                              }`
                              : "unassigned"}
                            {customerDetails1.email === 1 &&
                            InvoiceData1?.customer?.email ? (
                              <>
                                {" "}
                                <br />
                                <span
                                  style={{
                                    fontWeight: 700,
                                    marginTop: 28,
                                  }}
                                >
                                  Email:
                                </span>
                                {InvoiceData1?.customer?.email}
                              </>
                            ) : null}
                            {customerDetails1.phone === 1 &&
                            InvoiceData1?.customer?.phoneNumber ? (
                              <>
                                {" "}
                                <br />
                                <span
                                  style={{
                                    fontWeight: 700,
                                    marginTop: 28,
                                  }}
                                >
                                  Phone No.:
                                </span>
                                {InvoiceData1?.customer?.phoneNumber}
                              </>
                            ) : null}
                            {customerDetails1.address === 1 &&
                            InvoiceData1?.customer?.streetAdress ? (
                              <>
                                {" "}
                                <br />
                                <span
                                  style={{
                                    fontWeight: 700,
                                    marginTop: 28,
                                  }}
                                >
                                  Address:
                                </span>
                                {InvoiceData1?.customer?.streetAdress}
                              </>
                            ) : null}
                          </div>
                        </div>
                        <div>
                          <div
                            style={{
                              fontSize: 12,
                              fontWeight: 400,
                              color: "#000",
                              marginTop: 0,
                            }}
                          >
                            {moment(
                              InvoiceData1.createdAt,
                              "YYYY/MM/DD"
                            ).format("DD/MM/YYYY")}
                            {"  "}
                            {moment(InvoiceData1?.createdAt).format("LT")}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div style={{ padding: "4px 0px" }} />
                </div>
                <div>
                  <div>
                    <table
                      className="tabledata"
                      style={{ width: "100%" }}
                      border={0}
                    >
                      <thead>
                        <tr>
                          <th
                            style={{
                              fontSize: 12,
                              fontWeight: 700,
                              marginTop: 20,
                              color: "#000",
                              textAlign: "center",
                              borderBottom: "2px dashed #000",
                              padding: 4,
                            }}
                          >
                            QTY
                          </th>
                          <th
                            style={{
                              fontSize: 12,
                              fontWeight: 700,
                              marginTop: 20,
                              color: "#000",
                              textAlign: "left",
                              borderBottom: "2px dashed #000",
                              padding: 4,
                            }}
                          >
                            Item Name
                          </th>
                          <th
                            style={{
                              fontSize: 12,
                              fontWeight: 700,
                              marginTop: 20,
                              color: "#000",
                              textAlign: "right",
                              borderBottom: "2px dashed #000",
                              padding: 4,
                            }}
                          >
                            Discount
                          </th>
                          <th
                            style={{
                              fontSize: 12,
                              fontWeight: 700,
                              marginTop: 20,
                              color: "#000",
                              textAlign: "right",
                              borderBottom: "2px dashed #000",
                              padding: 4,
                            }}
                          >
                            Amount
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {Items.map((item, index) => {
                          const correspondingItem = ItemData?.find(
                            (itemId) => itemId?.id === item?.itemId
                          );
                          const subtotal = parseFloat(InvoiceData1.subTotal);
                          const discountPercentage = parseFloat(
                            InvoiceData1.discount
                          );
                          const taxRate = parseFloat(InvoiceData1.tax);

                          const discountedSubtotal =
                            subtotal * (1 - discountPercentage / 100);
                          const taxAmount =
                            discountedSubtotal * (taxRate / 100);
                          const overallTotal1 = discountedSubtotal + taxAmount;
                          return (
                            <tr>
                              <td
                                style={{
                                  fontSize: 12,
                                  fontWeight: 500,
                                  marginTop: 20,
                                  color: "#000",
                                  textAlign: "center",
                                  padding: 4,
                                }}
                              >
                                {item?.quantity}
                              </td>
                              <td
                                style={{
                                  fontSize: 12,
                                  fontWeight: 500,
                                  marginTop: 20,
                                  color: "#000",
                                  textAlign: "left",
                                  padding: 4,
                                }}
                              >
                                {selectedOption?.prodname === 1 &&
                                selectedOption?.sku === 0 &&
                                selectedOption?.prodname_sku === 0
                                  ? correspondingItem?.title
                                  : ""}
                                {selectedOption?.sku === 1 &&
                                selectedOption?.prodname === 0 &&
                                selectedOption?.prodname_sku === 0
                                  ? correspondingItem?.skuCode
                                  : ""}
                                {selectedOption?.prodname_sku === 1 &&
                                selectedOption?.prodname === 1 &&
                                selectedOption?.sku === 1
                                  ? `${correspondingItem?.title}(${correspondingItem?.skuCode})`
                                  : ""}
                                {/* {selectedOption.prodname === 1
                                  ? correspondingItem?.title
                                  : "--"} */}
                              </td>
                              <td
                                style={{
                                  fontSize: 12,
                                  fontWeight: 500,
                                  marginTop: 20,
                                  color: "#000",
                                  textAlign: "right",
                                  padding: 4,
                                }}
                              >
                                {item?.discount}
                              </td>
                              <td
                                style={{
                                  fontSize: 12,
                                  fontWeight: 500,
                                  marginTop: 20,
                                  color: "#000",
                                  textAlign: "right",
                                  padding: 4,
                                }}
                              >
                                {item?.total}
                              </td>
                            </tr>
                          );
                        })}

                        <tr>
                          <td
                            style={{
                              fontSize: 12,
                              fontWeight: 500,
                              // lineHeight: 20,
                              color: "#000",
                              textAlign: "center",
                              padding: 4,
                              marginTop: 20,
                              borderBottom: "2px dashed #000",
                            }}
                          ></td>
                          <td
                            style={{
                              fontSize: 12,
                              fontWeight: 500,
                              //lineHeight: 20,
                              color: "#000",
                              textAlign: "left",
                              padding: 4,
                              marginTop: 20,
                              borderBottom: "2px dashed #000",
                            }}
                          ></td>
                          <td
                            style={{
                              fontSize: 12,
                              fontWeight: 500,
                              //lineHeight: 20,
                              color: "#000",
                              textAlign: "right",
                              padding: 4,
                              marginTop: 20,
                              borderBottom: "2px dashed #000",
                            }}
                          ></td>
                          <td
                            style={{
                              fontSize: 12,
                              fontWeight: 500,
                              //lineHeight: 20,
                              color: "#000",
                              textAlign: "right",
                              padding: 4,
                              marginTop: 20,
                              borderBottom: "2px dashed #000",
                            }}
                          ></td>
                        </tr>
                      </tbody>
                      <tfoot>
                        <tr>
                          <td
                            style={{
                              fontSize: 12,
                              fontWeight: 500,
                              marginTop: 20,
                              color: "#000",
                              textAlign: "left",
                              padding: 4,
                            }}
                            colSpan={2}
                          >
                            Subtotal
                          </td>
                          <td
                            style={{
                              fontSize: 12,
                              fontWeight: 500,
                              marginTop: 20,
                              color: "#000",
                              textAlign: "right",
                              padding: 4,
                            }}
                            colSpan={2}
                          >
                            ${InvoiceData1?.subTotal}
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              fontSize: 12,
                              fontWeight: 400,
                              marginTop: 20,
                              color: "#000",
                              padding: 4,
                            }}
                            colSpan={2}
                          >
                            Discounts ({InvoiceData1.discount || 0}%)
                          </td>
                          <td
                            style={{
                              fontSize: 12,
                              fontWeight: 500,
                              marginTop: 20,
                              color: "#000",
                              textAlign: "right",
                              padding: 4,
                            }}
                            colSpan={2}
                          >
                            $
                            {(
                              parseFloat(InvoiceData1?.subTotal) -
                              parseFloat(discountedSubtotal)
                            ).toFixed(2) || 0}
                            {/* {discountedSubtotal.toFixed(2)} */}
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              fontSize: 12,
                              fontWeight: 500,
                              marginTop: 20,
                              color: "#000",
                              textAlign: "left",
                              padding: 4,
                            }}
                            colSpan={2}
                          >
                            Tax ({InvoiceData1.tax || 0}%)
                          </td>
                          <td
                            style={{
                              fontSize: 12,
                              fontWeight: 500,
                              marginTop: 20,
                              color: "#000",
                              textAlign: "right",
                              padding: 4,
                            }}
                            colSpan={2}
                          >
                            ${taxAmount.toFixed(2) || 0}
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              fontSize: 12,
                              fontWeight: 700,
                              marginTop: 20,
                              color: "#000",
                              textAlign: "left",
                              borderBottom: "2px dashed #000",
                              padding: 4,
                            }}
                            colSpan={2}
                          >
                            Grand Total
                          </td>
                          <td
                            style={{
                              fontSize: 12,
                              fontWeight: 700,
                              marginTop: 20,
                              color: "#000",
                              textAlign: "right",
                              borderBottom: "2px dashed #000",
                              padding: 4,
                            }}
                            colSpan={2}
                          >
                            ${InvoiceData1?.grandTotal}
                            {/* ${overallTotal1.toFixed(2)} */}
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
                <div>
                  <div style={{ padding: "8px 0px" }} />
                </div>
                <div>
                  <div>
                    <table style={{ width: "100%" }} border={0}>
                      <tbody>
                        {payment.map((item) => (
                          <>
                            <tr>
                              <td
                                style={{
                                  fontSize: 12,
                                  fontWeight: 400,
                                  //lineHeight: 20,
                                  color: "#000",
                                  marginTop: 20,
                                }}
                              >
                                <span
                                  style={{
                                    fontWeight: 600,
                                  }}
                                >
                                  Received:
                                </span>{" "}
                                ${item.payAmount}
                              </td>
                              <td
                                style={{
                                  fontSize: 12,
                                  fontWeight: 400,
                                  //lineHeight: 20,
                                  color: "#000",
                                  marginTop: 20,
                                }}
                              >
                                <span
                                  style={{
                                    fontWeight: 700,
                                  }}
                                >
                                  {item.paymentType}
                                  {"  "}
                                </span>
                                {item.date}{" "}
                                {moment(item.time, "HH:mm:ss").format(
                                  "hh:mm A"
                                )}{" "}
                              </td>
                            </tr>{" "}
                          </>
                        ))}

                        <tr>
                          <td
                            style={{
                              fontSize: 12,
                              fontWeight: 400,
                              marginTop: 20,
                              color: "#000",
                            }}
                            colSpan={2}
                          >
                            Change Given:{" "}
                            <span style={{ fontWeight: 700 }}>
                              ${InvoiceData1?.due ? InvoiceData1?.due : 0}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              fontSize: 12,
                              fontWeight: 400,
                              marginTop: 20,
                              color: "#000",
                            }}
                            colSpan={2}
                          >
                            <span style={{ fontWeight: 700 }}>Cashier:</span>{" "}
                            {(adminState === 0 || permission.length > 0) &&
                              dataUser?.userDetails?.name}
                            {(adminState === 1 || permission.length === 0) &&
                              data1?.userDetails?.userName}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div>
                  <div style={{ padding: "12px 0px" }} />
                </div>
                <div>
                  <div>
                    <div
                      style={{ width: "100%", textAlign: "center" }}
                      border={0}
                    >
                      <div>
                        <div>
                          {" "}
                          <div
                            style={{
                              textAlign: "justify",
                              fontSize: "12px",
                              fontWeight: "400",
                              color: "#000",
                              whiteSpace: "pre-wrap",
                              // Preserve whitespace and allow wrapping
                              wordWrap: "break-word", // Allow long words to break
                              maxWidth: "100%",
                            }}
                            // style={{
                            //   textAlign: "center",
                            //   fontSize: "12px",
                            //   fontWeight: "400",
                            //   color: "#000",
                            // }}
                          >
                            <pre
                              style={{
                                textAlign: "justify",
                                fontSize: "12px",
                                fontWeight: 400,
                                color: " #000",
                                whiteSpace: "pre-wrap",
                                wordWrap: "break-word",
                                maxWidth: "100%",
                              }}
                            >
                              {footer ? footer : "--"}
                            </pre>
                            {/* <div
                            style={{
                              fontSize: 12,
                              fontWeight: 400,
                              marginTop: 10,
                              color: "#000",
                            }}
                          >
                            <pre> {footer ? footer : "--"}</pre> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <div>
                    <div
                      style={{ width: "100%", textAlign: "center" }}
                      border={0}
                    >
                      <div>
                        {gstShow === 1 ? (
                          <div>
                            <div
                              style={{
                                fontSize: 12,
                                fontWeight: 400,
                                marginTop: 10,
                                color: "#000",
                              }}
                            >
                              Hst #: {gstNumber ? gstNumber : "--"}
                            </div>
                          </div>
                        ) : null}
                        {/* <div>
                        <div>
                          <img src="img/receipt-barcode.jpg" alt="" />
                        </div>
                      </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default CashRegister;
