import React, { useEffect, useState } from "react";
import $ from "jquery";
import SideUl from "./SideUl";
import MainSidebar from "./MainSidebar";
import {
  baseurl,
  GetAllColorApi,
  GetAllConditionApi,
  GetAllStorageApi,
  GetProductDeviceApi,
  ImportDeviceApi,
} from "../Utility/Api";
import Footer from "../Footer";
import { Helmet } from "react-helmet";
import DeviceModalList from "./DeviceModalList";
import { Select2 } from "select2";
import Swal from "sweetalert2";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import DeviceModalPop from "./DeviceModalPop";
import FreeTrail from "./FreeTrail";
import { CSVLink } from "react-csv";
import InventoryModal from "./InventoryModal";

const DeviceList = () => {
  const [allBrand, setAllBrand] = useState([]);
  const [allColor, setAllColor] = useState([]);
  const [allStorage, setAllStorage] = useState([]);
  const [allCondition, setAllCondition] = useState([]);
  const [allProduct, setAllProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [showModal1, setShowModal1] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("Filter Table");
  const [isSortAsc, setIsSortAsc] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCategory1, setSelectedCategory1] = useState("default");
  const [selectedCategory2, setSelectedCategory2] = useState("default");
  const [selectedCategory3, setSelectedCategory3] = useState("default");
  const [selectedCategory4, setSelectedCategory4] = useState("default");
  const [selectedCategory5, setSelectedCategory5] = useState("default");
  const [selectedCategory6, setSelectedCategory6] = useState("default");
  const [allCarrier, setAllCarrier] = useState([]);
  const [sortState, setSortState] = useState(null);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [filteredDataa, setFilteredData] = useState([]);
  const [AllModel, setAllModel] = useState([]);
  const [adminState, setAdminState] = useState("");
  const [itemState, setTimeState] = useState({});
  const [csvFile, setCsvFile] = useState("");

  const handleBrandChange = (value) => {
    setSelectedCategory2(value);
    if (value === "default") {
      setFilteredData(filteredData);
    } else {
      setFilteredData(
        filteredData.filter((item) => item?.brand?.name === value)
      );
      setAllModel(
        filteredData.filter((item) => item?.brand?.name === value && item.title)
      );
    }
  };

  const handleStorageChange = (value) => {
    setSelectedCategory1(value);
    if (value === "default") {
      setFilteredData(filteredData);
    } else {
      setFilteredData(filteredData.filter((item) => item?.title === value));
    }
  };

  const handleSupplierChange = (value) => {
    setSelectedCategory3(value);
    if (value === "default") {
      setFilteredData(filteredData);
    } else {
      setFilteredData([
        ...filteredData.filter(
          (item) =>
            item?.condition?.condition && item?.condition?.condition === value
        ),
      ]);
    }
  };

  const handleColorChange = (value) => {
    setSelectedCategory5(value);
    if (value === "default") {
      setFilteredData(filteredData);
    } else {
      setFilteredData([
        ...filteredData.filter(
          (item) => item?.color?.name && item?.color?.name === value
        ),
      ]);
    }
  };

  const handleCarrierChange = (value) => {
    setSelectedCategory6(value);
    if (value === "default") {
      setFilteredData(filteredData);
    } else {
      setFilteredData([
        ...filteredData.filter(
          (item) => item?.carrier?.name && item?.carrier?.name === value
        ),
      ]);
    }
  };

  const handleSearchTermChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSelectRow = (id) => {
    // Toggle selected state for a specific item
    const newSelectedItems = selectedItems.includes(id)
      ? selectedItems.filter((itemId) => itemId !== id)
      : [...selectedItems, id];

    setSelectedItems(newSelectedItems);
  };

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    const newSelectedItems = selectAll ? [] : allProduct.map((item) => item.id);
    setSelectedItems(newSelectedItems);
  };
  const handlePageClick = (newPage) => {
    setCurrentPage(newPage);
  };

  const GetAllBrand = () => {
    let local = localStorage.getItem("token");
    //console.log(local);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);
    myHeaders.append(
      "Cookie",
      "connect.sid=s%3AEXeh7T29UatkCPBdNwoIzBrcxDT_mEJd.RTtxRKFGO9m4w4ldAFV3Z016ghUV6rHgNPsCPhrrqgQ"
    );

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${baseurl}getAllBrand?productCategoryId=2`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log("device all brands", result);
        setAllBrand(result?.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const GetAllStorage = () => {
    let local = localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(GetAllStorageApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        //console.log(result);
        setAllStorage(result?.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const GetAllColor = () => {
    let local = localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(GetAllColorApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setAllColor(result?.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const GetAllCondition = () => {
    let local = localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(GetAllConditionApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setAllCondition(result?.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const GettAllCarrier = () => {
    let local = localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);
    myHeaders.append(
      "Cookie",
      "connect.sid=s%3A-_3JwaXdjnqK5jnz_MupfyYwBOQ-xwpN.F15%2BK8LY79Ye8glfaA8Fk2LX1rgoPDz%2BrF7ZAbnGd58"
    );

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${baseurl}getAllCarrier?productCategoryId=1`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setAllCarrier(result?.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  useEffect(() => {
    let localAdmin = localStorage.getItem("admin");
    let localPerminssion = JSON.parse(localStorage.getItem("permission"));

    setAdminState(localAdmin);
    GetAllBrand();
    GetAllStorage();
    GetAllColor();
    GetAllCondition();
    // GetAllDevice();
    GetAllProducts();
    GettAllCarrier();
  }, []);

  const onpress = () => {
    setShowModal(false);
  };
  const onpress1 = () => {
    setShowModal1(false);
  };
  // const GetAllProducts = () => {
  //   setLoading(true);
  //   let local = localStorage.getItem("token");
  //   var myHeaders = new Headers();
  //   myHeaders.append("Authorization", `Bearer ${local}`);
  //   myHeaders.append(
  //     "Cookie",
  //     "connect.sid=s%3AJ0pT5xJJsG8rHqot1A6txMupaTPvbUM2.LHRBUtee7s0DQmEebl5p1hig5dbcABIB0m4VWSzru%2Fo"
  //   );

  //   var requestOptions = {
  //     method: "GET",
  //     headers: myHeaders,
  //     redirect: "follow",
  //   };

  //   fetch(
  //     "https://api.toolboxpos.com/getAllItems?productCategoryId=2",
  //     requestOptions
  //   )
  //     .then((response) => response.json())
  //     .then((result) => {
  //       if (result?.status === true) {
  //         setLoading(false);
  //         setAllProducts(result?.data.reverse());
  //       } else {
  //         setLoading(false);
  //       }
  //     })
  //     .catch((error) => {
  //       console.log("error", error);
  //       setLoading(false);
  //     });
  // };
  const GetAllProducts = () => {
    setLoading(true);
    let local = localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);
    myHeaders.append(
      "Cookie",
      "connect.sid=s%3AJ0pT5xJJsG8rHqot1A6txMupaTPvbUM2.LHRBUtee7s0DQmEebl5p1hig5dbcABIB0m4VWSzru%2Fo"
    );

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(GetProductDeviceApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === true) {
          // console.log("resultproduct", result);
          // setAllProducts(result?.data);
          setAllProducts(
            result?.data
              .filter(
                (item) =>
                  item?.productCategoryId === 2 && item?.imeiNumber !== ""
              )
              .reverse()
          );
          // console.log(
          //   "devilist",
          //   result?.data.filter(
          //     (item) => item?.productCategoryId === 2 && item?.imeiNumber !== ""
          //   )
          // );
          setLoading(false);
          //setSelectedProduct(result?.data);
        } else {
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log("error", error);
        setLoading(false);
      });
  };
  const handleFilterChange1 = (event) => {
    const selectedValue = event.currentTarget.textContent.trim();
    if (selectedValue === "Filter By Date") {
      const sortedData = [...allProduct].sort((a, b) => {
        const nameA = `${a.createdAt}`.toLowerCase();
        const nameB = `${b.createdAt}`.toLowerCase();

        if (isSortAsc) {
          return nameA.localeCompare(nameB);
        } else {
          return nameB.localeCompare(nameA);
        }
      });

      setAllProducts(sortedData);
      setIsSortAsc(!isSortAsc);

      setAllProducts(sortedData);
      setSelectedFilter("Filter By Date");
    } else if (selectedValue === "Filter By Price") {
      const sortedData = [...allProduct].sort((a, b) => {
        const nameA = `${a.sellingPrice}`.toLowerCase();
        const nameB = `${b.sellingPrice}`.toLowerCase();

        if (isSortAsc) {
          return nameA.localeCompare(nameB);
        } else {
          return nameB.localeCompare(nameA);
        }
      });

      setAllProducts(sortedData);
      setIsSortAsc(!isSortAsc);

      setAllProducts(sortedData);
      setSelectedFilter("Filter By Price");
    } else {
      setSelectedFilter("Filter Table");
    }
  };

  const filteredData =
    selectedCategory1 === "default" &&
    selectedCategory2 === "default" &&
    selectedCategory3 === "default" &&
    selectedCategory4 === "default" &&
    selectedCategory5 === "default" &&
    selectedCategory6 === "default"
      ? allProduct
      : allProduct.filter(
          (item) =>
            (selectedCategory1 === "default" ||
              item?.title === selectedCategory1) &&
            (selectedCategory2 === "default" ||
              item?.brand?.name === selectedCategory2) &&
            (selectedCategory3 === "default" ||
              (item?.condition?.condition &&
                item?.condition?.condition === selectedCategory3)) &&
            (selectedCategory4 === "default" ||
              (selectedCategory4 === "draft" && item?.draft === 1) ||
              (item?.activeInactive === parseInt(selectedCategory4) &&
                item?.draft === 0)) &&
            (selectedCategory5 === "default" ||
              (item?.color?.name && item?.color?.name === selectedCategory5)) &&
            (selectedCategory6 === "default" ||
              (item?.carrier?.name &&
                item?.carrier?.name === selectedCategory6))
        );
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentData = filteredData
    .slice(startIndex, endIndex)
    .filter(
      (item) =>
        item?.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item?.brand?.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item?.imeiNumber.toString().includes(searchTerm.toLowerCase())
    );

  const HandleDelete = (id, num) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete data",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#c76cff",
      cancelButtonColor: "#efefef",
      confirmButtonText: "Yes, delete it!",
      confirmButtonText: "<span style='color: white;'>Yes, delete it!</span>",
      cancelButtonText: "<span style='color: black;'>cancel!</span>",
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      customClass: {
        container: "sweet-alert2-container-zindex",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Deleted!",
          text: "Your file has been deleted.",
          icon: "success",
          confirmButtonColor: "#c76cff",
          customClass: {
            container: "sweet-alert2-container-zindex",
          },
        }).then(() => {
          ProductDelete(id, num);
        });
      }
    });
  };

  const ProductDelete = (id, num) => {
    let local = localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);

    var raw = JSON.stringify({
      isDeleted: 1,
      imeiNumber: num,
    });

    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${baseurl}updateItem/${id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === true) {
          GetAllProducts();
          // Swal.fire(result?.message);
        } else {
          Swal.fire(result?.message);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const sortBrand = (a) => {
    let sorted = allProduct.sort((a, b) =>
      a.brand?.name > b.brand?.name ? 1 : -1
    );
    setAllProducts([...sorted]);
  };
  const sortBrand1 = (a) => {
    let sorted = allProduct.sort((a, b) =>
      a.brand?.name < b.brand?.name ? 1 : -1
    );
    setAllProducts([...sorted]);
  };

  const sortProduct = (a) => {
    let sorted = allProduct.sort((a, b) => (a.title > b.title ? 1 : -1));
    setAllProducts([...sorted]);
  };
  const sortProduct1 = (a) => {
    let sorted = allProduct.sort((a, b) => (a.title < b.title ? 1 : -1));
    setAllProducts([...sorted]);
  };

  const sortColor = (a) => {
    let sorted = allProduct.sort((a, b) =>
      a.color?.name > b.color?.name ? 1 : -1
    );
    setAllProducts([...sorted]);
  };
  const sortColor1 = (a) => {
    let sorted = allProduct.sort((a, b) =>
      a.color?.name < b.color?.name ? 1 : -1
    );
    setAllProducts([...sorted]);
  };

  const sortStorage = (a) => {
    let sorted = allProduct.sort((a, b) =>
      a.storage?.size > b.storage?.size ? 1 : -1
    );
    setAllProducts([...sorted]);
  };
  const sortStorage1 = (a) => {
    let sorted = allProduct.sort((a, b) =>
      a.storage?.size < b.storage?.size ? 1 : -1
    );
    setAllProducts([...sorted]);
  };

  const sortCondition = (a) => {
    let sorted = allProduct.sort((a, b) =>
      a.condition?.condition > b.condition?.condition ? 1 : -1
    );
    setAllProducts([...sorted]);
  };
  const sortCondition1 = (a) => {
    let sorted = allProduct.sort((a, b) =>
      a.condition?.condition < b.condition?.condition ? 1 : -1
    );
    setAllProducts([...sorted]);
  };

  const sortCarrier = (a) => {
    let sorted = allProduct.sort((a, b) =>
      a.carrier?.name > b.carrier?.name ? 1 : -1
    );
    setAllProducts([...sorted]);
  };
  const sortCarrier1 = (a) => {
    let sorted = allProduct.sort((a, b) =>
      a.carrier?.name < b.carrier?.name ? 1 : -1
    );
    setAllProducts([...sorted]);
  };
  const sortIMEI = (a) => {
    let sorted = allProduct.sort((a, b) =>
      a.imeiNumber > b.imeiNumber ? 1 : -1
    );
    setAllProducts([...sorted]);
  };
  const sortIMEI1 = (a) => {
    let sorted = allProduct.sort((a, b) =>
      a.imeiNumber < b.imeiNumber ? 1 : -1
    );
    setAllProducts([...sorted]);
  };
  const sortDate = (a) => {
    let sorted = allProduct.sort((a, b) =>
      a.createdAt > b.createdAt ? 1 : -1
    );
    setAllProducts([...sorted]);
  };
  const sortDate1 = (a) => {
    let sorted = allProduct.sort((a, b) =>
      a.createdAt < b.createdAt ? 1 : -1
    );
    setAllProducts([...sorted]);
  };
  const sortPO = (a) => {
    let sorted = allProduct.sort((a, b) => (a.po > b.po ? 1 : -1));
    setAllProducts([...sorted]);
  };
  const sortPO1 = (a) => {
    let sorted = allProduct.sort((a, b) => (a.po < b.po ? 1 : -1));
    setAllProducts([...sorted]);
  };
  const sortUnit = (a) => {
    let sorted = allProduct.sort((a, b) => (a.unitCost > b.unitCost ? 1 : -1));
    setAllProducts([...sorted]);
  };
  const sortUnit1 = (a) => {
    let sorted = allProduct.sort((a, b) => (a.unitCost < b.unitCost ? 1 : -1));
    setAllProducts([...sorted]);
  };
  const sortRetail = (a) => {
    let sorted = allProduct.sort((a, b) =>
      a.sellingPrice > b.sellingPrice ? 1 : -1
    );
    setAllProducts([...sorted]);
  };
  const sortRetail1 = (a) => {
    let sorted = allProduct.sort((a, b) =>
      a.sellingPrice < b.sellingPrice ? 1 : -1
    );
    setAllProducts([...sorted]);
  };
  const sortStatus = (a) => {
    let sorted = allProduct.sort((a, b) =>
      a.activeInactive > b.activeInactive ? 1 : -1
    );
    setAllProducts([...sorted]);
  };
  const sortStatus1 = (a) => {
    let sorted = allProduct.sort((a, b) =>
      a.activeInactive < b.activeInactive ? 1 : -1
    );
    setAllProducts([...sorted]);
  };

  const [isFreeTrial, setIsFreeTrial] = useState(false);

  useEffect(() => {
    const substatus = localStorage.getItem("substatus");
    const planDate = localStorage.getItem("planDate");

    if (
      substatus === "null" ||
      substatus === "canceled" ||
      substatus === "expired"
    ) {
      setIsFreeTrial(true);
    }
  }, []);

  const headers = [
    { label: "Brand", key: "Brand" },
    { label: "Model", key: "Model" },
    { label: "Colour", key: "Colour" },
    { label: "Storage", key: "Storage" },
    { label: "Condition", key: "Condition" },
    { label: "Carrier", key: "Carrier" },
    { label: "Serial#/IMEI#", key: "Serial" },
    { label: "Date Added", key: "Date" },
    { label: "PO#", key: "PO" },
    { label: "Unit Cost", key: "UnitCost" },
    { label: "Retail Price", key: "RetailPrice" },
    { label: "Status", key: "activeInactive" },
  ];

  const preprocessData = (data) => {
    return data.map((item) => ({
      Brand: item?.brand?.name || "--",
      Model: item?.title || "--",
      Colour: item?.color?.name || "--",
      Storage: item?.storage?.size || "--",
      Condition: item?.condition?.condition || "--",
      Carrier: item?.carrier?.name || "--",
      Serial: item?.imeiNumber || "--",
      Date: item?.createdAt ? moment(item?.createdAt).format("MM/DD/YYYY") : "",
      PO: item?.po || 0,
      UnitCost: item?.unitCost || 0,
      RetailPrice: item?.sellingPrice || 0,
      Status:
        item?.draft === 1
          ? "Draft"
          : item?.activeInactive === 1
          ? "Active"
          : "Inactive",
    }));
  };
  const data = preprocessData(allProduct);
  const [isSidebarVisible, setSidebarVisible] = useState(false);

  const toggleSidebar = () => {
    setSidebarVisible(!isSidebarVisible);
    localStorage.setItem("sidebar", !isSidebarVisible);
  };

  useEffect(() => {
    const savedSidebarState = localStorage.getItem("sidebar");
    if (savedSidebarState) {
      setSidebarVisible(JSON.parse(savedSidebarState));
    }
  }, []);

  const [isFreeTrialVisible, setFreeTrialVisible] = useState(true);

  const toggleFreeTrial = () => {
    setFreeTrialVisible(!isFreeTrialVisible);
  };

  const sidebarStyleone = {
    overflow: "auto",
    scrollbarWidth: "none",
    msOverflowStyle: "none",
    // position: 'sticky',

    // marginTop: isFreeTrialVisible ? "0px" : "0px",
  };

  const navbarStyle = {
    // top: isFreeTrialVisible ? "-4px" : "-50px",
  };
  const mainContentStyle = {
    // marginTop: isFreeTrialVisible ? "132px" : "92px",
  };
  const mainwrapper = {
    // marginTop: isSidebarVisible ? "0" : "0",
  };
  useEffect(() => {
    if (isFreeTrial) {
      // toggleSidebar(false);
      toggleFreeTrial(false);
    } else {
      // toggleSidebar(true);
      toggleFreeTrial(true);
    }
  }, [isFreeTrial]);
  useEffect(() => {
    const handleClickOutside = (event) => {
      const sidebarElement = document.querySelector(".main-sidebar");
      const toggleButtonElement = document.querySelector(".sidebar-arrow a");

      if (
        sidebarElement &&
        !sidebarElement.contains(event.target) &&
        toggleButtonElement &&
        !toggleButtonElement.contains(event.target)
      ) {
        const mediaQuery = window.matchMedia("(max-width: 1024px)");
        if (mediaQuery.matches) {
          setSidebarVisible(false);
        }
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleImport = () => {
    // Logic for importing data
    console.log("Import button clicked");
    document.getElementById("fileInput").click();
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setCsvFile(file);

      ImportCsv(file);

      // console.log("File selected:", file.name);
    }
  };

  const ImportCsv = (file, retries = 3) => {
    setLoading(true);
    const local = localStorage.getItem("token");

    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    const formdata = new FormData();
    formdata.append("file", file);

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    // console.log("form", formdata);

    const fetchData = (attempt) => {
      fetch(ImportDeviceApi, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          console.log(result);

          if (result.status === true) {
            GetAllProducts();
            // GetCustomer(currentPage, itemsPerPage, "");
            document.getElementById("fileInput").value = "";
            setLoading(false);
          } else {
            document.getElementById("fileInput").value = "";
            setLoading(false);
          }
        })
        .catch((error) => {
          document.getElementById("fileInput").value = "";
          if (attempt < retries) {
            console.log(`Retrying... (${attempt + 1}/${retries})`);
            fetchData(attempt + 1);
          } else {
            setLoading(false);
            console.error(error);
          }
        });
    };

    fetchData(0);
  };

  return (
    <>
      <div className={isSidebarVisible ? "sidebar-mini" : ""}>
        <div
          className={`${
            isFreeTrial
              ? "main-wrapper devicelistcstmtableres customclswrapper"
              : "main-wrapper devicelistcstmtableres "
          } `}
        >
          <Helmet>
            <title>Track Devices Seamlessly | Toolbox Device List</title>
            <meta
              name="description"
              content="Keep track of your devices effortlessly with the Device List tool. Ensure smooth operations and optimal performance across your network."
            />
          </Helmet>
          {loading ? (
            <div className="landing_loader">
              <div className="innerloader">
                <img src="img/spin-1s-156px.gif" alt="" />
              </div>
            </div>
          ) : null}
          {isFreeTrial ? (
            <>
              {localStorage.getItem("substatus") === "null" ||
              localStorage.getItem("substatus") === "canceled" ||
              localStorage.getItem("substatus") === "expired" ? (
                <>
                  <div className="app-container-trial">
                    <FreeTrail />
                  </div>

                  <div
                    // className={`${isSidebarVisible ? "sidebar-mini" : ""} ${
                    //   !isFreeTrialVisible ? "no-free-trial" : ""
                    // }`}
                    style={sidebarStyleone}
                  >
                    <nav
                      className="navbar navbar-expand-lg main-navbar sticky"
                      style={navbarStyle}
                    >
                      <div className="sidebar-arrow">
                        <a
                          href="#"
                          data-toggle="sidebar"
                          onClick={(e) => {
                            toggleSidebar();

                            e.preventDefault();
                          }}
                        >
                          <img src="assets/img/sidebar-toggle.webp" alt="" />
                        </a>
                      </div>
                      <div className="header-title mr-auto">
                        <h4>
                          Devices Inventory{" "}
                          <span className="count">
                            {allProduct.length} Devices
                          </span>
                        </h4>
                      </div>
                      <SideUl />
                    </nav>

                    <MainSidebar />
                    <div
                      className="main-content tableback"
                      style={mainContentStyle}
                    >
                      <div className="common_filter_sec">
                        <div className="filter_col">
                          <div className="divfiltermergedevicelistcstm filter_tble_btn">
                            <div className="dropdown filterdevicelistcstm ">
                              <button
                                type="button"
                                className="btn btn-secondary dropdown-toggle customdropdowntablebtnsetting"
                                href="#"
                                role="button"
                                id="dropdownMenuLink"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                {selectedFilter === "default"
                                  ? "Filter Table"
                                  : selectedFilter}
                              </button>
                              <img
                                src="assets/img/filter-icon.webp"
                                alt=""
                                className="filtericonimgboxsetting"
                              />
                              <ul
                                className="dropdown-menu customdropdownulboxsetting"
                                aria-labelledby="dropdownMenuLink"
                              >
                                <li>
                                  <a
                                    className="dropdown-item"
                                    href="#"
                                    onClick={handleFilterChange1}
                                  >
                                    Filter Table
                                  </a>
                                </li>
                                <li>
                                  <a
                                    className="dropdown-item"
                                    href="#"
                                    onClick={handleFilterChange1}
                                  >
                                    Filter By Date
                                  </a>
                                </li>
                                <li>
                                  <a
                                    className="dropdown-item"
                                    href="#"
                                    onClick={handleFilterChange1}
                                  >
                                    Filter By Price
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="manage_btn">
                            <a href="#">Manage</a>
                          </div>

                          <div className="search_field">
                            <div className="search_icon">
                              <img src="assets/img/search-icon.webp" alt="" />
                            </div>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Search IMEI/ Serial/Model /Brand"
                              value={searchTerm}
                              onChange={handleSearchTermChange}
                            />
                          </div>
                          <div className="imp_exp_btn export_btn">
                            <CSVLink
                              data={data}
                              headers={headers}
                              filename={"table-data.csv"}
                            >
                              <button type="button">
                                <img src="assets/img/export-icon.webp" alt="" />{" "}
                                Export
                              </button>
                            </CSVLink>
                          </div>
                          <div className="imp_exp_btn import_btn">
                            <button type="button" onClick={handleImport}>
                              <img src="assets/img/import-icon.webp" alt="" />{" "}
                              Import
                            </button>
                            <input
                              type="file"
                              id="fileInput"
                              style={{ display: "none" }}
                              onChange={handleFileChange}
                            />
                          </div>

                          <div
                            className="add_customer_btn"
                            onClick={() => setShowModal(true)}
                          >
                            <a
                              href="#"
                              data-toggle="modal"
                              data-target="#createproductModal"
                            >
                              New Devices <i className="fa fa-plus" />
                            </a>
                          </div>
                        </div>
                        <div className="filter_select_option">
                          <div className="ft_select_option brand_option option_15 customdropdownulboxcate">
                            <div className="dropdown customtabledropdownbox1 customtabledropdownbox2">
                              <button
                                type="button"
                                className="btn btn-secondary dropdown-toggle customdropdowntablebtnsetting textaligncustmtext"
                                role="button"
                                id="dropdownMenuLink"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                {selectedCategory2 === "default"
                                  ? "Select a brand"
                                  : selectedCategory2}
                              </button>
                              <ul
                                className="dropdown-menu customdropdownulboxsetting ulcustmrepairdropdowncss"
                                aria-labelledby="dropdownMenuLink"
                              >
                                <li>
                                  <a
                                    className="dropdown-item"
                                    href="#"
                                    onClick={() => handleBrandChange("default")}
                                  >
                                    Select a brand
                                  </a>
                                </li>
                                {allBrand.map((item) => (
                                  <li key={item.id}>
                                    <a
                                      className="dropdown-item"
                                      href="#"
                                      onClick={() =>
                                        handleBrandChange(item?.name)
                                      }
                                    >
                                      {item?.name}
                                    </a>
                                  </li>
                                ))}
                              </ul>
                              <img src="assets/img/select-arrow.webp" alt="" />
                            </div>
                          </div>
                          <div className="ft_select_option category_option option_15 customdropdownulboxcate">
                            <div className="dropdown customtabledropdownbox1 customtabledropdownbox2">
                              <button
                                type="button"
                                className="btn btn-secondary dropdown-toggle customdropdowntablebtnsetting textaligncustmtext"
                                role="button"
                                id="dropdownMenuLink"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                {selectedCategory1 === "default"
                                  ? "Select a Model"
                                  : selectedCategory1}
                              </button>
                              <ul
                                className="dropdown-menu customdropdownulboxsetting ulcustmrepairdropdowncss"
                                aria-labelledby="dropdownMenuLink"
                              >
                                <li>
                                  <a
                                    className="dropdown-item"
                                    href="#"
                                    onClick={() =>
                                      handleStorageChange("default")
                                    }
                                  >
                                    Select a Model
                                  </a>
                                </li>
                                {AllModel.map((category) => (
                                  <li key={category.id}>
                                    <a
                                      className="dropdown-item"
                                      href="#"
                                      onClick={() =>
                                        handleStorageChange(category.title)
                                      }
                                    >
                                      {category?.title}
                                    </a>
                                  </li>
                                ))}
                              </ul>
                              <img src="assets/img/select-arrow.webp" alt="" />
                            </div>
                          </div>
                          <div className="ft_select_option supplier_option option_20 customdropdownulboxsuppl">
                            <div className="dropdown customtabledropdownboxsuppl customtabledropdownboxsuppl2">
                              <button
                                type="button"
                                className="btn btn-secondary dropdown-toggle customdropdowntablebtnsetting textaligncustmtext"
                                role="button"
                                id="dropdownMenuLink"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                {selectedCategory3 === "default"
                                  ? "Select condtion"
                                  : selectedCategory3}
                              </button>
                              <ul
                                className="dropdown-menu customdropdownulboxsetting ulcustmrepairdropdowncss"
                                aria-labelledby="dropdownMenuLink"
                              >
                                <li>
                                  <a
                                    className="dropdown-item"
                                    href="#"
                                    onClick={() =>
                                      handleSupplierChange("default")
                                    }
                                  >
                                    Select condtion
                                  </a>
                                </li>
                                {allCondition?.map((item) => (
                                  <li key={item.id}>
                                    <a
                                      className="dropdown-item"
                                      href="#"
                                      onClick={() =>
                                        handleSupplierChange(item?.condition)
                                      }
                                    >
                                      {item?.condition}
                                    </a>
                                  </li>
                                ))}
                              </ul>
                              <img src="assets/img/select-arrow.webp" alt="" />
                            </div>
                          </div>
                          <div className="ft_select_option supplier_option option_20 customdropdownulboxsuppl">
                            <div className="dropdown customtabledropdownboxsuppl customtabledropdownboxsuppl2">
                              <button
                                type="button"
                                className="btn btn-secondary dropdown-toggle customdropdowntablebtnsetting textaligncustmtext"
                                role="button"
                                id="dropdownMenuLink"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                {selectedCategory5 === "default"
                                  ? "Select a colour"
                                  : selectedCategory5}
                              </button>
                              <ul
                                className="dropdown-menu customdropdownulboxsetting ulcustmrepairdropdowncss"
                                aria-labelledby="dropdownMenuLink"
                              >
                                <li>
                                  <a
                                    className="dropdown-item"
                                    href="#"
                                    onClick={() => handleColorChange("default")}
                                  >
                                    Select a colour
                                  </a>
                                </li>
                                {allColor.map((item) => (
                                  <li key={item.id}>
                                    <a
                                      className="dropdown-item"
                                      href="#"
                                      onClick={() =>
                                        handleColorChange(item?.name)
                                      }
                                    >
                                      {item?.name}
                                    </a>
                                  </li>
                                ))}
                              </ul>
                              <img src="assets/img/select-arrow.webp" alt="" />
                            </div>
                          </div>
                          <div className="ft_select_option supplier_option option_20 customdropdownulboxsuppl">
                            <div className="dropdown customtabledropdownboxsuppl customtabledropdownboxsuppl2">
                              <button
                                type="button"
                                className="btn btn-secondary dropdown-toggle customdropdowntablebtnsetting textaligncustmtext"
                                role="button"
                                id="dropdownMenuLink"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                {selectedCategory6 === "default"
                                  ? "Select a carrier"
                                  : selectedCategory6}
                              </button>
                              <ul
                                className="dropdown-menu customdropdownulboxsetting ulcustmrepairdropdowncss"
                                aria-labelledby="dropdownMenuLink"
                              >
                                <li>
                                  <a
                                    className="dropdown-item"
                                    href="#"
                                    onClick={() =>
                                      handleCarrierChange("default")
                                    }
                                  >
                                    Select a carrier
                                  </a>
                                </li>
                                {allCarrier.map((item) => (
                                  <li key={item.id}>
                                    <a
                                      className="dropdown-item"
                                      href="#"
                                      onClick={() =>
                                        handleCarrierChange(item?.name)
                                      }
                                    >
                                      {item?.name}
                                    </a>
                                  </li>
                                ))}
                              </ul>
                              <img src="assets/img/select-arrow.webp" alt="" />
                            </div>
                          </div>

                          <div className="search_field">
                            <div className="search_icon">
                              <img src="assets/img/search-icon.webp" alt="" />
                            </div>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Search Purchase Order number"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="clearfix" />
                      <div className="row">
                        <div className="col-lg-12 col-md-12">
                          <div className="customertablemain">
                            <table className="table table-striped  customdatatable">
                              <thead className="customtheadpadding">
                                <tr>
                                  <th scope="col" className="checkbtnthsetting">
                                    <input
                                      className="form-check-input customcheckboxbtnsetting"
                                      type="checkbox"
                                      value=""
                                      id="flexCheckDefault"
                                      onChange={handleSelectAll}
                                      checked={selectAll}
                                    />
                                  </th>

                                  <th
                                    scope="col"
                                    className="customnameth  customnamethactive"
                                  >
                                    Brand{" "}
                                    {sortState === 0 ? (
                                      <span
                                        className="upperarrow"
                                        onClick={() => {
                                          sortBrand();
                                          setSortState(0);
                                        }}
                                      >
                                        <i
                                          className="fa fa-long-arrow-up active"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    ) : (
                                      <span
                                        className="upperarrow"
                                        onClick={() => {
                                          sortBrand();
                                          setSortState(0);
                                        }}
                                      >
                                        <i
                                          className="fa fa-long-arrow-up"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    )}
                                    {sortState === 1 ? (
                                      <span
                                        className="lowerarrow"
                                        onClick={() => {
                                          sortBrand1();
                                          setSortState(1);
                                        }}
                                      >
                                        <i
                                          className="fa fa-long-arrow-down active "
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    ) : (
                                      <span
                                        className="lowerarrow"
                                        onClick={() => {
                                          sortBrand1();
                                          setSortState(1);
                                        }}
                                      >
                                        <i
                                          className="fa fa-long-arrow-down "
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    )}
                                  </th>
                                  <th scope="col" className="customnameth">
                                    Model{" "}
                                    {sortState === 2 ? (
                                      <span
                                        className="upperarrow"
                                        onClick={() => {
                                          sortProduct();
                                          setSortState(2);
                                        }}
                                      >
                                        <i
                                          className="fa fa-long-arrow-up active"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    ) : (
                                      <span
                                        className="upperarrow"
                                        onClick={() => {
                                          sortProduct();
                                          setSortState(2);
                                        }}
                                      >
                                        <i
                                          className="fa fa-long-arrow-up"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    )}
                                    {sortState === 3 ? (
                                      <span
                                        className="lowerarrow"
                                        onClick={() => {
                                          sortProduct1();
                                          setSortState(3);
                                        }}
                                      >
                                        <i
                                          className="fa fa-long-arrow-down active "
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    ) : (
                                      <span
                                        className="lowerarrow"
                                        onClick={() => {
                                          sortProduct1();
                                          setSortState(3);
                                        }}
                                      >
                                        <i
                                          className="fa fa-long-arrow-down "
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    )}
                                  </th>
                                  <th scope="col" className="customnameth">
                                    Colour{" "}
                                    {sortState === 4 ? (
                                      <span
                                        className="upperarrow"
                                        onClick={() => {
                                          sortColor();
                                          setSortState(4);
                                        }}
                                      >
                                        <i
                                          className="fa fa-long-arrow-up active"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    ) : (
                                      <span
                                        className="upperarrow"
                                        onClick={() => {
                                          sortColor();
                                          setSortState(4);
                                        }}
                                      >
                                        <i
                                          className="fa fa-long-arrow-up"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    )}
                                    {sortState === 5 ? (
                                      <span
                                        className="lowerarrow"
                                        onClick={() => {
                                          sortColor1();
                                          setSortState(5);
                                        }}
                                      >
                                        <i
                                          className="fa fa-long-arrow-down active "
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    ) : (
                                      <span
                                        className="lowerarrow"
                                        onClick={() => {
                                          sortColor1();
                                          setSortState(5);
                                        }}
                                      >
                                        <i
                                          className="fa fa-long-arrow-down "
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    )}
                                  </th>
                                  <th scope="col" className="customnameth">
                                    Storage
                                    {sortState === 6 ? (
                                      <span
                                        className="upperarrow"
                                        onClick={() => {
                                          sortStorage();
                                          setSortState(6);
                                        }}
                                      >
                                        <i
                                          className="fa fa-long-arrow-up active"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    ) : (
                                      <span
                                        className="upperarrow"
                                        onClick={() => {
                                          sortStorage();
                                          setSortState(6);
                                        }}
                                      >
                                        <i
                                          className="fa fa-long-arrow-up"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    )}
                                    {sortState === 7 ? (
                                      <span
                                        className="lowerarrow"
                                        onClick={() => {
                                          sortStorage1();
                                          setSortState(7);
                                        }}
                                      >
                                        <i
                                          className="fa fa-long-arrow-down active "
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    ) : (
                                      <span
                                        className="lowerarrow"
                                        onClick={() => {
                                          sortStorage1();
                                          setSortState(7);
                                        }}
                                      >
                                        <i
                                          className="fa fa-long-arrow-down "
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    )}
                                  </th>
                                  <th scope="col" className="customnameth">
                                    Condition{" "}
                                    {sortState === 8 ? (
                                      <span
                                        className="upperarrow"
                                        onClick={() => {
                                          sortCondition();
                                          setSortState(8);
                                        }}
                                      >
                                        <i
                                          className="fa fa-long-arrow-up active"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    ) : (
                                      <span
                                        className="upperarrow"
                                        onClick={() => {
                                          sortCondition();
                                          setSortState(8);
                                        }}
                                      >
                                        <i
                                          className="fa fa-long-arrow-up"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    )}
                                    {sortState === 9 ? (
                                      <span
                                        className="lowerarrow"
                                        onClick={() => {
                                          sortCondition1();
                                          setSortState(9);
                                        }}
                                      >
                                        <i
                                          className="fa fa-long-arrow-down active "
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    ) : (
                                      <span
                                        className="lowerarrow"
                                        onClick={() => {
                                          sortCondition1();
                                          setSortState(9);
                                        }}
                                      >
                                        <i
                                          className="fa fa-long-arrow-down "
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    )}
                                  </th>
                                  <th scope="col" className="customnameth">
                                    Carrier{" "}
                                    {sortState === 10 ? (
                                      <span
                                        className="upperarrow"
                                        onClick={() => {
                                          sortCarrier();
                                          setSortState(10);
                                        }}
                                      >
                                        <i
                                          className="fa fa-long-arrow-up active"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    ) : (
                                      <span
                                        className="upperarrow"
                                        onClick={() => {
                                          sortCarrier();
                                          setSortState(10);
                                        }}
                                      >
                                        <i
                                          className="fa fa-long-arrow-up"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    )}
                                    {sortState === 11 ? (
                                      <span
                                        className="lowerarrow"
                                        onClick={() => {
                                          sortCarrier1();
                                          setSortState(11);
                                        }}
                                      >
                                        <i
                                          className="fa fa-long-arrow-down active "
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    ) : (
                                      <span
                                        className="lowerarrow"
                                        onClick={() => {
                                          sortCarrier1();
                                          setSortState(11);
                                        }}
                                      >
                                        <i
                                          className="fa fa-long-arrow-down "
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    )}
                                  </th>
                                  <th scope="col" className="customnameth">
                                    Serial #/IMEI #{" "}
                                    {sortState === 12 ? (
                                      <span
                                        className="upperarrow"
                                        onClick={() => {
                                          sortIMEI();
                                          setSortState(12);
                                        }}
                                      >
                                        <i
                                          className="fa fa-long-arrow-up active"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    ) : (
                                      <span
                                        className="upperarrow"
                                        onClick={() => {
                                          sortIMEI();
                                          setSortState(12);
                                        }}
                                      >
                                        <i
                                          className="fa fa-long-arrow-up"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    )}
                                    {sortState === 13 ? (
                                      <span
                                        className="lowerarrow"
                                        onClick={() => {
                                          sortIMEI1();
                                          setSortState(13);
                                        }}
                                      >
                                        <i
                                          className="fa fa-long-arrow-down active "
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    ) : (
                                      <span
                                        className="lowerarrow"
                                        onClick={() => {
                                          sortIMEI1();
                                          setSortState(13);
                                        }}
                                      >
                                        <i
                                          className="fa fa-long-arrow-down "
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    )}
                                  </th>
                                  <th scope="col" className="customnameth">
                                    Date Added{" "}
                                    {sortState === 14 ? (
                                      <span
                                        className="upperarrow"
                                        onClick={() => {
                                          sortDate();
                                          setSortState(14);
                                        }}
                                      >
                                        <i
                                          className="fa fa-long-arrow-up active"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    ) : (
                                      <span
                                        className="upperarrow"
                                        onClick={() => {
                                          sortDate();
                                          setSortState(14);
                                        }}
                                      >
                                        <i
                                          className="fa fa-long-arrow-up"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    )}
                                    {sortState === 15 ? (
                                      <span
                                        className="lowerarrow"
                                        onClick={() => {
                                          sortDate1();
                                          setSortState(15);
                                        }}
                                      >
                                        <i
                                          className="fa fa-long-arrow-down active "
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    ) : (
                                      <span
                                        className="lowerarrow"
                                        onClick={() => {
                                          sortDate1();
                                          setSortState(15);
                                        }}
                                      >
                                        <i
                                          className="fa fa-long-arrow-down "
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    )}
                                  </th>
                                  <th scope="col" className="customnameth">
                                    PO#{" "}
                                    {sortState === 16 ? (
                                      <span
                                        className="upperarrow"
                                        onClick={() => {
                                          sortPO();
                                          setSortState(16);
                                        }}
                                      >
                                        <i
                                          className="fa fa-long-arrow-up active"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    ) : (
                                      <span
                                        className="upperarrow"
                                        onClick={() => {
                                          sortPO();
                                          setSortState(16);
                                        }}
                                      >
                                        <i
                                          className="fa fa-long-arrow-up"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    )}
                                    {sortState === 17 ? (
                                      <span
                                        className="lowerarrow"
                                        onClick={() => {
                                          sortPO1();
                                          setSortState(17);
                                        }}
                                      >
                                        <i
                                          className="fa fa-long-arrow-down active "
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    ) : (
                                      <span
                                        className="lowerarrow"
                                        onClick={() => {
                                          sortPO1();
                                          setSortState(17);
                                        }}
                                      >
                                        <i
                                          className="fa fa-long-arrow-down "
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    )}
                                  </th>
                                  <th scope="col" className="customnameth">
                                    Unit Cost{" "}
                                    {sortState === 18 ? (
                                      <span
                                        className="upperarrow"
                                        onClick={() => {
                                          sortUnit();
                                          setSortState(18);
                                        }}
                                      >
                                        <i
                                          className="fa fa-long-arrow-up active"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    ) : (
                                      <span
                                        className="upperarrow"
                                        onClick={() => {
                                          sortUnit();
                                          setSortState(18);
                                        }}
                                      >
                                        <i
                                          className="fa fa-long-arrow-up"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    )}
                                    {sortState === 19 ? (
                                      <span
                                        className="lowerarrow"
                                        onClick={() => {
                                          sortUnit1();
                                          setSortState(19);
                                        }}
                                      >
                                        <i
                                          className="fa fa-long-arrow-down active "
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    ) : (
                                      <span
                                        className="lowerarrow"
                                        onClick={() => {
                                          sortUnit1();
                                          setSortState(19);
                                        }}
                                      >
                                        <i
                                          className="fa fa-long-arrow-down "
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    )}
                                  </th>
                                  <th scope="col" className="customnameth">
                                    Retail price{" "}
                                    {sortState === 20 ? (
                                      <span
                                        className="upperarrow"
                                        onClick={() => {
                                          sortRetail();
                                          setSortState(20);
                                        }}
                                      >
                                        <i
                                          className="fa fa-long-arrow-up active"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    ) : (
                                      <span
                                        className="upperarrow"
                                        onClick={() => {
                                          sortRetail();
                                          setSortState(20);
                                        }}
                                      >
                                        <i
                                          className="fa fa-long-arrow-up"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    )}
                                    {sortState === 21 ? (
                                      <span
                                        className="lowerarrow"
                                        onClick={() => {
                                          sortRetail1();
                                          setSortState(21);
                                        }}
                                      >
                                        <i
                                          className="fa fa-long-arrow-down active "
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    ) : (
                                      <span
                                        className="lowerarrow"
                                        onClick={() => {
                                          sortRetail1();
                                          setSortState(21);
                                        }}
                                      >
                                        <i
                                          className="fa fa-long-arrow-down "
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    )}
                                  </th>
                                  <th scope="col" className="customnameth">
                                    Status{" "}
                                    {sortState === 22 ? (
                                      <span
                                        className="upperarrow"
                                        onClick={() => {
                                          sortStatus();
                                          setSortState(22);
                                        }}
                                      >
                                        <i
                                          className="fa fa-long-arrow-up active"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    ) : (
                                      <span
                                        className="upperarrow"
                                        onClick={() => {
                                          sortStatus();
                                          setSortState(22);
                                        }}
                                      >
                                        <i
                                          className="fa fa-long-arrow-up"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    )}
                                    {sortState === 23 ? (
                                      <span
                                        className="lowerarrow"
                                        onClick={() => {
                                          sortStatus1();
                                          setSortState(23);
                                        }}
                                      >
                                        <i
                                          className="fa fa-long-arrow-down active "
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    ) : (
                                      <span
                                        className="lowerarrow"
                                        onClick={() => {
                                          sortStatus1();
                                          setSortState(23);
                                        }}
                                      >
                                        <i
                                          className="fa fa-long-arrow-down "
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    )}
                                  </th>
                                  <th scope="col" className="customnameth">
                                    Action
                                  </th>
                                </tr>
                              </thead>
                              <tbody className="customtheadpadding">
                                {currentData.length ? (
                                  <>
                                    {currentData.map((item, index) => (
                                      <>
                                        <tr
                                          key={index.id}
                                          className={
                                            index % 2 === 0 ? "" : "customeven"
                                          }
                                        >
                                          <td
                                            key={index.id}
                                            className={
                                              index % 2 === 0 ? "" : "bgcolor"
                                            }
                                          >
                                            <input
                                              key={index.id}
                                              className="form-check-input customcheckboxbtnsetting"
                                              type="checkbox"
                                              value=""
                                              id={`flexCheckDefault${index}`}
                                              onChange={() =>
                                                handleSelectRow(item.id)
                                              }
                                              checked={selectedItems.includes(
                                                item.id
                                              )}
                                            />
                                          </td>
                                          <td
                                            key={index.id}
                                            className={
                                              index % 2 === 0
                                                ? ""
                                                : " prod_img bgcolor"
                                            }
                                          >
                                            {item?.image ? (
                                              <img
                                                key={index.id}
                                                className="imglistcstm"
                                                src={item?.image}
                                                alt=""
                                              />
                                            ) : (
                                              <img
                                                src="assets/img/defaultimg.webp"
                                                className="imglistcstm"
                                                style={{ marginRight: "10px" }}
                                                alt=""
                                              />
                                            )}
                                            <span> {item?.brand?.name}</span>
                                          </td>
                                          <td
                                            key={index.id}
                                            className={
                                              index % 2 === 0 ? "" : "bgcolor"
                                            }
                                          >
                                            {item?.title}
                                          </td>
                                          <td
                                            key={index.id}
                                            className={
                                              index % 2 === 0 ? "" : "bgcolor"
                                            }
                                          >
                                            {item?.color?.name}
                                          </td>
                                          <td
                                            key={index.id}
                                            className={
                                              index % 2 === 0 ? "" : "bgcolor"
                                            }
                                          >
                                            {item?.storage?.size}
                                          </td>
                                          <td
                                            key={index.id}
                                            className={
                                              index % 2 === 0 ? "" : "bgcolor"
                                            }
                                          >
                                            {item?.condition?.condition}
                                          </td>
                                          <td
                                            key={index.id}
                                            className={
                                              index % 2 === 0 ? "" : "bgcolor"
                                            }
                                          >
                                            {item?.carrier?.name}
                                          </td>
                                          <td
                                            key={index.id}
                                            className={
                                              index % 2 === 0 ? "" : "bgcolor"
                                            }
                                          >
                                            {item?.imeiNumber}
                                          </td>
                                          <td
                                            key={index.id}
                                            className={
                                              index % 2 === 0 ? "" : "bgcolor"
                                            }
                                          >
                                            {moment(item?.createdAt).format(
                                              "MM/DD/YYYY"
                                            )}
                                          </td>
                                          <td
                                            key={index.id}
                                            className={
                                              index % 2 === 0 ? "" : "bgcolor"
                                            }
                                          >
                                            {item?.po}
                                          </td>
                                          <td
                                            key={index.id}
                                            className={
                                              index % 2 === 0 ? "" : "bgcolor"
                                            }
                                          >
                                            ${item?.unitCost.toFixed(2)}
                                          </td>
                                          <td
                                            key={index.id}
                                            className={
                                              index % 2 === 0 ? "" : "bgcolor"
                                            }
                                          >
                                            ${item?.sellingPrice.toFixed(2)}
                                          </td>
                                          <td
                                            key={index.id}
                                            className={
                                              index % 2 === 0
                                                ? ""
                                                : "status_td st_inactive bgcolor"
                                            }
                                          >
                                            {item?.draft === 1 ? (
                                              <span
                                                className="spanactiveinactivecstm2"
                                                key={index.id}
                                              >
                                                Draft
                                              </span>
                                            ) : item?.activeInactive === 1 ? (
                                              <span
                                                className="spanactiveinactivecstm1"
                                                key={index.id}
                                              >
                                                Active
                                              </span>
                                            ) : (
                                              <span
                                                className="spanactiveinactivecstm"
                                                key={index.id}
                                              >
                                                Inactive
                                              </span>
                                            )}
                                          </td>
                                          <td
                                            key={index.id}
                                            className={
                                              index % 2 === 0 ? "" : "bgcolor"
                                            }
                                          >
                                            <a
                                              key={index.id}
                                              href="#"
                                              data-toggle="modal"
                                              data-target="#createproductModal1"
                                              //to="/editmodal"
                                              state={{ data: item }}
                                              className="edit_action_btn"
                                              onClick={() => {
                                                setShowModal1(true);

                                                setTimeState(item);
                                              }}
                                            >
                                              <img
                                                src="assets/img/action-edit-icon.webp"
                                                alt=""
                                              />
                                            </a>

                                            <i
                                              className="fa-solid fa-trash deletecustmtb"
                                              onClick={() =>
                                                HandleDelete(
                                                  item?.id,
                                                  item?.imeiNumber
                                                )
                                              }
                                            ></i>
                                          </td>
                                        </tr>
                                      </>
                                    ))}
                                  </>
                                ) : (
                                  <>
                                    <tr id="noDataMessage">
                                      <td colSpan="14" className="nodataav">
                                        No data available
                                      </td>
                                    </tr>
                                  </>
                                )}
                              </tbody>
                            </table>
                            {allProduct.length > 0 ? (
                              <div className="row">
                                <div className="col-md-12">
                                  <div className="custombottompaginationbox">
                                    <nav aria-label="Page navigation example">
                                      <ul className="pagination">
                                        <li
                                          className="page-item custompaginationli  paginationleftbtnbox"
                                          style={{ cursor: "pointer" }}
                                        >
                                          <button
                                            type="button"
                                            className="page-link"
                                            onClick={() =>
                                              handlePageClick(currentPage - 1)
                                            }
                                            disabled={currentPage === 1}
                                          >
                                            <img src="assets/img/previous.webp" />
                                          </button>
                                        </li>

                                        {Array.from(
                                          { length: totalPages },
                                          (_, index) => index + 1
                                        ).map((page, key) => (
                                          <li
                                            className="page-item custompaginationli"
                                            key={key}
                                          >
                                            <a
                                              key={key}
                                              className={
                                                currentPage === page
                                                  ? "page-link active customcountpagination"
                                                  : "page-link customcountpagination"
                                              }
                                              onClick={() =>
                                                handlePageClick(page)
                                              }
                                              disabled={currentPage === page}
                                              style={{ cursor: "pointer" }}
                                            >
                                              {page}
                                            </a>
                                          </li>
                                        ))}

                                        <li
                                          className="page-item custompaginationli paginationrightbtnbox"
                                          style={{ cursor: "pointer" }}
                                        >
                                          <button
                                            type="button"
                                            className="page-link"
                                            href="#"
                                            onClick={() =>
                                              handlePageClick(currentPage + 1)
                                            }
                                            disabled={
                                              currentPage === totalPages
                                            }
                                          >
                                            <img src="assets/img/next.webp" />
                                          </button>
                                        </li>
                                      </ul>
                                    </nav>
                                  </div>
                                </div>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <div
                  // className={`${isSidebarVisible ? "sidebar-mini" : ""} ${
                  //   !isFreeTrialVisible ? "no-free-trial" : ""
                  // }`}
                  style={sidebarStyleone}
                >
                  <nav
                    className="navbar navbar-expand-lg main-navbar sticky"
                    style={navbarStyle}
                  >
                    <div className="sidebar-arrow">
                      <a
                        href="#"
                        data-toggle="sidebar"
                        onClick={(e) => {
                          toggleSidebar();

                          e.preventDefault();
                        }}
                      >
                        <img src="assets/img/sidebar-toggle.webp" alt="" />
                      </a>
                    </div>
                    <div className="header-title mr-auto">
                      <h4>
                        Devices Inventory{" "}
                        <span className="count">
                          {allProduct.length} Devices
                        </span>
                      </h4>
                    </div>
                    <SideUl />
                  </nav>

                  <MainSidebar />
                  <div
                    className="main-content tableback"
                    style={mainContentStyle}
                  >
                    <div className="common_filter_sec">
                      <div className="filter_col">
                        <div className="divfiltermergedevicelistcstm filter_tble_btn">
                          <div className="dropdown filterdevicelistcstm ">
                            <button
                              type="button"
                              className="btn btn-secondary dropdown-toggle customdropdowntablebtnsetting"
                              href="#"
                              role="button"
                              id="dropdownMenuLink"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              {selectedFilter === "default"
                                ? "Filter Table"
                                : selectedFilter}
                            </button>
                            <img
                              src="assets/img/filter-icon.webp"
                              alt=""
                              className="filtericonimgboxsetting"
                            />
                            <ul
                              className="dropdown-menu customdropdownulboxsetting"
                              aria-labelledby="dropdownMenuLink"
                            >
                              <li>
                                <a
                                  className="dropdown-item"
                                  href="#"
                                  onClick={handleFilterChange1}
                                >
                                  Filter Table
                                </a>
                              </li>
                              <li>
                                <a
                                  className="dropdown-item"
                                  href="#"
                                  onClick={handleFilterChange1}
                                >
                                  Filter By Date
                                </a>
                              </li>
                              <li>
                                <a
                                  className="dropdown-item"
                                  href="#"
                                  onClick={handleFilterChange1}
                                >
                                  Filter By Price
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="manage_btn">
                          <a href="#">Manage</a>
                        </div>

                        <div className="search_field">
                          <div className="search_icon">
                            <img src="assets/img/search-icon.webp" alt="" />
                          </div>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search IMEI/ Serial/Model /Brand"
                            value={searchTerm}
                            onChange={handleSearchTermChange}
                          />
                        </div>
                        <div className="imp_exp_btn export_btn">
                          <CSVLink
                            data={data}
                            headers={headers}
                            filename={"table-data.csv"}
                          >
                            <button type="button">
                              <img src="assets/img/export-icon.webp" alt="" />{" "}
                              Export
                            </button>
                          </CSVLink>
                        </div>
                        <div className="imp_exp_btn import_btn">
                          <button type="button" onClick={handleImport}>
                            <img src="assets/img/import-icon.webp" alt="" />{" "}
                            Import
                          </button>
                          <input
                            type="file"
                            id="fileInput"
                            style={{ display: "none" }}
                            onChange={handleFileChange}
                          />
                        </div>

                        <div
                          className="add_customer_btn"
                          onClick={() => setShowModal(true)}
                        >
                          <a
                            href="#"
                            data-toggle="modal"
                            data-target="#createproductModal"
                          >
                            New Devices <i className="fa fa-plus" />
                          </a>
                        </div>
                      </div>
                      <div className="filter_select_option">
                        <div className="ft_select_option brand_option option_15 customdropdownulboxcate">
                          <div className="dropdown customtabledropdownbox1 customtabledropdownbox2">
                            <button
                              type="button"
                              className="btn btn-secondary dropdown-toggle customdropdowntablebtnsetting textaligncustmtext"
                              role="button"
                              id="dropdownMenuLink"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              {selectedCategory2 === "default"
                                ? "Select a brand"
                                : selectedCategory2}
                            </button>
                            <ul
                              className="dropdown-menu customdropdownulboxsetting ulcustmrepairdropdowncss"
                              aria-labelledby="dropdownMenuLink"
                            >
                              <li>
                                <a
                                  className="dropdown-item"
                                  href="#"
                                  onClick={() => handleBrandChange("default")}
                                >
                                  Select a brand
                                </a>
                              </li>
                              {allBrand.map((item) => (
                                <li key={item.id}>
                                  <a
                                    className="dropdown-item"
                                    href="#"
                                    onClick={() =>
                                      handleBrandChange(item?.name)
                                    }
                                  >
                                    {item?.name}
                                  </a>
                                </li>
                              ))}
                            </ul>
                            <img src="assets/img/select-arrow.webp" alt="" />
                          </div>
                        </div>
                        <div className="ft_select_option category_option option_15 customdropdownulboxcate">
                          <div className="dropdown customtabledropdownbox1 customtabledropdownbox2">
                            <button
                              type="button"
                              className="btn btn-secondary dropdown-toggle customdropdowntablebtnsetting textaligncustmtext"
                              role="button"
                              id="dropdownMenuLink"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              {selectedCategory1 === "default"
                                ? "Select a Model"
                                : selectedCategory1}
                            </button>
                            <ul
                              className="dropdown-menu customdropdownulboxsetting ulcustmrepairdropdowncss"
                              aria-labelledby="dropdownMenuLink"
                            >
                              <li>
                                <a
                                  className="dropdown-item"
                                  href="#"
                                  onClick={() => handleStorageChange("default")}
                                >
                                  Select a Model
                                </a>
                              </li>
                              {AllModel.map((category) => (
                                <li key={category.id}>
                                  <a
                                    className="dropdown-item"
                                    href="#"
                                    onClick={() =>
                                      handleStorageChange(category.title)
                                    }
                                  >
                                    {category?.title}
                                  </a>
                                </li>
                              ))}
                            </ul>
                            <img src="assets/img/select-arrow.webp" alt="" />
                          </div>
                        </div>
                        <div className="ft_select_option supplier_option option_20 customdropdownulboxsuppl">
                          <div className="dropdown customtabledropdownboxsuppl customtabledropdownboxsuppl2">
                            <button
                              type="button"
                              className="btn btn-secondary dropdown-toggle customdropdowntablebtnsetting textaligncustmtext"
                              role="button"
                              id="dropdownMenuLink"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              {selectedCategory3 === "default"
                                ? "Select condtion"
                                : selectedCategory3}
                            </button>
                            <ul
                              className="dropdown-menu customdropdownulboxsetting ulcustmrepairdropdowncss"
                              aria-labelledby="dropdownMenuLink"
                            >
                              <li>
                                <a
                                  className="dropdown-item"
                                  href="#"
                                  onClick={() =>
                                    handleSupplierChange("default")
                                  }
                                >
                                  Select condtion
                                </a>
                              </li>
                              {allCondition.map((item) => (
                                <li key={item.id}>
                                  <a
                                    className="dropdown-item"
                                    href="#"
                                    onClick={() =>
                                      handleSupplierChange(item?.condition)
                                    }
                                  >
                                    {item?.condition}
                                  </a>
                                </li>
                              ))}
                            </ul>
                            <img src="assets/img/select-arrow.webp" alt="" />
                          </div>
                        </div>
                        <div className="ft_select_option supplier_option option_20 customdropdownulboxsuppl">
                          <div className="dropdown customtabledropdownboxsuppl customtabledropdownboxsuppl2">
                            <button
                              type="button"
                              className="btn btn-secondary dropdown-toggle customdropdowntablebtnsetting textaligncustmtext"
                              role="button"
                              id="dropdownMenuLink"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              {selectedCategory5 === "default"
                                ? "Select a colour"
                                : selectedCategory5}
                            </button>
                            <ul
                              className="dropdown-menu customdropdownulboxsetting ulcustmrepairdropdowncss"
                              aria-labelledby="dropdownMenuLink"
                            >
                              <li>
                                <a
                                  className="dropdown-item"
                                  href="#"
                                  onClick={() => handleColorChange("default")}
                                >
                                  Select a colour
                                </a>
                              </li>
                              {allColor.map((item) => (
                                <li key={item.id}>
                                  <a
                                    className="dropdown-item"
                                    href="#"
                                    onClick={() =>
                                      handleColorChange(item?.name)
                                    }
                                  >
                                    {item?.name}
                                  </a>
                                </li>
                              ))}
                            </ul>
                            <img src="assets/img/select-arrow.webp" alt="" />
                          </div>
                        </div>
                        <div className="ft_select_option supplier_option option_20 customdropdownulboxsuppl">
                          <div className="dropdown customtabledropdownboxsuppl customtabledropdownboxsuppl2">
                            <button
                              type="button"
                              className="btn btn-secondary dropdown-toggle customdropdowntablebtnsetting textaligncustmtext"
                              role="button"
                              id="dropdownMenuLink"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              {selectedCategory6 === "default"
                                ? "Select a carrier"
                                : selectedCategory6}
                            </button>
                            <ul
                              className="dropdown-menu customdropdownulboxsetting ulcustmrepairdropdowncss"
                              aria-labelledby="dropdownMenuLink"
                            >
                              <li>
                                <a
                                  className="dropdown-item"
                                  href="#"
                                  onClick={() => handleCarrierChange("default")}
                                >
                                  Select a carrier
                                </a>
                              </li>
                              {allCarrier.map((item) => (
                                <li key={item.id}>
                                  <a
                                    className="dropdown-item"
                                    href="#"
                                    onClick={() =>
                                      handleCarrierChange(item?.name)
                                    }
                                  >
                                    {item?.name}
                                  </a>
                                </li>
                              ))}
                            </ul>
                            <img src="assets/img/select-arrow.webp" alt="" />
                          </div>
                        </div>

                        <div className="search_field">
                          <div className="search_icon">
                            <img src="assets/img/search-icon.webp" alt="" />
                          </div>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search Purchase Order number"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="clearfix" />
                    <div className="row">
                      <div className="col-lg-12 col-md-12">
                        <div className="customertablemain">
                          <table className="table table-striped  customdatatable">
                            <thead className="customtheadpadding">
                              <tr>
                                <th scope="col" className="checkbtnthsetting">
                                  <input
                                    className="form-check-input customcheckboxbtnsetting"
                                    type="checkbox"
                                    value=""
                                    id="flexCheckDefault"
                                    onChange={handleSelectAll}
                                    checked={selectAll}
                                  />
                                </th>

                                <th
                                  scope="col"
                                  className="customnameth  customnamethactive"
                                >
                                  Brand{" "}
                                  {sortState === 0 ? (
                                    <span
                                      className="upperarrow"
                                      onClick={() => {
                                        sortBrand();
                                        setSortState(0);
                                      }}
                                    >
                                      <i
                                        className="fa fa-long-arrow-up active"
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  ) : (
                                    <span
                                      className="upperarrow"
                                      onClick={() => {
                                        sortBrand();
                                        setSortState(0);
                                      }}
                                    >
                                      <i
                                        className="fa fa-long-arrow-up"
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  )}
                                  {sortState === 1 ? (
                                    <span
                                      className="lowerarrow"
                                      onClick={() => {
                                        sortBrand1();
                                        setSortState(1);
                                      }}
                                    >
                                      <i
                                        className="fa fa-long-arrow-down active "
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  ) : (
                                    <span
                                      className="lowerarrow"
                                      onClick={() => {
                                        sortBrand1();
                                        setSortState(1);
                                      }}
                                    >
                                      <i
                                        className="fa fa-long-arrow-down "
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  )}
                                </th>
                                <th scope="col" className="customnameth">
                                  Model{" "}
                                  {sortState === 2 ? (
                                    <span
                                      className="upperarrow"
                                      onClick={() => {
                                        sortProduct();
                                        setSortState(2);
                                      }}
                                    >
                                      <i
                                        className="fa fa-long-arrow-up active"
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  ) : (
                                    <span
                                      className="upperarrow"
                                      onClick={() => {
                                        sortProduct();
                                        setSortState(2);
                                      }}
                                    >
                                      <i
                                        className="fa fa-long-arrow-up"
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  )}
                                  {sortState === 3 ? (
                                    <span
                                      className="lowerarrow"
                                      onClick={() => {
                                        sortProduct1();
                                        setSortState(3);
                                      }}
                                    >
                                      <i
                                        className="fa fa-long-arrow-down active "
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  ) : (
                                    <span
                                      className="lowerarrow"
                                      onClick={() => {
                                        sortProduct1();
                                        setSortState(3);
                                      }}
                                    >
                                      <i
                                        className="fa fa-long-arrow-down "
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  )}
                                </th>
                                <th scope="col" className="customnameth">
                                  Colour{" "}
                                  {sortState === 4 ? (
                                    <span
                                      className="upperarrow"
                                      onClick={() => {
                                        sortColor();
                                        setSortState(4);
                                      }}
                                    >
                                      <i
                                        className="fa fa-long-arrow-up active"
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  ) : (
                                    <span
                                      className="upperarrow"
                                      onClick={() => {
                                        sortColor();
                                        setSortState(4);
                                      }}
                                    >
                                      <i
                                        className="fa fa-long-arrow-up"
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  )}
                                  {sortState === 5 ? (
                                    <span
                                      className="lowerarrow"
                                      onClick={() => {
                                        sortColor1();
                                        setSortState(5);
                                      }}
                                    >
                                      <i
                                        className="fa fa-long-arrow-down active "
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  ) : (
                                    <span
                                      className="lowerarrow"
                                      onClick={() => {
                                        sortColor1();
                                        setSortState(5);
                                      }}
                                    >
                                      <i
                                        className="fa fa-long-arrow-down "
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  )}
                                </th>
                                <th scope="col" className="customnameth">
                                  Storage
                                  {sortState === 6 ? (
                                    <span
                                      className="upperarrow"
                                      onClick={() => {
                                        sortStorage();
                                        setSortState(6);
                                      }}
                                    >
                                      <i
                                        className="fa fa-long-arrow-up active"
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  ) : (
                                    <span
                                      className="upperarrow"
                                      onClick={() => {
                                        sortStorage();
                                        setSortState(6);
                                      }}
                                    >
                                      <i
                                        className="fa fa-long-arrow-up"
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  )}
                                  {sortState === 7 ? (
                                    <span
                                      className="lowerarrow"
                                      onClick={() => {
                                        sortStorage1();
                                        setSortState(7);
                                      }}
                                    >
                                      <i
                                        className="fa fa-long-arrow-down active "
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  ) : (
                                    <span
                                      className="lowerarrow"
                                      onClick={() => {
                                        sortStorage1();
                                        setSortState(7);
                                      }}
                                    >
                                      <i
                                        className="fa fa-long-arrow-down "
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  )}
                                </th>
                                <th scope="col" className="customnameth">
                                  Condition{" "}
                                  {sortState === 8 ? (
                                    <span
                                      className="upperarrow"
                                      onClick={() => {
                                        sortCondition();
                                        setSortState(8);
                                      }}
                                    >
                                      <i
                                        className="fa fa-long-arrow-up active"
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  ) : (
                                    <span
                                      className="upperarrow"
                                      onClick={() => {
                                        sortCondition();
                                        setSortState(8);
                                      }}
                                    >
                                      <i
                                        className="fa fa-long-arrow-up"
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  )}
                                  {sortState === 9 ? (
                                    <span
                                      className="lowerarrow"
                                      onClick={() => {
                                        sortCondition1();
                                        setSortState(9);
                                      }}
                                    >
                                      <i
                                        className="fa fa-long-arrow-down active "
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  ) : (
                                    <span
                                      className="lowerarrow"
                                      onClick={() => {
                                        sortCondition1();
                                        setSortState(9);
                                      }}
                                    >
                                      <i
                                        className="fa fa-long-arrow-down "
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  )}
                                </th>
                                <th scope="col" className="customnameth">
                                  Carrier{" "}
                                  {sortState === 10 ? (
                                    <span
                                      className="upperarrow"
                                      onClick={() => {
                                        sortCarrier();
                                        setSortState(10);
                                      }}
                                    >
                                      <i
                                        className="fa fa-long-arrow-up active"
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  ) : (
                                    <span
                                      className="upperarrow"
                                      onClick={() => {
                                        sortCarrier();
                                        setSortState(10);
                                      }}
                                    >
                                      <i
                                        className="fa fa-long-arrow-up"
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  )}
                                  {sortState === 11 ? (
                                    <span
                                      className="lowerarrow"
                                      onClick={() => {
                                        sortCarrier1();
                                        setSortState(11);
                                      }}
                                    >
                                      <i
                                        className="fa fa-long-arrow-down active "
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  ) : (
                                    <span
                                      className="lowerarrow"
                                      onClick={() => {
                                        sortCarrier1();
                                        setSortState(11);
                                      }}
                                    >
                                      <i
                                        className="fa fa-long-arrow-down "
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  )}
                                </th>
                                <th scope="col" className="customnameth">
                                  Serial #/IMEI #{" "}
                                  {sortState === 12 ? (
                                    <span
                                      className="upperarrow"
                                      onClick={() => {
                                        sortIMEI();
                                        setSortState(12);
                                      }}
                                    >
                                      <i
                                        className="fa fa-long-arrow-up active"
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  ) : (
                                    <span
                                      className="upperarrow"
                                      onClick={() => {
                                        sortIMEI();
                                        setSortState(12);
                                      }}
                                    >
                                      <i
                                        className="fa fa-long-arrow-up"
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  )}
                                  {sortState === 13 ? (
                                    <span
                                      className="lowerarrow"
                                      onClick={() => {
                                        sortIMEI1();
                                        setSortState(13);
                                      }}
                                    >
                                      <i
                                        className="fa fa-long-arrow-down active "
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  ) : (
                                    <span
                                      className="lowerarrow"
                                      onClick={() => {
                                        sortIMEI1();
                                        setSortState(13);
                                      }}
                                    >
                                      <i
                                        className="fa fa-long-arrow-down "
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  )}
                                </th>
                                <th scope="col" className="customnameth">
                                  Date Added{" "}
                                  {sortState === 14 ? (
                                    <span
                                      className="upperarrow"
                                      onClick={() => {
                                        sortDate();
                                        setSortState(14);
                                      }}
                                    >
                                      <i
                                        className="fa fa-long-arrow-up active"
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  ) : (
                                    <span
                                      className="upperarrow"
                                      onClick={() => {
                                        sortDate();
                                        setSortState(14);
                                      }}
                                    >
                                      <i
                                        className="fa fa-long-arrow-up"
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  )}
                                  {sortState === 15 ? (
                                    <span
                                      className="lowerarrow"
                                      onClick={() => {
                                        sortDate1();
                                        setSortState(15);
                                      }}
                                    >
                                      <i
                                        className="fa fa-long-arrow-down active "
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  ) : (
                                    <span
                                      className="lowerarrow"
                                      onClick={() => {
                                        sortDate1();
                                        setSortState(15);
                                      }}
                                    >
                                      <i
                                        className="fa fa-long-arrow-down "
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  )}
                                </th>
                                <th scope="col" className="customnameth">
                                  PO#{" "}
                                  {sortState === 16 ? (
                                    <span
                                      className="upperarrow"
                                      onClick={() => {
                                        sortPO();
                                        setSortState(16);
                                      }}
                                    >
                                      <i
                                        className="fa fa-long-arrow-up active"
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  ) : (
                                    <span
                                      className="upperarrow"
                                      onClick={() => {
                                        sortPO();
                                        setSortState(16);
                                      }}
                                    >
                                      <i
                                        className="fa fa-long-arrow-up"
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  )}
                                  {sortState === 17 ? (
                                    <span
                                      className="lowerarrow"
                                      onClick={() => {
                                        sortPO1();
                                        setSortState(17);
                                      }}
                                    >
                                      <i
                                        className="fa fa-long-arrow-down active "
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  ) : (
                                    <span
                                      className="lowerarrow"
                                      onClick={() => {
                                        sortPO1();
                                        setSortState(17);
                                      }}
                                    >
                                      <i
                                        className="fa fa-long-arrow-down "
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  )}
                                </th>
                                <th scope="col" className="customnameth">
                                  Unit Cost{" "}
                                  {sortState === 18 ? (
                                    <span
                                      className="upperarrow"
                                      onClick={() => {
                                        sortUnit();
                                        setSortState(18);
                                      }}
                                    >
                                      <i
                                        className="fa fa-long-arrow-up active"
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  ) : (
                                    <span
                                      className="upperarrow"
                                      onClick={() => {
                                        sortUnit();
                                        setSortState(18);
                                      }}
                                    >
                                      <i
                                        className="fa fa-long-arrow-up"
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  )}
                                  {sortState === 19 ? (
                                    <span
                                      className="lowerarrow"
                                      onClick={() => {
                                        sortUnit1();
                                        setSortState(19);
                                      }}
                                    >
                                      <i
                                        className="fa fa-long-arrow-down active "
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  ) : (
                                    <span
                                      className="lowerarrow"
                                      onClick={() => {
                                        sortUnit1();
                                        setSortState(19);
                                      }}
                                    >
                                      <i
                                        className="fa fa-long-arrow-down "
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  )}
                                </th>
                                <th scope="col" className="customnameth">
                                  Retail price{" "}
                                  {sortState === 20 ? (
                                    <span
                                      className="upperarrow"
                                      onClick={() => {
                                        sortRetail();
                                        setSortState(20);
                                      }}
                                    >
                                      <i
                                        className="fa fa-long-arrow-up active"
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  ) : (
                                    <span
                                      className="upperarrow"
                                      onClick={() => {
                                        sortRetail();
                                        setSortState(20);
                                      }}
                                    >
                                      <i
                                        className="fa fa-long-arrow-up"
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  )}
                                  {sortState === 21 ? (
                                    <span
                                      className="lowerarrow"
                                      onClick={() => {
                                        sortRetail1();
                                        setSortState(21);
                                      }}
                                    >
                                      <i
                                        className="fa fa-long-arrow-down active "
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  ) : (
                                    <span
                                      className="lowerarrow"
                                      onClick={() => {
                                        sortRetail1();
                                        setSortState(21);
                                      }}
                                    >
                                      <i
                                        className="fa fa-long-arrow-down "
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  )}
                                </th>
                                <th scope="col" className="customnameth">
                                  Status{" "}
                                  {sortState === 22 ? (
                                    <span
                                      className="upperarrow"
                                      onClick={() => {
                                        sortStatus();
                                        setSortState(22);
                                      }}
                                    >
                                      <i
                                        className="fa fa-long-arrow-up active"
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  ) : (
                                    <span
                                      className="upperarrow"
                                      onClick={() => {
                                        sortStatus();
                                        setSortState(22);
                                      }}
                                    >
                                      <i
                                        className="fa fa-long-arrow-up"
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  )}
                                  {sortState === 23 ? (
                                    <span
                                      className="lowerarrow"
                                      onClick={() => {
                                        sortStatus1();
                                        setSortState(23);
                                      }}
                                    >
                                      <i
                                        className="fa fa-long-arrow-down active "
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  ) : (
                                    <span
                                      className="lowerarrow"
                                      onClick={() => {
                                        sortStatus1();
                                        setSortState(23);
                                      }}
                                    >
                                      <i
                                        className="fa fa-long-arrow-down "
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  )}
                                </th>
                                <th scope="col" className="customnameth">
                                  Action
                                </th>
                              </tr>
                            </thead>
                            <tbody className="customtheadpadding">
                              {currentData.length ? (
                                <>
                                  {currentData.map((item, index) => (
                                    <>
                                      <tr
                                        key={index.id}
                                        className={
                                          index % 2 === 0 ? "" : "customeven"
                                        }
                                      >
                                        <td
                                          key={index.id}
                                          className={
                                            index % 2 === 0 ? "" : "bgcolor"
                                          }
                                        >
                                          <input
                                            key={index.id}
                                            className="form-check-input customcheckboxbtnsetting"
                                            type="checkbox"
                                            value=""
                                            id={`flexCheckDefault${index}`}
                                            onChange={() =>
                                              handleSelectRow(item.id)
                                            }
                                            checked={selectedItems.includes(
                                              item.id
                                            )}
                                          />
                                        </td>
                                        <td
                                          key={index.id}
                                          className={
                                            index % 2 === 0
                                              ? ""
                                              : " prod_img bgcolor"
                                          }
                                        >
                                          {item?.image ? (
                                            <img
                                              key={index.id}
                                              className="imglistcstm"
                                              src={item?.image}
                                              alt=""
                                            />
                                          ) : (
                                            <img
                                              src="assets/img/defaultimg.webp"
                                              className="imglistcstm"
                                              style={{ marginRight: "10px" }}
                                              alt=""
                                            />
                                          )}
                                          <span> {item?.brand?.name}</span>
                                        </td>
                                        <td
                                          key={index.id}
                                          className={
                                            index % 2 === 0 ? "" : "bgcolor"
                                          }
                                        >
                                          {item?.title}
                                        </td>
                                        <td
                                          key={index.id}
                                          className={
                                            index % 2 === 0 ? "" : "bgcolor"
                                          }
                                        >
                                          {item?.color?.name}
                                        </td>
                                        <td
                                          key={index.id}
                                          className={
                                            index % 2 === 0 ? "" : "bgcolor"
                                          }
                                        >
                                          {item?.storage?.size}
                                        </td>
                                        <td
                                          key={index.id}
                                          className={
                                            index % 2 === 0 ? "" : "bgcolor"
                                          }
                                        >
                                          {item?.condition?.condition}
                                        </td>
                                        <td
                                          key={index.id}
                                          className={
                                            index % 2 === 0 ? "" : "bgcolor"
                                          }
                                        >
                                          {item?.carrier?.name}
                                        </td>
                                        <td
                                          key={index.id}
                                          className={
                                            index % 2 === 0 ? "" : "bgcolor"
                                          }
                                        >
                                          {item?.imeiNumber}
                                        </td>
                                        <td
                                          key={index.id}
                                          className={
                                            index % 2 === 0 ? "" : "bgcolor"
                                          }
                                        >
                                          {moment(item?.createdAt).format(
                                            "MM/DD/YYYY"
                                          )}
                                        </td>
                                        <td
                                          key={index.id}
                                          className={
                                            index % 2 === 0 ? "" : "bgcolor"
                                          }
                                        >
                                          {item?.po}
                                        </td>
                                        <td
                                          key={index.id}
                                          className={
                                            index % 2 === 0 ? "" : "bgcolor"
                                          }
                                        >
                                          ${item?.unitCost.toFixed(2)}
                                        </td>
                                        <td
                                          key={index.id}
                                          className={
                                            index % 2 === 0 ? "" : "bgcolor"
                                          }
                                        >
                                          ${item?.sellingPrice.toFixed(2)}
                                        </td>
                                        <td
                                          key={index.id}
                                          className={
                                            index % 2 === 0
                                              ? ""
                                              : "status_td st_inactive bgcolor"
                                          }
                                        >
                                          {item?.draft === 1 ? (
                                            <span
                                              className="spanactiveinactivecstm2"
                                              key={index.id}
                                            >
                                              Draft
                                            </span>
                                          ) : item?.activeInactive === 1 ? (
                                            <span
                                              className="spanactiveinactivecstm1"
                                              key={index.id}
                                            >
                                              Active
                                            </span>
                                          ) : (
                                            <span
                                              className="spanactiveinactivecstm"
                                              key={index.id}
                                            >
                                              Inactive
                                            </span>
                                          )}
                                        </td>
                                        <td
                                          key={index.id}
                                          className={
                                            index % 2 === 0 ? "" : "bgcolor"
                                          }
                                        >
                                          <a
                                            key={index.id}
                                            // to="/editdevice"
                                            href="#"
                                            data-toggle="modal"
                                            data-target="#createproductModal1"
                                            //to="/editmodal"
                                            state={{ data: item }}
                                            className="edit_action_btn"
                                            onClick={() => {
                                              setShowModal1(true);

                                              setTimeState(item);
                                            }}
                                          >
                                            <img
                                              src="assets/img/action-edit-icon.webp"
                                              alt=""
                                            />
                                          </a>

                                          <i
                                            className="fa-solid fa-trash deletecustmtb"
                                            onClick={() =>
                                              HandleDelete(
                                                item?.id,
                                                item?.imeiNumber
                                              )
                                            }
                                          ></i>
                                        </td>
                                      </tr>
                                    </>
                                  ))}
                                </>
                              ) : (
                                <>
                                  <tr id="noDataMessage">
                                    <td colSpan="14" className="nodataav">
                                      No data available
                                    </td>
                                  </tr>
                                </>
                              )}
                            </tbody>
                          </table>
                          {allProduct.length > 0 ? (
                            <div className="row">
                              <div className="col-md-12">
                                <div className="custombottompaginationbox">
                                  <nav aria-label="Page navigation example">
                                    <ul className="pagination">
                                      <li
                                        className="page-item custompaginationli  paginationleftbtnbox"
                                        style={{ cursor: "pointer" }}
                                      >
                                        <button
                                          type="button"
                                          className="page-link"
                                          onClick={() =>
                                            handlePageClick(currentPage - 1)
                                          }
                                          disabled={currentPage === 1}
                                        >
                                          <img src="assets/img/previous.webp" />
                                        </button>
                                      </li>

                                      {Array.from(
                                        { length: totalPages },
                                        (_, index) => index + 1
                                      ).map((page, key) => (
                                        <li
                                          className="page-item custompaginationli"
                                          key={key}
                                        >
                                          <a
                                            key={key}
                                            className={
                                              currentPage === page
                                                ? "page-link active customcountpagination"
                                                : "page-link customcountpagination"
                                            }
                                            onClick={() =>
                                              handlePageClick(page)
                                            }
                                            disabled={currentPage === page}
                                            style={{ cursor: "pointer" }}
                                          >
                                            {page}
                                          </a>
                                        </li>
                                      ))}

                                      <li
                                        className="page-item custompaginationli paginationrightbtnbox"
                                        style={{ cursor: "pointer" }}
                                      >
                                        <button
                                          type="button"
                                          className="page-link"
                                          href="#"
                                          onClick={() =>
                                            handlePageClick(currentPage + 1)
                                          }
                                          disabled={currentPage === totalPages}
                                        >
                                          <img src="assets/img/next.webp" />
                                        </button>
                                      </li>
                                    </ul>
                                  </nav>
                                </div>
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          ) : (
            <div
              // className={`${isSidebarVisible ? "sidebar-mini" : ""} ${
              //   !isFreeTrialVisible ? "no-free-trial" : ""
              // }`}
              style={sidebarStyleone}
            >
              <nav
                className="navbar navbar-expand-lg main-navbar sticky"
                style={navbarStyle}
              >
                <div className="sidebar-arrow">
                  <a
                    href="#"
                    data-toggle="sidebar"
                    onClick={(e) => {
                      toggleSidebar();

                      e.preventDefault();
                    }}
                  >
                    <img src="assets/img/sidebar-toggle.webp" alt="" />
                  </a>
                </div>
                <div className="header-title mr-auto">
                  <h4>
                    Devices Inventory{" "}
                    <span className="count">{allProduct.length} Devices</span>
                  </h4>
                </div>
                <SideUl />
              </nav>

              <MainSidebar />
              <div className="main-content tableback" style={mainContentStyle}>
                <div className="common_filter_sec">
                  <div className="filter_col">
                    <div className="divfiltermergedevicelistcstm filter_tble_btn">
                      <div className="dropdown filterdevicelistcstm ">
                        <button
                          type="button"
                          className="btn btn-secondary dropdown-toggle customdropdowntablebtnsetting"
                          href="#"
                          role="button"
                          id="dropdownMenuLink"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          {selectedFilter === "default"
                            ? "Filter Table"
                            : selectedFilter}
                        </button>
                        <img
                          src="assets/img/filter-icon.webp"
                          alt=""
                          className="filtericonimgboxsetting"
                        />
                        <ul
                          className="dropdown-menu customdropdownulboxsetting"
                          aria-labelledby="dropdownMenuLink"
                        >
                          <li>
                            <a
                              className="dropdown-item"
                              href="#"
                              onClick={handleFilterChange1}
                            >
                              Filter Table
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              href="#"
                              onClick={handleFilterChange1}
                            >
                              Filter By Date
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              href="#"
                              onClick={handleFilterChange1}
                            >
                              Filter By Price
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div className="manage_btn">
                      <a href="#">Manage</a>
                    </div>

                    <div className="search_field">
                      <div className="search_icon">
                        <img src="assets/img/search-icon.webp" alt="" />
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search IMEI/ Serial/Model /Brand"
                        value={searchTerm}
                        onChange={handleSearchTermChange}
                      />
                    </div>
                    <div className="imp_exp_btn export_btn">
                      <CSVLink
                        data={data}
                        headers={headers}
                        filename={"table-data.csv"}
                      >
                        <button type="button">
                          <img src="assets/img/export-icon.webp" alt="" />{" "}
                          Export
                        </button>
                      </CSVLink>
                    </div>
                    <div className="imp_exp_btn import_btn">
                      <button type="button" onClick={handleImport}>
                        <img src="assets/img/import-icon.webp" alt="" /> Import
                      </button>
                      <input
                        type="file"
                        id="fileInput"
                        style={{ display: "none" }}
                        onChange={handleFileChange}
                      />
                    </div>

                    <div
                      className="add_customer_btn"
                      onClick={() => setShowModal(true)}
                    >
                      <a
                        href="#"
                        data-toggle="modal"
                        data-target="#createproductModal"
                      >
                        New Devices <i className="fa fa-plus" />
                      </a>
                    </div>
                  </div>
                  <div className="filter_select_option">
                    <div className="ft_select_option brand_option option_15 customdropdownulboxcate">
                      <div className="dropdown customtabledropdownbox1 customtabledropdownbox2">
                        <button
                          type="button"
                          className="btn btn-secondary dropdown-toggle customdropdowntablebtnsetting textaligncustmtext"
                          role="button"
                          id="dropdownMenuLink"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          {selectedCategory2 === "default"
                            ? "Select a brand"
                            : selectedCategory2}
                        </button>
                        <ul
                          className="dropdown-menu customdropdownulboxsetting ulcustmrepairdropdowncss"
                          aria-labelledby="dropdownMenuLink"
                        >
                          <li>
                            <a
                              className="dropdown-item"
                              href="#"
                              onClick={() => handleBrandChange("default")}
                            >
                              Select a brand
                            </a>
                          </li>
                          {allBrand.map((item) => (
                            <li key={item.id}>
                              <a
                                className="dropdown-item"
                                href="#"
                                onClick={() => handleBrandChange(item?.name)}
                              >
                                {item?.name}
                              </a>
                            </li>
                          ))}
                        </ul>
                        <img src="assets/img/select-arrow.webp" alt="" />
                      </div>
                    </div>
                    <div className="ft_select_option category_option option_15 customdropdownulboxcate">
                      <div className="dropdown customtabledropdownbox1 customtabledropdownbox2">
                        <button
                          type="button"
                          className="btn btn-secondary dropdown-toggle customdropdowntablebtnsetting textaligncustmtext"
                          role="button"
                          id="dropdownMenuLink"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          {selectedCategory1 === "default"
                            ? "Select a Model"
                            : selectedCategory1}
                        </button>
                        <ul
                          className="dropdown-menu customdropdownulboxsetting ulcustmrepairdropdowncss"
                          aria-labelledby="dropdownMenuLink"
                        >
                          <li>
                            <a
                              className="dropdown-item"
                              href="#"
                              onClick={() => handleStorageChange("default")}
                            >
                              Select a Model
                            </a>
                          </li>
                          {AllModel.map((category) => (
                            <li key={category.id}>
                              <a
                                className="dropdown-item"
                                href="#"
                                onClick={() =>
                                  handleStorageChange(category.title)
                                }
                              >
                                {category?.title}
                              </a>
                            </li>
                          ))}
                        </ul>
                        <img src="assets/img/select-arrow.webp" alt="" />
                      </div>
                    </div>
                    <div className="ft_select_option supplier_option option_20 customdropdownulboxsuppl">
                      <div className="dropdown customtabledropdownboxsuppl customtabledropdownboxsuppl2">
                        <button
                          type="button"
                          className="btn btn-secondary dropdown-toggle customdropdowntablebtnsetting textaligncustmtext"
                          role="button"
                          id="dropdownMenuLink"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          {selectedCategory3 === "default"
                            ? "Select condtion"
                            : selectedCategory3}
                        </button>
                        <ul
                          className="dropdown-menu customdropdownulboxsetting ulcustmrepairdropdowncss"
                          aria-labelledby="dropdownMenuLink"
                        >
                          <li>
                            <a
                              className="dropdown-item"
                              href="#"
                              onClick={() => handleSupplierChange("default")}
                            >
                              Select condtion
                            </a>
                          </li>
                          {allCondition.map((item) => (
                            <li key={item.id}>
                              <a
                                className="dropdown-item"
                                href="#"
                                onClick={() =>
                                  handleSupplierChange(item?.condition)
                                }
                              >
                                {item?.condition}
                              </a>
                            </li>
                          ))}
                        </ul>
                        <img src="assets/img/select-arrow.webp" alt="" />
                      </div>
                    </div>
                    <div className="ft_select_option supplier_option option_20 customdropdownulboxsuppl">
                      <div className="dropdown customtabledropdownboxsuppl customtabledropdownboxsuppl2">
                        <button
                          type="button"
                          className="btn btn-secondary dropdown-toggle customdropdowntablebtnsetting textaligncustmtext"
                          role="button"
                          id="dropdownMenuLink"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          {selectedCategory5 === "default"
                            ? "Select a colour"
                            : selectedCategory5}
                        </button>
                        <ul
                          className="dropdown-menu customdropdownulboxsetting ulcustmrepairdropdowncss"
                          aria-labelledby="dropdownMenuLink"
                        >
                          <li>
                            <a
                              className="dropdown-item"
                              href="#"
                              onClick={() => handleColorChange("default")}
                            >
                              Select a colour
                            </a>
                          </li>
                          {allColor.map((item) => (
                            <li key={item.id}>
                              <a
                                className="dropdown-item"
                                href="#"
                                onClick={() => handleColorChange(item?.name)}
                              >
                                {item?.name}
                              </a>
                            </li>
                          ))}
                        </ul>
                        <img src="assets/img/select-arrow.webp" alt="" />
                      </div>
                    </div>
                    <div className="ft_select_option supplier_option option_20 customdropdownulboxsuppl">
                      <div className="dropdown customtabledropdownboxsuppl customtabledropdownboxsuppl2">
                        <button
                          type="button"
                          className="btn btn-secondary dropdown-toggle customdropdowntablebtnsetting textaligncustmtext"
                          role="button"
                          id="dropdownMenuLink"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          {selectedCategory6 === "default"
                            ? "Select a carrier"
                            : selectedCategory6}
                        </button>
                        <ul
                          className="dropdown-menu customdropdownulboxsetting ulcustmrepairdropdowncss"
                          aria-labelledby="dropdownMenuLink"
                        >
                          <li>
                            <a
                              className="dropdown-item"
                              href="#"
                              onClick={() => handleCarrierChange("default")}
                            >
                              Select a carrier
                            </a>
                          </li>
                          {allCarrier.map((item) => (
                            <li key={item.id}>
                              <a
                                className="dropdown-item"
                                href="#"
                                onClick={() => handleCarrierChange(item?.name)}
                              >
                                {item?.name}
                              </a>
                            </li>
                          ))}
                        </ul>
                        <img src="assets/img/select-arrow.webp" alt="" />
                      </div>
                    </div>

                    <div className="search_field">
                      <div className="search_icon">
                        <img src="assets/img/search-icon.webp" alt="" />
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search Purchase Order number"
                      />
                    </div>
                  </div>
                </div>
                <div className="clearfix" />
                <div className="row">
                  <div className="col-lg-12 col-md-12">
                    <div className="customertablemain">
                      <table className="table table-striped  customdatatable">
                        <thead className="customtheadpadding">
                          <tr>
                            <th scope="col" className="checkbtnthsetting">
                              <input
                                className="form-check-input customcheckboxbtnsetting"
                                type="checkbox"
                                value=""
                                id="flexCheckDefault"
                                onChange={handleSelectAll}
                                checked={selectAll}
                              />
                            </th>

                            <th
                              scope="col"
                              className="customnameth  customnamethactive"
                            >
                              Brand{" "}
                              {sortState === 0 ? (
                                <span
                                  className="upperarrow"
                                  onClick={() => {
                                    sortBrand();
                                    setSortState(0);
                                  }}
                                >
                                  <i
                                    className="fa fa-long-arrow-up active"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              ) : (
                                <span
                                  className="upperarrow"
                                  onClick={() => {
                                    sortBrand();
                                    setSortState(0);
                                  }}
                                >
                                  <i
                                    className="fa fa-long-arrow-up"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              )}
                              {sortState === 1 ? (
                                <span
                                  className="lowerarrow"
                                  onClick={() => {
                                    sortBrand1();
                                    setSortState(1);
                                  }}
                                >
                                  <i
                                    className="fa fa-long-arrow-down active "
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              ) : (
                                <span
                                  className="lowerarrow"
                                  onClick={() => {
                                    sortBrand1();
                                    setSortState(1);
                                  }}
                                >
                                  <i
                                    className="fa fa-long-arrow-down "
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              )}
                            </th>
                            <th scope="col" className="customnameth">
                              Model{" "}
                              {sortState === 2 ? (
                                <span
                                  className="upperarrow"
                                  onClick={() => {
                                    sortProduct();
                                    setSortState(2);
                                  }}
                                >
                                  <i
                                    className="fa fa-long-arrow-up active"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              ) : (
                                <span
                                  className="upperarrow"
                                  onClick={() => {
                                    sortProduct();
                                    setSortState(2);
                                  }}
                                >
                                  <i
                                    className="fa fa-long-arrow-up"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              )}
                              {sortState === 3 ? (
                                <span
                                  className="lowerarrow"
                                  onClick={() => {
                                    sortProduct1();
                                    setSortState(3);
                                  }}
                                >
                                  <i
                                    className="fa fa-long-arrow-down active "
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              ) : (
                                <span
                                  className="lowerarrow"
                                  onClick={() => {
                                    sortProduct1();
                                    setSortState(3);
                                  }}
                                >
                                  <i
                                    className="fa fa-long-arrow-down "
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              )}
                            </th>
                            <th scope="col" className="customnameth">
                              Colour{" "}
                              {sortState === 4 ? (
                                <span
                                  className="upperarrow"
                                  onClick={() => {
                                    sortColor();
                                    setSortState(4);
                                  }}
                                >
                                  <i
                                    className="fa fa-long-arrow-up active"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              ) : (
                                <span
                                  className="upperarrow"
                                  onClick={() => {
                                    sortColor();
                                    setSortState(4);
                                  }}
                                >
                                  <i
                                    className="fa fa-long-arrow-up"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              )}
                              {sortState === 5 ? (
                                <span
                                  className="lowerarrow"
                                  onClick={() => {
                                    sortColor1();
                                    setSortState(5);
                                  }}
                                >
                                  <i
                                    className="fa fa-long-arrow-down active "
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              ) : (
                                <span
                                  className="lowerarrow"
                                  onClick={() => {
                                    sortColor1();
                                    setSortState(5);
                                  }}
                                >
                                  <i
                                    className="fa fa-long-arrow-down "
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              )}
                            </th>
                            <th scope="col" className="customnameth">
                              Storage
                              {sortState === 6 ? (
                                <span
                                  className="upperarrow"
                                  onClick={() => {
                                    sortStorage();
                                    setSortState(6);
                                  }}
                                >
                                  <i
                                    className="fa fa-long-arrow-up active"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              ) : (
                                <span
                                  className="upperarrow"
                                  onClick={() => {
                                    sortStorage();
                                    setSortState(6);
                                  }}
                                >
                                  <i
                                    className="fa fa-long-arrow-up"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              )}
                              {sortState === 7 ? (
                                <span
                                  className="lowerarrow"
                                  onClick={() => {
                                    sortStorage1();
                                    setSortState(7);
                                  }}
                                >
                                  <i
                                    className="fa fa-long-arrow-down active "
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              ) : (
                                <span
                                  className="lowerarrow"
                                  onClick={() => {
                                    sortStorage1();
                                    setSortState(7);
                                  }}
                                >
                                  <i
                                    className="fa fa-long-arrow-down "
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              )}
                            </th>
                            <th scope="col" className="customnameth">
                              Condition{" "}
                              {sortState === 8 ? (
                                <span
                                  className="upperarrow"
                                  onClick={() => {
                                    sortCondition();
                                    setSortState(8);
                                  }}
                                >
                                  <i
                                    className="fa fa-long-arrow-up active"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              ) : (
                                <span
                                  className="upperarrow"
                                  onClick={() => {
                                    sortCondition();
                                    setSortState(8);
                                  }}
                                >
                                  <i
                                    className="fa fa-long-arrow-up"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              )}
                              {sortState === 9 ? (
                                <span
                                  className="lowerarrow"
                                  onClick={() => {
                                    sortCondition1();
                                    setSortState(9);
                                  }}
                                >
                                  <i
                                    className="fa fa-long-arrow-down active "
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              ) : (
                                <span
                                  className="lowerarrow"
                                  onClick={() => {
                                    sortCondition1();
                                    setSortState(9);
                                  }}
                                >
                                  <i
                                    className="fa fa-long-arrow-down "
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              )}
                            </th>
                            <th scope="col" className="customnameth">
                              Carrier{" "}
                              {sortState === 10 ? (
                                <span
                                  className="upperarrow"
                                  onClick={() => {
                                    sortCarrier();
                                    setSortState(10);
                                  }}
                                >
                                  <i
                                    className="fa fa-long-arrow-up active"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              ) : (
                                <span
                                  className="upperarrow"
                                  onClick={() => {
                                    sortCarrier();
                                    setSortState(10);
                                  }}
                                >
                                  <i
                                    className="fa fa-long-arrow-up"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              )}
                              {sortState === 11 ? (
                                <span
                                  className="lowerarrow"
                                  onClick={() => {
                                    sortCarrier1();
                                    setSortState(11);
                                  }}
                                >
                                  <i
                                    className="fa fa-long-arrow-down active "
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              ) : (
                                <span
                                  className="lowerarrow"
                                  onClick={() => {
                                    sortCarrier1();
                                    setSortState(11);
                                  }}
                                >
                                  <i
                                    className="fa fa-long-arrow-down "
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              )}
                            </th>
                            <th scope="col" className="customnameth">
                              Serial #/IMEI #{" "}
                              {sortState === 12 ? (
                                <span
                                  className="upperarrow"
                                  onClick={() => {
                                    sortIMEI();
                                    setSortState(12);
                                  }}
                                >
                                  <i
                                    className="fa fa-long-arrow-up active"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              ) : (
                                <span
                                  className="upperarrow"
                                  onClick={() => {
                                    sortIMEI();
                                    setSortState(12);
                                  }}
                                >
                                  <i
                                    className="fa fa-long-arrow-up"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              )}
                              {sortState === 13 ? (
                                <span
                                  className="lowerarrow"
                                  onClick={() => {
                                    sortIMEI1();
                                    setSortState(13);
                                  }}
                                >
                                  <i
                                    className="fa fa-long-arrow-down active "
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              ) : (
                                <span
                                  className="lowerarrow"
                                  onClick={() => {
                                    sortIMEI1();
                                    setSortState(13);
                                  }}
                                >
                                  <i
                                    className="fa fa-long-arrow-down "
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              )}
                            </th>
                            <th scope="col" className="customnameth">
                              Date Added{" "}
                              {sortState === 14 ? (
                                <span
                                  className="upperarrow"
                                  onClick={() => {
                                    sortDate();
                                    setSortState(14);
                                  }}
                                >
                                  <i
                                    className="fa fa-long-arrow-up active"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              ) : (
                                <span
                                  className="upperarrow"
                                  onClick={() => {
                                    sortDate();
                                    setSortState(14);
                                  }}
                                >
                                  <i
                                    className="fa fa-long-arrow-up"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              )}
                              {sortState === 15 ? (
                                <span
                                  className="lowerarrow"
                                  onClick={() => {
                                    sortDate1();
                                    setSortState(15);
                                  }}
                                >
                                  <i
                                    className="fa fa-long-arrow-down active "
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              ) : (
                                <span
                                  className="lowerarrow"
                                  onClick={() => {
                                    sortDate1();
                                    setSortState(15);
                                  }}
                                >
                                  <i
                                    className="fa fa-long-arrow-down "
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              )}
                            </th>
                            <th scope="col" className="customnameth">
                              PO#{" "}
                              {sortState === 16 ? (
                                <span
                                  className="upperarrow"
                                  onClick={() => {
                                    sortPO();
                                    setSortState(16);
                                  }}
                                >
                                  <i
                                    className="fa fa-long-arrow-up active"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              ) : (
                                <span
                                  className="upperarrow"
                                  onClick={() => {
                                    sortPO();
                                    setSortState(16);
                                  }}
                                >
                                  <i
                                    className="fa fa-long-arrow-up"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              )}
                              {sortState === 17 ? (
                                <span
                                  className="lowerarrow"
                                  onClick={() => {
                                    sortPO1();
                                    setSortState(17);
                                  }}
                                >
                                  <i
                                    className="fa fa-long-arrow-down active "
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              ) : (
                                <span
                                  className="lowerarrow"
                                  onClick={() => {
                                    sortPO1();
                                    setSortState(17);
                                  }}
                                >
                                  <i
                                    className="fa fa-long-arrow-down "
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              )}
                            </th>
                            <th scope="col" className="customnameth">
                              Unit Cost{" "}
                              {sortState === 18 ? (
                                <span
                                  className="upperarrow"
                                  onClick={() => {
                                    sortUnit();
                                    setSortState(18);
                                  }}
                                >
                                  <i
                                    className="fa fa-long-arrow-up active"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              ) : (
                                <span
                                  className="upperarrow"
                                  onClick={() => {
                                    sortUnit();
                                    setSortState(18);
                                  }}
                                >
                                  <i
                                    className="fa fa-long-arrow-up"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              )}
                              {sortState === 19 ? (
                                <span
                                  className="lowerarrow"
                                  onClick={() => {
                                    sortUnit1();
                                    setSortState(19);
                                  }}
                                >
                                  <i
                                    className="fa fa-long-arrow-down active "
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              ) : (
                                <span
                                  className="lowerarrow"
                                  onClick={() => {
                                    sortUnit1();
                                    setSortState(19);
                                  }}
                                >
                                  <i
                                    className="fa fa-long-arrow-down "
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              )}
                            </th>
                            <th scope="col" className="customnameth">
                              Retail price{" "}
                              {sortState === 20 ? (
                                <span
                                  className="upperarrow"
                                  onClick={() => {
                                    sortRetail();
                                    setSortState(20);
                                  }}
                                >
                                  <i
                                    className="fa fa-long-arrow-up active"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              ) : (
                                <span
                                  className="upperarrow"
                                  onClick={() => {
                                    sortRetail();
                                    setSortState(20);
                                  }}
                                >
                                  <i
                                    className="fa fa-long-arrow-up"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              )}
                              {sortState === 21 ? (
                                <span
                                  className="lowerarrow"
                                  onClick={() => {
                                    sortRetail1();
                                    setSortState(21);
                                  }}
                                >
                                  <i
                                    className="fa fa-long-arrow-down active "
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              ) : (
                                <span
                                  className="lowerarrow"
                                  onClick={() => {
                                    sortRetail1();
                                    setSortState(21);
                                  }}
                                >
                                  <i
                                    className="fa fa-long-arrow-down "
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              )}
                            </th>
                            <th scope="col" className="customnameth">
                              Status{" "}
                              {sortState === 22 ? (
                                <span
                                  className="upperarrow"
                                  onClick={() => {
                                    sortStatus();
                                    setSortState(22);
                                  }}
                                >
                                  <i
                                    className="fa fa-long-arrow-up active"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              ) : (
                                <span
                                  className="upperarrow"
                                  onClick={() => {
                                    sortStatus();
                                    setSortState(22);
                                  }}
                                >
                                  <i
                                    className="fa fa-long-arrow-up"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              )}
                              {sortState === 23 ? (
                                <span
                                  className="lowerarrow"
                                  onClick={() => {
                                    sortStatus1();
                                    setSortState(23);
                                  }}
                                >
                                  <i
                                    className="fa fa-long-arrow-down active "
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              ) : (
                                <span
                                  className="lowerarrow"
                                  onClick={() => {
                                    sortStatus1();
                                    setSortState(23);
                                  }}
                                >
                                  <i
                                    className="fa fa-long-arrow-down "
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              )}
                            </th>
                            <th scope="col" className="customnameth">
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody className="customtheadpadding">
                          {currentData.length ? (
                            <>
                              {currentData.map((item, index) => (
                                <>
                                  <tr
                                    key={index.id}
                                    className={
                                      index % 2 === 0 ? "" : "customeven"
                                    }
                                  >
                                    <td
                                      key={index.id}
                                      className={
                                        index % 2 === 0 ? "" : "bgcolor"
                                      }
                                    >
                                      <input
                                        key={index.id}
                                        className="form-check-input customcheckboxbtnsetting"
                                        type="checkbox"
                                        value=""
                                        id={`flexCheckDefault${index}`}
                                        onChange={() =>
                                          handleSelectRow(item.id)
                                        }
                                        checked={selectedItems.includes(
                                          item.id
                                        )}
                                      />
                                    </td>
                                    <td
                                      key={index.id}
                                      className={
                                        index % 2 === 0
                                          ? ""
                                          : " prod_img bgcolor"
                                      }
                                    >
                                      {item?.image ? (
                                        <img
                                          key={index.id}
                                          className="imglistcstm"
                                          src={item?.image}
                                          alt=""
                                        />
                                      ) : (
                                        <img
                                          src="assets/img/defaultimg.webp"
                                          className="imglistcstm"
                                          style={{ marginRight: "10px" }}
                                          alt=""
                                        />
                                      )}
                                      <span> {item?.brand?.name}</span>
                                    </td>
                                    <td
                                      key={index.id}
                                      className={
                                        index % 2 === 0 ? "" : "bgcolor"
                                      }
                                    >
                                      {item?.title}
                                    </td>
                                    <td
                                      key={index.id}
                                      className={
                                        index % 2 === 0 ? "" : "bgcolor"
                                      }
                                    >
                                      {item?.color?.name}
                                    </td>
                                    <td
                                      key={index.id}
                                      className={
                                        index % 2 === 0 ? "" : "bgcolor"
                                      }
                                    >
                                      {item?.storage?.size}
                                    </td>
                                    <td
                                      key={index.id}
                                      className={
                                        index % 2 === 0 ? "" : "bgcolor"
                                      }
                                    >
                                      {item?.condition?.condition}
                                    </td>
                                    <td
                                      key={index.id}
                                      className={
                                        index % 2 === 0 ? "" : "bgcolor"
                                      }
                                    >
                                      {item?.carrier?.name}
                                    </td>
                                    <td
                                      key={index.id}
                                      className={
                                        index % 2 === 0 ? "" : "bgcolor"
                                      }
                                    >
                                      {item?.imeiNumber}
                                    </td>
                                    <td
                                      key={index.id}
                                      className={
                                        index % 2 === 0 ? "" : "bgcolor"
                                      }
                                    >
                                      {moment(item?.createdAt).format(
                                        "MM/DD/YYYY"
                                      )}
                                    </td>
                                    <td
                                      key={index.id}
                                      className={
                                        index % 2 === 0 ? "" : "bgcolor"
                                      }
                                    >
                                      {item?.po}
                                    </td>
                                    <td
                                      key={index.id}
                                      className={
                                        index % 2 === 0 ? "" : "bgcolor"
                                      }
                                    >
                                      ${item?.unitCost.toFixed(2)}
                                    </td>
                                    <td
                                      key={index.id}
                                      className={
                                        index % 2 === 0 ? "" : "bgcolor"
                                      }
                                    >
                                      ${item?.sellingPrice.toFixed(2)}
                                    </td>
                                    <td
                                      key={index.id}
                                      className={
                                        index % 2 === 0
                                          ? ""
                                          : "status_td st_inactive bgcolor"
                                      }
                                    >
                                      {item?.draft === 1 ? (
                                        <span
                                          className="spanactiveinactivecstm2"
                                          key={index.id}
                                        >
                                          Draft
                                        </span>
                                      ) : item?.activeInactive === 1 ? (
                                        <span
                                          className="spanactiveinactivecstm1"
                                          key={index.id}
                                        >
                                          Active
                                        </span>
                                      ) : (
                                        <span
                                          className="spanactiveinactivecstm"
                                          key={index.id}
                                        >
                                          Inactive
                                        </span>
                                      )}
                                    </td>
                                    <td
                                      key={index.id}
                                      className={
                                        index % 2 === 0 ? "" : "bgcolor"
                                      }
                                    >
                                      <a
                                        key={index.id}
                                        href="#"
                                        data-toggle="modal"
                                        data-target="#createproductModal1"
                                        //to="/editmodal"
                                        state={{ data: item }}
                                        className="edit_action_btn"
                                        onClick={() => {
                                          setShowModal1(true);

                                          setTimeState(item);
                                        }}
                                        // to="/editdevice"
                                        // //to="/editmodal"
                                        // state={{ data: item }}
                                        // className="edit_action_btn"
                                      >
                                        <img
                                          src="assets/img/action-edit-icon.webp"
                                          alt=""
                                        />
                                      </a>

                                      <i
                                        className="fa-solid fa-trash deletecustmtb"
                                        onClick={() =>
                                          HandleDelete(
                                            item?.id,
                                            item?.imeiNumber
                                          )
                                        }
                                      ></i>
                                    </td>
                                  </tr>
                                </>
                              ))}
                            </>
                          ) : (
                            <>
                              <tr id="noDataMessage">
                                <td colSpan="14" className="nodataav">
                                  No data available
                                </td>
                              </tr>
                            </>
                          )}
                        </tbody>
                      </table>
                      {allProduct.length > 0 ? (
                        <div className="row">
                          <div className="col-md-12">
                            <div className="custombottompaginationbox">
                              <nav aria-label="Page navigation example">
                                <ul className="pagination">
                                  <li
                                    className="page-item custompaginationli  paginationleftbtnbox"
                                    style={{ cursor: "pointer" }}
                                  >
                                    <button
                                      type="button"
                                      className="page-link"
                                      onClick={() =>
                                        handlePageClick(currentPage - 1)
                                      }
                                      disabled={currentPage === 1}
                                    >
                                      <img src="assets/img/previous.webp" />
                                    </button>
                                  </li>

                                  {Array.from(
                                    { length: totalPages },
                                    (_, index) => index + 1
                                  ).map((page, key) => (
                                    <li
                                      className="page-item custompaginationli"
                                      key={key}
                                    >
                                      <a
                                        key={key}
                                        className={
                                          currentPage === page
                                            ? "page-link active customcountpagination"
                                            : "page-link customcountpagination"
                                        }
                                        onClick={() => handlePageClick(page)}
                                        disabled={currentPage === page}
                                        style={{ cursor: "pointer" }}
                                      >
                                        {page}
                                      </a>
                                    </li>
                                  ))}

                                  <li
                                    className="page-item custompaginationli paginationrightbtnbox"
                                    style={{ cursor: "pointer" }}
                                  >
                                    <button
                                      type="button"
                                      className="page-link"
                                      href="#"
                                      onClick={() =>
                                        handlePageClick(currentPage + 1)
                                      }
                                      disabled={currentPage === totalPages}
                                    >
                                      <img src="assets/img/next.webp" />
                                    </button>
                                  </li>
                                </ul>
                              </nav>
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* Main Content */}

          <Footer />
        </div>
        {showModal === true ? (
          <div
            className="modal fade common_modal createproduct_modal"
            id="createproductModal"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="createproductModalLabel"
            aria-hidden="true"
          >
            <DeviceModalPop onpress={onpress} onload={GetAllProducts} />
            {/* <DeviceModalList onpress={onpress} onload={GetAllProducts} /> */}
          </div>
        ) : null}
        {showModal1 === true ? (
          <div
            className="modal fade common_modal createproduct_modal"
            id="createproductModal1"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="createproductModalLabel1"
            aria-hidden="true"
          >
            <InventoryModal
              onpress={onpress1}
              onload={GetAllProducts}
              data={itemState}
            />
            {/* <DeviceModalList onpress={onpress} onload={GetAllProducts} /> */}
          </div>
        ) : null}
      </div>
    </>
  );
};
export default DeviceList;
