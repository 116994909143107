import React, { useEffect, useState } from "react";
import $ from "jquery";
import Footer from "../Footer";
import SideUl from "./SideUl";
import MainSidebar from "./MainSidebar";
import { Helmet } from "react-helmet";
import TicketModalList from "./TicketModalList";
import {
  baseurl,
  GetAllServiceApi,
  GetAllSubUserApi,
  GetAllTicketApi,
  GetStatusApi,
  ImportTicketApi,
  TicketStatusApi,
} from "../Utility/Api";
import { Link } from "react-router-dom";
import moment from "moment";
import Swal from "sweetalert2";
import FreeTrail from "./FreeTrail";

const TicketList = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("Filter Table");
  const [isSortAsc, setIsSortAsc] = useState(false);
  const [selectedCategory1, setSelectedCategory1] = useState("default");
  const [selectedCategory2, setSelectedCategory2] = useState("default");
  const [selectedCategory3, setSelectedCategory3] = useState("default");
  const [selectedCategory5, setSelectedCategory5] = useState("New");
  const [selectedCategory4, setSelectedCategory4] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [rowsPerPageOptions, setRowsPerPageOptions] = useState([10, 25, 50]);
  const [isCustomNameThActive, setIsCustomNameThActive] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [sortState, setSortState] = useState(null);
  const [allTickets, setAllTickets] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [allService, setAllService] = useState([]);
  const [filteredDataa, setFilteredData] = useState([]);
  const [AllBrand1, setAllBrand1] = useState([]);
  const [allSubuser, setAllSubuser] = useState([]);
  const [loading, setLoading] = useState(false);
  const [allstatus, setAllStatus] = useState([]);
  const [adminState, setAdminState] = useState("");
  const [selectedStatusId, setSelectedStatusId] = useState("");
  const [ticketStatus, setTicketStatus] = useState([]);
  const [todaycount, setTodayCount] = useState("");
  const [percentToday, setPercentToday] = useState("");
  const [totalArray, setTotalArray] = useState("");
  const [finishedCount, setFinishedCount] = useState("");
  const [csvFile, setCsvFile] = useState("");
  const [techPerson, setTechPerson] = useState([]);

  const handleCategoryChange = (value) => {
    setSelectedCategory2(value);
    if (value === "default") {
      setFilteredData(filteredData);
    } else {
      setFilteredData(filteredData.filter((item) => item?.name === value));
    }
  };

  const handleTechChange = (value) => {
    setSelectedCategory1(value);
    if (value === "default") {
      setFilteredData(filteredData);
    } else {
      // Update filtering condition to check for both userName and name
      setFilteredData(
        filteredData.filter(
          (item) =>
            item?.techPerson?.userName === value ||
            item?.techPerson?.name === value
        )
      );
    }
  };

  // const handleTechChange = (value) => {
  //   setSelectedCategory1(value);
  //   if (value === "default") {
  //     setFilteredData(filteredData);
  //   } else {
  //     setFilteredData(filteredData.filter((item) => item?.name === value));
  //   }
  // };

  const handleStatussChange = (value) => {
    // Set the selected status
    setSelectedCategory5(value);

    // If the selected status is "New", show all tickets
    if (value === "New") {
      setFilteredData(
        allTickets.filter((item) => item?.ticket?.statusOption?.name === "New")
      );
    }
    // For other statuses, filter based on the selected status
    else {
      setFilteredData(
        filteredData.filter(
          (item) => item?.ticket?.statusOption?.name === value
        )
      );
    }
  };

  const handleBrandChange = (value) => {
    setSelectedCategory3(value);
    if (value === "default") {
      setFilteredData(filteredData);
    } else {
      setFilteredData(filteredData.filter((item) => item?.name === value));
    }
  };
  const [permission, setPermission] = useState([]);
  useEffect(() => {
    let localAdmin = localStorage.getItem("admin");
    let localPerminssion = JSON.parse(localStorage.getItem("permission"));

    setAdminState(localAdmin);
    setPermission(localPerminssion);
    GetAlltickets();
    GetAllService();
    GetAllBrandDevice();
    GetAllSubUser();
    GetStatus();
    TicketStatus();
  }, []);

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    const newSelectedItems = selectAll
      ? []
      : allTickets.map((item) => item?.ticket.id);
    setSelectedItems(newSelectedItems);
  };

  const handleSelectRow = (id) => {
    // Toggle selected state for a specific item
    const newSelectedItems = selectedItems.includes(id)
      ? selectedItems.filter((itemId) => itemId.ticket?.id !== id)
      : [...selectedItems, id];

    setSelectedItems(newSelectedItems);
  };

  const handleSearchTermChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handlePageClick = (newPage) => {
    setCurrentPage(newPage);
  };
  const onpress = () => {
    setShowModal(false);
  };

  const GetAlltickets = () => {
    setLoading(true);
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(GetAllTicketApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log("ticket list", result?.data.reverse());
        setAllTickets(result?.data.reverse());
        // Extract all techPerson names, checking for both userName and name
        const allTechPersons = result?.data.map((ticket) => {
          const techPerson = ticket.techPerson;
          return techPerson?.userName || techPerson?.name; // Prioritize userName, fallback to name
        });

        // Create a unique set of techPerson names
        const uniqueTechPersons = [...new Set(allTechPersons)];
        setTechPerson(uniqueTechPersons);

        console.log("Unique tech persons", uniqueTechPersons);

        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };

  const filteredData =
    selectedCategory1 === "default" &&
    selectedCategory2 === "default" &&
    selectedCategory3 === "default" &&
    selectedCategory5 === "default"
      ? allTickets
      : allTickets.filter(
          (item) =>
            (selectedCategory1 === "default" ||
              item?.techPerson?.userName === selectedCategory1 || // Check userName
              item?.techPerson?.name === selectedCategory1) &&
            (selectedCategory2 === "default" ||
              item?.ticket?.service1?.name === selectedCategory2 ||
              item?.ticket?.service2?.name === selectedCategory2) &&
            (selectedCategory3 === "default" ||
              item?.ticket?.brand?.name === selectedCategory3) &&
            (selectedCategory5 === "default" ||
              item?.ticket?.statusOption?.name === selectedCategory5)
        );
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentData = filteredData
    .slice(startIndex, endIndex)
    .filter(
      (item) =>
        item?.ticket?.customer?.firstName
          .toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        item?.ticket?.customer?.email
          .toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        item?.ticket?.customer?.lastName
          .toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        item?.ticket?.customer?.phoneNumber
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
    );

  const sortTicket = (a) => {
    let sorted = allTickets.sort((a, b) => (a?.id > b?.id ? 1 : -1));

    setAllTickets([...sorted]);
  };
  const sortTicket1 = (a) => {
    let sorted = allTickets.sort((a, b) => (a?.id < b?.id ? 1 : -1));
    setAllTickets([...sorted]);
  };

  const sortName = (a) => {
    let sorted = allTickets.sort((a, b) =>
      a?.ticket?.customer?.firstName > b?.ticket?.customer?.firstName ? 1 : -1
    );

    setAllTickets([...sorted]);
  };
  const sortName1 = (a) => {
    let sorted = allTickets.sort((a, b) =>
      a?.ticket?.customer?.firstName < b?.ticket?.customer?.firstName ? 1 : -1
    );
    setAllTickets([...sorted]);
  };
  const sortEmail = (a) => {
    let sorted = allTickets.sort((a, b) =>
      a?.ticket?.customer?.email > b?.ticket?.customer?.email ? 1 : -1
    );
    setAllTickets([...sorted]);
  };
  const sortEmail1 = (a) => {
    let sorted = allTickets.sort((a, b) =>
      a?.ticket?.customer?.email < b?.ticket?.customer?.email ? 1 : -1
    );

    setAllTickets([...sorted]);
  };
  const sortDevice = (a) => {
    let sorted = allTickets.sort((a, b) =>
      a?.ticket?.customerDevice?.model > b?.ticket?.customerDevice?.model
        ? 1
        : -1
    );

    setAllTickets([...sorted]);
  };
  const sortDevice1 = (a) => {
    let sorted = allTickets.sort((a, b) =>
      a?.ticket?.customerDevice?.model < b?.ticket?.customerDevice?.model
        ? 1
        : -1
    );

    setAllTickets([...sorted]);
  };
  const sortService = (a) => {
    let sorted = allTickets.sort((a, b) =>
      a?.ticket?.service1?.name > b?.ticket?.service1?.name ? 1 : -1
    );

    setAllTickets([...sorted]);
  };
  const sortService1 = (a) => {
    let sorted = allTickets.sort((a, b) =>
      a?.ticket?.service1?.name < b?.ticket?.service1?.name ? 1 : -1
    );

    setAllTickets([...sorted]);
  };

  const sortDate = (a) => {
    let sorted = allTickets.sort((a, b) =>
      a?.ticket?.service1?.name > b?.ticket?.service1?.name ? 1 : -1
    );

    setAllTickets([...sorted]);
  };
  const sortDate1 = (a) => {
    let sorted = allTickets.sort((a, b) =>
      a?.ticket?.service1?.name < b?.ticket?.service1?.name ? 1 : -1
    );

    setAllTickets([...sorted]);
  };
  const sortStatus = (a) => {
    let sorted = allTickets.sort((a, b) =>
      a?.ticket?.statusOption?.name > b?.ticket?.statusOption?.name ? 1 : -1
    );

    setAllTickets([...sorted]);
  };
  const sortStatus1 = (a) => {
    let sorted = allTickets.sort((a, b) =>
      a?.ticket?.statusOption?.name < b?.ticket?.statusOption?.name ? 1 : -1
    );

    setAllTickets([...sorted]);
  };

  const GetAllService = () => {
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(GetAllServiceApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setAllService(result?.data.reverse());
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const GetAllBrandDevice = () => {
    let local = localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);
    myHeaders.append(
      "Cookie",
      "connect.sid=s%3AEXeh7T29UatkCPBdNwoIzBrcxDT_mEJd.RTtxRKFGO9m4w4ldAFV3Z016ghUV6rHgNPsCPhrrqgQ"
    );

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${baseurl}getAllBrand?productCategoryId=2`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setAllBrand1(result?.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const GetAllSubUser = () => {
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(GetAllSubUserApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === true) {
          // console.log("setAllSubuser", result?.data);
          setAllSubuser(result?.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  function formatPhoneNumber(phoneNumber) {
    // Check if phoneNumber is provided
    if (!phoneNumber) {
      return "--";
    }

    // Remove all non-digit characters from the phone number
    const digitsOnly = phoneNumber.replace(/\D/g, "");

    // Check if the phone number has a country code
    if (digitsOnly.length > 10) {
      // Extract the country code and phone number without the country code
      const countryCode = digitsOnly.slice(0, digitsOnly.length - 10);
      const phoneNumberWithoutCode = digitsOnly.slice(-10);

      return `(+${countryCode})${phoneNumberWithoutCode}`;
    } else {
      const areaCode = digitsOnly.slice(0, 3);
      const firstPart = digitsOnly.slice(3, 6);
      const secondPart = digitsOnly.slice(6);

      return `(${areaCode})${firstPart}-${secondPart}`;
    }
  }

  const DeleteTicket = (id) => {
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      isDeleted: 1,
    });

    const requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${baseurl}updateTicket/${id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === true) {
          GetAlltickets();
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const HandleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete data",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#c76cff",
      cancelButtonColor: "#efefef",
      confirmButtonText: "Yes, delete it!",
      confirmButtonText: "<span style='color: white;'>Yes, delete it!</span>",
      cancelButtonText: "<span style='color: black;'>cancel!</span>",
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      customClass: {
        container: "sweet-alert2-container-zindex",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Deleted!",
          text: "Your file has been deleted.",
          icon: "success",
          confirmButtonColor: "#c76cff",
          customClass: {
            container: "sweet-alert2-container-zindex",
          },
        }).then(() => {
          DeleteTicket(id);
        });
      }
    });
  };

  const handleFilterChange1 = (event) => {
    const selectedValue = event.currentTarget.textContent.trim();

    if (selectedValue === "Date") {
      const sortedData = [...allTickets].sort((a, b) => {
        const nameA = `${a?.ticket?.createdAt}`.toLowerCase();
        const nameB = `${b?.ticket?.createdAt}`.toLowerCase();

        if (isSortAsc) {
          return nameA.localeCompare(nameB);
        } else {
          return nameB.localeCompare(nameA);
        }
      });

      setAllTickets(sortedData);
      setIsSortAsc(!isSortAsc);
      setSelectedFilter("Date");
    } else if (selectedValue === "Ticket") {
      const sortedData = [...allTickets].sort((a, b) => {
        const nameA = `${a?.ticket?.id}`.toLowerCase();
        const nameB = `${b?.ticket?.id}`.toLowerCase();

        if (isSortAsc) {
          return nameA.localeCompare(nameB);
        } else {
          return nameB.localeCompare(nameA);
        }
      });

      setAllTickets(sortedData);
      setIsSortAsc(!isSortAsc);

      setSelectedFilter("Ticket");
    } else if (selectedValue === "Last UpDate") {
      const sortedData = [...allTickets].sort((a, b) => {
        const nameA = `${a?.ticket?.updatedAt}`.toLowerCase();
        const nameB = `${b?.ticket?.updatedAt}`.toLowerCase();

        if (isSortAsc) {
          return nameA.localeCompare(nameB);
        } else {
          return nameB.localeCompare(nameA);
        }
      });

      setAllTickets(sortedData);
      setIsSortAsc(!isSortAsc);

      setSelectedFilter("Last UpDate");
    } else if (selectedValue === "First Name") {
      const sortedData = [...allTickets].sort((a, b) => {
        const nameA = `${a?.ticket?.customer?.firstName}`.toLowerCase();
        const nameB = `${b?.ticket?.customer?.firstName}`.toLowerCase();

        if (isSortAsc) {
          return nameA.localeCompare(nameB);
        } else {
          return nameB.localeCompare(nameA);
        }
      });

      setAllTickets(sortedData);
      setIsSortAsc(!isSortAsc);

      setSelectedFilter("First Name");
    } else if (selectedValue === "Last Name") {
      const sortedData = [...allTickets].sort((a, b) => {
        const nameA = `${a?.ticket?.customer?.lastName}`.toLowerCase();
        const nameB = `${b?.ticket?.customer?.lastName}`.toLowerCase();

        if (isSortAsc) {
          return nameA.localeCompare(nameB);
        } else {
          return nameB.localeCompare(nameA);
        }
      });

      setAllTickets(sortedData);
      setIsSortAsc(!isSortAsc);

      setSelectedFilter("Last Name");
    } else {
      setSelectedFilter("Filter Table");
    }
  };

  const [statusId, setStatusId] = useState("");
  const GetStatus = () => {
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(GetStatusApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        const sortedStatusList = result?.data.sort((a, b) => {
          if (a?.name === "New") return -1;
          if (b?.name === "New") return 1;

          if (a?.name === "Finished") return 1;
          if (b?.name === "Finished") return -1;
          return a.name.localeCompare(b.name);
        });
        setAllStatus(sortedStatusList);
        const defaultNewStatus = sortedStatusList.find(
          (status) => status.name === "New"
        );

        if (defaultNewStatus) {
          setStatusId(defaultNewStatus.id);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const EditTicket = (selectedStatusId, d) => {
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);

    const raw = JSON.stringify({
      customerId: d?.customerId,
      customerDeviceId: d?.customerDeviceId,
      brandId: d?.brandId,
      serviceId1: d?.serviceId1,
      serviceId2: d?.serviceId2,
      statusOptionId: selectedStatusId,
      techAssignedId: d?.techAssignedId,
      salesPersonId: d?.salesPersonId,
      dueDate: d?.dueDate,
      password: d?.password,
      description: d?.description,
      drivingLicence: d?.drivingLicence,
      bin: d?.bin,
      isAdmin: adminState,
      isTechAdmin: d?.isTechAdmin,
      isSalesPersonAdmin: d?.isSalesPersonAdmin,
      isInvoice: d?.isInvoice,
      invoiceId: d?.invoiceId,
      techAssignedName: d?.techAssignedName,
    });

    const requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${baseurl}updateTicket/${d?.id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === true) {
          GetAlltickets();
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // const handleStatusChange = (selectedStatusId, ticket) => {
  //   EditTicket(selectedStatusId?.id, ticket);
  //   setSelectedCategory4(selectedStatusId?.name);
  //   setSelectedStatusId(selectedStatusId?.id);
  // };
  // const handleStatusChange = (selectedStatus, ticket) => {
  //   // Call the EditTicket function with the selected status ID and the ticket object
  //   EditTicket(selectedStatus?.id, ticket);
  //   // Update the selectedCategory4 state with the selected status name
  //   setSelectedCategory4(selectedStatus?.name);
  // };
  const handleStatusChange = (selectedStatus, ticket) => {
    EditTicket(selectedStatus.id, ticket);
    setSelectedCategory4(selectedStatus.name);
    setSelectedStatusId(selectedStatus.id);
  };

  const TicketStatus = () => {
    let local = localStorage.getItem("token");

    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(TicketStatusApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true) {
          setTicketStatus(result?.data);
          setTodayCount(result?.todayTicketCounts.toFixed(2));
          const data = result?.data?.map((item) => item?.status);

          const finishedStatusObject = result?.data.find(
            (item) => item?.status === "Finished"
          );
          setFinishedCount(finishedStatusObject?.ticketCount);

          const ticketStatusData = result?.data;
          // Calculate total ticket count with status 'New' and 'Finished'

          let totalTicketCount = 0;
          let finishedTicketCount = 0;
          const desiredStatuses = data;
          // Iterate through ticket status data to calculate totals
          ticketStatusData.forEach((item) => {
            if (desiredStatuses.includes(item.status)) {
              totalTicketCount += item.ticketCount;
            }
            if (item.status === "Finished") {
              finishedTicketCount += item.ticketCount;
            }
          });

          // Calculate the difference between total tickets for desired statuses and 'Finished' tickets
          const ticketCountDifference = totalTicketCount - finishedTicketCount;

          setTotalArray(ticketCountDifference);
          const prev = parseFloat(result?.previousDateTicketCounts);
          const today = parseFloat(result?.todayTicketCounts);

          if (!isNaN(prev) && !isNaN(today) && prev !== 0) {
            const percentToday = (today / prev) * 100;
            setPercentToday(percentToday.toFixed(2));

            // Use percentToday.toFixed(2) to format the percentage to two decimal places
          } else {
            //console.log("Invalid input. Please provide valid numeric values.");
          }
        } else {
          console.log(result.message);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const [isFreeTrial, setIsFreeTrial] = useState(false);

  useEffect(() => {
    const substatus = localStorage.getItem("substatus");
    const planDate = localStorage.getItem("planDate");

    if (
      substatus === "null" ||
      substatus === "canceled" ||
      substatus === "expired"
    ) {
      setIsFreeTrial(true);
    }
  }, []);

  const [isSidebarVisible, setSidebarVisible] = useState(false);

  const toggleSidebar = () => {
    setSidebarVisible(!isSidebarVisible);
    localStorage.setItem("sidebar", !isSidebarVisible);
  };

  useEffect(() => {
    const savedSidebarState = localStorage.getItem("sidebar");
    if (savedSidebarState) {
      setSidebarVisible(JSON.parse(savedSidebarState));
    }
  }, []);

  const [isFreeTrialVisible, setFreeTrialVisible] = useState(true);

  const toggleFreeTrial = () => {
    setFreeTrialVisible(!isFreeTrialVisible);
  };

  const sidebarStyleone = {
    overflow: "auto",
    scrollbarWidth: "none",
    msOverflowStyle: "none",
    // position: 'sticky',

    // marginTop: isFreeTrialVisible ? "0px" : "0px",
  };

  const navbarStyle = {
    // top: isFreeTrialVisible ? "-4px" : "-50px",
  };
  const mainContentStyle = {
    // marginTop: isFreeTrialVisible ? "132px" : "92px",
  };
  const mainwrapper = {
    // marginTop: isSidebarVisible ? "0" : "0",
  };
  useEffect(() => {
    if (isFreeTrial) {
      // toggleSidebar(false);
      toggleFreeTrial(false);
    } else {
      // toggleSidebar(true);
      toggleFreeTrial(true);
    }
  }, [isFreeTrial]);
  useEffect(() => {
    const handleClickOutside = (event) => {
      const sidebarElement = document.querySelector(".main-sidebar");
      const toggleButtonElement = document.querySelector(".sidebar-arrow a");

      if (
        sidebarElement &&
        !sidebarElement.contains(event.target) &&
        toggleButtonElement &&
        !toggleButtonElement.contains(event.target)
      ) {
        const mediaQuery = window.matchMedia("(max-width: 1024px)");
        if (mediaQuery.matches) {
          setSidebarVisible(false);
        }
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleImport = () => {
    // Logic for importing data
    console.log("Import button clicked");
    document.getElementById("fileInput").click();
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setCsvFile(file);

      ImportCsv(file);

      // console.log("File selected:", file.name);
    }
  };

  const ImportCsv = (file, retries = 3) => {
    setLoading(true);
    const local = localStorage.getItem("token");

    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    const formdata = new FormData();
    formdata.append("file", file);

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    // console.log("form", formdata);

    const fetchData = (attempt) => {
      fetch(ImportTicketApi, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          console.log(result);

          if (result.status === true) {
            GetAlltickets();
            document.getElementById("fileInput").value = "";
            setLoading(false);
          } else {
            document.getElementById("fileInput").value = "";
            setLoading(false);
          }
        })
        .catch((error) => {
          document.getElementById("fileInput").value = "";
          if (attempt < retries) {
            console.log(`Retrying... (${attempt + 1}/${retries})`);
            fetchData(attempt + 1);
          } else {
            setLoading(false);
            console.error(error);
          }
        });
    };

    fetchData(0);
  };

  const startPage = Math.max(currentPage - 2, 1);
  const endPage = Math.min(startPage + 3, totalPages);
  const pages = Array.from({ length: totalPages }, (_, index) => index + 1);

  return (
    <>
      <div className={isSidebarVisible ? "sidebar-mini" : ""}>
        <div
          className={`${
            isFreeTrial
              ? "main-wrapper devicelistcstmtableres customclswrapper"
              : "main-wrapper devicelistcstmtableres "
          } `}
        >
          <Helmet>
            <title>Manage Tickets Effectively | Toolbox Ticket List</title>
            <meta
              name="description"
              content="Efficiently organize and handle tickets with our Ticket List tool. Streamline communication and enhance customer support effortlessly."
            />
          </Helmet>
          {loading ? (
            <div className="landing_loader">
              <div className="innerloader">
                <img src="img/spin-1s-156px.gif" alt="" />
              </div>
            </div>
          ) : null}
          {isFreeTrial ? (
            <>
              {localStorage.getItem("substatus") === "null" ||
              localStorage.getItem("substatus") === "canceled" ||
              localStorage.getItem("substatus") === "expired" ? (
                <>
                  <div className="app-container-trial">
                    <FreeTrail />
                  </div>
                  <div style={sidebarStyleone}>
                    <nav className="navbar navbar-expand-lg main-navbar sticky">
                      <div className="sidebar-arrow">
                        <a
                          href="#"
                          data-toggle="sidebar"
                          onClick={(e) => {
                            toggleSidebar();

                            e.preventDefault();
                          }}
                        >
                          <img src="assets/img/sidebar-toggle.webp" alt="" />
                        </a>
                      </div>
                      <div className="header-title mr-auto">
                        <h4>
                          Tickets{" "}
                          <span className="count">
                            {allTickets?.length} Tickets
                          </span>
                        </h4>
                      </div>
                      <SideUl />
                    </nav>
                    <MainSidebar />{" "}
                    <div className="main-content tableback">
                      <div className="common_filter_sec ticket_filter">
                        <div className="filter_col">
                          <div className="ticket_counter">
                            <div className="counter_col">
                              <p>
                                Active Services (Open):{" "}
                                <span>{totalArray ? totalArray : 0}</span>
                              </p>
                              {/* <span className="cus_badge">
                    <img src="assets/img/arrowup-icon.webp" alt="" /> 10%
                  </span> */}
                            </div>
                            <div className="counter_col">
                              <p>
                                New Repair (Today):{" "}
                                <span>{todaycount ? todaycount : 0}</span>
                              </p>
                              <span className="cus_badge">
                                <img
                                  src="assets/img/arrowup-icon.webp"
                                  alt=""
                                />{" "}
                                {percentToday ? percentToday : 0}%
                              </span>
                            </div>
                            <div className="counter_col">
                              <p>
                                Finished:
                                <span>{finishedCount ? finishedCount : 0}</span>
                              </p>
                            </div>

                            <div className="ft_select_option status_option customdropdownulboxcate filterticketlistresponsive1 ">
                              <div className="dropdown repaircustomselectfilterstatus repairsfilterdropdowncustm1status">
                                <button
                                  type="button"
                                  className="btn btn-secondary dropdown-toggle customdropdowntablebtnsetting textaligncustmtext"
                                  role="button"
                                  id="dropdownMenuLink"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  {selectedCategory5 === "default"
                                    ? "Select Status"
                                    : selectedCategory5}
                                </button>
                                <ul
                                  className="dropdown-menu customdropdownulboxsetting ulcustmrepairdropdowncss"
                                  aria-labelledby="dropdownMenuLink"
                                >
                                  <li>
                                    <a
                                      className="dropdown-item"
                                      href="#"
                                      onClick={() =>
                                        handleStatussChange("default")
                                      }
                                    >
                                      Select Status
                                    </a>
                                  </li>
                                  {allstatus.map((category) => (
                                    <li key={category.id}>
                                      <a
                                        className="dropdown-item"
                                        href="#"
                                        onClick={() =>
                                          handleStatussChange(category.name)
                                        }
                                      >
                                        {category.name}
                                      </a>
                                    </li>
                                  ))}
                                </ul>
                                <img
                                  src="assets/img/select-arrow.webp"
                                  alt=""
                                />
                              </div>
                            </div>
                            {/* <p>
                    Pending Pick up: <span>10</span>
                  </p> */}
                          </div>

                          <div
                            className="add_customer_btn"
                            onClick={() => setShowModal(true)}
                          >
                            <a
                              href="#"
                              data-toggle="modal"
                              data-target="#newserviceModal"
                            >
                              Create Ticket <i className="fa fa-plus"></i>
                            </a>
                          </div>
                        </div>
                        <div className="filter_col">
                          <div className="filter_tble_btn">
                            <div className="dropdown  filterticketlistresponsive ">
                              <button
                                type="button"
                                className="btn btn-secondary dropdown-toggle customdropdowntablebtnsetting"
                                href="#"
                                role="button"
                                id="dropdownMenuLink"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                {selectedFilter === "default"
                                  ? "Filter Table"
                                  : selectedFilter}
                              </button>
                              <img
                                src="assets/img/filter-icon.webp"
                                alt=""
                                className="filtericonimgboxsetting"
                              />
                              <ul
                                className="dropdown-menu customdropdownulboxsetting"
                                aria-labelledby="dropdownMenuLink"
                              >
                                <li>
                                  <a
                                    className="dropdown-item"
                                    href="#"
                                    onClick={handleFilterChange1}
                                  >
                                    Filter Table
                                  </a>
                                </li>
                                <li>
                                  <a
                                    className="dropdown-item"
                                    href="#"
                                    onClick={handleFilterChange1}
                                  >
                                    Date
                                  </a>
                                </li>
                                <li>
                                  <a
                                    className="dropdown-item"
                                    href="#"
                                    onClick={handleFilterChange1}
                                  >
                                    Ticket
                                  </a>
                                </li>
                                <li>
                                  <a
                                    className="dropdown-item"
                                    href="#"
                                    onClick={handleFilterChange1}
                                  >
                                    Last UpDate
                                  </a>
                                </li>
                                <li>
                                  <a
                                    className="dropdown-item"
                                    href="#"
                                    onClick={handleFilterChange1}
                                  >
                                    First Name
                                  </a>
                                </li>
                                <li>
                                  <a
                                    className="dropdown-item"
                                    href="#"
                                    onClick={handleFilterChange1}
                                  >
                                    Last Name
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="search_field">
                            <div className="search_icon">
                              <img src="assets/img/search-icon.webp" alt="" />
                            </div>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Search First Name/Surname/Email/Phone#"
                              value={searchTerm}
                              onChange={handleSearchTermChange}
                            />
                          </div>
                          <div className="ft_select_option status_option customdropdownulboxcate filterticketlistresponsive1 ">
                            <div className="dropdown repaircustomselectfilter repairsfilterdropdowncustm1">
                              <button
                                type="button"
                                className="btn btn-secondary dropdown-toggle customdropdowntablebtnsetting textaligncustmtext"
                                role="button"
                                id="dropdownMenuLink"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                {selectedCategory1 === "default"
                                  ? "Assigned Tech"
                                  : selectedCategory1}
                              </button>
                              <ul
                                className="dropdown-menu customdropdownulboxsetting ulcustmrepairdropdowncss"
                                aria-labelledby="dropdownMenuLink"
                              >
                                <li>
                                  <a
                                    className="dropdown-item"
                                    href="#"
                                    onClick={() => handleTechChange("default")}
                                  >
                                    Assigned Tech
                                  </a>
                                </li>
                                {techPerson.map((category) => (
                                  <li key={category?.id}>
                                    <a
                                      className="dropdown-item"
                                      href="#"
                                      onClick={() => handleTechChange(category)}
                                    >
                                      {category}
                                    </a>
                                  </li>
                                ))}
                                {/* {allSubuser.map((category) => (
                                  <li key={category?.id}>
                                    <a
                                      className="dropdown-item"
                                      href="#"
                                      onClick={() =>
                                        handleTechChange(category?.name)
                                      }
                                    >
                                      {category?.subUser?.name}
                                    </a>
                                  </li>
                                ))} */}
                              </ul>
                              <img src="assets/img/select-arrow.webp" alt="" />
                            </div>
                          </div>
                          <div className="ft_select_option status_option customdropdownulboxcate filterticketlistresponsive2 ">
                            <div className="dropdown repaircustomselectfilter repairsfilterdropdowncustm2">
                              <button
                                type="button"
                                className="btn btn-secondary dropdown-toggle customdropdowntablebtnsetting textaligncustmtext"
                                role="button"
                                id="dropdownMenuLink"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                {selectedCategory2 === "default"
                                  ? " Select Service"
                                  : selectedCategory2}
                              </button>
                              <ul
                                className="dropdown-menu customdropdownulboxsetting ulcustmrepairdropdowncss"
                                aria-labelledby="dropdownMenuLink"
                              >
                                <li>
                                  <a
                                    className="dropdown-item"
                                    href="#"
                                    onClick={() =>
                                      handleCategoryChange("default")
                                    }
                                  >
                                    Select Service
                                  </a>
                                </li>
                                {allService.map((category) => (
                                  <li key={category.id}>
                                    <a
                                      className="dropdown-item"
                                      href="#"
                                      onClick={() =>
                                        handleCategoryChange(category.name)
                                      }
                                    >
                                      {category.name}
                                    </a>
                                  </li>
                                ))}
                              </ul>
                              <img src="assets/img/select-arrow.webp" alt="" />
                            </div>
                          </div>
                          <div className="ft_select_option status_option customdropdownulboxcate filterticketlistresponsive3 ">
                            <div className="dropdown repaircustomselectfilter repairsfilterdropdowncustm3">
                              <button
                                type="button"
                                className="btn btn-secondary dropdown-toggle customdropdowntablebtnsetting textaligncustmtext"
                                role="button"
                                id="dropdownMenuLink"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                {selectedCategory3 === "default"
                                  ? "Select Brand"
                                  : selectedCategory3}
                              </button>
                              <ul
                                className="dropdown-menu customdropdownulboxsetting ulcustmrepairdropdowncss"
                                aria-labelledby="dropdownMenuLink"
                              >
                                <li>
                                  <a
                                    className="dropdown-item"
                                    href="#"
                                    onClick={() => handleBrandChange("default")}
                                  >
                                    Select Brand
                                  </a>
                                </li>
                                {AllBrand1.map((category) => (
                                  <li key={category.id}>
                                    <a
                                      className="dropdown-item"
                                      href="#"
                                      onClick={() =>
                                        handleBrandChange(category.name)
                                      }
                                    >
                                      {category.name}
                                    </a>
                                  </li>
                                ))}
                              </ul>
                              <img src="assets/img/select-arrow.webp" alt="" />
                            </div>
                          </div>
                          {/* <div className="imp_exp_btn export_btn">
                <button type="button">
                  <img src="assets/img/export-icon.webp" alt="" /> Export
                </button>
              </div> */}
                          <div className="imp_exp_btn import_btn">
                            <button type="button" onClick={handleImport}>
                              <img src="assets/img/import-icon.webp" alt="" />{" "}
                              Import
                            </button>
                            <input
                              type="file"
                              id="fileInput"
                              style={{ display: "none" }}
                              onChange={handleFileChange}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="clearfix"></div>
                      <div className="row">
                        <div className="col-lg-12 col-md-12">
                          <div className="customertablemain">
                            <table className="table table-striped  customdatatable">
                              <thead className="customtheadpadding">
                                <tr style={{ cursor: "pointer" }}>
                                  <th scope="col" className="checkbtnthsetting">
                                    <input
                                      className="form-check-input customcheckboxbtnsetting"
                                      type="checkbox"
                                      value=""
                                      id="flexCheckDefault"
                                      onChange={handleSelectAll}
                                      checked={selectAll}
                                    />
                                  </th>
                                  <th scope="col" className="customnameth">
                                    Ticket#
                                    {sortState === 0 ? (
                                      <span
                                        className="upperarrow"
                                        onClick={() => {
                                          sortTicket();
                                          setSortState(0);
                                        }}
                                      >
                                        <i
                                          className="fa fa-long-arrow-up active"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    ) : (
                                      <span
                                        className="upperarrow"
                                        onClick={() => {
                                          sortTicket();
                                          setSortState(0);
                                        }}
                                      >
                                        <i
                                          className="fa fa-long-arrow-up"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    )}
                                    {sortState === 1 ? (
                                      <span
                                        className="lowerarrow"
                                        onClick={() => {
                                          sortTicket1();
                                          setSortState(1);
                                        }}
                                      >
                                        <i
                                          className="fa fa-long-arrow-down active "
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    ) : (
                                      <span
                                        className="lowerarrow"
                                        onClick={() => {
                                          sortTicket1();
                                          setSortState(1);
                                        }}
                                      >
                                        <i
                                          className="fa fa-long-arrow-down "
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    )}
                                  </th>
                                  <th scope="col">Name</th>
                                  <th scope="col">Email/Phone #</th>
                                  <th scope="col" className="customnameth">
                                    Device
                                    {sortState === 2 ? (
                                      <span
                                        className="upperarrow"
                                        onClick={() => {
                                          sortDevice();
                                          setSortState(2);
                                        }}
                                      >
                                        <i
                                          className="fa fa-long-arrow-up active"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    ) : (
                                      <span
                                        className="upperarrow"
                                        onClick={() => {
                                          sortDevice();
                                          setSortState(2);
                                        }}
                                      >
                                        <i
                                          className="fa fa-long-arrow-up"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    )}
                                    {sortState === 3 ? (
                                      <span
                                        className="lowerarrow"
                                        onClick={() => {
                                          sortDevice1();
                                          setSortState(3);
                                        }}
                                      >
                                        <i
                                          className="fa fa-long-arrow-down active"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    ) : (
                                      <span
                                        className="lowerarrow"
                                        onClick={() => {
                                          sortDevice1();
                                          setSortState(3);
                                        }}
                                      >
                                        <i
                                          className="fa fa-long-arrow-down "
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    )}
                                  </th>
                                  <th
                                    scope="col"
                                    className={`customnameth ${
                                      isCustomNameThActive
                                        ? "customnamethactive"
                                        : "customnameth"
                                    }`}
                                    //onClick={handleLocationSort}
                                  >
                                    Service
                                    {sortState === 4 ? (
                                      <span
                                        className="upperarrow"
                                        onClick={() => {
                                          sortService();
                                          setSortState(4);
                                        }}
                                      >
                                        <i
                                          className="fa fa-long-arrow-up active"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    ) : (
                                      <span
                                        className="upperarrow"
                                        onClick={() => {
                                          sortService();
                                          setSortState(4);
                                        }}
                                      >
                                        <i
                                          className="fa fa-long-arrow-up"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    )}
                                    {sortState === 5 ? (
                                      <span
                                        className="lowerarrow"
                                        onClick={() => {
                                          sortService1();
                                          setSortState(5);
                                        }}
                                      >
                                        <i
                                          className="fa fa-long-arrow-down active"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    ) : (
                                      <span
                                        className="lowerarrow"
                                        onClick={() => {
                                          sortService1();
                                          setSortState(5);
                                        }}
                                      >
                                        <i
                                          className="fa fa-long-arrow-down"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    )}
                                  </th>
                                  <th
                                    scope="col"
                                    className={`customnameth ${
                                      isCustomNameThActive
                                        ? "customnamethactive"
                                        : "customnameth"
                                    }`}
                                  >
                                    Date Created
                                    {sortState === 6 ? (
                                      <span
                                        className="upperarrow"
                                        onClick={() => {
                                          setSortState(6);
                                        }}
                                      >
                                        <i
                                          className="fa fa-long-arrow-up active"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    ) : (
                                      <span
                                        className="upperarrow"
                                        onClick={() => {
                                          setSortState(6);
                                        }}
                                      >
                                        <i
                                          className="fa fa-long-arrow-up"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    )}
                                    {sortState === 7 ? (
                                      <span
                                        className="lowerarrow"
                                        onClick={() => {
                                          setSortState(7);
                                        }}
                                      >
                                        <i
                                          className="fa fa-long-arrow-down active"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    ) : (
                                      <span
                                        className="lowerarrow"
                                        onClick={() => {
                                          setSortState(7);
                                        }}
                                      >
                                        <i
                                          className="fa fa-long-arrow-down "
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    )}
                                  </th>
                                  <th
                                    scope="col"
                                    className={`customnameth ${
                                      isCustomNameThActive
                                        ? "customnamethactive"
                                        : "customnameth"
                                    }`}
                                    //onClick={handleStoreSort}
                                  >
                                    Status
                                    {sortState === 8 ? (
                                      <span
                                        className="upperarrow storearrcust"
                                        onClick={() => {
                                          sortStatus();
                                          setSortState(8);
                                        }}
                                      >
                                        <i
                                          className="fa fa-long-arrow-up active"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    ) : (
                                      <span
                                        className="upperarrow storearrcust"
                                        onClick={() => {
                                          sortStatus();
                                          setSortState(8);
                                        }}
                                      >
                                        <i
                                          className="fa fa-long-arrow-up "
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    )}
                                    {sortState === 9 ? (
                                      <span
                                        className="lowerarrow responsstore"
                                        onClick={() => {
                                          sortStatus1();
                                          setSortState(9);
                                        }}
                                      >
                                        <i
                                          className="fa fa-long-arrow-down active"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    ) : (
                                      <span
                                        className="lowerarrow responsstore"
                                        onClick={() => {
                                          sortStatus1();
                                          setSortState(9);
                                        }}
                                      >
                                        <i
                                          className="fa fa-long-arrow-down"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    )}
                                  </th>

                                  <th scope="col">Action</th>
                                </tr>
                              </thead>
                              <tbody className="customtheadpadding">
                                {currentData.length ? (
                                  <>
                                    {currentData.map((item, index) => (
                                      <tr
                                        key={index.id}
                                        className={
                                          index % 2 === 0 ? "" : "customeven"
                                        }
                                      >
                                        <td
                                          key={index.id}
                                          className={
                                            index % 2 === 0 ? "" : "bgcolor"
                                          }
                                        >
                                          <Link
                                            to="/ticketdetail"
                                            state={{ data: item }}
                                            className="editbtn"
                                          >
                                            <input
                                              key={index.id}
                                              className="form-check-input customcheckboxbtnsetting"
                                              type="checkbox"
                                              value=""
                                              id={`flexCheckDefault${index}`}
                                              onChange={() =>
                                                handleSelectRow(item.ticket?.id)
                                              }
                                              checked={selectedItems.includes(
                                                item.ticket?.id
                                              )}
                                            />
                                          </Link>
                                        </td>

                                        <td
                                          key={index.id}
                                          className={
                                            index % 2 === 0 ? "" : "bgcolor"
                                          }
                                        >
                                          {" "}
                                          <Link
                                            to="/ticketdetail"
                                            state={{ data: item }}
                                            className="editbtn"
                                          >
                                            {" "}
                                            #{item?.ticket?.id}{" "}
                                          </Link>
                                        </td>

                                        <td
                                          key={index.id}
                                          className={
                                            index % 2 === 0 ? "" : "bgcolor"
                                          }
                                        >
                                          {" "}
                                          <Link
                                            to="/ticketdetail"
                                            state={{ data: item }}
                                            className="editbtn"
                                          >
                                            {item?.ticket?.customer?.firstName}{" "}
                                            {item?.ticket?.customer?.lastName}
                                          </Link>
                                        </td>
                                        <td
                                          key={index.id}
                                          className={
                                            index % 2 === 0 ? "" : "bgcolor"
                                          }
                                        >
                                          <Link
                                            to="/ticketdetail"
                                            state={{ data: item }}
                                            className="editbtn"
                                          >
                                            {item?.ticket?.customer?.email ||
                                              "--"}{" "}
                                            <br />
                                            {formatPhoneNumber(
                                              item?.ticket?.customer
                                                ?.phoneNumber
                                            ) || "--"}
                                          </Link>
                                        </td>
                                        <td
                                          key={index.id}
                                          className={
                                            index % 2 === 0 ? "" : "bgcolor"
                                          }
                                        >
                                          <Link
                                            to="/ticketdetail"
                                            state={{ data: item }}
                                            className="editbtn"
                                          >
                                            {
                                              item?.ticket?.customerDevice
                                                ?.model
                                            }
                                          </Link>
                                        </td>
                                        <td
                                          key={index.id}
                                          className={
                                            index % 2 === 0 ? "" : "bgcolor"
                                          }
                                        >
                                          <Link
                                            to="/ticketdetail"
                                            state={{ data: item }}
                                            className="editbtn"
                                          >
                                            {item?.ticket?.service1?.name
                                              ? item?.ticket?.service1?.name
                                              : item?.ticket?.service2?.name}
                                          </Link>
                                        </td>
                                        <td
                                          key={index.id}
                                          className={
                                            index % 2 === 0 ? "" : "bgcolor"
                                          }
                                        >
                                          <Link
                                            to="/ticketdetail"
                                            state={{ data: item }}
                                            className="editbtn"
                                          >
                                            {moment(
                                              item?.ticket?.timeZone,
                                              "YYYY/MM/DD"
                                            ).format("DD/MM/YYYY")}{" "}
                                            {moment(
                                              item?.ticket?.timeZone
                                            ).format("LT")}
                                            <br />
                                            Tech:
                                            {item?.techPerson?.name}
                                            {item?.techPerson?.userName}
                                            <br />
                                            Last Update:{" "}
                                            {moment(
                                              item?.ticket?.updatedTimeZone
                                            ).format("LT")}{" "}
                                            {moment(
                                              item?.ticket?.updatedTimeZone,
                                              "YYYY/MM/DD"
                                            ).format("DD/MM/YYYY")}
                                          </Link>
                                        </td>
                                        <td
                                          key={index.id}
                                          className={
                                            index % 2 === 0
                                              ? ""
                                              : "status_td st_green bgcolor"
                                          }
                                        >
                                          <div className="employeeroledropdown">
                                            <div className="dropdown">
                                              <button
                                                className="btn btn-secondary dropdown-toggle  customemployeetabledropdownsettting"
                                                type="button"
                                                id="dropdownMenuButton1"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                              >
                                                <span
                                                  className="customspanlistsatutus"
                                                  style={{
                                                    background:
                                                      item?.ticket?.statusOption
                                                        ?.color,
                                                  }}
                                                ></span>
                                                {item?.ticket?.statusOption
                                                  ?.name
                                                  ? item?.ticket?.statusOption
                                                      ?.name
                                                  : item?.ticket?.statusOption
                                                      ?.name}
                                              </button>
                                              <ul
                                                className="dropdown-menu"
                                                aria-labelledby="dropdownMenuButton1"
                                              >
                                                {allstatus.map((status) => (
                                                  <>
                                                    <li key={status?.id}>
                                                      <a
                                                        className="dropdown-item"
                                                        href="#"
                                                        onClick={() =>
                                                          handleStatusChange(
                                                            status,
                                                            item?.ticket
                                                          )
                                                        }
                                                      >
                                                        <span
                                                          className="customspanlistsatutus"
                                                          style={{
                                                            background:
                                                              status?.color,
                                                          }}
                                                        ></span>{" "}
                                                        {status?.name}{" "}
                                                      </a>
                                                    </li>
                                                  </>
                                                ))}
                                              </ul>
                                            </div>
                                          </div>
                                        </td>

                                        {/* <td
                              key={index.id}
                              className={
                                index % 2 === 0
                                  ? ""
                                  : "status_td st_green bgcolor"
                              }
                            >
                              <span className="statusfinishedcustm">
                                <span
                                  style={{
                                    content: "",
                                    width: " 7px",
                                    height: "7px",
                                    borderRadius: "50%",
                                    display: "inline - block",
                                    background:
                                      item?.ticket?.statusOption?.color,
                                  }}
                                ></span>
                                {item?.ticket?.statusOption?.name}
                              </span>
                            </td> */}

                                        <td
                                          key={index.id}
                                          className={
                                            index % 2 === 0 ? "" : "bgcolor"
                                          }
                                        >
                                          <Link
                                            to="/ticketdetail"
                                            state={{ data: item }}
                                            //className="editbtn"
                                          >
                                            <img src="assets/img/editicon.webp" />
                                          </Link>
                                          <i
                                            className="fa-solid fa-trash deletecustmtb"
                                            onClick={() =>
                                              HandleDelete(item?.ticket?.id)
                                            }
                                          ></i>
                                        </td>
                                      </tr>
                                    ))}
                                  </>
                                ) : (
                                  <>
                                    {" "}
                                    <tr id="noDataMessage">
                                      <td colSpan="10" className="nodataav">
                                        No data available
                                      </td>
                                    </tr>
                                  </>
                                )}
                              </tbody>
                            </table>
                            {/* <div className="rows-per-page">
                  Rows per page:
                  <select
                    value={itemsPerPage}
                    onChange={handleRowsPerPageChange}
                  >
                    {rowsPerPageOptions.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                </div> */}
                            {allTickets.length > 0 ? (
                              <div className="row">
                                <div className="col-md-12">
                                  <div className="custombottompaginationbox">
                                    <nav aria-label="Page navigation example">
                                      <ul className="pagination">
                                        <li
                                          className="page-item custompaginationli  paginationleftbtnbox"
                                          style={{ cursor: "pointer" }}
                                        >
                                          <button
                                            type="button"
                                            className="page-link"
                                            onClick={() =>
                                              handlePageClick(currentPage - 1)
                                            }
                                            disabled={currentPage === 1}
                                          >
                                            <img src="assets/img/previous.webp" />
                                          </button>
                                        </li>
                                        {pages.map((page, key) => {
                                          if (
                                            page === 1 ||
                                            page === totalPages ||
                                            (page >= startPage &&
                                              page <= endPage)
                                          ) {
                                            return (
                                              <li
                                                className="page-item custompaginationli"
                                                key={key}
                                              >
                                                <a
                                                  className={
                                                    currentPage === page
                                                      ? "page-link active customcountpagination"
                                                      : "page-link customcountpagination"
                                                  }
                                                  onClick={() =>
                                                    handlePageClick(page)
                                                  }
                                                  disabled={
                                                    currentPage === page
                                                  }
                                                  style={{ cursor: "pointer" }}
                                                >
                                                  {page}
                                                </a>
                                              </li>
                                            );
                                          } else if (
                                            page === startPage - 1 ||
                                            page === endPage + 1
                                          ) {
                                            return (
                                              <li
                                                className="page-item custompaginationli"
                                                key={key}
                                              >
                                                <a
                                                  className="page-link customcountpagination"
                                                  style={{ cursor: "pointer" }}
                                                >
                                                  ...
                                                </a>
                                              </li>
                                            );
                                          } else {
                                            return null;
                                          }
                                        })}

                                        {/* {Array.from(
                                          { length: totalPages },
                                          (_, index) => index + 1
                                        ).map((page, key) => (
                                          <li
                                            className="page-item custompaginationli"
                                            key={key}
                                          >
                                            <a
                                              key={key}
                                              className={
                                                currentPage === page
                                                  ? "page-link active customcountpagination"
                                                  : "page-link customcountpagination"
                                              }
                                              onClick={() =>
                                                handlePageClick(page)
                                              }
                                              disabled={currentPage === page}
                                              style={{ cursor: "pointer" }}
                                            >
                                              {page}
                                            </a>
                                          </li>
                                        ))} */}

                                        <li
                                          className="page-item custompaginationli paginationrightbtnbox"
                                          style={{ cursor: "pointer" }}
                                        >
                                          <button
                                            type="button"
                                            className="page-link"
                                            href="#"
                                            onClick={() =>
                                              handlePageClick(currentPage + 1)
                                            }
                                            disabled={
                                              currentPage === totalPages
                                            }
                                          >
                                            <img src="assets/img/next.webp" />
                                          </button>
                                        </li>
                                      </ul>
                                    </nav>
                                  </div>
                                </div>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <div
                  // className={`${isSidebarVisible ? "sidebar-mini" : ""} ${
                  //   !isFreeTrialVisible ? "no-free-trial" : ""
                  // }`}
                  style={sidebarStyleone}
                >
                  <nav
                    className="navbar navbar-expand-lg main-navbar sticky"
                    style={navbarStyle}
                  >
                    <div className="sidebar-arrow">
                      <a
                        href="#"
                        data-toggle="sidebar"
                        onClick={(e) => {
                          toggleSidebar();

                          e.preventDefault();
                        }}
                      >
                        <img src="assets/img/sidebar-toggle.webp" alt="" />
                      </a>
                    </div>
                    <div className="header-title mr-auto">
                      <h4>
                        Tickets{" "}
                        <span className="count">
                          {allTickets?.length} Tickets
                        </span>
                      </h4>
                    </div>
                    <SideUl />
                  </nav>
                  <MainSidebar />{" "}
                  <div
                    className="main-content tableback"
                    style={mainContentStyle}
                  >
                    <div className="common_filter_sec ticket_filter">
                      <div className="filter_col">
                        <div className="ticket_counter">
                          <div className="counter_col">
                            <p>
                              Active Services (Open):{" "}
                              <span>{totalArray ? totalArray : 0}</span>
                            </p>
                            {/* <span className="cus_badge">
                    <img src="assets/img/arrowup-icon.webp" alt="" /> 10%
                  </span> */}
                          </div>
                          <div className="counter_col">
                            <p>
                              New Repair (Today):{" "}
                              <span>{todaycount ? todaycount : 0}</span>
                            </p>
                            <span className="cus_badge">
                              <img src="assets/img/arrowup-icon.webp" alt="" />{" "}
                              {percentToday ? percentToday : 0}%
                            </span>
                          </div>
                          <div className="counter_col">
                            <p>
                              Finished:
                              <span>{finishedCount ? finishedCount : 0}</span>
                            </p>
                          </div>

                          <div className="ft_select_option status_option customdropdownulboxcate filterticketlistresponsive1 ">
                            <div className="dropdown repaircustomselectfilterstatus repairsfilterdropdowncustm1status">
                              <button
                                type="button"
                                className="btn btn-secondary dropdown-toggle customdropdowntablebtnsetting textaligncustmtext"
                                role="button"
                                id="dropdownMenuLink"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                {selectedCategory5 === "default"
                                  ? "Select Status"
                                  : selectedCategory5}
                              </button>
                              <ul
                                className="dropdown-menu customdropdownulboxsetting ulcustmrepairdropdowncss"
                                aria-labelledby="dropdownMenuLink"
                              >
                                <li>
                                  <a
                                    className="dropdown-item"
                                    href="#"
                                    onClick={() =>
                                      handleStatussChange("default")
                                    }
                                  >
                                    Select Status
                                  </a>
                                </li>
                                {allstatus.map((category) => (
                                  <li key={category.id}>
                                    <a
                                      className="dropdown-item"
                                      href="#"
                                      onClick={() =>
                                        handleStatussChange(category.name)
                                      }
                                    >
                                      {category.name}
                                    </a>
                                  </li>
                                ))}
                              </ul>
                              <img src="assets/img/select-arrow.webp" alt="" />
                            </div>
                          </div>
                          {/* <p>
                    Pending Pick up: <span>10</span>
                  </p> */}
                        </div>

                        <div
                          className="add_customer_btn"
                          onClick={() => setShowModal(true)}
                        >
                          <a
                            href="#"
                            data-toggle="modal"
                            data-target="#newserviceModal"
                          >
                            Create Ticket <i className="fa fa-plus"></i>
                          </a>
                        </div>
                      </div>
                      <div className="filter_col">
                        <div className="filter_tble_btn">
                          <div className="dropdown  filterticketlistresponsive ">
                            <button
                              type="button"
                              className="btn btn-secondary dropdown-toggle customdropdowntablebtnsetting"
                              href="#"
                              role="button"
                              id="dropdownMenuLink"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              {selectedFilter === "default"
                                ? "Filter Table"
                                : selectedFilter}
                            </button>
                            <img
                              src="assets/img/filter-icon.webp"
                              alt=""
                              className="filtericonimgboxsetting"
                            />
                            <ul
                              className="dropdown-menu customdropdownulboxsetting"
                              aria-labelledby="dropdownMenuLink"
                            >
                              <li>
                                <a
                                  className="dropdown-item"
                                  href="#"
                                  onClick={handleFilterChange1}
                                >
                                  Filter Table
                                </a>
                              </li>
                              <li>
                                <a
                                  className="dropdown-item"
                                  href="#"
                                  onClick={handleFilterChange1}
                                >
                                  Date
                                </a>
                              </li>
                              <li>
                                <a
                                  className="dropdown-item"
                                  href="#"
                                  onClick={handleFilterChange1}
                                >
                                  Ticket
                                </a>
                              </li>
                              <li>
                                <a
                                  className="dropdown-item"
                                  href="#"
                                  onClick={handleFilterChange1}
                                >
                                  Last UpDate
                                </a>
                              </li>
                              <li>
                                <a
                                  className="dropdown-item"
                                  href="#"
                                  onClick={handleFilterChange1}
                                >
                                  First Name
                                </a>
                              </li>
                              <li>
                                <a
                                  className="dropdown-item"
                                  href="#"
                                  onClick={handleFilterChange1}
                                >
                                  Last Name
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="search_field">
                          <div className="search_icon">
                            <img src="assets/img/search-icon.webp" alt="" />
                          </div>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search First Name/Surname/Email/Phone#"
                            value={searchTerm}
                            onChange={handleSearchTermChange}
                          />
                        </div>
                        <div className="ft_select_option status_option customdropdownulboxcate filterticketlistresponsive1 ">
                          <div className="dropdown repaircustomselectfilter repairsfilterdropdowncustm1">
                            <button
                              type="button"
                              className="btn btn-secondary dropdown-toggle customdropdowntablebtnsetting textaligncustmtext"
                              role="button"
                              id="dropdownMenuLink"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              {selectedCategory1 === "default"
                                ? "Assigned Tech"
                                : selectedCategory1}
                            </button>
                            <ul
                              className="dropdown-menu customdropdownulboxsetting ulcustmrepairdropdowncss"
                              aria-labelledby="dropdownMenuLink"
                            >
                              <li>
                                <a
                                  className="dropdown-item"
                                  href="#"
                                  onClick={() => handleTechChange("default")}
                                >
                                  Assigned Tech
                                </a>
                              </li>
                              {techPerson.map((category) => (
                                <li key={category?.id}>
                                  <a
                                    className="dropdown-item"
                                    href="#"
                                    onClick={() => handleTechChange(category)}
                                  >
                                    {category}
                                  </a>
                                </li>
                              ))}
                              {/* {allSubuser.map((category) => (
                                <li key={category?.id}>
                                  <a
                                    className="dropdown-item"
                                    href="#"
                                    onClick={() =>
                                      handleTechChange(category?.name)
                                    }
                                  >
                                    {category?.subUser?.name}
                                  </a>
                                </li>
                              ))} */}
                            </ul>
                            <img src="assets/img/select-arrow.webp" alt="" />
                          </div>
                        </div>
                        <div className="ft_select_option status_option customdropdownulboxcate filterticketlistresponsive2 ">
                          <div className="dropdown repaircustomselectfilter repairsfilterdropdowncustm2">
                            <button
                              type="button"
                              className="btn btn-secondary dropdown-toggle customdropdowntablebtnsetting textaligncustmtext"
                              role="button"
                              id="dropdownMenuLink"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              {selectedCategory2 === "default"
                                ? " Select Service"
                                : selectedCategory2}
                            </button>
                            <ul
                              className="dropdown-menu customdropdownulboxsetting ulcustmrepairdropdowncss"
                              aria-labelledby="dropdownMenuLink"
                            >
                              <li>
                                <a
                                  className="dropdown-item"
                                  href="#"
                                  onClick={() =>
                                    handleCategoryChange("default")
                                  }
                                >
                                  Select Service
                                </a>
                              </li>
                              {allService.map((category) => (
                                <li key={category.id}>
                                  <a
                                    className="dropdown-item"
                                    href="#"
                                    onClick={() =>
                                      handleCategoryChange(category.name)
                                    }
                                  >
                                    {category.name}
                                  </a>
                                </li>
                              ))}
                            </ul>
                            <img src="assets/img/select-arrow.webp" alt="" />
                          </div>
                        </div>
                        <div className="ft_select_option status_option customdropdownulboxcate filterticketlistresponsive3 ">
                          <div className="dropdown repaircustomselectfilter repairsfilterdropdowncustm3">
                            <button
                              type="button"
                              className="btn btn-secondary dropdown-toggle customdropdowntablebtnsetting textaligncustmtext"
                              role="button"
                              id="dropdownMenuLink"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              {selectedCategory3 === "default"
                                ? "Select Brand"
                                : selectedCategory3}
                            </button>
                            <ul
                              className="dropdown-menu customdropdownulboxsetting ulcustmrepairdropdowncss"
                              aria-labelledby="dropdownMenuLink"
                            >
                              <li>
                                <a
                                  className="dropdown-item"
                                  href="#"
                                  onClick={() => handleBrandChange("default")}
                                >
                                  Select Brand
                                </a>
                              </li>
                              {AllBrand1.map((category) => (
                                <li key={category.id}>
                                  <a
                                    className="dropdown-item"
                                    href="#"
                                    onClick={() =>
                                      handleBrandChange(category.name)
                                    }
                                  >
                                    {category.name}
                                  </a>
                                </li>
                              ))}
                            </ul>
                            <img src="assets/img/select-arrow.webp" alt="" />
                          </div>
                        </div>
                        {/* <div className="imp_exp_btn export_btn">
                <button type="button">
                  <img src="assets/img/export-icon.webp" alt="" /> Export
                </button>
              </div> */}
                        <div className="imp_exp_btn import_btn">
                          <button type="button" onClick={handleImport}>
                            <img src="assets/img/import-icon.webp" alt="" />{" "}
                            Import
                          </button>
                          <input
                            type="file"
                            id="fileInput"
                            style={{ display: "none" }}
                            onChange={handleFileChange}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="clearfix"></div>
                    <div className="row">
                      <div className="col-lg-12 col-md-12">
                        <div className="customertablemain">
                          <table className="table table-striped  customdatatable">
                            <thead className="customtheadpadding">
                              <tr style={{ cursor: "pointer" }}>
                                <th scope="col" className="checkbtnthsetting">
                                  <input
                                    className="form-check-input customcheckboxbtnsetting"
                                    type="checkbox"
                                    value=""
                                    id="flexCheckDefault"
                                    onChange={handleSelectAll}
                                    checked={selectAll}
                                  />
                                </th>
                                <th scope="col" className="customnameth">
                                  Ticket#
                                  {sortState === 0 ? (
                                    <span
                                      className="upperarrow"
                                      onClick={() => {
                                        sortTicket();
                                        setSortState(0);
                                      }}
                                    >
                                      <i
                                        className="fa fa-long-arrow-up active"
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  ) : (
                                    <span
                                      className="upperarrow"
                                      onClick={() => {
                                        sortTicket();
                                        setSortState(0);
                                      }}
                                    >
                                      <i
                                        className="fa fa-long-arrow-up"
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  )}
                                  {sortState === 1 ? (
                                    <span
                                      className="lowerarrow"
                                      onClick={() => {
                                        sortTicket1();
                                        setSortState(1);
                                      }}
                                    >
                                      <i
                                        className="fa fa-long-arrow-down active "
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  ) : (
                                    <span
                                      className="lowerarrow"
                                      onClick={() => {
                                        sortTicket1();
                                        setSortState(1);
                                      }}
                                    >
                                      <i
                                        className="fa fa-long-arrow-down "
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  )}
                                </th>
                                <th scope="col">Name</th>
                                <th scope="col">Email/Phone #</th>
                                <th scope="col" className="customnameth">
                                  Device
                                  {sortState === 2 ? (
                                    <span
                                      className="upperarrow"
                                      onClick={() => {
                                        sortDevice();
                                        setSortState(2);
                                      }}
                                    >
                                      <i
                                        className="fa fa-long-arrow-up active"
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  ) : (
                                    <span
                                      className="upperarrow"
                                      onClick={() => {
                                        sortDevice();
                                        setSortState(2);
                                      }}
                                    >
                                      <i
                                        className="fa fa-long-arrow-up"
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  )}
                                  {sortState === 3 ? (
                                    <span
                                      className="lowerarrow"
                                      onClick={() => {
                                        sortDevice1();
                                        setSortState(3);
                                      }}
                                    >
                                      <i
                                        className="fa fa-long-arrow-down active"
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  ) : (
                                    <span
                                      className="lowerarrow"
                                      onClick={() => {
                                        sortDevice1();
                                        setSortState(3);
                                      }}
                                    >
                                      <i
                                        className="fa fa-long-arrow-down "
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  )}
                                </th>
                                <th
                                  scope="col"
                                  className={`customnameth ${
                                    isCustomNameThActive
                                      ? "customnamethactive"
                                      : "customnameth"
                                  }`}
                                  //onClick={handleLocationSort}
                                >
                                  Service
                                  {sortState === 4 ? (
                                    <span
                                      className="upperarrow"
                                      onClick={() => {
                                        sortService();
                                        setSortState(4);
                                      }}
                                    >
                                      <i
                                        className="fa fa-long-arrow-up active"
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  ) : (
                                    <span
                                      className="upperarrow"
                                      onClick={() => {
                                        sortService();
                                        setSortState(4);
                                      }}
                                    >
                                      <i
                                        className="fa fa-long-arrow-up"
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  )}
                                  {sortState === 5 ? (
                                    <span
                                      className="lowerarrow"
                                      onClick={() => {
                                        sortService1();
                                        setSortState(5);
                                      }}
                                    >
                                      <i
                                        className="fa fa-long-arrow-down active"
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  ) : (
                                    <span
                                      className="lowerarrow"
                                      onClick={() => {
                                        sortService1();
                                        setSortState(5);
                                      }}
                                    >
                                      <i
                                        className="fa fa-long-arrow-down"
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  )}
                                </th>
                                <th
                                  scope="col"
                                  className={`customnameth ${
                                    isCustomNameThActive
                                      ? "customnamethactive"
                                      : "customnameth"
                                  }`}
                                >
                                  Date Created
                                  {sortState === 6 ? (
                                    <span
                                      className="upperarrow"
                                      onClick={() => {
                                        setSortState(6);
                                      }}
                                    >
                                      <i
                                        className="fa fa-long-arrow-up active"
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  ) : (
                                    <span
                                      className="upperarrow"
                                      onClick={() => {
                                        setSortState(6);
                                      }}
                                    >
                                      <i
                                        className="fa fa-long-arrow-up"
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  )}
                                  {sortState === 7 ? (
                                    <span
                                      className="lowerarrow"
                                      onClick={() => {
                                        setSortState(7);
                                      }}
                                    >
                                      <i
                                        className="fa fa-long-arrow-down active"
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  ) : (
                                    <span
                                      className="lowerarrow"
                                      onClick={() => {
                                        setSortState(7);
                                      }}
                                    >
                                      <i
                                        className="fa fa-long-arrow-down "
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  )}
                                </th>
                                <th
                                  scope="col"
                                  className={`customnameth ${
                                    isCustomNameThActive
                                      ? "customnamethactive"
                                      : "customnameth"
                                  }`}
                                  //onClick={handleStoreSort}
                                >
                                  Status
                                  {sortState === 8 ? (
                                    <span
                                      className="upperarrow storearrcust"
                                      onClick={() => {
                                        sortStatus();
                                        setSortState(8);
                                      }}
                                    >
                                      <i
                                        className="fa fa-long-arrow-up active"
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  ) : (
                                    <span
                                      className="upperarrow storearrcust"
                                      onClick={() => {
                                        sortStatus();
                                        setSortState(8);
                                      }}
                                    >
                                      <i
                                        className="fa fa-long-arrow-up "
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  )}
                                  {sortState === 9 ? (
                                    <span
                                      className="lowerarrow responsstore"
                                      onClick={() => {
                                        sortStatus1();
                                        setSortState(9);
                                      }}
                                    >
                                      <i
                                        className="fa fa-long-arrow-down active"
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  ) : (
                                    <span
                                      className="lowerarrow responsstore"
                                      onClick={() => {
                                        sortStatus1();
                                        setSortState(9);
                                      }}
                                    >
                                      <i
                                        className="fa fa-long-arrow-down"
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  )}
                                </th>

                                <th scope="col">Action</th>
                              </tr>
                            </thead>
                            <tbody className="customtheadpadding">
                              {currentData.length ? (
                                <>
                                  {currentData.map((item, index) => (
                                    <tr
                                      key={index.id}
                                      className={
                                        index % 2 === 0 ? "" : "customeven"
                                      }
                                    >
                                      <td
                                        key={index.id}
                                        className={
                                          index % 2 === 0 ? "" : "bgcolor"
                                        }
                                      >
                                        <Link
                                          to="/ticketdetail"
                                          state={{ data: item }}
                                          className="editbtn"
                                        >
                                          <input
                                            key={index.id}
                                            className="form-check-input customcheckboxbtnsetting"
                                            type="checkbox"
                                            value=""
                                            id={`flexCheckDefault${index}`}
                                            onChange={() =>
                                              handleSelectRow(item.ticket?.id)
                                            }
                                            checked={selectedItems.includes(
                                              item.ticket?.id
                                            )}
                                          />
                                        </Link>
                                      </td>

                                      <td
                                        key={index.id}
                                        className={
                                          index % 2 === 0 ? "" : "bgcolor"
                                        }
                                      >
                                        {" "}
                                        <Link
                                          to="/ticketdetail"
                                          state={{ data: item }}
                                          className="editbtn"
                                        >
                                          {" "}
                                          #{item?.ticket?.id}{" "}
                                        </Link>
                                      </td>

                                      <td
                                        key={index.id}
                                        className={
                                          index % 2 === 0 ? "" : "bgcolor"
                                        }
                                      >
                                        {" "}
                                        <Link
                                          to="/ticketdetail"
                                          state={{ data: item }}
                                          className="editbtn"
                                        >
                                          {item?.ticket?.customer?.firstName}{" "}
                                          {item?.ticket?.customer?.lastName}
                                        </Link>
                                      </td>
                                      <td
                                        key={index.id}
                                        className={
                                          index % 2 === 0 ? "" : "bgcolor"
                                        }
                                      >
                                        <Link
                                          to="/ticketdetail"
                                          state={{ data: item }}
                                          className="editbtn"
                                        >
                                          {item?.ticket?.customer?.email ||
                                            "--"}{" "}
                                          <br />
                                          {formatPhoneNumber(
                                            item?.ticket?.customer?.phoneNumber
                                          ) || "--"}
                                        </Link>
                                      </td>
                                      <td
                                        key={index.id}
                                        className={
                                          index % 2 === 0 ? "" : "bgcolor"
                                        }
                                      >
                                        <Link
                                          to="/ticketdetail"
                                          state={{ data: item }}
                                          className="editbtn"
                                        >
                                          {item?.ticket?.customerDevice?.model}
                                        </Link>
                                      </td>
                                      <td
                                        key={index.id}
                                        className={
                                          index % 2 === 0 ? "" : "bgcolor"
                                        }
                                      >
                                        <Link
                                          to="/ticketdetail"
                                          state={{ data: item }}
                                          className="editbtn"
                                        >
                                          {item?.ticket?.service1?.name
                                            ? item?.ticket?.service1?.name
                                            : item?.ticket?.service2?.name}
                                        </Link>
                                      </td>
                                      <td
                                        key={index.id}
                                        className={
                                          index % 2 === 0 ? "" : "bgcolor"
                                        }
                                      >
                                        <Link
                                          to="/ticketdetail"
                                          state={{ data: item }}
                                          className="editbtn"
                                        >
                                          {moment(
                                            item?.ticket?.timeZone,
                                            "YYYY/MM/DD"
                                          ).format("DD/MM/YYYY")}{" "}
                                          {moment(
                                            item?.ticket?.timeZone
                                          ).format("LT")}
                                          <br />
                                          Tech:
                                          {/* {item?.ticket?.techAssignedName} */}
                                          {item?.techPerson?.userName}
                                          <br />
                                          Last Update:{" "}
                                          {moment(
                                            item?.ticket?.updatedTimeZone
                                          ).format("LT")}{" "}
                                          {moment(
                                            item?.ticket?.updatedTimeZone,
                                            "YYYY/MM/DD"
                                          ).format("DD/MM/YYYY")}
                                        </Link>
                                      </td>
                                      <td
                                        key={index.id}
                                        className={
                                          index % 2 === 0
                                            ? ""
                                            : "status_td st_green bgcolor"
                                        }
                                      >
                                        <div className="employeeroledropdown">
                                          <div className="dropdown">
                                            <button
                                              className="btn btn-secondary dropdown-toggle  customemployeetabledropdownsettting"
                                              type="button"
                                              id="dropdownMenuButton1"
                                              data-bs-toggle="dropdown"
                                              aria-expanded="false"
                                            >
                                              <span
                                                className="customspanlistsatutus"
                                                style={{
                                                  background:
                                                    item?.ticket?.statusOption
                                                      ?.color,
                                                }}
                                              ></span>
                                              {item?.ticket?.statusOption?.name
                                                ? item?.ticket?.statusOption
                                                    ?.name
                                                : item?.ticket?.statusOption
                                                    ?.name}
                                            </button>
                                            <ul
                                              className="dropdown-menu"
                                              aria-labelledby="dropdownMenuButton1"
                                            >
                                              {allstatus.map((status) => (
                                                <>
                                                  <li key={status?.id}>
                                                    <a
                                                      className="dropdown-item"
                                                      href="#"
                                                      onClick={() =>
                                                        handleStatusChange(
                                                          status,
                                                          item?.ticket
                                                        )
                                                      }
                                                    >
                                                      <span
                                                        className="customspanlistsatutus"
                                                        style={{
                                                          background:
                                                            status?.color,
                                                        }}
                                                      ></span>{" "}
                                                      {status?.name}{" "}
                                                    </a>
                                                  </li>
                                                </>
                                              ))}
                                            </ul>
                                          </div>
                                        </div>
                                      </td>

                                      {/* <td
                              key={index.id}
                              className={
                                index % 2 === 0
                                  ? ""
                                  : "status_td st_green bgcolor"
                              }
                            >
                              <span className="statusfinishedcustm">
                                <span
                                  style={{
                                    content: "",
                                    width: " 7px",
                                    height: "7px",
                                    borderRadius: "50%",
                                    display: "inline - block",
                                    background:
                                      item?.ticket?.statusOption?.color,
                                  }}
                                ></span>
                                {item?.ticket?.statusOption?.name}
                              </span>
                            </td> */}

                                      <td
                                        key={index.id}
                                        className={
                                          index % 2 === 0 ? "" : "bgcolor"
                                        }
                                      >
                                        <Link
                                          to="/ticketdetail"
                                          state={{ data: item }}
                                          //className="editbtn"
                                        >
                                          <img src="assets/img/editicon.webp" />
                                        </Link>
                                        <i
                                          className="fa-solid fa-trash deletecustmtb"
                                          onClick={() =>
                                            HandleDelete(item?.ticket?.id)
                                          }
                                        ></i>
                                      </td>
                                    </tr>
                                  ))}
                                </>
                              ) : (
                                <>
                                  {" "}
                                  <tr id="noDataMessage">
                                    <td colSpan="10" className="nodataav">
                                      No data available
                                    </td>
                                  </tr>
                                </>
                              )}
                            </tbody>
                          </table>
                          {/* <div className="rows-per-page">
                  Rows per page:
                  <select
                    value={itemsPerPage}
                    onChange={handleRowsPerPageChange}
                  >
                    {rowsPerPageOptions.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                </div> */}
                          {allTickets.length > 0 ? (
                            <div className="row">
                              <div className="col-md-12">
                                <div className="custombottompaginationbox">
                                  <nav aria-label="Page navigation example">
                                    <ul className="pagination">
                                      <li
                                        className="page-item custompaginationli  paginationleftbtnbox"
                                        style={{ cursor: "pointer" }}
                                      >
                                        <button
                                          type="button"
                                          className="page-link"
                                          onClick={() =>
                                            handlePageClick(currentPage - 1)
                                          }
                                          disabled={currentPage === 1}
                                        >
                                          <img src="assets/img/previous.webp" />
                                        </button>
                                      </li>

                                      {pages.map((page, key) => {
                                        if (
                                          page === 1 ||
                                          page === totalPages ||
                                          (page >= startPage && page <= endPage)
                                        ) {
                                          return (
                                            <li
                                              className="page-item custompaginationli"
                                              key={key}
                                            >
                                              <a
                                                className={
                                                  currentPage === page
                                                    ? "page-link active customcountpagination"
                                                    : "page-link customcountpagination"
                                                }
                                                onClick={() =>
                                                  handlePageClick(page)
                                                }
                                                disabled={currentPage === page}
                                                style={{ cursor: "pointer" }}
                                              >
                                                {page}
                                              </a>
                                            </li>
                                          );
                                        } else if (
                                          page === startPage - 1 ||
                                          page === endPage + 1
                                        ) {
                                          return (
                                            <li
                                              className="page-item custompaginationli"
                                              key={key}
                                            >
                                              <a
                                                className="page-link customcountpagination"
                                                style={{ cursor: "pointer" }}
                                              >
                                                ...
                                              </a>
                                            </li>
                                          );
                                        } else {
                                          return null;
                                        }
                                      })}
                                      {/* 
                                      {Array.from(
                                        { length: totalPages },
                                        (_, index) => index + 1
                                      ).map((page, key) => (
                                        <li
                                          className="page-item custompaginationli"
                                          key={key}
                                        >
                                          <a
                                            key={key}
                                            className={
                                              currentPage === page
                                                ? "page-link active customcountpagination"
                                                : "page-link customcountpagination"
                                            }
                                            onClick={() =>
                                              handlePageClick(page)
                                            }
                                            disabled={currentPage === page}
                                            style={{ cursor: "pointer" }}
                                          >
                                            {page}
                                          </a>
                                        </li>
                                      ))} */}

                                      <li
                                        className="page-item custompaginationli paginationrightbtnbox"
                                        style={{ cursor: "pointer" }}
                                      >
                                        <button
                                          type="button"
                                          className="page-link"
                                          href="#"
                                          onClick={() =>
                                            handlePageClick(currentPage + 1)
                                          }
                                          disabled={currentPage === totalPages}
                                        >
                                          <img src="assets/img/next.webp" />
                                        </button>
                                      </li>
                                    </ul>
                                  </nav>
                                </div>
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          ) : (
            <div
              // className={`${isSidebarVisible ? "sidebar-mini" : ""} ${
              //   !isFreeTrialVisible ? "no-free-trial" : ""
              // }`}
              style={sidebarStyleone}
            >
              <nav
                className="navbar navbar-expand-lg main-navbar sticky"
                style={navbarStyle}
              >
                <div className="sidebar-arrow">
                  <a
                    href="#"
                    data-toggle="sidebar"
                    onClick={(e) => {
                      toggleSidebar();

                      e.preventDefault();
                    }}
                  >
                    <img src="assets/img/sidebar-toggle.webp" alt="" />
                  </a>
                </div>
                <div className="header-title mr-auto">
                  <h4>
                    Tickets{" "}
                    <span className="count">{allTickets?.length} Tickets</span>
                  </h4>
                </div>
                <SideUl />
              </nav>
              <MainSidebar />{" "}
              <div className="main-content tableback" style={mainContentStyle}>
                <div className="common_filter_sec ticket_filter">
                  <div className="filter_col">
                    <div className="ticket_counter">
                      <div className="counter_col">
                        <p>
                          Active Services (Open):{" "}
                          <span>{totalArray ? totalArray : 0}</span>
                        </p>
                        {/* <span className="cus_badge">
                    <img src="assets/img/arrowup-icon.webp" alt="" /> 10%
                  </span> */}
                      </div>
                      <div className="counter_col">
                        <p>
                          New Repair (Today):{" "}
                          <span>{todaycount ? todaycount : 0}</span>
                        </p>
                        <span className="cus_badge">
                          <img src="assets/img/arrowup-icon.webp" alt="" />{" "}
                          {percentToday ? percentToday : 0}%
                        </span>
                      </div>
                      <div className="counter_col">
                        <p>
                          Finished:
                          <span>{finishedCount ? finishedCount : 0}</span>
                        </p>
                      </div>

                      <div className="ft_select_option status_option customdropdownulboxcate filterticketlistresponsive1 ">
                        <div className="dropdown repaircustomselectfilterstatus repairsfilterdropdowncustm1status">
                          <button
                            type="button"
                            className="btn btn-secondary dropdown-toggle customdropdowntablebtnsetting textaligncustmtext"
                            role="button"
                            id="dropdownMenuLink"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            {selectedCategory5 === "default"
                              ? "Select Status"
                              : selectedCategory5}
                          </button>
                          <ul
                            className="dropdown-menu customdropdownulboxsetting ulcustmrepairdropdowncss"
                            aria-labelledby="dropdownMenuLink"
                          >
                            <li>
                              <a
                                className="dropdown-item"
                                href="#"
                                onClick={() => handleStatussChange("default")}
                              >
                                Select Status
                              </a>
                            </li>
                            {allstatus.map((category) => (
                              <li key={category.id}>
                                <a
                                  className="dropdown-item"
                                  href="#"
                                  onClick={() =>
                                    handleStatussChange(category.name)
                                  }
                                >
                                  {category.name}
                                </a>
                              </li>
                            ))}
                          </ul>
                          <img src="assets/img/select-arrow.webp" alt="" />
                        </div>
                      </div>
                      {/* <p>
                    Pending Pick up: <span>10</span>
                  </p> */}
                    </div>

                    <div
                      className="add_customer_btn"
                      onClick={() => setShowModal(true)}
                    >
                      <a
                        href="#"
                        data-toggle="modal"
                        data-target="#newserviceModal"
                      >
                        Create Ticket <i className="fa fa-plus"></i>
                      </a>
                    </div>
                  </div>
                  <div className="filter_col">
                    <div className="filter_tble_btn">
                      <div className="dropdown  filterticketlistresponsive ">
                        <button
                          type="button"
                          className="btn btn-secondary dropdown-toggle customdropdowntablebtnsetting"
                          href="#"
                          role="button"
                          id="dropdownMenuLink"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          {selectedFilter === "default"
                            ? "Filter Table"
                            : selectedFilter}
                        </button>
                        <img
                          src="assets/img/filter-icon.webp"
                          alt=""
                          className="filtericonimgboxsetting"
                        />
                        <ul
                          className="dropdown-menu customdropdownulboxsetting"
                          aria-labelledby="dropdownMenuLink"
                        >
                          <li>
                            <a
                              className="dropdown-item"
                              href="#"
                              onClick={handleFilterChange1}
                            >
                              Filter Table
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              href="#"
                              onClick={handleFilterChange1}
                            >
                              Date
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              href="#"
                              onClick={handleFilterChange1}
                            >
                              Ticket
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              href="#"
                              onClick={handleFilterChange1}
                            >
                              Last UpDate
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              href="#"
                              onClick={handleFilterChange1}
                            >
                              First Name
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              href="#"
                              onClick={handleFilterChange1}
                            >
                              Last Name
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="search_field">
                      <div className="search_icon">
                        <img src="assets/img/search-icon.webp" alt="" />
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search First Name/Surname/Email/Phone#"
                        value={searchTerm}
                        onChange={handleSearchTermChange}
                      />
                    </div>
                    <div className="ft_select_option status_option customdropdownulboxcate filterticketlistresponsive1 ">
                      <div className="dropdown repaircustomselectfilter repairsfilterdropdowncustm1">
                        <button
                          type="button"
                          className="btn btn-secondary dropdown-toggle customdropdowntablebtnsetting textaligncustmtext"
                          role="button"
                          id="dropdownMenuLink"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          {selectedCategory1 === "default"
                            ? "Assigned Tech"
                            : selectedCategory1}
                        </button>
                        <ul
                          className="dropdown-menu customdropdownulboxsetting ulcustmrepairdropdowncss"
                          aria-labelledby="dropdownMenuLink"
                        >
                          <li>
                            <a
                              className="dropdown-item"
                              href="#"
                              onClick={() => handleTechChange("default")}
                            >
                              Assigned Tech
                            </a>
                          </li>
                          {techPerson.map((category) => (
                            <li key={category?.id}>
                              <a
                                className="dropdown-item"
                                href="#"
                                onClick={() => handleTechChange(category)}
                              >
                                {category}
                              </a>
                            </li>
                          ))}
                          {/* {allSubuser.map((category) => (
                            <li key={category?.id}>
                              <a
                                className="dropdown-item"
                                href="#"
                                onClick={() => handleTechChange(category?.name)}
                              >
                                {category?.subUser?.name}
                              </a>
                            </li>
                          ))} */}
                        </ul>
                        <img src="assets/img/select-arrow.webp" alt="" />
                      </div>
                    </div>
                    <div className="ft_select_option status_option customdropdownulboxcate filterticketlistresponsive2 ">
                      <div className="dropdown repaircustomselectfilter repairsfilterdropdowncustm2">
                        <button
                          type="button"
                          className="btn btn-secondary dropdown-toggle customdropdowntablebtnsetting textaligncustmtext"
                          role="button"
                          id="dropdownMenuLink"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          {selectedCategory2 === "default"
                            ? " Select Service"
                            : selectedCategory2}
                        </button>
                        <ul
                          className="dropdown-menu customdropdownulboxsetting ulcustmrepairdropdowncss"
                          aria-labelledby="dropdownMenuLink"
                        >
                          <li>
                            <a
                              className="dropdown-item"
                              href="#"
                              onClick={() => handleCategoryChange("default")}
                            >
                              Select Service
                            </a>
                          </li>
                          {allService.map((category) => (
                            <li key={category.id}>
                              <a
                                className="dropdown-item"
                                href="#"
                                onClick={() =>
                                  handleCategoryChange(category.name)
                                }
                              >
                                {category.name}
                              </a>
                            </li>
                          ))}
                        </ul>
                        <img src="assets/img/select-arrow.webp" alt="" />
                      </div>
                    </div>
                    <div className="ft_select_option status_option customdropdownulboxcate filterticketlistresponsive3 ">
                      <div className="dropdown repaircustomselectfilter repairsfilterdropdowncustm3">
                        <button
                          type="button"
                          className="btn btn-secondary dropdown-toggle customdropdowntablebtnsetting textaligncustmtext"
                          role="button"
                          id="dropdownMenuLink"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          {selectedCategory3 === "default"
                            ? "Select Brand"
                            : selectedCategory3}
                        </button>
                        <ul
                          className="dropdown-menu customdropdownulboxsetting ulcustmrepairdropdowncss"
                          aria-labelledby="dropdownMenuLink"
                        >
                          <li>
                            <a
                              className="dropdown-item"
                              href="#"
                              onClick={() => handleBrandChange("default")}
                            >
                              Select Brand
                            </a>
                          </li>
                          {AllBrand1.map((category) => (
                            <li key={category.id}>
                              <a
                                className="dropdown-item"
                                href="#"
                                onClick={() => handleBrandChange(category.name)}
                              >
                                {category.name}
                              </a>
                            </li>
                          ))}
                        </ul>
                        <img src="assets/img/select-arrow.webp" alt="" />
                      </div>
                    </div>
                    {/* <div className="imp_exp_btn export_btn">
                <button type="button">
                  <img src="assets/img/export-icon.webp" alt="" /> Export
                </button>
              </div> */}
                    <div className="imp_exp_btn import_btn">
                      <button type="button" onClick={handleImport}>
                        <img src="assets/img/import-icon.webp" alt="" /> Import
                      </button>
                      <input
                        type="file"
                        id="fileInput"
                        style={{ display: "none" }}
                        onChange={handleFileChange}
                      />
                    </div>
                    {/* <div className="imp_exp_btn import_btn">
                      <button type="button">
                        <img src="assets/img/import-icon.webp" alt="" /> Import
                      </button>
                    </div> */}
                  </div>
                </div>

                <div className="clearfix"></div>
                <div className="row">
                  <div className="col-lg-12 col-md-12">
                    <div className="customertablemain">
                      <table className="table table-striped  customdatatable">
                        <thead className="customtheadpadding">
                          <tr style={{ cursor: "pointer" }}>
                            <th scope="col" className="checkbtnthsetting">
                              <input
                                className="form-check-input customcheckboxbtnsetting"
                                type="checkbox"
                                value=""
                                id="flexCheckDefault"
                                onChange={handleSelectAll}
                                checked={selectAll}
                              />
                            </th>
                            <th scope="col" className="customnameth">
                              Ticket#
                              {sortState === 0 ? (
                                <span
                                  className="upperarrow"
                                  onClick={() => {
                                    sortTicket();
                                    setSortState(0);
                                  }}
                                >
                                  <i
                                    className="fa fa-long-arrow-up active"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              ) : (
                                <span
                                  className="upperarrow"
                                  onClick={() => {
                                    sortTicket();
                                    setSortState(0);
                                  }}
                                >
                                  <i
                                    className="fa fa-long-arrow-up"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              )}
                              {sortState === 1 ? (
                                <span
                                  className="lowerarrow"
                                  onClick={() => {
                                    sortTicket1();
                                    setSortState(1);
                                  }}
                                >
                                  <i
                                    className="fa fa-long-arrow-down active "
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              ) : (
                                <span
                                  className="lowerarrow"
                                  onClick={() => {
                                    sortTicket1();
                                    setSortState(1);
                                  }}
                                >
                                  <i
                                    className="fa fa-long-arrow-down "
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              )}
                            </th>
                            <th scope="col">Name</th>
                            <th scope="col">Email/Phone #</th>
                            <th scope="col" className="customnameth">
                              Device
                              {sortState === 2 ? (
                                <span
                                  className="upperarrow"
                                  onClick={() => {
                                    sortDevice();
                                    setSortState(2);
                                  }}
                                >
                                  <i
                                    className="fa fa-long-arrow-up active"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              ) : (
                                <span
                                  className="upperarrow"
                                  onClick={() => {
                                    sortDevice();
                                    setSortState(2);
                                  }}
                                >
                                  <i
                                    className="fa fa-long-arrow-up"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              )}
                              {sortState === 3 ? (
                                <span
                                  className="lowerarrow"
                                  onClick={() => {
                                    sortDevice1();
                                    setSortState(3);
                                  }}
                                >
                                  <i
                                    className="fa fa-long-arrow-down active"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              ) : (
                                <span
                                  className="lowerarrow"
                                  onClick={() => {
                                    sortDevice1();
                                    setSortState(3);
                                  }}
                                >
                                  <i
                                    className="fa fa-long-arrow-down "
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              )}
                            </th>
                            <th
                              scope="col"
                              className={`customnameth ${
                                isCustomNameThActive
                                  ? "customnamethactive"
                                  : "customnameth"
                              }`}
                              //onClick={handleLocationSort}
                            >
                              Service
                              {sortState === 4 ? (
                                <span
                                  className="upperarrow"
                                  onClick={() => {
                                    sortService();
                                    setSortState(4);
                                  }}
                                >
                                  <i
                                    className="fa fa-long-arrow-up active"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              ) : (
                                <span
                                  className="upperarrow"
                                  onClick={() => {
                                    sortService();
                                    setSortState(4);
                                  }}
                                >
                                  <i
                                    className="fa fa-long-arrow-up"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              )}
                              {sortState === 5 ? (
                                <span
                                  className="lowerarrow"
                                  onClick={() => {
                                    sortService1();
                                    setSortState(5);
                                  }}
                                >
                                  <i
                                    className="fa fa-long-arrow-down active"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              ) : (
                                <span
                                  className="lowerarrow"
                                  onClick={() => {
                                    sortService1();
                                    setSortState(5);
                                  }}
                                >
                                  <i
                                    className="fa fa-long-arrow-down"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              )}
                            </th>
                            <th
                              scope="col"
                              className={`customnameth ${
                                isCustomNameThActive
                                  ? "customnamethactive"
                                  : "customnameth"
                              }`}
                            >
                              Date Created
                              {sortState === 6 ? (
                                <span
                                  className="upperarrow"
                                  onClick={() => {
                                    setSortState(6);
                                  }}
                                >
                                  <i
                                    className="fa fa-long-arrow-up active"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              ) : (
                                <span
                                  className="upperarrow"
                                  onClick={() => {
                                    setSortState(6);
                                  }}
                                >
                                  <i
                                    className="fa fa-long-arrow-up"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              )}
                              {sortState === 7 ? (
                                <span
                                  className="lowerarrow"
                                  onClick={() => {
                                    setSortState(7);
                                  }}
                                >
                                  <i
                                    className="fa fa-long-arrow-down active"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              ) : (
                                <span
                                  className="lowerarrow"
                                  onClick={() => {
                                    setSortState(7);
                                  }}
                                >
                                  <i
                                    className="fa fa-long-arrow-down "
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              )}
                            </th>
                            <th
                              scope="col"
                              className={`customnameth ${
                                isCustomNameThActive
                                  ? "customnamethactive"
                                  : "customnameth"
                              }`}
                              //onClick={handleStoreSort}
                            >
                              Status
                              {sortState === 8 ? (
                                <span
                                  className="upperarrow storearrcust"
                                  onClick={() => {
                                    sortStatus();
                                    setSortState(8);
                                  }}
                                >
                                  <i
                                    className="fa fa-long-arrow-up active"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              ) : (
                                <span
                                  className="upperarrow storearrcust"
                                  onClick={() => {
                                    sortStatus();
                                    setSortState(8);
                                  }}
                                >
                                  <i
                                    className="fa fa-long-arrow-up "
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              )}
                              {sortState === 9 ? (
                                <span
                                  className="lowerarrow responsstore"
                                  onClick={() => {
                                    sortStatus1();
                                    setSortState(9);
                                  }}
                                >
                                  <i
                                    className="fa fa-long-arrow-down active"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              ) : (
                                <span
                                  className="lowerarrow responsstore"
                                  onClick={() => {
                                    sortStatus1();
                                    setSortState(9);
                                  }}
                                >
                                  <i
                                    className="fa fa-long-arrow-down"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              )}
                            </th>

                            <th scope="col">Action</th>
                          </tr>
                        </thead>
                        <tbody className="customtheadpadding">
                          {currentData.length ? (
                            <>
                              {currentData.map((item, index) => (
                                <tr
                                  key={index.id}
                                  className={
                                    index % 2 === 0 ? "" : "customeven"
                                  }
                                >
                                  <td
                                    key={index.id}
                                    className={index % 2 === 0 ? "" : "bgcolor"}
                                  >
                                    <Link
                                      to="/ticketdetail"
                                      state={{ data: item }}
                                      className="editbtn"
                                    >
                                      <input
                                        key={index.id}
                                        className="form-check-input customcheckboxbtnsetting"
                                        type="checkbox"
                                        value=""
                                        id={`flexCheckDefault${index}`}
                                        onChange={() =>
                                          handleSelectRow(item.ticket?.id)
                                        }
                                        checked={selectedItems.includes(
                                          item.ticket?.id
                                        )}
                                      />
                                    </Link>
                                  </td>

                                  <td
                                    key={index.id}
                                    className={index % 2 === 0 ? "" : "bgcolor"}
                                  >
                                    {" "}
                                    <Link
                                      to="/ticketdetail"
                                      state={{ data: item }}
                                      className="editbtn"
                                    >
                                      {" "}
                                      #{item?.ticket?.id}{" "}
                                    </Link>
                                  </td>

                                  <td
                                    key={index.id}
                                    className={index % 2 === 0 ? "" : "bgcolor"}
                                  >
                                    {" "}
                                    <Link
                                      to="/ticketdetail"
                                      state={{ data: item }}
                                      className="editbtn"
                                    >
                                      {item?.ticket?.customer?.firstName}{" "}
                                      {item?.ticket?.customer?.lastName}
                                    </Link>
                                  </td>
                                  <td
                                    key={index.id}
                                    className={index % 2 === 0 ? "" : "bgcolor"}
                                  >
                                    <Link
                                      to="/ticketdetail"
                                      state={{ data: item }}
                                      className="editbtn"
                                    >
                                      {item?.ticket?.customer?.email || "--"}{" "}
                                      <br />
                                      {formatPhoneNumber(
                                        item?.ticket?.customer?.phoneNumber
                                      ) || "--"}
                                    </Link>
                                  </td>
                                  <td
                                    key={index.id}
                                    className={index % 2 === 0 ? "" : "bgcolor"}
                                  >
                                    <Link
                                      to="/ticketdetail"
                                      state={{ data: item }}
                                      className="editbtn"
                                    >
                                      {item?.ticket?.customerDevice?.model}
                                    </Link>
                                  </td>
                                  <td
                                    key={index.id}
                                    className={index % 2 === 0 ? "" : "bgcolor"}
                                  >
                                    <Link
                                      to="/ticketdetail"
                                      state={{ data: item }}
                                      className="editbtn"
                                    >
                                      {item?.ticket?.service1?.name
                                        ? item?.ticket?.service1?.name
                                        : item?.ticket?.service2?.name}
                                    </Link>
                                  </td>
                                  <td
                                    key={index.id}
                                    className={index % 2 === 0 ? "" : "bgcolor"}
                                  >
                                    <Link
                                      to="/ticketdetail"
                                      state={{ data: item }}
                                      className="editbtn"
                                    >
                                      {moment(
                                        item?.ticket?.timeZone,
                                        "YYYY/MM/DD"
                                      ).format("DD/MM/YYYY")}{" "}
                                      {moment(item?.ticket?.timeZone).format(
                                        "LT"
                                      )}
                                      <br />
                                      Tech:
                                      {/* {item?.ticket?.techAssignedName} */}
                                      {item?.techPerson?.userName}
                                      <br />
                                      Last Update:{" "}
                                      {moment(
                                        item?.ticket?.updatedTimeZone
                                      ).format("LT")}{" "}
                                      {moment(
                                        item?.ticket?.updatedTimeZone,
                                        "YYYY/MM/DD"
                                      ).format("DD/MM/YYYY")}
                                    </Link>
                                  </td>
                                  <td
                                    key={index.id}
                                    className={
                                      index % 2 === 0
                                        ? ""
                                        : "status_td st_green bgcolor"
                                    }
                                  >
                                    <div className="employeeroledropdown">
                                      <div className="dropdown">
                                        <button
                                          className="btn btn-secondary dropdown-toggle  customemployeetabledropdownsettting"
                                          type="button"
                                          id="dropdownMenuButton1"
                                          data-bs-toggle="dropdown"
                                          aria-expanded="false"
                                        >
                                          <span
                                            className="customspanlistsatutus"
                                            style={{
                                              background:
                                                item?.ticket?.statusOption
                                                  ?.color,
                                            }}
                                          ></span>
                                          {item?.ticket?.statusOption?.name
                                            ? item?.ticket?.statusOption?.name
                                            : item?.ticket?.statusOption?.name}
                                        </button>
                                        <ul
                                          className="dropdown-menu"
                                          aria-labelledby="dropdownMenuButton1"
                                        >
                                          {allstatus.map((status) => (
                                            <>
                                              <li key={status?.id}>
                                                <a
                                                  className="dropdown-item"
                                                  href="#"
                                                  onClick={() =>
                                                    handleStatusChange(
                                                      status,
                                                      item?.ticket
                                                    )
                                                  }
                                                >
                                                  <span
                                                    className="customspanlistsatutus"
                                                    style={{
                                                      background: status?.color,
                                                    }}
                                                  ></span>{" "}
                                                  {status?.name}{" "}
                                                </a>
                                              </li>
                                            </>
                                          ))}
                                        </ul>
                                      </div>
                                    </div>
                                  </td>

                                  {/* <td
                              key={index.id}
                              className={
                                index % 2 === 0
                                  ? ""
                                  : "status_td st_green bgcolor"
                              }
                            >
                              <span className="statusfinishedcustm">
                                <span
                                  style={{
                                    content: "",
                                    width: " 7px",
                                    height: "7px",
                                    borderRadius: "50%",
                                    display: "inline - block",
                                    background:
                                      item?.ticket?.statusOption?.color,
                                  }}
                                ></span>
                                {item?.ticket?.statusOption?.name}
                              </span>
                            </td> */}

                                  <td
                                    key={index.id}
                                    className={index % 2 === 0 ? "" : "bgcolor"}
                                  >
                                    <Link
                                      to="/ticketdetail"
                                      state={{ data: item }}
                                      //className="editbtn"
                                    >
                                      <img src="assets/img/editicon.webp" />
                                    </Link>
                                    <i
                                      className="fa-solid fa-trash deletecustmtb"
                                      onClick={() =>
                                        HandleDelete(item?.ticket?.id)
                                      }
                                    ></i>
                                  </td>
                                </tr>
                              ))}
                            </>
                          ) : (
                            <>
                              {" "}
                              <tr id="noDataMessage">
                                <td colSpan="10" className="nodataav">
                                  No data available
                                </td>
                              </tr>
                            </>
                          )}
                        </tbody>
                      </table>
                      {/* <div className="rows-per-page">
                  Rows per page:
                  <select
                    value={itemsPerPage}
                    onChange={handleRowsPerPageChange}
                  >
                    {rowsPerPageOptions.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                </div> */}
                      {allTickets.length > 0 ? (
                        <div className="row">
                          <div className="col-md-12">
                            <div className="custombottompaginationbox">
                              <nav aria-label="Page navigation example">
                                <ul className="pagination">
                                  <li
                                    className="page-item custompaginationli  paginationleftbtnbox"
                                    style={{ cursor: "pointer" }}
                                  >
                                    <button
                                      type="button"
                                      className="page-link"
                                      onClick={() =>
                                        handlePageClick(currentPage - 1)
                                      }
                                      disabled={currentPage === 1}
                                    >
                                      <img src="assets/img/previous.webp" />
                                    </button>
                                  </li>
                                  {pages.map((page, key) => {
                                    if (
                                      page === 1 ||
                                      page === totalPages ||
                                      (page >= startPage && page <= endPage)
                                    ) {
                                      return (
                                        <li
                                          className="page-item custompaginationli"
                                          key={key}
                                        >
                                          <a
                                            className={
                                              currentPage === page
                                                ? "page-link active customcountpagination"
                                                : "page-link customcountpagination"
                                            }
                                            onClick={() =>
                                              handlePageClick(page)
                                            }
                                            disabled={currentPage === page}
                                            style={{ cursor: "pointer" }}
                                          >
                                            {page}
                                          </a>
                                        </li>
                                      );
                                    } else if (
                                      page === startPage - 1 ||
                                      page === endPage + 1
                                    ) {
                                      return (
                                        <li
                                          className="page-item custompaginationli"
                                          key={key}
                                        >
                                          <a
                                            className="page-link customcountpagination"
                                            style={{ cursor: "pointer" }}
                                          >
                                            ...
                                          </a>
                                        </li>
                                      );
                                    } else {
                                      return null;
                                    }
                                  })}
                                  {/* {Array.from(
                                    { length: totalPages },
                                    (_, index) => index + 1
                                  ).map((page, key) => (
                                    <li
                                      className="page-item custompaginationli"
                                      key={key}
                                    >
                                      <a
                                        key={key}
                                        className={
                                          currentPage === page
                                            ? "page-link active customcountpagination"
                                            : "page-link customcountpagination"
                                        }
                                        onClick={() => handlePageClick(page)}
                                        disabled={currentPage === page}
                                        style={{ cursor: "pointer" }}
                                      >
                                        {page}
                                      </a>
                                    </li>
                                  ))} */}

                                  <li
                                    className="page-item custompaginationli paginationrightbtnbox"
                                    style={{ cursor: "pointer" }}
                                  >
                                    <button
                                      type="button"
                                      className="page-link"
                                      href="#"
                                      onClick={() =>
                                        handlePageClick(currentPage + 1)
                                      }
                                      disabled={currentPage === totalPages}
                                    >
                                      <img src="assets/img/next.webp" />
                                    </button>
                                  </li>
                                </ul>
                              </nav>
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {/* <MainSidebar /> */}
          {/* <!-- Main Content --> */}

          <Footer />
        </div>
        {showModal === true ? (
          <div
            className="modal common_modal newservice_modal"
            id="newserviceModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="newserviceModalLabel"
            aria-hidden="true"
          >
            <TicketModalList
              onpress={onpress}
              onload={GetAlltickets}
              status={statusId}
            />
          </div>
        ) : null}
      </div>
    </>
  );
};
export default TicketList;
