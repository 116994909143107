import logo from "./logo.svg";
// import "./App.css";
import React, { useEffect, useState } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  useNavigate,
  Navigate,
} from "react-router-dom";
import Home from "./Home";
import Register from "./Register";
import Login from "./Login";
import VerifyEmail from "./VerifyEmail";
import Dashboard from "./MainDashboard/Dashboard";
import SubDomainLogin from "./SubDomainLogin";
import Settings from "./MainDashboard/Settings";
import ForgotPass from "./Components/ForgotPass";
import OtpVerify from "./Components/OtpVerify";
import UpdatePassword from "./Components/UpdatePassword";
import CashRegister from "./CashRegister";
import SaleDashboard from "./MainDashboard/SaleDashboar";
import TicketList from "./MainDashboard/TicketList";
import CustomerList from "./MainDashboard/CustomerList";
import ProductList from "./MainDashboard/ProductList";
import DeviceList from "./MainDashboard/DeviceList";
import InvoiceList from "./MainDashboard/InvoiceList";
import Protected from "./MainDashboard/Protected";
import CustomerDetail from "./CustomerDetail";
import EditProduct from "./EditProduct";
import PurchaseOrderList from "./MainDashboard/PurchaseOrderList";
import PurchaseOrder from "./MainDashboard/PurchaseOrder";
import PurchaseOrderEdit from "./MainDashboard/PurchaseOrderEdit";
import EditDevice from "./EditDevice";
import Success from "./MainDashboard/Success";
import Cancel from "./MainDashboard/Cancel";
import UserBlank from "./MainDashboard/UserBlank";
import TicketDetail from "./TicketDetail";
import TicketCustomerDetail from "./TicketDetail/TicketCustomerDetail";
import Refund from "./MainDashboard/Refund";
import EditInvoice from "./MainDashboard/EditInvoice";
import VoidSale from "./MainDashboard/VoidSale";
import SuccesLink from "./MainDashboard/SuccesLink";
import ResetPass from "./MainDashboard/ResetPass";
import OfflinePopup from "./MainDashboard/OfflinePopup";
import NotFound from "./MainDashboard/NotFound";
import ProtectedH from "./MainDashboard/ProtectedH";
import Reports from "./MainDashboard/Reports";
import SalesDate from "./MainDashboard/SalesDate";
import SalesPerson from "./MainDashboard/SalesPerson";
// import Timer from "./MainDashboard/Timer";
import Swal from "sweetalert2";

function App() {
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  // const navigate = useNavigate();
  const [isSessionExpired, setIsSessionExpired] = useState(false);
  const [inactivityTime, setInactivityTime] = useState(Date.now());

  //const logoutTime = 15 * 60 * 1000; // 10 minutes in milliseconds
  const logoutTime = 4 * 60 * 60 * 1000; // 4 hours in milliseconds

  useEffect(() => {
    // Handle online/offline status
    const handleOnlineStatus = () => {
      setIsOnline(navigator.onLine);
    };

    window.addEventListener("online", handleOnlineStatus);
    window.addEventListener("offline", handleOnlineStatus);

    // Handle inactivity logic
    let logoutTimer;

    const resetTimer = () => {
      clearTimeout(logoutTimer); // Clear previous timer
      logoutTimer = setTimeout(() => {
        handleLogout();

        // Logout after 10 minutes of inactivity
      }, logoutTime);
    };

    const handleLogout = () => {
      Swal.fire(
        "Your session has expired due to inactivity. Please log in again to continue."
      );
      // Clear session data or token (as per your app's logic)
      localStorage.clear(); // Example: remove
      window.location.href = "https://app.toolboxpos.com";

      // navigate("/login"); // Redirect to login page
    };

    const events = ["mousemove", "mousedown", "click", "keydown", "touchstart"];

    // Attach events to track user activity
    events.forEach((event) => window.addEventListener(event, resetTimer));

    // Set the initial timer
    resetTimer();

    // Cleanup function
    return () => {
      window.removeEventListener("online", handleOnlineStatus);
      window.removeEventListener("offline", handleOnlineStatus);
      clearTimeout(logoutTimer);
      events.forEach((event) => window.removeEventListener(event, resetTimer));
    };
  }, []);

  return (
    <>
      {!isOnline ? (
        <>
          <OfflinePopup isVisible={!isOnline} />
        </>
      ) : (
        <>
          <BrowserRouter>
            <Routes>
              {/* <Route path="/" element={<Home />} /> */}
              {/* <Route path="/" element={<ProtectedH Cmps={Home} />} /> */}
              <Route path="/" element={<Navigate to="/Login" replace />} />
              <Route path="/Login" element={<SubDomainLogin />} />
              <Route path="/Account" element={<Login />} />
              <Route path="*" element={<NotFound />} />

              <Route path="/succeslink" element={<SuccesLink />} />

              <Route path="/Signup" element={<Register />} />

              <Route path="/VerifyEmail" element={<VerifyEmail />} />
              {/* <Route path="/editproduct" element={<EditProduct />} /> */}
              <Route
                path="/editproduct"
                element={<Protected Cmp={EditProduct} />}
              />

              {/* <Route path="/editdevice" element={<EditDevice />} /> */}
              <Route
                path="/editdevice"
                element={<Protected Cmp={EditDevice} />}
              />

              <Route path="/reports" element={<Reports />} />
              <Route path="/saledate" element={<SalesDate />} />
              <Route path="/saleperson" element={<SalesPerson />} />

              <Route path="/success" element={<Success />} />
              <Route path="/cancel" element={<Cancel />} />
              <Route path="/userblank" element={<UserBlank />} />
              <Route path="/refund" element={<Refund />} />
              <Route path="/editinvoice" element={<EditInvoice />} />
              <Route path="/voidsale" element={<VoidSale />} />
              <Route path="/resetpass" element={<ResetPass />} />

              {/* <Route path="/ticketdetail" element={<TicketDetail />} /> */}
              <Route
                path="/ticketdetail"
                element={<Protected Cmp={TicketDetail} />}
              />

              <Route
                path="/ticketcustomerdetail"
                element={<TicketCustomerDetail />}
              />

              {/* <Route path="/Dashboard" element={<Dashboard />} /> */}
              <Route
                path="/dashboard"
                element={<Protected Cmp={Dashboard} />}
              />
              {/* <Route
          path="/Login"
          element={<Protected Cmp={SubDomainLogin} />}
        /> */}

              {/* <Route path="/purchaseorder" element={<PurchaseOrder />} /> */}
              <Route
                path="/purchaseorder"
                element={<Protected Cmp={PurchaseOrder} />}
              />

              {/* <Route path="/purchaseorderedit" element={<PurchaseOrderEdit />} /> */}
              <Route
                path="/purchaseorderedit"
                element={<Protected Cmp={PurchaseOrderEdit} />}
              />

              <Route path="/settings" element={<Settings />} />
              {/* <Route path="/timer" element={<Timer />} /> */}

              <Route path="/forgotpass" element={<ForgotPass />} />
              <Route path="/otpverify" element={<OtpVerify />} />
              <Route path="/updatepassword" element={<UpdatePassword />} />

              <Route
                path="/cash-register"
                element={<Protected Cmp={CashRegister} />}
              />
              {/* <Route path="/saledashboard" element={<SaleDashboard />} /> */}
              <Route
                path="/saledashboard"
                element={<Protected Cmp={SaleDashboard} />}
              />

              {/* <Route path="/ticketlist" element={<TicketList />} /> */}
              <Route
                path="/ticketlist"
                element={<Protected Cmp={TicketList} />}
              />
              <Route
                path="/purchaseorderlist"
                element={<Protected Cmp={PurchaseOrderList} />}
              />
              {/* <Route path="/purchaseorderlist" element={<PurchaseOrderList />} /> */}
              <Route
                path="/customerList"
                element={<Protected Cmp={CustomerList} />}
              />
              {/* <Route path="/productlist" element={<ProductList />} /> */}
              <Route
                path="/productlist"
                element={<Protected Cmp={ProductList} />}
              />

              {/* <Route path="/devicelist" element={<DeviceList />} /> */}
              <Route
                path="/devicelist"
                element={<Protected Cmp={DeviceList} />}
              />

              {/* <Route path="/invoicelist" element={<InvoiceList />} /> */}
              <Route
                path="/invoicelist"
                element={<Protected Cmp={InvoiceList} />}
              />

              {/* <Route path="/CustomerDetail" element={<CustomerDetail />} /> */}
              <Route
                path="/CustomerDetail"
                element={<Protected Cmp={CustomerDetail} />}
              />
            </Routes>
          </BrowserRouter>
        </>
      )}
    </>
  );
}

export default App;
