import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import SideUl from "./SideUl";
import MainSidebar from "./MainSidebar";
import {
  baseurl,
  customerAddApi,
  customerGetApi,
  DeleteSelectedCustomerApi,
  ImportApi,
  uploadImageApi,
} from "../Utility/Api";
import Footer from "../Footer";
import { Link } from "react-router-dom";
import { saveAs } from "file-saver";
import { CSVLink } from "react-csv";
import Swal from "sweetalert2";
import "react-phone-number-input/style.css";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import FreeTrail from "./FreeTrail";

const CustomerList = () => {
  const [data, setData] = useState([]);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [images, setImages] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [country, setCountry] = useState("");
  const [city, SetCity] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [state, setState] = useState("");
  const [error, setError] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [filter, setFilter] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [rowsPerPageOptions, setRowsPerPageOptions] = useState([
    10, 25, 50, 100, 500,
  ]);
  const [isCustomNameThActive, setIsCustomNameThActive] = useState(false);
  const [isSortAsc, setIsSortAsc] = useState(true);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("Filter Table");
  const [validstate, setValidState] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [totalPages1, setTotalPages] = useState(1);
  const [imageMsg, setImageMsg] = useState("");
  const [pageSize, setPageSize] = useState(10);
  const [sortState, setSortState] = useState(null);
  const [countrycode, setCountryCode] = useState({});
  const [adminState, setAdminState] = useState("");
  const [permission, setPermission] = useState([]);
  const [dataTotal, setDataTotal] = useState("");
  const [csvFile, setCsvFile] = useState("");
  const [selectedCustomerIds, setSelectedCustomerIds] = useState([]);
  const handleSelectRow = (id) => {
    const newSelectedItems = selectedItems.includes(id)
      ? selectedItems.filter((itemId) => itemId !== id)
      : [...selectedItems, id];

    setSelectedItems(newSelectedItems);
  };

  // Handle select all
  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    const newSelectedItems = selectAll
      ? []
      : currentData.map((item) => item.id);
    setSelectedItems(newSelectedItems);
  };

  // const handleSelectAll = () => {
  //   setSelectAll(!selectAll);
  //   // Toggle selectAll state
  //   const newSelectedItems = selectAll
  //     ? []
  //     : currentData.map((item) => item.id);
  //   setSelectedItems(newSelectedItems);
  // };

  // const handleSelectRow = (id) => {
  //   // Toggle selected state for a specific item
  //   const newSelectedItems = selectedItems.includes(id)
  //     ? selectedItems.filter((itemId) => itemId !== id)
  //     : [...selectedItems, id];

  //   setSelectedItems(newSelectedItems);
  // };

  const handleSearchTermChange = (e) => {
    setSearchTerm(e.target.value);
    GetCustomer(currentPage, itemsPerPage, e.target.value);
  };

  const handlePageClick = (newPage) => {
    setCurrentPage(newPage);
    GetCustomer(newPage, itemsPerPage, "");
  };
  const handleRowsPerPageChange = (e) => {
    setItemsPerPage(parseInt(e.target.value, 10));
    setCurrentPage(1); // Reset to the first page

    GetCustomer(1, parseInt(e.target.value, 10), "");
  };
  const filteredRowsPerPageOptions = rowsPerPageOptions.filter(
    (option) => option <= dataTotal || option === 10
  );

  useEffect(() => {
    let localAdmin = localStorage.getItem("admin");
    let localPerminssion = JSON.parse(localStorage.getItem("permission"));

    setAdminState(localAdmin);
    setPermission(localPerminssion);
    GetCustomer(currentPage, itemsPerPage, "");
    // fetch(
    //   "https://ip-api.io/api/json?api_key=fabc32c1-943d-43c7-8a13-ea82cd6ac052"
    // )
    fetch(
      "https://ip-api.io/api/json?api_key=fabc32c1-943d-43c7-8a13-ea82cd6ac052"
    )
      .then((response) => response.json())
      .then((json) => {
        setCountryCode(json);
      });
  }, []);

  const GetCustomer = (page, itemsPerPage, t) => {
    setLoading(true);
    let local = localStorage.getItem("token");

    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    // console.log(
    //   "urll",
    //   `https://api.toolboxpos.com/getAllCustomer?pageSize=${itemsPerPage}&page=${page}&keyword=${t}`
    // );

    fetch(
      `${baseurl}getAllCustomer?pageSize=${itemsPerPage}&page=${page}&keyword=${t}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        //console.log("customer", result);
        if (result.status === true) {
          setData(result?.data.reverse());
          setTotalPages(result?.totalPages);
          setDataTotal(result?.totalCount);
          // const totalPages = Math.ceil(result?.data.length / itemsPerPage);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log("error", error);
        setLoading(false);
      });
  };

  const isDisposableEmail = (email) => {
    const domain = email.split("@")[1];
    return disposableEmailDomains.includes(domain);
  };

  const disposableEmailDomains = [
    "gmail.com",
    "yahoo.com",
    "outlook.com",
    "hotmail.com",
    "aol.com",
    "icloud.com",
    "mail.com",
    "yandex.com",
    "protonmail.com",
    "zoho.com",
    "gmx.com",
    "ymail.com",
    "comcast.net",
    "me.com",
    "msn.com",
    "live.com",
    "sbcglobal.net",
    "verizon.net",
    "att.net",
    "cox.net",
    "smartitventures.com",
    // Add more domains as needed
  ];

  const Validation = () => {
    let error = {};
    let reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+*!=])(?=.*\d).{6,}$/;
    // const isValid = /^[0-9]+$/.test(value);
    const regex = /^[A-Za-z ]+$/;

    const isNumeric = /^[0-9]+$/;
    // if (!email) {
    //   error.email = "Email is required.";
    // } else if (!reg.test(email)) {
    //   error.email = "Invalid email .";
    // }
    if (email && !reg.test(email)) {
      error.email = "Invalid email.";
    } else if (email && !isDisposableEmail(email)) {
      error.email =
        "Please use a valid email address. Temporary email addresses are not allowed.";
    }
    if (!firstName) {
      error.firstName = "First Name Is Required";
    } else if (!regex.test(firstName)) {
      error.firstName = "First Name Is Invalid";
    }
    // if (!phoneNo) {
    //   error.phoneNo = "Phone Number Is Required";
    // }

    if (Object.keys(error).length === 0) {
      // console.log(Object.keys(error).length);
      CustomerApi();
    }
    return error;
  };

  const CustomerApi = () => {
    document
      .querySelectorAll(".modal-backdrop")
      .forEach((el) => el.classList.remove("modal-backdrop"));
    let local = localStorage.getItem("token");
    let userid = localStorage.getItem("userId");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);
    myHeaders.append(
      "Cookie",
      "connect.sid=s%3AyaHFql-YNXNgdQlka4nRvpWL-iJLSdB-.%2B6wW6d812YXYU4ihAgIwRTFu54mu6VtFKEcdK50W57A"
    );

    var raw = JSON.stringify({
      firstName: firstName,
      lastName: lastName,
      email: email,
      phoneNumber: phoneNo, // phoneNo ? phoneNo.replace(/^\+/, "") : "",
      profileImage: images,
      country: country,
      countryCode: String(countrycode.country_code),
      streetAddress: address,
      state: state,
      zipCode: zipcode,
      city: city,
      mainUserId: userid,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(customerAddApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true) {
          setShowModal(false);
          GetCustomer(currentPage, itemsPerPage, "");
          setMessage(result?.message);
          setFirstName("");
          setLastName("");
          setImages("");
          setAddress("");
          setPhoneNo("");
          setCountry("");
          SetCity("");
          setZipcode("");
          setState("");
          setEmail("");
          setMessage("");
          setValidState(false);
        } else {
          setMessage(result?.message);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const SubmitCus = () => {
    setError(Validation());
  };

  const handleImages = (e) => {
    var file = e.target.files[0];

    if (file) {
      const minSizeInBytes = 5000; // 5 KB
      const maxSizeInBytes = 1024 * 1024; // 1 MB

      if (file.size < minSizeInBytes) {
        setImageMsg("Image size is below the minimum allowed size (5 KB)");
        e.target.value = null;
        return;
      }

      let size = file.type.replace("/", ".");
      var blob = file.slice(0, file.size, file.type);
      const newName = new Date().getTime() + size;
      var newFile = new File([blob], newName, { type: file.type });

      UploadImage(newFile);
    }
  };

  const UploadImage = async (newFile) => {
    setLoading(true);
    var formdata = new FormData();
    formdata.append("file", newFile);
    formdata.append("type", "customers-images");

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(uploadImageApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === true) {
          setImages(result?.data);
          setLoading(false);
        } else {
          Swal.fire(result?.message);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log("error", error);
        setLoading(false);
      });
  };
  const handleModalClose = () => {
    setShowModal(false);
  };

  const handlePhoneChange = (value) => {
    setPhoneNo(value);
    const countryInfo = value && value.match(/^\+(\d+)/);
    const countryCode = countryInfo ? `+${countryInfo[1]}` : "";
  };

  const HandleDelete = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete image",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#c76cff",
      cancelButtonColor: "#efefef",
      confirmButtonText: "Yes, delete it!",
      confirmButtonText: "<span style='color: white;'>Yes, delete it!</span>",
      cancelButtonText: "<span style='color: black;'>cancel!</span>",
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      customClass: {
        container: "sweet-alert2-container-zindex",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Deleted!",
          text: "Your file has been deleted.",
          icon: "success",
          confirmButtonColor: "#c76cff",
          customClass: {
            container: "sweet-alert2-container-zindex",
          },
        }).then(() => {
          setImages("");
        });
      }
    });
  };
  const handleFilterChange1 = (event) => {
    const selectedValue = event.currentTarget.textContent.trim();
    if (selectedValue === "First Name A-Z") {
      const sortedData = [...data].sort((a, b) =>
        a?.firstName.localeCompare(b?.firstName)
      );
      setData(sortedData);
      setSelectedFilter("First Name A-Z");
    } else if (selectedValue === "Last Name A-Z") {
      const sortedData = [...data].sort((a, b) =>
        a?.lastName.localeCompare(b?.lastName)
      );
      setData(sortedData);
      setSelectedFilter("Last Name A-Z");
    } else {
      setSelectedFilter("Filter Table");
    }
  };
  const headers = [
    { label: "Name", key: "firstName" },
    { label: "Email", key: "email" },
    { label: "Phone", key: "phoneNumber" },
    { label: "Company", key: "company" },
    { label: "Location", key: "country" },
    { label: "--", key: "--" },
    { label: "--", key: "--" },
    { label: "--", key: "--" },
  ];
  const preprocessData = (data) => {
    return data.map((item) => ({
      firstName: item?.firstName || "--",
      email: item?.email || "--",
      phoneNumber: item?.phoneNumber || "--",
      company: item?.company || "--",
      country: item?.country || "--",
    }));
  };
  const data1 = preprocessData(data);
  const filteredData = data?.filter((item) => {
    const lowerCaseSearchTerm = searchTerm.toLowerCase();

    return (
      item?.firstName.toLowerCase().includes(lowerCaseSearchTerm) ||
      item?.email.toLowerCase().includes(lowerCaseSearchTerm) ||
      item?.lastName.toLowerCase().includes(lowerCaseSearchTerm) ||
      item?.phoneNumber.toLowerCase().includes(lowerCaseSearchTerm)
    );
  });
  // const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentData = filteredData;

  const cancleValues = () => {
    setImages("");
    setEmail("");
    setError("");
    setFirstName("");
    setLastName("");
    setAddress("");
    setState("");
    setCountry("");
    SetCity("");
    setPhoneNo("");
    setZipcode("");
    setValidState(false);
    setMessage("");
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const UpdateCustomer = (id) => {
    let local = localStorage.getItem("token");
    let userid = localStorage.getItem("userId");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);

    var raw = JSON.stringify({
      isDeleted: 1,
      mainUserId: userid,
    });

    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${baseurl}updateCustomerProfile/${id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true) {
          GetCustomer(currentPage, itemsPerPage, "");
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const HandleDeleteCustomer = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete customer",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#c76cff",
      cancelButtonColor: "#efefef",
      confirmButtonText: "Yes, delete it!",
      confirmButtonText: "<span style='color: white;'>Yes, delete it!</span>",
      cancelButtonText: "<span style='color: black;'>cancel!</span>",
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      customClass: {
        container: "sweet-alert2-container-zindex",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Deleted!",
          text: "Your data has been deleted.",
          icon: "success",
          confirmButtonColor: "#c76cff",
          customClass: {
            container: "sweet-alert2-container-zindex",
          },
        }).then(() => {
          UpdateCustomer(id);
        });
      }
    });
  };

  const sortName = (a) => {
    let sorted = data.sort((a, b) => (a?.firstName > b?.firstName ? 1 : -1));
    setData([...sorted]);
  };
  const sortName1 = (a) => {
    let sorted = data.sort((a, b) => (a?.firstName < b?.firstName ? 1 : -1));
    setData([...sorted]);
  };

  const sortLocation = (a) => {
    let sorted = data.sort((a, b) => (a?.country > b?.country ? 1 : -1));
    setData([...sorted]);
  };
  const sortLocation1 = (a) => {
    let sorted = data.sort((a, b) => (a?.country < b?.country ? 1 : -1));
    setData([...sorted]);
  };

  const sortStore = (a) => {
    let sorted = data.sort((a, b) =>
      a?.storeCredit > b?.storeCredit ? 1 : -1
    );
    setData([...sorted]);
  };
  const sortStore1 = (a) => {
    let sorted = data.sort((a, b) =>
      a?.storeCredit < b?.storeCredit ? 1 : -1
    );
    setData([...sorted]);
  };
  const sortAccount = (a) => {
    let sorted = data.sort((a, b) => (a?.account > b?.account ? 1 : -1));
    setData([...sorted]);
  };
  const sortAccount1 = (a) => {
    let sorted = data.sort((a, b) => (a?.account < b?.account ? 1 : -1));
    setData([...sorted]);
  };
  const sortCompany = (a) => {
    let sorted = data.sort((a, b) => (a?.firstName > b?.firstName ? 1 : -1));
    setData([...sorted]);
  };
  const sortCompany1 = (a) => {
    let sorted = data.sort((a, b) => (a?.firstName < b?.firstName ? 1 : -1));
    setData([...sorted]);
  };

  const [selectedFilter1, setSelectedFilter1] = useState("");
  const [isFreeTrial, setIsFreeTrial] = useState(false);

  useEffect(() => {
    const substatus = localStorage.getItem("substatus");
    const planDate = localStorage.getItem("planDate");

    if (
      (substatus === "null" ||
        substatus === "canceled" ||
        substatus === "expired") &&
      new Date(localStorage.getItem("planDate")) > new Date()
    ) {
      setIsFreeTrial(true);
    }
  }, []);
  const [isSidebarVisible, setSidebarVisible] = useState(false);

  const toggleSidebar = () => {
    setSidebarVisible(!isSidebarVisible);
    localStorage.setItem("sidebar", !isSidebarVisible);
  };

  useEffect(() => {
    const savedSidebarState = localStorage.getItem("sidebar");
    if (savedSidebarState) {
      setSidebarVisible(JSON.parse(savedSidebarState));
    }
  }, []);

  const handleImport = () => {
    // Logic for importing data
    // console.log("Import button clicked");
    document.getElementById("fileInput").click();
  };

  const ImportCsv = (file, retries = 3) => {
    setLoading(true);
    const local = localStorage.getItem("token");

    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    const formdata = new FormData();
    formdata.append("file", file);

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    // console.log("form", formdata);

    const fetchData = (attempt) => {
      fetch(ImportApi, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          // console.log(result);

          if (result.status === true) {
            GetCustomer(currentPage, itemsPerPage, "");
            document.getElementById("fileInput").value = "";
            setLoading(false);
          } else {
            document.getElementById("fileInput").value = "";
            setLoading(false);
          }
        })
        .catch((error) => {
          document.getElementById("fileInput").value = "";
          if (attempt < retries) {
            console.log(`Retrying... (${attempt + 1}/${retries})`);
            fetchData(attempt + 1);
          } else {
            setLoading(false);
            console.error(error);
          }
        });
    };

    fetchData(0);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setCsvFile(file);

      ImportCsv(file);

      // console.log("File selected:", file.name);
    }
  };

  const startPage = Math.max(currentPage - 2, 1);
  const endPage = Math.min(startPage + 3, totalPages1);
  const pages = Array.from({ length: totalPages1 }, (_, index) => index + 1);

  const [isFreeTrialVisible, setFreeTrialVisible] = useState(true);

  const toggleFreeTrial = () => {
    setFreeTrialVisible(!isFreeTrialVisible);
  };

  const sidebarStyleone = {
    overflow: "auto",
    scrollbarWidth: "none",
    msOverflowStyle: "none",
  };

  useEffect(() => {
    if (isFreeTrial) {
      // toggleSidebar(false);
      toggleFreeTrial(false);
    } else {
      // toggleSidebar(true);
      toggleFreeTrial(true);
    }
  }, [isFreeTrial]);

  const DeleteselectedCustomer = () => {
    setLoading(true);
    const local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);

    const raw = JSON.stringify({
      customerIds: selectedItems,
    });

    const requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(DeleteSelectedCustomerApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log(result);
        if (result.status === true) {
          Swal.fire(result.message);
          GetCustomer(currentPage, itemsPerPage, "");
          setLoading(false);
        } else {
          Swal.fire(result.message);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };
  const HandleDeleteSelectedCustomer = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete customer",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#c76cff",
      cancelButtonColor: "#efefef",
      confirmButtonText: "Yes, delete it!",
      confirmButtonText: "<span style='color: white;'>Yes, delete it!</span>",
      cancelButtonText: "<span style='color: black;'>cancel!</span>",
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      customClass: {
        container: "sweet-alert2-container-zindex",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Deleted!",
          text: "Your data has been deleted.",
          icon: "success",
          confirmButtonColor: "#c76cff",
          customClass: {
            container: "sweet-alert2-container-zindex",
          },
        }).then(() => {
          DeleteselectedCustomer();
        });
      }
    });
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      const sidebarElement = document.querySelector(".main-sidebar");
      const toggleButtonElement = document.querySelector(".sidebar-arrow a");

      if (
        sidebarElement &&
        !sidebarElement.contains(event.target) &&
        toggleButtonElement &&
        !toggleButtonElement.contains(event.target)
      ) {
        const mediaQuery = window.matchMedia("(max-width: 1024px)");
        if (mediaQuery.matches) {
          setSidebarVisible(false);
        }
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  return (
    <>
      <div className={isSidebarVisible ? "sidebar-mini" : ""}>
        <div
          className={`${
            isFreeTrial
              ? "main-wrapper devicelistcstmtableres customclswrapper"
              : "main-wrapper devicelistcstmtableres "
          } `}
        >
          <Helmet>
            <title>Centralized Customer Database | Toolbox Customer List</title>
            <meta
              name="description"
              content="Access a centralized customer list for personalized interactions. Strengthen relationships and improve customer satisfaction."
            />
          </Helmet>
          {loading ? (
            <div className="landing_loader">
              <div className="innerloader">
                <img src="img/spin-1s-156px.gif" alt="" />
              </div>
            </div>
          ) : null}
          {isFreeTrial ? (
            <>
              <div className="app-container-trial">
                <FreeTrail />
              </div>
            </>
          ) : null}
          <div style={sidebarStyleone}>
            <nav className="navbar navbar-expand-lg main-navbar sticky">
              <div className="sidebar-arrow">
                <a
                  href="#"
                  data-toggle="sidebar"
                  onClick={(e) => {
                    toggleSidebar();
                    e.preventDefault();
                  }}
                >
                  <img src="assets/img/sidebar-toggle.webp" alt="" />
                </a>
              </div>
              <div className="header-title mr-auto">
                <h4>
                  Customers <span className="count">{dataTotal} Customers</span>
                </h4>
              </div>
              <SideUl />
            </nav>
            <MainSidebar />
            <div className="main-content tableback">
              <div className="common_filter_sec">
                <div className="filter_col">
                  <div className="filter_tble_btn">
                    <div className="dropdown customerlistfiltercstmresp">
                      <button
                        type="button"
                        className="btn btn-secondary dropdown-toggle customdropdowntablebtnsetting"
                        href="#"
                        role="button"
                        id="dropdownMenuLink"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        onClick={handleFilterChange1}
                      >
                        {selectedFilter === "deafult"
                          ? "Filter Table"
                          : selectedFilter}
                      </button>
                      <img
                        src="assets/img/filter-icon.webp"
                        alt=""
                        className="filtericonimgboxsetting"
                      />
                      <ul
                        className="dropdown-menu customdropdownulboxsetting"
                        aria-labelledby="dropdownMenuLink"
                      >
                        <li>
                          <a
                            className="dropdown-item"
                            href="#"
                            onClick={handleFilterChange1}
                          >
                            Filter Table
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="#"
                            onClick={handleFilterChange1}
                          >
                            First Name A-Z
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="#"
                            onClick={handleFilterChange1}
                          >
                            Last Name A-Z
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="manage_btn">
                    <a href="#" onClick={() => HandleDeleteSelectedCustomer()}>
                      Delete
                    </a>
                  </div>
                  <div className="search_field">
                    <div className="search_icon">
                      <img src="assets/img/search-icon.webp" alt="" />
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search First Name/Surname/Email/Phone#"
                      value={searchTerm}
                      onChange={handleSearchTermChange}
                    />
                  </div>

                  <div className="imp_exp_btn export_btn">
                    <CSVLink
                      data={data1}
                      headers={headers}
                      filename={"table-data.csv"}
                    >
                      <button type="button">
                        <img src="assets/img/export-icon.webp" alt="" /> Export
                      </button>
                    </CSVLink>
                  </div>

                  <div className="imp_exp_btn import_btn">
                    <button type="button" onClick={handleImport}>
                      <img src="assets/img/import-icon.webp" alt="" /> Import
                    </button>
                    <input
                      type="file"
                      id="fileInput"
                      style={{ display: "none" }}
                      onChange={handleFileChange}
                    />
                  </div>

                  <div
                    className="add_customer_btn"
                    onClick={() => setShowModal(true)}
                  >
                    <a
                      href="#"
                      data-toggle="modal"
                      data-target="#addcustomerModal"
                    >
                      New Customer <i className="fa fa-plus" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="clearfix" />
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <div className="customertablemain">
                    <table className="table table-striped  customdatatable">
                      <thead className="customtheadpadding">
                        <tr style={{ cursor: "pointer" }}>
                          <th scope="col" className="checkbtnthsetting">
                            <input
                              className="form-check-input customcheckboxbtnsetting"
                              type="checkbox"
                              value=""
                              id="flexCheckDefault"
                              onChange={handleSelectAll}
                              checked={selectAll}
                            />
                          </th>
                          <th
                            scope="col"
                            // className={`customnameth ${
                            //   isCustomNameThActive
                            //     ? "customnamethactive"
                            //     : "customnameth"
                            // }`}
                            //onClick={handleNameSort}
                            className="customnameth"
                          >
                            Name
                            {sortState === 0 ? (
                              <span
                                className="upperarrow"
                                onClick={() => {
                                  sortName();
                                  setSortState(0);
                                }}
                              >
                                <i
                                  className="fa fa-long-arrow-up active"
                                  aria-hidden="true"
                                ></i>
                              </span>
                            ) : (
                              <span
                                className="upperarrow"
                                onClick={() => {
                                  sortName();
                                  setSortState(0);
                                }}
                              >
                                <i
                                  className="fa fa-long-arrow-up"
                                  aria-hidden="true"
                                ></i>
                              </span>
                            )}
                            {sortState === 1 ? (
                              <span
                                className="lowerarrow"
                                onClick={() => {
                                  sortName1();
                                  setSortState(1);
                                }}
                              >
                                <i
                                  className="fa fa-long-arrow-down active "
                                  aria-hidden="true"
                                ></i>
                              </span>
                            ) : (
                              <span
                                className="lowerarrow"
                                onClick={() => {
                                  sortName1();
                                  setSortState(1);
                                }}
                              >
                                <i
                                  className="fa fa-long-arrow-down "
                                  aria-hidden="true"
                                ></i>
                              </span>
                            )}
                          </th>
                          <th scope="col">Phone#</th>
                          <th scope="col">Email</th>
                          <th scope="col" className="customnameth">
                            Company
                            {sortState === 2 ? (
                              <span
                                className="upperarrow"
                                onClick={() => {
                                  setSortState(2);
                                }}
                              >
                                <i
                                  className="fa fa-long-arrow-up active"
                                  aria-hidden="true"
                                ></i>
                              </span>
                            ) : (
                              <span
                                className="upperarrow"
                                onClick={() => {
                                  setSortState(2);
                                }}
                              >
                                <i
                                  className="fa fa-long-arrow-up"
                                  aria-hidden="true"
                                ></i>
                              </span>
                            )}
                            {sortState === 3 ? (
                              <span
                                className="lowerarrow"
                                onClick={() => {
                                  setSortState(3);
                                }}
                              >
                                <i
                                  className="fa fa-long-arrow-down active"
                                  aria-hidden="true"
                                ></i>
                              </span>
                            ) : (
                              <span
                                className="lowerarrow"
                                onClick={() => {
                                  setSortState(3);
                                }}
                              >
                                <i
                                  className="fa fa-long-arrow-down "
                                  aria-hidden="true"
                                ></i>
                              </span>
                            )}
                          </th>
                          <th
                            scope="col"
                            className={`customnameth ${
                              isCustomNameThActive
                                ? "customnamethactive"
                                : "customnameth"
                            }`}
                            //onClick={handleLocationSort}
                          >
                            Location
                            {sortState === 4 ? (
                              <span
                                className="upperarrow"
                                onClick={() => {
                                  sortLocation();
                                  setSortState(4);
                                }}
                              >
                                <i
                                  className="fa fa-long-arrow-up active"
                                  aria-hidden="true"
                                ></i>
                              </span>
                            ) : (
                              <span
                                className="upperarrow"
                                onClick={() => {
                                  sortLocation();
                                  setSortState(4);
                                }}
                              >
                                <i
                                  className="fa fa-long-arrow-up"
                                  aria-hidden="true"
                                ></i>
                              </span>
                            )}
                            {sortState === 5 ? (
                              <span
                                className="lowerarrow"
                                onClick={() => {
                                  sortLocation1();
                                  setSortState(5);
                                }}
                              >
                                <i
                                  className="fa fa-long-arrow-down active"
                                  aria-hidden="true"
                                ></i>
                              </span>
                            ) : (
                              <span
                                className="lowerarrow"
                                onClick={() => {
                                  sortLocation1();
                                  setSortState(5);
                                }}
                              >
                                <i
                                  className="fa fa-long-arrow-down"
                                  aria-hidden="true"
                                ></i>
                              </span>
                            )}
                          </th>
                          <th
                            scope="col"
                            className={`customnameth ${
                              isCustomNameThActive
                                ? "customnamethactive"
                                : "customnameth"
                            }`}
                          >
                            {/* Tickets */}--
                            {/* {sortState === 6 ? (
                          <span
                            className="upperarrow"
                            onClick={() => {
                              setSortState(6);
                            }}
                          >
                            <i
                              className="fa fa-long-arrow-up active"
                              aria-hidden="true"
                            ></i>
                          </span>
                        ) : (
                          <span
                            className="upperarrow"
                            onClick={() => {
                              setSortState(6);
                            }}
                          >
                            <i
                              className="fa fa-long-arrow-up"
                              aria-hidden="true"
                            ></i>
                          </span>
                        )}
                        {sortState === 7 ? (
                          <span
                            className="lowerarrow"
                            onClick={() => {
                              setSortState(7);
                            }}
                          >
                            <i
                              className="fa fa-long-arrow-down active"
                              aria-hidden="true"
                            ></i>
                          </span>
                        ) : (
                          <span
                            className="lowerarrow"
                            onClick={() => {
                              setSortState(7);
                            }}
                          >
                            <i
                              className="fa fa-long-arrow-down "
                              aria-hidden="true"
                            ></i>
                          </span>
                        )} */}
                          </th>
                          <th
                            scope="col"
                            className={`customnameth ${
                              isCustomNameThActive
                                ? "customnamethactive"
                                : "customnameth"
                            }`}
                            //onClick={handleStoreSort}
                          >
                            {/* Store Credit */}--
                            {/* {sortState === 8 ? (
                          <span
                            className="upperarrow storearrcust"
                            onClick={() => {
                              sortStore();
                              setSortState(8);
                            }}
                          >
                            <i
                              className="fa fa-long-arrow-up active"
                              aria-hidden="true"
                            ></i>
                          </span>
                        ) : (
                          <span
                            className="upperarrow storearrcust"
                            onClick={() => {
                              sortStore();
                              setSortState(8);
                            }}
                          >
                            <i
                              className="fa fa-long-arrow-up "
                              aria-hidden="true"
                            ></i>
                          </span>
                        )}
                        {sortState === 9 ? (
                          <span
                            className="lowerarrow responsstore"
                            onClick={() => {
                              sortStore1();
                              setSortState(9);
                            }}
                          >
                            <i
                              className="fa fa-long-arrow-down active"
                              aria-hidden="true"
                            ></i>
                          </span>
                        ) : (
                          <span
                            className="lowerarrow responsstore"
                            onClick={() => {
                              sortStore1();
                              setSortState(9);
                            }}
                          >
                            <i
                              className="fa fa-long-arrow-down"
                              aria-hidden="true"
                            ></i>
                          </span>
                        )} */}
                          </th>
                          <th
                            scope="col"
                            className={`customnameth ${
                              isCustomNameThActive
                                ? "customnamethactive"
                                : "customnameth"
                            }`}
                            //onClick={handleAccountSort}
                          >
                            {/* Account */}--
                            {/* {sortState === 10 ? (
                          <span
                            className="upperarrow"
                            onClick={() => {
                              sortAccount();
                              setSortState(10);
                            }}
                          >
                            <i
                              className="fa fa-long-arrow-up active"
                              aria-hidden="true"
                            ></i>
                          </span>
                        ) : (
                          <span
                            className="upperarrow"
                            onClick={() => {
                              sortAccount();
                              setSortState(10);
                            }}
                          >
                            <i
                              className="fa fa-long-arrow-up"
                              aria-hidden="true"
                            ></i>
                          </span>
                        )} */}
                            {/* {sortState === 11 ? (
                          <span
                            className="lowerarrow"
                            onClick={() => {
                              sortAccount1();
                              setSortState(11);
                            }}
                          >
                            <i
                              className="fa fa-long-arrow-down active"
                              aria-hidden="true"
                            ></i>
                          </span>
                        ) : (
                          <span
                            className="lowerarrow"
                            onClick={() => {
                              sortAccount1();
                              setSortState(11);
                            }}
                          >
                            <i
                              className="fa fa-long-arrow-down"
                              aria-hidden="true"
                            ></i>
                          </span>
                        )} */}
                          </th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody className="customtheadpadding">
                        {currentData.length ? (
                          <>
                            {currentData?.map((item, index) => (
                              <tr
                                key={index}
                                className={index % 2 === 0 ? "" : "customeven"}
                              >
                                <td
                                  key={index.id}
                                  className={index % 2 === 0 ? "" : "bgcolor"}
                                >
                                  <input
                                    key={index.id}
                                    className="form-check-input customcheckboxbtnsetting"
                                    type="checkbox"
                                    value=""
                                    id={`flexCheckDefault${index}`}
                                    onChange={() => handleSelectRow(item?.id)}
                                    checked={selectedItems.includes(item?.id)}
                                  />
                                </td>
                                <td
                                  key={index.id}
                                  className={index % 2 === 0 ? "" : "bgcolor"}
                                >
                                  {" "}
                                  {item?.firstName} {item?.lastName}
                                </td>
                                <td
                                  key={index.id}
                                  className={index % 2 === 0 ? "" : "bgcolor"}
                                >
                                  {item?.phoneNumber || "--"}
                                </td>
                                <td
                                  key={index.id}
                                  className={index % 2 === 0 ? "" : "bgcolor"}
                                >
                                  {item?.email || "--"}
                                </td>
                                <td
                                  key={index.id}
                                  className={index % 2 === 0 ? "" : "bgcolor"}
                                >
                                  --
                                </td>
                                <td
                                  key={index.id}
                                  className={index % 2 === 0 ? "" : "bgcolor"}
                                >
                                  {item?.country || "--"}
                                </td>
                                <td
                                  key={index.id}
                                  className={index % 2 === 0 ? "" : "bgcolor"}
                                >
                                  --
                                </td>
                                <td
                                  key={index.id}
                                  className={index % 2 === 0 ? "" : "bgcolor"}
                                >
                                  --
                                  {/* {item.storeCredit} */}
                                </td>
                                <td
                                  key={index.id}
                                  className={index % 2 === 0 ? "" : "bgcolor"}
                                >
                                  --
                                  {/* {item.account} */}
                                </td>
                                <td
                                  key={index.id}
                                  className={index % 2 === 0 ? "" : "bgcolor"}
                                >
                                  <Link
                                    to="/CustomerDetail"
                                    state={{ data: item }}
                                    className="editbtn"
                                  >
                                    <img src="assets/img/editicon.webp" />
                                  </Link>

                                  <i
                                    className="fa-solid fa-trash deletecustmtb"
                                    onClick={() =>
                                      HandleDeleteCustomer(item.id)
                                    }
                                  ></i>
                                </td>
                              </tr>
                            ))}
                          </>
                        ) : (
                          <>
                            {" "}
                            <tr id="noDataMessage">
                              <td colSpan="10" className="nodataav">
                                No data available
                              </td>
                            </tr>
                          </>
                        )}
                      </tbody>
                    </table>
                    <div className="rows-per-page">
                      Rows per page:
                      <select
                        className="selectrowsperpage"
                        value={itemsPerPage}
                        onChange={handleRowsPerPageChange}
                      >
                        {filteredRowsPerPageOptions.map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                    </div>
                    {data.length > 0 ? (
                      <div className="row">
                        <div className="col-md-12">
                          <div className="custombottompaginationbox">
                            <nav aria-label="Page navigation example">
                              <ul className="pagination">
                                <li
                                  className="page-item custompaginationli  paginationleftbtnbox"
                                  style={{ cursor: "pointer" }}
                                >
                                  <button
                                    type="button"
                                    className="page-link"
                                    onClick={() =>
                                      handlePageClick(currentPage - 1)
                                    }
                                    disabled={currentPage === 1}
                                  >
                                    <img src="assets/img/previous.webp" />
                                  </button>
                                </li>
                                {pages.map((page, key) => {
                                  if (
                                    page === 1 ||
                                    page === totalPages1 ||
                                    (page >= startPage && page <= endPage)
                                  ) {
                                    return (
                                      <li
                                        className="page-item custompaginationli"
                                        key={key}
                                      >
                                        <a
                                          className={
                                            currentPage === page
                                              ? "page-link active customcountpagination"
                                              : "page-link customcountpagination"
                                          }
                                          onClick={() => handlePageClick(page)}
                                          disabled={currentPage === page}
                                          style={{ cursor: "pointer" }}
                                        >
                                          {page}
                                        </a>
                                      </li>
                                    );
                                  } else if (
                                    page === startPage - 1 ||
                                    page === endPage + 1
                                  ) {
                                    return (
                                      <li
                                        className="page-item custompaginationli"
                                        key={key}
                                      >
                                        <a
                                          className="page-link customcountpagination"
                                          style={{ cursor: "pointer" }}
                                        >
                                          ...
                                        </a>
                                      </li>
                                    );
                                  } else {
                                    return null;
                                  }
                                })}
                                {/* {Array.from(
                                        { length: totalPages },
                                        (_, index) => index + 1
                                      ).map((page, key) => (
                                        <li
                                          className="page-item custompaginationli"
                                          key={key}
                                        >
                                          <a
                                            key={key}
                                            className={
                                              currentPage === page
                                                ? "page-link active customcountpagination"
                                                : "page-link customcountpagination"
                                            }
                                            onClick={() =>
                                              handlePageClick(page)
                                            }
                                            disabled={currentPage === page}
                                            style={{ cursor: "pointer" }}
                                          >
                                            {page}
                                          </a>
                                        </li>
                                      ))} */}

                                <li
                                  className="page-item custompaginationli paginationrightbtnbox"
                                  style={{ cursor: "pointer" }}
                                >
                                  <button
                                    type="button"
                                    className="page-link"
                                    href="#"
                                    onClick={() =>
                                      handlePageClick(currentPage + 1)
                                    }
                                    disabled={currentPage === totalPages1}
                                  >
                                    <img src="assets/img/next.webp" />
                                  </button>
                                </li>
                              </ul>
                            </nav>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Footer />
        </div>
        {showModal === true ? (
          <div
            className="modal fade common_modal addcustomer_modal"
            id="addcustomerModal"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="addcustomerModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="addcustomerModalLabel">
                    Add New Customer
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => {
                      handleModalClose();
                      cancleValues();
                    }}
                  >
                    <span className="close_icon" />
                  </button>
                </div>
                <div className="modal-body">
                  <div className="custom_form customer_form">
                    <form action="" method="" className="cus_form">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <div className="upload_files">
                              {images ? (
                                <img
                                  className="uploaded_img"
                                  src={images}
                                  alt=""
                                />
                              ) : (
                                <img
                                  style={{ display: "none" }}
                                  className="uploaded_img"
                                  src=""
                                  alt=""
                                />
                              )}
                              <div className="file_upload">
                                <input
                                  type="file"
                                  name="files"
                                  accept="image/jpeg, image/jpg, image/png"
                                  onChange={handleImages}
                                />
                                <div className="fileedit_icon">
                                  <img
                                    src="assets/img/file-edit-icon.webp"
                                    alt=""
                                  />
                                </div>
                              </div>
                              <div className="filetrash_icon deleteimgcust">
                                {/* <button
                            type="button"
                            class="btn btn-primary"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                          >
                            <img src="assets/img/file-trash-icon.webp" alt="" />
                          </button> */}
                                <a
                                  href="#"
                                  onClick={() => {
                                    if (images) {
                                      HandleDelete();
                                    }
                                  }}
                                >
                                  <img
                                    src="assets/img/file-trash-icon.webp"
                                    alt=""
                                  />
                                </a>
                              </div>
                            </div>
                            {imageMsg && (
                              <span className="customvalidationdesignlogin">
                                {imageMsg}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-12">
                          <h5>Credentials</h5>
                        </div>

                        <div className="col-md-6">
                          <div className="form_column col_half">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                name="first_name"
                                placeholder="First Name"
                                value={firstName}
                                onChange={(e) => {
                                  setFirstName(e.target.value.trim());
                                  if (error.firstName) {
                                    setError({ ...error, firstName: "" });
                                  }
                                }}
                              />
                              {error.firstName && (
                                <span className="customvalidationdesignlogin">
                                  {error.firstName}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 lastnamecust">
                          <div className="form_column col_half">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                name="last_name"
                                placeholder="Last Name"
                                value={lastName}
                                onChange={(e) => {
                                  setLastName(e.target.value.trim());
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form_column col_half">
                            <div className="form-group">
                              <input
                                type="email"
                                className="form-control"
                                name="email"
                                placeholder="Email Address"
                                value={email}
                                onChange={(e) => {
                                  setEmail(e.target.value.trim());
                                  if (error.email) {
                                    setError({ ...error, email: "" });
                                  }
                                }}
                              />
                              {error.email && (
                                <span className="customvalidationdesignlogin">
                                  {error.email}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 lastnamecust">
                          <div className="form_column col_half">
                            <div className="form-group">
                              <PhoneInput
                                className="form-control"
                                placeholder="Enter phone number"
                                international
                                countryCallingCodeEditable={false}
                                //limitMaxLength={10}
                                value={phoneNo}
                                onChange={(val) => {
                                  handlePhoneChange(val);

                                  setValidState(true);
                                }}
                                //   onKeyup={setValidState(true)}
                                defaultCountry={countrycode.country_code}
                              />

                              {validstate === true ? (
                                <span className="customvalidationdesignlogin">
                                  {phoneNo && isValidPhoneNumber(phoneNo)
                                    ? ""
                                    : "Invalid phone number"}
                                </span>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <h5>Address</h5>
                        </div>
                        <div className="col-md-12">
                          <div className="form_column col_full">
                            <div className="form-group">
                              <textarea
                                className="form-control"
                                name="address"
                                placeholder="Street Address"
                                value={address}
                                onChange={(e) => {
                                  setAddress(e.target.value);
                                }}
                              ></textarea>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form_column col_half">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                name="country"
                                placeholder="Country"
                                value={country}
                                onChange={(e) => {
                                  setCountry(e.target.value.trim());
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form_column col_half">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                name="city"
                                placeholder="City"
                                value={city}
                                onChange={(e) => {
                                  SetCity(e.target.value.trim());
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form_column col_half">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                name="state"
                                placeholder="State / Province"
                                value={state}
                                onChange={(e) => {
                                  setState(e.target.value.trim());
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form_column col_half">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                name="zipcode"
                                placeholder="Zip Code"
                                value={zipcode}
                                onChange={(e) => {
                                  setZipcode(e.target.value.trim());
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        {message && (
                          <span className="customvalidationdesignlogin">
                            {message}
                          </span>
                        )}
                      </div>
                    </form>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary default_btn"
                    data-dismiss="modal"
                    onClick={() => {
                      handleModalClose();
                      cancleValues();
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary theme_btn"
                    onClick={() => SubmitCus()}
                  >
                    Save Customer
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
};
export default CustomerList;
