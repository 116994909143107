import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { detect, BrowserInfo } from "detect-browser";
import { baseurl, loginApi, resendVerifyApi } from "../Utility/Api";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState({});
  const [type, setType] = useState("password");
  const [icon, setIcon] = useState("fa fa-eye-slash");
  const [token, setToken] = useState("");
  const [deviceInfo, setDeviceInfo] = useState(null);
  const [ipAddress, setIpAddress] = useState(null);
  const [platform, setPlatform] = useState("");
  const [message, setMessage] = useState("");
  const [resendState, setResendState] = useState(false);
  const [message1, setMessage1] = useState("");
  const [sub, setSub] = useState("");
  const browser = detect();
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  let navigate = useNavigate();
  let location = useLocation();

  // useEffect(() => {
  //   const protocol = window.location.protocol;
  //   let host = window.location.host;

  //   const home = "/Account";
  //   let hostParts = host.split(".");
  //   if (hostParts[0] === "app") {
  //     hostParts.shift();
  //   }

  //   const newHost = hostParts.join(".");
  //   const newUrl = `${protocol}//${newHost}${home}`;
  //   console.log("newUrl", newUrl);
  //   // window.location.href = newUrl;
  // }, []);

  useEffect(() => {
    const protocol = window.location.protocol;
    const host = window.location.host;
    const subdomain = host.split(".")[0];

    setSub(subdomain);
    checkLoginSubdomain(subdomain);
    const disableBack = () => {
      window.history.forward();
    };

    disableBack();

    const handlePopstate = () => {
      disableBack();
    };

    window.addEventListener("popstate", handlePopstate);
    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, []);

  useEffect(() => {
    if (location.pathname === "/dashboard") {
      window.history.replaceState(null, null, window.location.pathname);
    }
  }, [location.pathname]);

  const isDisposableEmail = (email) => {
    const domain = email.split("@")[1];
    return disposableEmailDomains.includes(domain);
  };

  const disposableEmailDomains = [
    "gmail.com",
    "yahoo.com",
    "outlook.com",
    "hotmail.com",
    "aol.com",
    "icloud.com",
    "mail.com",
    "yandex.com",
    "protonmail.com",
    "zoho.com",
    "gmx.com",
    "ymail.com",
    "comcast.net",
    "me.com",
    "msn.com",
    "live.com",
    "sbcglobal.net",
    "verizon.net",
    "att.net",
    "cox.net",
    "smartitventures.com",
    // Add more domains as needed
  ];

  const Validation = () => {
    let error = {};
    let reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+*!=])(?=.*\d).{6,}$/;
    if (!email || !reg.test(email)) {
      error.email = "Please provide a valid email address.";
    } else if (!isDisposableEmail(email)) {
      error.email =
        "Please use a valid email address. Temporary email addresses are not allowed.";
    }
    if (!password) {
      error.password = "password is required.";
    }

    if (Object.keys(error).length === 0) {
      //console.log(Object.keys(error).length);
      LoginApi();
    }
    return error;
  };

  const OnLogin = () => {
    setError(Validation());
  };
  const checkLoginSubdomain = (subdomain) => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ subDomain: subdomain }),
      redirect: "follow",
    };

    fetch(`${baseurl}checkSubdomainForLogin`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === false) {
          window.location.replace("https://app.toolboxpos.com/Login");
        }
      })
      .catch((error) => console.log("error", error));
  };
  const LoginApi = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      email: email,
      password: password,
      ipAddress: ipAddress,
      browser: browser.name,
      subDomain: sub,
      device: platform,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    console.log("raww login", raw);
    fetch(loginApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log("login result", result);
        if (result?.status === true) {
          Swal.fire(
            "To ensure you see the most recent changes, please clear your browser's cache"
          );
          // Set local storage values
          localStorage.setItem("token", result?.token);
          localStorage.setItem("subscribed", result?.comapany?.isSubscribed);
          localStorage.setItem("planDate", result?.comapany?.planDate);
          localStorage.setItem("admin", result?.admin);
          localStorage.setItem("userId", result?.data?.userId);
          localStorage.setItem("subuserId", result?.data?.id);
          localStorage.setItem("userRole", result?.data?.userRole?.name);
          localStorage.setItem(
            "substatus",
            result?.comapany?.subscriptionStatus
          );
          localStorage.setItem(
            "permission",
            JSON.stringify(result?.permission)
          );

          setMessage(result?.message);

          const planDate = new Date(result?.comapany?.planDate);
          const currentDate = new Date();
          const subscriptionStatus = result?.comapany?.subscriptionStatus;

          if (
            planDate > currentDate &&
            (subscriptionStatus === "canceled" ||
              subscriptionStatus === null ||
              subscriptionStatus === "expired")
          ) {
            navigate("/dashboard");
          } else if (
            currentDate > planDate &&
            (subscriptionStatus === "canceled" ||
              subscriptionStatus === null ||
              subscriptionStatus === "expired")
          ) {
            navigate("/settings", { state: { activeState: 1 } });
          } else if (
            result?.admin === 0 &&
            planDate < currentDate &&
            (subscriptionStatus === "canceled" ||
              subscriptionStatus === null ||
              subscriptionStatus === "expired")
          ) {
            navigate("/userblank");
          } else {
            navigate("/dashboard");
            setMessage(result?.message);
          }
        } else if (result?.status === false) {
          if (
            result?.message ===
            "Your email is not verified yet. Please verify your email to proceed."
          ) {
            setResendState(true);
          }
          setMessage(result?.message);
        }

        // if (result.status === true) {
        //   localStorage.setItem("token", result?.token);
        //   localStorage.setItem("subscribed", result?.comapany?.isSubscribed);
        //   localStorage.setItem("planDate", result?.comapany?.planDate);
        //   localStorage.setItem("admin", result?.admin);
        //   localStorage.setItem("userId", result?.data?.userId);
        //   localStorage.setItem(
        //     "substatus",
        //     result?.comapany?.subscriptionStatus
        //   );
        //   localStorage.setItem(
        //     "permission",
        //     JSON.stringify(result?.permission)
        //   );

        //   setMessage(result?.message);
        //   if (
        //     new Date(result?.comapany?.planDate) > new Date() &&
        //     (result?.comapany?.subscriptionStatus === "canceled" ||
        //       result?.comapany?.subscriptionStatus === null ||
        //       result?.comapany?.subscriptionStatus === "expired")
        //   ) {
        //     navigate("/dashboard");
        //   } else if (
        //     new Date() > new Date(result?.comapany?.planDate) &&
        //     (result?.comapany?.subscriptionStatus === "canceled" ||
        //       result?.comapany?.subscriptionStatus === null ||
        //       result?.comapany?.subscriptionStatus === "expired")
        //   ) {
        //     navigate("/settings", { state: { activeState: 1 } });
        //   } else if (
        //     result?.admin === 0 &&
        //     new Date(result?.comapany?.planDate) < new Date() &&
        //     (result?.comapany?.subscriptionStatus === "canceled" ||
        //       result?.comapany?.subscriptionStatus === null ||
        //       result?.comapany?.subscriptionStatus === "expired")
        //   ) {
        //     navigate("/userblank");
        //   } else {
        //     navigate("/dashboard");
        //     setMessage(result?.message);
        //   }
        // }
        // if (
        //   result?.status === false &&
        //   result?.message ===
        //     "Your email is not verified yet. Please verify your email to proceed."
        // ) {
        //   setResendState(true);
        //   setMessage(result?.message);
        // } else {
        //   setMessage(result?.message);
        // }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      OnLogin();
    }
  };

  useEffect(() => {
    const getDeviceInfo = () => {
      const info = {
        userAgent: navigator.userAgent,
        platform: navigator.platform,
        language: navigator.language,
        screenWidth: window.screen.width,
        screenHeight: window.screen.height,
      };
      setDeviceInfo(info);
      setPlatform(info.platform);
    };

    const getIpAddress = async () => {
      try {
        const response = await fetch("https://api64.ipify.org?format=json");
        const data = await response.json();
        setIpAddress(data.ip);
      } catch (error) {
        console.error("Error fetching IP address:", error);
      }
    };

    getDeviceInfo();
    getIpAddress();
  }, []);

  const Validation1 = () => {
    let error = {};
    let reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+*!=])(?=.*\d).{6,}$/;
    if (!email) {
      error.email = "Email is required.";
    } else if (!reg.test(email)) {
      error.email = "Invalid email .";
    } else if (email && !isDisposableEmail(email)) {
      error.email =
        "Please use a valid email address. Temporary email addresses are not allowed.";
    }

    if (Object.keys(error).length === 0) {
      ResendEmail();
    }
    return error;
  };

  const Register = () => {
    setError(Validation1());
  };
  const ResendEmail = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      email: email,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(resendVerifyApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === true) {
          setMessage1(result?.message);
          setMessage("");
          setEmail("");
          setPassword("");
          setResendState(false);
        } else {
          setMessage1(result?.message);
          setEmail("");
          setPassword("");
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const BackLogin = () => {
    window.location.href = "https://app.toolboxpos.com/Login";
  };

  return (
    <>
      <div className="main-wrapper sublogincstm">
        <div className="login_page">
          <div className="regi_step_form">
            <form className="stepform">
              <div className="login_inner">
                <div className="login_left">
                  <div className="topheader">
                    <a href="#">
                      <img src="img/toolbox-logo-txt.webp" alt="" />
                    </a>
                  </div>
                  <div className="logo">
                    <a href="#">
                      <img src="img/landing-logo-icon.webp" alt="" />
                    </a>
                  </div>
                  <div className="other_link">
                    <ul>
                      <li>
                        <a href="#">Terms and conditions</a>
                      </li>
                      <li>
                        <a href="#">Privacy policy</a>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="login_right">
                  <div
                    className="not_store"
                    style={{ cursor: "pointer" }}
                    onClick={BackLogin}
                  >
                    <p>Not your store?</p>
                  </div>

                  <div className="form_area">
                    <div className="form_title">
                      <h4>Log into Toolbox</h4>
                      <h5>{sub}.toolboxpos.com</h5>
                    </div>
                    <div className="form_field">
                      <div className="form-group">
                        <label className="cus_label">Sign in</label>
                        <div className="input_field">
                          <input
                            type="email"
                            className="form-control"
                            name="email"
                            placeholder="Email Address *"
                            value={email}
                            onChange={(e) => {
                              setEmail(e.target.value.trim());
                              setError("");
                            }}
                            onKeyDown={handleKeyPress}
                          />
                        </div>
                        {error.email && (
                          <span className="customvalidationdesignlogin">
                            {error.email}
                          </span>
                        )}
                      </div>

                      <div className="form-group">
                        <div className="input_field">
                          <img
                            src={
                              showPassword
                                ? "assets/img/eyeclose.webp"
                                : "assets/img/eyeup.webp"
                            }
                            className="customupdatepasswordformeyeiconsettingreset"
                            onClick={togglePasswordVisibility}
                            alt={
                              showPassword ? "Hide password" : "Show password"
                            }
                          />
                          <input
                            type={showPassword ? "text" : "password"}
                            //type="password"
                            className="form-control"
                            value={password}
                            name="password"
                            onChange={(e) => {
                              setPassword(e.target.value.trim());
                              setError("");
                            }}
                            onKeyDown={handleKeyPress}
                            placeholder="Password"
                          />
                        </div>
                        {error.password && (
                          <span className="customvalidationdesignlogin">
                            {error.password}
                          </span>
                        )}
                        {message && (
                          <span className="customvalidationdesignlogin">
                            {message}
                          </span>
                        )}
                        {message1 && (
                          <span style={{ color: "red" }}>{message1}</span>
                        )}
                      </div>

                      <div className="form-btn">
                        <Link to="/forgotpass">Forgot your password?</Link>
                        <button
                          type="button"
                          className="submit_btn"
                          name="submit"
                          onClick={() => OnLogin()}
                        >
                          Log in <img src="img/Login-next-arrow.webp" alt="" />
                        </button>
                      </div>

                      {resendState === true ? (
                        <>
                          <div className="customform">
                            {/* <span style={{ color: "red" }}> {message}</span> */}
                            <h5 className="mt-5">
                              Please Enter Your Email For verification Again
                            </h5>
                          </div>
                          <div className="form-group">
                            {/* <label className="cus_label"></label> */}
                            <div className="input_field">
                              <input
                                type="email"
                                className="form-control"
                                name="email"
                                placeholder="Email Address *"
                                value={email}
                                onChange={(e) => {
                                  setEmail(e.target.value.trim());
                                  setError("");
                                }}
                                onKeyDown={handleKeyPress}
                              />
                            </div>
                            {error.email && (
                              <span className="customvalidationdesignlogin">
                                {error.email}
                              </span>
                            )}
                          </div>

                          <div className="form-btn resendbut">
                            <button
                              type="button"
                              className="submit_btn"
                              name="submit"
                              onClick={() => Register()}
                            >
                              Resend{" "}
                              {/* <img src="img/Login-next-arrow.svg" alt="" /> */}
                            </button>
                          </div>

                          <div className="text-center">
                            {message1 && (
                              <span style={{ color: "red" }}>{message1}</span>
                            )}
                          </div>
                        </>
                      ) : null}
                    </div>
                  </div>
                  <div className="already_exist">
                    <p>
                      Don't have an account? <Link to="/Signup">Sign up</Link>
                    </p>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
export default Login;
