import React, { useEffect, useState, useRef } from "react";
import $ from "jquery";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Footer from "../Footer";
import SideUl from "../MainDashboard/SideUl";
import MainSidebar from "../MainDashboard/MainSidebar";
import {
  AddBrandApi,
  AddConditionApi,
  AddItemApi,
  AddManufactureApi,
  AddPurchaseOrderApi,
  AddStorageApi,
  AddSupplierApi,
  AddTagsApi,
  GetAllColorApi,
  GetAllConditionApi,
  GetAllStorageApi,
  GetAllSupplierApi,
  GetManufactureApi,
  baseurl,
  customerEditApi,
  uploadImageApi,
} from "../Utility/Api";
import "react-phone-number-input/style.css";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import Swal from "sweetalert2";
import EditDeviceModal from "./EditDeviceModal";
import { join } from "lodash";
import FreeTrail from "../MainDashboard/FreeTrail";
//import EditProductModal from "./EditProductModal";

const EditDevice = () => {
  const [productName, setProductName] = useState("");
  const [brand, setBrand] = useState("");
  const [category, setCategory] = useState("");
  const [images, setImages] = useState("");
  const [description, setDescription] = useState("");
  const [SKU, setSkU] = useState("");
  const [Supplier, SetSupplier] = useState("");
  const [Supplier1, SetSupplier1] = useState("");
  const [SupplierId, SetSupplierId] = useState("");
  const [SupplierId1, SetSupplierId1] = useState("");
  const [unitCost, setUnitCost] = useState("");
  const [unitCost1, setUnitCost1] = useState("");
  const [price, setPrice] = useState("");
  const [error, setError] = useState({});
  const [minimunPrice, setMinimunPrice] = useState("");
  const [loading, setLoading] = useState(false);
  const [averageCost, setAverageCost] = useState("");
  const [imageMsg, setImageMsg] = useState("");
  const [id, setId] = useState("");
  const [errormsg, setErrormsg] = useState("");
  const [AllBrand, setAllBrand] = useState([]);
  const [showList, setShowList] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [typedText, setTypedText] = useState("");
  const [selectedBrandId, setSelectedBrandId] = useState(null);
  const [searchTermCategory, setSearchTermCategory] = useState("");
  const [showListCategory, setShowListCategory] = useState(false);
  const [selectedCateId, setSelectedCateId] = useState(null);
  const [selectedSupplierId, setSelectedSuppierId] = useState(null);
  const [selectedSupplierId1, setSelectedSuppierId1] = useState(null);
  const [AllItems, setAllItems] = useState([]);
  const [searchTermSuppiler, setsearchTermSuppiler] = useState("");
  const [searchTermSuppiler1, setsearchTermSuppiler1] = useState("");
  const [searchTermTags, setsearchTermTags] = useState("");
  const [AllSupplier, setAllSupplier] = useState([]);
  const [AllSupplier1, setAllSupplier1] = useState([]);
  const [showListSuppiler, setShowListSuppiler] = useState(false);
  const [showListSuppiler1, setShowListSuppiler1] = useState(false);
  const [AllTags, setAllTags] = useState([]);
  const [autogenrate, setAutoGenrate] = useState(0);
  const [autogenText, setAutoGenTex] = useState("Select SKU Code");
  const [showListAuto, setShowListAuto] = useState(false);
  const [selectedCategory4, setSelectedCategory4] = useState("default");
  const [checked, setChecked] = useState(true);
  const [overSelling, setOverSelling] = useState(checked ? 1 : 0);
  const [active, setActive] = useState("default");
  const [draft, setDrat] = useState(0);
  const [allColor, setAllColor] = useState([]);
  const [allStorage, setAllStorage] = useState([]);
  const [allCondition, setAllCondition] = useState([]);
  const [imeiNumber, setIMEINumber] = useState([]);
  const [searchTermStorage, setSearchTermStorage] = useState("");
  const [selectedStorage, setSelectedStorage] = useState(null);
  const [showListStorage, setShowListStorage] = useState(false);
  const [selectedStorageId, setSelectedStorageId] = useState(null);
  const [selectedColorId, setSelectedColorId] = useState(null);
  const [selectedColor, setSelectedColor] = useState(null);
  const [searchTermColor, setsearchTermColor] = useState("");
  const [showListColor, setShowListColor] = useState(false);
  const [selectedCondition, setSelectedCondition] = useState(null);
  const [searchTermCondition, setSearchTermCondition] = useState("");
  const [showListCondition, setShowListCondition] = useState(false);
  const [selectedConditionId, setSelectedConditionId] = useState(null);
  const [selectedCarrierId, setSelectedCarrierId] = useState(null);
  const [inventory, setInventory] = useState("");
  const inputRef = useRef(null);
  const inputRef1 = useRef(null);
  const inputRef2 = useRef(null);
  const inputRef3 = useRef(null);
  const inputRef4 = useRef(null);
  const dropdownRef = useRef(null);
  const dropdownRef1 = useRef(null);
  const dropdownRef2 = useRef(null);
  const dropdownRef3 = useRef(null);
  const dropdownRef4 = useRef(null);

  const [serialNumber, setSerialNumber] = useState("");
  const [quantity, setQuantity] = useState(0);
  const [imeiList, setImeiList] = useState([]);
  const [imeiCount, setImeiCount] = useState(0);

  const handleSerialNumberChange = (e) => {
    setSerialNumber(e.target.value.trim());
  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      const imei = e.target.value;
      if (!imei || imei.trim() === "") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Please enter a valid IMEI Number",
        });
        return;
      }
      setImeiList([serialNumber, ...imeiList]);
      setImeiCount(imeiCount + 1);
      setSerialNumber("");

      if (imeiList.length + 1 > quantity) {
        setQuantity(imeiList.length + 1);
      }
    }
  };

  const handleRemoveImei = (e, index) => {
    e.preventDefault();
    const updatedImeiList = [...imeiList];
    updatedImeiList.splice(index, 1);
    setImeiList(updatedImeiList);
    setQuantity(updatedImeiList.length);
  };

  const handleSearchChangeStorage = (e) => {
    setSearchTermStorage(e.target.value);
    setTypedText(e.target.value);
    setSelectedStorage(null);
  };

  const handleStorageSelect = (storage) => {
    setSelectedStorage(storage?.size);
    setSelectedStorageId(storage?.id);
    setShowListStorage(false);
  };

  const handleAddNewClickStorage = (e) => {
    if (!typedText.trim()) {
      Swal.fire({
        title: "Please enter a valid Category name!",
        customClass: {
          container: "custom-swal-container",

          confirmButton: "custom-swal-confirm-button",
        },
      });
      return;
    }
    //e.preventDefault();
    setSelectedStorage(typedText);
    setShowListStorage(false);
    setSearchTermStorage("");
    setTypedText("");
    AddStorage();
  };

  const handleSearchChangeColor = (e) => {
    setsearchTermColor(e.target.value);
    setTypedText(e.target.value);
    setSelectedColor(null);
  };

  const handleColorSelect = (item) => {
    setSelectedColor(item?.name);
    setSelectedColorId(item?.id);
    setShowListColor(false);
  };

  const handleAddNewClickColor = (e) => {
    if (!typedText.trim()) {
      Swal.fire({
        title: "Please enter a valid Color name!",
        customClass: {
          container: "custom-swal-container",

          confirmButton: "custom-swal-confirm-button",
        },
      });
      return;
    }
    //  e.preventDefault();
    setSelectedColor(typedText);
    setShowListColor(false);
    setsearchTermColor("");
    setTypedText("");
    AddColorDevice();
  };
  const filteredColor = allColor.filter((item) => {
    const name = item?.name;

    // Check if 'name' is not null before calling toLowerCase
    if (name && typeof name === "string") {
      return name.toLowerCase().includes(searchTermColor.toLowerCase());
    }

    return false;
  });

  const handleSearchChangeCondition = (e) => {
    setSearchTermCondition(e.target.value);
    setTypedText(e.target.value);
    setSelectedCondition(null);
  };

  const handleConditionSelect = (item) => {
    setSelectedCondition(item?.condition);
    setSelectedConditionId(item?.id);
    setShowListCondition(false);
  };

  const filteredCondition = allCondition.filter((item) => {
    const name = item?.condition;

    // Check if 'name' is not null before calling toLowerCase
    if (name && typeof name === "string") {
      return name.toLowerCase().includes(searchTermCondition.toLowerCase());
    }

    return false;
  });

  const handleAddNewClickCondition = (e) => {
    if (!typedText.trim()) {
      Swal.fire({
        title: "Please enter a valid Condition!",
        customClass: {
          container: "custom-swal-container",

          confirmButton: "custom-swal-confirm-button",
        },
      });
      return;
    }

    setSelectedCondition(typedText);
    setShowListCondition(false);
    setSearchTermCondition("");
    setTypedText("");
    AddCondition();
  };

  useEffect(() => {
    setOverSelling(checked ? 1 : 0);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowList(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef1.current &&
        !dropdownRef1.current.contains(event.target)
      ) {
        setShowListStorage(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef1]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef2.current &&
        !dropdownRef2.current.contains(event.target)
      ) {
        setShowListColor(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef2]);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef3.current &&
        !dropdownRef3.current.contains(event.target)
      ) {
        setShowListCondition(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef3]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef4.current &&
        !dropdownRef4.current.contains(event.target)
      ) {
        setShowListSuppiler1(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef4]);

  let location = useLocation();

  const navigate = useNavigate();
  const [imeiState, setImeiState] = useState(false);

  useEffect(() => {
    let detail = location?.state?.data;
    console.log("dadata", location?.state?.data);
    setProductName(detail?.title);
    setBrand(detail?.brand?.name);
    setSelectedBrandId(detail?.brand?.id);
    setSelectedStorage(detail?.storage?.size);
    setSelectedStorageId(detail?.storage?.id);
    setSelectedColor(detail?.color?.name);
    setSelectedColorId(detail?.color?.id);
    setSelectedCondition(detail?.condition?.condition);
    setSelectedConditionId(detail?.condition?.id);
    setDescription(detail?.description);
    setSkU(detail?.skuCode);
    SetSupplier(detail?.manufacturer?.name);
    SetSupplierId(detail?.supplier?.id);
    SetSupplierId1(detail?.manufacturer?.id);
    setUnitCost(detail?.unitCost);
    setPrice(detail?.sellingPrice);
    setImages(detail?.image);
    setMinimunPrice(detail?.minimumPrice);
    setId(detail?.id);

    setAverageCost(detail?.averageCost.toFixed(2));
    setSelectedCarrierId(detail?.carrier?.id);
    setSelectedSuppierId(detail?.manufacturer?.id);
    setSelectedSuppierId1(detail?.supplier?.id);
    setInventory(detail?.inventory);

    if (detail?.draft) {
      setSelectedCategory4("Draft");
      setActive(0);
    } else if (detail?.activeInactive === 1) {
      setSelectedCategory4("Active");
      setActive(1);
    } else {
      setSelectedCategory4("Inactive");
      setActive(0);
    }
    // setIMEINumber(detail?.imeiNumberArr);
    // const imeiNumbers = JSON.parse(detail?.imeiNumberArr);
    // imeiNumbers.sort((a, b) => a - b);
    // setIMEINumber(imeiNumbers.join(", ") || null);
    setQuantity(detail?.inventory);
    setQuantity(quantity);
    GetAllBrand();
    GetAllItem();
    GetAllSupplier();
    GetAllSupplier1();
    GetAllTags();
    GetAllStorage();
    GetAllColor();
    GetAllCondition();
  }, []);

  useEffect(() => {
    let detail = location?.state?.data;
    if (detail?.imeiNumberArr) {
      try {
        const imeiNumbers = JSON.parse(detail?.imeiNumberArr);
        if (Array.isArray(imeiNumbers)) {
          imeiNumbers.sort((a, b) => a - b);
          setIMEINumber(imeiNumbers.join(", "));
        } else {
          setIMEINumber([]);
        }
      } catch (error) {
        console.error("Error parsing JSON:", error);
        setIMEINumber([]);
      }
    } else {
      setIMEINumber([]);
    }
  }, []);

  const generateSKU = () => {
    // Assuming you have a specific format for SKU, you can customize this logic
    const sku = Math.floor(100000 + Math.random() * 900000)
      .toString()
      .substring(0, 6);

    setSkU(sku);
    return sku;
  };

  const Validation = () => {
    let error = {};
    let reg = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    // let reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+*!=])(?=.*\d).{6,}$/;

    const numericRegex = /^[0-9]+$/;

    if (!price) {
      error.price = "Selling Price Is Required";
    }

    if (!productName) {
      error.productName = "Product Name Is Required";
    } else if (productName.trim().length === 0) {
      error.productName = "Product Name Is Required";
    }

    if (Object.keys(error).length === 0) {
      // console.log(Object.keys(error).length);
      EditProduct();
    }
    return error;
  };

  const Validation1 = () => {
    let error = {};

    if (!quantity) {
      error.quantity = "Quantity Is Required";
    }
    if (!Supplier1) {
      error.Supplier1 = "Supplier Name is Required";
    }
    if (!unitCost1) {
      error.unitCost1 = "Cost Price is Required";
    }
    if (Object.keys(error).length === 0) {
      // console.log(Object.keys(error).length);
      AddPurchaseOrder();
    }
    return error;
  };

  const SubmitCus = () => {
    // EditProduct();
    setError(Validation());
  };

  const maxDescriptionLength = 200;
  const handleDescriptionChange = (e) => {
    const newDescription = e.target.value;

    if (newDescription.length <= maxDescriptionLength) {
      setDescription(newDescription);
      setErrormsg("");
    } else if (newDescription.length > maxDescriptionLength) {
      setTimeout(() => {
        setErrormsg("Allow Maximum 200 Characters");
      }, 2000);
    }
  };
  const handleImages = (e) => {
    var file = e.target.files[0];

    if (file) {
      const minSizeInBytes = 5000; // 5 KB
      const maxSizeInBytes = 1024 * 1024; // 1 MB

      if (file.size < minSizeInBytes) {
        setImageMsg("Image size is below the minimum allowed size (5 KB)");
        e.target.value = null;
        return;
      }

      let size = file.type.replace("/", ".");
      var blob = file.slice(0, file.size, file.type);
      const newName = new Date().getTime() + size;
      var newFile = new File([blob], newName, { type: file.type });

      UploadImage(newFile);
    }
  };

  const UploadImage = async (newFile) => {
    setLoading(true);
    var formdata = new FormData();
    formdata.append("file", newFile);
    formdata.append("type", "device-images");

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(uploadImageApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === true) {
          setImages(result?.data);
          // Swal.fire(result?.message);
          setLoading(false);
        } else {
          Swal.fire(result?.message);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log("error", error);
      });
  };

  const HandleDelete = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete image",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#c76cff",
      cancelButtonColor: "#efefef",
      confirmButtonText: "Yes, delete it!",
      confirmButtonText: "<span style='color: white;'>Yes, delete it!</span>",
      cancelButtonText: "<span style='color: black;'>cancel!</span>",
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      customClass: {
        container: "sweet-alert2-container-zindex",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Deleted!",
          text: "Your file has been deleted.",
          icon: "success",
          confirmButtonColor: "#c76cff",
          customClass: {
            container: "sweet-alert2-container-zindex",
          },
        }).then(() => {
          setImages("");
        });
      }
    });
  };

  const EditProduct = () => {
    setLoading(true);
    let local = localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);

    var raw = JSON.stringify({
      productCategoryId: 2,
      brandId: selectedBrandId,
      //supplierId: selectedSupplierId1 ? selectedSupplierId1 : "",
      manufacturerId: selectedSupplierId ? selectedSupplierId : SupplierId,
      title: productName,
      image: images,
      activeInactive: active,
      draft: draft ? draft : 0,
      skuCode: SKU,
      description: description,
      sellingPrice: Number(price) ? Number(price) : 0,
      unitCost: unitCost ? unitCost : 0,
      minimumPrice: Number(minimunPrice) ? Number(minimunPrice) : 0,
      averageCost: Number(averageCost) ? Number(averageCost) : 0,
      allowOverSelling: overSelling,
      storageId: selectedStorageId,
      colorId: selectedColorId,
      conditionId: selectedConditionId,
      imeiNumber: "",
      carrierId: selectedCarrierId,
    });

    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    console.log("edit raw", raw);

    fetch(`${baseurl}updateItem/${id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log("edit device", result);
        if (result?.status === true) {
          // navigate("/devicelist");
          navigate("/productlist");
          setLoading(false);
          Swal.fire(result?.message);
        } else {
          Swal.fire(result?.message);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const AddBrand = () => {
    let local = localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);
    myHeaders.append(
      "Cookie",
      "connect.sid=s%3AEXeh7T29UatkCPBdNwoIzBrcxDT_mEJd.RTtxRKFGO9m4w4ldAFV3Z016ghUV6rHgNPsCPhrrqgQ"
    );

    var raw = JSON.stringify({
      name: typedText,
      productCategoryId: 2,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(AddBrandApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === true) {
          GetAllBrand();
          setSelectedBrandId(result?.data?.id);
          setSearchTerm("");
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const AddColorDevice = () => {
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);

    const raw = JSON.stringify({
      name: typedText,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${baseurl}addColor`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === true) {
          GetAllColor();
          setSelectedColorId(result?.data?.id);
          setsearchTermColor("");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const AddCondition = () => {
    let local = localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);
    myHeaders.append(
      "Cookie",
      "connect.sid=s%3A-_3JwaXdjnqK5jnz_MupfyYwBOQ-xwpN.F15%2BK8LY79Ye8glfaA8Fk2LX1rgoPDz%2BrF7ZAbnGd58"
    );

    var raw = JSON.stringify({
      condition: typedText,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(AddConditionApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === true) {
          GetAllCondition();
          setSelectedConditionId(result?.data?.id);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const GetAllCondition = () => {
    let local = localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(GetAllConditionApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setAllCondition(result?.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const GetAllColor = () => {
    let local = localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(GetAllColorApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setAllColor(result?.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const GetAllBrand = () => {
    let local = localStorage.getItem("token");

    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);
    myHeaders.append(
      "Cookie",
      "connect.sid=s%3AEXeh7T29UatkCPBdNwoIzBrcxDT_mEJd.RTtxRKFGO9m4w4ldAFV3Z016ghUV6rHgNPsCPhrrqgQ"
    );

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${baseurl}getAllBrand?productCategoryId=2`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setAllBrand(result?.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const filteredBrands = AllBrand.filter((brand) => {
    const name = brand?.name;

    if (name && typeof name === "string") {
      return name.toLowerCase().includes(searchTerm.toLowerCase());
    }

    return false;
  });

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setTypedText(e.target.value);
    setBrand(null);
  };
  const handleBrandSelect = (brand) => {
    setBrand(brand?.name);
    setSelectedBrandId(brand?.id);
    setShowList(false);
  };
  const handleAddNewClick = (e) => {
    if (!typedText.trim()) {
      Swal.fire({
        title: "Please enter a valid Brand name!",
        customClass: {
          container: "custom-swal-container",

          confirmButton: "custom-swal-confirm-button",
        },
      });
      return;
    }
    //e.preventDefault();
    setBrand(typedText);
    setShowList(false);
    setSearchTerm("");
    setTypedText("");
    AddBrand();
  };

  const filteredCategory = AllItems.filter((brand) => {
    const name = brand?.name;

    if (name && typeof name === "string") {
      return name.toLowerCase().includes(searchTermCategory.toLowerCase());
    }

    return false;
  });

  const filteredStorage = allStorage.filter((brand) => {
    const name = brand?.size;
    // Check if 'name' is not null before calling toLowerCase
    if (name && typeof name === "string") {
      return name.toLowerCase().includes(searchTermStorage.toLowerCase());
    }

    return false; // Or handle null or non-string values as needed
  });

  const AddItem = () => {
    let local = localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);

    var raw = JSON.stringify({
      name: typedText,
      productCategoryId: 1,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(AddItemApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === true) {
          GetAllItem();
          setSelectedCateId(result?.data?.id);
          setSearchTermCategory("");
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const GetAllItem = () => {
    let local = localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${baseurl}getAllItemCategory?productCategoryId=1`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setAllItems(result?.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  const handleCategorySelect = (brand) => {
    setCategory(brand?.name);
    setSelectedCateId(brand?.id);
    setShowListCategory(false);
  };

  const filteredSuppiler = AllSupplier.filter((brand) => {
    const name = brand?.name;

    if (name && typeof name === "string") {
      return name.toLowerCase().includes(searchTermSuppiler.toLowerCase());
    }

    return false;
  });

  const filteredSuppiler1 = AllSupplier1.filter((brand) => {
    const name = brand?.name;

    if (name && typeof name === "string") {
      return name.toLowerCase().includes(searchTermSuppiler1.toLowerCase());
    }

    return false;
  });

  const filteredTags = AllTags.filter((brand) => {
    const name = brand?.name;

    if (name && typeof name === "string") {
      return name.toLowerCase().includes(searchTermTags.toLowerCase());
    }

    return false;
  });
  const AddSuppier = () => {
    let local = localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);

    var raw = JSON.stringify({
      name: typedText,
      productCategoryId: 2,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(AddManufactureApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === true) {
          GetAllSupplier();
          setSelectedSuppierId1(result?.data?.id);
          setsearchTermSuppiler("");
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const AddSuppier1 = () => {
    let local = localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);

    var raw = JSON.stringify({
      name: typedText,
      productCategoryId: 2,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(AddSupplierApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === true) {
          GetAllSupplier1();
          setSelectedSuppierId1(result?.data?.id);
          setsearchTermSuppiler1("");
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const GetAllSupplier = () => {
    let local = localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(GetManufactureApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setAllSupplier(result?.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const GetAllSupplier1 = () => {
    let local = localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(GetAllSupplierApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setAllSupplier1(result?.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  const handleSearchChangeSupplier = (e) => {
    setsearchTermSuppiler(e.target.value);
    setTypedText(e.target.value);
    SetSupplier(null);
  };

  const handleSearchChangeSupplier1 = (e) => {
    setsearchTermSuppiler1(e.target.value);
    setTypedText(e.target.value);
    SetSupplier1(null);
  };

  const handleSupplierSelect = (brand) => {
    SetSupplier(brand.name);
    setSelectedSuppierId(brand.id);
    setShowListSuppiler(false);
  };

  const handleSupplierSelect1 = (brand) => {
    SetSupplier1(brand.name);
    setSelectedSuppierId1(brand.id);
    setShowListSuppiler1(false);
  };

  const handleAddNewClickSupplier = (e) => {
    if (!typedText.trim()) {
      Swal.fire({
        title: "Please enter a valid Manufacturer name!",
        customClass: {
          container: "custom-swal-container",

          confirmButton: "custom-swal-confirm-button",
        },
      });
      return;
    }
    //e.preventDefault();
    SetSupplier(typedText);
    setShowListSuppiler(false);
    setsearchTermSuppiler("");
    setTypedText("");
    AddSuppier();
  };

  const handleAddNewClickSupplier1 = (e) => {
    if (!typedText.trim()) {
      Swal.fire({
        title: "Please enter a valid Supplier name!",
        customClass: {
          container: "custom-swal-container",

          confirmButton: "custom-swal-confirm-button",
        },
      });
      return;
    }
    //e.preventDefault();
    SetSupplier1(typedText);
    setShowListSuppiler1(false);
    setsearchTermSuppiler1("");
    setTypedText("");
    AddSuppier1();
  };

  const GetAllTags = () => {
    let local = localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${baseurl}getAllTag?productCategoryId=1`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log(result);
        setAllTags(result?.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  const onAdd = () => {
    if (imeiList.length == quantity) {
      setError(Validation1());
    } else {
      Swal.fire(
        "Please enter valid IMEI numbers according to the specified quantity."
      );
    }
  };

  const AddStorage = () => {
    let local = localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);

    var raw = JSON.stringify({
      size: typedText,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(AddStorageApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === true) {
          GetAllStorage();
          setSelectedStorageId(result?.data?.id);
          setSearchTermStorage("");
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const GetAllStorage = () => {
    let local = localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(GetAllStorageApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setAllStorage(result?.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const Total = parseFloat(Number(quantity) * Number(unitCost1));

  const AddPurchaseOrder = () => {
    let local = localStorage.getItem("token");
    var myHeaders = new Headers();

    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);
    const itemsData = [
      {
        inventory: Number(quantity),
        po: 0,
        unitCost: Number(unitCost1),
        itemId: id,
        imeiNumberArr: imeiList,
      },
    ];

    var raw = JSON.stringify({
      itemIds: [id],
      items: itemsData,
      supplierId: selectedSupplierId1,
      email: "",
      shipToId: 0,
      trackingNumber: "",
      shippingAddress: "",
      mailingAddress: "",
      deliveryDate: new Date(),
      description: "",
      totalCost: Total,
      activeInactive: 0,
      shippingCost: 0,
      puchaseOrderDate: new Date(),
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    console.log("device edit", raw);
    fetch(AddPurchaseOrderApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log("resulttt", result);
        if (
          result?.status === true &&
          result?.message === "Purchase Order Created Successfully!"
        ) {
          // navigate("/purchaseorderlist");
          Swal.fire("Inventory Added Successfully !");
          setQuantity("");
          setUnitCost1("");
          SetSupplier1("");
          setIMEINumber("");
          setImeiList([]);
        } else {
          Swal.fire(result?.message);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const [isFreeTrial, setIsFreeTrial] = useState(false);

  useEffect(() => {
    const substatus = localStorage.getItem("substatus");
    const planDate = localStorage.getItem("planDate");

    if (
      substatus === "null" ||
      substatus === "canceled" ||
      (substatus === "expired" &&
        new Date(localStorage.getItem("planDate")) > new Date())
    ) {
      setIsFreeTrial(true);
    }
  }, []);

  const [isSidebarVisible, setSidebarVisible] = useState(false);

  const toggleSidebar = () => {
    setSidebarVisible(!isSidebarVisible);
    localStorage.setItem("sidebar", !isSidebarVisible);
  };

  useEffect(() => {
    const savedSidebarState = localStorage.getItem("sidebar");
    if (savedSidebarState) {
      setSidebarVisible(JSON.parse(savedSidebarState));
    }
  }, []);

  const [isFreeTrialVisible, setFreeTrialVisible] = useState(true);

  const toggleFreeTrial = () => {
    setFreeTrialVisible(!isFreeTrialVisible);
  };

  const sidebarStyleone = {
    overflow: "auto",
    scrollbarWidth: "none",
    msOverflowStyle: "none",
    // position: 'sticky',

    // marginTop: isFreeTrialVisible ? "0px" : "0px",
  };

  useEffect(() => {
    if (isFreeTrial) {
      // toggleSidebar(false);
      toggleFreeTrial(false);
    } else {
      // toggleSidebar(true);
      toggleFreeTrial(true);
    }
  }, [isFreeTrial]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      const sidebarElement = document.querySelector(".main-sidebar");
      const toggleButtonElement = document.querySelector(".sidebar-arrow a");

      if (
        sidebarElement &&
        !sidebarElement.contains(event.target) &&
        toggleButtonElement &&
        !toggleButtonElement.contains(event.target)
      ) {
        const mediaQuery = window.matchMedia("(max-width: 1024px)");
        if (mediaQuery.matches) {
          setSidebarVisible(false);
        }
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <>
      <div className={isSidebarVisible ? "sidebar-mini" : ""}>
        {loading ? (
          <div className="landing_loader">
            <div className="innerloader">
              <img src="img/spin-1s-156px.gif" alt="" />
            </div>
          </div>
        ) : null}
        <div
          className={`${
            isFreeTrial
              ? "main-wrapper devicelistcstmtableres customclswrapper"
              : "main-wrapper devicelistcstmtableres "
          } `}
        >
          {isFreeTrial ? (
            <>
              <div className="app-container-trial">
                <FreeTrail />
              </div>
            </>
          ) : null}
          <div
            // className={`${isSidebarVisible ? "sidebar-mini" : ""} ${
            //   !isFreeTrialVisible ? "no-free-trial" : ""
            // }`}
            style={sidebarStyleone}
          >
            <nav className="navbar navbar-expand-lg main-navbar sticky">
              <div className="sidebar-arrow">
                <a
                  href="#"
                  data-toggle="sidebar"
                  onClick={(e) => {
                    toggleSidebar();

                    e.preventDefault();
                  }}
                >
                  <img src="assets/img/sidebar-toggle.webp" alt="" />
                </a>
              </div>
              <div className="header-title mr-auto edit_header_title">
                <div className="backbtn">
                  <Link to="/productlist">
                    <img src="assets/img/back-arrow.png" alt="" />
                  </Link>
                </div>
                <h4>Edit Device</h4>
              </div>
              <SideUl />
            </nav>
            <MainSidebar />
            <div className="main-content">
              <div className="edit_layout">
                <form action="" method="">
                  <div className="inner_edit_btns">
                    <div className="button_col_left cus_button_col">
                      <button
                        type="button"
                        className="cus_btn print_barcode createscsutmprodct"
                        data-toggle="modal"
                        data-target="#createproductModal"
                      >
                        Create Similar Product
                      </button>
                      <button type="button" className="cus_btn print_barcode">
                        Print Barcode
                      </button>
                      <button
                        type="button"
                        className="cus_btn inventory_adjust"
                      >
                        Adjust Inventory
                      </button>
                    </div>
                    <div className="button_col_right cus_button_col">
                      <button
                        type="button"
                        className="cus_btn update_btn"
                        onClick={() => SubmitCus()}
                      >
                        Save Changes
                      </button>
                    </div>
                  </div>
                  <div className="inner_edit_column">
                    <div className="edit_col_left">
                      <div className="edit_block_box block_box_full">
                        <div className="block_title">
                          <h5>General</h5>
                          <p>
                            Here you can manage and configure your stores
                            receipt, how it will look and how it will be printed
                          </p>
                        </div>
                        <div className="block_field">
                          <div className="field_row">
                            <div className="field_col fieldcol4">
                              <div className="form-group">
                                <label>Model Name</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Product Name"
                                  value={productName}
                                  onChange={(e) => {
                                    setProductName(e.target.value);
                                    if (error.productName) {
                                      setError({
                                        ...error,
                                        productName: "",
                                      });
                                    }
                                  }}
                                />
                                {error.productName && (
                                  <span
                                    style={{
                                      fontSize: "13px",
                                      color: "red",
                                    }}
                                  >
                                    {error.productName}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="field_col fieldcol4">
                              <div className="form-group">
                                <label>Brand</label>
                                <div
                                  className="formfield field_wd100 selectcategoryformbox editbrandcustmcls"
                                  ref={dropdownRef}
                                >
                                  <div>
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <input
                                        ref={inputRef}
                                        type="text"
                                        className="customsearchinputboxsetting inputeditcstmprd inputdeviceformcustmbox form-control"
                                        placeholder={`Select Brand`}
                                        value={brand || searchTerm}
                                        onChange={handleSearchChange}
                                        onClick={() => setShowList(!showList)}
                                      />
                                    </div>

                                    {showList && (
                                      <div className="addselectbox addbtneditformcstm">
                                        <ul>
                                          {filteredBrands.map((brand) => (
                                            <li
                                              key={brand.id}
                                              onClick={() =>
                                                handleBrandSelect(brand)
                                              }
                                              style={{
                                                cursor: "pointer",
                                              }}
                                            >
                                              {brand?.name}
                                            </li>
                                          ))}
                                        </ul>

                                        <button
                                          onClick={() => {
                                            handleAddNewClick();
                                            if (inputRef.current) {
                                              inputRef.current.focus();
                                            }
                                          }}
                                          style={{
                                            backgroundColor: "#9d3dd3",
                                          }}
                                          type="button"
                                          className="btn cus_addnew_btn  customaddnewcatebtnsetting"
                                        >
                                          <span>+</span> Add New Brand
                                        </button>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="clearfix"></div>
                            <div className="field_row">
                              <div className="field_col fieldcol4">
                                <div className="form-group">
                                  <label>Storage</label>
                                  <div
                                    className="formfield field_wd100 selectcategoryformbox editbrandcustmcls"
                                    ref={dropdownRef1}
                                  >
                                    <div>
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <input
                                          ref={inputRef1}
                                          type="text"
                                          className="customsearchinputboxsetting inputeditcstmprd inputdeviceformcustmbox form-control"
                                          placeholder={`Select Storage`}
                                          value={
                                            selectedStorage || searchTermStorage
                                          }
                                          onChange={handleSearchChangeStorage}
                                          onClick={() =>
                                            setShowListStorage(!showListStorage)
                                          }
                                        />
                                      </div>

                                      {showListStorage && (
                                        <div className="addselectbox addbtneditformcstm">
                                          <ul>
                                            {filteredStorage.map((brand) => (
                                              <li
                                                key={brand.id}
                                                onClick={() =>
                                                  handleStorageSelect(brand)
                                                }
                                                style={{
                                                  cursor: "pointer",
                                                }}
                                                aria-selected="true"
                                              >
                                                {brand?.size}
                                              </li>
                                            ))}
                                          </ul>

                                          <button
                                            onClick={() => {
                                              handleAddNewClickStorage();
                                              if (inputRef1.current) {
                                                inputRef1.current.focus();
                                              }
                                            }}
                                            style={{
                                              backgroundColor: "#9d3dd3",
                                            }}
                                            type="button"
                                            className="btn cus_addnew_btn  customaddnewcatebtnsetting"
                                          >
                                            <span>+</span> Add New Storage
                                          </button>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="field_col fieldcol4">
                                <div className="form-group">
                                  <label>Colour</label>
                                  <div
                                    className="formfield field_wd100 selectcategoryformbox editbrandcustmcls"
                                    ref={dropdownRef2}
                                  >
                                    <div>
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                        onClick={() =>
                                          setShowListColor(!showListColor)
                                        }
                                      >
                                        <input
                                          ref={inputRef2}
                                          type="text"
                                          className="customsearchinputboxsetting inputeditcstmprd inputdeviceformcustmbox form-control"
                                          placeholder={`Select Colour`}
                                          value={
                                            selectedColor || searchTermColor
                                          }
                                          onChange={handleSearchChangeColor}
                                          onClick={() =>
                                            setShowListColor(!showListColor)
                                          }
                                        />
                                      </div>

                                      {showListColor && (
                                        <div className="addselectbox addbtneditformcstm">
                                          <ul>
                                            {filteredColor.map((item) => (
                                              <li
                                                key={item.id}
                                                onClick={() =>
                                                  handleColorSelect(item)
                                                }
                                                style={{
                                                  cursor: "pointer",
                                                }}
                                                aria-selected="true"
                                              >
                                                {item?.name}
                                              </li>
                                            ))}
                                          </ul>

                                          <button
                                            onClick={() => {
                                              handleAddNewClickColor();
                                              if (inputRef2.current) {
                                                inputRef2.current.focus();
                                              }
                                            }}
                                            style={{
                                              backgroundColor: "#9d3dd3",
                                            }}
                                            type="button"
                                            className="btn cus_addnew_btn  customaddnewcatebtnsetting"
                                          >
                                            <span>+</span> Add New Colour
                                          </button>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="field_col fieldcol4">
                                <div className="form-group">
                                  <label>Condition/Grade</label>
                                  <div
                                    className="formfield field_wd100 selectcategoryformbox editbrandcustmcls"
                                    ref={dropdownRef3}
                                  >
                                    <div>
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <input
                                          ref={inputRef3}
                                          type="text"
                                          className="customsearchinputboxsetting inputeditcstmprd inputdeviceformcustmbox form-control"
                                          placeholder={`Select Condition/Grade`}
                                          value={
                                            selectedCondition ||
                                            searchTermCondition
                                          }
                                          onChange={handleSearchChangeCondition}
                                          onClick={() =>
                                            setShowListCondition(
                                              !showListCondition
                                            )
                                          }
                                        />
                                      </div>

                                      {showListCondition && (
                                        <div className="addselectbox addbtneditformcstm">
                                          <ul>
                                            {filteredCondition.map((brand) => (
                                              <li
                                                key={brand.id}
                                                onClick={() =>
                                                  handleConditionSelect(brand)
                                                }
                                                style={{
                                                  cursor: "pointer",
                                                }}
                                                aria-selected="true"
                                              >
                                                {brand?.condition}
                                              </li>
                                            ))}
                                          </ul>

                                          <button
                                            onClick={() => {
                                              handleAddNewClickCondition();
                                              if (inputRef3.current) {
                                                inputRef3.current.focus();
                                              }
                                            }}
                                            style={{
                                              backgroundColor: "#9d3dd3",
                                            }}
                                            type="button"
                                            className="btn cus_addnew_btn  customaddnewcatebtnsetting"
                                          >
                                            <span>+</span> Add New Condition
                                          </button>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="clearfix"></div>
                          <div className="field_row">
                            <div className="field_col fieldcol12">
                              <div className="form-group">
                                <label>Description</label>
                                <textarea
                                  className="form-control"
                                  name="description"
                                  placeholder="Add a description for your product"
                                  value={description}
                                  onChange={handleDescriptionChange}
                                ></textarea>
                              </div>{" "}
                              {errormsg && (
                                <span
                                  style={{
                                    fontSize: "13px",
                                    color: "red",
                                  }}
                                >
                                  {errormsg}
                                </span>
                              )}
                            </div>
                          </div>

                          <div className="clearfix"></div>

                          <div className="clearfix"></div>
                        </div>
                      </div>
                      {/* <div className="edit_block_box block_box_full">
                    <div className="block_title">
                      <h5>Serial Number/IMEI</h5>
                      <p>Manage Serial number information</p>
                    </div>
                    <div className="block_field">
                      <div className="field_row">
                        <div className="field_col fieldcol4">
                          <div className="form-group">
                            <label>Serial/IMEI</label>
                            <input
                              type="text"
                              className="form-control"
                              name="Serial_IMEI"
                              placeholder="Serial/IMEI Number"
                              value={imeiNumber}
                              onChange={(e) =>
                                setIMEINumber(e.target.value.trim())
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div className="clearfix"></div>
                    </div>
                  </div> */}
                      <div className="field_block_box">
                        <div className="edit_block_box block_box_half">
                          <div className="block_title">
                            <h5>Images</h5>
                            <p>Product images</p>
                          </div>
                          <div className="block_field">
                            <div className="field_row">
                              <div className="field_col fieldcol12">
                                <div className="import_files imageeditcustmpd">
                                  {images ? (
                                    <i
                                      className="fa-regular fa-circle-xmark deletimgcustmeditpr"
                                      onClick={() => HandleDelete()}
                                    ></i>
                                  ) : null}
                                  {images ? (
                                    <>
                                      <img
                                        src={images}
                                        className="custeditproductimg"
                                      />
                                    </>
                                  ) : (
                                    <>
                                      <h4>
                                        <img
                                          src="assets/img/import-files-arrow.webp"
                                          alt=""
                                        />{" "}
                                        Import Files
                                      </h4>
                                      <span>
                                        Drag and drop files here or click to
                                        upload
                                      </span>
                                      <input
                                        type="file"
                                        multiple
                                        className="import_field"
                                        onChange={handleImages}
                                        accept="image/jpeg, image/jpg, image/png"
                                      />
                                    </>
                                  )}
                                </div>
                              </div>
                              {/* {imageMsg ? imageMsg : null}
                               */}
                              {imageMsg && (
                                <span
                                  style={{
                                    fontSize: "13px",
                                    color: "red",
                                  }}
                                >
                                  {imageMsg}
                                </span>
                              )}
                            </div>
                            <div className="clearfix"></div>
                          </div>
                        </div>
                        <div className="edit_block_box block_box_half">
                          <div className="block_title">
                            <h5>SKU</h5>
                            <p>Manage and configure SKU</p>
                          </div>
                          <div className="block_field">
                            <div className="field_row">
                              <div className="autocstmgent">
                                <div className="field_col fieldcol6 autgentcstomedit">
                                  <div className="form-group">
                                    <div className="selectfield field_wd100">
                                      <div className="selectcategoryformbox1 editbrandcustmcls form-control">
                                        <div>
                                          <div
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            <span
                                              className="customsearchinputboxsetting skillskucodecstm"
                                              onClick={() =>
                                                setShowListAuto(!showListAuto)
                                              }
                                            >
                                              {autogenText
                                                ? autogenText
                                                : "Select SKU Code"}
                                            </span>
                                            <button
                                              className="btnautocstmsku"
                                              onClick={(e) => {
                                                setShowListAuto(!showListAuto);

                                                e.preventDefault();
                                              }}
                                              style={{
                                                cursor: "pointer",
                                              }}
                                            >
                                              <img
                                                src="assets/img/select-arrow.webp"
                                                alt="Handy Icon"
                                              />
                                            </button>
                                          </div>
                                          {showListAuto && (
                                            <div className="addselectbox addbtneditformcstm">
                                              <ul>
                                                <li
                                                  onClick={() => {
                                                    setShowListAuto(false);
                                                    setAutoGenrate(0);

                                                    setAutoGenTex(
                                                      " Select SKU Code"
                                                    );
                                                  }}
                                                  style={{
                                                    cursor: "pointer",
                                                  }}
                                                >
                                                  Select SKU Code
                                                </li>
                                                <li
                                                  onClick={() => {
                                                    setShowListAuto(false);
                                                    setAutoGenrate(2);
                                                    generateSKU();

                                                    setAutoGenTex(
                                                      "Auto Generated"
                                                    );
                                                  }}
                                                  style={{
                                                    cursor: "pointer",
                                                  }}
                                                >
                                                  Auto Generated
                                                </li>
                                                <li
                                                  onClick={() => {
                                                    setShowListAuto(false);
                                                    setAutoGenrate(1);
                                                    setSkU("");
                                                    setAutoGenTex(
                                                      "Manually Filled"
                                                    );
                                                  }}
                                                  style={{
                                                    cursor: "pointer",
                                                  }}
                                                >
                                                  Manually Filled
                                                </li>
                                              </ul>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="field_col fieldcol6 autgentcstomedit skucodecstm">
                                  <div className="form-group">
                                    <div className="selectfield field_wd100">
                                      {autogenrate === 1 ? (
                                        <div className="field_wd50">
                                          <input
                                            type="text"
                                            className="form-control inptcstmsku"
                                            name="sku_code"
                                            value={SKU}
                                            placeholder="Enter SKU Code"
                                            onChange={(e) =>
                                              setSkU(e.target.value)
                                            }
                                          />
                                        </div>
                                      ) : null}

                                      {autogenrate === 0 ? (
                                        <div className="field_wd50">
                                          <input
                                            type="text"
                                            className="form-control inptcstmsku"
                                            name="sku_code"
                                            //placeholder={SKU}
                                            value={SKU}
                                            readOnly
                                          />
                                        </div>
                                      ) : null}
                                      {autogenrate === 2 ? (
                                        <div className="field_wd50">
                                          <input
                                            type="text"
                                            className="form-control inptcstmsku"
                                            name="sku_code"
                                            //placeholder={SKU}
                                            value={SKU}
                                            readOnly
                                          />
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="clearfix"></div>
                          </div>
                        </div>
                      </div>
                      <div className="clearfix"></div>
                      <div className="edit_block_box block_box_full">
                        <div className="block_title">
                          <h5>Manufacturer Information</h5>
                          <p>Manage Manufacturer information</p>
                        </div>
                        <div className="block_field">
                          <div className="field_row">
                            <div className="field_col fieldcol4">
                              <div className="form-group">
                                <label>Manufacturer Name</label>
                                <div className="selectcategoryformbox">
                                  <div>
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <input
                                        ref={inputRef2}
                                        type="text"
                                        className="customsearchinputboxsetting inputeditcstmprd inputdeviceformcustmbox form-control"
                                        placeholder={`Select Manufacturer`}
                                        value={Supplier || searchTermSuppiler}
                                        onChange={handleSearchChangeSupplier}
                                        onClick={() =>
                                          setShowListSuppiler(!showListSuppiler)
                                        }
                                      />
                                    </div>

                                    {showListSuppiler && (
                                      <div className="addselectbox addbtneditformcstm">
                                        <ul>
                                          {filteredSuppiler.map((brand) => (
                                            <li
                                              key={brand.id}
                                              onClick={() => {
                                                handleSupplierSelect(brand);
                                              }}
                                              style={{
                                                cursor: "pointer",
                                              }}
                                            >
                                              {brand.name}
                                            </li>
                                          ))}
                                        </ul>

                                        <button
                                          onClick={() => {
                                            handleAddNewClickSupplier();
                                            if (inputRef2.current) {
                                              inputRef2.current.focus();
                                            }
                                          }}
                                          style={{
                                            backgroundColor: "#9d3dd3",
                                          }}
                                          type="button"
                                          className="btn cus_addnew_btn  customaddnewcatebtnsetting"
                                        >
                                          <span>+</span> Add New Manufacturer
                                        </button>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="clearfix"></div>
                        </div>
                      </div>
                      <div className="edit_block_box block_box_full">
                        <div className="block_title">
                          <h5>Pricing</h5>
                          <p>Manage pricing settings</p>
                        </div>
                        <div className="block_field manage_price_field">
                          <div className="field_row">
                            <div className="field_col fieldcol12">
                              <div className="form-group">
                                <label className="cus_label">Price Print</label>
                                <div className="formfield pricefield">
                                  <div className="price_col">
                                    <label className="cus_label label_red">
                                      Retail Price *
                                    </label>
                                    <input
                                      type="number"
                                      className="form-control no-arrow-input"
                                      name="retail_price"
                                      value={price}
                                      onChange={(e) => {
                                        setPrice(e.target.value.trim());

                                        if (error.price) {
                                          setError({
                                            ...error,
                                            price: "",
                                          });
                                        }
                                      }}
                                    />
                                    {error.price && (
                                      <span
                                        style={{
                                          fontSize: "13px",
                                          color: "red",
                                        }}
                                      >
                                        {error.price}
                                      </span>
                                    )}
                                    <div className="doller_sign">
                                      <span>$</span>
                                    </div>
                                  </div>

                                  <div className="price_col">
                                    <label className="cus_label">
                                      Minimum Price
                                    </label>
                                    <input
                                      type="number"
                                      className="form-control no-arrow-input"
                                      name="minimum_price"
                                      value={minimunPrice}
                                      onChange={(e) => {
                                        setMinimunPrice(e.target.value.trim());
                                      }}
                                    />

                                    <div className="doller_sign">
                                      <span>$</span>
                                    </div>
                                  </div>
                                  <div className="price_col">
                                    <label className="cus_label">
                                      Average Cost
                                    </label>
                                    <input
                                      type="number"
                                      className="form-control no-arrow-input"
                                      name="average_cost"
                                      value={averageCost}
                                      onChange={(e) => {
                                        setAverageCost(e.target.value.trim());
                                      }}
                                      readOnly
                                    />

                                    <div className="doller_sign">
                                      <span>$</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="clearfix"></div>
                        </div>
                      </div>
                      <div className="edit_block_box block_box_full">
                        <div className="block_title inventory_title">
                          <div className="blctitle">
                            <h5>Add Inventory</h5>
                            <p>Manage and add inventory</p>
                          </div>
                          <div className="inventory_checkbox">
                            <div className="checkbox">
                              <label>
                                Allow over Selling:{" "}
                                <input
                                  type="checkbox"
                                  name="checked"
                                  checked={checked}
                                  onChange={(e) => {
                                    setChecked(e.target.checked);
                                    setOverSelling(e.target.checked ? 1 : 0);
                                  }}
                                />
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="block_field inventory_field device_inventory_field">
                          <div className="field_row">
                            <div className="field_col fieldcol3">
                              <div className="form-group">
                                <label className="cus_label label_red">
                                  Supplier Name*
                                </label>
                                <div
                                  className="selectcategoryformbox"
                                  ref={dropdownRef4}
                                >
                                  <input
                                    ref={inputRef4}
                                    type="text"
                                    className="customsearchinputboxsetting inputeditcstmprd inputdeviceformcustmbox form-control"
                                    placeholder={`Select supplier`}
                                    value={Supplier1 || searchTermSuppiler1}
                                    onChange={handleSearchChangeSupplier1}
                                    onClick={() =>
                                      setShowListSuppiler1(!showListSuppiler1)
                                    }
                                  />

                                  {showListSuppiler1 && (
                                    <div className="addselectbox addbtneditformcstm">
                                      <ul>
                                        {filteredSuppiler1.map((brand) => (
                                          <li
                                            key={brand.id}
                                            onClick={() => {
                                              handleSupplierSelect1(brand);
                                            }}
                                            style={{
                                              cursor: "pointer",
                                            }}
                                          >
                                            {brand.name}
                                          </li>
                                        ))}
                                      </ul>

                                      <button
                                        onClick={() => {
                                          handleAddNewClickSupplier1();
                                          if (inputRef4.current) {
                                            inputRef4.current.focus();
                                          }
                                        }}
                                        style={{
                                          backgroundColor: "#9d3dd3",
                                        }}
                                        type="button"
                                        className="btn cus_addnew_btn  customaddnewcatebtnsetting"
                                      >
                                        <span>+</span> Add New Supplier
                                      </button>
                                    </div>
                                  )}
                                </div>
                                {error.Supplier1 && (
                                  <span
                                    style={{
                                      fontSize: "13px",
                                      color: "red",
                                      marginTop: "50px",
                                    }}
                                  >
                                    {error.Supplier1}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="field_col fieldcol2">
                              <div className="form-group">
                                <label className="cus_label label_red">
                                  Qty *
                                </label>
                                <input
                                  type="number"
                                  className="form-control no-arrow-input"
                                  placeholder="Quantity"
                                  value={quantity}
                                  onChange={(e) => {
                                    setQuantity(e.target.value.trim());

                                    if (error.quantity) {
                                      setError({
                                        ...error,
                                        quantity: "",
                                      });
                                    }
                                  }}
                                />
                                {error.quantity && (
                                  <span
                                    style={{
                                      fontSize: "13px",
                                      color: "red",
                                    }}
                                  >
                                    {error.quantity}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="field_col fieldcol2 price_field">
                              <div className="form-group">
                                <label className="cus_label label_red">
                                  Cost*
                                </label>
                                <input
                                  type="number"
                                  className="form-control no-arrow-input"
                                  name="average_cost"
                                  value={unitCost1}
                                  onChange={(e) =>
                                    setUnitCost1(e.target.value.trim())
                                  }
                                />
                                {error.unitCost1 && (
                                  <span
                                    style={{
                                      fontSize: "13px",
                                      color: "red",
                                    }}
                                  >
                                    {error.unitCost1}
                                  </span>
                                )}
                                <div className="doller_sign">
                                  <span>$</span>
                                </div>
                              </div>
                            </div>
                            <div className="field_col fieldcol2 serialno_field">
                              <div className="form-group">
                                <label className="cus_label label_red">
                                  Serial/IMEI *
                                </label>

                                <div>
                                  <input
                                    type="text"
                                    className="form-control textarea_cls"
                                    placeholder="Type IMEI and hit Enter"
                                    value={serialNumber}
                                    onChange={handleSerialNumberChange}
                                    onKeyPress={handleKeyPress}
                                  />
                                  <ul
                                    className="selected-tagss"
                                    style={{
                                      border: "1px solid #ccc",
                                      borderRadius: "5px",
                                      maxHeight: "100px",
                                      overflowY: "auto",
                                      backgroundColor: "#f9fafb",
                                    }}
                                  >
                                    {imeiList.map((imei, index) => (
                                      <li
                                        key={index}
                                        style={{
                                          position: "relative",
                                          marginBottom: "5px",
                                        }}
                                      >
                                        <span style={{ marginRight: "5px" }}>
                                          {imei}
                                        </span>
                                        <button
                                          className="delete-tag-buttons"
                                          onClick={(e) =>
                                            handleRemoveImei(e, index)
                                          }
                                          style={{
                                            position: "absolute",
                                            top: "0",
                                            right: "0",
                                            padding: "2px 5px",
                                            background: "none",
                                            border: "none",
                                            backgroundColor: "gray",
                                            borderRadius: "50px",
                                            height: "20px",
                                            width: "20px",
                                            textAlign: "center",
                                            fontSize: "10px",
                                            color: "white",
                                          }}
                                        >
                                          &#x2715;
                                        </button>
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                              </div>
                            </div>
                            <div className="field_col fieldcol3">
                              <div className="form-btn">
                                <button
                                  type="button"
                                  className="inventory_add"
                                  onClick={() => onAdd()}
                                >
                                  Add Inventory
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="clearfix"></div>
                        </div>
                      </div>
                      <div className="edit_block_box block_box_full">
                        <div className="block_title">
                          <h5>Tax</h5>
                          <p>Manage Tax settings</p>
                        </div>
                        <div className="block_field">
                          <div className="field_row">
                            <div className="field_col fieldcol12">
                              <div className="form-group">
                                <div className="checkbox">
                                  <label>
                                    <input type="checkbox" name="tax" /> Taxable
                                  </label>
                                  <span className="note">
                                    Product is taxable when selling
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="clearfix"></div>
                        </div>
                      </div>
                    </div>
                    <div className="edit_col_right respeditform">
                      <div className="product_status prod_sidebar">
                        <h5>Product Status</h5>
                        <div className="ft_select_option supplier_option option_20 ">
                          <div className="dropdown ">
                            <button
                              type="button"
                              className="btn dropdown-toggle statusdropdowncstm "
                              role="button"
                              id="dropdownMenuLink"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              {selectedCategory4 === "default"
                                ? "Select a Status"
                                : selectedCategory4}
                            </button>
                            <ul
                              className="dropdown-menu statusulprodctcstm"
                              aria-labelledby="dropdownMenuLink"
                            >
                              <li
                                onClick={() => {
                                  setSelectedCategory4("Active");

                                  setActive(1);
                                  setDrat(0);
                                }}
                              >
                                <a className="dropdown-item" href="#">
                                  Active
                                </a>
                              </li>

                              <li
                                onClick={() => {
                                  setSelectedCategory4("Inactive");
                                  setActive(0);
                                  setDrat(0);
                                }}
                              >
                                <a className="dropdown-item" href="#">
                                  Inactive
                                </a>
                              </li>
                              <li
                                onClick={() => {
                                  setSelectedCategory4("Draft");
                                  setActive(0);
                                  setDrat(1);
                                }}
                              >
                                <a className="dropdown-item" href="#">
                                  Draft
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="product_info_detial prod_sidebar">
                        <div className="product_info cus_prod_col">
                          <h5>
                            Product Information <span>Preview</span>
                          </h5>
                          <div className="prod_info">
                            <div className="prod_img">
                              {images ? (
                                <img src={images} alt="" />
                              ) : (
                                <img src="assets/img/defaultimg.webp" alt="" />
                              )}
                            </div>
                            <div className="prod_name">
                              <h6>{productName}</h6>
                              <span>{SKU}</span>
                            </div>
                            <div className="prod_content">
                              <p>{description}</p>
                            </div>
                          </div>
                        </div>
                        <div className="product_inventory cus_prod_col">
                          <h5>
                            Inventory <span>Product Inventory</span>
                          </h5>
                          <div className="inventory_list">
                            <div className="inventory_col">
                              <label>In Stock:</label>
                              <span>{inventory}</span>
                            </div>
                            <div className="inventory_col">
                              <label>On PO:</label>
                              <span>0</span>
                            </div>
                            <div className="inventory_col">
                              <label>Minimum Stock:</label>
                              <span>0</span>
                            </div>
                          </div>
                        </div>
                        <div className="product_detail cus_prod_col">
                          <h5>
                            Details <span>Product details</span>
                          </h5>
                          <div className="prod_detail_list">
                            <div className="prod_detail_col">
                              <label>Name:</label>
                              <span>{productName}</span>
                            </div>
                            <div className="prod_detail_col">
                              <label>Brand:</label>
                              <span>{brand}</span>
                            </div>
                            <div className="prod_detail_col">
                              <label>Manufacturer:</label>
                              <span>{Supplier}</span>
                            </div>
                            <div className="prod_detail_col">
                              <label>Storage:</label>
                              <span>{selectedStorage}</span>
                            </div>
                            <div className="prod_detail_col">
                              <label>Colour:</label>
                              <span>{selectedColor}</span>
                            </div>
                            <div className="prod_detail_col">
                              <label>Condition:</label>
                              <span>{selectedCondition}</span>
                            </div>
                            <div className="prod_detail_col">
                              <label>SKU:</label>
                              <span>{SKU}</span>
                            </div>
                            <div className="prod_detail_col">
                              <label>Serial/IMEI:</label>
                              <span>{imeiNumber}</span>
                            </div>
                          </div>
                        </div>
                        <div className="product_price cus_prod_col">
                          <h5>
                            Price <span>Pricing Information</span>
                          </h5>
                          <div className="prod_detail_list">
                            <div className="prod_detail_col">
                              <label className="label_red">Retail Price:</label>
                              <span>$ {price}</span>
                            </div>
                            <div className="prod_detail_col">
                              <label>Supplier Cost:</label>
                              <span>$ {unitCost}</span>
                            </div>
                            <div className="prod_detail_col">
                              <label>Average Cost:</label>
                              <span>$ {averageCost}</span>
                            </div>
                          </div>
                        </div>
                        <div className="product_image cus_prod_col">
                          <h5>
                            Images <span>Product Images</span>
                          </h5>{" "}
                          <div className="prod_image_upload imgseeditprocstm">
                            {images ? (
                              <i
                                className="fa-regular fa-circle-xmark deletimgcustmeditpr"
                                onClick={() => HandleDelete()}
                              ></i>
                            ) : null}
                            {images ? (
                              <>
                                <img src={images} />
                              </>
                            ) : (
                              <>
                                <label>Images</label>
                                <span>No images yet, upload images</span>
                                <input
                                  type="file"
                                  multiple
                                  className="import_field"
                                />
                              </>
                            )}
                          </div>
                        </div>
                        <div className="additional_info cus_prod_col">
                          <h5>
                            Additonal Info{" "}
                            <span>Additional product settings</span>
                          </h5>
                          <div className="additional_field">
                            <div className="checkbox">
                              <label>
                                Taxable{" "}
                                <input
                                  type="checkbox"
                                  name="additional_info"
                                  value="Taxable"
                                />
                              </label>
                            </div>
                            <div className="checkbox">
                              <label>
                                Taxable{" "}
                                <input
                                  type="checkbox"
                                  name="additional_info"
                                  value="Taxable"
                                />
                              </label>
                            </div>
                            <div className="checkbox">
                              <label>
                                Taxable{" "}
                                <input
                                  type="checkbox"
                                  name="additional_info"
                                  value="Taxable"
                                />
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="clearfix"></div>
                </form>
              </div>
            </div>
          </div>

          <Footer />
        </div>
        <div
          className="modal common_modal createproduct_modal"
          id="createproductModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="createproductModalLabel"
          aria-hidden="true"
        >
          <EditDeviceModal data={location?.state?.data} />
        </div>
      </div>
    </>
  );
};
export default EditDevice;
