import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import $ from "jquery";
import Footer from "../Footer";
import MainSidebar from "./MainSidebar";
import NavBar from "./NavBar";
import {
  DashboardInvoiceApi,
  DashboardTicketApi,
  GetSubUserProfileApi,
  HomeDashboardApi,
  getProfileApi,
} from "../Utility/Api";
import { Link, useNavigate } from "react-router-dom";
import "jquery-ui/ui/widgets/datepicker";
import moment from "moment-timezone";
import FreeTrail from "./FreeTrail";
import Swal from "sweetalert2";
import SideUl from "./SideUl";

const Dashboard = () => {
  const [data, setData] = useState([]);
  const [adminState, setAdminState] = useState("");
  const [permission, setPermission] = useState([]);
  const [dataUser, setDataUser] = useState([]);
  const [images, setImages] = useState("");
  const [totalSale, setTotalSale] = useState("");
  const [profit, setProfit] = useState("");
  const [NumberSales, setNumberSales] = useState("");
  const [NumberServices, setNumberServices] = useState("");
  const [TicketData, setTicketData] = useState([]);

  const [activeTicketId, setActiveTicketId] = useState(null);
  const [totalSalePer, setTotalSalePer] = useState("");
  const [profitPer, setProfitPer] = useState("");
  const [NumSalePer, setNumSalePer] = useState("");
  const [NumService, setNumService] = useState("");

  const handleTicketClick = (ticketId) => {
    setActiveTicketId(ticketId === activeTicketId ? null : ticketId);
  };

  useEffect(() => {
    let localAdmin = localStorage.getItem("admin");
    let localPerminssion = JSON.parse(localStorage.getItem("permission"));

    if (localAdmin && localPerminssion.length) {
      setAdminState(localAdmin);
      setPermission(localPerminssion);
      GetSubUserProfile();
    }
    GetProfile();
    window.scrollTo(0, 0);
    //GetProfile();
  }, []);
  useEffect(() => {
    $("#yourSelect2Element").select2();
    $(".select2service").select2();
    $(".select2product").select2();
    $(".cashselect2").select2();
    $(".select2filter").select2();
    $("#custom_calendar").datepicker();

    $(".duedatepicker").datepicker({
      beforeShow: function (input, inst) {
        $(inst.dpDiv).addClass("commondatepicker");
      },
    });
  }, []);

  const [greeting, setGreeting] = useState("");

  useEffect(() => {
    const getCurrentTime = () => {
      const userTimeZone = moment.tz.guess();
      const currentTime = moment().tz(userTimeZone);
      const currentHour = currentTime.hours();

      if (currentHour >= 5 && currentHour < 12) {
        setGreeting("Good Morning");
      } else if (currentHour >= 12 && currentHour < 17) {
        setGreeting("Good Afternoon");
      } else {
        setGreeting("Good Evening");
      }
    };

    getCurrentTime();
    GetDashboard();
    //GetInvoiceListWeek();
    GetInvoiceListToday();
    //GetInvoiceListYesterday();
  }, []);

  const GetProfile = () => {
    let local = localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(getProfileApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true) {
          setData(result?.data);
          setImages(result?.data?.userDetails?.profileImage);
        } else {
          // console.log(result.message);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const GetSubUserProfile = () => {
    let local = localStorage.getItem("token");

    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(GetSubUserProfileApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true) {
          setDataUser(result?.data);
        } else {
          //console.log(result.message);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  let navigate = useNavigate();

  const Setting = () => {
    navigate("/settings");
  };

  const GetDashboard = () => {
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);
    // myHeaders.append(
    //   "Authorization",
    //   "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6Mzg2LCJjb21wYW55SWQiOjIyMCwiaWF0IjoxNzI2MTEzOTk3LCJleHAiOjE3MjYxMjgzOTd9.z2CxaTrpDlimVD1VjfauXOexamYTqDYV2fG9wc7bRgI"
    // );

    const raw = JSON.stringify({
      // startDate: "2024-09-11",
      // endDate: "2024-09-11",
      startDate: moment(new Date()).format("YYYY-MM-DD"),
      endDate: moment(new Date()).format("YYYY-MM-DD"),
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(HomeDashboardApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log(result);

        console.log("dashboard data", result);
        if (result.status === true) {
          // Set values with two decimal places
          setNumberServices(result?.data?.ServiceCount.toFixed(2));
          setNumberSales(result?.data?.InvoiceCount.toFixed(2));
          setProfit(result?.data?.checkRevenue.toFixed(2));
          setTotalSale(result?.data?.totalSalesAmountGrandTotal.toFixed(2));

          // Parse the float values from the result data
          const t = parseFloat(result?.data?.totalSalesAmountGrandTotal);
          const t1 = parseFloat(
            result?.data?.totalSalesAmountGrandTotalPrevious
          );

          // Calculate total sale percentage
          let t2 = 0;
          if (t1 !== 0) {
            t2 = (t / t1) * 100;
          }
          setTotalSalePer(t2.toFixed(2));

          // Parse the float values from the result data
          const p = parseFloat(result?.data?.checkRevenue);
          const p1 = parseFloat(result?.data?.checkRevenuePrevious);

          // Calculate profit percentage
          let p2 = 0;
          if (p1 !== 0) {
            p2 = (p / p1) * 100;
          }
          // console.log("p2", p2);
          setProfitPer(p2.toFixed(2));

          // Parse the float values from the result data
          const n = parseFloat(result?.data?.ServiceCount);
          const n1 = parseFloat(result?.data?.ServiceCountPrevious);

          // Calculate number service percentage
          let n2 = 0;
          if (n1 !== 0) {
            n2 = (n / n1) * 100;
          }
          setNumService(n2.toFixed(2));

          // Parse the float values from the result data
          const s = parseFloat(result?.data?.InvoiceCount);
          const s1 = parseFloat(result?.data?.InvoiceCountPrevious);

          // Calculate number sale percentage
          let s2 = 0;
          if (s1 !== 0) {
            s2 = (s / s1) * 100;
          }
          setNumSalePer(s2.toFixed(2));
        } else {
          // console.log(result.message);
        }
      })
      .catch((error) => console.error(error));
    // let local = localStorage.getItem("token");

    // var myHeaders = new Headers();
    // myHeaders.append("Authorization", `Bearer ${local}`);

    // const raw = JSON.stringify({
    //   startDate: "2024-09-11", // moment(new Date()).format("YYYY-MM-DD"),
    //   endDate: "2024-09-11", // moment(new Date()).format("YYYY-MM-DD"),
    //   //status: 1,
    // });

    // const requestOptions = {
    //   method: "POST",
    //   headers: myHeaders,
    //   body: raw,
    //   redirect: "follow",
    // };
    // console.log("data", raw);

    // fetch(HomeDashboardApi, requestOptions)
    //   .then((response) => response.json())
    //   .then((result) => {
    //     console.log("dashboard data", result);
    //     if (result.status === true) {
    //       // Set values with two decimal places
    //       setNumberServices(result?.data?.ServiceCount.toFixed(2));
    //       setNumberSales(result?.data?.InvoiceCount.toFixed(2));
    //       setProfit(result?.data?.checkRevenue.toFixed(2));
    //       setTotalSale(result?.data?.totalSalesAmountGrandTotal.toFixed(2));

    //       // Parse the float values from the result data
    //       const t = parseFloat(result?.data?.totalSalesAmountGrandTotal);
    //       const t1 = parseFloat(
    //         result?.data?.totalSalesAmountGrandTotalPrevious
    //       );

    //       // Calculate total sale percentage
    //       let t2 = 0;
    //       if (t1 !== 0) {
    //         t2 = (t / t1) * 100;
    //       }
    //       setTotalSalePer(t2.toFixed(2));

    //       // Parse the float values from the result data
    //       const p = parseFloat(result?.data?.checkRevenue);
    //       const p1 = parseFloat(result?.data?.checkRevenuePrevious);

    //       // Calculate profit percentage
    //       let p2 = 0;
    //       if (p1 !== 0) {
    //         p2 = (p / p1) * 100;
    //       }
    //       // console.log("p2", p2);
    //       setProfitPer(p2.toFixed(2));

    //       // Parse the float values from the result data
    //       const n = parseFloat(result?.data?.ServiceCount);
    //       const n1 = parseFloat(result?.data?.ServiceCountPrevious);

    //       // Calculate number service percentage
    //       let n2 = 0;
    //       if (n1 !== 0) {
    //         n2 = (n / n1) * 100;
    //       }
    //       setNumService(n2.toFixed(2));

    //       // Parse the float values from the result data
    //       const s = parseFloat(result?.data?.InvoiceCount);
    //       const s1 = parseFloat(result?.data?.InvoiceCountPrevious);

    //       // Calculate number sale percentage
    //       let s2 = 0;
    //       if (s1 !== 0) {
    //         s2 = (s / s1) * 100;
    //       }
    //       setNumSalePer(s2.toFixed(2));

    //       // setNumberServices(result?.data?.ServiceCount.toFixed(2));
    //       // setNumberSales(result?.data?.InvoiceCount.toFixed(2));
    //       // setProfit(result?.data?.checkRevenue.toFixed(2));
    //       // setTotalSale(result?.data?.totalSalesAmountGrandTotal.toFixed(2));

    //       // const t = parseFloat(result?.data?.totalSalesAmountGrandTotal);
    //       // const t1 = parseFloat(
    //       //   result?.data?.totalSalesAmountGrandTotalPrevious
    //       // );

    //       // const t2 = (t / t1) * 100;

    //       // setTotalSalePer(t2.toFixed(2));

    //       // const p = parseFloat(result?.data?.checkRevenue);
    //       // const p1 = parseFloat(result?.data?.checkRevenuePrevious);
    //       // const p2 = (p / p1) * 100;
    //       // console.log("p2", p2);

    //       // setProfitPer(p2.toFixed(2));

    //       // const n = parseFloat(result?.data?.ServiceCount);
    //       // const n1 = parseFloat(result?.data?.ServiceCountPrevious);
    //       // const n2 = (n / n1) * 100;

    //       // setNumService(n2.toFixed(2));

    //       // const s = parseFloat(result?.data?.InvoiceCount);
    //       // const s1 = parseFloat(result?.data?.InvoiceCountPrevious);
    //       // const s2 = (s / s1) * 100;

    //       // setNumSalePer(s2.toFixed(2));

    //       //setDataUser(result?.data);
    //     } else {
    //       // console.log(result.message);
    //     }
    //   })
    //   .catch((error) => {
    //     console.log("error", error);
    //   });
  };

  const GetInvoiceListToday = () => {
    let local = localStorage.getItem("token");

    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    const raw = JSON.stringify({
      startDate: moment(new Date()).format("YYYY-MM-DD"),
      endDate: moment(new Date()).format("YYYY-MM-DD"),
      //status: 1,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(DashboardTicketApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log("result dashboard", result);
        if (result.status === true) {
          setTicketData(result?.data);
        } else {
          //console.log(result.message);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const GetInvoiceListYesterday = () => {
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);

    const yesterday = moment().subtract(1, "days");
    const raw = JSON.stringify({
      startDate: yesterday.format("YYYY-MM-DD"),
      endDate: yesterday.format("YYYY-MM-DD"),

      // startDate: moment(new Date()).format("YYYY-MM-DD"),
      // endDate: moment(new Date()).format("YYYY-MM-DD"),
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(DashboardTicketApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true) {
          setTicketData(result?.data);
        } else {
          //console.log(result.message);

          setTicketData(result?.data);
        }
      })
      .catch((error) => console.error(error));
  };

  const GetInvoiceListWeek = () => {
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);

    const startOfWeek = moment().startOf("week").format("YYYY-MM-DD");
    const endOfWeek = moment().endOf("week").format("YYYY-MM-DD");

    const raw = JSON.stringify({
      startDate: startOfWeek,
      endDate: endOfWeek,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(DashboardTicketApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true) {
          setTicketData(result?.data);
        } else {
          //console.log(result.message);

          setTicketData(result?.data);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  const handleTimeRangeChange = (selectedOption) => {
    switch (selectedOption) {
      case "Today":
        GetInvoiceListToday();
        break;
      case "Yesterday":
        GetInvoiceListYesterday();
        break;
      case "Week":
        GetInvoiceListWeek();
        break;

        break;
      default:
        break;
    }
  };
  const getInitials = (firstName, lastName) => {
    const firstInitial = firstName ? firstName.charAt(0).toUpperCase() : "";
    const lastInitial = lastName ? lastName.charAt(0).toUpperCase() : "";
    return `${firstInitial}${lastInitial}`;
  };
  const [isFreeTrial, setIsFreeTrial] = useState(false);

  useEffect(() => {
    const substatus = localStorage.getItem("substatus");
    const planDate = localStorage.getItem("planDate");

    if (
      (substatus === "null" ||
        substatus === "canceled" ||
        substatus === "expired") &&
      new Date(localStorage.getItem("planDate")) > new Date()
    ) {
      setIsFreeTrial(true);
    }
  }, []);

  const [isSidebarVisible, setSidebarVisible] = useState(false);

  const toggleSidebar = () => {
    setSidebarVisible(!isSidebarVisible);
    localStorage.setItem("sidebar", !isSidebarVisible);
  };

  useEffect(() => {
    const savedSidebarState = localStorage.getItem("sidebar");
    if (savedSidebarState) {
      setSidebarVisible(JSON.parse(savedSidebarState));
    }
  }, []);

  const [isFreeTrialVisible, setFreeTrialVisible] = useState(true);

  const toggleFreeTrial = () => {
    setFreeTrialVisible(!isFreeTrialVisible);
  };

  const sidebarStyleone = {
    overflow: "auto",
    scrollbarWidth: "none",
    msOverflowStyle: "none",
  };

  useEffect(() => {
    if (isFreeTrial) {
      // toggleSidebar(false);
      toggleFreeTrial(false);
    } else {
      // toggleSidebar(true);
      toggleFreeTrial(true);
    }
  }, [isFreeTrial]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      const sidebarElement = document.querySelector(".main-sidebar");
      const toggleButtonElement = document.querySelector(".sidebar-arrow a");

      if (
        sidebarElement &&
        !sidebarElement.contains(event.target) &&
        toggleButtonElement &&
        !toggleButtonElement.contains(event.target)
      ) {
        const mediaQuery = window.matchMedia("(max-width: 1024px)");
        if (mediaQuery.matches) {
          setSidebarVisible(false);
        }
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <>
      <div className={isSidebarVisible ? "sidebar-mini" : ""}>
        <div
          className={`${
            isFreeTrial
              ? "main-wrapper devicelistcstmtableres customclswrapper"
              : "main-wrapper devicelistcstmtableres "
          } `}
        >
          <Helmet>
            <title>User-Friendly Dashboard | Toolbox Management</title>
            <meta
              name="description"
              content="Navigate through a user-friendly dashboard for seamless account management. Experience efficiency and control at your fingertips."
            />
          </Helmet>

          {isFreeTrial ? (
            <>
              <div className="app-container-trial">
                <FreeTrail />
              </div>
            </>
          ) : null}
          <div style={sidebarStyleone}>
            <nav className="navbar navbar-expand-lg main-navbar sticky">
              <div className="sidebar-arrow">
                <a
                  href="#"
                  data-toggle="sidebar"
                  onClick={(e) => {
                    toggleSidebar();

                    e.preventDefault();
                  }}
                >
                  <img src="assets/img/sidebar-toggle.webp" alt="" />
                </a>
              </div>
              <div className="header-title mr-auto">
                <h4>Dashboard</h4>
              </div>
              <SideUl />
            </nav>
            <MainSidebar />
            <div className="main-content">
              <div className="dashboard_inner">
                <div className="dashboard_left">
                  <div className="dashbaord_title">
                    {/* <h5>Good morning, {data?.userDetails?.userName} 👋</h5> */}
                    <h5>{`${greeting}, ${
                      adminState === 0 || permission?.length > 0
                        ? dataUser?.userDetails?.name
                        : adminState === 1 || permission?.length === 0
                        ? data?.userDetails?.userName
                        : ""
                    } 👋`}</h5>
                  </div>
                  <div className="dashbaord_block">
                    <div className="dash_box dash_box1">
                      <p>Total Sales</p>
                      <h3>${totalSale}</h3>
                      <div className="dash_box_btm">
                        <span className="dash_val">
                          {totalSalePer >= 0 ? (
                            <img
                              src="assets/img/dash-box-arrow1.webp"
                              alt="Arrow 1"
                            />
                          ) : (
                            <img
                              src="assets/img/dash-box-arrow2.webp"
                              alt="Arrow 2"
                            />
                          )}{" "}
                          {totalSalePer || 0}%
                          {/* <img src="assets/img/dash-box-arrow1.webp" alt="" />{" "} */}
                          {/* {totalSalePer ? totalSalePer : 0}% */}
                        </span>
                        <Link to="/saledashboard">View Sales</Link>
                      </div>
                    </div>
                    <div className="dash_box dash_box2">
                      <p>Profit</p>
                      <h3>${profit}</h3>
                      <div className="dash_box_btm">
                        <span className="dash_val">
                          {profitPer >= 0 ? (
                            <img
                              src="assets/img/dash-box-arrow1.webp"
                              alt="Arrow 1"
                            />
                          ) : (
                            <img
                              src="assets/img/dash-box-arrow2.webp"
                              alt="Arrow 2"
                            />
                          )}{" "}
                          {profitPer || 0}%
                          {/* <img src="assets/img/dash-box-arrow2.webp" alt="" />{" "} */}
                          {/* {profitPer || 0}% */}
                        </span>
                        <Link to="/saledashboard">View Profit</Link>
                      </div>
                    </div>
                    <div className="dash_box dash_box3">
                      <p>Number of Sales</p>
                      <h3>{NumberSales}</h3>
                      <div className="dash_box_btm">
                        <span className="dash_val">
                          <img src="assets/img/dash-box-arrow3.webp" alt="" />{" "}
                          {NumSalePer || 0}% this week
                        </span>
                        <Link to="/saledashboard">View entire list</Link>
                      </div>
                    </div>
                    <div className="dash_box dash_box4">
                      <p>Number of Services</p>
                      <h3>{NumberServices}</h3>
                      <div className="dash_box_btm">
                        <span className="dash_val">
                          <img src="assets/img/dash-box-arrow4.webp" alt="" />{" "}
                          {NumService || 0}% this week
                        </span>
                        <Link to="/ticketlist">View entire list</Link>
                      </div>
                    </div>
                  </div>
                  <div className="dashboard_store_block">
                    <div className="store_block_left">
                      <img src="assets/img/dash-store-img.webp" alt="" />
                    </div>
                    <div className="store_block_right">
                      <div className="store_content">
                        <img src="assets/img/black-logo.webp" alt="" />
                        <span>Get started with your store!</span>
                      </div>
                      <div className="store_link" onClick={Setting}>
                        <a href="#">Set up Store</a>
                      </div>
                    </div>
                  </div>
                  <div className="clearfix"></div>
                  <div className="dashboard_pages_block">
                    <h4>Pages</h4>
                    <div className="dash_pages_list">
                      <ul>
                        {adminState === 1 || permission?.length === 0 ? (
                          <>
                            <li>
                              <Link to="/saledashboard">
                                <div className="icon_txt">
                                  <div className="page_icon sale_dash_icn">
                                    <img
                                      src="assets/img/dash-home-icon.webp"
                                      alt="Sales Dashboard"
                                    />
                                  </div>
                                  <span>Sales Dashboard</span>
                                </div>
                                <div className="arrowicon">
                                  <img
                                    src="assets/img/arrow-right-icon.svg"
                                    alt=""
                                  />
                                </div>
                              </Link>
                            </li>
                            <li>
                              <Link to="/ticketlist">
                                <div className="icon_txt">
                                  <div className="page_icon repair_icn">
                                    <img
                                      src="assets/img/dash-tool-icon.webp"
                                      alt="Repair"
                                    />
                                  </div>
                                  <span>Repair</span>
                                </div>
                                <div className="arrowicon">
                                  <img
                                    src="assets/img/arrow-right-icon.svg"
                                    alt=""
                                  />
                                </div>
                              </Link>
                            </li>
                            <li>
                              <Link to="/productlist">
                                <div className="icon_txt">
                                  <div className="page_icon product_icn">
                                    <img
                                      src="assets/img/product-icon.svg"
                                      alt="Products"
                                    />
                                  </div>
                                  <span>Products</span>
                                </div>
                                <div className="arrowicon">
                                  <img
                                    src="assets/img/arrow-right-icon.svg"
                                    alt=""
                                  />
                                </div>
                              </Link>
                            </li>
                            <li>
                              <Link to="/devicelist">
                                <div className="icon_txt">
                                  <div className="page_icon devices_icn">
                                    <img
                                      src="assets/img/dash-devices-icon.webp"
                                      alt="Devices"
                                    />
                                  </div>
                                  <span>Devices</span>
                                </div>
                                <div className="arrowicon">
                                  <img
                                    src="assets/img/arrow-right-icon.svg"
                                    alt=""
                                  />
                                </div>
                              </Link>
                            </li>
                            <li>
                              <Link to="/invoicelist">
                                <div className="icon_txt">
                                  <div className="page_icon invoices_icn">
                                    <img
                                      src="assets/img/dash-file-icon.webp"
                                      alt="Invoices"
                                    />
                                  </div>
                                  <span>Invoices</span>
                                </div>
                                <div className="arrowicon">
                                  <img
                                    src="assets/img/arrow-right-icon.svg"
                                    alt=""
                                  />
                                </div>
                              </Link>
                            </li>
                            <li>
                              <Link to="/customerList">
                                <div className="icon_txt">
                                  <div className="page_icon customer_icn">
                                    <img
                                      src="assets/img/dash-users-icon.webp"
                                      alt="Customers"
                                    />
                                  </div>
                                  <span>Customers</span>
                                </div>
                                <div className="arrowicon">
                                  <img
                                    src="assets/img/arrow-right-icon.svg"
                                    alt=""
                                  />
                                </div>
                              </Link>
                            </li>
                            <li>
                              <Link to="/settings">
                                <div className="icon_txt">
                                  <div className="page_icon setting_icn">
                                    <img
                                      src="assets/img/dash-setting-icon.webp"
                                      alt="Settings"
                                    />
                                  </div>
                                  <span>Settings</span>
                                </div>
                                <div className="arrowicon">
                                  <img
                                    src="assets/img/arrow-right-icon.svg"
                                    alt=""
                                  />
                                </div>
                              </Link>
                            </li>
                            <li>
                              <Link to="/devicelist">
                                <div className="icon_txt">
                                  <div className="page_icon inventory_icn">
                                    <img
                                      src="assets/img/dash-invertory-icon.webp"
                                      alt="Invenotry"
                                    />
                                  </div>
                                  <span>Inventory </span>
                                </div>
                                <div className="arrowicon">
                                  <img
                                    src="assets/img/arrow-right-icon.svg"
                                    alt=""
                                  />
                                </div>
                              </Link>
                            </li>
                            <li>
                              <Link to="/reports">
                                <div className="icon_txt">
                                  <div className="page_icon report_icn">
                                    <img
                                      src="assets/img/invoices-icon.svg"
                                      alt="Reports"
                                    />
                                  </div>
                                  <span>Reports</span>
                                </div>
                                <div className="arrowicon">
                                  <img
                                    src="assets/img/arrow-right-icon.svg"
                                    alt=""
                                  />
                                </div>
                              </Link>
                            </li>
                          </>
                        ) : (
                          permission?.map((permission) => (
                            <>
                              <li>
                                {permission?.name === "Sales Dashboard" && (
                                  <Link to="/saledashboard">
                                    <div className="icon_txt">
                                      <div className="page_icon sale_dash_icn">
                                        <img
                                          src="assets/img/dash-home-icon.webp"
                                          alt="Sales Dashboard"
                                        />
                                      </div>
                                      <span>Sales Dashboard</span>
                                    </div>
                                    <div className="arrowicon">
                                      <img
                                        src="assets/img/arrow-right-icon.svg"
                                        alt=""
                                      />
                                    </div>
                                  </Link>
                                )}
                                {permission?.name === "Repair" && (
                                  <Link to="/ticketlist">
                                    <div className="icon_txt">
                                      <div className="page_icon repair_icn">
                                        <img
                                          src="assets/img/dash-tool-icon.webp"
                                          alt="Repair"
                                        />
                                      </div>
                                      <span>Repair</span>
                                    </div>
                                    <div className="arrowicon">
                                      <img
                                        src="assets/img/arrow-right-icon.svg"
                                        alt=""
                                      />
                                    </div>
                                  </Link>
                                )}
                                {permission?.name === "Products" && (
                                  <Link to="/productlist">
                                    <div className="icon_txt">
                                      <div className="page_icon product_icn">
                                        <img
                                          src="assets/img/product-icon.svg"
                                          alt="Products"
                                        />
                                      </div>
                                      <span>Products</span>
                                    </div>
                                    <div className="arrowicon">
                                      <img
                                        src="assets/img/arrow-right-icon.svg"
                                        alt=""
                                      />
                                    </div>
                                  </Link>
                                )}
                                {permission?.name === "Devices" && (
                                  <Link to="/devicelist">
                                    <div className="icon_txt">
                                      <div className="page_icon devices_icn">
                                        <img
                                          src="assets/img/dash-devices-icon.webp"
                                          alt="Devices"
                                        />
                                      </div>
                                      <span>Devices</span>
                                    </div>
                                    <div className="arrowicon">
                                      <img
                                        src="assets/img/arrow-right-icon.svg"
                                        alt=""
                                      />
                                    </div>
                                  </Link>
                                )}
                                {permission?.name === "Invoices" && (
                                  <Link to="/invoicelist">
                                    <div className="icon_txt">
                                      <div className="page_icon invoices_icn">
                                        <img
                                          src="assets/img/dash-file-icon.webp"
                                          alt="Invoices"
                                        />
                                      </div>
                                      <span>Invoices</span>
                                    </div>
                                    <div className="arrowicon">
                                      <img
                                        src="assets/img/arrow-right-icon.svg"
                                        alt=""
                                      />
                                    </div>
                                  </Link>
                                )}
                                {permission?.name === "Customers" && (
                                  <Link to="/customerList">
                                    <div className="icon_txt">
                                      <div className="page_icon customer_icn">
                                        <img
                                          src="assets/img/dash-users-icon.webp"
                                          alt="Customers"
                                        />
                                      </div>
                                      <span>Customers</span>
                                    </div>
                                    <div className="arrowicon">
                                      <img
                                        src="assets/img/arrow-right-icon.svg"
                                        alt=""
                                      />
                                    </div>
                                  </Link>
                                )}
                                {permission?.name === "Settings" && (
                                  <Link to="/settings">
                                    <div className="icon_txt">
                                      <div className="page_icon setting_icn">
                                        <img
                                          src="assets/img/dash-setting-icon.webp"
                                          alt="Settings"
                                        />
                                      </div>
                                      <span>Settings</span>
                                    </div>
                                    <div className="arrowicon">
                                      <img
                                        src="assets/img/arrow-right-icon.svg"
                                        alt=""
                                      />
                                    </div>
                                  </Link>
                                )}
                                {permission?.name === "Inventory Reports" && (
                                  <Link to="devicelist">
                                    <div className="icon_txt">
                                      <div className="page_icon inventory_icn">
                                        <img
                                          src="assets/img/dash-invertory-icon.webp"
                                          alt="Invenotry"
                                        />
                                      </div>
                                      <span>Inventory</span>
                                    </div>
                                    <div className="arrowicon">
                                      <img
                                        src="assets/img/arrow-right-icon.svg"
                                        alt=""
                                      />
                                    </div>
                                  </Link>
                                )}
                                {permission?.name === "Reports" && (
                                  <Link to="/reports">
                                    <div className="icon_txt">
                                      <div className="page_icon report_icn">
                                        <img
                                          src="assets/img/invoices-icon.svg"
                                          alt="Reports"
                                        />
                                      </div>
                                      <span>Reports</span>
                                    </div>
                                    <div className="arrowicon">
                                      <img
                                        src="assets/img/arrow-right-icon.svg"
                                        alt=""
                                      />
                                    </div>
                                  </Link>
                                )}
                              </li>
                            </>
                          ))
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="dashboard_right">
                  <div className="dash_calendar">
                    <div className="title">
                      <h4>Calendar</h4>
                      {/* <a href="#">
                    Create New <i className="fa fa-plus"></i>
                  </a> */}
                    </div>
                    <div className="calendar_layout">
                      <div
                        id="custom_calendar"
                        className="custom_datepicker"
                      ></div>
                    </div>
                  </div>
                  <div className="dash_services">
                    <div className="title">
                      <h4>New Services</h4>
                      <div className="service_dropdown">
                        <select
                          className="cus_select"
                          onChange={(e) =>
                            handleTimeRangeChange(e.target.value)
                          }
                        >
                          <option value="Today">Today</option>
                          <option value="Yesterday">Yesterday</option>
                          <option value="Week">Week</option>
                        </select>
                        <img src="assets/img/dropdown-grey-arrow.webp" alt="" />
                      </div>
                    </div>
                    <div className="dash_serv_list">
                      {TicketData?.length > 0 ? (
                        <>
                          {TicketData?.map((item, index) => (
                            <>
                              <div
                                key={item?.ticket?.id}
                                className={`serv_column lightgreen ${
                                  item?.ticket?.id === activeTicketId
                                    ? "active"
                                    : ""
                                }`}
                                onClick={() =>
                                  handleTicketClick(item?.ticket?.id)
                                }
                              >
                                <div className="name_small">
                                  <span>
                                    {getInitials(
                                      item?.ticket?.customer?.firstName,
                                      item?.ticket?.customer?.lastName
                                    )}
                                  </span>
                                </div>
                                <div className="name_servname">
                                  <h5>
                                    {item?.ticket?.customer?.firstName}{" "}
                                    {item?.ticket?.customer?.lastName}
                                  </h5>
                                  <span>
                                    {item?.ticket?.customerDevice?.model}
                                  </span>
                                </div>
                                <div className="serv_time">
                                  <span>
                                    {" "}
                                    {moment(item?.ticket?.timeZone).format(
                                      "LT"
                                    )}
                                  </span>
                                </div>
                              </div>
                            </>
                          ))}
                        </>
                      ) : (
                        <>
                          <h6>No Data Available</h6>
                        </>
                      )}
                    </div>
                    <div className="clearfix"></div>
                    {TicketData.map(
                      (item) =>
                        item?.ticket?.id === activeTicketId && (
                          <div
                            className="dash_serv_detail"
                            key={item?.ticket?.id}
                          >
                            <h4>Details</h4>
                            <div className="inner_details">
                              <div className="serv_detail_info">
                                <div className="name_small lightgreen">
                                  <span>
                                    {getInitials(
                                      item?.ticket?.customer?.firstName,
                                      item?.ticket?.customer?.lastName
                                    )}
                                  </span>
                                </div>
                                <div className="name_servopened">
                                  <h5>
                                    {item?.ticket?.customer?.firstName}{" "}
                                    {item?.ticket?.customer?.lastName}
                                  </h5>
                                  <span>
                                    {item?.ticket?.statusOption?.name}{" "}
                                    {moment(item?.ticket?.timeZone).format(
                                      "MMM Do, YYYY, h:mmA"
                                    )}
                                  </span>
                                </div>
                                <div className="serv_action">
                                  <a href="#">
                                    <img
                                      src="assets/img/serv-detail-icon.webp"
                                      alt=""
                                    />
                                  </a>
                                </div>
                              </div>
                              <div className="clearfix"></div>
                              <div className="serv_detail_content">
                                <ul>
                                  <li>
                                    <span>Ticket #</span>
                                    <a href="#">#{item?.ticket?.id}</a>
                                  </li>
                                  <li>
                                    <span>Device</span>{" "}
                                    {item?.ticket?.brand?.name
                                      ? `${item?.ticket?.brand?.name} `
                                      : ""}
                                    {item?.ticket?.customerDevice?.model}
                                  </li>
                                  <li>
                                    <span>Service</span>
                                    {item?.ticket?.service1?.name}
                                  </li>
                                  <li>
                                    <span>Due</span>
                                    {item?.ticket?.dueDate &&
                                    moment(item.ticket.dueDate).isValid()
                                      ? moment(item.ticket.dueDate).format(
                                          "MMM Do, YYYY, h:mm a"
                                        )
                                      : "--"}
                                    {/* {moment(item?.ticket?.dueDate).format(
                                      "MMM Do, YYYY, h:mm a"
                                    )} */}
                                  </li>
                                  <li>
                                    <span>Description</span>
                                    {item?.ticket?.description || "--"}
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        )
                    )}
                  </div>
                </div>
              </div>
              <div className="clearfix"></div>
            </div>
          </div>

          <Footer />
        </div>
      </div>
    </>
  );
};
export default Dashboard;
