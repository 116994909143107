import React, { useEffect, useState, useRef } from "react";
import SideUl from "../MainDashboard/SideUl";
import MainSidebar from "../MainDashboard/MainSidebar";
import { Link, useNavigate, useLocation } from "react-router-dom";
import {
  AddBrandApi,
  AddCustomerDeviceApi,
  AddServiceApi,
  GetAllServiceApi,
  GetAllSubUserApi,
  GetLabelRecieptApi,
  GetStatusApi,
  baseurl,
  customerAddApi,
  uploadImageApi,
} from "../Utility/Api";
import Swal from "sweetalert2";
import moment from "moment";
import TicketDeviceModal from "../MainDashboard/TicketDeviceModal";
import TicketCash from "./TicketCash";
import "react-phone-number-input/style.css";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import FreeTrail from "../MainDashboard/FreeTrail";

const TicketDetail = () => {
  const [make, setMake] = useState("");
  const [model, setModel] = useState("");
  const [service, setService] = useState("");
  const [dueDate, setDueDate] = useState("");
  const [techAssigned, setTechAssigned] = useState("");
  const [salesPerson, setSalesPerson] = useState("");
  const [binLocation, setBinLocation] = useState("");
  const [ticketId, setTicketId] = useState("");
  const [address, setAddress] = useState("");
  const [imeiNumber, setIMEINumber] = useState("");
  const [password, setPassword] = useState("");
  const [driverLiecence, setDriverLiecence] = useState("");
  const [dateCreated, setDateCreated] = useState("");
  const [LastDate, setLastDate] = useState("");
  const [description, setDescription] = useState("");
  let location = useLocation();
  const [selectedStatus, setSelectedStatus] = useState("Select");
  const [selectedStatus1, setSelectedStatus1] = useState("Select");
  const [selectedStatusId, setSelectedStatusId] = useState("");
  const [customerData, setCustomerData] = useState({});
  const [searchTerm1, setSearchTerm1] = useState("");
  const [AllBrand1, setAllBrand1] = useState([]);
  const [typedText, setTypedText] = useState("");
  const [selectedBrand1, setSelectedBrand1] = useState(null);
  const [selectedBrandId1, setSelectedBrandId1] = useState(null);
  const [showList1, setShowList1] = useState(false);
  const [showListService, setShowListService] = useState(false);
  const [showListService1, setShowListService1] = useState(false);
  const [selectedService, setSelectedService] = useState("");
  const [selectedService1, setSelectedService1] = useState("");
  const [selectedServiceId, setSelectedServiceId] = useState("");
  const [selectedServiceId1, setSelectedServiceId1] = useState("");
  const [searchTermService, setSearchTermService] = useState("");
  const [searchTermService1, setSearchTermService1] = useState("");
  const [salesPersonId, setSalesPersonId] = useState("");
  const [allService, setAllService] = useState([]);
  const [allService1, setAllService1] = useState([]);
  const [otherInfo, setOtherInfo] = useState("");
  const [showListDevice, setShowListDevice] = useState(false);
  const [selectedDeviceId, setSelectedDeviceId] = useState(null);
  const [selectedDevice, setSelectedDevice] = useState("");
  const [allcustomerDevice, setAllCustomerDevice] = useState([]);
  const [adminState, setAdminState] = useState("");
  const [formDate, setFormDate] = useState("");
  const [formDate1, setFormDate1] = useState("");
  const [allSubuser, setAllSubuser] = useState([]);
  const [selectedTech, setSelectedTech] = useState(null);
  const [selectedTechId1, setSelectedTechId1] = useState(null);
  const [showListTech, setShowListTech] = useState(false);
  const [searchTermTech, setSearchTermTech] = useState("");
  const [searchTermDevice, setSearchTermDevice] = useState("");
  const [showModal1, setShowModal1] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [images, setImages] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [images1, setImages1] = useState("");
  const [email, setEmail] = useState("");
  const [country, setCountry] = useState("");
  const [city, SetCity] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [state, setState] = useState("");
  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [validstate, setValidState] = useState(false);
  const [imageMsg, setImageMsg] = useState("");
  const [countrycode, setCountryCode] = useState({});
  const [selectedColor, setSelectedColor] = useState("Select");
  const [errormsg, setErrormsg] = useState("");
  const [selectedOption, setSelectedOption] = useState({
    customerPhone: 0,
    customerEmail: 0,
    none: 0,
  });
  const [customerDetails, setCustomerDetails] = useState({
    imei: 0,
    dueDate: 0,
    service: 0,
    brand: 0,
    barcode: 0,
    model: 0,
    password: 0,
  });

  const [width, setWidth] = useState();
  const [height, setHeight] = useState();

  const [description1, setDescription1] = useState("");
  const [Status, setStatus] = useState([]);
  const [statusColors, setStatusColors] = useState({});
  const inputRef5 = useRef(null);
  const inputRef6 = useRef(null);
  const dropdownRef5 = useRef(null);
  const dropdownRef6 = useRef(null);
  const inputRef1 = useRef(null);
  const inputRef2 = useRef(null);
  const dropdownRef1 = useRef(null);
  const dropdownRef2 = useRef(null);
  const inputRef3 = useRef(null);
  const dropdownRef3 = useRef(null);
  const [unit, setUnit] = useState("");
  const [techpersonsub, setTechPersonSub] = useState("");

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef5.current &&
        !dropdownRef5.current.contains(event.target)
      ) {
        setShowList1(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef5]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef2.current &&
        !dropdownRef2.current.contains(event.target)
      ) {
        setShowListService(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef2]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef3.current &&
        !dropdownRef3.current.contains(event.target)
      ) {
        setShowListService1(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef3]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef6.current &&
        !dropdownRef6.current.contains(event.target)
      ) {
        setShowListDevice(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef6]);

  const handleStatusSelect = (status) => {
    setSelectedStatus(status?.name);
    setSelectedColor(status?.color);
    setSelectedStatusId(status?.id);
    EditTicket1(status?.id);
  };

  const handleDueDateChange = (event) => {
    setDueDate(event.target.value);
  };

  const handleTechAssignedChange = (event) => {
    setTechAssigned(event.target.value);
  };

  const handleSalesPersonChange = (event) => {
    setSalesPerson(event.target.value);
  };

  const handleBinLocationChange = (event) => {
    setBinLocation(event.target.value);
  };

  const handleMakeChange = (event) => {
    setMake(event.target.value);
  };

  const handleModelChange = (event) => {
    setModel(event.target.value);
  };

  const handleServiceChange = (event) => {
    setService(event.target.value);
  };

  const GetAllSubUser = () => {
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(GetAllSubUserApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === true) {
          setAllSubuser(result?.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const GetCustomerDevice = (id) => {
    let local = localStorage.getItem("token");

    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${baseurl}getAllCustomerDevice?customerId=${id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setAllCustomerDevice(result?.data.reverse());
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    GetAllStatus();
    let localAdmin = localStorage.getItem("admin");
    // GetReciept();
    GetCustomerId();
    setAdminState(localAdmin);
    GetAllBrandDevice();
    GetAllService();
    GetAllSubUser();
    GetAllService1();
    let data = location?.state?.data;
    GetCustomerDevice(data?.ticket?.customer?.id);
    console.log("ticketdetail", data);

    setModel(data?.ticket?.customerDevice?.model);
    setSelectedDevice(data?.ticket?.customerDevice?.model);
    setSelectedDeviceId(data?.ticket?.customerDevice?.id);
    setMake(data?.ticket?.customerDevice?.model);
    setSelectedService(data?.ticket?.service1?.name);
    setSelectedServiceId(data?.ticket?.service1?.id);
    setSelectedService1(data?.ticket?.service2?.name);
    setSelectedServiceId1(data?.ticket?.service2?.id);
    setTicketId(data?.ticket?.id);
    setFirstName(data?.ticket?.customer?.firstName);
    setEmail(data?.ticket?.customer?.email);
    setPhoneNo(data?.ticket?.customer?.phoneNumber);
    setLastName(data?.ticket?.customer?.lastName);
    setAddress(data?.ticket?.customer?.streetAddress);
    setIMEINumber(data?.ticket?.customerDevice?.imeiNumber);
    setPassword(data?.ticket?.password);
    setDriverLiecence(data?.ticket?.drivingLicence);
    setBinLocation(data?.ticket?.bin);
    setDueDate(data?.ticket?.dueDate);
    if (data?.salesPerson?.name || data?.techPerson?.name) {
      setSalesPerson(data?.salesPerson?.name);
      setTechPersonSub(data?.techPerson?.name);
      setTechAssigned(data?.techPerson?.name);
    } else {
      setSalesPerson(data?.salesPerson?.userName);
      setTechAssigned(data?.techPerson?.userName);
    }
    setSalesPersonId(data?.salesPerson?.id);

    setCustomerData(data?.ticket?.customer);

    setSelectedBrand1(data?.ticket?.brand?.name);
    setSelectedBrandId1(data?.ticket?.brand?.id);
    setDateCreated(data?.ticket?.timeZone);
    setLastDate(data?.ticket?.updatedTimeZone);
    setSelectedTech(data?.techPerson?.userName);
    setSelectedTechId1(data?.techPerson?.id);
    setImages(data?.ticket?.customer?.profileImage);
    SetCity(data?.ticket?.customer?.city);
    setCountry(data?.ticket?.customer?.country);
    setState(data?.ticket?.customer?.state);
    setZipcode(data?.ticket?.customer?.zipCode);
    setDescription(data?.ticket?.description);
    setImages1(data?.ticket?.customerDevice?.image);
    setSelectedStatusId(data?.ticket?.statusOption?.id);
    setSelectedStatus(data?.ticket?.statusOption?.name);
    setSelectedStatus1(data?.ticket?.statusOption?.name);
    setSelectedColor(data?.ticket?.statusOption?.color);
    const dateString = data?.ticket?.timeZone;

    // Parse the date string using Moment.js and set the timezone
    const date = moment(dateString).tz(moment.tz.guess());

    // Format the date in the desired format
    const formattedDate = date.format("ddd, MMM DD, YYYY, h:mma");
    setFormDate(formattedDate);
    const dateString1 = data?.ticket?.updatedTimeZone;
    // Parse the date string using Moment.js and set the timezone
    const date1 = moment(dateString1).tz(moment.tz.guess());

    // Format the date in the desired format
    const formattedDate1 = date1.format("ddd, MMM DD, YYYY, h:mma");
    setFormDate1(formattedDate1);
  }, []);

  function formatPhoneNumber(phoneNumber) {
    // Check if phoneNumber is undefined or null
    if (!phoneNumber) {
      return ""; // Return an empty string or handle the case as needed
    }

    // Remove all non-digit characters from the phone number
    const digitsOnly = phoneNumber.replace(/\D/g, "");

    // Check if the phone number has a country code
    if (digitsOnly.length > 10) {
      // Extract the country code and phone number without the country code
      const countryCode = digitsOnly.slice(0, digitsOnly.length - 10);
      const phoneNumberWithoutCode = digitsOnly.slice(-10);

      // Format the phone number with the country code
      return `(+${countryCode})${phoneNumberWithoutCode}`;
    } else {
      // If the phone number doesn't have a country code, format it as a regular 10-digit number
      const areaCode = digitsOnly.slice(0, 3);
      const firstPart = digitsOnly.slice(3, 6);
      const secondPart = digitsOnly.slice(6);

      return `(${areaCode})${firstPart}-${secondPart}`;
    }
  }

  const handleSearchChange1 = (e) => {
    setSearchTerm1(e.target.value);
    setTypedText(e.target.value);
    setSelectedBrand1(null);
  };

  const handleAddNewClickDevice = (e) => {
    if (!typedText.trim()) {
      Swal.fire({
        title: "Please enter a valid Brand name!",
        customClass: {
          container: "custom-swal-container",

          confirmButton: "custom-swal-confirm-button",
        },
      });
      return;
    }

    setSelectedBrand1(typedText);
    setShowList1(false);
    setSearchTerm1("");
    setTypedText("");
    AddBrandDevice();
  };
  const handleBrandSelect1 = (brand) => {
    setSelectedBrand1(brand.name);
    setSelectedBrandId1(brand.id);
    setShowList1(false);
  };
  const filteredBrands1 = AllBrand1.filter((brand) => {
    const name = brand.name;
    if (name && typeof name === "string") {
      return name.toLowerCase().includes(searchTerm1.toLowerCase());
    }

    return false;
  });

  const AddBrandDevice = () => {
    let local = localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);
    myHeaders.append(
      "Cookie",
      "connect.sid=s%3AEXeh7T29UatkCPBdNwoIzBrcxDT_mEJd.RTtxRKFGO9m4w4ldAFV3Z016ghUV6rHgNPsCPhrrqgQ"
    );

    var raw = JSON.stringify({
      name: typedText,
      productCategoryId: 2,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(AddBrandApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === true) {
          GetAllBrandDevice();
          setSelectedBrandId1(result?.data?.id);
          setSearchTerm1("");
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const GetAllBrandDevice = () => {
    let local = localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);
    myHeaders.append(
      "Cookie",
      "connect.sid=s%3AEXeh7T29UatkCPBdNwoIzBrcxDT_mEJd.RTtxRKFGO9m4w4ldAFV3Z016ghUV6rHgNPsCPhrrqgQ"
    );

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${baseurl}getAllBrand?productCategoryId=2`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setAllBrand1(result?.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const AddService = () => {
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);

    const raw = JSON.stringify({
      name: typedText,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(AddServiceApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === true) {
          setSelectedService(result?.data?.name);
          setSelectedServiceId(result?.data?.id);
          GetAllService();
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const GetAllService = () => {
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(GetAllServiceApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setAllService(result?.data.reverse());
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const filteredService = allService.filter((brand) => {
    const name = brand.name;
    if (name && typeof name === "string") {
      return name.toLowerCase().includes(searchTermService.toLowerCase());
    }

    return false;
  });
  const navigate = useNavigate();
  const handleServiceSelect = (brand) => {
    setSelectedService(brand.name);
    setSelectedServiceId(brand.id);
    setShowListService(false);
    // if (error.value) {
    //   setError({ ...error, value: "" });
    // }
  };
  const handleSearchChangeService = (e) => {
    setSearchTermService(e.target.value);
    setTypedText(e.target.value);
    setSelectedService(null);
  };
  const handleButtonClickService = (e) => {
    e.preventDefault();
    setShowListService(!showListService);
  };

  const handleAddNewClickService = (e) => {
    if (!typedText.trim()) {
      Swal.fire({
        title: "Please enter a valid Service name!",
        customClass: {
          container: "custom-swal-container",

          confirmButton: "custom-swal-confirm-button",
        },
      });
      return;
    }
    setSelectedService(typedText);
    setShowListService(false);
    setSearchTermService("");
    setTypedText("");
    AddService();
  };

  const AddService1 = () => {
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);

    const raw = JSON.stringify({
      name: typedText,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(AddServiceApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === true) {
          setSelectedService1(result?.data?.name);
          setSelectedServiceId1(result?.data?.id);
          GetAllService1();
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const GetAllService1 = () => {
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(GetAllServiceApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setAllService1(result?.data.reverse());
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleImages1 = (e) => {
    var file = e.target.files[0];

    if (file) {
      const minSizeInBytes = 5000; // 5 KB
      const maxSizeInBytes = 1024 * 1024; // 1 MB

      if (file.size < minSizeInBytes) {
        setImageMsg("Image size is below the minimum allowed size (5 KB)");
        e.target.value = null;
        return;
      }

      let size = file.type.replace("/", ".");
      var blob = file.slice(0, file.size, file.type);
      const newName = new Date().getTime() + size;
      var newFile = new File([blob], newName, { type: file.type });

      UploadImage1(newFile);
    }
  };

  const UploadImage1 = async (newFile) => {
    setLoading(true);
    var formdata = new FormData();
    formdata.append("file", newFile);
    formdata.append("type", "ticket-images");

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(uploadImageApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log(result);
        setImages1(result?.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log("error", error);
        setLoading(false);
      });
  };

  const filteredService1 = allService1.filter((brand) => {
    const name = brand.name;
    if (name && typeof name === "string") {
      return name.toLowerCase().includes(searchTermService.toLowerCase());
    }

    return false;
  });

  const handleServiceSelect1 = (brand) => {
    setSelectedService1(brand.name);
    setSelectedServiceId1(brand.id);
    setShowListService1(false);
  };
  const handleSearchChangeService1 = (e) => {
    setSearchTermService1(e.target.value);
    setTypedText(e.target.value);
    setSelectedService1(null);
  };
  const handleButtonClickService1 = (e) => {
    e.preventDefault();
    setShowListService1(!showListService);
  };

  const handleAddNewClickService1 = (e) => {
    if (!typedText.trim()) {
      Swal.fire({
        title: "Please enter a valid Service name!",
        customClass: {
          container: "custom-swal-container",

          confirmButton: "custom-swal-confirm-button",
        },
      });
      return;
    }
    setSelectedService1(typedText);
    setShowListService1(false);
    setSearchTermService1("");
    setTypedText("");
    AddService1();
  };

  const EditTicket = (id) => {
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);

    const raw = JSON.stringify({
      customerId: customerData?.id,
      customerDeviceId: selectedDeviceId ? selectedDeviceId : null,
      brandId: selectedBrandId1 ? selectedBrandId1 : null,
      serviceId1: selectedServiceId ? selectedServiceId : null,
      serviceId2: selectedServiceId1 ? selectedServiceId1 : null,
      statusOptionId: selectedStatusId,
      techAssignedId: selectedTechId1,
      salesPersonId: salesPersonId,
      dueDate: dueDate,
      password: password,
      description: description,
      drivingLicence: driverLiecence,
      bin: binLocation,
      isAdmin: adminState,
      isTechAdmin: adminState,
      isSalesPersonAdmin: adminState,
      isInvoice: 1,
      invoiceId: id,
      techAssignedName: techAssigned,
    });

    const requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    console.log("rwa editticket", raw);
    fetch(`${baseurl}updateTicket/${ticketId}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === true) {
          // navigate("/ticketlist");
          document
            .querySelectorAll(".modal-backdrop")
            .forEach((el) => el.classList.remove("modal-backdrop"));
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const filteredTech = allSubuser.filter((brand) => {
    const name = brand.name;
    if (name && typeof name === "string") {
      return name.toLowerCase().includes(searchTermTech.toLowerCase());
    }

    return false;
  });
  const handleServiceTech = (brand) => {
    setSelectedTech(brand.name);
    setSelectedTechId1(brand.id);
    setShowListTech(false);
  };
  const handleSearchChangeTech = (e) => {
    setSearchTermTech(e.target.value);
    setTypedText(e.target.value);
    setSelectedTech(null);
  };
  const formattedDueDate = dueDate ? dueDate.split("T")[0] : "";
  const filteredDevice = allcustomerDevice.filter((brand) => {
    const name = brand.model;
    if (name && typeof name === "string") {
      return name.toLowerCase().includes(searchTermDevice.toLowerCase());
    }

    return false;
  });

  const handleButtonClickDevice = (e) => {
    e.preventDefault();
    setShowListDevice(!showListDevice);
  };

  const handleSearchChangeDevice = (e) => {
    setSearchTermDevice(e.target.value);
    setTypedText(e.target.value);
    setSelectedDevice(null);
  };
  const handleBrandSelectDevice = (brand) => {
    setSelectedDevice(brand.model);
    setSelectedDeviceId(brand.id);
    setShowListDevice(false);
  };
  const onpress = () => {
    setShowModal1(false);
  };

  const isDisposableEmail = (email) => {
    const domain = email.split("@")[1];
    return disposableEmailDomains.includes(domain);
  };

  const disposableEmailDomains = [
    "gmail.com",
    "yahoo.com",
    "outlook.com",
    "hotmail.com",
    "aol.com",
    "icloud.com",
    "mail.com",
    "yandex.com",
    "protonmail.com",
    "zoho.com",
    "gmx.com",
    "ymail.com",
    "comcast.net",
    "me.com",
    "msn.com",
    "live.com",
    "sbcglobal.net",
    "verizon.net",
    "att.net",
    "cox.net",
    "smartitventures.com",
    // Add more domains as needed
  ];

  const Validation = () => {
    let error = {};
    let reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+*!=])(?=.*\d).{6,}$/;
    // const isValid = /^[0-9]+$/.test(value);
    const regex = /^[A-Za-z ]+$/;

    const isNumeric = /^[0-9]+$/;
    // if (!email) {
    //   error.email = "Email is required.";
    // } else
    if (email && !reg.test(email)) {
      error.email = "Invalid email.";
    } else if (email && !isDisposableEmail(email)) {
      error.email =
        "Please use a valid email address. Temporary email addresses are not allowed.";
    }
    if (!firstName) {
      error.firstName = "First Name Is Required";
    } else if (!regex.test(firstName)) {
      error.firstName = "First Name Is Invalid";
    }
    // if (!phoneNo) {
    //   error.phoneNo = "Phone Number Is Required";
    // }

    if (Object.keys(error).length === 0) {
      // console.log(Object.keys(error).length);
      CustomerApi();
    }
    return error;
  };

  const CustomerApi = () => {
    document
      .querySelectorAll(".modal-backdrop")
      .forEach((el) => el.classList.remove("modal-backdrop"));
    let local = localStorage.getItem("token");
    let userid = localStorage.getItem("userId");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);
    myHeaders.append(
      "Cookie",
      "connect.sid=s%3AyaHFql-YNXNgdQlka4nRvpWL-iJLSdB-.%2B6wW6d812YXYU4ihAgIwRTFu54mu6VtFKEcdK50W57A"
    );

    var raw = JSON.stringify({
      firstName: firstName,
      lastName: lastName,
      email: email,
      phoneNumber: phoneNo, // phoneNo ? phoneNo.replace(/^\+/, "") : "",
      profileImage: images,
      country: country,
      countryCode: String(countrycode.country_code),
      streetAddress: address,
      state: state,
      zipCode: zipcode,
      city: city,
      storeCredit: 32.23,
      account: 10.23,
      mainUserId: userid,
    });

    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(`${baseurl}updateCustomerProfile/${customerData?.id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true) {
          setShowModal(false);
          GetCustomerId(result?.data?.id);
          setMessage(result?.message);
          setFirstName("");
          setLastName("");
          setImages("");
          setAddress("");
          setPhoneNo("");
          setCountry("");
          SetCity("");
          setZipcode("");
          setState("");
          setEmail("");
          setMessage("");
          setValidState(false);
        } else {
          setMessage(result?.message);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const SubmitCus = () => {
    setError(Validation());
  };

  const handleImages = (e) => {
    var file = e.target.files[0];

    if (file) {
      const minSizeInBytes = 5000; // 5 KB
      const maxSizeInBytes = 1024 * 1024; // 1 MB

      if (file.size < minSizeInBytes) {
        setImageMsg("Image size is below the minimum allowed size (5 KB)");
        e.target.value = null;
        return;
      }

      let size = file.type.replace("/", ".");
      var blob = file.slice(0, file.size, file.type);
      const newName = new Date().getTime() + size;
      var newFile = new File([blob], newName, { type: file.type });

      UploadImage(newFile);
    }
  };

  const UploadImage = async (newFile) => {
    setLoading(true);
    var formdata = new FormData();
    formdata.append("file", newFile);
    formdata.append("type", "customers-images");

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(uploadImageApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setImages(result?.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log("error", error);
        setLoading(false);
      });
  };
  const handleModalClose = () => {
    setShowModal(false);
  };

  const handlePhoneChange = (value) => {
    setPhoneNo(value);
    const countryInfo = value && value.match(/^\+(\d+)/);
    const countryCode = countryInfo ? `+${countryInfo[1]}` : "";
  };

  const HandleDelete = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete image",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#c76cff",
      cancelButtonColor: "#efefef",
      confirmButtonText: "Yes, delete it!",
      confirmButtonText: "<span style='color: white;'>Yes, delete it!</span>",
      cancelButtonText: "<span style='color: black;'>cancel!</span>",
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      customClass: {
        container: "sweet-alert2-container-zindex",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Deleted!",
          text: "Your file has been deleted.",
          icon: "success",
          confirmButtonColor: "#c76cff",
          customClass: {
            container: "sweet-alert2-container-zindex",
          },
        }).then(() => {
          setImages("");
        });
      }
    });
  };

  const HandleDelete1 = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete image",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#c76cff",
      cancelButtonColor: "#efefef",
      confirmButtonText: "Yes, delete it!",
      confirmButtonText: "<span style='color: white;'>Yes, delete it!</span>",
      cancelButtonText: "<span style='color: black;'>cancel!</span>",
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      customClass: {
        container: "sweet-alert2-container-zindex",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Deleted!",
          text: "Your file has been deleted.",
          icon: "success",
          confirmButtonColor: "#c76cff",
          customClass: {
            container: "sweet-alert2-container-zindex",
          },
        }).then(() => {
          setImages1("");
        });
      }
    });
  };

  useEffect(() => {
    // GetCustomer();
    // fetch(
    //   "https://ip-api.io/api/json?api_key=fabc32c1-943d-43c7-8a13-ea82cd6ac052"
    // )
    fetch(
      "https://ip-api.io/api/json?api_key=fabc32c1-943d-43c7-8a13-ea82cd6ac052"
    )
      .then((response) => response.json())
      .then((json) => {
        setCountryCode(json);
      });
  }, []);

  const GetCustomerId = (id) => {
    let local = localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${baseurl}getCustomerProfile/${id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === true) {
          setFirstName(result?.data?.firstName);
          setLastName(result?.data?.lastName);
          setPhoneNo(result?.data?.phoneNumber);
          setEmail(result?.data?.email);
          setAddress(result?.data?.streetAddress);
          setCountry(result?.data?.country);
          SetCity(result?.data?.city);
          setZipcode(result?.data?.zipCode);
          setState(result?.data?.state);
          setImages(result?.data?.profileImage);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const Validate = () => {
    let error = {};
    // if (!selectedDevice) {
    //   error.selectedDevice = "Model Name Is Required";
    // } else if (selectedDevice.trim().length === 0) {
    //   error.selectedDevice = "Model NAME Is Required";
    // }
    if (Object.keys(error).length === 0) {
      // console.log(Object.keys(error).length);
      CustomerDevice();
    }
    return error;
  };

  const OnSubmit = () => {
    setError(Validate());
  };

  const maxDescriptionLength = 200;
  const handleDescriptionChange1 = (e) => {
    const newDescription = e.target.value;

    if (newDescription.length <= maxDescriptionLength) {
      setDescription1(newDescription);
      setErrormsg("");
    } else if (newDescription.length > maxDescriptionLength) {
      setTimeout(() => {
        setErrormsg("Allow Maximum 200 Characters");
      }, 2000);
    }
  };
  const CustomerDevice = () => {
    let local = localStorage.getItem("token");
    document
      .querySelectorAll(".modal-backdrop")
      .forEach((el) => el.classList.remove("modal-backdrop"));
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);

    const raw = JSON.stringify({
      brandId: selectedBrandId1 ? selectedBrandId1 : null,
      description: description1,
      imeiNumber: imeiNumber,
      customerId: customerData?.id,
      model: model,
      image: images1,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(AddCustomerDeviceApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === true) {
          GetCustomerDevice(customerData?.id);
          setSelectedDevice(result?.data?.model);
          setSelectedDeviceId(result?.data?.id);
          setImages1(result?.data?.image);
          setShowModal1(false);
          //  props.onpress(false);
          //  props.onload(result?.data?.id);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const GetAllStatus = () => {
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(GetStatusApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        const sortedStatusList = result?.data.sort((a, b) => {
          if (a?.name === "New") return -1;
          if (b?.name === "New") return 1;

          if (a?.name === "Finished") return 1;
          if (b?.name === "Finished") return -1;
          return a.name.localeCompare(b.name);
        });
        setStatus(sortedStatusList);

        // Initialize statusColors state with default colors
        const defaultColors = result?.data.reduce((acc, curr) => {
          acc[curr.id] = "#ffffff"; // You can set a default color here
          return acc;
        }, {});
        setStatusColors(defaultColors);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const [isFreeTrial, setIsFreeTrial] = useState(false);

  useEffect(() => {
    const substatus = localStorage.getItem("substatus");
    const planDate = localStorage.getItem("planDate");

    if (
      (substatus === "null" ||
        substatus === "canceled" ||
        substatus === "expired") &&
      new Date(localStorage.getItem("planDate")) > new Date()
    ) {
      setIsFreeTrial(true);
    }
  }, []);

  const printSetup = () => {
    Swal.fire("You have not  Setup Receipt Configuration ");
  };
  const printTable = () => {
    const printContents =
      document.querySelector(".recieptlabelprint").innerHTML;
    const originalContents = document.body.innerHTML;
    const printWindow = window.open("", "_blank");
    printWindow.document.open();
    printWindow.document.write(`
    <html>
     <head>
     <style>
     @media print {
  @page {
    margin: 0; /* Remove default margins to ensure there's no space at the top and bottom */
  }
  body {
    margin: 0; /* Remove margins on body as well */
   
  }
  /* Hide browser print headers and footers */
  header,
  footer {
    display: none;
  }
}
     </style>

     </head>
      <body>${printContents}</body>
    </html>
  `);
    printWindow.document.close();
    printWindow.print();
    printWindow.close();
  };

  const GetReciept = () => {
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(GetLabelRecieptApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === true) {
          if (!result?.data) {
            //setStates(true);
          } else {
            const data = result?.data;

            let { height, width, unit } = data;
            if (unit === "Inches") {
              height = convertInchesToMm(height);
              width = convertInchesToMm(width);
            }
            setHeight(height);
            setWidth(width);

            setUnit("mm");
            // setHeight(result?.data?.height);
            // setWidth(result?.data?.width);
            // setUnit(result?.data?.unit);
            const parsedItems = JSON.parse(data.items);
            const parsedCustomer = JSON.parse(data.customer);

            setSelectedOption({
              customerPhone: parsedCustomer.phone === 1 ? 1 : 0,
              customerEmail: parsedCustomer.email === 1 ? 1 : 0,
              none: parsedCustomer.none === 1 ? 1 : 0,
            });

            setCustomerDetails({
              imei: parsedItems.imei,
              dueDate: parsedItems.dueDate,
              service: parsedItems.service,
              brand: parsedItems.brand,
              barcode: parsedItems.barcode,
              model: parsedItems.model,
              password: parsedItems.password,
            });
            setTimeout(() => {
              printTable();
            }, 1100);
          }
        } else {
          Swal.fire(result?.message);
          navigate("/settings", { state: { activeState: 11 } });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const convertInchesToMm = (inches) => {
    return parseFloat(inches) * 25.4;
  };
  const EditTicket1 = (id) => {
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);

    const raw = JSON.stringify({
      customerId: customerData?.id,
      customerDeviceId: selectedDeviceId ? selectedDeviceId : null,
      brandId: selectedBrandId1 ? selectedBrandId1 : null,
      serviceId1: selectedServiceId ? selectedServiceId : null,
      serviceId2: selectedServiceId1 ? selectedServiceId1 : null,
      statusOptionId: id,
      techAssignedId: selectedTechId1,
      salesPersonId: salesPersonId,
      dueDate: dueDate,
      password: password,
      description: description,
      drivingLicence: driverLiecence,
      bin: binLocation,
      isAdmin: adminState,
      isTechAdmin: adminState,
      isSalesPersonAdmin: adminState,
      isInvoice: 1,
      invoiceId: id,
      techAssignedName: techAssigned,
    });

    const requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${baseurl}updateTicket/${ticketId}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === true) {
          // navigate("/ticketlist");
          document
            .querySelectorAll(".modal-backdrop")
            .forEach((el) => el.classList.remove("modal-backdrop"));
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const [isSidebarVisible, setSidebarVisible] = useState(false);

  const toggleSidebar = () => {
    setSidebarVisible(!isSidebarVisible);
    localStorage.setItem("sidebar", !isSidebarVisible);
  };

  useEffect(() => {
    const savedSidebarState = localStorage.getItem("sidebar");
    if (savedSidebarState) {
      setSidebarVisible(JSON.parse(savedSidebarState));
    }
  }, []);

  const [isFreeTrialVisible, setFreeTrialVisible] = useState(true);

  const toggleFreeTrial = () => {
    setFreeTrialVisible(!isFreeTrialVisible);
  };

  const sidebarStyleone = {
    overflow: "auto",
    scrollbarWidth: "none",
    msOverflowStyle: "none",
    // position: 'sticky',

    // marginTop: isFreeTrialVisible ? "0px" : "0px",
  };

  const navbarStyle = {
    // top: isFreeTrialVisible ? "-4px" : "-50px",
  };
  const mainContentStyle = {
    // marginTop: isFreeTrialVisible ? "132px" : "92px",
  };
  const mainwrapper = {
    // marginTop: isSidebarVisible ? "0" : "0",
  };
  useEffect(() => {
    if (isFreeTrial) {
      // toggleSidebar(false);
      toggleFreeTrial(false);
    } else {
      // toggleSidebar(true);
      toggleFreeTrial(true);
    }
  }, [isFreeTrial]);
  useEffect(() => {
    const handleClickOutside = (event) => {
      const sidebarElement = document.querySelector(".main-sidebar");
      const toggleButtonElement = document.querySelector(".sidebar-arrow a");

      if (
        sidebarElement &&
        !sidebarElement.contains(event.target) &&
        toggleButtonElement &&
        !toggleButtonElement.contains(event.target)
      ) {
        const mediaQuery = window.matchMedia("(max-width: 1024px)");
        if (mediaQuery.matches) {
          setSidebarVisible(false);
        }
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  return (
    <>
      <div className={isSidebarVisible ? "sidebar-mini" : ""}>
        {loading ? (
          <div className="landing_loader">
            <div className="innerloader">
              <img src="img/spin-1s-156px.gif" alt="" />
            </div>
          </div>
        ) : null}
        <div
          className={`${
            isFreeTrial
              ? "main-wrapper devicelistcstmtableres customclswrapper"
              : "main-wrapper devicelistcstmtableres "
          } `}
        >
          {isFreeTrial ? (
            <>
              {localStorage.getItem("substatus") === "null" ||
              localStorage.getItem("substatus") === "canceled" ||
              localStorage.getItem("substatus") === "expired" ? (
                <>
                  <div className="app-container-trial">
                    <FreeTrail />
                  </div>
                  <div
                    // className={`${isSidebarVisible ? "sidebar-mini" : ""} ${
                    //   !isFreeTrialVisible ? "no-free-trial" : ""
                    // }`}
                    style={sidebarStyleone}
                  >
                    <nav
                      className="navbar navbar-expand-lg main-navbar sticky"
                      style={navbarStyle}
                    >
                      <div className="sidebar-arrow">
                        <a
                          href="#"
                          data-toggle="sidebar"
                          onClick={(e) => {
                            toggleSidebar();

                            e.preventDefault();
                          }}
                        >
                          <img src="assets/img/sidebar-toggle.webp" alt="" />
                        </a>
                      </div>
                      <div className="header-title mr-auto">
                        <h4>Ticket#{ticketId}</h4>
                      </div>
                      <SideUl />
                    </nav>
                    <MainSidebar />{" "}
                    <div className="main-content" style={mainContentStyle}>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="ticket_status_btn">
                            <div className="status_btn">
                              {selectedStatus1 === "Finished" ? (
                                <>
                                  <div className="dropdown">
                                    <button
                                      style={{
                                        background: selectedColor,
                                      }}
                                      className="btn btn-secondary dropdown-toggle"
                                      type="button"
                                      // id="dropdownMenuButton"
                                      // data-toggle="dropdown"
                                      // aria-haspopup="true"
                                      // aria-expanded="false"
                                    >
                                      Status:{" "}
                                      <span
                                        className="customspanlistsatutus"
                                        style={{
                                          background: selectedColor,
                                        }}
                                      ></span>
                                      {selectedStatus}
                                    </button>
                                    {/* <div
                            className="dropdown-menu"
                            aria-labelledby="dropdownMenuButton"
                            style={{ width: "100%" }}
                          >
                            {Status.map((item) => (
                              <a
                                className="dropdown-item"
                                href="#"
                                onClick={() => handleStatusSelect(item)}
                              >
                                <span
                                  className="customspanlistsatutus"
                                  style={{
                                    background: item?.color,
                                  }}
                                ></span>{" "}
                                {item.name}{" "}
                              </a>
                            ))}
                          </div> */}
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div className="dropdown">
                                    <button
                                      style={{
                                        background: selectedColor,
                                      }}
                                      className="btn btn-secondary dropdown-toggle"
                                      type="button"
                                      id="dropdownMenuButton"
                                      data-toggle="dropdown"
                                      aria-haspopup="true"
                                      aria-expanded="false"
                                    >
                                      Status:{" "}
                                      <span
                                        className="customspanlistsatutus"
                                        style={{
                                          background: selectedColor,
                                        }}
                                      ></span>
                                      {selectedStatus}
                                    </button>
                                    <div
                                      className="dropdown-menu"
                                      aria-labelledby="dropdownMenuButton"
                                      style={{ width: "100%" }}
                                    >
                                      {Status.map((item) => (
                                        <a
                                          className="dropdown-item"
                                          href="#"
                                          onClick={() =>
                                            handleStatusSelect(item)
                                          }
                                        >
                                          <span
                                            className="customspanlistsatutus"
                                            style={{
                                              background: item?.color,
                                            }}
                                          ></span>{" "}
                                          {item.name}{" "}
                                        </a>
                                      ))}
                                    </div>
                                  </div>
                                </>
                              )}
                            </div>
                            <div className="ticket_extra_btns">
                              <button type="text" className="cus_btn email_btn">
                                Email
                              </button>
                              <button type="text" className="cus_btn text_btn">
                                Text
                              </button>
                              <button
                                type="text"
                                className="cus_btn invoice_btn"
                              >
                                Print Invoice
                              </button>
                              <button
                                type="text"
                                className="cus_btn label_btn"
                                onClick={GetReciept}
                              >
                                Print Label
                              </button>
                              <button
                                type="text"
                                className="cus_btn servicetkt_btn"
                              >
                                Print Service Ticket
                              </button>
                              <button
                                type="text"
                                className="cus_btn thermal_recepit_btn"
                              >
                                Print Thermal Receipt
                              </button>
                            </div>{" "}
                            {/* <button
                    type="text"
                    className="savechangescusmtedit"
                    onClick={() => EditTicket()}
                  >
                    SAVE
                  </button> */}
                          </div>
                          <div className="ticket_block_content">
                            <div className="ticket_block_box ticketinfo_box">
                              <div className="tkt_box_title">
                                <h4>Ticket Info</h4>
                                <span>#{ticketId}</span>
                              </div>
                              <div className="tkt_box_content">
                                <div className="picturebrandticketdetail">
                                  <div className="ticket_box_img">
                                    {images1 ? (
                                      <img
                                        className="imagesdevicelistticket"
                                        src={images1}
                                        alt=""
                                      />
                                    ) : (
                                      <img
                                        src="assets/img/phone_img.webp"
                                        alt=""
                                      />
                                    )}

                                    {/* <label className="picture" tabIndex="0">
                          <span className="picture__image">
                            {images1 ? (
                              <img
                                className="imagesdevicelistticket"
                                src={images1}
                                alt="Uploaded"
                              />
                            ) : (
                              <img src="assets/img/phone_img.png" alt="" />
                            )}
                          </span>
                        </label> */}
                                  </div>
                                  <ul className="uleditrepairticketcustm tkt_box_ul">
                                    <li
                                      className="editrepairticketlistshowcustm"
                                      ref={dropdownRef5}
                                    >
                                      <span>Brand:</span>
                                      <input
                                        ref={inputRef5}
                                        type="text"
                                        placeholder={`Select Brand`}
                                        value={selectedBrand1 || searchTerm1}
                                        onChange={handleSearchChange1}
                                        onClick={() => setShowList1(!showList1)}
                                        className="ticketinput inputcustmeditrepairticketcustm"
                                      />
                                      {showList1 && (
                                        <div className="addselectbox addbrandnamecustmeditticket">
                                          <ul>
                                            {filteredBrands1.map((brand) => (
                                              <li
                                                key={brand.id}
                                                onClick={() =>
                                                  handleBrandSelect1(brand)
                                                }
                                                style={{
                                                  cursor: "pointer",
                                                }}
                                                className="liuleditbrandrepaircstm"
                                              >
                                                {brand.name}
                                              </li>
                                            ))}
                                          </ul>

                                          <button
                                            onClick={() => {
                                              handleAddNewClickDevice();
                                              if (inputRef5.current) {
                                                inputRef5.current.focus();
                                              }
                                            }}
                                            style={{
                                              backgroundColor: "#9d3dd3",
                                            }}
                                            type="button"
                                            className="btn cus_addnew_btn   brandrepairticektbtncustom"
                                          >
                                            <span className="addbrandbtnrepairticketcstm">
                                              +
                                            </span>{" "}
                                            Add New Brand
                                          </button>
                                        </div>
                                      )}
                                    </li>
                                    <li
                                      className="editrepairticketlistshowcustm"
                                      ref={dropdownRef6}
                                    >
                                      <span>Model:</span>
                                      <input
                                        type="text"
                                        placeholder={`Select Device`}
                                        value={
                                          selectedDevice || searchTermDevice
                                        }
                                        onChange={handleSearchChangeDevice}
                                        onClick={() =>
                                          setShowListDevice(!showListDevice)
                                        }
                                        className="ticketinput inputcustmeditrepairticketcustm"
                                      />
                                      {showListDevice && (
                                        <div className="addselectbox addbrandnamecustmeditticket">
                                          <ul>
                                            {filteredDevice.map((brand) => (
                                              <li
                                                key={brand.id}
                                                onClick={() =>
                                                  handleBrandSelectDevice(brand)
                                                }
                                                style={{
                                                  cursor: "pointer",
                                                }}
                                                className="liuleditbrandrepaircstm"
                                              >
                                                {brand.model}
                                              </li>
                                            ))}
                                          </ul>
                                          <button
                                            data-toggle="modal"
                                            data-target="#createproductModal"
                                            onClick={() => {
                                              if (customerData?.id) {
                                                setShowModal1(true);
                                                setShowListDevice(false);
                                              } else {
                                                Swal.fire({
                                                  title:
                                                    "Please Select Customer First!",
                                                  customClass: {
                                                    container:
                                                      "custom-swal-container",

                                                    confirmButton:
                                                      "custom-swal-confirm-button",
                                                  },
                                                });
                                              }
                                              if (inputRef6.current) {
                                                inputRef6.current.focus();
                                              }
                                            }}
                                            style={{
                                              backgroundColor: "#9d3dd3",
                                            }}
                                            type="button"
                                            className="btn cus_addnew_btn   brandrepairticektbtncustom"
                                          >
                                            <span className="addbrandbtnrepairticketcstm">
                                              +
                                            </span>{" "}
                                            Add New Device
                                          </button>
                                        </div>
                                      )}
                                    </li>
                                    <li
                                      className="editrepairticketlistshowcustm"
                                      ref={dropdownRef2}
                                    >
                                      <span>Serivce:</span>
                                      <input
                                        type="text"
                                        ref={inputRef2}
                                        placeholder={`Select Service`}
                                        value={
                                          selectedService || searchTermService
                                        }
                                        onChange={handleSearchChangeService}
                                        onClick={() =>
                                          setShowListService(!showListService)
                                        }
                                        className="ticketinput inputcustmeditrepairticketcustm"
                                      />
                                      {showListService && (
                                        <div className="addselectbox addbrandnamecustmeditticket">
                                          <ul>
                                            {filteredService.map((brand) => (
                                              <li
                                                key={brand.id}
                                                onClick={() =>
                                                  handleServiceSelect(brand)
                                                }
                                                style={{
                                                  cursor: "pointer",
                                                }}
                                                className="liuleditbrandrepaircstm"
                                              >
                                                {brand.name}
                                              </li>
                                            ))}
                                          </ul>

                                          <button
                                            onClick={() => {
                                              handleAddNewClickService();
                                              if (inputRef2.current) {
                                                inputRef2.current.focus();
                                              }
                                            }}
                                            style={{
                                              backgroundColor: "#9d3dd3",
                                            }}
                                            type="button"
                                            className="btn cus_addnew_btn   brandrepairticektbtncustom"
                                          >
                                            <span className="addbrandbtnrepairticketcstm">
                                              +
                                            </span>{" "}
                                            Add New Service
                                          </button>
                                        </div>
                                      )}
                                    </li>

                                    <li
                                      className="editrepairticketlistshowcustm"
                                      ref={dropdownRef3}
                                    >
                                      <span>Serivce 2:</span>
                                      <input
                                        type="text"
                                        ref={inputRef3}
                                        placeholder={`Select Service2`}
                                        value={
                                          selectedService1 || searchTermService1
                                        }
                                        onChange={handleSearchChangeService1}
                                        onClick={() =>
                                          setShowListService1(!showListService1)
                                        }
                                        className="ticketinput inputcustmeditrepairticketcustm"
                                      />
                                      {showListService1 && (
                                        <div className="addselectbox addbrandnamecustmeditticket">
                                          <ul>
                                            {filteredService1.map((brand) => (
                                              <li
                                                key={brand.id}
                                                onClick={() =>
                                                  handleServiceSelect1(brand)
                                                }
                                                style={{
                                                  cursor: "pointer",
                                                }}
                                                className="liuleditbrandrepaircstm"
                                              >
                                                {brand.name}
                                              </li>
                                            ))}
                                          </ul>

                                          <button
                                            onClick={() => {
                                              handleAddNewClickService1();
                                              if (inputRef3.current) {
                                                inputRef3.current.focus();
                                              }
                                            }}
                                            style={{
                                              backgroundColor: "#9d3dd3",
                                            }}
                                            type="button"
                                            className="btn cus_addnew_btn   brandrepairticektbtncustom"
                                          >
                                            <span className="addbrandbtnrepairticketcstm">
                                              +
                                            </span>{" "}
                                            Add New Service2
                                          </button>
                                        </div>
                                      )}
                                    </li>
                                    <li>
                                      <span>Passcode:</span>
                                      <input
                                        type="password"
                                        value={password}
                                        onChange={(e) =>
                                          setPassword(e.target.value)
                                        }
                                        className="ticketinput"
                                        autoComplete="new-password"
                                      />
                                    </li>
                                    <li>
                                      <span>IMEI/Serial:</span>
                                      <input
                                        type="text"
                                        value={imeiNumber}
                                        onChange={(e) =>
                                          setIMEINumber(e.target.value)
                                        }
                                        className="ticketinput"
                                      />
                                    </li>
                                    <li>
                                      <span>Drivers Licenses:</span>
                                      <input
                                        type="text"
                                        value={driverLiecence}
                                        onChange={(e) =>
                                          setDriverLiecence(e.target.value)
                                        }
                                        className="ticketinput"
                                      />
                                    </li>
                                    <li>
                                      <span>Additional Details:</span>
                                      <input
                                        type="text"
                                        value={description}
                                        onChange={(e) =>
                                          setDescription(e.target.value)
                                        }
                                        className="ticketinput"
                                      />
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                            <div className="ticket_block_box customerinfo_box">
                              <div className="tkt_box_title">
                                <h4>Customer Info</h4>

                                <a
                                  href="#"
                                  data-toggle="modal"
                                  data-target="#addcustomerModal"
                                  onClick={() => setShowModal(true)}
                                >
                                  Edit info
                                </a>

                                {/* <Link
                        to="/ticketcustomerdetail"
                        state={{ data: customerData }}
                      >
                        Edit info
                      </Link> */}
                              </div>
                              <div className="tkt_box_content">
                                <div className="ticket_box_img">
                                  {images ? (
                                    <img
                                      src={images}
                                      className="customerimagescustmeditticket"
                                      alt=""
                                    />
                                  ) : (
                                    <img
                                      src="assets/img/customer-info-img.webp"
                                      alt=""
                                    />
                                  )}
                                </div>
                                <ul className="tkt_box_ul">
                                  <li>
                                    <span>Name:</span>
                                    <b>
                                      {firstName} {lastName}
                                    </b>
                                  </li>
                                  <li>
                                    <span>Phone no:</span>
                                    <b>{formatPhoneNumber(phoneNo)}</b>
                                  </li>
                                  <li>
                                    <span>Email:</span>
                                    <b>{email}</b>
                                  </li>
                                  <li>
                                    <span>Address:</span>
                                    <b>{address}</b>
                                  </li>
                                  {/* <li>
                          <span>Passcode:</span>
                          <b>{password}</b>
                        </li> */}

                                  {/* <li>
                          <span>Other info:</span>
                          <input
                            type="text"
                            value={otherInfo}
                            onChange={(e) => setOtherInfo(e.target.value)}
                            className="ticketinput"
                          />
                        </li> */}
                                </ul>
                              </div>
                            </div>
                            <div className="ticket_block_box ticketdate_info">
                              <div className="tkt_box_title">
                                <h4>Date</h4>
                              </div>
                              <div className="tkt_box_content">
                                <ul className="tkt_box_ul">
                                  <li>
                                    <span>Date Created:</span>
                                    <b>{formDate}</b>
                                  </li>
                                  <li>
                                    <span>Last Modified:</span>
                                    <b>{formDate1}</b>
                                  </li>
                                  <li>
                                    <span>Due Date:</span>
                                    <input
                                      type="date"
                                      value={formattedDueDate}
                                      onChange={handleDueDateChange}
                                      className="ticketinput"
                                    />
                                    {/* <img
                            src="assets/img/ticket-calendar-icon.webp"
                            alt=""
                          /> */}
                                  </li>
                                  <li>
                                    <span>Tech Assigned:</span>
                                    <input
                                      type="text"
                                      placeholder="Tech Name"
                                      value={techAssigned}
                                      readOnly
                                      onChange={handleTechAssignedChange}
                                      // onClick={() => setShowListTech(!showListTech)}
                                      className="ticketinput"
                                    />{" "}
                                    {/* {showListTech && (
                            <div className="addselectbox">
                              <ul>
                                {filteredTech.map((brand) => (
                                  <li
                                    key={brand.id}
                                    onClick={() => handleServiceTech(brand)}
                                    style={{
                                      cursor: "pointer",
                                    }}
                                  >
                                    {brand.name}
                                  </li>
                                ))}
                              </ul>

                             
                            </div>
                          )} */}
                                  </li>
                                  <li>
                                    <span>Sales Person:</span>
                                    <b>{salesPerson}</b>
                                  </li>
                                  <li className="maintitle">Bin</li>
                                  <li>
                                    <span>Location</span>
                                    <input
                                      type="text"
                                      value={binLocation}
                                      onChange={handleBinLocationChange}
                                      className="ticketinput"
                                    />
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>{" "}
                      <TicketCash
                        status={selectedStatus}
                        status1={selectedStatus1}
                        onload={EditTicket}
                        ticketid={ticketId}
                        customer={customerData}
                        data={location?.state?.data}
                      />
                    </div>
                  </div>
                </>
              ) : (
                <div
                  // className={`${isSidebarVisible ? "sidebar-mini" : ""} ${
                  //   !isFreeTrialVisible ? "no-free-trial" : ""
                  // }`}
                  style={sidebarStyleone}
                >
                  <nav
                    className="navbar navbar-expand-lg main-navbar sticky"
                    style={navbarStyle}
                  >
                    <div className="sidebar-arrow">
                      <a
                        href="#"
                        data-toggle="sidebar"
                        onClick={(e) => {
                          toggleSidebar();

                          e.preventDefault();
                        }}
                      >
                        <img src="assets/img/sidebar-toggle.webp" alt="" />
                      </a>
                    </div>
                    <div className="header-title mr-auto">
                      <h4>Ticket#{ticketId}</h4>
                    </div>
                    <SideUl />
                  </nav>
                  <MainSidebar />{" "}
                  <div className="main-content" style={mainContentStyle}>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="ticket_status_btn">
                          <div className="status_btn">
                            {selectedStatus1 === "Finished" ? (
                              <>
                                <div className="dropdown">
                                  <button
                                    style={{
                                      background: selectedColor,
                                    }}
                                    className="btn btn-secondary dropdown-toggle"
                                    type="button"
                                    // id="dropdownMenuButton"
                                    // data-toggle="dropdown"
                                    // aria-haspopup="true"
                                    // aria-expanded="false"
                                  >
                                    Status:{" "}
                                    <span
                                      className="customspanlistsatutus"
                                      style={{
                                        background: selectedColor,
                                      }}
                                    ></span>
                                    {selectedStatus}
                                  </button>
                                  {/* <div
                            className="dropdown-menu"
                            aria-labelledby="dropdownMenuButton"
                            style={{ width: "100%" }}
                          >
                            {Status.map((item) => (
                              <a
                                className="dropdown-item"
                                href="#"
                                onClick={() => handleStatusSelect(item)}
                              >
                                <span
                                  className="customspanlistsatutus"
                                  style={{
                                    background: item?.color,
                                  }}
                                ></span>{" "}
                                {item.name}{" "}
                              </a>
                            ))}
                          </div> */}
                                </div>
                              </>
                            ) : (
                              <>
                                <div className="dropdown">
                                  <button
                                    style={{
                                      background: selectedColor,
                                    }}
                                    className="btn btn-secondary dropdown-toggle"
                                    type="button"
                                    id="dropdownMenuButton"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  >
                                    Status:{" "}
                                    <span
                                      className="customspanlistsatutus"
                                      style={{
                                        background: selectedColor,
                                      }}
                                    ></span>
                                    {selectedStatus}
                                  </button>
                                  <div
                                    className="dropdown-menu"
                                    aria-labelledby="dropdownMenuButton"
                                    style={{ width: "100%" }}
                                  >
                                    {Status.map((item) => (
                                      <a
                                        className="dropdown-item"
                                        href="#"
                                        onClick={() => handleStatusSelect(item)}
                                      >
                                        <span
                                          className="customspanlistsatutus"
                                          style={{
                                            background: item?.color,
                                          }}
                                        ></span>{" "}
                                        {item.name}{" "}
                                      </a>
                                    ))}
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                          <div className="ticket_extra_btns">
                            <button type="text" className="cus_btn email_btn">
                              Email
                            </button>
                            <button type="text" className="cus_btn text_btn">
                              Text
                            </button>
                            <button type="text" className="cus_btn invoice_btn">
                              Print Invoice
                            </button>
                            <button
                              type="text"
                              className="cus_btn label_btn"
                              onClick={GetReciept}
                              // onClick={printTable}
                            >
                              Print Label
                            </button>
                            <button
                              type="text"
                              className="cus_btn servicetkt_btn"
                            >
                              Print Service Ticket
                            </button>
                            <button
                              type="text"
                              className="cus_btn thermal_recepit_btn"
                            >
                              Print Thermal Receipt
                            </button>
                          </div>{" "}
                          {/* <button
                    type="text"
                    className="savechangescusmtedit"
                    onClick={() => EditTicket()}
                  >
                    SAVE
                  </button> */}
                        </div>
                        <div className="ticket_block_content">
                          <div className="ticket_block_box ticketinfo_box">
                            <div className="tkt_box_title">
                              <h4>Ticket Info</h4>
                              <span>#{ticketId}</span>
                            </div>
                            <div className="tkt_box_content">
                              <div className="picturebrandticketdetail">
                                <div className="ticket_box_img">
                                  {images1 ? (
                                    <img
                                      className="imagesdevicelistticket"
                                      src={images1}
                                      alt=""
                                    />
                                  ) : (
                                    <img
                                      src="assets/img/phone_img.webp"
                                      alt=""
                                    />
                                  )}

                                  {/* <label className="picture" tabIndex="0">
                          <span className="picture__image">
                            {images1 ? (
                              <img
                                className="imagesdevicelistticket"
                                src={images1}
                                alt="Uploaded"
                              />
                            ) : (
                              <img src="assets/img/phone_img.png" alt="" />
                            )}
                          </span>
                        </label> */}
                                </div>
                                <ul className="uleditrepairticketcustm tkt_box_ul">
                                  <li
                                    className="editrepairticketlistshowcustm"
                                    ref={dropdownRef5}
                                  >
                                    <span>Brand:</span>
                                    <input
                                      ref={inputRef5}
                                      type="text"
                                      placeholder={`Select Brand`}
                                      value={selectedBrand1 || searchTerm1}
                                      onChange={handleSearchChange1}
                                      onClick={() => setShowList1(!showList1)}
                                      className="ticketinput inputcustmeditrepairticketcustm"
                                    />
                                    {showList1 && (
                                      <div className="addselectbox addbrandnamecustmeditticket">
                                        <ul>
                                          {filteredBrands1.map((brand) => (
                                            <li
                                              key={brand.id}
                                              onClick={() =>
                                                handleBrandSelect1(brand)
                                              }
                                              style={{
                                                cursor: "pointer",
                                              }}
                                              className="liuleditbrandrepaircstm"
                                            >
                                              {brand.name}
                                            </li>
                                          ))}
                                        </ul>

                                        <button
                                          onClick={() => {
                                            handleAddNewClickDevice();
                                            if (inputRef5.current) {
                                              inputRef5.current.focus();
                                            }
                                          }}
                                          style={{ backgroundColor: "#9d3dd3" }}
                                          type="button"
                                          className="btn cus_addnew_btn   brandrepairticektbtncustom"
                                        >
                                          <span className="addbrandbtnrepairticketcstm">
                                            +
                                          </span>{" "}
                                          Add New Brand
                                        </button>
                                      </div>
                                    )}
                                  </li>
                                  <li
                                    className="editrepairticketlistshowcustm"
                                    ref={dropdownRef6}
                                  >
                                    <span>Model:</span>
                                    <input
                                      type="text"
                                      placeholder={`Select Device`}
                                      value={selectedDevice || searchTermDevice}
                                      onChange={handleSearchChangeDevice}
                                      onClick={() =>
                                        setShowListDevice(!showListDevice)
                                      }
                                      className="ticketinput inputcustmeditrepairticketcustm"
                                    />
                                    {showListDevice && (
                                      <div className="addselectbox addbrandnamecustmeditticket">
                                        <ul>
                                          {filteredDevice.map((brand) => (
                                            <li
                                              key={brand.id}
                                              onClick={() =>
                                                handleBrandSelectDevice(brand)
                                              }
                                              style={{
                                                cursor: "pointer",
                                              }}
                                              className="liuleditbrandrepaircstm"
                                            >
                                              {brand.model}
                                            </li>
                                          ))}
                                        </ul>
                                        <button
                                          data-toggle="modal"
                                          data-target="#createproductModal"
                                          onClick={() => {
                                            if (customerData?.id) {
                                              setShowModal1(true);
                                              setShowListDevice(false);
                                            } else {
                                              Swal.fire({
                                                title:
                                                  "Please Select Customer First!",
                                                customClass: {
                                                  container:
                                                    "custom-swal-container",

                                                  confirmButton:
                                                    "custom-swal-confirm-button",
                                                },
                                              });
                                            }
                                            if (inputRef6.current) {
                                              inputRef6.current.focus();
                                            }
                                          }}
                                          style={{ backgroundColor: "#9d3dd3" }}
                                          type="button"
                                          className="btn cus_addnew_btn   brandrepairticektbtncustom"
                                        >
                                          <span className="addbrandbtnrepairticketcstm">
                                            +
                                          </span>{" "}
                                          Add New Device
                                        </button>
                                      </div>
                                    )}
                                  </li>
                                  <li
                                    className="editrepairticketlistshowcustm"
                                    ref={dropdownRef2}
                                  >
                                    <span>Serivce:</span>
                                    <input
                                      type="text"
                                      ref={inputRef2}
                                      placeholder={`Select Service`}
                                      value={
                                        selectedService || searchTermService
                                      }
                                      onChange={handleSearchChangeService}
                                      onClick={() =>
                                        setShowListService(!showListService)
                                      }
                                      className="ticketinput inputcustmeditrepairticketcustm"
                                    />
                                    {showListService && (
                                      <div className="addselectbox addbrandnamecustmeditticket">
                                        <ul>
                                          {filteredService.map((brand) => (
                                            <li
                                              key={brand.id}
                                              onClick={() =>
                                                handleServiceSelect(brand)
                                              }
                                              style={{
                                                cursor: "pointer",
                                              }}
                                              className="liuleditbrandrepaircstm"
                                            >
                                              {brand.name}
                                            </li>
                                          ))}
                                        </ul>

                                        <button
                                          onClick={() => {
                                            handleAddNewClickService();
                                            if (inputRef2.current) {
                                              inputRef2.current.focus();
                                            }
                                          }}
                                          style={{ backgroundColor: "#9d3dd3" }}
                                          type="button"
                                          className="btn cus_addnew_btn   brandrepairticektbtncustom"
                                        >
                                          <span className="addbrandbtnrepairticketcstm">
                                            +
                                          </span>{" "}
                                          Add New Service
                                        </button>
                                      </div>
                                    )}
                                  </li>

                                  <li
                                    className="editrepairticketlistshowcustm"
                                    ref={dropdownRef3}
                                  >
                                    <span>Serivce 2:</span>
                                    <input
                                      type="text"
                                      ref={inputRef3}
                                      placeholder={`Select Service2`}
                                      value={
                                        selectedService1 || searchTermService1
                                      }
                                      onChange={handleSearchChangeService1}
                                      onClick={() =>
                                        setShowListService1(!showListService1)
                                      }
                                      className="ticketinput inputcustmeditrepairticketcustm"
                                    />
                                    {showListService1 && (
                                      <div className="addselectbox addbrandnamecustmeditticket">
                                        <ul>
                                          {filteredService1.map((brand) => (
                                            <li
                                              key={brand.id}
                                              onClick={() =>
                                                handleServiceSelect1(brand)
                                              }
                                              style={{
                                                cursor: "pointer",
                                              }}
                                              className="liuleditbrandrepaircstm"
                                            >
                                              {brand.name}
                                            </li>
                                          ))}
                                        </ul>

                                        <button
                                          onClick={() => {
                                            handleAddNewClickService1();
                                            if (inputRef3.current) {
                                              inputRef3.current.focus();
                                            }
                                          }}
                                          style={{ backgroundColor: "#9d3dd3" }}
                                          type="button"
                                          className="btn cus_addnew_btn   brandrepairticektbtncustom"
                                        >
                                          <span className="addbrandbtnrepairticketcstm">
                                            +
                                          </span>{" "}
                                          Add New Service2
                                        </button>
                                      </div>
                                    )}
                                  </li>
                                  <li>
                                    <span>Passcode:</span>
                                    <input
                                      type="password"
                                      value={password}
                                      onChange={(e) =>
                                        setPassword(e.target.value)
                                      }
                                      className="ticketinput"
                                      autoComplete="new-password"
                                    />
                                  </li>
                                  <li>
                                    <span>IMEI/Serial:</span>
                                    <input
                                      type="text"
                                      value={imeiNumber}
                                      onChange={(e) =>
                                        setIMEINumber(e.target.value)
                                      }
                                      className="ticketinput"
                                    />
                                  </li>
                                  <li>
                                    <span>Drivers Licenses:</span>
                                    <input
                                      type="text"
                                      value={driverLiecence}
                                      onChange={(e) =>
                                        setDriverLiecence(e.target.value)
                                      }
                                      className="ticketinput"
                                    />
                                  </li>
                                  <li>
                                    <span>Additional Details:</span>
                                    <input
                                      type="text"
                                      value={description}
                                      onChange={(e) =>
                                        setDescription(e.target.value)
                                      }
                                      className="ticketinput"
                                    />
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div className="ticket_block_box customerinfo_box">
                            <div className="tkt_box_title">
                              <h4>Customer Info</h4>
                              {adminState === "0" ? (
                                <a
                                  href="#"
                                  // data-toggle="modal"
                                  // data-target="#addcustomerModal"
                                  // onClick={() => setShowModal(true)}
                                >
                                  Edit info
                                </a>
                              ) : (
                                <a
                                  href="#"
                                  data-toggle="modal"
                                  data-target="#addcustomerModal"
                                  onClick={() => setShowModal(true)}
                                >
                                  Edit info
                                </a>
                              )}
                              {/* <a
                                href="#"
                                data-toggle="modal"
                                data-target="#addcustomerModal"
                                onClick={() => setShowModal(true)}
                              >
                                Edit info
                              </a> */}
                              {/* <Link
                        to="/ticketcustomerdetail"
                        state={{ data: customerData }}
                      >
                        Edit info
                      </Link> */}
                            </div>
                            <div className="tkt_box_content">
                              <div className="ticket_box_img">
                                {images ? (
                                  <img
                                    src={images}
                                    className="customerimagescustmeditticket"
                                    alt=""
                                  />
                                ) : (
                                  <img
                                    src="assets/img/customer-info-img.webp"
                                    alt=""
                                  />
                                )}
                              </div>
                              <ul className="tkt_box_ul">
                                <li>
                                  <span>Name:</span>
                                  <b>
                                    {firstName} {lastName}
                                  </b>
                                </li>
                                <li>
                                  <span>Phone no:</span>
                                  <b>{formatPhoneNumber(phoneNo)}</b>
                                </li>
                                <li>
                                  <span>Email:</span>
                                  <b>{email}</b>
                                </li>
                                <li>
                                  <span>Address:</span>
                                  <b>{address}</b>
                                </li>
                                {/* <li>
                          <span>Passcode:</span>
                          <b>{password}</b>
                        </li> */}

                                {/* <li>
                          <span>Other info:</span>
                          <input
                            type="text"
                            value={otherInfo}
                            onChange={(e) => setOtherInfo(e.target.value)}
                            className="ticketinput"
                          />
                        </li> */}
                              </ul>
                            </div>
                          </div>
                          <div className="ticket_block_box ticketdate_info">
                            <div className="tkt_box_title">
                              <h4>Date</h4>
                            </div>
                            <div className="tkt_box_content">
                              <ul className="tkt_box_ul">
                                <li>
                                  <span>Date Created:</span>
                                  <b>{formDate}</b>
                                </li>
                                <li>
                                  <span>Last Modified:</span>
                                  <b>{formDate1}</b>
                                </li>
                                <li>
                                  <span>Due Date:</span>
                                  <input
                                    type="date"
                                    value={formattedDueDate}
                                    onChange={handleDueDateChange}
                                    className="ticketinput"
                                  />
                                  {/* <img
                            src="assets/img/ticket-calendar-icon.webp"
                            alt=""
                          /> */}
                                </li>
                                <li>
                                  <span>Tech Assigned:</span>
                                  <input
                                    type="text"
                                    placeholder="Tech Name"
                                    value={techAssigned}
                                    // value={techAssigned}
                                    readOnly
                                    onChange={handleTechAssignedChange}
                                    // onClick={() => setShowListTech(!showListTech)}
                                    className="ticketinput"
                                  />{" "}
                                  {/* {showListTech && (
                            <div className="addselectbox">
                              <ul>
                                {filteredTech.map((brand) => (
                                  <li
                                    key={brand.id}
                                    onClick={() => handleServiceTech(brand)}
                                    style={{
                                      cursor: "pointer",
                                    }}
                                  >
                                    {brand.name}
                                  </li>
                                ))}
                              </ul>

                             
                            </div>
                          )} */}
                                </li>
                                <li>
                                  <span>Sales Person:</span>
                                  <b>{salesPerson}</b>
                                </li>
                                <li className="maintitle">Bin</li>
                                <li>
                                  <span>Location</span>
                                  <input
                                    type="text"
                                    value={binLocation}
                                    onChange={handleBinLocationChange}
                                    className="ticketinput"
                                  />
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>{" "}
                    <TicketCash
                      status={selectedStatus}
                      status1={selectedStatus1}
                      onload={EditTicket}
                      ticketid={ticketId}
                      customer={customerData}
                      data={location?.state?.data}
                    />
                  </div>
                </div>
              )}
            </>
          ) : (
            <div
              // className={`${isSidebarVisible ? "sidebar-mini" : ""} ${
              //   !isFreeTrialVisible ? "no-free-trial" : ""
              // }`}
              style={sidebarStyleone}
            >
              <nav
                className="navbar navbar-expand-lg main-navbar sticky"
                style={navbarStyle}
              >
                <div className="sidebar-arrow">
                  <a
                    href="#"
                    data-toggle="sidebar"
                    onClick={(e) => {
                      toggleSidebar();

                      e.preventDefault();
                    }}
                  >
                    <img src="assets/img/sidebar-toggle.webp" alt="" />
                  </a>
                </div>
                <div className="header-title mr-auto">
                  <h4>Ticket#{ticketId}</h4>
                </div>
                <SideUl />
              </nav>
              <MainSidebar />{" "}
              <div className="main-content" style={mainContentStyle}>
                <div className="row">
                  <div className="col-md-12">
                    <div className="ticket_status_btn">
                      <div className="status_btn">
                        {selectedStatus1 === "Finished" ? (
                          <>
                            <div className="dropdown">
                              <button
                                style={{
                                  background: selectedColor,
                                }}
                                className="btn btn-secondary dropdown-toggle"
                                type="button"
                                // id="dropdownMenuButton"
                                // data-toggle="dropdown"
                                // aria-haspopup="true"
                                // aria-expanded="false"
                              >
                                Status:{" "}
                                <span
                                  className="customspanlistsatutus"
                                  style={{
                                    background: selectedColor,
                                  }}
                                ></span>
                                {selectedStatus}
                              </button>
                              {/* <div
                            className="dropdown-menu"
                            aria-labelledby="dropdownMenuButton"
                            style={{ width: "100%" }}
                          >
                            {Status.map((item) => (
                              <a
                                className="dropdown-item"
                                href="#"
                                onClick={() => handleStatusSelect(item)}
                              >
                                <span
                                  className="customspanlistsatutus"
                                  style={{
                                    background: item?.color,
                                  }}
                                ></span>{" "}
                                {item.name}{" "}
                              </a>
                            ))}
                          </div> */}
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="dropdown">
                              <button
                                style={{
                                  background: selectedColor,
                                }}
                                className="btn btn-secondary dropdown-toggle"
                                type="button"
                                id="dropdownMenuButton"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                Status:{" "}
                                <span
                                  className="customspanlistsatutus"
                                  style={{
                                    background: selectedColor,
                                  }}
                                ></span>
                                {selectedStatus}
                              </button>
                              <div
                                className="dropdown-menu"
                                aria-labelledby="dropdownMenuButton"
                                style={{ width: "100%" }}
                              >
                                {Status.map((item) => (
                                  <a
                                    className="dropdown-item"
                                    href="#"
                                    onClick={() => handleStatusSelect(item)}
                                  >
                                    <span
                                      className="customspanlistsatutus"
                                      style={{
                                        background: item?.color,
                                      }}
                                    ></span>{" "}
                                    {item.name}{" "}
                                  </a>
                                ))}
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                      <div className="ticket_extra_btns">
                        <button type="text" className="cus_btn email_btn">
                          Email
                        </button>
                        <button type="text" className="cus_btn text_btn">
                          Text
                        </button>
                        <button type="text" className="cus_btn invoice_btn">
                          Print Invoice
                        </button>
                        <button
                          type="text"
                          className="cus_btn label_btn"
                          // onClick={printTable}
                          onClick={GetReciept}
                        >
                          Print Label
                        </button>
                        <button type="text" className="cus_btn servicetkt_btn">
                          Print Service Ticket
                        </button>
                        <button
                          type="text"
                          className="cus_btn thermal_recepit_btn"
                        >
                          Print Thermal Receipt
                        </button>
                      </div>{" "}
                      {/* <button
                    type="text"
                    className="savechangescusmtedit"
                    onClick={() => EditTicket()}
                  >
                    SAVE
                  </button> */}
                    </div>
                    <div className="ticket_block_content">
                      <div className="ticket_block_box ticketinfo_box">
                        <div className="tkt_box_title">
                          <h4>Ticket Info</h4>
                          <span>#{ticketId}</span>
                        </div>
                        <div className="tkt_box_content">
                          <div className="picturebrandticketdetail">
                            <div className="ticket_box_img">
                              {images1 ? (
                                <img
                                  className="imagesdevicelistticket"
                                  src={images1}
                                  alt=""
                                />
                              ) : (
                                <img src="assets/img/phone_img.webp" alt="" />
                              )}

                              {/* <label className="picture" tabIndex="0">
                          <span className="picture__image">
                            {images1 ? (
                              <img
                                className="imagesdevicelistticket"
                                src={images1}
                                alt="Uploaded"
                              />
                            ) : (
                              <img src="assets/img/phone_img.png" alt="" />
                            )}
                          </span>
                        </label> */}
                            </div>
                            <ul className="uleditrepairticketcustm tkt_box_ul">
                              <li
                                className="editrepairticketlistshowcustm"
                                ref={dropdownRef5}
                              >
                                <span>Brand:</span>
                                <input
                                  ref={inputRef5}
                                  type="text"
                                  placeholder={`Select Brand`}
                                  value={selectedBrand1 || searchTerm1}
                                  onChange={handleSearchChange1}
                                  onClick={() => setShowList1(!showList1)}
                                  className="ticketinput inputcustmeditrepairticketcustm"
                                />
                                {showList1 && (
                                  <div className="addselectbox addbrandnamecustmeditticket">
                                    <ul>
                                      {filteredBrands1.map((brand) => (
                                        <li
                                          key={brand.id}
                                          onClick={() =>
                                            handleBrandSelect1(brand)
                                          }
                                          style={{
                                            cursor: "pointer",
                                          }}
                                          className="liuleditbrandrepaircstm"
                                        >
                                          {brand.name}
                                        </li>
                                      ))}
                                    </ul>

                                    <button
                                      onClick={() => {
                                        handleAddNewClickDevice();
                                        if (inputRef5.current) {
                                          inputRef5.current.focus();
                                        }
                                      }}
                                      style={{ backgroundColor: "#9d3dd3" }}
                                      type="button"
                                      className="btn cus_addnew_btn   brandrepairticektbtncustom"
                                    >
                                      <span className="addbrandbtnrepairticketcstm">
                                        +
                                      </span>{" "}
                                      Add New Brand
                                    </button>
                                  </div>
                                )}
                              </li>
                              <li
                                className="editrepairticketlistshowcustm"
                                ref={dropdownRef6}
                              >
                                <span>Model:</span>
                                <input
                                  type="text"
                                  placeholder={`Select Device`}
                                  value={selectedDevice || searchTermDevice}
                                  onChange={handleSearchChangeDevice}
                                  onClick={() =>
                                    setShowListDevice(!showListDevice)
                                  }
                                  className="ticketinput inputcustmeditrepairticketcustm"
                                />
                                {showListDevice && (
                                  <div className="addselectbox addbrandnamecustmeditticket">
                                    <ul>
                                      {filteredDevice.map((brand) => (
                                        <li
                                          key={brand.id}
                                          onClick={() =>
                                            handleBrandSelectDevice(brand)
                                          }
                                          style={{
                                            cursor: "pointer",
                                          }}
                                          className="liuleditbrandrepaircstm"
                                        >
                                          {brand?.model}
                                        </li>
                                      ))}
                                    </ul>
                                    <button
                                      data-toggle="modal"
                                      data-target="#createproductModal"
                                      onClick={() => {
                                        if (customerData?.id) {
                                          setShowModal1(true);
                                          setShowListDevice(false);
                                        } else {
                                          Swal.fire({
                                            title:
                                              "Please Select Customer First!",
                                            customClass: {
                                              container:
                                                "custom-swal-container",

                                              confirmButton:
                                                "custom-swal-confirm-button",
                                            },
                                          });
                                        }
                                        if (inputRef6.current) {
                                          inputRef6.current.focus();
                                        }
                                      }}
                                      style={{ backgroundColor: "#9d3dd3" }}
                                      type="button"
                                      className="btn cus_addnew_btn   brandrepairticektbtncustom"
                                    >
                                      <span className="addbrandbtnrepairticketcstm">
                                        +
                                      </span>{" "}
                                      Add New Device
                                    </button>
                                  </div>
                                )}
                              </li>
                              <li
                                className="editrepairticketlistshowcustm"
                                ref={dropdownRef2}
                              >
                                <span>Serivce:</span>
                                <input
                                  type="text"
                                  ref={inputRef2}
                                  placeholder={`Select Service`}
                                  value={selectedService || searchTermService}
                                  onChange={handleSearchChangeService}
                                  onClick={() =>
                                    setShowListService(!showListService)
                                  }
                                  className="ticketinput inputcustmeditrepairticketcustm"
                                />
                                {showListService && (
                                  <div className="addselectbox addbrandnamecustmeditticket">
                                    <ul>
                                      {filteredService.map((brand) => (
                                        <li
                                          key={brand.id}
                                          onClick={() =>
                                            handleServiceSelect(brand)
                                          }
                                          style={{
                                            cursor: "pointer",
                                          }}
                                          className="liuleditbrandrepaircstm"
                                        >
                                          {brand.name}
                                        </li>
                                      ))}
                                    </ul>

                                    <button
                                      onClick={() => {
                                        handleAddNewClickService();
                                        if (inputRef2.current) {
                                          inputRef2.current.focus();
                                        }
                                      }}
                                      style={{ backgroundColor: "#9d3dd3" }}
                                      type="button"
                                      className="btn cus_addnew_btn   brandrepairticektbtncustom"
                                    >
                                      <span className="addbrandbtnrepairticketcstm">
                                        +
                                      </span>{" "}
                                      Add New Service
                                    </button>
                                  </div>
                                )}
                              </li>

                              <li
                                className="editrepairticketlistshowcustm"
                                ref={dropdownRef3}
                              >
                                <span>Serivce 2:</span>
                                <input
                                  type="text"
                                  ref={inputRef3}
                                  placeholder={`Select Service2`}
                                  value={selectedService1 || searchTermService1}
                                  onChange={handleSearchChangeService1}
                                  onClick={() =>
                                    setShowListService1(!showListService1)
                                  }
                                  className="ticketinput inputcustmeditrepairticketcustm"
                                />
                                {showListService1 && (
                                  <div className="addselectbox addbrandnamecustmeditticket">
                                    <ul>
                                      {filteredService1.map((brand) => (
                                        <li
                                          key={brand.id}
                                          onClick={() =>
                                            handleServiceSelect1(brand)
                                          }
                                          style={{
                                            cursor: "pointer",
                                          }}
                                          className="liuleditbrandrepaircstm"
                                        >
                                          {brand.name}
                                        </li>
                                      ))}
                                    </ul>

                                    <button
                                      onClick={() => {
                                        handleAddNewClickService1();
                                        if (inputRef3.current) {
                                          inputRef3.current.focus();
                                        }
                                      }}
                                      style={{ backgroundColor: "#9d3dd3" }}
                                      type="button"
                                      className="btn cus_addnew_btn   brandrepairticektbtncustom"
                                    >
                                      <span className="addbrandbtnrepairticketcstm">
                                        +
                                      </span>{" "}
                                      Add New Service2
                                    </button>
                                  </div>
                                )}
                              </li>
                              <li>
                                <span>Passcode:</span>
                                <input
                                  type="password"
                                  value={password}
                                  onChange={(e) => setPassword(e.target.value)}
                                  className="ticketinput"
                                  autoComplete="new-password"
                                />
                              </li>
                              <li>
                                <span>IMEI/Serial:</span>
                                <input
                                  type="text"
                                  value={imeiNumber}
                                  onChange={(e) =>
                                    setIMEINumber(e.target.value)
                                  }
                                  className="ticketinput"
                                />
                              </li>
                              <li>
                                <span>Drivers Licenses:</span>
                                <input
                                  type="text"
                                  value={driverLiecence}
                                  onChange={(e) =>
                                    setDriverLiecence(e.target.value)
                                  }
                                  className="ticketinput"
                                />
                              </li>
                              <li>
                                <span>Additional Details:</span>
                                <input
                                  type="text"
                                  value={description}
                                  onChange={(e) =>
                                    setDescription(e.target.value)
                                  }
                                  className="ticketinput"
                                />
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="ticket_block_box customerinfo_box">
                        <div className="tkt_box_title">
                          <h4>Customer Info</h4>
                          {adminState === "0" ? (
                            <a
                              href="#"
                              // data-toggle="modal"
                              // data-target="#addcustomerModal"
                              // onClick={() => setShowModal(true)}
                            >
                              Edit info
                            </a>
                          ) : (
                            <a
                              href="#"
                              data-toggle="modal"
                              data-target="#addcustomerModal"
                              onClick={() => setShowModal(true)}
                            >
                              Edit info
                            </a>
                          )}
                          {/* <a
                            href="#"
                            data-toggle="modal"
                            data-target="#addcustomerModal"
                            onClick={() => setShowModal(true)}
                          >
                            Edit info
                          </a> */}
                          {/* <Link
                        to="/ticketcustomerdetail"
                        state={{ data: customerData }}
                      >
                        Edit info
                      </Link> */}
                        </div>
                        <div className="tkt_box_content">
                          <div className="ticket_box_img">
                            {images ? (
                              <img
                                src={images}
                                className="customerimagescustmeditticket"
                                alt=""
                              />
                            ) : (
                              <img
                                src="assets/img/customer-info-img.webp"
                                alt=""
                              />
                            )}
                          </div>
                          <ul className="tkt_box_ul">
                            <li>
                              <span>Name:</span>
                              <b>
                                {firstName} {lastName}
                              </b>
                            </li>
                            <li>
                              <span>Phone no:</span>
                              <b>{formatPhoneNumber(phoneNo)}</b>
                            </li>
                            <li>
                              <span>Email:</span>
                              <b>{email}</b>
                            </li>
                            <li>
                              <span>Address:</span>
                              <b>{address}</b>
                            </li>
                            {/* <li>
                          <span>Passcode:</span>
                          <b>{password}</b>
                        </li> */}

                            {/* <li>
                          <span>Other info:</span>
                          <input
                            type="text"
                            value={otherInfo}
                            onChange={(e) => setOtherInfo(e.target.value)}
                            className="ticketinput"
                          />
                        </li> */}
                          </ul>
                        </div>
                      </div>
                      <div className="ticket_block_box ticketdate_info">
                        <div className="tkt_box_title">
                          <h4>Date</h4>
                        </div>
                        <div className="tkt_box_content">
                          <ul className="tkt_box_ul">
                            <li>
                              <span>Date Created:</span>
                              <b>{formDate}</b>
                            </li>
                            <li>
                              <span>Last Modified:</span>
                              <b>{formDate1}</b>
                            </li>
                            <li>
                              <span>Due Date:</span>
                              <input
                                type="date"
                                value={formattedDueDate}
                                onChange={handleDueDateChange}
                                className="ticketinput"
                              />
                              {/* <img
                            src="assets/img/ticket-calendar-icon.webp"
                            alt=""
                          /> */}
                            </li>
                            <li>
                              <span>Tech Assigned:</span>
                              <input
                                type="text"
                                placeholder="Tech Name"
                                value={techAssigned}
                                // value={techAssigned}
                                readOnly
                                onChange={handleTechAssignedChange}
                                // onClick={() => setShowListTech(!showListTech)}
                                className="ticketinput"
                              />{" "}
                              {/* {showListTech && (
                            <div className="addselectbox">
                              <ul>
                                {filteredTech.map((brand) => (
                                  <li
                                    key={brand.id}
                                    onClick={() => handleServiceTech(brand)}
                                    style={{
                                      cursor: "pointer",
                                    }}
                                  >
                                    {brand.name}
                                  </li>
                                ))}
                              </ul>

                             
                            </div>
                          )} */}
                            </li>
                            <li>
                              <span>Sales Person:</span>
                              <b>{salesPerson}</b>
                            </li>
                            <li className="maintitle">Bin</li>
                            <li>
                              <span>Location</span>
                              <input
                                type="text"
                                value={binLocation}
                                onChange={handleBinLocationChange}
                                className="ticketinput"
                              />
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>{" "}
                <TicketCash
                  status={selectedStatus}
                  status1={selectedStatus1}
                  onload={EditTicket}
                  ticketid={ticketId}
                  customer={customerData}
                  data={location?.state?.data}
                />
              </div>
            </div>
          )}
          {/* <MainSidebar /> */}
          {/* Main Content */}

          <footer className="main-footer" style={{ display: "none" }}>
            <div className="footer-left">
              <a href="#">Toolbox</a>
            </div>
            <div className="footer-right"></div>
          </footer>
        </div>

        <div
          className="modal common_modal cases_modal"
          id="casesModal"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="casesModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="casesModalLabel">
                  Cases
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span className="close_icon" />
                </button>
                <p>Select Model:</p>
              </div>
              <div className="modal-body">
                <div className="cases_list">
                  <ul>
                    <li>
                      <a href="#">Regular</a>
                    </li>
                    <li>
                      <a href="#">Private</a>
                    </li>
                    <li>
                      <a href="#">Mat</a>
                    </li>
                    <li>
                      <a href="#">Full G</a>
                    </li>
                    <li>
                      <a href="#">Black</a>
                    </li>
                    <li>
                      <a href="#">Gold</a>
                    </li>
                    <li>
                      <a href="#">White</a>
                    </li>
                    <li>
                      <a href="#">Silver</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal common_modal payment_modal"
          id="paymentModal"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="paymentModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span className="close_icon" />
                </button>
              </div>
              <div className="modal-body">
                <div className="payment_receipt">
                  <div className="payment_receipt_top">
                    <div className="payment_col payment_col_left">
                      <span className="payment_label">Change due:</span>
                      <span className="payment_value">$0.00</span>
                    </div>
                    <div className="payment_col payment_col_right">
                      <span className="payment_label">Change given:</span>
                      <div className="payment_btns">
                        <a href="#" className="cash_btn">
                          Cash
                        </a>
                        <a href="#" className="debit_btn">
                          Debit
                        </a>
                        <a href="#" className="visa_btn">
                          Visa
                        </a>
                        <a href="#" className="other_btn">
                          Other
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="payment_receipt_btm">
                    <div className="payment_btns">
                      <a href="#" className="email_btn black_btn">
                        Email
                      </a>
                      <a href="#" className="text_btn black_btn">
                        Text
                      </a>
                      <a href="#" className="noreceipt_btn darkred_btn">
                        No receipt
                      </a>
                      <a href="#" className="thermal_recepit_btn black_btn">
                        Thermal Receipt
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {showModal1 === true ? (
          <div
            className="modal fade common_modal createproduct_modal"
            id="createproductModal"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="createproductModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="createproductModalLabel">
                    Create New Device
                  </h5>

                  <button
                    type="button"
                    className="close"
                    aria-label="Close"
                    onClick={() => {
                      // props.onpress(false);
                      document
                        .querySelectorAll(".modal-backdrop")
                        .forEach((el) => el.classList.remove("modal-backdrop"));
                    }}
                  >
                    <span className="close_icon"></span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="custom_form createproduct_form">
                    <form action="" method="" className="cus_form">
                      <div className="form_row product_radio_row">
                        <div className="form_column col_full">
                          <div className="form-group"></div>
                        </div>
                      </div>

                      <div className="product_row">
                        <div className="product_col100 cus_prod_col">
                          <h4>
                            General information<span>*</span>
                          </h4>
                          <p>
                            To start selling, all you need is a name and a
                            price.
                          </p>
                          <div className="form_row">
                            <div className="form_column col_full">
                              <div className="form-group">
                                <label className="cus_label">IMEI/Serial</label>
                                <div className="formfield field_wd100">
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="serialno"
                                    placeholder="Enter IMEI/Serial"
                                    value={imeiNumber}
                                    onChange={(e) =>
                                      setIMEINumber(e.target.value.trim())
                                    }
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="form_column col_full">
                              <div className="form-group">
                                <label className="cus_label label_red">
                                  Model Name<span>*</span>
                                </label>
                                {/* <div className="formfield field_wd100">
                            <input
                              type="text"
                              className="form-control"
                              name="title"
                              placeholder="Classic Bathrobe"
                              value={title}
                              onChange={(e) => {
                                setTitle(e.target.value);
                                if (error.title) {
                                  setError({ ...error, title: "" });
                                }
                              }}
                            />{" "}


                          </div>{" "} */}

                                <div
                                  className="formfield field_wd100  add_cust_field selectcustomerboxcustmlist"
                                  //ref={dropdownRef1}
                                >
                                  <div className="tagsscustomdiv">
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                      // onClick={() => setShowList(!showList)}
                                    >
                                      <input
                                        type="text"
                                        className="form-control customsearchinputboxsetting  "
                                        name="firstName"
                                        placeholder="Enter Model Name"
                                        value={model}
                                        onChange={(e) =>
                                          setModel(e.target.value)
                                        }
                                        //onClick={() => setShowList(!showList)}
                                      />
                                    </div>
                                    {/* {showList && (
                                    <div className="addselectbox">
                                      <ul>
                                        {filteredDevices.map((brand) => (
                                          <li
                                            key={brand.id}
                                            onClick={() =>
                                              handleDeviceSelect(brand)
                                            }
                                            style={{
                                              cursor: "pointer",
                                            }}
                                          >
                                            {brand.model}
                                          </li>
                                        ))}
                                      </ul>

                                      {/* <button
                                onClick={() => {
                                  handleAddNewClickDevice();
                                  if (inputRef5.current) {
                                    inputRef5.current.focus();
                                  }
                                }}
                                style={{ backgroundColor: "#9d3dd3" }}
                                type="button"
                                className="btn cus_addnew_btn  customaddnewcatebtnsetting"
                              >
                                <span>+</span> Add New Brand
                              </button> 
                                    </div>
                                  )} */}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="form_column col_full">
                              <div className="form-group">
                                <label className="cus_label">
                                  Description (optional)
                                </label>
                                <div className="formfield field_wd100">
                                  <textarea
                                    className="form-control"
                                    name="description"
                                    placeholder="Enter Description"
                                    value={description1}
                                    onChange={handleDescriptionChange1}
                                  ></textarea>
                                </div>{" "}
                                {errormsg && (
                                  <span
                                    style={{
                                      fontSize: "12px",
                                      color: "red",
                                      marginLeft: "110px",
                                    }}
                                  >
                                    {errormsg}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="product_col100 cus_prod_col uploadimagedevicescustm">
                              <div className="uploadimgesrepairticketdevice">
                                <h4>Thumbnail</h4>
                                <p>Upload image of product</p>
                              </div>
                              <div className="form_row">
                                <div className="form_column col_full">
                                  <div className="form-group">
                                    <div className="thumbnail_img">
                                      {images1 ? (
                                        <i
                                          className="fa-regular fa-circle-xmark deletimgcustm1"
                                          onClick={() => HandleDelete1()}
                                        ></i>
                                      ) : null}
                                      {images1 ? (
                                        <>
                                          <img
                                            src={images1}
                                            className="imgescstmdisply"
                                          />
                                        </>
                                      ) : (
                                        <>
                                          <input
                                            type="file"
                                            className="form-control"
                                            name="prod_thumnail"
                                            accept="image/jpeg, image/jpg, image/png"
                                            onChange={handleImages1}
                                          />
                                          <div className="file_upload">
                                            <img
                                              src="assets/img/thumbnail_img.webp"
                                              alt=""
                                            />
                                            <div className="file_txt">
                                              Drop your file here, or{" "}
                                              <span>click to browse</span>
                                            </div>
                                          </div>
                                        </>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="clearfix"></div>

                      <div className="clearfix"></div>
                    </form>
                  </div>
                </div>

                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary default_btn"
                    onClick={() => {
                      //props.onpress(false);
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary theme_btn"
                    onClick={() => OnSubmit()}
                  >
                    Save Device
                  </button>
                </div>
              </div>
            </div>
            {/* <TicketDeviceModal
            onpress={onpress}
            onload={GetCustomerDevice}
            id={selectedCustomerId}
            brandid={selectedBrandId1}
          /> */}
          </div>
        ) : null}

        {showModal === true ? (
          <div
            className="modal fade common_modal addcustomer_modal"
            id="addcustomerModal"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="addcustomerModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="addcustomerModalLabel">
                    Edit Customer
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => {
                      handleModalClose();
                    }}
                  >
                    <span className="close_icon" />
                  </button>
                </div>
                <div className="modal-body">
                  <div className="custom_form customer_form">
                    <form action="" method="" className="cus_form">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <div className="upload_files">
                              {images ? (
                                <img
                                  className="uploaded_img"
                                  src={images}
                                  alt=""
                                />
                              ) : (
                                <img
                                  style={{ display: "none" }}
                                  className="uploaded_img"
                                  src=""
                                  alt=""
                                />
                              )}
                              <div className="file_upload">
                                <input
                                  type="file"
                                  name="files"
                                  accept="image/jpeg, image/jpg, image/png"
                                  onChange={handleImages}
                                />
                                <div className="fileedit_icon">
                                  <img
                                    src="assets/img/file-edit-icon.webp"
                                    alt=""
                                  />
                                </div>
                              </div>
                              <div className="filetrash_icon deleteimgcust">
                                {/* <button
                            type="button"
                            className="btn btn-primary"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                          >
                            <img src="assets/img/file-trash-icon.webp" alt="" />
                          </button> */}
                                <a
                                  href="#"
                                  onClick={() => {
                                    if (images) {
                                      HandleDelete();
                                    }
                                  }}
                                >
                                  <img
                                    src="assets/img/file-trash-icon.webp"
                                    alt=""
                                  />
                                </a>
                              </div>
                            </div>
                            {imageMsg && (
                              <span className="customvalidationdesignlogin">
                                {imageMsg}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-12">
                          <h5>Customer Info</h5>
                        </div>

                        <div className="col-md-6">
                          <div className="form_column col_half">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                name="first_name"
                                placeholder="First Name"
                                value={firstName}
                                onChange={(e) => {
                                  setFirstName(e.target.value.trim());
                                  if (error.firstName) {
                                    setError({ ...error, firstName: "" });
                                  }
                                }}
                              />
                              {error.firstName && (
                                <span className="customvalidationdesignlogin">
                                  {error.firstName}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 lastnamecust">
                          <div className="form_column col_half">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                name="last_name"
                                placeholder="Last Name"
                                value={lastName}
                                onChange={(e) => {
                                  setLastName(e.target.value.trim());
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form_column col_half">
                            <div className="form-group">
                              <input
                                type="email"
                                className="form-control"
                                name="email"
                                placeholder="Email Address"
                                value={email}
                                onChange={(e) => {
                                  setEmail(e.target.value.trim());
                                  if (error.email) {
                                    setError({ ...error, email: "" });
                                  }
                                }}
                              />
                              {error.email && (
                                <span className="customvalidationdesignlogin">
                                  {error.email}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 lastnamecust">
                          <div className="form_column col_half">
                            <div className="form-group">
                              <PhoneInput
                                className="form-control"
                                placeholder="Enter phone number"
                                international
                                countryCallingCodeEditable={false}
                                //limitMaxLength={10}
                                value={phoneNo}
                                onChange={(val) => {
                                  handlePhoneChange(val);
                                  // if (error.phoneNo) {
                                  //   setError({ ...error, phoneNo: "" });
                                  // }
                                  setValidState(true);
                                }}
                                //   onKeyup={setValidState(true)}
                                defaultCountry={countrycode.country_code}
                                //defaultCountry="IN"
                                // defaultCountry={country.countryCode}
                                // error={
                                //   value
                                //     ? isValidPhoneNumber(value)
                                //       ? undefined
                                //       : "Invalid phone number"
                                //     : "Phone number required"
                                // }
                              />

                              {validstate === true ? (
                                <span className="customvalidationdesignlogin">
                                  {phoneNo && isValidPhoneNumber(phoneNo)
                                    ? ""
                                    : "Invalid phone number"}
                                </span>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <h5>Address</h5>
                        </div>
                        <div className="col-md-12">
                          <div className="form_column col_full">
                            <div className="form-group">
                              <textarea
                                className="form-control"
                                name="address"
                                placeholder="Street Address"
                                value={address}
                                onChange={(e) => {
                                  setAddress(e.target.value);
                                }}
                              ></textarea>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form_column col_half">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                name="country"
                                placeholder="Country"
                                value={country}
                                onChange={(e) => {
                                  setCountry(e.target.value.trim());
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form_column col_half">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                name="city"
                                placeholder="City"
                                value={city}
                                onChange={(e) => {
                                  SetCity(e.target.value.trim());
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form_column col_half">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                name="state"
                                placeholder="State / Province"
                                value={state}
                                onChange={(e) => {
                                  setState(e.target.value.trim());
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form_column col_half">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                name="zipcode"
                                placeholder="Zip Code"
                                value={zipcode}
                                onChange={(e) => {
                                  setZipcode(e.target.value.trim());
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        {message && (
                          <span className="customvalidationdesignlogin">
                            {message}
                          </span>
                        )}
                      </div>
                    </form>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary default_btn"
                    data-dismiss="modal"
                    onClick={() => {
                      handleModalClose();
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary theme_btn"
                    onClick={() => SubmitCus()}
                  >
                    Save Customer
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : null}
        <div style={{ display: "none" }}>
          <div className="recieptlabelprint">
            <div
              style={{
                width: `${width}mm`,
                height: `${height}mm`,
                textAlign: "left",
                fontSize: 12,
                margin: 0,
                padding: 0,
              }}
              border={0}
            >
              <div style={{ textAlign: "left" }} border={0}>
                <div
                  style={{
                    padding: "5px 0px",
                    fontSize: 12,
                    fontWeight: 600,
                    //marginTop: 20,
                    color: "#000",
                  }}
                >
                  {firstName} {lastName}
                </div>
                {selectedOption.customerPhone === 1 && phoneNo ? (
                  <div
                    style={{
                      padding: "5px 0px",
                      fontSize: 12,
                      fontWeight: 400,
                      // marginTop: 1,
                      color: "#000",
                    }}
                  >
                    {phoneNo}
                  </div>
                ) : null}
                {selectedOption.customerEmail === 1 && email ? (
                  <div
                    style={{
                      padding: "5px 0px",
                      fontSize: 12,
                      fontWeight: 400,
                      // marginTop: 1,
                      color: "#000",
                    }}
                  >
                    {email}
                  </div>
                ) : null}

                <div
                  style={{
                    padding: "5px 0px",
                    fontSize: 12,
                    fontWeight: 800,
                    // marginTop: 5,
                    color: "#000",
                  }}
                >
                  {customerDetails.brand === 1 && selectedBrand1
                    ? selectedBrand1
                    : null}{" "}
                  {customerDetails.model === 1 && selectedDevice
                    ? selectedDevice
                    : null}
                  <br />
                  {customerDetails.service === 1 &&
                  `${selectedService1} ${selectedService}`
                    ? `${selectedService1} ${selectedService}`
                    : null}
                  <br />
                  {customerDetails.imei === 1 && imeiNumber ? imeiNumber : null}
                  <br />
                  {customerDetails.password === 1 && password ? password : null}
                </div>
              </div>

              <div style={{ textAlign: "left" }} border={0}>
                <div>
                  <span
                    style={{
                      fontSize: 12,
                      fontWeight: 400,
                      // marginTop: 5,
                      color: "#000",
                      display: "block",
                    }}
                  >
                    {customerDetails.dueDate === 1 && dueDate ? (
                      <>Due:{moment(dueDate).format("MMMM Do YYYY, h:mm a")}</>
                    ) : null}
                  </span>
                  {/* <img src="assets/img/barcode-img.png" alt="" /> */}
                  <span
                    style={{
                      padding: 0,
                      fontSize: 12,
                      fontWeight: 400,
                      // marginTop: 8,
                      color: "#000",
                      display: "block",
                    }}
                  >
                    Ticket
                    <span style={{ fontWeight: 600 }}>#{ticketId}</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default TicketDetail;
