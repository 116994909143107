import React, { useEffect, useState, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import Footer from "../Footer";
import NavBar from "./NavBar";
import MainSidebar from "./MainSidebar";
import {
  AddSubUserApi,
  AddUserRoleApi,
  CheckOutApi,
  ForgotPasswordSubApi,
  GetAllRolePermissionApi,
  GetAllSubUserApi,
  GetAllSubscriptionApi,
  GetAllUserRoleApi,
  GetSubUserProfileApi,
  PlansAndBillingApi,
  UpdatePasswordApi,
  baseurl,
  getProfileApi,
  updateUserApi,
  uploadImageApi,
} from "../Utility/Api";
import moment from "moment";
import Swal from "sweetalert2";
import { loadStripe } from "@stripe/stripe-js";
import CompanyEdit from "./CompanyEdit";
import RecieptConfig from "./RecieptConfig";
import StatusOption from "./StatusOption";
import CashLayout from "./CashLayout";
import PaymentOptions from "./PaymentOptions";
import Layouts from "./Layouts";
import DiscountOptions from "./DiscountOptions";
import TaxOptions from "./TaxOptions";
import RecieptLabel from "./RecieptLabel";
import FreeTrail from "./FreeTrail";
import SideUl from "./SideUl";
import Contact from "./Contact";
import TimeSettings from "./TimeSettings";
import { result } from "lodash";

const Settings = () => {
  const [userDetails, setUserDetails] = useState({});
  const [companyDetails, setCompanyDetails] = useState({});
  const [updateUserName, setUpdateUserName] = useState("");
  const [updatePhone, setUpdatePhone] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [images, setImages] = useState("");
  const [checkbox, setCheckbox] = useState(false);
  const [userState, setUserState] = useState(false);
  const [userPhState, setUserPhState] = useState(false);
  const [newUserState, setNewUserState] = useState(false);
  const [createState, setCraeteState] = useState(false);
  const [layoutState, setLayoutState] = useState(false);
  const [userPhone, setUserPhone] = useState("");
  const [userRoleEmail, setUserRoleEmail] = useState("");
  const [UserName, setUserName] = useState("");
  const [showList, setShowList] = useState(false);
  const [selectedRole, setSelectedRole] = useState("");
  const [selectedRoleId, setSelectedRoleId] = useState("");
  const [AllRole, setAllRole] = useState([]);
  const [searchTermRole, setSearchTermRole] = useState("");
  const [typedText, setTypedText] = useState("");
  const inputRef2 = useRef(null);
  const [error, setError] = useState({});
  const [allSubuser, setAllSubuser] = useState([]);
  const [allPermission, setAllPermission] = useState([]);
  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const [adminState, setAdminState] = useState("");
  const [permission, setPermission] = useState([]);
  const dropdownRef = useRef(null);
  const [UpgradeState, setUpgradeState] = useState(false);
  const [activeState, setActiveState] = useState(0);
  const [planData, setPlanData] = useState([]);
  const [planNameId, setPlanNameId] = useState("");
  const [price, setPrice] = useState("");
  const [planName, setPlanName] = useState("");
  const [duration, setDuration] = useState("");
  const [listState, setListState] = useState(false);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState("");
  const [editState, setEditState] = useState(false);
  const [editId, setEditId] = useState("");
  const [permissionError, setPermissionError] = useState(null);
  const [password, setPassword] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showModal2, setShowModal2] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [message, setMessage] = useState("");
  const [customers, setCustomers] = useState("");
  const [invoiceCount, setInvoiceCount] = useState("");
  const [salesCount, setSalesCount] = useState("");
  const [CustomerPercent, setCustomerPercent] = useState("");
  const [InvoicePercent, setInvoicePercen] = useState();
  const [SalesPercent, setSalesPercent] = useState();
  const [companyImage, setCompanyImage] = useState("");
  //const [userDetails, setUserDetails] = useState({});
  //const [companyDetails, setCompanyDetails] = useState({});
  const [companyName, setCompanyName] = useState("");
  const [companyPhone, setCompanyPhone] = useState("");
  const [companyService, setCompanyService] = useState("");
  const [country, setCountry] = useState("");
  const [address, setAddress] = useState("");
  const [state, setState] = useState("");
  const [zipcode, setZipcode] = useState("");
  //const [loading, setLoading] = useState(false);
  const [images1, setImages1] = useState("");
  const [StartDate, setStartDate] = useState("");
  const [EndDate, setEndDate] = useState("");
  const [selectAll, setSelectAll] = useState(false);

  // Function to handle "Select All" checkbox change
  const handleSelectAll = () => {
    if (!selectAll) {
      // If "Select All" is checked, select all permissions
      const allIds = allPermission.map((item) => item.id);
      setSelectedPermissions(allIds);
    } else {
      // If "Select All" is unchecked, unselect all permissions
      setSelectedPermissions([]);
    }
    setSelectAll(!selectAll);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const togglePasswordVisibility1 = () => {
    setShowPassword1(!showPassword1);
  };
  const togglePasswordVisibility2 = () => {
    setShowPassword2(!showPassword2);
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowList(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const handleCheckboxChange = (permissionId) => {
    if (selectedPermissions.includes(permissionId)) {
      setSelectedPermissions((prevSelected) =>
        prevSelected.filter((id) => id !== permissionId)
      );
    } else {
      setSelectedPermissions((prevSelected) => [...prevSelected, permissionId]);
    }
    setPermissionError(null);
  };

  const handleCheckboxChangeedit = (permissionId) => {
    if (selectedPermissions.includes(permissionId)) {
      setSelectedPermissions((prevSelected) =>
        prevSelected.filter((id) => id !== permissionId)
      );
    } else {
      setSelectedPermissions((prevSelected) => [...prevSelected, permissionId]);
    }
    setPermissionError(null);
  };
  const isDisposableEmail = (email) => {
    const domain = email.split("@")[1];
    return disposableEmailDomains.includes(domain);
  };

  const disposableEmailDomains = [
    "gmail.com",
    "yahoo.com",
    "outlook.com",
    "hotmail.com",
    "aol.com",
    "icloud.com",
    "mail.com",
    "yandex.com",
    "protonmail.com",
    "zoho.com",
    "gmx.com",
    "ymail.com",
    "comcast.net",
    "me.com",
    "msn.com",
    "live.com",
    "sbcglobal.net",
    "verizon.net",
    "att.net",
    "cox.net",
    "smartitventures.com",
    // "yopmail.com",
    // "mailinator.com",
    // "dispostable.com",
    // "10minutemail.com",
    // "temp-mail.org",
    // "guerrillamail.com",
    // "throwawaymail.com",
    // "mohmal.com",
    // "trashmail.com",
    // Add more domains as needed
  ];

  const Validation = () => {
    let error = {};
    let reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    if (!userRoleEmail) {
      error.userRoleEmail = "Email is required.";
    } else if (!reg.test(userRoleEmail)) {
      error.userRoleEmail = "Invalid email .";
    } else if (!isDisposableEmail(userRoleEmail)) {
      error.userRoleEmail =
        "Please use a valid email address. Temporary email addresses are not allowed.";
    }
    if (!selectedRole) {
      error.selectedRole = "Please select role permission.";
    }
    if (Object.keys(error).length === 0) {
      AddSubuser();
    }
    return error;
  };
  const Submit = () => {
    if (selectedPermissions?.length === 0) {
      setPermissionError("Please select at least one permission.");
    } else {
      setError(Validation());
    }
  };

  const Validation1 = () => {
    let error = {};
    let reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    if (!userRoleEmail) {
      error.userRoleEmail = "Email is required.";
    } else if (!reg.test(userRoleEmail)) {
      error.userRoleEmail = "Invalid email .";
    } else if (!isDisposableEmail(userRoleEmail)) {
      error.userRoleEmail =
        "Please use a valid email address. Temporary email addresses are not allowed.";
    }
    if (!selectedRole) {
      error.selectedRole = "Please select role permission.";
    }
    if (Object.keys(error).length === 0) {
      EditSubUser();
    }
    return error;
  };

  const Submit1 = () => {
    if (selectedPermissions.length === 0) {
      setPermissionError("Please select at least one permission.");
    } else {
      setError(Validation1());
    }
  };

  const handleSwitchChange = (e) => {
    const isChecked = e.target.checked;
    setCheckbox(isChecked);
  };

  const ResetPassword = () => {
    setLoading(true);
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);

    const raw = JSON.stringify({
      email: userRoleEmail,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(ForgotPasswordSubApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === true) {
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const location = useLocation();
  const [userRolee, setUserRolee] = useState("");

  useEffect(() => {
    PlansAndBilling();
    GetSubscriptionPlan();

    if (location?.state?.activeState === 1) {
      setActiveState(1);
    }
    if (location?.state?.activeState === 11) {
      setActiveState(11);
    }
    if (location?.state?.activeState === 6) {
      setActiveState(6);
    }
    let localAdmin = localStorage.getItem("admin");
    let localPerminssion = JSON.parse(localStorage.getItem("permission"));
    let userRole = localStorage.getItem("userRole");
    console.log("userRole", userRole);
    setUserRolee(userRole);
    setAdminState(localAdmin);
    console.log("adminstate", localAdmin);
    setPermission(localPerminssion);
    if (localAdmin === "0" && localPerminssion?.length) {
      GetSubUserProfile();
    }

    // GetSubUserProfile();
    else {
      GetProfile();
    }
    GetAllUserRole();
    GetAllSubUser();
    GetAllRolePermission();
    let plan = localStorage.getItem("planDate");
  }, []);

  const GetProfile = () => {
    let local = localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(getProfileApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true) {
          // console.log("profile", result);
          //   setData(result?.data);
          setStatus(
            result?.data?.comapany?.subscriptionStatus === "canceled" ||
              result?.data?.comapany?.subscriptionStatus === null ||
              result?.data?.comapany?.subscriptionStatus === "expired"
          );
          // localStorage.setItem(
          //   "substatus",
          //   result?.data?.comapany?.subscriptionStatus
          // );
          // localStorage.setItem("planDate", result?.data?.comapany?.planDate);
          setUserDetails(result?.data?.userDetails);
          setUpdateUserName(result?.data?.userDetails?.userName);
          setUpdatePhone(result?.data?.userDetails?.phoneNumber);
          setUserEmail(result?.data?.userDetails?.email);
          setImages(result?.data?.userDetails?.profileImage);
          setCompanyImage(result?.data?.companyDetails?.image);

          setCompanyDetails(result?.data?.companyDetails);
          setCompanyName(result?.data?.companyDetails?.companyName);
          setCompanyPhone(result?.data?.companyDetails?.companyPhoneNo);
          setCompanyService(result?.data?.companyDetails?.companyServiceNo);
          setCountry(result?.data?.companyDetails?.country);
          setAddress(result?.data?.companyDetails?.streetAddress);
          setState(result?.data?.companyDetails?.state);
          setZipcode(result?.data?.companyDetails?.zipCode);
          setImages1(result?.data?.companyDetails?.image);
          const timestamp = result?.data?.companyDetails?.subscriptionStartDate;
          if (timestamp) {
            const date = new Date(timestamp * 1000);

            const formattedDate = date.toLocaleDateString("en-GB", {
              day: "2-digit",
              month: "short",
              year: "numeric",
            });

            // console.log("formattedDate", formattedDate);
            setStartDate(formattedDate);
          }

          const timestamp1 = result?.data?.companyDetails?.subscriptionEndDate;
          if (timestamp1) {
            const date1 = new Date(timestamp1 * 1000);

            const formattedDate1 = date1.toLocaleDateString("en-GB", {
              day: "2-digit",
              month: "short",
              year: "numeric",
            });

            // console.log("formattedDate1", formattedDate1);
            setEndDate(formattedDate1);
          }
        } else {
          console.log(result.message);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const GetSubUserProfile = () => {
    let local = localStorage.getItem("token");

    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(GetSubUserProfileApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log("subuser profile", result);
        if (result.status === true) {
          //   setData(result?.data);
          setStatus(
            result?.data?.comapany?.subscriptionStatus === "canceled" ||
              result?.data?.comapany?.subscriptionStatus === null ||
              result?.data?.comapany?.subscriptionStatus === "expired"
          );
          setUserDetails(result?.data?.userDetails);
          setUpdateUserName(result?.data?.userDetails?.name);
          setUpdatePhone(result?.data?.userDetails?.phoneNumber);
          setUserEmail(result?.data?.userDetails?.email);
          setImages(result?.data?.userDetails?.profileImage);
          setCompanyImage(result?.data?.companyDetails?.image);

          setCompanyDetails(result?.data?.companyDetails);
          setCompanyName(result?.data?.companyDetails?.companyName);
          setCompanyPhone(result?.data?.companyDetails?.companyPhoneNo);
          setCompanyService(result?.data?.companyDetails?.companyServiceNo);
          setCountry(result?.data?.companyDetails?.country);
          setAddress(result?.data?.companyDetails?.streetAddress);
          setState(result?.data?.companyDetails?.state);
          setZipcode(result?.data?.companyDetails?.zipCode);
          setImages1(result?.data?.companyDetails?.image);
        } else {
          console.log(result.message);
        }
      })
      //   if (result.status === true) {
      //     setDataUser(result?.data);
      //   } else {
      //     console.log(result.message);
      //   }
      // })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const UpdateUser = () => {
    setLoading(true);
    let local = localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);

    var raw = JSON.stringify({
      userName: updateUserName,
      phoneNumber: updatePhone,
      profileImage: images,
      twoFactorAuthentication: checkbox ? "1" : "0",
      twoFactorAuthenticationEnableDate: moment(new Date()).format(
        "YYYY-MM-DD HH:mm:ss"
      ),
      emailVerify: 1,
      emailVerifyDate: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
    });

    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(updateUserApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true) {
          setLoading(false);
          GetProfile();
        } else {
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log("error", error);
      });
  };

  const OnUpdate = () => {
    UpdateUser();
  };

  const handleImages = (e) => {
    var file = e.target.files[0];
    let size = e.target.files[0].type.replace("/", ".");
    var blob = file.slice(0, file.size, e.target.files[0].type);
    const newName = new Date().getTime() + size;
    var newFile = new File([blob], newName, { type: e.target.files[0].type });
    UploadImage(newFile);
  };

  const UploadImage = async (newFile) => {
    setLoading(true);
    var formdata = new FormData();
    formdata.append("file", newFile);
    formdata.append("type", "users-images");

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(uploadImageApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === true) {
          setImages(result?.data);
          setLoading(false);
        } else {
          Swal.fire(result?.message);
          setLoading(false);
        }
        // setImages(result?.data);
        // setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log("error", error);
      });
  };

  const filterRole = AllRole.filter((item) => {
    const name = item.name;

    if (name && typeof name === "string") {
      return name.toLowerCase().includes(searchTermRole.toLowerCase());
    }

    return false;
  });
  const AddUserRole = () => {
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);

    const raw = JSON.stringify({
      name: typedText,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(AddUserRoleApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === true) {
          GetAllUserRole();
          setSelectedRoleId(result?.data?.id);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const GetAllUserRole = () => {
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(GetAllUserRoleApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log("getusers", result);
        if (result?.status === true) {
          setAllRole(result?.data);

          console.log(
            "subbbb",
            result?.data?.some((role) => role.name === "Super Admin")
          );
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const AddSubuser = () => {
    setLoading(true);
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);

    const raw = JSON.stringify({
      name: UserName,
      email: userRoleEmail,
      phoneNumber: userPhone,
      userRoleId: selectedRoleId,
      permissionIds: selectedPermissions,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(AddSubUserApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === true) {
          setCraeteState(false);
          GetAllSubUser();
          setUserRoleEmail("");
          setUserName("");
          setUserPhone("");
          setSelectedRole("");
          setSelectedRoleId("");
          setSelectedPermissions([]);
          setSelectAll(false);
          // Swal.fire(result?.message);
          Swal.fire({
            icon: "success",
            title: result?.message,
            text: "Please check your email for further details.",
          });
          setLoading(false);
        } else {
          Swal.fire(result?.message);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };

  const GetAllSubUser = () => {
    setLoading(true);
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(GetAllSubUserApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log("subuser", result);
        if (result?.status === true) {
          setAllSubuser(result?.data);
          setLoading(false);
        } else {
          setLoading(false);
          setAllSubuser(result?.data);
        }
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };

  const GetAllRolePermission = () => {
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(GetAllRolePermissionApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setAllPermission(result?.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleSearchChangeRole = (e) => {
    setSearchTermRole(e.target.value);
    setTypedText(e.target.value);
    setSelectedRole(null);
    // if (error.selectedRole) {
    //   setError({
    //     ...error,
    //     selectedRole: "",
    //   });
    // }
  };

  const handleButtonClickRole = (e) => {
    e.preventDefault();
    setShowList(!showList);
  };

  const handleRoleSelect = (item) => {
    setSelectedRole(item.name);
    setSelectedRoleId(item.id);
    setShowList(false);
    if (error.selectedRole) {
      setError({
        ...error,
        selectedRole: "",
      });
    }
  };

  const handleAddNewClickRole = (e) => {
    if (!typedText.trim()) {
      Swal.fire({
        title: "Please enter a valid Role name!",
        customClass: {
          container: "custom-swal-container",

          confirmButton: "custom-swal-confirm-button",
        },
      });

      return;
    }
    setSelectedRole(typedText);
    setShowList(false);
    setSearchTermRole("");
    setTypedText("");
    AddUserRole();
  };

  const makePayment = async () => {
    setLoading(true);

    const stripePromise = await loadStripe(
      //"pk_test_51MbzndSEIkEUYvlTFyI5oOGcpdZytAek4h89XJaETt80sASktkypxjvCQ5kadtkGlrf7fY4VbS21IBAanjx5wUfx000afVwaQv"
      "pk_live_51NnjQ6APceLgLRS8bX3OAGjbWiHzL0D7LPD9zpBsqrEmcVVuejLu87JIAzZp7p2wrRigTFRl8RZn6EhthKeL0yoR00eCYcozpU"
      // "pk_test_51PfgP0Cd0MIKYSkgOG3b2vi2MddYoqjSQqm12LPBpg7Msc1DmBX28HFQF4Vl8vrGjz8ehHBmx5IoGSLfVnNwuBM400arTu6d2Z"
    );

    const raw = JSON.stringify({
      email: userEmail,
    });
    // console.log("checkout", raw);
    const response = await fetch(CheckOutApi, {
      method: "POST",
      headers: {
        "Content-Type": "Application/JSON",
      },
      body: raw,
    });
    // console.log("response", response);

    if (response.status === 409) {
      setLoading(false);
      const data = await response.json();
      // console.log("data", data);
      if (data && data.redirectUrl) {
        setLoading(false);
        window.location.href = data.redirectUrl;
      }
    } else {
      setLoading(false);
      const session = await response.json();
      // console.log("session", session);
      stripePromise.redirectToCheckout({
        sessionId: session.id,
      });
    }
  };

  const GetSubscriptionPlan = () => {
    let local = localStorage.getItem("token");

    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(GetAllSubscriptionApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log("plan data", result);
        if (result?.status === true) {
          setPlanData(result?.data);
          setPlanNameId(result?.data[0]?.id);
          setPlanName(result?.data[0]?.name);
          setPrice(result?.data[0]?.price);
          setDuration(result?.data[0]?.duration);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const Checkout = () => {
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);
    const raw = JSON.stringify({
      subscriptionPlanId: planNameId,
      price: price,
      startDate: new Date(),
      endDate: new Date(),
      products: [
        {
          name: planName,
          price: price,
        },
      ],
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(CheckOutApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log(result);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const userData = localStorage.getItem("substatus");

  const HandleEdit = (item) => {
    setUserName(item?.subUser?.name);
    setUserRoleEmail(item?.subUser?.email);
    setUserPhone(item?.subUser?.phoneNumber);
    setSelectedRole(item?.subUser?.userRole?.name);
    setSelectedRoleId(item?.subUser?.userRole?.id);
    setEditId(item?.subUser?.id);

    // Parse permissionIds to get the array
    const permissions = JSON.parse(item?.subUser?.permissionIds);

    // Check if the length of permissions is 11 or more
    if (permissions.length >= 11) {
      setSelectedPermissions(permissions);
      setSelectAll(true); // Set select all to true if there are 11 or more permissions
    } else {
      setSelectedPermissions(permissions);
      setSelectAll(false); // Set select all to false if there are less than 11 permissions
    }
  };

  // const HandleEdit = (item) => {
  //   setUserName(item?.subUser?.name);
  //   setUserRoleEmail(item?.subUser?.email);
  //   setUserPhone(item?.subUser?.phoneNumber);
  //   setSelectedRole(item?.subUser?.userRole?.name);
  //   setSelectedRoleId(item?.subUser?.userRole?.id);
  //   setEditId(item?.subUser?.id);
  //   if (JSON.parse(item?.subUser?.permissionIds) > 11) {
  //     setSelectedPermissions(JSON.parse(item?.subUser?.permissionIds));
  //     setSelectAll(true);
  //   } else {
  //     setSelectedPermissions(JSON.parse(item?.subUser?.permissionIds));
  //     setSelectAll(false);
  //   }
  // };

  const EditSubUser = () => {
    setLoading(true);
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);

    const raw = JSON.stringify({
      name: UserName,
      email: userRoleEmail,
      phoneNumber: userPhone,
      userRoleId: selectedRoleId,
      permissionIds: selectedPermissions,
    });

    const requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${baseurl}updateSubUser/${editId}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === true) {
          setCraeteState(false);
          setEditState(false);
          GetAllSubUser();

          Swal.fire(result?.message);
          setLoading(false);
        } else {
          Swal.fire(result?.message);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };

  const DeleteUser = (id) => {
    setLoading(true);
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    const requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${baseurl}deleteSubUser/${id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === true) {
          setLoading(false);
          Swal.fire(result?.message);
          GetAllSubUser();
        } else {
          setLoading(false);
          Swal.fire(result?.message);
        }
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };

  const HandleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete subuser",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#c76cff",
      cancelButtonColor: "#efefef",
      confirmButtonText: "Yes, delete it!",
      confirmButtonText: "<span style='color: white;'>Yes, delete it!</span>",
      cancelButtonText: "<span style='color: black;'>cancel!</span>",
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      customClass: {
        container: "sweet-alert2-container-zindex",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Deleted!",
          text: "Your data has been deleted.",
          icon: "success",
          confirmButtonColor: "#c76cff",
          customClass: {
            container: "sweet-alert2-container-zindex",
          },
        }).then(() => {
          DeleteUser(id);
        });
      }
    });
  };

  const HandleReset = async () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to Reset Your Password",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#c76cff",
      cancelButtonColor: "#efefef",
      confirmButtonText: "<span style='color: white;'>Yes, Reset it!</span>",
      cancelButtonText: "<span style='color: black;'>Cancel!</span>",
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      customClass: {
        container: "sweet-alert2-container-zindex",
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          // Call the ResetPassword function
          const result = await ResetPassword();

          // Show success message
          Swal.fire({
            title: "Reset Password!",
            text: "Your password has been changed. Please check your email for further details.",
            icon: "success",
            confirmButtonColor: "#c76cff",
            customClass: {
              container: "sweet-alert2-container-zindex",
            },
          });
        } catch (error) {
          // Show error message
          Swal.fire({
            title: "Error",
            text: error.message || "Failed to reset password.",
            icon: "error",
            confirmButtonColor: "#c76cff",
            customClass: {
              container: "sweet-alert2-container-zindex",
            },
          });
          console.error(error);
        }
      }
    });
  };

  const Validationp = () => {
    let error = {};
    let reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+*!=])(?=.*\d).{6,}$/;
    // const isValid = /^[0-9]+$/.test(value);
    const regex = /^[A-Za-z ]+$/;

    if (!password) {
      error.password = "Password is required.";
    } else if (password.length < 8) {
      error.password = "Password must be at least 8 characters long.";
    } else if (!passwordRegex.test(password)) {
      error.password =
        "Password must have at least 1 uppercase letter, 1 lowercase letter, 1 special character and 1 number.";
    }
    if (!oldPassword) {
      error.oldPassword = "Old Password is Required.";
    }

    if (!confirmPassword) {
      error.confirmPassword = "Confirm Password is Required.";
    } else if (confirmPassword !== password) {
      error.confirmPassword = "Confirm Password is not match.";
    }
    if (Object.keys(error).length === 0) {
      UpdatePassword();
    }
    return error;
  };

  const SubmitPassword = () => {
    setError(Validationp());
  };

  const UpdatePassword = () => {
    let local = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);

    const raw = JSON.stringify({
      password: password,
      oldPassword: oldPassword,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(UpdatePasswordApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === true) {
          setShowModal2(false);
          setOldPassword("");
          setPassword("");
          setMessage("");
          setConfirmPassword("");
          document
            .querySelectorAll(".modal-backdrop")
            .forEach((el) => el.classList.remove("modal-backdrop"));
          Swal.fire(result?.message);
        } else {
          // setShowModal2(false);
          setMessage(result?.message);
          //Swal.fire(result?.message);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const loading1 = () => {
    setLoading(true);
  };

  const PlansAndBilling = () => {
    let local = localStorage.getItem("token");

    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(PlansAndBillingApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true) {
          // console.log("pla date", result);
          setCustomers(result?.data?.CustomerCount);
          setSalesCount(result?.data?.totalSalesAmount.toFixed(2));
          setInvoiceCount(result?.data?.InvoiceCount);
          const cs = parseFloat(result?.data?.CustomerCount);
          const pc = parseFloat(result?.data?.PreviousMotnhCustomerCount);
          const per = (cs / pc) * 100;
          if (pc === 0) {
            setCustomerPercent(100);
          } else {
            setCustomerPercent(per.toFixed(2));
          }

          const cs1 = parseFloat(result?.data?.InvoiceCount);
          const pc1 = parseFloat(result?.data?.PreviousInvoiceCount);
          let per1 = 0; // Initialize per1 to 0 by default

          if (!isNaN(cs1) && !isNaN(pc1) && pc1 !== 0) {
            per1 = (cs1 / pc1) * 100;
          }

          // Check if per1 is Infinity or NaN and set it to 0 if it is
          if (!isFinite(per1)) {
            per1 = 0;
          }

          setInvoicePercen(Number(per1.toFixed(2)));
          // const per1 = (cs1 / pc1) * 100;
          // setInvoicePercen(Number(per1.toFixed(2)));

          const cs2 = parseFloat(result?.data?.totalSalesAmount);
          const pc2 = parseFloat(result?.data?.previousTotalSalesAmount);
          let per2 = 0; // Initialize per2 to 0 by default

          if (!isNaN(cs2) && !isNaN(pc2) && pc2 !== 0) {
            per2 = (cs2 / pc2) * 100;
          }

          // Check if per2 is Infinity or NaN and set it to 0 if it is
          if (!isFinite(per2)) {
            per2 = 0;
          }

          setSalesPercent(Number(per2.toFixed(2)));
          // const per2 = (cs2 / pc2) * 100;
          // setSalesPercent(Number(per2.toFixed(2)));
          //setDataUser(result?.data);
        } else {
          console.log(result.message);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const CompanyUpdate = () => {
    setLoading(true);
    let local = localStorage.getItem("token");

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);

    const raw = JSON.stringify({
      companyName: companyName,
      companyPhoneNo: companyPhone,
      companyServiceNo: companyService,
      country: country,
      streetAddress: address,
      state: state,
      zipCode: zipcode,
      image: images1,
    });

    const requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${baseurl}updateCompany/${userDetails?.companyId}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === true) {
          setLoading(false);
          GetProfile();
          setCompanyName(result?.data?.companyName);
          setCompanyPhone(result?.data?.companyPhoneNo);
          setCompanyService(result?.data?.companyServiceNo);
          setCountry(result?.data?.country);
          setAddress(result?.data?.streetAddress);
          setState(result?.data?.state);
          setZipcode(result?.data?.zipCode);
          setImages1(result?.data?.image);
        } else {
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };

  const handleImages1 = (e) => {
    var file = e.target.files[0];
    let size = e.target.files[0].type.replace("/", ".");
    var blob = file.slice(0, file.size, e.target.files[0].type);
    const newName = new Date().getTime() + size;
    var newFile = new File([blob], newName, { type: e.target.files[0].type });
    UploadImage1(newFile);
  };

  const UploadImage1 = async (newFile) => {
    setLoading(true);
    var formdata = new FormData();
    formdata.append("file", newFile);
    formdata.append("type", "company-images");

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(uploadImageApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setImages1(result?.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log("error", error);
      });
  };

  const [isFreeTrial, setIsFreeTrial] = useState(false);

  useEffect(() => {
    const substatus = localStorage.getItem("substatus");
    const planDate = localStorage.getItem("planDate");

    if (
      substatus === "null" ||
      substatus === "canceled" ||
      substatus === "expired"
    ) {
      setIsFreeTrial(true);
    }
  }, []);

  const [isSidebarVisible, setSidebarVisible] = useState(false);

  const toggleSidebar = () => {
    setSidebarVisible(!isSidebarVisible);
    localStorage.setItem("sidebar", !isSidebarVisible);
  };

  useEffect(() => {
    const savedSidebarState = localStorage.getItem("sidebar");
    if (savedSidebarState) {
      setSidebarVisible(JSON.parse(savedSidebarState));
    }
  }, []);
  const [isFreeTrialVisible, setFreeTrialVisible] = useState(true);

  const toggleFreeTrial = () => {
    setFreeTrialVisible(!isFreeTrialVisible);
  };

  const sidebarStyleone = {
    overflow: "auto",
    scrollbarWidth: "none",
    msOverflowStyle: "none",
    // position: 'sticky',

    // marginTop: isFreeTrialVisible ? "0px" : "0px",
  };

  const navbarStyle = {
    // top: isFreeTrialVisible ? "-4px" : "-50px",
  };
  const mainContentStyle = {
    // marginTop: isFreeTrialVisible ? "132px" : "92px",
  };

  useEffect(() => {
    if (isFreeTrial) {
      // toggleSidebar(false);
      toggleFreeTrial(false);
    } else {
      // toggleSidebar(true);
      toggleFreeTrial(true);
    }
  }, [isFreeTrial]);
  useEffect(() => {
    const handleClickOutside = (event) => {
      const sidebarElement = document.querySelector(".main-sidebar");
      const toggleButtonElement = document.querySelector(".sidebar-arrow a");

      if (
        sidebarElement &&
        !sidebarElement.contains(event.target) &&
        toggleButtonElement &&
        !toggleButtonElement.contains(event.target)
      ) {
        const mediaQuery = window.matchMedia("(max-width: 1024px)");
        if (mediaQuery.matches) {
          setSidebarVisible(false);
        }
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const getNextPaymentDate = (startDate) => {
    const date = new Date(startDate);
    date.setMonth(date.getMonth() + 1);
    return date.toISOString().split("T")[0]; // Format date to YYYY-MM-DD
  };

  const getStartAndNextPaymentDates = () => {
    const substatus = localStorage.getItem("substatus");
    if (substatus && !["null", "canceled", "expired"].includes(substatus)) {
      const startDate = new Date().toISOString().split("T")[0]; // Current date as start date
      const nextPaymentDate = getNextPaymentDate(startDate);
      return { startDate, nextPaymentDate };
    }
    return { startDate: "N/A", nextPaymentDate: "N/A" };
  };

  const { startDate, nextPaymentDate } = getStartAndNextPaymentDates();

  return (
    <>
      <div className={isSidebarVisible ? "sidebar-mini" : ""}>
        <div
          className={`${
            isFreeTrial
              ? "main-wrapper devicelistcstmtableres customclswrapper"
              : "main-wrapper devicelistcstmtableres "
          } `}
        >
          {loading ? (
            <div className="landing_loader">
              <div className="innerloader">
                <img src="img/spin-1s-156px.gif" alt="" />
              </div>
            </div>
          ) : null}

          {isFreeTrial ? (
            <>
              {localStorage.getItem("substatus") === "null" ||
              localStorage.getItem("substatus") === "canceled" ||
              localStorage.getItem("substatus") === "expired" ? (
                <>
                  <div className="app-container-trial">
                    <FreeTrail />
                  </div>
                  <div
                    // className={`${isSidebarVisible ? "sidebar-mini" : ""} ${
                    //   !isFreeTrialVisible ? "no-free-trial" : ""
                    // }`}
                    style={sidebarStyleone}
                  >
                    <nav
                      className="navbar navbar-expand-lg main-navbar sticky"
                      style={navbarStyle}
                    >
                      <div className="sidebar-arrow">
                        <a
                          href="#"
                          data-toggle="sidebar"
                          onClick={(e) => {
                            toggleSidebar();

                            e.preventDefault();
                          }}
                        >
                          <img src="assets/img/sidebar-toggle.webp" alt="" />
                        </a>
                      </div>
                      <div className="header-title mr-auto">
                        <h4>Dashboard</h4>
                      </div>
                      <SideUl image={images} name={updateUserName} />
                    </nav>
                    <div className="main-wrapper devicelistcstmtableres ">
                      <MainSidebar image={images1} companyname={companyName} />

                      <div
                        className="main-content tableback"
                        style={mainContentStyle}
                      >
                        <div className="dash_setting">
                          <div className="setting_tabs_content">
                            {(userData === "null" ||
                              userData === "cancelled" ||
                              userData === "expired") &&
                            new Date(localStorage.getItem("planDate")) <
                              new Date() ? null : (
                              <>
                                <ul
                                  className="nav nav-pills setting_tabs"
                                  id="setting"
                                  role="tablist"
                                >
                                  <li className="nav-item">
                                    <a
                                      onClick={() => setActiveState(0)}
                                      className={`nav-link ${
                                        activeState === 0 && "active"
                                      }`}
                                      data-toggle="tab"
                                      href="#account_security"
                                      role="tab"
                                      aria-controls="account_security"
                                      aria-selected="true"
                                    >
                                      Account & Security
                                    </a>
                                  </li>

                                  {adminState === "0" &&
                                  permission?.length !== 0 &&
                                  userRolee !== "Super Admin" ? null : (
                                    <li className="nav-item">
                                      <a
                                        onClick={() => setActiveState(1)}
                                        className={`nav-link ${
                                          activeState === 1 && "active"
                                        }`}
                                        data-toggle="tab"
                                        href="#billing_plan"
                                        role="tab"
                                        aria-controls="billing_plan"
                                        aria-selected="true"
                                      >
                                        Plans and Billing
                                      </a>
                                    </li>
                                  )}

                                  <li className="nav-item">
                                    <a
                                      onClick={() => setActiveState(2)}
                                      className={`nav-link ${
                                        activeState === 2 && "active"
                                      }`}
                                      data-toggle="tab"
                                      href="#profile_info"
                                      role="tab"
                                      aria-controls="profile_info"
                                      aria-selected="true"
                                    >
                                      Profile Info
                                    </a>
                                  </li>
                                  <li className="nav-item">
                                    <a
                                      onClick={() => setActiveState(3)}
                                      className={`nav-link ${
                                        activeState === 3 && "active"
                                      }`}
                                      data-toggle="tab"
                                      href="#status_option"
                                      role="tab"
                                      aria-controls="status_option"
                                      aria-selected="true"
                                    >
                                      Status Option
                                    </a>
                                  </li>
                                  <li className="nav-item">
                                    <a
                                      onClick={() => setActiveState(4)}
                                      className={`nav-link ${
                                        activeState === 4 && "active"
                                      }`}
                                      data-toggle="tab"
                                      href="#payment_option"
                                      role="tab"
                                      aria-controls="payment_option"
                                      aria-selected="true"
                                    >
                                      Payment Option
                                    </a>
                                  </li>
                                  <li className="nav-item">
                                    <a
                                      onClick={() => setActiveState(5)}
                                      className={`nav-link ${
                                        activeState === 5 && "active"
                                      }`}
                                      data-toggle="tab"
                                      href="#tax_option"
                                      role="tab"
                                      aria-controls="tax_option"
                                      aria-selected="true"
                                    >
                                      Tax Option
                                    </a>
                                  </li>

                                  <li className="nav-item">
                                    <a
                                      onClick={() => setActiveState(9)}
                                      className={`nav-link ${
                                        activeState === 9 && "active"
                                      }`}
                                      data-toggle="tab"
                                      href="#discount_option"
                                      role="tab"
                                      aria-controls="discount_option"
                                      aria-selected="true"
                                    >
                                      Discount Option
                                    </a>
                                  </li>

                                  <li className="nav-item">
                                    <a
                                      onClick={() => setActiveState(6)}
                                      className={`nav-link ${
                                        activeState === 6 && "active"
                                      }`}
                                      data-toggle="tab"
                                      href="#register_layout"
                                      role="tab"
                                      aria-controls="register_layout"
                                      aria-selected="true"
                                    >
                                      Cash Register Layout
                                    </a>
                                  </li>
                                  <li className="nav-item">
                                    <a
                                      onClick={() => setActiveState(7)}
                                      className={`nav-link ${
                                        activeState === 7 && "active"
                                      }`}
                                      data-toggle="tab"
                                      href="#receipt_config_setup"
                                      role="tab"
                                      aria-controls="receipt_config_setup"
                                      aria-selected="true"
                                    >
                                      Receipt Configuration & Setup
                                    </a>
                                  </li>
                                  <li className="nav-item">
                                    <a
                                      onClick={() => setActiveState(11)}
                                      className={`nav-link ${
                                        activeState === 11 && "active"
                                      }`}
                                      data-toggle="tab"
                                      href="#receipt_label_config_setup"
                                      role="tab"
                                      aria-controls="receipt_label_config_setup"
                                      aria-selected="true"
                                    >
                                      Receipt Label Configuration & Setup
                                    </a>
                                  </li>
                                  {adminState === "0" &&
                                  permission?.length !== 0 &&
                                  userRolee !== "Super Admin" ? null : (
                                    <li
                                      className="nav-item"
                                      onClick={() => setCraeteState(false)}
                                    >
                                      <a
                                        onClick={() => setActiveState(8)}
                                        className={`nav-link ${
                                          activeState === 8 && "active"
                                        }`}
                                        data-toggle="tab"
                                        href="#user_setup"
                                        role="tab"
                                        aria-controls="user_setup"
                                        aria-selected="true"
                                      >
                                        User Setup
                                      </a>
                                    </li>
                                  )}
                                  <li className="nav-item">
                                    <a
                                      onClick={() => setActiveState(12)}
                                      className={`nav-link ${
                                        activeState === 12 && "active"
                                      }`}
                                      data-toggle="tab"
                                      href="#contact_setup"
                                      role="tab"
                                      aria-controls="contact_setup"
                                      aria-selected="true"
                                    >
                                      Contact Us
                                    </a>
                                  </li>
                                  <li className="nav-item">
                                    <a
                                      onClick={() => setActiveState(13)}
                                      className={`nav-link ${
                                        activeState === 13 && "active"
                                      }`}
                                      data-toggle="tab"
                                      href="#time_setting"
                                      role="tab"
                                      aria-controls="time_setting"
                                      aria-selected="true"
                                    >
                                      Time Setup
                                    </a>
                                  </li>
                                </ul>{" "}
                              </>
                            )}
                            <div className="tab-content" id="settingContent">
                              <div
                                // className="tab-pane fade active show"
                                className={`tab-pane fade show ${
                                  activeState === 0 && "active"
                                }`}
                                //id="account_security"
                                role="tabpanel"
                                aria-labelledby="account_security-tab"
                              >
                                <div className="setting_content">
                                  <h4>Account and Security</h4>
                                  <div className="inner_block account_setting">
                                    <form
                                      className="setting_form"
                                      action=""
                                      method="post"
                                    >
                                      <div className="block_column wd65">
                                        <h6>Profile </h6>
                                        <div className="inner_field">
                                          <div className="form-group">
                                            <div className="form_field">
                                              <label>Username</label>
                                              <input
                                                type="text"
                                                className="form-control"
                                                name="username"
                                                placeholder="@pg"
                                                value={updateUserName}
                                                onChange={(e) => {
                                                  setUpdateUserName(
                                                    e.target.value
                                                  );
                                                }}
                                              />
                                            </div>
                                          </div>
                                          <div className="form-group">
                                            <div className="form_field">
                                              <label>Email Address</label>
                                              <span>{userEmail}</span>
                                            </div>
                                          </div>
                                          <div className="form-group">
                                            <div className="form_field ">
                                              <label>Password</label>
                                              <input
                                                type="password"
                                                className="form-control"
                                                name="password"
                                                placeholder="**********"
                                                value={password}
                                                autoComplete="new-password"
                                                // onChange={(e) => {
                                                //   setPassword(e.target.value.trim());
                                                //   if (error.password) {
                                                //     setError({ ...error, password: "" });
                                                //   }
                                                // }}
                                              />
                                              {/* {error.password && (
                                  <span className="customvalidationdesignlogin">
                                    {error.password}
                                  </span>
                                )} */}
                                              {/* <span>
                                  You signed up via Google, so you don't have a
                                  password.
                                </span> */}
                                              {adminState === "0" ? (
                                                <div className="change_btn">
                                                  <button
                                                    type="button"
                                                    className="cus_btn green_btn"
                                                    // data-toggle="modal"
                                                    // data-target="#adddiscountoptionModal"
                                                    // onClick={() => {
                                                    //   setShowModal2(true);
                                                    // }}
                                                  >
                                                    Change
                                                  </button>
                                                </div>
                                              ) : (
                                                <div className="change_btn">
                                                  <button
                                                    type="button"
                                                    className="cus_btn green_btn"
                                                    data-toggle="modal"
                                                    data-target="#adddiscountoptionModal"
                                                    onClick={() => {
                                                      setShowModal2(true);
                                                    }}
                                                  >
                                                    Change
                                                  </button>
                                                </div>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                        {adminState === "0" ? (
                                          <div
                                            style={{
                                              display: "flex",
                                              justifyContent: "right",
                                            }}
                                          >
                                            <button
                                              type="button"
                                              className="btnprofileinfo"
                                              // onClick={OnUpdate}
                                            >
                                              Save Changes
                                            </button>
                                          </div>
                                        ) : (
                                          <div
                                            style={{
                                              display: "flex",
                                              justifyContent: "right",
                                            }}
                                          >
                                            <button
                                              type="button"
                                              className="btnprofileinfo"
                                              onClick={OnUpdate}
                                            >
                                              Save Changes
                                            </button>
                                          </div>
                                        )}
                                        {/* <h6>Security</h6>
                                        <div className="inner_field">
                                          <div className="form-group">
                                            <div className="form_field pwd_field">
                                              <label>Password</label>
                                              <input
                                                type="password"
                                                className="form-control"
                                                name="password"
                                                placeholder="**********"
                                              />
                                              <span>
                                                You signed up via Google, so you
                                                don't have a password.
                                              </span>
                                  
                                            </div>
                                          </div>
                                          <div className="form-group">
                                            <div className="form_field delete_field">
                                              <label>Deletion</label>
                                              <input
                                                type="password"
                                                className="form-control"
                                                name="password"
                                                placeholder="hello@designdrops.io"
                                                autoComplete="new-password"
                                              />
                                              <span>
                                                Permanently delete your account,
                                                posts, and comments.
                                              </span>
                                              <div className="change_btn">
                                                <button
                                                  type="button"
                                                  className="cus_btn delete_btn"
                                                >
                                                  Delete
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                        </div> */}
                                        {/* <h6>2 Factor Authentication</h6> */}
                                        <div className="inner_field">
                                          {/* <div className="form-group">
                                            <div className="form_field">
                                              <label>
                                                2-FA autentification
                                              </label>
                                              <input
                                                type="text"
                                                className="form-control"
                                                name="stepfactor"
                                                placeholder="lorem"
                                              />
                                              <div className="factorfield switch_field">
                                                <label className="custom-switch">
                                                  <input
                                                    type="checkbox"
                                                    name="custom-switch-checkbox"
                                                    className="custom-switch-input"
                                                    checked={checkbox}
                                                    value={checkbox}
                                                    onChange={
                                                      handleSwitchChange
                                                    }
                                                  />
                                                  <span className="custom-switch-indicator"></span>
                                                </label>
                                              </div>
                                            </div>
                                          </div> */}
                                          <div className="form-group">
                                            <div className="form_field">
                                              <label>Phone number</label>
                                              <input
                                                type="text"
                                                className="form-control"
                                                name="phone"
                                                placeholder="+380 931234567"
                                                value={updatePhone}
                                                onChange={(e) => {
                                                  setUpdatePhone(
                                                    e.target.value
                                                  );
                                                }}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        {/* <h6>Sessions</h6>
                                        <div className="inner_field">
                                          <div className="form-group">
                                            <div className="form_field lastsignin_field">
                                              <label>Last Sign In</label>
                                              <span>
                                                today at 18:34, Safary
                                                198.123.23.23
                                              </span>
                                              
                                            </div>
                                          </div>
                                          <div className="session_list">
                                            <div className="title">
                                              <h6>Total active sessions (5)</h6>
                                              <button
                                                type="button"
                                                className="reset_session_btn"
                                              >
                                                Reset all active sessions
                                              </button>
                                            </div>
                                            <div className="active_session">
                                              <ul>
                                                <li>
                                                  DESKTOP-6TIG6EC • Kyiv,
                                                  Ukraine{" "}
                                                  <span>
                                                    Chrome • Used right now
                                                  </span>
                                                </li>
                                                <li>
                                                  Iphone 11 • Kyiv, Ukraine{" "}
                                                  <span>
                                                    Chrome • 04/19/2022
                                                  </span>
                                                </li>
                                                <li>
                                                  Iphone 11 • Kyiv, Ukraine{" "}
                                                  <span>
                                                    Chrome • 04/19/2022
                                                  </span>
                                                </li>
                                              </ul>
                                            </div>
                                          </div>
                                        </div> */}
                                      </div>
                                      <div className="block_column wd35">
                                        <h6>Profile Picture</h6>
                                        <div className="inner_field">
                                          <div className="form-group">
                                            <div className="form_field profile_field">
                                              <div className="profile_upload">
                                                <input
                                                  type="file"
                                                  name="profile_img"
                                                  accept="image/jpeg, image/jpg, image/png"
                                                  onChange={handleImages}
                                                />
                                                {images ? (
                                                  <img
                                                    src={images}
                                                    className="userupdateimagecustom"
                                                    alt=""
                                                  />
                                                ) : (
                                                  <>
                                                    <img
                                                      src="assets/img/profile-image.webp"
                                                      alt=""
                                                    />
                                                    <span className="dum_upload_btn">
                                                      Upload
                                                    </span>
                                                  </>
                                                )}
                                              </div>
                                              <button
                                                type="button"
                                                className="cus_btn"
                                              >
                                                <img
                                                  src="assets/img/profile-edit-icon.webp"
                                                  alt=""
                                                />{" "}
                                                Edit
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </form>
                                    <div className="clearfix"></div>
                                  </div>
                                </div>
                              </div>

                              <div
                                // className="tab-pane fade"
                                className={`tab-pane fade show ${
                                  activeState === 1 && "active"
                                }`}
                                // id="billing_plan"
                                role="tabpanel"
                                aria-labelledby="billing_plan-tab"
                              >
                                <div className="setting_content">
                                  <h4>Plans and Billing</h4>
                                  <div className="billing_plan_upgrade">
                                    <form
                                      className="setting_form"
                                      action=""
                                      method="post"
                                    >
                                      <div className="block_column plan_upgrade_block wd100">
                                        <div className="inner_plan_column">
                                          <div className="plan_column">
                                            <span>Plan Name</span>
                                            {planData?.map((item) => (
                                              <>
                                                <h5>{item.name}</h5>{" "}
                                                <sub>
                                                  Start Date: {StartDate}
                                                </sub>
                                                <br />
                                                <sub>
                                                  Next Payment Date: {EndDate}
                                                </sub>
                                              </>
                                            ))}
                                          </div>
                                          <div className="plan_column">
                                            <span>Payment</span>
                                            {planData?.map((item) => (
                                              <h5>
                                                ${item.price}{" "}
                                                <sub>per month</sub>
                                              </h5>
                                            ))}
                                          </div>
                                          <div className="plan_column plan_btns">
                                            <a
                                              onClick={() => {
                                                makePayment();
                                                //Checkout();
                                              }}
                                              style={{ cursor: "pointer" }}
                                            >
                                              Cancel Plan
                                            </a>
                                            <button
                                              type="button"
                                              onClick={() => {
                                                makePayment();
                                                //Checkout();
                                              }}
                                            >
                                              Upgrade Plan
                                            </button>
                                          </div>
                                        </div>
                                        <div className="plan_progress_sec">
                                          <div className="plan_progress_col">
                                            <h5>Monthly Customers</h5>
                                            <div className="progress">
                                              <div
                                                className="progress_bar"
                                                role="progressbar"
                                                aria-valuenow={CustomerPercent}
                                                aria-valuemin="0"
                                                aria-valuemax="100"
                                                style={{
                                                  width: `${CustomerPercent}%`,
                                                }}
                                              ></div>
                                            </div>
                                            <div className="progress_value">
                                              <span>
                                                <img
                                                  src="assets/img/customer-plan-icon.webp"
                                                  alt=""
                                                />{" "}
                                                {customers}({CustomerPercent}%)
                                              </span>
                                              <span>∞</span>
                                            </div>
                                          </div>
                                          <div className="plan_progress_col">
                                            <h5>Monthly Sales</h5>
                                            <div className="progress">
                                              <div
                                                className="progress_bar"
                                                role="progressbar"
                                                aria-valuenow={SalesPercent}
                                                aria-valuemin="0"
                                                aria-valuemax="100"
                                                style={{
                                                  width: `${SalesPercent}%`,
                                                }}
                                              ></div>
                                            </div>
                                            <div className="progress_value">
                                              <span>
                                                <img
                                                  src="assets/img/customer-plan-icon.webp"
                                                  alt=""
                                                />{" "}
                                                {salesCount}(
                                                {SalesPercent
                                                  ? SalesPercent
                                                  : 0}
                                                %)
                                              </span>
                                              <span>∞</span>
                                            </div>
                                          </div>
                                          <div className="plan_progress_col">
                                            <h5>Monthly Invoice</h5>
                                            <div className="progress">
                                              <div
                                                className="progress_bar"
                                                role="progressbar"
                                                aria-valuenow={InvoicePercent}
                                                aria-valuemin="0"
                                                aria-valuemax="100"
                                                style={{
                                                  width: `${InvoicePercent}%`,
                                                }}
                                              ></div>
                                            </div>
                                            <div className="progress_value">
                                              <span>
                                                <img
                                                  src="assets/img/customer-plan-icon.webp"
                                                  alt=""
                                                />{" "}
                                                {invoiceCount} (
                                                {InvoicePercent
                                                  ? InvoicePercent
                                                  : 0}
                                                %)
                                              </span>
                                              <span>∞</span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      {/* <div className="block_column payment_method_column wd100">
                                        <h5>Payment History</h5>
                                        <div className="payment_method_block">
                                          <div className="payment_card_column">
                                            <div className="card_detail">
                                              <h6>MasterCard</h6>
                                              <span>**** **** **** 4002</span>
                                              <small>Expiry on 20/2024</small>
                                            </div>
                                          </div>
                                        </div>
                                      </div> */}
                                      {/* <div className="block_column payment_method_column wd100">
                          <h5>Payment Method</h5>
                          <div className="payment_method_block">
                            <div className="payment_card_column">
                              <div className="card_detail">
                                <div className="card_radio">
                                  <input
                                    type="radio"
                                    name="paymentmethod"
                                    id="mastercard"
                                    checked
                                  />
                                  <span className="radio_check"></span>
                                </div>
                                <div className="card_img">
                                  <img
                                    src="assets/img/master-card.png"
                                    alt=""
                                  />
                                </div>
                                <div className="card_info">
                                  <h6>MasterCard</h6>
                                  <span>**** **** **** 4002</span>
                                  <small>Expiry on 20/2024</small>
                                </div>
                              </div>
                              <div className="card_action">
                                <a href="#">
                                  <img
                                    src="assets/img/ellipsis-horizontal.webp"
                                    alt=""
                                  />
                                </a>
                              </div>
                            </div>
                            <div className="payment_card_column">
                              <div className="card_detail">
                                <div className="card_radio">
                                  <input
                                    type="radio"
                                    name="paymentmethod"
                                    id="visacard"
                                  />
                                  <span className="radio_check"></span>
                                </div>
                                <div className="card_img">
                                  <img src="assets/img/visa-card.png" alt="" />
                                </div>
                                <div className="card_info">
                                  <h6>Visa</h6>
                                  <span>**** **** **** 4002</span>
                                  <small>Expiry on 20/2024</small>
                                </div>
                              </div>
                              <div className="card_action">
                                <a href="#">
                                  <img
                                    src="assets/img/ellipsis-horizontal.webp"
                                    alt=""
                                  />
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="add_payment_method">
                            <button type="button" className="add_new">
                              <i className="fa fa-plus"></i> New Payment Method
                            </button>
                          </div>
                        </div> */}
                                    </form>
                                    <div className="clearfix"></div>
                                  </div>
                                </div>
                              </div>

                              <div
                                // className="tab-pane fade"
                                className={`tab-pane fade show ${
                                  activeState === 2 && "active"
                                }`}
                                // id="profile_info"
                                role="tabpanel"
                                aria-labelledby="profile_info-tab"
                              >
                                <div className="setting_content">
                                  <h4>Company Profile</h4>
                                  <div className="inner_block account_setting compnyphotocusmtcss ">
                                    <form
                                      className="setting_form"
                                      action=""
                                      method="post"
                                    >
                                      <div className="block_column wd65 ">
                                        <h6>Company Profile</h6>
                                        <div className="inner_field">
                                          <div className="form-group">
                                            <div className="form_field">
                                              <label>Sub-Domain</label>
                                              <p className="form-control">
                                                {userDetails?.subDomain}
                                              </p>
                                            </div>
                                          </div>
                                          <div className="form-group">
                                            <div className="form_field">
                                              <label>Company Name</label>
                                              <input
                                                type="text"
                                                className="form-control"
                                                name="company_name"
                                                placeholder="Enter Company Name"
                                                value={companyName}
                                                onChange={(e) =>
                                                  setCompanyName(e.target.value)
                                                }
                                              />
                                            </div>
                                          </div>
                                          <div className="form-group">
                                            <div className="form_field">
                                              <label>Company Phone No.</label>
                                              <input
                                                type="text"
                                                className="form-control"
                                                name="company_phone"
                                                placeholder="Enter Company phone No."
                                                value={companyPhone}
                                                onChange={(e) =>
                                                  setCompanyPhone(
                                                    e.target.value
                                                  )
                                                }
                                              />
                                            </div>
                                          </div>
                                          <div className="form-group">
                                            <div className="form_field">
                                              <label>
                                                Customer Service No.
                                              </label>
                                              <input
                                                type="text"
                                                className="form-control"
                                                name="company_serviceno"
                                                placeholder="Enter Company Service No."
                                                value={companyService}
                                                onChange={(e) =>
                                                  setCompanyService(
                                                    e.target.value
                                                  )
                                                }
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        <h6>Security</h6>
                                        <div className="inner_field">
                                          <div className="form-group">
                                            <div className="form_field">
                                              <label>Country</label>
                                              <input
                                                type="text"
                                                className="form-control"
                                                name="country"
                                                placeholder="Enter Country"
                                                value={country}
                                                onChange={(e) =>
                                                  setCountry(e.target.value)
                                                }
                                              />
                                            </div>
                                          </div>
                                          <div className="form-group">
                                            <div className="form_field">
                                              <label>Street Address</label>
                                              <input
                                                type="text"
                                                className="form-control"
                                                name="street_address"
                                                placeholder="Enetr Address"
                                                value={address}
                                                onChange={(e) =>
                                                  setAddress(e.target.value)
                                                }
                                              />
                                            </div>
                                          </div>
                                          <div className="form-group">
                                            <div className="form_field">
                                              <label>State/Province</label>
                                              <input
                                                type="text"
                                                className="form-control"
                                                name="state_province"
                                                placeholder="Enter State"
                                                value={state}
                                                onChange={(e) =>
                                                  setState(e.target.value)
                                                }
                                              />
                                            </div>
                                          </div>
                                          <div className="form-group">
                                            <div className="form_field">
                                              <label>Zip/Postal Code</label>
                                              <input
                                                type="text"
                                                className="form-control"
                                                name="zip_postalcode"
                                                placeholder="Enter Zip/Postal code"
                                                value={zipcode}
                                                onChange={(e) =>
                                                  setZipcode(e.target.value)
                                                }
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="block_column wd35">
                                        <h6>Company Picture</h6>
                                        <div className="inner_field">
                                          <div className="form-group">
                                            <div className="form_field profile_field">
                                              <div className="profile_upload">
                                                <input
                                                  type="file"
                                                  name="profile_img"
                                                  accept="image/jpeg, image/jpg, image/png"
                                                  onChange={handleImages1}
                                                />
                                                {images1 ? (
                                                  <img
                                                    src={images1}
                                                    className="userupdateimagecustom"
                                                    alt=""
                                                  />
                                                ) : (
                                                  <>
                                                    <img
                                                      src="assets/img/profile-image.webp"
                                                      alt=""
                                                    />
                                                    <span className="dum_upload_btn">
                                                      Upload
                                                    </span>
                                                  </>
                                                )}
                                                {/* <img src="assets/img/profile-image.webp" alt="" /> */}
                                              </div>
                                              <button
                                                type="button"
                                                className="cus_btn"
                                              >
                                                <img
                                                  src="assets/img/profile-edit-icon.webp"
                                                  alt=""
                                                />{" "}
                                                Edit
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      {adminState === "0" ? (
                                        <div className="editsavechangesbtn">
                                          <button
                                            type="button"
                                            className="btnprofileinfo"
                                            // onClick={CompanyUpdate}
                                          >
                                            Save Changes
                                          </button>
                                        </div>
                                      ) : (
                                        <div className="editsavechangesbtn">
                                          <button
                                            type="button"
                                            className="btnprofileinfo"
                                            onClick={CompanyUpdate}
                                          >
                                            Save Changes
                                          </button>
                                        </div>
                                      )}

                                      {/* <div className="editsavechangesbtn">
                                        <button
                                          type="button"
                                          className="btnprofileinfo"
                                          onClick={CompanyUpdate}
                                        >
                                          Save Changes
                                        </button>
                                      </div> */}
                                    </form>

                                    <div className="clearfix"></div>
                                  </div>
                                </div>
                                {/* <CompanyEdit /> */}
                              </div>
                              <div
                                // className="tab-pane fade"
                                className={`tab-pane fade show ${
                                  activeState === 3 && "active"
                                }`}
                                // id="status_option"
                                role="tabpanel"
                                aria-labelledby="status_option-tab"
                              >
                                <StatusOption loading={loading1} />
                              </div>
                              <div
                                // className="tab-pane fade"
                                className={`tab-pane fade show ${
                                  activeState === 4 && "active"
                                }`}
                                //  id="payment_option"
                                role="tabpanel"
                                aria-labelledby="payment_option-tab"
                              >
                                <PaymentOptions />
                              </div>
                              <div
                                // className="tab-pane fade"
                                className={`tab-pane fade show ${
                                  activeState === 5 && "active"
                                }`}
                                // id="tax_option"
                                role="tabpanel"
                                aria-labelledby="tax_option-tab"
                              >
                                <TaxOptions />
                              </div>

                              <div
                                className={`tab-pane fade show ${
                                  activeState === 6 && "active"
                                }`}
                                role="tabpanel"
                                aria-labelledby="register_layout-tab"
                              >
                                <Layouts />
                                {/* <CashLayout /> */}
                              </div>
                              <div
                                className={`tab-pane fade show ${
                                  activeState === 11 && "active"
                                }`}
                                role="tabpanel"
                                aria-labelledby="receipt_label_config_setup-tab"
                              >
                                <RecieptLabel />
                                {/* <CashLayout /> */}
                              </div>
                              <div
                                className={`tab-pane fade show ${
                                  activeState === 12 && "active"
                                }`}
                                role="tabpanel"
                                aria-labelledby="contact_setup-tab"
                              >
                                {" "}
                                <Contact />{" "}
                              </div>
                              <div
                                className={`tab-pane fade show ${
                                  activeState === 13 && "active"
                                }`}
                                role="tabpanel"
                                aria-labelledby="time_setting-tab"
                              >
                                {" "}
                                <TimeSettings />{" "}
                              </div>
                              <div
                                className={`tab-pane fade show ${
                                  activeState === 8 && "active"
                                }`}
                                role="tabpanel"
                                aria-labelledby="user_setup-tab"
                              >
                                <div className="setting_content">
                                  <h4>Users</h4>
                                  <div className="inner_block plan_billing_setting">
                                    <form
                                      className="setting_form"
                                      action=""
                                      method="post"
                                    >
                                      {createState === false ? (
                                        <>
                                          <div className="block_column wd100 user_setup_block">
                                            <h5>User Setup</h5>
                                            <p>
                                              Manage the users and employees who
                                              have access to your account.
                                            </p>
                                            <div className="inner_field">
                                              <div className="tax_label_block">
                                                <div className="tax_label">
                                                  <h6>User list</h6>
                                                  <span>
                                                    All the users on your
                                                    account
                                                  </span>
                                                </div>
                                                <div className="edit_tax_btn">
                                                  <button
                                                    type="button"
                                                    className="cus_btn"
                                                    onClick={() => {
                                                      setCraeteState(true);
                                                      setUserRoleEmail("");
                                                      setUserName("");
                                                      setUserPhone("");
                                                      setSelectedRole("");
                                                      setSelectedRoleId("");
                                                      setSelectedPermissions(
                                                        []
                                                      );
                                                    }}
                                                  >
                                                    Create New User +
                                                  </button>
                                                </div>
                                              </div>
                                              <div className="status_table">
                                                <div className="table-responsive">
                                                  <table className="table">
                                                    <thead>
                                                      <tr>
                                                        <th>S.No.</th>
                                                        <th>User Role</th>
                                                        <th>User Name</th>
                                                        <th>User Email</th>
                                                        <th>Action</th>
                                                      </tr>
                                                    </thead>
                                                    <tbody>
                                                      {allSubuser?.map(
                                                        (item, index) => (
                                                          <>
                                                            <tr
                                                              key={
                                                                item?.subUser
                                                                  ?.id
                                                              }
                                                            >
                                                              <td>
                                                                {index + 1}
                                                              </td>
                                                              <td>
                                                                {
                                                                  item?.subUser
                                                                    ?.userRole
                                                                    ?.name
                                                                }
                                                              </td>
                                                              <td>
                                                                {
                                                                  item?.subUser
                                                                    ?.name
                                                                }
                                                              </td>
                                                              <td>
                                                                {
                                                                  item?.subUser
                                                                    ?.email
                                                                }
                                                              </td>
                                                              <td>
                                                                <div
                                                                  style={{
                                                                    width:
                                                                      " 70px",
                                                                    display:
                                                                      "flex",
                                                                    gap: "10px",
                                                                    justifyContent:
                                                                      "center",
                                                                  }}
                                                                >
                                                                  <img
                                                                    style={{
                                                                      cursor:
                                                                        "pointer",
                                                                    }}
                                                                    src="assets/img/layout-edit-icon.webp"
                                                                    alt=""
                                                                    onClick={() => {
                                                                      HandleEdit(
                                                                        item
                                                                      );
                                                                      setEditState(
                                                                        true
                                                                      );
                                                                      setCraeteState(
                                                                        true
                                                                      );
                                                                    }}
                                                                  />
                                                                  <img
                                                                    style={{
                                                                      cursor:
                                                                        "pointer",
                                                                    }}
                                                                    onClick={() =>
                                                                      HandleDelete(
                                                                        item
                                                                          ?.subUser
                                                                          ?.id
                                                                      )
                                                                    }
                                                                    src="assets/img/delete-icon.webp"
                                                                    alt=""
                                                                  />
                                                                </div>
                                                              </td>
                                                            </tr>
                                                          </>
                                                        )
                                                      )}
                                                    </tbody>
                                                  </table>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </>
                                      ) : editState === true ? (
                                        <>
                                          {" "}
                                          <div className="create_user">
                                            <div className="block_column wd100">
                                              <a
                                                className="back_userblock"
                                                onClick={() => {
                                                  setCraeteState(false);
                                                  setEditState(false);
                                                }}
                                                style={{ cursor: "pointer" }}
                                              >
                                                <img
                                                  src="assets/img/back-arrow.webp"
                                                  alt=""
                                                  className="settingusersubimgcstm"
                                                />
                                              </a>
                                              <h5>Edit User</h5>
                                              <p>
                                                Manage the users and employees
                                                who have access to your account.
                                              </p>
                                              <div className="inner_field">
                                                <div className="user_field_block">
                                                  <div className="user_field">
                                                    <div className="field_row">
                                                      <div className="field_col colhalf settingformcsscstm">
                                                        <div className="form-group">
                                                          <label className="cus_label">
                                                            Full Name
                                                          </label>
                                                          <input
                                                            type="text"
                                                            className="form-control"
                                                            name="fullname"
                                                            placeholder="Enter Full Name"
                                                            value={UserName}
                                                            onChange={(e) =>
                                                              setUserName(
                                                                e.target.value
                                                              )
                                                            }
                                                          />
                                                        </div>
                                                      </div>
                                                      <div className="field_col colhalf settingformcsscstm">
                                                        <div className="form-group">
                                                          <label className="cus_label">
                                                            Email Address
                                                          </label>
                                                          <input
                                                            type="email"
                                                            className="form-control"
                                                            name="userRoleEmail"
                                                            placeholder="Email Address"
                                                            value={
                                                              userRoleEmail
                                                            }
                                                            onChange={(e) => {
                                                              setUserRoleEmail(
                                                                e.target.value
                                                              );

                                                              if (
                                                                error.userRoleEmail
                                                              ) {
                                                                setError({
                                                                  ...error,
                                                                  userRoleEmail:
                                                                    "",
                                                                });
                                                              }
                                                            }}
                                                          />
                                                          {error.userRoleEmail && (
                                                            <span className="customvalidationdesignlogin">
                                                              {
                                                                error.userRoleEmail
                                                              }
                                                            </span>
                                                          )}
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div className="field_row">
                                                      <div className="field_col colhalf settingformcsscstm">
                                                        <div className="form-group">
                                                          <label className="cus_label">
                                                            Phone Number
                                                          </label>
                                                          <input
                                                            type="number"
                                                            className="form-control  no-arrow-input"
                                                            name="userPhone"
                                                            placeholder="Enter Phone Number"
                                                            value={userPhone}
                                                            onChange={(e) =>
                                                              setUserPhone(
                                                                e.target.value
                                                              )
                                                            }
                                                          />
                                                        </div>
                                                      </div>
                                                      <div className="field_col colhalf settingformcsscstm">
                                                        <div className="form-group">
                                                          <label className="cus_label">
                                                            Role
                                                          </label>
                                                          <div
                                                            ref={dropdownRef}
                                                          >
                                                            <div className="tagsscustomdiv rolecstmrespsetting">
                                                              <input
                                                                ref={inputRef2}
                                                                type="text"
                                                                className="customsearchinputboxsetting customrowinputsetttingfiled"
                                                                placeholder={`Select Role`}
                                                                value={
                                                                  selectedRole ||
                                                                  searchTermRole
                                                                }
                                                                onChange={
                                                                  handleSearchChangeRole
                                                                }
                                                                onClick={() =>
                                                                  setShowList(
                                                                    !showList
                                                                  )
                                                                }
                                                              />
                                                              <button
                                                                onClick={
                                                                  handleButtonClickRole
                                                                }
                                                                style={{
                                                                  cursor:
                                                                    "pointer",
                                                                }}
                                                                className="buttonrolesetting"
                                                              >
                                                                <img
                                                                  src="assets/img/select-arrow.webp"
                                                                  alt="Handy Icon"
                                                                />
                                                              </button>
                                                            </div>

                                                            {showList && (
                                                              <div className="addselectbox displycustomrolelist">
                                                                <ul>
                                                                  {filterRole.map(
                                                                    (item) => (
                                                                      <li
                                                                        key={
                                                                          item.id
                                                                        }
                                                                        onClick={() => {
                                                                          handleRoleSelect(
                                                                            item
                                                                          );
                                                                        }}
                                                                        style={{
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      >
                                                                        {
                                                                          item.name
                                                                        }
                                                                      </li>
                                                                    )
                                                                  )}
                                                                </ul>

                                                                <button
                                                                  onClick={() => {
                                                                    handleAddNewClickRole();
                                                                    if (
                                                                      inputRef2.current
                                                                    ) {
                                                                      inputRef2.current.focus();
                                                                    }
                                                                  }}
                                                                  style={{
                                                                    backgroundColor:
                                                                      "#9d3dd3",
                                                                  }}
                                                                  type="button"
                                                                  className="btn cus_addnew_btn  customaddnewcatebtnsetting"
                                                                >
                                                                  <span>+</span>{" "}
                                                                  Add New Role
                                                                </button>
                                                              </div>
                                                            )}
                                                          </div>
                                                          {error.selectedRole && (
                                                            <span className="customvalidationdesignlogin">
                                                              {
                                                                error.selectedRole
                                                              }
                                                            </span>
                                                          )}
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div className="clearfix"></div>
                                                    <div className="form-group">
                                                      <label
                                                        className="cus_label"
                                                        style={{
                                                          color: "#111827",
                                                          fontWeight: "600",
                                                        }}
                                                      >
                                                        Select All Role
                                                        Permissions
                                                      </label>

                                                      <div className="checkbox">
                                                        <input
                                                          type="checkbox"
                                                          className="chkfield"
                                                          name="userrole"
                                                          checked={selectAll}
                                                          onChange={
                                                            handleSelectAll
                                                          }
                                                        />
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div
                                                    className={`status_table userrole_field ${
                                                      selectAll
                                                        ? "disabled"
                                                        : ""
                                                    }`}
                                                  >
                                                    <div className="table-responsive">
                                                      <table className="table">
                                                        <thead>
                                                          <tr>
                                                            <th
                                                              colSpan="3"
                                                              style={{
                                                                verticalAlign:
                                                                  "top",
                                                              }}
                                                            >
                                                              Role Permissions
                                                              {permissionError && (
                                                                <span className="customvalidationdesignlogin">
                                                                  {
                                                                    permissionError
                                                                  }
                                                                </span>
                                                              )}
                                                            </th>
                                                            <th
                                                              style={{
                                                                textAlign:
                                                                  "center",
                                                              }}
                                                            >
                                                              {/* <label>
                                                                Select All
                                                              </label>

                                                              <div className="checkbox">
                                                                <input
                                                                  type="checkbox"
                                                                  className="chkfield"
                                                                  name="userrole"
                                                                  checked={
                                                                    selectAll
                                                                  }
                                                                  onChange={
                                                                    handleSelectAll
                                                                  }
                                                                />
                                                              </div> */}
                                                            </th>
                                                          </tr>
                                                        </thead>
                                                        <tbody>
                                                          {allPermission?.map(
                                                            (item, index) =>
                                                              index % 2 ===
                                                              0 ? (
                                                                <tr
                                                                  key={item?.id}
                                                                >
                                                                  <td>
                                                                    {item?.name}
                                                                  </td>
                                                                  <td>
                                                                    <div className="checkbox">
                                                                      <input
                                                                        type="checkbox"
                                                                        className="chkfield"
                                                                        value={
                                                                          item.name
                                                                        }
                                                                        name="userrole"
                                                                        // checked={selectedPermissions.includes(
                                                                        //   item?.id
                                                                        // )}
                                                                        checked={
                                                                          selectAll ||
                                                                          selectedPermissions.includes(
                                                                            item?.id
                                                                          )
                                                                        }
                                                                        disabled={
                                                                          selectAll
                                                                        }
                                                                        onChange={() =>
                                                                          handleCheckboxChange(
                                                                            item?.id
                                                                          )
                                                                        }
                                                                      />
                                                                    </div>
                                                                  </td>

                                                                  {allPermission[
                                                                    index + 1
                                                                  ] && (
                                                                    <>
                                                                      <td>
                                                                        {
                                                                          allPermission[
                                                                            index +
                                                                              1
                                                                          ]
                                                                            ?.name
                                                                        }
                                                                      </td>
                                                                      <td>
                                                                        <div className="checkbox">
                                                                          <input
                                                                            type="checkbox"
                                                                            className="chkfield"
                                                                            value={
                                                                              allPermission[
                                                                                index +
                                                                                  1
                                                                              ]
                                                                                ?.name
                                                                            }
                                                                            name="userrole"
                                                                            // checked={selectedPermissions.includes(
                                                                            //   allPermission[
                                                                            //     index +
                                                                            //       1
                                                                            //   ]
                                                                            //     ?.id
                                                                            // )}
                                                                            checked={
                                                                              selectAll ||
                                                                              selectedPermissions.includes(
                                                                                allPermission[
                                                                                  index +
                                                                                    1
                                                                                ]
                                                                                  ?.id
                                                                              )
                                                                            }
                                                                            disabled={
                                                                              selectAll
                                                                            }
                                                                            onChange={() =>
                                                                              handleCheckboxChangeedit(
                                                                                allPermission[
                                                                                  index +
                                                                                    1
                                                                                ]
                                                                                  ?.id
                                                                              )
                                                                            }
                                                                          />
                                                                        </div>
                                                                      </td>
                                                                    </>
                                                                  )}
                                                                </tr>
                                                              ) : null
                                                          )}
                                                        </tbody>
                                                      </table>
                                                    </div>
                                                  </div>
                                                  <div className="clearfix"></div>
                                                  <div className="restepasswordcustmbtn">
                                                    <div className="userrole_btn restbuttoncustmcss">
                                                      <button
                                                        type="button"
                                                        className="role_save_btn"
                                                        onClick={() =>
                                                          HandleReset()
                                                        }
                                                      >
                                                        Reset Password
                                                      </button>
                                                    </div>
                                                    <div className="userrole_btn">
                                                      <button
                                                        type="button"
                                                        className="role_save_btn"
                                                        onClick={() =>
                                                          Submit1()
                                                        }
                                                      >
                                                        Save
                                                      </button>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </>
                                      ) : (
                                        <>
                                          <div className="create_user">
                                            <div className="block_column wd100">
                                              <a
                                                className="back_userblock"
                                                onClick={() =>
                                                  setCraeteState(false)
                                                }
                                                style={{ cursor: "pointer" }}
                                              >
                                                <img
                                                  src="assets/img/back-arrow.webp"
                                                  alt=""
                                                  className="settingusersubimgcstm"
                                                />
                                              </a>
                                              <h5>Create New User</h5>
                                              <p>
                                                Manage the users and employees
                                                who have access to your account.
                                              </p>
                                              <div className="inner_field">
                                                <div className="user_field_block">
                                                  <div className="user_field">
                                                    <div className="field_row">
                                                      <div className="field_col colhalf settingformcsscstm">
                                                        <div className="form-group">
                                                          <label className="cus_label">
                                                            Full Name
                                                          </label>
                                                          <input
                                                            type="text"
                                                            className="form-control"
                                                            name="fullname"
                                                            placeholder="Enter Full Name"
                                                            value={UserName}
                                                            onChange={(e) =>
                                                              setUserName(
                                                                e.target.value
                                                              )
                                                            }
                                                          />
                                                        </div>
                                                      </div>
                                                      <div className="field_col colhalf settingformcsscstm">
                                                        <div className="form-group">
                                                          <label className="cus_label">
                                                            Email Address
                                                          </label>
                                                          <input
                                                            type="email"
                                                            className="form-control"
                                                            name="userRoleEmail"
                                                            placeholder="Email Address"
                                                            value={
                                                              userRoleEmail
                                                            }
                                                            onChange={(e) => {
                                                              setUserRoleEmail(
                                                                e.target.value
                                                              );

                                                              if (
                                                                error.userRoleEmail
                                                              ) {
                                                                setError({
                                                                  ...error,
                                                                  userRoleEmail:
                                                                    "",
                                                                });
                                                              }
                                                            }}
                                                          />
                                                          {error.userRoleEmail && (
                                                            <span className="customvalidationdesignlogin">
                                                              {
                                                                error.userRoleEmail
                                                              }
                                                            </span>
                                                          )}
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div className="field_row">
                                                      <div className="field_col colhalf settingformcsscstm">
                                                        <div className="form-group">
                                                          <label className="cus_label">
                                                            Phone Number
                                                          </label>
                                                          <input
                                                            type="number"
                                                            className="form-control  no-arrow-input"
                                                            name="userPhone"
                                                            placeholder="Enter Phone Number"
                                                            value={userPhone}
                                                            onChange={(e) =>
                                                              setUserPhone(
                                                                e.target.value
                                                              )
                                                            }
                                                          />
                                                        </div>
                                                      </div>
                                                      <div className="field_col colhalf settingformcsscstm">
                                                        <div className="form-group">
                                                          <label className="cus_label">
                                                            Role
                                                          </label>
                                                          <div
                                                            ref={dropdownRef}
                                                          >
                                                            <div className="tagsscustomdiv rolecstmrespsetting">
                                                              <input
                                                                ref={inputRef2}
                                                                type="text"
                                                                className="customsearchinputboxsetting customrowinputsetttingfiled"
                                                                placeholder={`Select Role`}
                                                                value={
                                                                  selectedRole ||
                                                                  searchTermRole
                                                                }
                                                                onChange={
                                                                  handleSearchChangeRole
                                                                }
                                                                onClick={() =>
                                                                  setShowList(
                                                                    !showList
                                                                  )
                                                                }
                                                              />
                                                              <button
                                                                onClick={
                                                                  handleButtonClickRole
                                                                }
                                                                style={{
                                                                  cursor:
                                                                    "pointer",
                                                                }}
                                                                className="buttonrolesetting"
                                                              >
                                                                <img
                                                                  src="assets/img/select-arrow.webp"
                                                                  alt="Handy Icon"
                                                                />
                                                              </button>
                                                            </div>

                                                            {showList && (
                                                              <div className="addselectbox displycustomrolelist">
                                                                <ul>
                                                                  {filterRole.map(
                                                                    (item) => (
                                                                      <li
                                                                        key={
                                                                          item.id
                                                                        }
                                                                        onClick={() => {
                                                                          handleRoleSelect(
                                                                            item
                                                                          );
                                                                        }}
                                                                        style={{
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      >
                                                                        {
                                                                          item.name
                                                                        }
                                                                      </li>
                                                                    )
                                                                  )}
                                                                </ul>

                                                                <button
                                                                  onClick={() => {
                                                                    handleAddNewClickRole();
                                                                    if (
                                                                      inputRef2.current
                                                                    ) {
                                                                      inputRef2.current.focus();
                                                                    }
                                                                  }}
                                                                  style={{
                                                                    backgroundColor:
                                                                      "#9d3dd3",
                                                                  }}
                                                                  type="button"
                                                                  className="btn cus_addnew_btn  customaddnewcatebtnsetting"
                                                                >
                                                                  <span>+</span>{" "}
                                                                  Add New Role
                                                                </button>
                                                              </div>
                                                            )}
                                                          </div>
                                                          {error.selectedRole && (
                                                            <span className="customvalidationdesignlogin">
                                                              {
                                                                error.selectedRole
                                                              }
                                                            </span>
                                                          )}
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div className="clearfix"></div>
                                                    <div className="form-group">
                                                      <label
                                                        className="cus_label"
                                                        style={{
                                                          color: "#111827",
                                                          fontWeight: "600",
                                                        }}
                                                      >
                                                        Select All Role
                                                        Permissions
                                                      </label>

                                                      <div className="checkbox">
                                                        <input
                                                          type="checkbox"
                                                          className="chkfield"
                                                          name="userrole"
                                                          checked={selectAll}
                                                          onChange={
                                                            handleSelectAll
                                                          }
                                                        />
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div
                                                    className={`status_table userrole_field ${
                                                      selectAll
                                                        ? "disabled"
                                                        : ""
                                                    }`}
                                                  >
                                                    <div className="table-responsive">
                                                      <table className="table">
                                                        <thead>
                                                          <tr>
                                                            <th
                                                              colSpan="3"
                                                              style={{
                                                                verticalAlign:
                                                                  "top",
                                                              }}
                                                            >
                                                              Role Permissions{" "}
                                                              {permissionError && (
                                                                <span className="customvalidationdesignlogin">
                                                                  {
                                                                    permissionError
                                                                  }
                                                                </span>
                                                              )}{" "}
                                                            </th>
                                                            <th
                                                              style={{
                                                                textAlign:
                                                                  "center",
                                                              }}
                                                            >
                                                              {/* <label>
                                                                Select All
                                                              </label>
                                                              <div className="checkbox">
                                                                <input
                                                                  type="checkbox"
                                                                  className="chkfield"
                                                                  name="userrole"
                                                                  checked={
                                                                    selectAll
                                                                  }
                                                                  onChange={
                                                                    handleSelectAll
                                                                  }
                                                                />
                                                              </div> */}
                                                            </th>
                                                          </tr>
                                                        </thead>
                                                        <tbody>
                                                          {allPermission?.map(
                                                            (item, index) =>
                                                              index % 2 ===
                                                              0 ? (
                                                                <tr
                                                                  key={item?.id}
                                                                >
                                                                  <td>
                                                                    {item?.name}
                                                                  </td>
                                                                  <td>
                                                                    <div className="checkbox">
                                                                      <input
                                                                        type="checkbox"
                                                                        className="chkfield"
                                                                        value={
                                                                          item.name
                                                                        }
                                                                        name="userrole"
                                                                        checked={
                                                                          selectAll ||
                                                                          selectedPermissions.includes(
                                                                            item?.id
                                                                          )
                                                                        }
                                                                        disabled={
                                                                          selectAll
                                                                        }
                                                                        // checked={selectedPermissions.includes(
                                                                        //   item?.id
                                                                        // )}
                                                                        onChange={() =>
                                                                          handleCheckboxChange(
                                                                            item?.id
                                                                          )
                                                                        }
                                                                      />
                                                                    </div>
                                                                  </td>

                                                                  {allPermission[
                                                                    index + 1
                                                                  ] && (
                                                                    <>
                                                                      <td>
                                                                        {
                                                                          allPermission[
                                                                            index +
                                                                              1
                                                                          ]
                                                                            ?.name
                                                                        }
                                                                      </td>
                                                                      <td>
                                                                        <div className="checkbox">
                                                                          <input
                                                                            type="checkbox"
                                                                            className="chkfield"
                                                                            value={
                                                                              allPermission[
                                                                                index +
                                                                                  1
                                                                              ]
                                                                                ?.name
                                                                            }
                                                                            name="userrole"
                                                                            // checked={selectedPermissions.includes(
                                                                            //   allPermission[
                                                                            //     index +
                                                                            //       1
                                                                            //   ]
                                                                            //     ?.id
                                                                            // )}
                                                                            checked={
                                                                              selectAll ||
                                                                              selectedPermissions.includes(
                                                                                allPermission[
                                                                                  index +
                                                                                    1
                                                                                ]
                                                                                  ?.id
                                                                              )
                                                                            }
                                                                            disabled={
                                                                              selectAll
                                                                            }
                                                                            onChange={() =>
                                                                              handleCheckboxChange(
                                                                                allPermission[
                                                                                  index +
                                                                                    1
                                                                                ]
                                                                                  ?.id
                                                                              )
                                                                            }
                                                                          />
                                                                        </div>
                                                                      </td>
                                                                    </>
                                                                  )}
                                                                </tr>
                                                              ) : null
                                                          )}
                                                        </tbody>
                                                      </table>
                                                    </div>
                                                  </div>
                                                  <div className="clearfix"></div>
                                                  <div className="userrole_btn">
                                                    <button
                                                      type="button"
                                                      className="role_save_btn"
                                                      onClick={() => Submit()}
                                                    >
                                                      Save
                                                    </button>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </>
                                      )}
                                      <div className="clearfix"></div>
                                    </form>
                                    <div className="clearfix"></div>
                                  </div>
                                </div>
                              </div>

                              <div
                                className={`tab-pane fade show ${
                                  activeState === 9 && "active"
                                }`}
                                id="discount_option"
                                role="tabpanel"
                                aria-labelledby="discount_option-tab"
                              >
                                <DiscountOptions />
                              </div>

                              <div
                                // className="tab-pane fade"
                                className={`tab-pane fade show ${
                                  activeState === 7 && "active"
                                }`}
                                //  id="receipt_config_setup"
                                role="tabpanel"
                                aria-labelledby="receipt_config_setup-tab"
                              >
                                <RecieptConfig />
                              </div>
                            </div>
                          </div>
                          <div className="clearfix"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <div
                  // className={`${isSidebarVisible ? "sidebar-mini" : ""} ${
                  //   !isFreeTrialVisible ? "no-free-trial" : ""
                  // }`}
                  style={sidebarStyleone}
                >
                  <nav
                    className="navbar navbar-expand-lg main-navbar sticky"
                    style={navbarStyle}
                  >
                    <div className="sidebar-arrow">
                      <a
                        href="#"
                        data-toggle="sidebar"
                        onClick={(e) => {
                          toggleSidebar();

                          e.preventDefault();
                        }}
                      >
                        <img src="assets/img/sidebar-toggle.webp" alt="" />
                      </a>
                    </div>
                    <div className="header-title mr-auto">
                      <h4>Dashboard</h4>
                    </div>
                    <SideUl image={images} name={updateUserName} />
                  </nav>
                  <div className="main-wrapper devicelistcstmtableres ">
                    <MainSidebar image={images1} companyname={companyName} />{" "}
                    <div
                      className="main-content tableback"
                      style={mainContentStyle}
                    >
                      <div className="dash_setting">
                        <div className="setting_tabs_content">
                          {(userData === "null" ||
                            userData === "cancelled" ||
                            userData === "expired") &&
                          new Date(localStorage.getItem("planDate")) <
                            new Date() ? null : (
                            <>
                              <ul
                                className="nav nav-pills setting_tabs"
                                id="setting"
                                role="tablist"
                              >
                                <li className="nav-item">
                                  <a
                                    onClick={() => setActiveState(0)}
                                    className={`nav-link ${
                                      activeState === 0 && "active"
                                    }`}
                                    data-toggle="tab"
                                    href="#account_security"
                                    role="tab"
                                    aria-controls="account_security"
                                    aria-selected="true"
                                  >
                                    Account & Security
                                  </a>
                                </li>

                                {adminState === "0" &&
                                permission?.length !== 0 &&
                                userRolee !== "Super Admin" ? null : (
                                  <li className="nav-item">
                                    <a
                                      onClick={() => setActiveState(1)}
                                      className={`nav-link ${
                                        activeState === 1 && "active"
                                      }`}
                                      data-toggle="tab"
                                      href="#billing_plan"
                                      role="tab"
                                      aria-controls="billing_plan"
                                      aria-selected="true"
                                    >
                                      Plans and Billing
                                    </a>
                                  </li>
                                )}

                                <li className="nav-item">
                                  <a
                                    onClick={() => setActiveState(2)}
                                    className={`nav-link ${
                                      activeState === 2 && "active"
                                    }`}
                                    data-toggle="tab"
                                    href="#profile_info"
                                    role="tab"
                                    aria-controls="profile_info"
                                    aria-selected="true"
                                  >
                                    Profile Info
                                  </a>
                                </li>
                                <li className="nav-item">
                                  <a
                                    onClick={() => setActiveState(3)}
                                    className={`nav-link ${
                                      activeState === 3 && "active"
                                    }`}
                                    data-toggle="tab"
                                    href="#status_option"
                                    role="tab"
                                    aria-controls="status_option"
                                    aria-selected="true"
                                  >
                                    Status Option
                                  </a>
                                </li>
                                <li className="nav-item">
                                  <a
                                    onClick={() => setActiveState(4)}
                                    className={`nav-link ${
                                      activeState === 4 && "active"
                                    }`}
                                    data-toggle="tab"
                                    href="#payment_option"
                                    role="tab"
                                    aria-controls="payment_option"
                                    aria-selected="true"
                                  >
                                    Payment Option
                                  </a>
                                </li>
                                <li className="nav-item">
                                  <a
                                    onClick={() => setActiveState(5)}
                                    className={`nav-link ${
                                      activeState === 5 && "active"
                                    }`}
                                    data-toggle="tab"
                                    href="#tax_option"
                                    role="tab"
                                    aria-controls="tax_option"
                                    aria-selected="true"
                                  >
                                    Tax Option
                                  </a>
                                </li>

                                <li className="nav-item">
                                  <a
                                    onClick={() => setActiveState(9)}
                                    className={`nav-link ${
                                      activeState === 9 && "active"
                                    }`}
                                    data-toggle="tab"
                                    href="#discount_option"
                                    role="tab"
                                    aria-controls="discount_option"
                                    aria-selected="true"
                                  >
                                    Discount Option
                                  </a>
                                </li>

                                <li className="nav-item">
                                  <a
                                    onClick={() => setActiveState(6)}
                                    className={`nav-link ${
                                      activeState === 6 && "active"
                                    }`}
                                    data-toggle="tab"
                                    href="#register_layout"
                                    role="tab"
                                    aria-controls="register_layout"
                                    aria-selected="true"
                                  >
                                    Cash Register Layout
                                  </a>
                                </li>
                                <li className="nav-item">
                                  <a
                                    onClick={() => setActiveState(7)}
                                    className={`nav-link ${
                                      activeState === 7 && "active"
                                    }`}
                                    data-toggle="tab"
                                    href="#receipt_config_setup"
                                    role="tab"
                                    aria-controls="receipt_config_setup"
                                    aria-selected="true"
                                  >
                                    Receipt Configuration & Setup
                                  </a>
                                </li>
                                <li className="nav-item">
                                  <a
                                    onClick={() => setActiveState(11)}
                                    className={`nav-link ${
                                      activeState === 11 && "active"
                                    }`}
                                    data-toggle="tab"
                                    href="#receipt_label_config_setup"
                                    role="tab"
                                    aria-controls="receipt_label_config_setup"
                                    aria-selected="true"
                                  >
                                    Receipt Label Configuration & Setup
                                  </a>
                                </li>
                                {adminState === "0" &&
                                permission?.length !== 0 &&
                                userRolee !== "Super Admin" ? null : (
                                  <li
                                    className="nav-item"
                                    onClick={() => setCraeteState(false)}
                                  >
                                    <a
                                      onClick={() => setActiveState(8)}
                                      className={`nav-link ${
                                        activeState === 8 && "active"
                                      }`}
                                      data-toggle="tab"
                                      href="#user_setup"
                                      role="tab"
                                      aria-controls="user_setup"
                                      aria-selected="true"
                                    >
                                      User Setup
                                    </a>
                                  </li>
                                )}
                                <li className="nav-item">
                                  <a
                                    onClick={() => setActiveState(12)}
                                    className={`nav-link ${
                                      activeState === 12 && "active"
                                    }`}
                                    data-toggle="tab"
                                    href="#contact_setup"
                                    role="tab"
                                    aria-controls="contact_setup"
                                    aria-selected="true"
                                  >
                                    Contact Us
                                  </a>
                                </li>
                                <li className="nav-item">
                                  <a
                                    onClick={() => setActiveState(13)}
                                    className={`nav-link ${
                                      activeState === 13 && "active"
                                    }`}
                                    data-toggle="tab"
                                    href="#time_setting"
                                    role="tab"
                                    aria-controls="time_setting"
                                    aria-selected="true"
                                  >
                                    Time Setup
                                  </a>
                                </li>
                              </ul>{" "}
                            </>
                          )}
                          <div className="tab-content" id="settingContent">
                            <div
                              // className="tab-pane fade active show"
                              className={`tab-pane fade show ${
                                activeState === 0 && "active"
                              }`}
                              //id="account_security"
                              role="tabpanel"
                              aria-labelledby="account_security-tab"
                            >
                              <div className="setting_content">
                                <h4>Account and Security</h4>
                                <div className="inner_block account_setting">
                                  <form
                                    className="setting_form"
                                    action=""
                                    method="post"
                                  >
                                    <div className="block_column wd65">
                                      <h6>Profile </h6>
                                      <div className="inner_field">
                                        <div className="form-group">
                                          <div className="form_field">
                                            <label>Username</label>
                                            <input
                                              type="text"
                                              className="form-control"
                                              name="username"
                                              placeholder="@pg"
                                              value={updateUserName}
                                              onChange={(e) => {
                                                setUpdateUserName(
                                                  e.target.value
                                                );
                                              }}
                                            />
                                          </div>
                                        </div>
                                        <div className="form-group">
                                          <div className="form_field">
                                            <label>Email Address</label>
                                            <span>{userEmail}</span>
                                          </div>
                                        </div>
                                        <div className="form-group">
                                          <div className="form_field ">
                                            <label>Password</label>
                                            <input
                                              type="password"
                                              className="form-control"
                                              name="password"
                                              placeholder="**********"
                                              value={password}
                                              autoComplete="new-password"
                                              // onChange={(e) => {
                                              //   setPassword(e.target.value.trim());
                                              //   if (error.password) {
                                              //     setError({ ...error, password: "" });
                                              //   }
                                              // }}
                                            />
                                            {/* {error.password && (
                                  <span className="customvalidationdesignlogin">
                                    {error.password}
                                  </span>
                                )} */}
                                            {/* <span>
                                  You signed up via Google, so you don't have a
                                  password.
                                </span> */}
                                            {adminState === "0" ? (
                                              <div className="change_btn">
                                                <button
                                                  type="button"
                                                  className="cus_btn green_btn"
                                                  // data-toggle="modal"
                                                  // data-target="#adddiscountoptionModal"
                                                  // onClick={() => {
                                                  //   setShowModal2(true);
                                                  // }}
                                                >
                                                  Change
                                                </button>
                                              </div>
                                            ) : (
                                              <div className="change_btn">
                                                <button
                                                  type="button"
                                                  className="cus_btn green_btn"
                                                  data-toggle="modal"
                                                  data-target="#adddiscountoptionModal"
                                                  onClick={() => {
                                                    setShowModal2(true);
                                                  }}
                                                >
                                                  Change
                                                </button>
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                      {adminState === "0" ? (
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "right",
                                          }}
                                        >
                                          <button
                                            type="button"
                                            className="btnprofileinfo"
                                            onClick={OnUpdate}
                                          >
                                            Save Changes
                                          </button>
                                        </div>
                                      ) : (
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "right",
                                          }}
                                        >
                                          <button
                                            type="button"
                                            className="btnprofileinfo"
                                            onClick={OnUpdate}
                                          >
                                            Save Changes
                                          </button>
                                        </div>
                                      )}
                                      {/* <h6>Security</h6>
                                      <div className="inner_field">
                                        <div className="form-group">
                                          <div className="form_field pwd_field">
                                            <label>Password</label>
                                            <input
                                              type="password"
                                              className="form-control"
                                              name="password"
                                              placeholder="**********"
                                            />
                                            <span>
                                              You signed up via Google, so you
                                              don't have a password.
                                            </span>
                                 
                                          </div>
                                        </div>
                                        <div className="form-group">
                                          <div className="form_field delete_field">
                                            <label>Deletion</label>
                                            <input
                                              type="password"
                                              className="form-control"
                                              name="password"
                                              placeholder="hello@designdrops.io"
                                              autoComplete="new-password"
                                            />
                                            <span>
                                              Permanently delete your account,
                                              posts, and comments.
                                            </span>
                                            <div className="change_btn">
                                              <button
                                                type="button"
                                                className="cus_btn delete_btn"
                                              >
                                                Delete
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div> */}
                                      {/* <h6>2 Factor Authentication</h6> */}
                                      <div className="inner_field">
                                        {/* <div className="form-group">
                                          <div className="form_field">
                                            <label>2-FA autentification</label>
                                            <input
                                              type="text"
                                              className="form-control"
                                              name="stepfactor"
                                              placeholder="lorem"
                                            />
                                            <div className="factorfield switch_field">
                                              <label className="custom-switch">
                                                <input
                                                  type="checkbox"
                                                  name="custom-switch-checkbox"
                                                  className="custom-switch-input"
                                                  checked={checkbox}
                                                  value={checkbox}
                                                  onChange={handleSwitchChange}
                                                />
                                                <span className="custom-switch-indicator"></span>
                                              </label>
                                            </div>
                                          </div>
                                        </div> */}
                                        <div className="form-group">
                                          <div className="form_field">
                                            <label>Phone number</label>
                                            <input
                                              type="text"
                                              className="form-control"
                                              name="phone"
                                              placeholder="+380 931234567"
                                              value={updatePhone}
                                              onChange={(e) => {
                                                setUpdatePhone(e.target.value);
                                              }}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      {/* <h6>Sessions</h6>
                                      <div className="inner_field">
                                        <div className="form-group">
                                          <div className="form_field lastsignin_field">
                                            <label>Last Sign In</label>
                                            <span>
                                              today at 18:34, Safary
                                              198.123.23.23
                                            </span>
                                           
                                          </div>
                                        </div>
                                        <div className="session_list">
                                          <div className="title">
                                            <h6>Total active sessions (5)</h6>
                                            <button
                                              type="button"
                                              className="reset_session_btn"
                                            >
                                              Reset all active sessions
                                            </button>
                                          </div>
                                          <div className="active_session">
                                            <ul>
                                              <li>
                                                DESKTOP-6TIG6EC • Kyiv, Ukraine{" "}
                                                <span>
                                                  Chrome • Used right now
                                                </span>
                                              </li>
                                              <li>
                                                Iphone 11 • Kyiv, Ukraine{" "}
                                                <span>Chrome • 04/19/2022</span>
                                              </li>
                                              <li>
                                                Iphone 11 • Kyiv, Ukraine{" "}
                                                <span>Chrome • 04/19/2022</span>
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                      </div> */}
                                    </div>
                                    <div className="block_column wd35">
                                      <h6>Profile Picture</h6>
                                      <div className="inner_field">
                                        <div className="form-group">
                                          <div className="form_field profile_field">
                                            <div className="profile_upload">
                                              <input
                                                type="file"
                                                name="profile_img"
                                                accept="image/jpeg, image/jpg, image/png"
                                                onChange={handleImages}
                                              />
                                              {images ? (
                                                <img
                                                  src={images}
                                                  className="userupdateimagecustom"
                                                  alt=""
                                                />
                                              ) : (
                                                <>
                                                  <img
                                                    src="assets/img/profile-image.webp"
                                                    alt=""
                                                  />
                                                  <span className="dum_upload_btn">
                                                    Upload
                                                  </span>
                                                </>
                                              )}
                                            </div>
                                            <button
                                              type="button"
                                              className="cus_btn"
                                            >
                                              <img
                                                src="assets/img/profile-edit-icon.webp"
                                                alt=""
                                              />{" "}
                                              Edit
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </form>
                                  <div className="clearfix"></div>
                                </div>
                              </div>
                            </div>

                            <div
                              // className="tab-pane fade"
                              className={`tab-pane fade show ${
                                activeState === 1 && "active"
                              }`}
                              // id="billing_plan"
                              role="tabpanel"
                              aria-labelledby="billing_plan-tab"
                            >
                              <div className="setting_content">
                                <h4>Plans and Billing</h4>
                                <div className="billing_plan_upgrade">
                                  <form
                                    className="setting_form"
                                    action=""
                                    method="post"
                                  >
                                    <div className="block_column plan_upgrade_block wd100">
                                      <div className="inner_plan_column">
                                        <div className="plan_column">
                                          <span>Plan Name</span>
                                          {planData?.map((item) => (
                                            <>
                                              <h5>{item.name}</h5>{" "}
                                              <sub>Start Date: {StartDate}</sub>
                                              <br />
                                              <sub>
                                                Next Payment Date: {EndDate}
                                              </sub>
                                            </>
                                          ))}
                                        </div>
                                        <div className="plan_column">
                                          <span>Payment</span>
                                          {planData?.map((item) => (
                                            <h5>
                                              ${item.price} <sub>per month</sub>
                                            </h5>
                                          ))}
                                        </div>
                                        <div className="plan_column plan_btns">
                                          <a
                                            onClick={() => {
                                              makePayment();
                                              //Checkout();
                                            }}
                                            style={{ cursor: "pointer" }}
                                          >
                                            Cancel Plan
                                          </a>
                                          <button
                                            type="button"
                                            onClick={() => {
                                              makePayment();
                                              //Checkout();
                                            }}
                                          >
                                            Upgrade Plan
                                          </button>
                                        </div>
                                      </div>
                                      <div className="plan_progress_sec">
                                        <div className="plan_progress_col">
                                          <h5>Monthly Customers</h5>
                                          <div className="progress">
                                            <div
                                              className="progress_bar"
                                              role="progressbar"
                                              aria-valuenow={CustomerPercent}
                                              aria-valuemin="0"
                                              aria-valuemax="100"
                                              style={{
                                                width: `${CustomerPercent}%`,
                                              }}
                                            ></div>
                                          </div>
                                          <div className="progress_value">
                                            <span>
                                              <img
                                                src="assets/img/customer-plan-icon.webp"
                                                alt=""
                                              />{" "}
                                              {customers}({CustomerPercent}%)
                                            </span>
                                            <span>∞</span>
                                          </div>
                                        </div>
                                        <div className="plan_progress_col">
                                          <h5>Monthly Sales</h5>
                                          <div className="progress">
                                            <div
                                              className="progress_bar"
                                              role="progressbar"
                                              aria-valuenow={SalesPercent}
                                              aria-valuemin="0"
                                              aria-valuemax="100"
                                              style={{
                                                width: `${SalesPercent}%`,
                                              }}
                                            ></div>
                                          </div>
                                          <div className="progress_value">
                                            <span>
                                              <img
                                                src="assets/img/customer-plan-icon.webp"
                                                alt=""
                                              />{" "}
                                              {salesCount}(
                                              {SalesPercent ? SalesPercent : 0}
                                              %)
                                            </span>
                                            <span>∞</span>
                                          </div>
                                        </div>
                                        <div className="plan_progress_col">
                                          <h5>Monthly Invoice</h5>
                                          <div className="progress">
                                            <div
                                              className="progress_bar"
                                              role="progressbar"
                                              aria-valuenow={InvoicePercent}
                                              aria-valuemin="0"
                                              aria-valuemax="100"
                                              style={{
                                                width: `${InvoicePercent}%`,
                                              }}
                                            ></div>
                                          </div>
                                          <div className="progress_value">
                                            <span>
                                              <img
                                                src="assets/img/customer-plan-icon.webp"
                                                alt=""
                                              />{" "}
                                              {invoiceCount} (
                                              {InvoicePercent
                                                ? InvoicePercent
                                                : 0}
                                              %)
                                            </span>
                                            <span>∞</span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    {/* <div className="block_column payment_method_column wd100">
                                      <h5>Payment History</h5>
                                      <div className="payment_method_block">
                                        <div className="payment_card_column">
                                          <div className="card_detail">
                                            <h6>MasterCard</h6>
                                            <span>**** **** **** 4002</span>
                                            <small>Expiry on 20/2024</small>
                                          </div>
                                        </div>
                                      </div>
                                    </div> */}
                                    {/* <div className="block_column payment_method_column wd100">
                          <h5>Payment Method</h5>
                          <div className="payment_method_block">
                            <div className="payment_card_column">
                              <div className="card_detail">
                                <div className="card_radio">
                                  <input
                                    type="radio"
                                    name="paymentmethod"
                                    id="mastercard"
                                    checked
                                  />
                                  <span className="radio_check"></span>
                                </div>
                                <div className="card_img">
                                  <img
                                    src="assets/img/master-card.png"
                                    alt=""
                                  />
                                </div>
                                <div className="card_info">
                                  <h6>MasterCard</h6>
                                  <span>**** **** **** 4002</span>
                                  <small>Expiry on 20/2024</small>
                                </div>
                              </div>
                              <div className="card_action">
                                <a href="#">
                                  <img
                                    src="assets/img/ellipsis-horizontal.webp"
                                    alt=""
                                  />
                                </a>
                              </div>
                            </div>
                            <div className="payment_card_column">
                              <div className="card_detail">
                                <div className="card_radio">
                                  <input
                                    type="radio"
                                    name="paymentmethod"
                                    id="visacard"
                                  />
                                  <span className="radio_check"></span>
                                </div>
                                <div className="card_img">
                                  <img src="assets/img/visa-card.png" alt="" />
                                </div>
                                <div className="card_info">
                                  <h6>Visa</h6>
                                  <span>**** **** **** 4002</span>
                                  <small>Expiry on 20/2024</small>
                                </div>
                              </div>
                              <div className="card_action">
                                <a href="#">
                                  <img
                                    src="assets/img/ellipsis-horizontal.webp"
                                    alt=""
                                  />
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="add_payment_method">
                            <button type="button" className="add_new">
                              <i className="fa fa-plus"></i> New Payment Method
                            </button>
                          </div>
                        </div> */}
                                  </form>
                                  <div className="clearfix"></div>
                                </div>
                              </div>
                            </div>

                            <div
                              // className="tab-pane fade"
                              className={`tab-pane fade show ${
                                activeState === 2 && "active"
                              }`}
                              // id="profile_info"
                              role="tabpanel"
                              aria-labelledby="profile_info-tab"
                            >
                              <div className="setting_content">
                                <h4>Company Profile</h4>
                                <div className="inner_block account_setting compnyphotocusmtcss ">
                                  <form
                                    className="setting_form"
                                    action=""
                                    method="post"
                                  >
                                    <div className="block_column wd65 ">
                                      <h6>Company Profile</h6>
                                      <div className="inner_field">
                                        <div className="form-group">
                                          <div className="form_field">
                                            <label>Sub-Domain</label>
                                            <p className="form-control">
                                              {userDetails?.subDomain}
                                            </p>
                                          </div>
                                        </div>
                                        <div className="form-group">
                                          <div className="form_field">
                                            <label>Company Name</label>
                                            <input
                                              type="text"
                                              className="form-control"
                                              name="company_name"
                                              placeholder="Enter Company Name"
                                              value={companyName}
                                              onChange={(e) =>
                                                setCompanyName(e.target.value)
                                              }
                                            />
                                          </div>
                                        </div>
                                        <div className="form-group">
                                          <div className="form_field">
                                            <label>Company Phone No.</label>
                                            <input
                                              type="text"
                                              className="form-control"
                                              name="company_phone"
                                              placeholder="Enter Company phone No."
                                              value={companyPhone}
                                              onChange={(e) =>
                                                setCompanyPhone(e.target.value)
                                              }
                                            />
                                          </div>
                                        </div>
                                        <div className="form-group">
                                          <div className="form_field">
                                            <label>Customer Service No.</label>
                                            <input
                                              type="text"
                                              className="form-control"
                                              name="company_serviceno"
                                              placeholder="Enter Company Service No."
                                              value={companyService}
                                              onChange={(e) =>
                                                setCompanyService(
                                                  e.target.value
                                                )
                                              }
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <h6>Security</h6>
                                      <div className="inner_field">
                                        <div className="form-group">
                                          <div className="form_field">
                                            <label>Country</label>
                                            <input
                                              type="text"
                                              className="form-control"
                                              name="country"
                                              placeholder="Enter Country"
                                              value={country}
                                              onChange={(e) =>
                                                setCountry(e.target.value)
                                              }
                                            />
                                          </div>
                                        </div>
                                        <div className="form-group">
                                          <div className="form_field">
                                            <label>Street Address</label>
                                            <input
                                              type="text"
                                              className="form-control"
                                              name="street_address"
                                              placeholder="Enetr Address"
                                              value={address}
                                              onChange={(e) =>
                                                setAddress(e.target.value)
                                              }
                                            />
                                          </div>
                                        </div>
                                        <div className="form-group">
                                          <div className="form_field">
                                            <label>State/Province</label>
                                            <input
                                              type="text"
                                              className="form-control"
                                              name="state_province"
                                              placeholder="Enter State"
                                              value={state}
                                              onChange={(e) =>
                                                setState(e.target.value)
                                              }
                                            />
                                          </div>
                                        </div>
                                        <div className="form-group">
                                          <div className="form_field">
                                            <label>Zip/Postal Code</label>
                                            <input
                                              type="text"
                                              className="form-control"
                                              name="zip_postalcode"
                                              placeholder="Enter Zip/Postal code"
                                              value={zipcode}
                                              onChange={(e) =>
                                                setZipcode(e.target.value)
                                              }
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="block_column wd35">
                                      <h6>Company Picture</h6>
                                      <div className="inner_field">
                                        <div className="form-group">
                                          <div className="form_field profile_field">
                                            <div className="profile_upload">
                                              <input
                                                type="file"
                                                name="profile_img"
                                                accept="image/jpeg, image/jpg, image/png"
                                                onChange={handleImages1}
                                              />
                                              {images1 ? (
                                                <img
                                                  src={images1}
                                                  className="userupdateimagecustom"
                                                  alt=""
                                                />
                                              ) : (
                                                <>
                                                  <img
                                                    src="assets/img/profile-image.webp"
                                                    alt=""
                                                  />
                                                  <span className="dum_upload_btn">
                                                    Upload
                                                  </span>
                                                </>
                                              )}
                                              {/* <img src="assets/img/profile-image.webp" alt="" /> */}
                                            </div>
                                            <button
                                              type="button"
                                              className="cus_btn"
                                            >
                                              <img
                                                src="assets/img/profile-edit-icon.webp"
                                                alt=""
                                              />{" "}
                                              Edit
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    {adminState === "0" ? (
                                      <div className="editsavechangesbtn">
                                        <button
                                          type="button"
                                          className="btnprofileinfo"
                                          //onClick={CompanyUpdate}
                                        >
                                          Save Changes
                                        </button>
                                      </div>
                                    ) : (
                                      <div className="editsavechangesbtn">
                                        <button
                                          type="button"
                                          className="btnprofileinfo"
                                          onClick={CompanyUpdate}
                                        >
                                          Save Changes
                                        </button>
                                      </div>
                                    )}

                                    {/* <div className="editsavechangesbtn">
                                      <button
                                        type="button"
                                        className="btnprofileinfo"
                                        onClick={CompanyUpdate}
                                      >
                                        Save Changes
                                      </button>
                                    </div> */}
                                  </form>
                                  {/* <div className="editsavechangesbtn">
                                  <button
                                    type="button"
                                    className="btnprofileinfo"
                                    onClick={CompanyUpdate}
                                  >
                                    Save Changes
                                  </button>
                                </div> */}
                                  <div className="clearfix"></div>
                                </div>
                              </div>
                              {/* <CompanyEdit /> */}
                            </div>
                            <div
                              // className="tab-pane fade"
                              className={`tab-pane fade show ${
                                activeState === 3 && "active"
                              }`}
                              // id="status_option"
                              role="tabpanel"
                              aria-labelledby="status_option-tab"
                            >
                              <StatusOption loading={loading1} />
                            </div>
                            <div
                              // className="tab-pane fade"
                              className={`tab-pane fade show ${
                                activeState === 4 && "active"
                              }`}
                              //  id="payment_option"
                              role="tabpanel"
                              aria-labelledby="payment_option-tab"
                            >
                              <PaymentOptions />
                            </div>
                            <div
                              // className="tab-pane fade"
                              className={`tab-pane fade show ${
                                activeState === 5 && "active"
                              }`}
                              // id="tax_option"
                              role="tabpanel"
                              aria-labelledby="tax_option-tab"
                            >
                              <TaxOptions />
                            </div>
                            <div
                              className={`tab-pane fade show ${
                                activeState === 12 && "active"
                              }`}
                              role="tabpanel"
                              aria-labelledby="contact_setup-tab"
                            >
                              {" "}
                              <Contact />{" "}
                            </div>
                            <div
                              className={`tab-pane fade show ${
                                activeState === 6 && "active"
                              }`}
                              role="tabpanel"
                              aria-labelledby="register_layout-tab"
                            >
                              <Layouts />
                              {/* <CashLayout /> */}
                            </div>
                            <div
                              className={`tab-pane fade show ${
                                activeState === 11 && "active"
                              }`}
                              role="tabpanel"
                              aria-labelledby="receipt_label_config_setup-tab"
                            >
                              <RecieptLabel />
                              {/* <CashLayout /> */}
                            </div>
                            <div
                              className={`tab-pane fade show ${
                                activeState === 13 && "active"
                              }`}
                              role="tabpanel"
                              aria-labelledby="time_setting-tab"
                            >
                              {" "}
                              <TimeSettings />{" "}
                            </div>
                            <div
                              className={`tab-pane fade show ${
                                activeState === 8 && "active"
                              }`}
                              role="tabpanel"
                              aria-labelledby="user_setup-tab"
                            >
                              <div className="setting_content">
                                <h4>Users</h4>
                                <div className="inner_block plan_billing_setting">
                                  <form
                                    className="setting_form"
                                    action=""
                                    method="post"
                                  >
                                    {createState === false ? (
                                      <>
                                        <div className="block_column wd100 user_setup_block">
                                          <h5>User Setup</h5>
                                          <p>
                                            Manage the users and employees who
                                            have access to your account.
                                          </p>
                                          <div className="inner_field">
                                            <div className="tax_label_block">
                                              <div className="tax_label">
                                                <h6>User list</h6>
                                                <span>
                                                  All the users on your account
                                                </span>
                                              </div>
                                              <div className="edit_tax_btn">
                                                <button
                                                  type="button"
                                                  className="cus_btn"
                                                  onClick={() => {
                                                    setCraeteState(true);
                                                    setUserRoleEmail("");
                                                    setUserName("");
                                                    setUserPhone("");
                                                    setSelectedRole("");
                                                    setSelectedRoleId("");
                                                    setSelectedPermissions([]);
                                                  }}
                                                >
                                                  Create New User +
                                                </button>
                                              </div>
                                            </div>
                                            <div className="status_table">
                                              <div className="table-responsive">
                                                <table className="table">
                                                  <thead>
                                                    <tr>
                                                      <th>S.No.</th>
                                                      <th>User Role</th>
                                                      <th>User Name</th>
                                                      <th>User Email</th>
                                                      <th>Action</th>
                                                    </tr>
                                                  </thead>
                                                  <tbody>
                                                    {allSubuser?.map(
                                                      (item, index) => (
                                                        <>
                                                          <tr
                                                            key={
                                                              item?.subUser?.id
                                                            }
                                                          >
                                                            <td>{index + 1}</td>
                                                            <td>
                                                              {
                                                                item?.subUser
                                                                  ?.userRole
                                                                  ?.name
                                                              }
                                                            </td>
                                                            <td>
                                                              {
                                                                item?.subUser
                                                                  ?.name
                                                              }
                                                            </td>
                                                            <td>
                                                              {
                                                                item?.subUser
                                                                  ?.email
                                                              }
                                                            </td>
                                                            <td>
                                                              <div
                                                                style={{
                                                                  width:
                                                                    " 70px",
                                                                  display:
                                                                    "flex",
                                                                  gap: "10px",
                                                                  justifyContent:
                                                                    "center",
                                                                }}
                                                              >
                                                                <img
                                                                  style={{
                                                                    cursor:
                                                                      "pointer",
                                                                  }}
                                                                  src="assets/img/layout-edit-icon.webp"
                                                                  alt=""
                                                                  onClick={() => {
                                                                    HandleEdit(
                                                                      item
                                                                    );
                                                                    setEditState(
                                                                      true
                                                                    );
                                                                    setCraeteState(
                                                                      true
                                                                    );
                                                                  }}
                                                                />
                                                                <img
                                                                  style={{
                                                                    cursor:
                                                                      "pointer",
                                                                  }}
                                                                  onClick={() =>
                                                                    HandleDelete(
                                                                      item
                                                                        ?.subUser
                                                                        ?.id
                                                                    )
                                                                  }
                                                                  src="assets/img/delete-icon.webp"
                                                                  alt=""
                                                                />
                                                              </div>
                                                            </td>
                                                          </tr>
                                                        </>
                                                      )
                                                    )}
                                                  </tbody>
                                                </table>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </>
                                    ) : editState === true ? (
                                      <>
                                        {" "}
                                        <div className="create_user">
                                          <div className="block_column wd100">
                                            <a
                                              className="back_userblock"
                                              onClick={() => {
                                                setCraeteState(false);
                                                setEditState(false);
                                              }}
                                              style={{ cursor: "pointer" }}
                                            >
                                              <img
                                                src="assets/img/back-arrow.webp"
                                                alt=""
                                                className="settingusersubimgcstm"
                                              />
                                            </a>
                                            <h5>Edit User</h5>
                                            <p>
                                              Manage the users and employees who
                                              have access to your account.
                                            </p>
                                            <div className="inner_field">
                                              <div className="user_field_block">
                                                <div className="user_field">
                                                  <div className="field_row">
                                                    <div className="field_col colhalf settingformcsscstm">
                                                      <div className="form-group">
                                                        <label className="cus_label">
                                                          Full Name
                                                        </label>
                                                        <input
                                                          type="text"
                                                          className="form-control"
                                                          name="fullname"
                                                          placeholder="Enter Full Name"
                                                          value={UserName}
                                                          onChange={(e) =>
                                                            setUserName(
                                                              e.target.value
                                                            )
                                                          }
                                                        />
                                                      </div>
                                                    </div>
                                                    <div className="field_col colhalf settingformcsscstm">
                                                      <div className="form-group">
                                                        <label className="cus_label">
                                                          Email Address
                                                        </label>
                                                        <input
                                                          type="email"
                                                          className="form-control"
                                                          name="userRoleEmail"
                                                          placeholder="Email Address"
                                                          value={userRoleEmail}
                                                          onChange={(e) => {
                                                            setUserRoleEmail(
                                                              e.target.value
                                                            );

                                                            if (
                                                              error.userRoleEmail
                                                            ) {
                                                              setError({
                                                                ...error,
                                                                userRoleEmail:
                                                                  "",
                                                              });
                                                            }
                                                          }}
                                                        />
                                                        {error.userRoleEmail && (
                                                          <span className="customvalidationdesignlogin">
                                                            {
                                                              error.userRoleEmail
                                                            }
                                                          </span>
                                                        )}
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className="field_row">
                                                    <div className="field_col colhalf settingformcsscstm">
                                                      <div className="form-group">
                                                        <label className="cus_label">
                                                          Phone Number
                                                        </label>
                                                        <input
                                                          type="number"
                                                          className="form-control  no-arrow-input"
                                                          name="userPhone"
                                                          placeholder="Enter Phone Number"
                                                          value={userPhone}
                                                          onChange={(e) =>
                                                            setUserPhone(
                                                              e.target.value
                                                            )
                                                          }
                                                        />
                                                      </div>
                                                    </div>
                                                    <div className="field_col colhalf settingformcsscstm">
                                                      <div className="form-group">
                                                        <label className="cus_label">
                                                          Role
                                                        </label>
                                                        <div ref={dropdownRef}>
                                                          <div className="tagsscustomdiv rolecstmrespsetting">
                                                            <input
                                                              ref={inputRef2}
                                                              type="text"
                                                              className="customsearchinputboxsetting customrowinputsetttingfiled"
                                                              placeholder={`Select Role`}
                                                              value={
                                                                selectedRole ||
                                                                searchTermRole
                                                              }
                                                              onChange={
                                                                handleSearchChangeRole
                                                              }
                                                              onClick={() =>
                                                                setShowList(
                                                                  !showList
                                                                )
                                                              }
                                                            />
                                                            <button
                                                              onClick={
                                                                handleButtonClickRole
                                                              }
                                                              style={{
                                                                cursor:
                                                                  "pointer",
                                                              }}
                                                              className="buttonrolesetting"
                                                            >
                                                              <img
                                                                src="assets/img/select-arrow.webp"
                                                                alt="Handy Icon"
                                                              />
                                                            </button>
                                                          </div>

                                                          {showList && (
                                                            <div className="addselectbox displycustomrolelist">
                                                              <ul>
                                                                {filterRole.map(
                                                                  (item) => (
                                                                    <li
                                                                      key={
                                                                        item.id
                                                                      }
                                                                      onClick={() => {
                                                                        handleRoleSelect(
                                                                          item
                                                                        );
                                                                      }}
                                                                      style={{
                                                                        cursor:
                                                                          "pointer",
                                                                      }}
                                                                    >
                                                                      {
                                                                        item.name
                                                                      }
                                                                    </li>
                                                                  )
                                                                )}
                                                              </ul>

                                                              <button
                                                                onClick={() => {
                                                                  handleAddNewClickRole();
                                                                  if (
                                                                    inputRef2.current
                                                                  ) {
                                                                    inputRef2.current.focus();
                                                                  }
                                                                }}
                                                                style={{
                                                                  backgroundColor:
                                                                    "#9d3dd3",
                                                                }}
                                                                type="button"
                                                                className="btn cus_addnew_btn  customaddnewcatebtnsetting"
                                                              >
                                                                <span>+</span>{" "}
                                                                Add New Role
                                                              </button>
                                                            </div>
                                                          )}
                                                        </div>
                                                        {error.selectedRole && (
                                                          <span className="customvalidationdesignlogin">
                                                            {error.selectedRole}
                                                          </span>
                                                        )}
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className="clearfix"></div>
                                                  <div className="form-group">
                                                    <label
                                                      className="cus_label"
                                                      style={{
                                                        color: "#111827",
                                                        fontWeight: "600",
                                                      }}
                                                    >
                                                      Select All Role
                                                      Permissions
                                                    </label>

                                                    <div className="checkbox">
                                                      <input
                                                        type="checkbox"
                                                        className="chkfield"
                                                        name="userrole"
                                                        checked={selectAll}
                                                        onChange={
                                                          handleSelectAll
                                                        }
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                                <div
                                                  className={`status_table userrole_field ${
                                                    selectAll ? "disabled" : ""
                                                  }`}
                                                >
                                                  <div className="table-responsive">
                                                    <table className="table">
                                                      <thead>
                                                        <tr>
                                                          <th
                                                            colSpan="3"
                                                            style={{
                                                              verticalAlign:
                                                                "top",
                                                            }}
                                                          >
                                                            Role Permissions
                                                            {permissionError && (
                                                              <span className="customvalidationdesignlogin">
                                                                {
                                                                  permissionError
                                                                }
                                                              </span>
                                                            )}{" "}
                                                          </th>
                                                          <th
                                                            style={{
                                                              textAlign:
                                                                "center",
                                                            }}
                                                          >
                                                            {/* <label>
                                                              Select All
                                                            </label>
                                                            <div className="checkbox">
                                                              <input
                                                                type="checkbox"
                                                                className="chkfield"
                                                                name="userrole"
                                                                checked={
                                                                  selectAll
                                                                }
                                                                onChange={
                                                                  handleSelectAll
                                                                }
                                                              />
                                                            </div> */}
                                                          </th>
                                                        </tr>
                                                      </thead>
                                                      <tbody>
                                                        {allPermission?.map(
                                                          (item, index) =>
                                                            index % 2 === 0 ? (
                                                              <tr
                                                                key={item?.id}
                                                              >
                                                                <td>
                                                                  {item?.name}
                                                                </td>
                                                                <td>
                                                                  <div className="checkbox">
                                                                    <input
                                                                      type="checkbox"
                                                                      className="chkfield"
                                                                      value={
                                                                        item.name
                                                                      }
                                                                      name="userrole"
                                                                      // checked={selectedPermissions.includes(
                                                                      //   item?.id
                                                                      // )}
                                                                      checked={
                                                                        selectAll ||
                                                                        selectedPermissions.includes(
                                                                          item?.id
                                                                        )
                                                                      }
                                                                      disabled={
                                                                        selectAll
                                                                      }
                                                                      onChange={() =>
                                                                        handleCheckboxChange(
                                                                          item?.id
                                                                        )
                                                                      }
                                                                    />
                                                                  </div>
                                                                </td>

                                                                {allPermission[
                                                                  index + 1
                                                                ] && (
                                                                  <>
                                                                    <td>
                                                                      {
                                                                        allPermission[
                                                                          index +
                                                                            1
                                                                        ]?.name
                                                                      }
                                                                    </td>
                                                                    <td>
                                                                      <div className="checkbox">
                                                                        <input
                                                                          type="checkbox"
                                                                          className="chkfield"
                                                                          value={
                                                                            allPermission[
                                                                              index +
                                                                                1
                                                                            ]
                                                                              ?.name
                                                                          }
                                                                          name="userrole"
                                                                          // checked={selectedPermissions.includes(
                                                                          //   allPermission[
                                                                          //     index +
                                                                          //       1
                                                                          //   ]
                                                                          //     ?.id
                                                                          // )}
                                                                          checked={
                                                                            selectAll ||
                                                                            selectedPermissions.includes(
                                                                              allPermission[
                                                                                index +
                                                                                  1
                                                                              ]
                                                                                ?.id
                                                                            )
                                                                          }
                                                                          disabled={
                                                                            selectAll
                                                                          }
                                                                          onChange={() =>
                                                                            handleCheckboxChangeedit(
                                                                              allPermission[
                                                                                index +
                                                                                  1
                                                                              ]
                                                                                ?.id
                                                                            )
                                                                          }
                                                                        />
                                                                      </div>
                                                                    </td>
                                                                  </>
                                                                )}
                                                              </tr>
                                                            ) : null
                                                        )}
                                                      </tbody>
                                                    </table>
                                                  </div>
                                                </div>
                                                <div className="clearfix"></div>
                                                <div className="restepasswordcustmbtn">
                                                  <div className="userrole_btn restbuttoncustmcss">
                                                    <button
                                                      type="button"
                                                      className="role_save_btn"
                                                      onClick={() =>
                                                        HandleReset()
                                                      }
                                                    >
                                                      Reset Password
                                                    </button>
                                                  </div>
                                                  <div className="userrole_btn">
                                                    <button
                                                      type="button"
                                                      className="role_save_btn"
                                                      onClick={() => Submit1()}
                                                    >
                                                      Save
                                                    </button>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </>
                                    ) : (
                                      <>
                                        <div className="create_user">
                                          <div className="block_column wd100">
                                            <a
                                              className="back_userblock"
                                              onClick={() =>
                                                setCraeteState(false)
                                              }
                                              style={{ cursor: "pointer" }}
                                            >
                                              <img
                                                src="assets/img/back-arrow.webp"
                                                alt=""
                                                className="settingusersubimgcstm"
                                              />
                                            </a>
                                            <h5>Create New User</h5>
                                            <p>
                                              Manage the users and employees who
                                              have access to your account.
                                            </p>
                                            <div className="inner_field">
                                              <div className="user_field_block">
                                                <div className="user_field">
                                                  <div className="field_row">
                                                    <div className="field_col colhalf settingformcsscstm">
                                                      <div className="form-group">
                                                        <label className="cus_label">
                                                          Full Name
                                                        </label>
                                                        <input
                                                          type="text"
                                                          className="form-control"
                                                          name="fullname"
                                                          placeholder="Enter Full Name"
                                                          value={UserName}
                                                          onChange={(e) =>
                                                            setUserName(
                                                              e.target.value
                                                            )
                                                          }
                                                        />
                                                      </div>
                                                    </div>
                                                    <div className="field_col colhalf settingformcsscstm">
                                                      <div className="form-group">
                                                        <label className="cus_label">
                                                          Email Address
                                                        </label>
                                                        <input
                                                          type="email"
                                                          className="form-control"
                                                          name="userRoleEmail"
                                                          placeholder="Email Address"
                                                          value={userRoleEmail}
                                                          onChange={(e) => {
                                                            setUserRoleEmail(
                                                              e.target.value
                                                            );

                                                            if (
                                                              error.userRoleEmail
                                                            ) {
                                                              setError({
                                                                ...error,
                                                                userRoleEmail:
                                                                  "",
                                                              });
                                                            }
                                                          }}
                                                        />
                                                        {error.userRoleEmail && (
                                                          <span className="customvalidationdesignlogin">
                                                            {
                                                              error.userRoleEmail
                                                            }
                                                          </span>
                                                        )}
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className="field_row">
                                                    <div className="field_col colhalf settingformcsscstm">
                                                      <div className="form-group">
                                                        <label className="cus_label">
                                                          Phone Number
                                                        </label>
                                                        <input
                                                          type="number"
                                                          className="form-control  no-arrow-input"
                                                          name="userPhone"
                                                          placeholder="Enter Phone Number"
                                                          value={userPhone}
                                                          onChange={(e) =>
                                                            setUserPhone(
                                                              e.target.value
                                                            )
                                                          }
                                                        />
                                                      </div>
                                                    </div>
                                                    <div className="field_col colhalf settingformcsscstm">
                                                      <div className="form-group">
                                                        <label className="cus_label">
                                                          Role
                                                        </label>
                                                        <div ref={dropdownRef}>
                                                          <div className="tagsscustomdiv rolecstmrespsetting">
                                                            <input
                                                              ref={inputRef2}
                                                              type="text"
                                                              className="customsearchinputboxsetting customrowinputsetttingfiled"
                                                              placeholder={`Select Role`}
                                                              value={
                                                                selectedRole ||
                                                                searchTermRole
                                                              }
                                                              onChange={
                                                                handleSearchChangeRole
                                                              }
                                                              onClick={() =>
                                                                setShowList(
                                                                  !showList
                                                                )
                                                              }
                                                            />
                                                            <button
                                                              onClick={
                                                                handleButtonClickRole
                                                              }
                                                              style={{
                                                                cursor:
                                                                  "pointer",
                                                              }}
                                                              className="buttonrolesetting"
                                                            >
                                                              <img
                                                                src="assets/img/select-arrow.webp"
                                                                alt="Handy Icon"
                                                              />
                                                            </button>
                                                          </div>

                                                          {showList && (
                                                            <div className="addselectbox displycustomrolelist">
                                                              <ul>
                                                                {filterRole.map(
                                                                  (item) => (
                                                                    <li
                                                                      key={
                                                                        item.id
                                                                      }
                                                                      onClick={() => {
                                                                        handleRoleSelect(
                                                                          item
                                                                        );
                                                                      }}
                                                                      style={{
                                                                        cursor:
                                                                          "pointer",
                                                                      }}
                                                                    >
                                                                      {
                                                                        item.name
                                                                      }
                                                                    </li>
                                                                  )
                                                                )}
                                                              </ul>

                                                              <button
                                                                onClick={() => {
                                                                  handleAddNewClickRole();
                                                                  if (
                                                                    inputRef2.current
                                                                  ) {
                                                                    inputRef2.current.focus();
                                                                  }
                                                                }}
                                                                style={{
                                                                  backgroundColor:
                                                                    "#9d3dd3",
                                                                }}
                                                                type="button"
                                                                className="btn cus_addnew_btn  customaddnewcatebtnsetting"
                                                              >
                                                                <span>+</span>{" "}
                                                                Add New Role
                                                              </button>
                                                            </div>
                                                          )}
                                                        </div>
                                                        {error.selectedRole && (
                                                          <span className="customvalidationdesignlogin">
                                                            {error.selectedRole}
                                                          </span>
                                                        )}
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className="clearfix"></div>
                                                  <div className="form-group">
                                                    <label
                                                      className="cus_label"
                                                      style={{
                                                        color: "#111827",
                                                        fontWeight: "600",
                                                      }}
                                                    >
                                                      Select All Role
                                                      Permissions
                                                    </label>

                                                    <div className="checkbox">
                                                      <input
                                                        type="checkbox"
                                                        className="chkfield"
                                                        name="userrole"
                                                        checked={selectAll}
                                                        onChange={
                                                          handleSelectAll
                                                        }
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                                <div
                                                  className={`status_table userrole_field ${
                                                    selectAll ? "disabled" : ""
                                                  }`}
                                                >
                                                  <div className="table-responsive">
                                                    <table className="table">
                                                      <thead>
                                                        <tr>
                                                          <th
                                                            colSpan="3"
                                                            style={{
                                                              verticalAlign:
                                                                "top",
                                                            }}
                                                          >
                                                            Role Permissions{" "}
                                                            {permissionError && (
                                                              <span className="customvalidationdesignlogin">
                                                                {
                                                                  permissionError
                                                                }
                                                              </span>
                                                            )}{" "}
                                                          </th>
                                                          <th
                                                            style={{
                                                              textAlign:
                                                                "center",
                                                            }}
                                                          >
                                                            {/* <label>
                                                              Select All
                                                            </label>

                                                            <div className="checkbox">
                                                              <input
                                                                type="checkbox"
                                                                className="chkfield"
                                                                name="userrole"
                                                                checked={
                                                                  selectAll
                                                                }
                                                                onChange={
                                                                  handleSelectAll
                                                                }
                                                              />
                                                            </div> */}
                                                          </th>
                                                        </tr>
                                                      </thead>
                                                      <tbody>
                                                        {allPermission?.map(
                                                          (item, index) =>
                                                            index % 2 === 0 ? (
                                                              <tr
                                                                key={item?.id}
                                                              >
                                                                <td>
                                                                  {item?.name}
                                                                </td>
                                                                <td>
                                                                  <div className="checkbox">
                                                                    <input
                                                                      type="checkbox"
                                                                      className="chkfield"
                                                                      value={
                                                                        item.name
                                                                      }
                                                                      name="userrole"
                                                                      checked={
                                                                        selectAll ||
                                                                        selectedPermissions.includes(
                                                                          item?.id
                                                                        )
                                                                      }
                                                                      disabled={
                                                                        selectAll
                                                                      }
                                                                      // checked={selectedPermissions.includes(
                                                                      //   item?.id
                                                                      // )}
                                                                      onChange={() =>
                                                                        handleCheckboxChange(
                                                                          item?.id
                                                                        )
                                                                      }
                                                                    />
                                                                  </div>
                                                                </td>

                                                                {allPermission[
                                                                  index + 1
                                                                ] && (
                                                                  <>
                                                                    <td>
                                                                      {
                                                                        allPermission[
                                                                          index +
                                                                            1
                                                                        ]?.name
                                                                      }
                                                                    </td>
                                                                    <td>
                                                                      <div className="checkbox">
                                                                        <input
                                                                          type="checkbox"
                                                                          className="chkfield"
                                                                          value={
                                                                            allPermission[
                                                                              index +
                                                                                1
                                                                            ]
                                                                              ?.name
                                                                          }
                                                                          name="userrole"
                                                                          // checked={selectedPermissions.includes(
                                                                          //   allPermission[
                                                                          //     index +
                                                                          //       1
                                                                          //   ]
                                                                          //     ?.id
                                                                          // )}
                                                                          checked={
                                                                            selectAll ||
                                                                            selectedPermissions.includes(
                                                                              allPermission[
                                                                                index +
                                                                                  1
                                                                              ]
                                                                                ?.id
                                                                            )
                                                                          }
                                                                          disabled={
                                                                            selectAll
                                                                          }
                                                                          onChange={() =>
                                                                            handleCheckboxChange(
                                                                              allPermission[
                                                                                index +
                                                                                  1
                                                                              ]
                                                                                ?.id
                                                                            )
                                                                          }
                                                                        />
                                                                      </div>
                                                                    </td>
                                                                  </>
                                                                )}
                                                              </tr>
                                                            ) : null
                                                        )}
                                                      </tbody>
                                                    </table>
                                                  </div>
                                                </div>
                                                <div className="clearfix"></div>
                                                <div className="userrole_btn">
                                                  <button
                                                    type="button"
                                                    className="role_save_btn"
                                                    onClick={() => Submit()}
                                                  >
                                                    Save
                                                  </button>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </>
                                    )}
                                    <div className="clearfix"></div>
                                  </form>
                                  <div className="clearfix"></div>
                                </div>
                              </div>
                            </div>

                            <div
                              className={`tab-pane fade show ${
                                activeState === 9 && "active"
                              }`}
                              id="discount_option"
                              role="tabpanel"
                              aria-labelledby="discount_option-tab"
                            >
                              <DiscountOptions />
                            </div>

                            <div
                              // className="tab-pane fade"
                              className={`tab-pane fade show ${
                                activeState === 7 && "active"
                              }`}
                              //  id="receipt_config_setup"
                              role="tabpanel"
                              aria-labelledby="receipt_config_setup-tab"
                            >
                              <RecieptConfig />
                            </div>
                          </div>
                        </div>
                        <div className="clearfix"></div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          ) : (
            <div
              // className={`${isSidebarVisible ? "sidebar-mini" : ""} ${
              //   !isFreeTrialVisible ? "no-free-trial" : ""
              // }`}
              style={sidebarStyleone}
            >
              <nav
                className="navbar navbar-expand-lg main-navbar sticky"
                style={navbarStyle}
              >
                <div className="sidebar-arrow">
                  <a
                    href="#"
                    data-toggle="sidebar"
                    onClick={(e) => {
                      toggleSidebar();

                      e.preventDefault();
                    }}
                  >
                    <img src="assets/img/sidebar-toggle.webp" alt="" />
                  </a>
                </div>
                <div className="header-title mr-auto">
                  <h4>Dashboard</h4>
                </div>
                <SideUl image={images} name={updateUserName} />
              </nav>
              <div className="main-wrapper devicelistcstmtableres ">
                <MainSidebar image={images1} companyname={companyName} />{" "}
                <div
                  className="main-content tableback"
                  style={mainContentStyle}
                >
                  <div className="dash_setting">
                    <div className="setting_tabs_content">
                      {(userData === "null" ||
                        userData === "cancelled" ||
                        userData === "expired") &&
                      new Date(localStorage.getItem("planDate")) <
                        new Date() ? null : (
                        <>
                          <ul
                            className="nav nav-pills setting_tabs"
                            id="setting"
                            role="tablist"
                          >
                            <li className="nav-item">
                              <a
                                onClick={() => setActiveState(0)}
                                className={`nav-link ${
                                  activeState === 0 && "active"
                                }`}
                                data-toggle="tab"
                                href="#account_security"
                                role="tab"
                                aria-controls="account_security"
                                aria-selected="true"
                              >
                                Account & Security
                              </a>
                            </li>

                            {adminState === "0" &&
                            permission?.length !== 0 &&
                            userRolee !== "Super Admin" ? null : (
                              <li className="nav-item">
                                <a
                                  onClick={() => setActiveState(1)}
                                  className={`nav-link ${
                                    activeState === 1 && "active"
                                  }`}
                                  data-toggle="tab"
                                  href="#billing_plan"
                                  role="tab"
                                  aria-controls="billing_plan"
                                  aria-selected="true"
                                >
                                  Plans and Billing
                                </a>
                              </li>
                            )}

                            <li className="nav-item">
                              <a
                                onClick={() => setActiveState(2)}
                                className={`nav-link ${
                                  activeState === 2 && "active"
                                }`}
                                data-toggle="tab"
                                href="#profile_info"
                                role="tab"
                                aria-controls="profile_info"
                                aria-selected="true"
                              >
                                Profile Info
                              </a>
                            </li>
                            <li className="nav-item">
                              <a
                                onClick={() => setActiveState(3)}
                                className={`nav-link ${
                                  activeState === 3 && "active"
                                }`}
                                data-toggle="tab"
                                href="#status_option"
                                role="tab"
                                aria-controls="status_option"
                                aria-selected="true"
                              >
                                Status Option
                              </a>
                            </li>
                            <li className="nav-item">
                              <a
                                onClick={() => setActiveState(4)}
                                className={`nav-link ${
                                  activeState === 4 && "active"
                                }`}
                                data-toggle="tab"
                                href="#payment_option"
                                role="tab"
                                aria-controls="payment_option"
                                aria-selected="true"
                              >
                                Payment Option
                              </a>
                            </li>
                            <li className="nav-item">
                              <a
                                onClick={() => setActiveState(5)}
                                className={`nav-link ${
                                  activeState === 5 && "active"
                                }`}
                                data-toggle="tab"
                                href="#tax_option"
                                role="tab"
                                aria-controls="tax_option"
                                aria-selected="true"
                              >
                                Tax Option
                              </a>
                            </li>

                            <li className="nav-item">
                              <a
                                onClick={() => setActiveState(9)}
                                className={`nav-link ${
                                  activeState === 9 && "active"
                                }`}
                                data-toggle="tab"
                                href="#discount_option"
                                role="tab"
                                aria-controls="discount_option"
                                aria-selected="true"
                              >
                                Discount Option
                              </a>
                            </li>

                            <li className="nav-item">
                              <a
                                onClick={() => setActiveState(6)}
                                className={`nav-link ${
                                  activeState === 6 && "active"
                                }`}
                                data-toggle="tab"
                                href="#register_layout"
                                role="tab"
                                aria-controls="register_layout"
                                aria-selected="true"
                              >
                                Cash Register Layout
                              </a>
                            </li>
                            <li className="nav-item">
                              <a
                                onClick={() => setActiveState(7)}
                                className={`nav-link ${
                                  activeState === 7 && "active"
                                }`}
                                data-toggle="tab"
                                href="#receipt_config_setup"
                                role="tab"
                                aria-controls="receipt_config_setup"
                                aria-selected="true"
                              >
                                Receipt Configuration & Setup
                              </a>
                            </li>
                            <li className="nav-item">
                              <a
                                onClick={() => setActiveState(11)}
                                className={`nav-link ${
                                  activeState === 11 && "active"
                                }`}
                                data-toggle="tab"
                                href="#receipt_label_config_setup"
                                role="tab"
                                aria-controls="receipt_label_config_setup"
                                aria-selected="true"
                              >
                                Receipt Label Configuration & Setup
                              </a>
                            </li>
                            {adminState === "0" &&
                            permission?.length !== 0 &&
                            userRolee !== "Super Admin" ? null : (
                              <li
                                className="nav-item"
                                onClick={() => setCraeteState(false)}
                              >
                                <a
                                  onClick={() => setActiveState(8)}
                                  className={`nav-link ${
                                    activeState === 8 && "active"
                                  }`}
                                  data-toggle="tab"
                                  href="#user_setup"
                                  role="tab"
                                  aria-controls="user_setup"
                                  aria-selected="true"
                                >
                                  User Setup
                                </a>
                              </li>
                            )}
                            <li className="nav-item">
                              <a
                                onClick={() => setActiveState(12)}
                                className={`nav-link ${
                                  activeState === 12 && "active"
                                }`}
                                data-toggle="tab"
                                href="#contact_setup"
                                role="tab"
                                aria-controls="contact_setup"
                                aria-selected="true"
                              >
                                Contact Us
                              </a>
                            </li>
                            <li className="nav-item">
                              <a
                                onClick={() => setActiveState(13)}
                                className={`nav-link ${
                                  activeState === 13 && "active"
                                }`}
                                data-toggle="tab"
                                href="#time_setting"
                                role="tab"
                                aria-controls="time_setting"
                                aria-selected="true"
                              >
                                Time Setup
                              </a>
                            </li>
                          </ul>{" "}
                        </>
                      )}
                      <div className="tab-content" id="settingContent">
                        <div
                          // className="tab-pane fade active show"
                          className={`tab-pane fade show ${
                            activeState === 0 && "active"
                          }`}
                          //id="account_security"
                          role="tabpanel"
                          aria-labelledby="account_security-tab"
                        >
                          <div className="setting_content">
                            <h4>Account and Security</h4>
                            <div className="inner_block account_setting">
                              <form
                                className="setting_form"
                                action=""
                                method="post"
                              >
                                <div className="block_column wd65">
                                  <h6>Profile </h6>
                                  <div className="inner_field">
                                    <div className="form-group">
                                      <div className="form_field">
                                        <label>Username</label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="username"
                                          placeholder="@pg"
                                          value={updateUserName}
                                          onChange={(e) => {
                                            setUpdateUserName(e.target.value);
                                          }}
                                        />
                                      </div>
                                    </div>
                                    <div className="form-group">
                                      <div className="form_field">
                                        <label>Email Address</label>
                                        <span>{userEmail}</span>
                                      </div>
                                    </div>
                                    <div className="form-group">
                                      <div className="form_field ">
                                        <label>Password</label>
                                        <input
                                          type="password"
                                          className="form-control"
                                          name="password"
                                          placeholder="**********"
                                          value={password}
                                          autoComplete="new-password"
                                          // onChange={(e) => {
                                          //   setPassword(e.target.value.trim());
                                          //   if (error.password) {
                                          //     setError({ ...error, password: "" });
                                          //   }
                                          // }}
                                        />
                                        {/* {error.password && (
                                  <span className="customvalidationdesignlogin">
                                    {error.password}
                                  </span>
                                )} */}
                                        {/* <span>
                                  You signed up via Google, so you don't have a
                                  password.
                                </span> */}
                                        {adminState === "0" ? (
                                          <div className="change_btn">
                                            <button
                                              type="button"
                                              className="cus_btn green_btn"
                                              // data-toggle="modal"
                                              // data-target="#adddiscountoptionModal"
                                              // onClick={() => {
                                              //   setShowModal2(true);
                                              // }}
                                            >
                                              Change
                                            </button>
                                          </div>
                                        ) : (
                                          <div className="change_btn">
                                            <button
                                              type="button"
                                              className="cus_btn green_btn"
                                              data-toggle="modal"
                                              data-target="#adddiscountoptionModal"
                                              onClick={() => {
                                                setShowModal2(true);
                                              }}
                                            >
                                              Change
                                            </button>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  {adminState === "0" ? (
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "right",
                                      }}
                                    >
                                      <button
                                        type="button"
                                        className="btnprofileinfo"
                                        //onClick={OnUpdate}
                                      >
                                        Save Changes
                                      </button>
                                    </div>
                                  ) : (
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "right",
                                      }}
                                    >
                                      <button
                                        type="button"
                                        className="btnprofileinfo"
                                        onClick={OnUpdate}
                                      >
                                        Save Changes
                                      </button>
                                    </div>
                                  )}
                                  {/* <h6>Security</h6>
                                  <div className="inner_field">
                                    <div className="form-group">
                                      <div className="form_field pwd_field">
                                        <label>Password</label>
                                        <input
                                          type="password"
                                          className="form-control"
                                          name="password"
                                          placeholder="**********"
                                        />
                                        <span>
                                          You signed up via Google, so you don't
                                          have a password.
                                        </span>
                              
                                      </div>
                                    </div>
                                    <div className="form-group">
                                      <div className="form_field delete_field">
                                        <label>Deletion</label>
                                        <input
                                          type="password"
                                          className="form-control"
                                          name="password"
                                          placeholder="hello@designdrops.io"
                                          autoComplete="new-password"
                                        />
                                        <span>
                                          Permanently delete your account,
                                          posts, and comments.
                                        </span>
                                        <div className="change_btn">
                                          <button
                                            type="button"
                                            className="cus_btn delete_btn"
                                          >
                                            Delete
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div> */}
                                  {/* <h6>2 Factor Authentication</h6> */}
                                  <div className="inner_field">
                                    {/* <div className="form-group">
                                      <div className="form_field">
                                        <label>2-FA autentification</label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="stepfactor"
                                          placeholder="lorem"
                                        />
                                        <div className="factorfield switch_field">
                                          <label className="custom-switch">
                                            <input
                                              type="checkbox"
                                              name="custom-switch-checkbox"
                                              className="custom-switch-input"
                                              checked={checkbox}
                                              value={checkbox}
                                              onChange={handleSwitchChange}
                                            />
                                            <span className="custom-switch-indicator"></span>
                                          </label>
                                        </div>
                                      </div>
                                    </div> */}
                                    <div className="form-group">
                                      <div className="form_field">
                                        <label>Phone number</label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="phone"
                                          placeholder="+380 931234567"
                                          value={updatePhone}
                                          onChange={(e) => {
                                            setUpdatePhone(e.target.value);
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  {/* <h6>Sessions</h6>
                                  <div className="inner_field">
                                    <div className="form-group">
                                      <div className="form_field lastsignin_field">
                                        <label>Last Sign In</label>
                                        <span>
                                          today at 18:34, Safary 198.123.23.23
                                        </span>
                               
                                      </div>
                                    </div>
                                    <div className="session_list">
                                      <div className="title">
                                        <h6>Total active sessions (5)</h6>
                                        <button
                                          type="button"
                                          className="reset_session_btn"
                                        >
                                          Reset all active sessions
                                        </button>
                                      </div>
                                      <div className="active_session">
                                        <ul>
                                          <li>
                                            DESKTOP-6TIG6EC • Kyiv, Ukraine{" "}
                                            <span>Chrome • Used right now</span>
                                          </li>
                                          <li>
                                            Iphone 11 • Kyiv, Ukraine{" "}
                                            <span>Chrome • 04/19/2022</span>
                                          </li>
                                          <li>
                                            Iphone 11 • Kyiv, Ukraine{" "}
                                            <span>Chrome • 04/19/2022</span>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div> */}
                                </div>
                                <div className="block_column wd35">
                                  <h6>Profile Picture</h6>
                                  <div className="inner_field">
                                    <div className="form-group">
                                      <div className="form_field profile_field">
                                        <div className="profile_upload">
                                          <input
                                            type="file"
                                            name="profile_img"
                                            accept="image/jpeg, image/jpg, image/png"
                                            onChange={handleImages}
                                          />
                                          {images ? (
                                            <img
                                              src={images}
                                              className="userupdateimagecustom"
                                              alt=""
                                            />
                                          ) : (
                                            <>
                                              <img
                                                src="assets/img/profile-image.webp"
                                                alt=""
                                              />
                                              <span className="dum_upload_btn">
                                                Upload
                                              </span>
                                            </>
                                          )}
                                        </div>
                                        <button
                                          type="button"
                                          className="cus_btn"
                                        >
                                          <img
                                            src="assets/img/profile-edit-icon.webp"
                                            alt=""
                                          />{" "}
                                          Edit
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </form>
                              <div className="clearfix"></div>
                            </div>
                          </div>
                        </div>

                        <div
                          // className="tab-pane fade"
                          className={`tab-pane fade show ${
                            activeState === 1 && "active"
                          }`}
                          // id="billing_plan"
                          role="tabpanel"
                          aria-labelledby="billing_plan-tab"
                        >
                          <div className="setting_content">
                            <h4>Plans and Billing</h4>
                            <div className="billing_plan_upgrade">
                              <form
                                className="setting_form"
                                action=""
                                method="post"
                              >
                                <div className="block_column plan_upgrade_block wd100">
                                  <div className="inner_plan_column">
                                    <div className="plan_column">
                                      <span>Plan Name</span>
                                      {planData?.map((item) => (
                                        <>
                                          <h5>{item.name}</h5>
                                          <sub>Start Date: {StartDate}</sub>
                                          <br />
                                          <sub>
                                            Next Payment Date: {EndDate}
                                          </sub>
                                        </>
                                      ))}
                                    </div>
                                    <div className="plan_column">
                                      <span>Payment</span>
                                      {planData?.map((item) => (
                                        <h5>
                                          ${item.price} <sub>per month</sub>
                                        </h5>
                                      ))}
                                    </div>
                                    <div className="plan_column plan_btns">
                                      <a
                                        onClick={() => {
                                          makePayment();
                                          //Checkout();
                                        }}
                                        style={{ cursor: "pointer" }}
                                      >
                                        Cancel Plan
                                      </a>
                                      <button
                                        type="button"
                                        onClick={() => {
                                          makePayment();
                                          //Checkout();
                                        }}
                                      >
                                        Upgrade Plan
                                      </button>
                                    </div>
                                  </div>
                                  <div className="plan_progress_sec">
                                    <div className="plan_progress_col">
                                      <h5>Monthly Customers</h5>
                                      <div className="progress">
                                        <div
                                          className="progress_bar"
                                          role="progressbar"
                                          aria-valuenow={CustomerPercent}
                                          aria-valuemin="0"
                                          aria-valuemax="100"
                                          style={{
                                            width: `${CustomerPercent}%`,
                                          }}
                                        ></div>
                                      </div>
                                      <div className="progress_value">
                                        <span>
                                          <img
                                            src="assets/img/customer-plan-icon.webp"
                                            alt=""
                                          />{" "}
                                          {customers}({CustomerPercent}%)
                                        </span>
                                        <span>∞</span>
                                      </div>
                                    </div>
                                    <div className="plan_progress_col">
                                      <h5>Monthly Sales</h5>
                                      <div className="progress">
                                        <div
                                          className="progress_bar"
                                          role="progressbar"
                                          aria-valuenow={SalesPercent}
                                          aria-valuemin="0"
                                          aria-valuemax="100"
                                          style={{ width: `${SalesPercent}%` }}
                                        ></div>
                                      </div>
                                      <div className="progress_value">
                                        <span>
                                          <img
                                            src="assets/img/customer-plan-icon.webp"
                                            alt=""
                                          />{" "}
                                          {salesCount}(
                                          {SalesPercent ? SalesPercent : 0}
                                          %)
                                        </span>
                                        <span>∞</span>
                                      </div>
                                    </div>
                                    <div className="plan_progress_col">
                                      <h5>Monthly Invoice</h5>
                                      <div className="progress">
                                        <div
                                          className="progress_bar"
                                          role="progressbar"
                                          aria-valuenow={InvoicePercent}
                                          aria-valuemin="0"
                                          aria-valuemax="100"
                                          style={{
                                            width: `${InvoicePercent}%`,
                                          }}
                                        ></div>
                                      </div>
                                      <div className="progress_value">
                                        <span>
                                          <img
                                            src="assets/img/customer-plan-icon.webp"
                                            alt=""
                                          />{" "}
                                          {invoiceCount} (
                                          {InvoicePercent ? InvoicePercent : 0}
                                          %)
                                        </span>
                                        <span>∞</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {/* <div className="block_column payment_method_column wd100">
                                  <h5>Payment History</h5>
                                  <div className="payment_method_block">
                                    <div className="payment_card_column">
                                      <div className="card_detail">
                                        <h6>MasterCard</h6>
                                        <span>**** **** **** 4002</span>
                                        <small>Expiry on 20/2024</small>
                                      </div>
                                    </div>
                                  </div>
                                </div> */}
                                {/* <div className="block_column payment_method_column wd100">
                          <h5>Payment Method</h5>
                          <div className="payment_method_block">
                            <div className="payment_card_column">
                              <div className="card_detail">
                                <div className="card_radio">
                                  <input
                                    type="radio"
                                    name="paymentmethod"
                                    id="mastercard"
                                    checked
                                  />
                                  <span className="radio_check"></span>
                                </div>
                                <div className="card_img">
                                  <img
                                    src="assets/img/master-card.png"
                                    alt=""
                                  />
                                </div>
                                <div className="card_info">
                                  <h6>MasterCard</h6>
                                  <span>**** **** **** 4002</span>
                                  <small>Expiry on 20/2024</small>
                                </div>
                              </div>
                              <div className="card_action">
                                <a href="#">
                                  <img
                                    src="assets/img/ellipsis-horizontal.webp"
                                    alt=""
                                  />
                                </a>
                              </div>
                            </div>
                            <div className="payment_card_column">
                              <div className="card_detail">
                                <div className="card_radio">
                                  <input
                                    type="radio"
                                    name="paymentmethod"
                                    id="visacard"
                                  />
                                  <span className="radio_check"></span>
                                </div>
                                <div className="card_img">
                                  <img src="assets/img/visa-card.png" alt="" />
                                </div>
                                <div className="card_info">
                                  <h6>Visa</h6>
                                  <span>**** **** **** 4002</span>
                                  <small>Expiry on 20/2024</small>
                                </div>
                              </div>
                              <div className="card_action">
                                <a href="#">
                                  <img
                                    src="assets/img/ellipsis-horizontal.webp"
                                    alt=""
                                  />
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="add_payment_method">
                            <button type="button" className="add_new">
                              <i className="fa fa-plus"></i> New Payment Method
                            </button>
                          </div>
                        </div> */}
                              </form>
                              <div className="clearfix"></div>
                            </div>
                          </div>
                        </div>

                        <div
                          // className="tab-pane fade"
                          className={`tab-pane fade show ${
                            activeState === 2 && "active"
                          }`}
                          // id="profile_info"
                          role="tabpanel"
                          aria-labelledby="profile_info-tab"
                        >
                          <div className="setting_content">
                            <h4>Company Profile</h4>
                            <div className="inner_block account_setting compnyphotocusmtcss ">
                              <form
                                className="setting_form"
                                action=""
                                method="post"
                              >
                                <div className="block_column wd65 ">
                                  <h6>Company Profile</h6>
                                  <div className="inner_field">
                                    <div className="form-group">
                                      <div className="form_field">
                                        <label>Sub-Domain</label>
                                        <p className="form-control">
                                          {userDetails?.subDomain}
                                        </p>
                                      </div>
                                    </div>
                                    <div className="form-group">
                                      <div className="form_field">
                                        <label>Company Name</label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="company_name"
                                          placeholder="Enter Company Name"
                                          value={companyName}
                                          onChange={(e) =>
                                            setCompanyName(e.target.value)
                                          }
                                        />
                                      </div>
                                    </div>
                                    <div className="form-group">
                                      <div className="form_field">
                                        <label>Company Phone No.</label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="company_phone"
                                          placeholder="Enter Company phone No."
                                          value={companyPhone}
                                          onChange={(e) =>
                                            setCompanyPhone(e.target.value)
                                          }
                                        />
                                      </div>
                                    </div>
                                    <div className="form-group">
                                      <div className="form_field">
                                        <label>Customer Service No.</label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="company_serviceno"
                                          placeholder="Enter Company Service No."
                                          value={companyService}
                                          onChange={(e) =>
                                            setCompanyService(e.target.value)
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <h6>Security</h6>
                                  <div className="inner_field">
                                    <div className="form-group">
                                      <div className="form_field">
                                        <label>Country</label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="country"
                                          placeholder="Enter Country"
                                          value={country}
                                          onChange={(e) =>
                                            setCountry(e.target.value)
                                          }
                                        />
                                      </div>
                                    </div>
                                    <div className="form-group">
                                      <div className="form_field">
                                        <label>Street Address</label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="street_address"
                                          placeholder="Enetr Address"
                                          value={address}
                                          onChange={(e) =>
                                            setAddress(e.target.value)
                                          }
                                        />
                                      </div>
                                    </div>
                                    <div className="form-group">
                                      <div className="form_field">
                                        <label>State/Province</label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="state_province"
                                          placeholder="Enter State"
                                          value={state}
                                          onChange={(e) =>
                                            setState(e.target.value)
                                          }
                                        />
                                      </div>
                                    </div>
                                    <div className="form-group">
                                      <div className="form_field">
                                        <label>Zip/Postal Code</label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="zip_postalcode"
                                          placeholder="Enter Zip/Postal code"
                                          value={zipcode}
                                          onChange={(e) =>
                                            setZipcode(e.target.value)
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="block_column wd35">
                                  <h6>Company Picture</h6>
                                  <div className="inner_field">
                                    <div className="form-group">
                                      <div className="form_field profile_field">
                                        <div className="profile_upload">
                                          <input
                                            type="file"
                                            name="profile_img"
                                            accept="image/jpeg, image/jpg, image/png"
                                            onChange={handleImages1}
                                          />
                                          {images1 ? (
                                            <img
                                              src={images1}
                                              className="userupdateimagecustom"
                                              alt=""
                                            />
                                          ) : (
                                            <>
                                              <img
                                                src="assets/img/profile-image.webp"
                                                alt=""
                                              />
                                              <span className="dum_upload_btn">
                                                Upload
                                              </span>
                                            </>
                                          )}
                                          {/* <img src="assets/img/profile-image.webp" alt="" /> */}
                                        </div>
                                        <button
                                          type="button"
                                          className="cus_btn"
                                        >
                                          <img
                                            src="assets/img/profile-edit-icon.webp"
                                            alt=""
                                          />{" "}
                                          Edit
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {adminState === "0" ? (
                                  <div className="editsavechangesbtn">
                                    <button
                                      type="button"
                                      className="btnprofileinfo"
                                      //  onClick={CompanyUpdate}
                                    >
                                      Save Changes
                                    </button>
                                  </div>
                                ) : (
                                  <div className="editsavechangesbtn">
                                    <button
                                      type="button"
                                      className="btnprofileinfo"
                                      onClick={CompanyUpdate}
                                    >
                                      Save Changes
                                    </button>
                                  </div>
                                )}
                              </form>
                              {/* <div className="editsavechangesbtn">
                              <button
                                type="button"
                                className="btnprofileinfo"
                                onClick={CompanyUpdate}
                              >
                                Save Changes
                              </button>
                            </div> */}
                              <div className="clearfix"></div>
                            </div>
                          </div>
                          {/* <CompanyEdit /> */}
                        </div>
                        <div
                          // className="tab-pane fade"
                          className={`tab-pane fade show ${
                            activeState === 3 && "active"
                          }`}
                          // id="status_option"
                          role="tabpanel"
                          aria-labelledby="status_option-tab"
                        >
                          <StatusOption loading={loading1} />
                        </div>
                        <div
                          // className="tab-pane fade"
                          className={`tab-pane fade show ${
                            activeState === 4 && "active"
                          }`}
                          //  id="payment_option"
                          role="tabpanel"
                          aria-labelledby="payment_option-tab"
                        >
                          <PaymentOptions />
                        </div>
                        <div
                          // className="tab-pane fade"
                          className={`tab-pane fade show ${
                            activeState === 5 && "active"
                          }`}
                          // id="tax_option"
                          role="tabpanel"
                          aria-labelledby="tax_option-tab"
                        >
                          <TaxOptions />
                        </div>
                        <div
                          className={`tab-pane fade show ${
                            activeState === 12 && "active"
                          }`}
                          role="tabpanel"
                          aria-labelledby="contact_setup-tab"
                        >
                          {" "}
                          <Contact />{" "}
                        </div>
                        <div
                          className={`tab-pane fade show ${
                            activeState === 6 && "active"
                          }`}
                          role="tabpanel"
                          aria-labelledby="register_layout-tab"
                        >
                          <Layouts />
                          {/* <CashLayout /> */}
                        </div>
                        <div
                          className={`tab-pane fade show ${
                            activeState === 11 && "active"
                          }`}
                          role="tabpanel"
                          aria-labelledby="receipt_label_config_setup-tab"
                        >
                          <RecieptLabel />
                          {/* <CashLayout /> */}
                        </div>
                        <div
                          className={`tab-pane fade show ${
                            activeState === 13 && "active"
                          }`}
                          role="tabpanel"
                          aria-labelledby="time_setting-tab"
                        >
                          {" "}
                          <TimeSettings />{" "}
                        </div>
                        <div
                          className={`tab-pane fade show ${
                            activeState === 8 && "active"
                          }`}
                          role="tabpanel"
                          aria-labelledby="user_setup-tab"
                        >
                          <div className="setting_content">
                            <h4>Users</h4>
                            <div className="inner_block plan_billing_setting">
                              <form
                                className="setting_form"
                                action=""
                                method="post"
                              >
                                {createState === false ? (
                                  <>
                                    <div className="block_column wd100 user_setup_block">
                                      <h5>User Setup</h5>
                                      <p>
                                        Manage the users and employees who have
                                        access to your account.
                                      </p>
                                      <div className="inner_field">
                                        <div className="tax_label_block">
                                          <div className="tax_label">
                                            <h6>User list</h6>
                                            <span>
                                              All the users on your account
                                            </span>
                                          </div>
                                          <div className="edit_tax_btn">
                                            <button
                                              type="button"
                                              className="cus_btn"
                                              onClick={() => {
                                                setCraeteState(true);
                                                setUserRoleEmail("");
                                                setUserName("");
                                                setUserPhone("");
                                                setSelectedRole("");
                                                setSelectedRoleId("");
                                                setSelectedPermissions([]);
                                              }}
                                            >
                                              Create New User +
                                            </button>
                                          </div>
                                        </div>
                                        <div className="status_table">
                                          <div className="table-responsive">
                                            <table className="table">
                                              <thead>
                                                <tr>
                                                  <th>S.No.</th>
                                                  <th>User Role</th>
                                                  <th>User Name</th>
                                                  <th>User Email</th>
                                                  <th>Action</th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                {allSubuser?.map(
                                                  (item, index) => (
                                                    <>
                                                      <tr
                                                        key={item?.subUser?.id}
                                                      >
                                                        <td>{index + 1}</td>
                                                        <td>
                                                          {
                                                            item?.subUser
                                                              ?.userRole?.name
                                                          }
                                                        </td>
                                                        <td>
                                                          {item?.subUser?.name}
                                                        </td>
                                                        <td>
                                                          {item?.subUser?.email}
                                                        </td>
                                                        <td>
                                                          <div
                                                            style={{
                                                              width: " 70px",
                                                              display: "flex",
                                                              gap: "10px",
                                                              justifyContent:
                                                                "center",
                                                            }}
                                                          >
                                                            <img
                                                              style={{
                                                                cursor:
                                                                  "pointer",
                                                              }}
                                                              src="assets/img/layout-edit-icon.webp"
                                                              alt=""
                                                              onClick={() => {
                                                                HandleEdit(
                                                                  item
                                                                );
                                                                setEditState(
                                                                  true
                                                                );
                                                                setCraeteState(
                                                                  true
                                                                );
                                                              }}
                                                            />
                                                            <img
                                                              style={{
                                                                cursor:
                                                                  "pointer",
                                                              }}
                                                              onClick={() =>
                                                                HandleDelete(
                                                                  item?.subUser
                                                                    ?.id
                                                                )
                                                              }
                                                              src="assets/img/delete-icon.webp"
                                                              alt=""
                                                            />
                                                          </div>
                                                        </td>
                                                      </tr>
                                                    </>
                                                  )
                                                )}
                                              </tbody>
                                            </table>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                ) : editState === true ? (
                                  <>
                                    {" "}
                                    <div className="create_user">
                                      <div className="block_column wd100">
                                        <a
                                          className="back_userblock"
                                          onClick={() => {
                                            setCraeteState(false);
                                            setEditState(false);
                                          }}
                                          style={{ cursor: "pointer" }}
                                        >
                                          <img
                                            src="assets/img/back-arrow.webp"
                                            alt=""
                                            className="settingusersubimgcstm"
                                          />
                                        </a>
                                        <h5>Edit User</h5>
                                        <p>
                                          Manage the users and employees who
                                          have access to your account.
                                        </p>
                                        <div className="inner_field">
                                          <div className="user_field_block">
                                            <div className="user_field">
                                              <div className="field_row">
                                                <div className="field_col colhalf settingformcsscstm">
                                                  <div className="form-group">
                                                    <label className="cus_label">
                                                      Full Name
                                                    </label>
                                                    <input
                                                      type="text"
                                                      className="form-control"
                                                      name="fullname"
                                                      placeholder="Enter Full Name"
                                                      value={UserName}
                                                      onChange={(e) =>
                                                        setUserName(
                                                          e.target.value
                                                        )
                                                      }
                                                    />
                                                  </div>
                                                </div>
                                                <div className="field_col colhalf settingformcsscstm">
                                                  <div className="form-group">
                                                    <label className="cus_label">
                                                      Email Address
                                                    </label>
                                                    <input
                                                      type="email"
                                                      className="form-control"
                                                      name="userRoleEmail"
                                                      placeholder="Email Address"
                                                      value={userRoleEmail}
                                                      onChange={(e) => {
                                                        setUserRoleEmail(
                                                          e.target.value
                                                        );

                                                        if (
                                                          error.userRoleEmail
                                                        ) {
                                                          setError({
                                                            ...error,
                                                            userRoleEmail: "",
                                                          });
                                                        }
                                                      }}
                                                    />
                                                    {error.userRoleEmail && (
                                                      <span className="customvalidationdesignlogin">
                                                        {error.userRoleEmail}
                                                      </span>
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="field_row">
                                                <div className="field_col colhalf settingformcsscstm">
                                                  <div className="form-group">
                                                    <label className="cus_label">
                                                      Phone Number
                                                    </label>
                                                    <input
                                                      type="number"
                                                      className="form-control  no-arrow-input"
                                                      name="userPhone"
                                                      placeholder="Enter Phone Number"
                                                      value={userPhone}
                                                      onChange={(e) =>
                                                        setUserPhone(
                                                          e.target.value
                                                        )
                                                      }
                                                    />
                                                  </div>
                                                </div>
                                                <div className="field_col colhalf settingformcsscstm">
                                                  <div className="form-group">
                                                    <label className="cus_label">
                                                      Role
                                                    </label>
                                                    <div ref={dropdownRef}>
                                                      <div className="tagsscustomdiv rolecstmrespsetting">
                                                        <input
                                                          ref={inputRef2}
                                                          type="text"
                                                          className="customsearchinputboxsetting customrowinputsetttingfiled"
                                                          placeholder={`Select Role`}
                                                          value={
                                                            selectedRole ||
                                                            searchTermRole
                                                          }
                                                          onChange={
                                                            handleSearchChangeRole
                                                          }
                                                          onClick={() =>
                                                            setShowList(
                                                              !showList
                                                            )
                                                          }
                                                        />
                                                        <button
                                                          onClick={
                                                            handleButtonClickRole
                                                          }
                                                          style={{
                                                            cursor: "pointer",
                                                          }}
                                                          className="buttonrolesetting"
                                                        >
                                                          <img
                                                            src="assets/img/select-arrow.webp"
                                                            alt="Handy Icon"
                                                          />
                                                        </button>
                                                      </div>

                                                      {showList && (
                                                        <div className="addselectbox displycustomrolelist">
                                                          <ul>
                                                            {filterRole.map(
                                                              (item) => (
                                                                <li
                                                                  key={item.id}
                                                                  onClick={() => {
                                                                    handleRoleSelect(
                                                                      item
                                                                    );
                                                                  }}
                                                                  style={{
                                                                    cursor:
                                                                      "pointer",
                                                                  }}
                                                                >
                                                                  {item.name}
                                                                </li>
                                                              )
                                                            )}
                                                          </ul>

                                                          <button
                                                            onClick={() => {
                                                              handleAddNewClickRole();
                                                              if (
                                                                inputRef2.current
                                                              ) {
                                                                inputRef2.current.focus();
                                                              }
                                                            }}
                                                            style={{
                                                              backgroundColor:
                                                                "#9d3dd3",
                                                            }}
                                                            type="button"
                                                            className="btn cus_addnew_btn  customaddnewcatebtnsetting"
                                                          >
                                                            <span>+</span> Add
                                                            New Role
                                                          </button>
                                                        </div>
                                                      )}
                                                    </div>
                                                    {error.selectedRole && (
                                                      <span className="customvalidationdesignlogin">
                                                        {error.selectedRole}
                                                      </span>
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="clearfix"></div>
                                              <div className="form-group">
                                                <label
                                                  className="cus_label"
                                                  style={{
                                                    color: "#111827",
                                                    fontWeight: "600",
                                                  }}
                                                >
                                                  Select All Role Permissions
                                                </label>

                                                <div className="checkbox">
                                                  <input
                                                    type="checkbox"
                                                    className="chkfield"
                                                    name="userrole"
                                                    checked={selectAll}
                                                    onChange={handleSelectAll}
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                            <div
                                              className={`status_table userrole_field ${
                                                selectAll ? "disabled" : ""
                                              }`}
                                            >
                                              <div className="table-responsive">
                                                <table className="table">
                                                  <thead>
                                                    <tr>
                                                      <th
                                                        colSpan="3"
                                                        style={{
                                                          verticalAlign: "top",
                                                        }}
                                                      >
                                                        Role Permissions
                                                        {permissionError && (
                                                          <span className="customvalidationdesignlogin">
                                                            {permissionError}
                                                          </span>
                                                        )}
                                                      </th>
                                                      <th
                                                        style={{
                                                          textAlign: "center",
                                                        }}
                                                      >
                                                        {/* <label>
                                                          Select All
                                                        </label>

                                                        <div className="checkbox">
                                                          <input
                                                            type="checkbox"
                                                            className="chkfield"
                                                            name="userrole"
                                                            checked={selectAll}
                                                            onChange={
                                                              handleSelectAll
                                                            }
                                                          />
                                                        </div> */}
                                                      </th>
                                                    </tr>
                                                  </thead>
                                                  <tbody>
                                                    {allPermission?.map(
                                                      (item, index) =>
                                                        index % 2 === 0 ? (
                                                          <tr key={item?.id}>
                                                            <td>
                                                              {item?.name}
                                                            </td>
                                                            <td>
                                                              <div className="checkbox">
                                                                <input
                                                                  type="checkbox"
                                                                  className="chkfield"
                                                                  value={
                                                                    item.name
                                                                  }
                                                                  name="userrole"
                                                                  // checked={selectedPermissions.includes(
                                                                  //   item?.id
                                                                  // )}
                                                                  checked={
                                                                    selectAll ||
                                                                    selectedPermissions.includes(
                                                                      item?.id
                                                                    )
                                                                  }
                                                                  disabled={
                                                                    selectAll
                                                                  }
                                                                  onChange={() =>
                                                                    handleCheckboxChange(
                                                                      item?.id
                                                                    )
                                                                  }
                                                                />
                                                              </div>
                                                            </td>

                                                            {allPermission[
                                                              index + 1
                                                            ] && (
                                                              <>
                                                                <td>
                                                                  {
                                                                    allPermission[
                                                                      index + 1
                                                                    ]?.name
                                                                  }
                                                                </td>
                                                                <td>
                                                                  <div className="checkbox">
                                                                    <input
                                                                      type="checkbox"
                                                                      className="chkfield"
                                                                      value={
                                                                        allPermission[
                                                                          index +
                                                                            1
                                                                        ]?.name
                                                                      }
                                                                      name="userrole"
                                                                      // checked={selectedPermissions.includes(
                                                                      //   allPermission[
                                                                      //     index +
                                                                      //       1
                                                                      //   ]?.id
                                                                      // )}
                                                                      checked={
                                                                        selectAll ||
                                                                        selectedPermissions.includes(
                                                                          allPermission[
                                                                            index +
                                                                              1
                                                                          ]?.id
                                                                        )
                                                                      }
                                                                      disabled={
                                                                        selectAll
                                                                      }
                                                                      onChange={() =>
                                                                        handleCheckboxChangeedit(
                                                                          allPermission[
                                                                            index +
                                                                              1
                                                                          ]?.id
                                                                        )
                                                                      }
                                                                    />
                                                                  </div>
                                                                </td>
                                                              </>
                                                            )}
                                                          </tr>
                                                        ) : null
                                                    )}
                                                  </tbody>
                                                </table>
                                              </div>
                                            </div>
                                            <div className="clearfix"></div>
                                            <div className="restepasswordcustmbtn">
                                              <div className="userrole_btn restbuttoncustmcss">
                                                <button
                                                  type="button"
                                                  className="role_save_btn"
                                                  onClick={() => HandleReset()}
                                                >
                                                  Reset Password
                                                </button>
                                              </div>
                                              <div className="userrole_btn">
                                                <button
                                                  type="button"
                                                  className="role_save_btn"
                                                  onClick={() => Submit1()}
                                                >
                                                  Save
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div className="create_user">
                                      <div className="block_column wd100">
                                        <a
                                          className="back_userblock"
                                          onClick={() => setCraeteState(false)}
                                          style={{ cursor: "pointer" }}
                                        >
                                          <img
                                            src="assets/img/back-arrow.webp"
                                            alt=""
                                            className="settingusersubimgcstm"
                                          />
                                        </a>
                                        <h5>Create New User</h5>
                                        <p>
                                          Manage the users and employees who
                                          have access to your account.
                                        </p>
                                        <div className="inner_field">
                                          <div className="user_field_block">
                                            <div className="user_field">
                                              <div className="field_row">
                                                <div className="field_col colhalf settingformcsscstm">
                                                  <div className="form-group">
                                                    <label className="cus_label">
                                                      Full Name
                                                    </label>
                                                    <input
                                                      type="text"
                                                      className="form-control"
                                                      name="fullname"
                                                      placeholder="Enter Full Name"
                                                      value={UserName}
                                                      onChange={(e) =>
                                                        setUserName(
                                                          e.target.value
                                                        )
                                                      }
                                                    />
                                                  </div>
                                                </div>
                                                <div className="field_col colhalf settingformcsscstm">
                                                  <div className="form-group">
                                                    <label className="cus_label">
                                                      Email Address
                                                    </label>
                                                    <input
                                                      type="email"
                                                      className="form-control"
                                                      name="userRoleEmail"
                                                      placeholder="Email Address"
                                                      value={userRoleEmail}
                                                      onChange={(e) => {
                                                        setUserRoleEmail(
                                                          e.target.value
                                                        );

                                                        if (
                                                          error.userRoleEmail
                                                        ) {
                                                          setError({
                                                            ...error,
                                                            userRoleEmail: "",
                                                          });
                                                        }
                                                      }}
                                                    />
                                                    {error.userRoleEmail && (
                                                      <span className="customvalidationdesignlogin">
                                                        {error.userRoleEmail}
                                                      </span>
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="field_row">
                                                <div className="field_col colhalf settingformcsscstm">
                                                  <div className="form-group">
                                                    <label className="cus_label">
                                                      Phone Number
                                                    </label>
                                                    <input
                                                      type="number"
                                                      className="form-control  no-arrow-input"
                                                      name="userPhone"
                                                      placeholder="Enter Phone Number"
                                                      value={userPhone}
                                                      onChange={(e) =>
                                                        setUserPhone(
                                                          e.target.value
                                                        )
                                                      }
                                                    />
                                                  </div>
                                                </div>
                                                <div className="field_col colhalf settingformcsscstm">
                                                  <div className="form-group">
                                                    <label className="cus_label">
                                                      Role
                                                    </label>
                                                    <div ref={dropdownRef}>
                                                      <div className="tagsscustomdiv rolecstmrespsetting">
                                                        <input
                                                          ref={inputRef2}
                                                          type="text"
                                                          className="customsearchinputboxsetting customrowinputsetttingfiled"
                                                          placeholder={`Select Role`}
                                                          value={
                                                            selectedRole ||
                                                            searchTermRole
                                                          }
                                                          onChange={
                                                            handleSearchChangeRole
                                                          }
                                                          onClick={() =>
                                                            setShowList(
                                                              !showList
                                                            )
                                                          }
                                                        />
                                                        <button
                                                          onClick={
                                                            handleButtonClickRole
                                                          }
                                                          style={{
                                                            cursor: "pointer",
                                                          }}
                                                          className="buttonrolesetting"
                                                        >
                                                          <img
                                                            src="assets/img/select-arrow.webp"
                                                            alt="Handy Icon"
                                                          />
                                                        </button>
                                                      </div>

                                                      {showList && (
                                                        <div className="addselectbox displycustomrolelist">
                                                          <ul>
                                                            {filterRole.map(
                                                              (item) => (
                                                                <li
                                                                  key={item.id}
                                                                  onClick={() => {
                                                                    handleRoleSelect(
                                                                      item
                                                                    );
                                                                  }}
                                                                  style={{
                                                                    cursor:
                                                                      "pointer",
                                                                  }}
                                                                >
                                                                  {item.name}
                                                                </li>
                                                              )
                                                            )}
                                                          </ul>

                                                          <button
                                                            onClick={() => {
                                                              handleAddNewClickRole();
                                                              if (
                                                                inputRef2.current
                                                              ) {
                                                                inputRef2.current.focus();
                                                              }
                                                            }}
                                                            style={{
                                                              backgroundColor:
                                                                "#9d3dd3",
                                                            }}
                                                            type="button"
                                                            className="btn cus_addnew_btn  customaddnewcatebtnsetting"
                                                          >
                                                            <span>+</span> Add
                                                            New Role
                                                          </button>
                                                        </div>
                                                      )}
                                                    </div>
                                                    {error.selectedRole && (
                                                      <span className="customvalidationdesignlogin">
                                                        {error.selectedRole}
                                                      </span>
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="clearfix"></div>
                                              <div className="form-group">
                                                <label
                                                  className="cus_label"
                                                  style={{
                                                    color: "#111827",
                                                    fontWeight: "600",
                                                  }}
                                                >
                                                  Select All Role Permissions
                                                </label>

                                                <div className="checkbox">
                                                  <input
                                                    type="checkbox"
                                                    className="chkfield"
                                                    name="userrole"
                                                    checked={selectAll}
                                                    onChange={handleSelectAll}
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                            <div
                                              className={`status_table userrole_field ${
                                                selectAll ? "disabled" : ""
                                              }`}
                                            >
                                              {/* <div className="status_table userrole_field"> */}
                                              <div className="table-responsive">
                                                <table className="table">
                                                  <thead>
                                                    <tr>
                                                      <th
                                                        colSpan="3"
                                                        style={{
                                                          verticalAlign: "top",
                                                        }}
                                                      >
                                                        Role Permissions{" "}
                                                        {permissionError && (
                                                          <span className="customvalidationdesignlogin">
                                                            {permissionError}
                                                          </span>
                                                        )}
                                                      </th>
                                                      <th
                                                        style={{
                                                          textAlign: "center",
                                                        }}
                                                      >
                                                        {/* <label>
                                                          Select All
                                                        </label>

                                                        <div className="checkbox">
                                                          <input
                                                            type="checkbox"
                                                            className="chkfield"
                                                            name="userrole"
                                                            checked={selectAll}
                                                            onChange={
                                                              handleSelectAll
                                                            }
                                                          />
                                                        </div> */}
                                                      </th>
                                                    </tr>
                                                  </thead>
                                                  <tbody>
                                                    {allPermission?.map(
                                                      (item, index) =>
                                                        index % 2 === 0 ? (
                                                          <tr key={item?.id}>
                                                            <td>
                                                              {item?.name}
                                                            </td>
                                                            <td>
                                                              <div className="checkbox">
                                                                <input
                                                                  type="checkbox"
                                                                  className="chkfield"
                                                                  value={
                                                                    item.name
                                                                  }
                                                                  name="userrole"
                                                                  checked={
                                                                    selectAll ||
                                                                    selectedPermissions.includes(
                                                                      item?.id
                                                                    )
                                                                  }
                                                                  onChange={() =>
                                                                    handleCheckboxChange(
                                                                      item?.id
                                                                    )
                                                                  }
                                                                  disabled={
                                                                    selectAll
                                                                  }
                                                                  // checked={selectedPermissions.includes(
                                                                  //   item?.id
                                                                  // )}
                                                                  // onChange={() =>
                                                                  //   handleCheckboxChange(
                                                                  //     item?.id
                                                                  //   )
                                                                  // }
                                                                />
                                                              </div>
                                                            </td>

                                                            {allPermission[
                                                              index + 1
                                                            ] && (
                                                              <>
                                                                <td>
                                                                  {
                                                                    allPermission[
                                                                      index + 1
                                                                    ]?.name
                                                                  }
                                                                </td>
                                                                <td>
                                                                  <div className="checkbox">
                                                                    <input
                                                                      type="checkbox"
                                                                      className="chkfield"
                                                                      value={
                                                                        allPermission[
                                                                          index +
                                                                            1
                                                                        ]?.name
                                                                      }
                                                                      name="userrole"
                                                                      // checked={selectedPermissions.includes(
                                                                      //   allPermission[
                                                                      //     index +
                                                                      //       1
                                                                      //   ]?.id
                                                                      // )}
                                                                      checked={
                                                                        selectAll ||
                                                                        selectedPermissions.includes(
                                                                          allPermission[
                                                                            index +
                                                                              1
                                                                          ]?.id
                                                                        )
                                                                      }
                                                                      disabled={
                                                                        selectAll
                                                                      }
                                                                      onChange={() =>
                                                                        handleCheckboxChange(
                                                                          allPermission[
                                                                            index +
                                                                              1
                                                                          ]?.id
                                                                        )
                                                                      }
                                                                    />
                                                                  </div>
                                                                </td>
                                                              </>
                                                            )}
                                                          </tr>
                                                        ) : null
                                                    )}
                                                  </tbody>
                                                </table>
                                              </div>
                                            </div>
                                            <div className="clearfix"></div>
                                            <div className="userrole_btn">
                                              <button
                                                type="button"
                                                className="role_save_btn"
                                                onClick={() => Submit()}
                                              >
                                                Save
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                )}
                                <div className="clearfix"></div>
                              </form>
                              <div className="clearfix"></div>
                            </div>
                          </div>
                        </div>

                        <div
                          className={`tab-pane fade show ${
                            activeState === 9 && "active"
                          }`}
                          id="discount_option"
                          role="tabpanel"
                          aria-labelledby="discount_option-tab"
                        >
                          <DiscountOptions />
                        </div>

                        <div
                          // className="tab-pane fade"
                          className={`tab-pane fade show ${
                            activeState === 7 && "active"
                          }`}
                          //  id="receipt_config_setup"
                          role="tabpanel"
                          aria-labelledby="receipt_config_setup-tab"
                        >
                          <RecieptConfig />
                        </div>
                      </div>
                    </div>
                    <div className="clearfix"></div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* <div className="main-wrapper devicelistcstmtableres "> */}
          {/* <NavBar image={images} name={updateUserName} />
        {(userData === "null" ||
          userData === "cancelled" ||
          userData === "expired") &&
        new Date(localStorage.getItem("planDate")) < new Date() ? null : (
          <MainSidebar image={images1} />
        )} */}
          {/* <!-- Main Content --> */}

          {showModal2 === true ? (
            <>
              <div
                className="modal common_modal status_option_modal"
                id="adddiscountoptionModal"
                tabindex="-1"
                role="dialog"
                aria-labelledby="adddiscountoptionModalLabel"
                aria-hidden="true"
              >
                <div
                  className="modal-dialog modal-dialog-centered"
                  role="document"
                >
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5
                        className="modal-title"
                        id="adddiscountoptionModalLabel"
                      >
                        Update Password
                      </h5>
                    </div>
                    <div className="modal-body">
                      <div className="custom_form newservice_form">
                        <form action="" method="" className="cus_form">
                          <div className="form_row">
                            <div className="form_column col_full">
                              <div className="form-group">
                                <label className="cus_label">
                                  Old Password:
                                </label>
                                <div className="formfield field_wd100  customupdateformfiledsetting">
                                  <img
                                    src={
                                      showPassword
                                        ? "assets/img/eyeclose.webp"
                                        : "assets/img/eyeup.webp"
                                    }
                                    className="customupdatepasswordformeyeiconsetting"
                                    onClick={togglePasswordVisibility}
                                    alt={
                                      showPassword
                                        ? "Hide password"
                                        : "Show password"
                                    }
                                  />
                                  <input
                                    type={showPassword ? "text" : "password"}
                                    className="form-control"
                                    name="password"
                                    placeholder="*********"
                                    value={oldPassword}
                                    autoComplete="new-password"
                                    onChange={(e) => {
                                      setOldPassword(e.target.value.trim());
                                      if (error.oldPassword) {
                                        setError({ ...error, oldPassword: "" });
                                      }
                                    }}
                                  />
                                </div>
                                {error.oldPassword && (
                                  <span className="customvalidationdesignlogin  customupdatepasswordeorrorsetting">
                                    {error.oldPassword}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="form_column col_full">
                              <div className="form-group">
                                <label className="cus_label">
                                  New Password:
                                </label>
                                <div className="formfield field_wd100 customupdateformfiledsetting">
                                  <img
                                    src={
                                      showPassword1
                                        ? "assets/img/eyeclose.webp"
                                        : "assets/img/eyeup.webp"
                                    }
                                    className="customupdatepasswordformeyeiconsetting"
                                    onClick={togglePasswordVisibility1}
                                    alt={
                                      showPassword1
                                        ? "Hide password"
                                        : "Show password"
                                    }
                                  />
                                  <input
                                    type={showPassword1 ? "text" : "password"}
                                    className="form-control"
                                    name="password"
                                    placeholder="*********"
                                    value={password}
                                    autoComplete="new-password"
                                    onChange={(e) => {
                                      setPassword(e.target.value.trim());
                                      if (error.password) {
                                        setError({ ...error, password: "" });
                                      }
                                    }}
                                  />
                                </div>
                                {error.password && (
                                  <span className="customvalidationdesignlogin customupdatepasswordeorrorsetting">
                                    {error.password}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="form_column col_full">
                              <div className="form-group">
                                <label className="cus_label">
                                  Confirm Password:
                                </label>
                                <div className="formfield field_wd100 customupdateformfiledsetting">
                                  <img
                                    src={
                                      showPassword2
                                        ? "assets/img/eyeclose.webp"
                                        : "assets/img/eyeup.webp"
                                    }
                                    className="customupdatepasswordformeyeiconsetting"
                                    onClick={togglePasswordVisibility2}
                                    alt={
                                      showPassword2
                                        ? "Hide password"
                                        : "Show password"
                                    }
                                  />
                                  <input
                                    type={showPassword2 ? "text" : "password"}
                                    className="form-control"
                                    name="password"
                                    placeholder="*********"
                                    value={confirmPassword}
                                    autoComplete="new-password"
                                    onChange={(e) => {
                                      setConfirmPassword(e.target.value.trim());
                                      if (error.confirmPassword) {
                                        setError({
                                          ...error,
                                          confirmPassword: "",
                                        });
                                      }
                                    }}
                                  />
                                </div>
                                {error.confirmPassword && (
                                  <span className="customvalidationdesignlogin customupdatepasswordeorrorsetting">
                                    {error.confirmPassword}
                                  </span>
                                )}
                                {message && (
                                  <span className="customvalidationdesignlogin customupdatepasswordeorrorsetting">
                                    {message}
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-secondary default_btn"
                        data-dismiss="modal"
                        onClick={() => {
                          setShowModal2(false);
                          setOldPassword("");
                          setPassword("");
                          setError("");
                          setConfirmPassword("");
                          setMessage("");
                          document
                            .querySelectorAll(".modal-backdrop")
                            .forEach((el) =>
                              el.classList.remove("modal-backdrop")
                            );
                        }}
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        className="btn btn-primary theme_btn"
                        onClick={SubmitPassword}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : null}
          <Footer />
        </div>
      </div>
      {/* </div> */}
    </>
  );
};
export default Settings;
