import React, { useEffect, useState } from "react";
import SideUl from "./SideUl";
import MainSidebar from "./MainSidebar";
import { Link, useNavigate } from "react-router-dom";
import ProductModalList from "./ProductModalList";
import Swal from "sweetalert2";
import Footer from "../Footer";
import { Helmet } from "react-helmet";
import {
  baseurl,
  GetManufactureApi,
  ImportApi,
  ImportProductApi,
} from "../Utility/Api";
import FreeTrail from "./FreeTrail";
import { CSVLink } from "react-csv";
import ProductModal from "./ProductModal";
import DeviceModal from "./DeviceModal";
import ProductDeviceModal from "./ProductDeviceModal";
import DeviceProductModal from "./DeviceProductModal";

const ProductList = () => {
  const [categories, setCategories] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [filteredDataa, setFilteredData] = useState([]);
  const [filteredDataa1, setFilteredData1] = useState([]);
  const [brands, setBrands] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [allProduct, setAllProducts] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [sortState, setSortState] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("Filter Table");
  const [isSortAsc, setIsSortAsc] = useState(false);
  const [selectedCategory1, setSelectedCategory1] = useState("default");
  const [selectedCategory2, setSelectedCategory2] = useState("default");
  const [selectedCategory3, setSelectedCategory3] = useState("default");
  const [selectedCategory4, setSelectedCategory4] = useState("default");
  const [draft, setDraft] = useState("Select Status");
  const [adminState, setAdminState] = useState("");
  const [showModal3, setShowModal3] = useState(false);
  const [showModal4, setShowModal4] = useState(false);
  const [showModal5, setShowModal5] = useState(false);
  const [totalPages1, setTotalPages] = useState(1);
  const [csvFile, setCsvFile] = useState("");

  const handleCategoryChange = (value) => {
    setSelectedCategory1(value);
    if (value === "default") {
      setFilteredData(filteredData);
    } else {
      setFilteredData(
        filteredData.filter((item) => item?.itemCategory?.name === value)
      );
    }
  };

  const handleBrandChange = (value) => {
    setSelectedCategory2(value);
    if (value === "default") {
      setFilteredData(filteredData);
    } else {
      setFilteredData(
        filteredData.filter((item) => item?.brand?.name === value)
      );
    }
  };
  const handleSupplierChange = (value) => {
    setSelectedCategory3(value);
    if (value === "default") {
      setFilteredData(filteredData);
    } else {
      setFilteredData([
        ...filteredData.filter(
          (item) =>
            item?.manufacturer?.name && item?.manufacturer?.name === value
        ),
      ]);
    }
  };

  const getStatusName = (value) => {
    switch (value) {
      case 1:
        return "Active";
      case 0:
        return "Inactive";
      case "draft":
        return "Draft";
      default:
        return "Select a status";
    }
  };

  const handleStatusChange = (value) => {
    setSelectedCategory4(value);

    setCurrentPage(1);
    if (value === "default") {
      setFilteredData(filteredData);
    } else if (value === "draft") {
      setFilteredData(filteredData.filter((item) => item?.draft));
    } else {
      setFilteredData(
        filteredData.filter(
          (item) => item?.activeInactive === value && item?.draft === 0
        )
      );
    }
  };

  const handleStatusChangeDraft = (value) => {
    setDraft("Draft");
    setSelectedCategory4(value);

    if (value === "default") {
      setFilteredData(filteredData);
    } else {
      let dataa = filteredData.filter(
        (item) =>
          item?.draft === value ||
          (item?.activeInactive === 1 && item?.activeInactive === 0)
      );
      setFilteredData([...dataa]);
    }
  };

  const HandleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete data",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#c76cff",
      cancelButtonColor: "#efefef",
      confirmButtonText: "Yes, delete it!",
      confirmButtonText: "<span style='color: white;'>Yes, delete it!</span>",
      cancelButtonText: "<span style='color: black;'>cancel!</span>",
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      customClass: {
        container: "sweet-alert2-container-zindex",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Deleted!",
          text: "Your file has been deleted.",
          icon: "success",
          confirmButtonColor: "#c76cff",
          customClass: {
            container: "sweet-alert2-container-zindex",
          },
        }).then(() => {
          ProductDelete(id);
        });
      }
    });
  };

  useEffect(() => {
    let localAdmin = localStorage.getItem("admin");
    let localPerminssion = JSON.parse(localStorage.getItem("permission"));

    setAdminState(localAdmin);
    GetCategory();
    GetSupplier();
    GetBrands();
    GetAllProducts();
  }, []);

  const GetCategory = () => {
    setLoading(true);
    let local = localStorage.getItem("token");

    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${baseurl}getAllItemCategory?productCategoryId=1`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status == true) {
          setCategories(result?.data);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch((error) => console.log("error", error));
    setLoading(false);
  };

  const GetSupplier = () => {
    setLoading(true);
    let local = localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(GetManufactureApi, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status == true) {
          setSuppliers(result?.data);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch((error) => console.log("error", error));
    setLoading(false);
  };

  const GetBrands = () => {
    setLoading(true);
    let local = localStorage.getItem("token");

    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${baseurl}getAllBrand?productCategoryId=1`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log("allbrands", result);
        if (result.status == true) {
          setBrands(result?.data);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log("error", error);
        setLoading(false);
      });
  };

  const GetAllProducts = () => {
    setLoading(true);
    let local = localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);
    myHeaders.append(
      "Cookie",
      "connect.sid=s%3AJ0pT5xJJsG8rHqot1A6txMupaTPvbUM2.LHRBUtee7s0DQmEebl5p1hig5dbcABIB0m4VWSzru%2Fo"
    );

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    //   fetch(`${baseurl}getAllItems?productCategoryId=1`, requestOptions)
    fetch(`${baseurl}getAllItems`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === true) {
          // console.log("productlist", result);
          setLoading(false);
          setAllProducts(result?.data.reverse());
        } else {
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log("error", error);
        setLoading(false);
      });
  };

  const handleSelectRow = (id) => {
    // Toggle selected state for a specific item
    const newSelectedItems = selectedItems.includes(id)
      ? selectedItems.filter((itemId) => itemId !== id)
      : [...selectedItems, id];

    setSelectedItems(newSelectedItems);
  };

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    const newSelectedItems = selectAll ? [] : allProduct.map((item) => item.id);
    setSelectedItems(newSelectedItems);
  };
  const handleSearchTermChange = (e) => {
    setSearchTerm(e.target.value);
  };

  // const filteredData = allProduct?.filter((item) => {
  //   const lowerCaseSearchTerm = searchTerm.toLowerCase();

  //   return (
  //     item.title.toLowerCase().includes(lowerCaseSearchTerm) ||
  //     item?.brand?.name.toLowerCase().includes(lowerCaseSearchTerm) ||
  //     item.skuCode.toString().includes(lowerCaseSearchTerm)
  //   );
  // });

  const filteredData =
    selectedCategory1 === "default" &&
    selectedCategory2 === "default" &&
    selectedCategory3 === "default" &&
    selectedCategory4 === "default"
      ? allProduct
      : allProduct.filter(
          (item) =>
            (selectedCategory1 === "default" ||
              item?.itemCategory?.name === selectedCategory1) &&
            (selectedCategory2 === "default" ||
              item?.brand?.name === selectedCategory2) &&
            (selectedCategory3 === "default" ||
              (item?.manufacturer?.name &&
                item?.manufacturer?.name === selectedCategory3)) &&
            (selectedCategory4 === "default" ||
              (selectedCategory4 === "draft" && item?.draft === 1) ||
              (item?.activeInactive === parseInt(selectedCategory4) &&
                item?.draft === 0))
        );
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentData = filteredData
    .slice(startIndex, endIndex)
    .filter(
      (item) =>
        item?.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item?.brand?.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item?.skuCode.toString().includes(searchTerm.toLowerCase())
    );

  const handlePageClick = (newPage) => {
    setCurrentPage(newPage);
  };

  const onpress = () => {
    setShowModal(false);
  };
  const onpress1 = () => {
    setShowModal4(false);
  };
  const onpress2 = () => {
    setShowModal5(false);
  };
  const sortProduct = (a) => {
    let sorted = allProduct.sort((a, b) => (a?.title > b?.title ? 1 : -1));

    setAllProducts([...sorted]);
  };
  const sortProduct1 = (a) => {
    let sorted = allProduct.sort((a, b) => (a?.title < b?.title ? 1 : -1));

    setAllProducts([...sorted]);
  };

  const sortSupplier = (a) => {
    let sorted = allProduct.sort((a, b) =>
      a?.supplier?.name > b?.supplier?.name ? 1 : -1
    );

    setAllProducts([...sorted]);
  };
  const sortSupplier1 = (a) => {
    let sorted = allProduct.sort((a, b) =>
      a?.supplier?.name < b?.supplier?.name ? 1 : -1
    );

    setAllProducts([...sorted]);
  };

  const sortSKUCode = (a) => {
    let sorted = allProduct.sort((a, b) => (a?.skuCode > b?.skuCode ? 1 : -1));

    setAllProducts([...sorted]);
  };
  const sortSKUCode1 = (a) => {
    let sorted = allProduct.sort((a, b) => (a?.skuCode < b?.skuCode ? 1 : -1));

    setAllProducts([...sorted]);
  };

  const sortUnitCost = (a) => {
    let sorted = allProduct.sort((a, b) =>
      a?.unitCost > b?.unitCost ? 1 : -1
    );

    setAllProducts([...sorted]);
  };
  const sortUnitCost1 = (a) => {
    let sorted = allProduct.sort((a, b) =>
      a?.unitCost < b?.unitCost ? 1 : -1
    );

    setAllProducts([...sorted]);
  };

  const sortPrice = (a) => {
    let sorted = allProduct.sort((a, b) =>
      a?.sellingPrice > b?.sellingPrice ? 1 : -1
    );

    setAllProducts([...sorted]);
  };
  const sortPrice1 = (a) => {
    let sorted = allProduct.sort((a, b) =>
      a?.sellingPrice < b?.sellingPrice ? 1 : -1
    );

    setAllProducts([...sorted]);
  };

  const sortCategory = (a) => {
    let sorted = allProduct.sort((a, b) =>
      a?.itemCategory?.name > b?.itemCategory?.name ? 1 : -1
    );

    setAllProducts([...sorted]);
  };
  const sortCategory1 = (a) => {
    let sorted = allProduct.sort((a, b) =>
      a?.itemCategory?.name < b?.itemCategory?.name ? 1 : -1
    );

    setAllProducts([...sorted]);
  };
  const sortBrand = (a) => {
    let sorted = allProduct.sort((a, b) =>
      a?.brand?.name > b?.brand?.name ? 1 : -1
    );

    setAllProducts([...sorted]);
  };
  const sortBrand1 = (a) => {
    let sorted = allProduct.sort((a, b) =>
      a?.brand?.name < b?.brand?.name ? 1 : -1
    );

    setAllProducts([...sorted]);
  };

  const handleFilterChange1 = (event) => {
    const selectedValue = event.currentTarget.textContent.trim();

    if (selectedValue === "Filter By Date") {
      const sortedData = [...allProduct].sort((a, b) => {
        const nameA = `${a?.createdAt}`.toLowerCase();
        const nameB = `${b?.createdAt}`.toLowerCase();

        if (isSortAsc) {
          return nameA.localeCompare(nameB);
        } else {
          return nameB.localeCompare(nameA);
        }
      });

      setAllProducts(sortedData);
      setIsSortAsc(!isSortAsc);

      setAllProducts(sortedData);
      setSelectedFilter("Filter By Date");
    } else if (selectedValue === "Filter By Price") {
      const sortedData = [...allProduct].sort((a, b) => {
        const nameA = `${a?.sellingPrice}`.toLowerCase();
        const nameB = `${b?.sellingPrice}`.toLowerCase();

        if (isSortAsc) {
          return nameA.localeCompare(nameB);
        } else {
          return nameB.localeCompare(nameA);
        }
      });

      setAllProducts(sortedData);
      setIsSortAsc(!isSortAsc);

      setAllProducts(sortedData);
      setSelectedFilter("Filter By Price");
    } else {
      // Handle other cases or set the data as per your requirement
      // Example: setData(initialData); // Assuming initialData is your initial dataset
      setSelectedFilter("Filter Table");
    }
  };

  const ProductDelete = (id) => {
    let local = localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${local}`);

    var raw = JSON.stringify({
      isDeleted: 1,
      imeiNumber: null,
    });

    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${baseurl}updateItem/${id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === true) {
          GetAllProducts();
          // Swal.fire(result?.message);
        } else {
          Swal.fire(result?.message);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const [isFreeTrial, setIsFreeTrial] = useState(false);

  useEffect(() => {
    const substatus = localStorage.getItem("substatus");
    const planDate = localStorage.getItem("planDate");

    if (
      substatus === "null" ||
      substatus === "canceled" ||
      substatus === "expired"
    ) {
      setIsFreeTrial(true);
    }
  }, []);

  const headers = [
    { label: "Product", key: "product" }, // Change to title since we're not using the image URL directly
    { label: "Category", key: "category" },
    { label: "Brand", key: "brand" },
    { label: "Supplier", key: "supplier" },
    { label: "SKU/BARCODE", key: "skuCode" },
    { label: "Inventory", key: "inventory" },
    { label: "Unit Cost", key: "unitCost" },
    { label: "Retail Price", key: "sellingPrice" },
    { label: "Status", key: "activeInactive" },
  ];

  const preprocessData = (data) => {
    return data.map((item) => ({
      product: item?.title || "--", // Use title for product name
      category: item?.itemCategory?.name || "--",
      brand: item?.brand?.name || "--",
      supplier: item?.supplier?.name || "--",
      skuCode: item?.skuCode || "--",
      inventory: item?.inventory || 0,
      unitCost: item?.unitCost || 0,
      sellingPrice: item?.sellingPrice || 0,
      activeInactive:
        item?.draft === 1
          ? "Draft"
          : item?.activeInactive === 1
          ? "Active"
          : "Inactive", // Determine status
    }));
  };
  const data = preprocessData(allProduct);

  const [isSidebarVisible, setSidebarVisible] = useState(false);

  const toggleSidebar = () => {
    setSidebarVisible(!isSidebarVisible);
    localStorage.setItem("sidebar", !isSidebarVisible);
  };

  useEffect(() => {
    const savedSidebarState = localStorage.getItem("sidebar");
    if (savedSidebarState) {
      setSidebarVisible(JSON.parse(savedSidebarState));
    }
  }, []);

  const [isFreeTrialVisible, setFreeTrialVisible] = useState(true);

  const toggleFreeTrial = () => {
    setFreeTrialVisible(!isFreeTrialVisible);
  };

  const sidebarStyleone = {
    overflow: "auto",
    scrollbarWidth: "none",
    msOverflowStyle: "none",
    // position: 'sticky',

    // marginTop: isFreeTrialVisible ? "0px" : "0px",
  };

  const navbarStyle = {
    // top: isFreeTrialVisible ? "-4px" : "-50px",
  };
  const mainContentStyle = {
    // marginTop: isFreeTrialVisible ? "132px" : "92px",
  };
  const mainwrapper = {
    // marginTop: isSidebarVisible ? "0" : "0",
  };
  useEffect(() => {
    if (isFreeTrial) {
      // toggleSidebar(false);
      toggleFreeTrial(false);
    } else {
      // toggleSidebar(true);
      toggleFreeTrial(true);
    }
  }, [isFreeTrial]);
  useEffect(() => {
    const handleClickOutside = (event) => {
      const sidebarElement = document.querySelector(".main-sidebar");
      const toggleButtonElement = document.querySelector(".sidebar-arrow a");

      if (
        sidebarElement &&
        !sidebarElement.contains(event.target) &&
        toggleButtonElement &&
        !toggleButtonElement.contains(event.target)
      ) {
        const mediaQuery = window.matchMedia("(max-width: 1024px)");
        if (mediaQuery.matches) {
          setSidebarVisible(false);
        }
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const startPage = Math.max(currentPage - 2, 1);
  const endPage = Math.min(startPage + 3, totalPages);
  const pages = Array.from({ length: totalPages }, (_, index) => index + 1);

  const handleImport = () => {
    // Logic for importing data
    console.log("Import button clicked");
    document.getElementById("fileInput").click();
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setCsvFile(file);

      ImportCsv(file);

      // console.log("File selected:", file.name);
    }
  };

  const ImportCsv = (file, retries = 3) => {
    setLoading(true);
    const local = localStorage.getItem("token");

    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${local}`);

    const formdata = new FormData();
    formdata.append("file", file);

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    // console.log("form", formdata);

    const fetchData = (attempt) => {
      fetch(ImportProductApi, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          console.log(result);

          if (result.status === true) {
            GetAllProducts();
            // GetCustomer(currentPage, itemsPerPage, "");
            document.getElementById("fileInput").value = "";
            setLoading(false);
          } else {
            document.getElementById("fileInput").value = "";
            setLoading(false);
          }
        })
        .catch((error) => {
          document.getElementById("fileInput").value = "";
          if (attempt < retries) {
            console.log(`Retrying... (${attempt + 1}/${retries})`);
            fetchData(attempt + 1);
          } else {
            setLoading(false);
            console.error(error);
          }
        });
    };

    fetchData(0);
  };

  return (
    <>
      <div className={isSidebarVisible ? "sidebar-mini" : ""}>
        <div
          className={`${
            isFreeTrial
              ? "main-wrapper devicelistcstmtableres customclswrapper"
              : "main-wrapper devicelistcstmtableres "
          } `}
        >
          <Helmet>
            <title>Organize Your Products | Toolbox Product List</title>
            <meta
              name="description"
              content="Efficiently manage your product catalog with our Product List tool. Stay organized and enhance your inventory control for better business outcomes."
            />
          </Helmet>
          {loading ? (
            <div className="landing_loader">
              <div className="innerloader">
                <img src="img/spin-1s-156px.gif" alt="" />
              </div>
            </div>
          ) : null}
          {isFreeTrial ? (
            <>
              {localStorage.getItem("substatus") === "null" ||
              localStorage.getItem("substatus") === "canceled" ||
              localStorage.getItem("substatus") === "expired" ? (
                <>
                  <div className="app-container-trial">
                    <FreeTrail />
                  </div>
                  <div
                    // className={`${isSidebarVisible ? "sidebar-mini" : ""} ${
                    //   !isFreeTrialVisible ? "no-free-trial" : ""
                    // }`}
                    style={sidebarStyleone}
                  >
                    <nav
                      className="navbar navbar-expand-lg main-navbar sticky"
                      style={navbarStyle}
                    >
                      <div className="sidebar-arrow">
                        <a
                          href="#"
                          data-toggle="sidebar"
                          onClick={(e) => {
                            toggleSidebar();

                            e.preventDefault();
                          }}
                        >
                          <img src="assets/img/sidebar-toggle.webp" alt="" />
                        </a>
                      </div>
                      <div className="header-title mr-auto">
                        <h4>
                          Products{" "}
                          <span className="count">
                            {allProduct?.length} Products
                          </span>
                        </h4>
                      </div>
                      <SideUl />
                    </nav>
                    <MainSidebar />
                    <div
                      className="main-content tableback"
                      style={mainContentStyle}
                    >
                      <div className="common_filter_sec">
                        <div className="filter_col">
                          <div className="filtermanagecustmdiv filter_tble_btn">
                            <div className="dropdown customtabledropdownbox">
                              <button
                                type="button"
                                className="btn btn-secondary dropdown-toggle customdropdowntablebtnsetting"
                                href="#"
                                role="button"
                                id="dropdownMenuLink"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                {selectedFilter === "default"
                                  ? "Filter Table"
                                  : selectedFilter}
                              </button>
                              <img
                                src="assets/img/filter-icon.webp"
                                alt=""
                                className="filtericonimgboxsetting"
                              />
                              <ul
                                className="dropdown-menu customdropdownulboxsetting"
                                aria-labelledby="dropdownMenuLink"
                              >
                                <li>
                                  <a
                                    className="dropdown-item"
                                    href="#"
                                    onClick={handleFilterChange1}
                                  >
                                    Filter Table
                                  </a>
                                </li>
                                <li>
                                  <a
                                    className="dropdown-item"
                                    href="#"
                                    onClick={handleFilterChange1}
                                  >
                                    Filter By Date
                                  </a>
                                </li>
                                <li>
                                  <a
                                    className="dropdown-item"
                                    href="#"
                                    onClick={handleFilterChange1}
                                  >
                                    Filter By Price
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="manage_btn">
                            <a href="#">Manage</a>
                          </div>
                          <div className="search_field">
                            <div className="search_icon">
                              <img src="assets/img/search-icon.webp" alt="" />
                            </div>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Find Products"
                              value={searchTerm}
                              onChange={handleSearchTermChange}
                            />
                          </div>
                          <div className="imp_exp_btn export_btn">
                            <CSVLink
                              data={data}
                              headers={headers}
                              filename={"table-data.csv"}
                            >
                              <button type="button">
                                <img src="assets/img/export-icon.webp" alt="" />{" "}
                                Export
                              </button>
                            </CSVLink>
                            {/* <button type="button">
                  <img src="assets/img/export-icon.webp" alt="" /> Export
                </button> */}
                          </div>
                          <div className="imp_exp_btn import_btn">
                            <button type="button" onClick={handleImport}>
                              <img src="assets/img/import-icon.webp" alt="" />{" "}
                              Import
                            </button>
                            <input
                              type="file"
                              id="fileInput"
                              style={{ display: "none" }}
                              onChange={handleFileChange}
                            />
                          </div>
                          {/* <div className="imp_exp_btn import_btn">
                            <button type="button">
                              <img src="assets/img/import-icon.webp" alt="" />{" "}
                              Import
                            </button>
                          </div> */}

                          <div
                            className="add_customer_btn"
                            // onClick={() => setShowModal(true)}
                          >
                            <a
                              href="#"
                              // data-toggle="modal"
                              // data-target="#createproductModal"
                              data-toggle="modal"
                              data-target="#newproductModal"
                              onClick={() => setShowModal3(true)}
                            >
                              New Products <i className="fa fa-plus" />
                            </a>
                          </div>
                        </div>
                        <div className="filter_select_option">
                          <div className="ft_select_option category_option option_15 customdropdownulboxcate">
                            <div className="dropdown customtabledropdownbox1">
                              <button
                                type="button"
                                className="btn btn-secondary dropdown-toggle customdropdowntablebtnsetting textaligncustmtext"
                                role="button"
                                id="dropdownMenuLink"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                {selectedCategory1 === "default"
                                  ? "Select a category"
                                  : selectedCategory1}
                              </button>
                              <ul
                                className="dropdown-menu customdropdownulboxsetting ulcustmrepairdropdowncss"
                                aria-labelledby="dropdownMenuLink"
                              >
                                <li>
                                  <a
                                    className="dropdown-item"
                                    href="#"
                                    onClick={() =>
                                      handleCategoryChange("default")
                                    }
                                  >
                                    Select a category
                                  </a>
                                </li>
                                {categories.map((category) => (
                                  <li key={category.id}>
                                    <a
                                      className="dropdown-item"
                                      href="#"
                                      onClick={() =>
                                        handleCategoryChange(category?.name)
                                      }
                                    >
                                      {category?.name}
                                    </a>
                                  </li>
                                ))}
                              </ul>
                              <img src="assets/img/select-arrow.webp" alt="" />
                            </div>
                          </div>
                          <div className="ft_select_option brand_option option_15 customdropdownulboxcate">
                            <div className="dropdown customtabledropdownbox1">
                              <button
                                type="button"
                                className="btn btn-secondary dropdown-toggle customdropdowntablebtnsetting textaligncustmtext"
                                role="button"
                                id="dropdownMenuLink"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                {selectedCategory2 === "default"
                                  ? "Select a brand"
                                  : selectedCategory2}
                              </button>
                              <ul
                                className="dropdown-menu customdropdownulboxsetting ulcustmrepairdropdowncss"
                                aria-labelledby="dropdownMenuLink"
                              >
                                <li>
                                  <a
                                    className="dropdown-item"
                                    href="#"
                                    onClick={() => handleBrandChange("default")}
                                  >
                                    Select a brand
                                  </a>
                                </li>
                                {brands.map((item) => (
                                  <li key={item.id}>
                                    <a
                                      className="dropdown-item"
                                      href="#"
                                      onClick={() =>
                                        handleBrandChange(item.name)
                                      }
                                    >
                                      {item?.name}
                                    </a>
                                  </li>
                                ))}
                              </ul>
                              <img src="assets/img/select-arrow.webp" alt="" />
                            </div>
                          </div>
                          <div className="ft_select_option supplier_option option_20 customdropdownulboxsuppl">
                            <div className="dropdown customtabledropdownboxsuppl">
                              <button
                                type="button"
                                className="btn btn-secondary dropdown-toggle customdropdowntablebtnsetting textaligncustmtext"
                                role="button"
                                id="dropdownMenuLink"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                {selectedCategory3 === "default"
                                  ? "Select a Manufacturer"
                                  : selectedCategory3}
                              </button>
                              <ul
                                className="dropdown-menu customdropdownulboxsetting ulcustmrepairdropdowncss"
                                aria-labelledby="dropdownMenuLink"
                              >
                                <li>
                                  <a
                                    className="dropdown-item"
                                    href="#"
                                    onClick={() =>
                                      handleSupplierChange("default")
                                    }
                                  >
                                    Select a Manufacturer
                                  </a>
                                </li>
                                {suppliers.map((item) => (
                                  <li key={item.id}>
                                    <a
                                      className="dropdown-item"
                                      href="#"
                                      onClick={() =>
                                        handleSupplierChange(item?.name)
                                      }
                                    >
                                      {item?.name}
                                    </a>
                                  </li>
                                ))}
                              </ul>
                              <img src="assets/img/select-arrow.webp" alt="" />
                            </div>
                          </div>
                          <div className="search_field">
                            <div className="search_icon">
                              <img src="assets/img/search-icon.webp" alt="" />
                            </div>
                            <input
                              type="text"
                              className="form-control purchasecustminpt"
                              placeholder="Search Purchase Order number"
                            />
                          </div>
                          <div className="ft_select_option status_option option_20 customdropdownulboxsuppl">
                            <div className="dropdown customtabledropdownboxsuppl">
                              <button
                                type="button"
                                className="btn btn-secondary dropdown-toggle customdropdowntablebtnsetting textaligncustmtext"
                                role="button"
                                id="dropdownMenuLink"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                {getStatusName(selectedCategory4)}
                              </button>
                              <ul
                                className="dropdown-menu customdropdownulboxsetting ulcustmrepairdropdowncss"
                                aria-labelledby="dropdownMenuLink"
                              >
                                <li>
                                  <a
                                    className="dropdown-item"
                                    href="#"
                                    onClick={() =>
                                      handleStatusChange("default")
                                    }
                                  >
                                    Select a status
                                  </a>
                                </li>

                                <li>
                                  <a
                                    className="dropdown-item"
                                    href="#"
                                    onClick={() => handleStatusChange(1)}
                                  >
                                    Active
                                  </a>
                                </li>
                                <li>
                                  <a
                                    className="dropdown-item"
                                    href="#"
                                    onClick={() => handleStatusChange(0)}
                                  >
                                    Inactive
                                  </a>
                                </li>
                                <li>
                                  <a
                                    className="dropdown-item"
                                    href="#"
                                    onClick={() => handleStatusChange("draft")}
                                  >
                                    Draft
                                  </a>
                                </li>
                              </ul>
                              <img src="assets/img/select-arrow.webp" alt="" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="clearfix" />
                      <div className="row">
                        <div className="col-lg-12 col-md-12">
                          <div className="customertablemain">
                            <table className="table table-striped  customdatatable">
                              <thead className="customtheadpadding">
                                <tr>
                                  <th scope="col" className="checkbtnthsetting">
                                    <input
                                      className="form-check-input customcheckboxbtnsetting"
                                      type="checkbox"
                                      value=""
                                      id="flexCheckDefault"
                                      onChange={handleSelectAll}
                                      checked={selectAll}
                                    />
                                  </th>

                                  <th
                                    scope="col"
                                    className="customnameth  customnamethactive"
                                  >
                                    Product{" "}
                                    {sortState === 0 ? (
                                      <span
                                        className="upperarrow"
                                        onClick={() => {
                                          sortProduct();
                                          setSortState(0);
                                        }}
                                      >
                                        <i
                                          className="fa fa-long-arrow-up active"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    ) : (
                                      <span
                                        className="upperarrow"
                                        onClick={() => {
                                          sortProduct();
                                          setSortState(0);
                                        }}
                                      >
                                        <i
                                          className="fa fa-long-arrow-up"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    )}
                                    {sortState === 1 ? (
                                      <span
                                        className="lowerarrow"
                                        onClick={() => {
                                          sortProduct1();
                                          setSortState(1);
                                        }}
                                      >
                                        <i
                                          className="fa fa-long-arrow-down active "
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    ) : (
                                      <span
                                        className="lowerarrow"
                                        onClick={() => {
                                          sortProduct1();
                                          setSortState(1);
                                        }}
                                      >
                                        <i
                                          className="fa fa-long-arrow-down "
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    )}
                                  </th>
                                  <th scope="col" className="customnameth">
                                    Category{" "}
                                    {sortState === 2 ? (
                                      <span
                                        className="upperarrow"
                                        onClick={() => {
                                          sortCategory();
                                          setSortState(2);
                                        }}
                                      >
                                        <i
                                          className="fa fa-long-arrow-up active"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    ) : (
                                      <span
                                        className="upperarrow"
                                        onClick={() => {
                                          sortCategory();
                                          setSortState(2);
                                        }}
                                      >
                                        <i
                                          className="fa fa-long-arrow-up"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    )}
                                    {sortState === 3 ? (
                                      <span
                                        className="lowerarrow"
                                        onClick={() => {
                                          sortCategory1();
                                          setSortState(3);
                                        }}
                                      >
                                        <i
                                          className="fa fa-long-arrow-down active "
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    ) : (
                                      <span
                                        className="lowerarrow"
                                        onClick={() => {
                                          sortCategory1();
                                          setSortState(3);
                                        }}
                                      >
                                        <i
                                          className="fa fa-long-arrow-down "
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    )}
                                  </th>
                                  <th scope="col" className="customnameth">
                                    Brand{" "}
                                    {sortState === 4 ? (
                                      <span
                                        className="upperarrow"
                                        onClick={() => {
                                          sortBrand();
                                          setSortState(4);
                                        }}
                                      >
                                        <i
                                          className="fa fa-long-arrow-up active"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    ) : (
                                      <span
                                        className="upperarrow"
                                        onClick={() => {
                                          sortBrand();
                                          setSortState(4);
                                        }}
                                      >
                                        <i
                                          className="fa fa-long-arrow-up"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    )}
                                    {sortState === 5 ? (
                                      <span
                                        className="lowerarrow"
                                        onClick={() => {
                                          sortBrand1();
                                          setSortState(5);
                                        }}
                                      >
                                        <i
                                          className="fa fa-long-arrow-down active "
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    ) : (
                                      <span
                                        className="lowerarrow"
                                        onClick={() => {
                                          sortBrand1();
                                          setSortState(5);
                                        }}
                                      >
                                        <i
                                          className="fa fa-long-arrow-down "
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    )}
                                  </th>
                                  <th scope="col" className="customnameth">
                                    Supplier
                                    {sortState === 6 ? (
                                      <span
                                        className="upperarrow"
                                        onClick={() => {
                                          sortSupplier();
                                          setSortState(6);
                                        }}
                                      >
                                        <i
                                          className="fa fa-long-arrow-up active"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    ) : (
                                      <span
                                        className="upperarrow"
                                        onClick={() => {
                                          sortSupplier();
                                          setSortState(6);
                                        }}
                                      >
                                        <i
                                          className="fa fa-long-arrow-up"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    )}
                                    {sortState === 7 ? (
                                      <span
                                        className="lowerarrow"
                                        onClick={() => {
                                          sortSupplier1();
                                          setSortState(7);
                                        }}
                                      >
                                        <i
                                          className="fa fa-long-arrow-down active "
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    ) : (
                                      <span
                                        className="lowerarrow"
                                        onClick={() => {
                                          sortSupplier1();
                                          setSortState(7);
                                        }}
                                      >
                                        <i
                                          className="fa fa-long-arrow-down "
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    )}
                                  </th>
                                  <th scope="col" className="customnameth">
                                    SKU/BARCODE{" "}
                                    {sortState === 8 ? (
                                      <span
                                        className="upperarrow"
                                        onClick={() => {
                                          sortSKUCode();
                                          setSortState(8);
                                        }}
                                      >
                                        <i
                                          className="fa fa-long-arrow-up active"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    ) : (
                                      <span
                                        className="upperarrow"
                                        onClick={() => {
                                          sortSKUCode();
                                          setSortState(8);
                                        }}
                                      >
                                        <i
                                          className="fa fa-long-arrow-up"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    )}
                                    {sortState === 9 ? (
                                      <span
                                        className="lowerarrow"
                                        onClick={() => {
                                          sortSKUCode1();
                                          setSortState(9);
                                        }}
                                      >
                                        <i
                                          className="fa fa-long-arrow-down active "
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    ) : (
                                      <span
                                        className="lowerarrow"
                                        onClick={() => {
                                          sortSKUCode1();
                                          setSortState(9);
                                        }}
                                      >
                                        <i
                                          className="fa fa-long-arrow-down "
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    )}
                                  </th>
                                  <th scope="col" className="customnameth">
                                    Inventory{" "}
                                    {sortState === 10 ? (
                                      <span
                                        className="upperarrow"
                                        onClick={() => {
                                          setSortState(10);
                                        }}
                                      >
                                        <i
                                          className="fa fa-long-arrow-up active"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    ) : (
                                      <span
                                        className="upperarrow"
                                        onClick={() => {
                                          setSortState(10);
                                        }}
                                      >
                                        <i
                                          className="fa fa-long-arrow-up"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    )}
                                    {sortState === 11 ? (
                                      <span
                                        className="lowerarrow"
                                        onClick={() => {
                                          setSortState(11);
                                        }}
                                      >
                                        <i
                                          className="fa fa-long-arrow-down active "
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    ) : (
                                      <span
                                        className="lowerarrow"
                                        onClick={() => {
                                          setSortState(11);
                                        }}
                                      >
                                        <i
                                          className="fa fa-long-arrow-down "
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    )}
                                  </th>
                                  <th scope="col" className="customnameth">
                                    Unit Cost{" "}
                                    {sortState === 12 ? (
                                      <span
                                        className="upperarrow"
                                        onClick={() => {
                                          sortUnitCost();
                                          setSortState(12);
                                        }}
                                      >
                                        <i
                                          className="fa fa-long-arrow-up active"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    ) : (
                                      <span
                                        className="upperarrow"
                                        onClick={() => {
                                          sortUnitCost();
                                          setSortState(12);
                                        }}
                                      >
                                        <i
                                          className="fa fa-long-arrow-up"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    )}
                                    {sortState === 13 ? (
                                      <span
                                        className="lowerarrow"
                                        onClick={() => {
                                          sortUnitCost1();
                                          setSortState(13);
                                        }}
                                      >
                                        <i
                                          className="fa fa-long-arrow-down active "
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    ) : (
                                      <span
                                        className="lowerarrow"
                                        onClick={() => {
                                          sortUnitCost1();
                                          setSortState(13);
                                        }}
                                      >
                                        <i
                                          className="fa fa-long-arrow-down "
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    )}
                                  </th>
                                  <th scope="col" className="customnameth">
                                    Retail price{" "}
                                    {sortState === 14 ? (
                                      <span
                                        className="upperarrow"
                                        onClick={() => {
                                          sortPrice();
                                          setSortState(14);
                                        }}
                                      >
                                        <i
                                          className="fa fa-long-arrow-up active"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    ) : (
                                      <span
                                        className="upperarrow"
                                        onClick={() => {
                                          sortPrice();
                                          setSortState(14);
                                        }}
                                      >
                                        <i
                                          className="fa fa-long-arrow-up"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    )}
                                    {sortState === 15 ? (
                                      <span
                                        className="lowerarrow"
                                        onClick={() => {
                                          sortPrice1();
                                          setSortState(15);
                                        }}
                                      >
                                        <i
                                          className="fa fa-long-arrow-down active "
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    ) : (
                                      <span
                                        className="lowerarrow"
                                        onClick={() => {
                                          sortPrice1();
                                          setSortState(15);
                                        }}
                                      >
                                        <i
                                          className="fa fa-long-arrow-down "
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    )}
                                  </th>
                                  <th scope="col" className="customnameth">
                                    Status{" "}
                                    {sortState === 16 ? (
                                      <span
                                        className="upperarrow"
                                        onClick={() => {
                                          setSortState(16);
                                        }}
                                      >
                                        <i
                                          className="fa fa-long-arrow-up active"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    ) : (
                                      <span
                                        className="upperarrow"
                                        onClick={() => {
                                          setSortState(16);
                                        }}
                                      >
                                        <i
                                          className="fa fa-long-arrow-up"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    )}
                                    {sortState === 17 ? (
                                      <span
                                        className="lowerarrow"
                                        onClick={() => {
                                          setSortState(17);
                                        }}
                                      >
                                        <i
                                          className="fa fa-long-arrow-down active "
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    ) : (
                                      <span
                                        className="lowerarrow"
                                        onClick={() => {
                                          setSortState(17);
                                        }}
                                      >
                                        <i
                                          className="fa fa-long-arrow-down "
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    )}
                                  </th>
                                  <th scope="col" className="customnameth">
                                    Action
                                  </th>
                                </tr>
                              </thead>
                              <tbody className="customtheadpadding">
                                {currentData.length ? (
                                  <>
                                    {currentData?.map((item, index) => (
                                      <>
                                        <tr
                                          key={index.id}
                                          className={
                                            index % 2 === 0 ? "" : "customeven"
                                          }
                                        >
                                          <td
                                            key={index.id}
                                            className={
                                              index % 2 === 0 ? "" : "bgcolor"
                                            }
                                          >
                                            <input
                                              key={index.id}
                                              className="form-check-input customcheckboxbtnsetting"
                                              type="checkbox"
                                              value=""
                                              id={`flexCheckDefault${index}`}
                                              onChange={() =>
                                                handleSelectRow(item?.id)
                                              }
                                              checked={selectedItems.includes(
                                                item?.id
                                              )}
                                            />
                                          </td>
                                          <td
                                            key={index.id}
                                            className={
                                              index % 2 === 0
                                                ? ""
                                                : " prod_img bgcolor"
                                            }
                                          >
                                            {item?.image ? (
                                              <img
                                                key={index.id}
                                                className="imglistcstm"
                                                src={item?.image}
                                                alt=""
                                              />
                                            ) : (
                                              <img
                                                src="assets/img/defaultimg.webp"
                                                className="imglistcstm"
                                                style={{ marginRight: "10px" }}
                                                alt=""
                                              />
                                            )}
                                            <span>{item?.title}</span>
                                          </td>
                                          <td
                                            key={index.id}
                                            className={
                                              index % 2 === 0 ? "" : "bgcolor"
                                            }
                                          >
                                            {item?.itemCategory?.name}
                                          </td>
                                          <td
                                            key={index.id}
                                            className={
                                              index % 2 === 0 ? "" : "bgcolor"
                                            }
                                          >
                                            {item?.brand?.name}
                                          </td>
                                          <td
                                            key={index.id}
                                            className={
                                              index % 2 === 0 ? "" : "bgcolor"
                                            }
                                          >
                                            {item?.supplier?.name}
                                          </td>
                                          <td
                                            key={index.id}
                                            className={
                                              index % 2 === 0 ? "" : "bgcolor"
                                            }
                                          >
                                            {item?.skuCode}
                                          </td>
                                          <td
                                            key={index.id}
                                            className={
                                              index % 2 === 0 ? "" : "bgcolor"
                                            }
                                          >
                                            {item?.inventory}
                                          </td>
                                          <td
                                            key={index.id}
                                            className={
                                              index % 2 === 0 ? "" : "bgcolor"
                                            }
                                          >
                                            ${item?.unitCost?.toFixed(2)}
                                          </td>
                                          <td
                                            key={index.id}
                                            className={
                                              index % 2 === 0 ? "" : "bgcolor"
                                            }
                                          >
                                            ${item?.sellingPrice.toFixed(2)}
                                          </td>

                                          <td
                                            key={index.id}
                                            className={
                                              index % 2 === 0
                                                ? ""
                                                : "status_td st_inactive bgcolor"
                                            }
                                            //className="status_td st_inactive"
                                          >
                                            {item?.draft === 1 ? (
                                              <span
                                                className="spanactiveinactivecstm2"
                                                key={index.id}
                                              >
                                                Draft
                                              </span>
                                            ) : item?.activeInactive === 1 ? (
                                              <span
                                                className="spanactiveinactivecstm1"
                                                key={index.id}
                                              >
                                                Active
                                              </span>
                                            ) : (
                                              <span
                                                className="spanactiveinactivecstm"
                                                key={index.id}
                                              >
                                                Inactive
                                              </span>
                                            )}
                                          </td>
                                          <td
                                            key={index.id}
                                            className={
                                              index % 2 === 0 ? "" : "bgcolor"
                                            }
                                          >
                                            {item?.productCategoryId === 2 ? (
                                              <Link
                                                key={index.id}
                                                to="/editdevice"
                                                state={{ data: item }}
                                                className="edit_action_btn"
                                              >
                                                <img
                                                  src="assets/img/action-edit-icon.webp"
                                                  alt=""
                                                />
                                              </Link>
                                            ) : (
                                              <Link
                                                key={index.id}
                                                to="/editproduct"
                                                state={{ data: item }}
                                                className="edit_action_btn"
                                              >
                                                <img
                                                  src="assets/img/action-edit-icon.webp"
                                                  alt=""
                                                />
                                              </Link>
                                            )}

                                            <i
                                              className="fa-solid fa-trash deletecustmtb"
                                              onClick={() =>
                                                HandleDelete(item?.id)
                                              }
                                            ></i>
                                          </td>
                                        </tr>
                                      </>
                                    ))}
                                  </>
                                ) : (
                                  <>
                                    <tr id="noDataMessage">
                                      <td colSpan="10" className="nodataav">
                                        No data available
                                      </td>
                                    </tr>
                                  </>
                                )}
                              </tbody>
                            </table>
                            {allProduct.length > 0 ? (
                              <div className="row">
                                <div className="col-md-12">
                                  <div className="custombottompaginationbox">
                                    <nav aria-label="Page navigation example">
                                      <ul className="pagination">
                                        <li
                                          className="page-item custompaginationli  paginationleftbtnbox"
                                          style={{ cursor: "pointer" }}
                                        >
                                          <button
                                            type="button"
                                            className="page-link"
                                            onClick={() =>
                                              handlePageClick(currentPage - 1)
                                            }
                                            disabled={currentPage === 1}
                                          >
                                            <img src="assets/img/previous.webp" />
                                          </button>
                                        </li>
                                        {pages.map((page, key) => {
                                          if (
                                            page === 1 ||
                                            page === totalPages ||
                                            (page >= startPage &&
                                              page <= endPage)
                                          ) {
                                            return (
                                              <li
                                                className="page-item custompaginationli"
                                                key={key}
                                              >
                                                <a
                                                  className={
                                                    currentPage === page
                                                      ? "page-link active customcountpagination"
                                                      : "page-link customcountpagination"
                                                  }
                                                  onClick={() =>
                                                    handlePageClick(page)
                                                  }
                                                  disabled={
                                                    currentPage === page
                                                  }
                                                  style={{ cursor: "pointer" }}
                                                >
                                                  {page}
                                                </a>
                                              </li>
                                            );
                                          } else if (
                                            page === startPage - 1 ||
                                            page === endPage + 1
                                          ) {
                                            return (
                                              <li
                                                className="page-item custompaginationli"
                                                key={key}
                                              >
                                                <a
                                                  className="page-link customcountpagination"
                                                  style={{ cursor: "pointer" }}
                                                >
                                                  ...
                                                </a>
                                              </li>
                                            );
                                          } else {
                                            return null;
                                          }
                                        })}

                                        <li
                                          className="page-item custompaginationli paginationrightbtnbox"
                                          style={{ cursor: "pointer" }}
                                        >
                                          <button
                                            type="button"
                                            className="page-link"
                                            href="#"
                                            onClick={() =>
                                              handlePageClick(currentPage + 1)
                                            }
                                            disabled={
                                              currentPage === totalPages
                                            }
                                          >
                                            <img src="assets/img/next.webp" />
                                          </button>
                                        </li>
                                      </ul>
                                    </nav>
                                  </div>
                                </div>
                              </div>
                            ) : null}
                            {/* {allProduct.length > 0 ? (
                              <div className="row">
                                <div className="col-md-12">
                                  <div className="custombottompaginationbox">
                                    <nav aria-label="Page navigation example">
                                      <ul className="pagination">
                                        <li
                                          className="page-item custompaginationli  paginationleftbtnbox"
                                          style={{ cursor: "pointer" }}
                                        >
                                          <button
                                            type="button"
                                            className="page-link"
                                            onClick={() =>
                                              handlePageClick(currentPage - 1)
                                            }
                                            disabled={currentPage === 1}
                                          >
                                            <img src="assets/img/previous.webp" />
                                          </button>
                                        </li>

                                        {Array.from(
                                          { length: totalPages },
                                          (_, index) => index + 1
                                        ).map((page, key) => (
                                          <li
                                            className="page-item custompaginationli"
                                            key={key}
                                          >
                                            <a
                                              key={key}
                                              className={
                                                currentPage === page
                                                  ? "page-link active customcountpagination"
                                                  : "page-link customcountpagination"
                                              }
                                              onClick={() =>
                                                handlePageClick(page)
                                              }
                                              disabled={currentPage === page}
                                              style={{ cursor: "pointer" }}
                                            >
                                              {page}
                                            </a>
                                          </li>
                                        ))}

                                        <li
                                          className="page-item custompaginationli paginationrightbtnbox"
                                          style={{ cursor: "pointer" }}
                                        >
                                          <button
                                            type="button"
                                            className="page-link"
                                            href="#"
                                            onClick={() =>
                                              handlePageClick(currentPage + 1)
                                            }
                                            disabled={
                                              currentPage === totalPages
                                            }
                                          >
                                            <img src="assets/img/next.webp" />
                                          </button>
                                        </li>
                                      </ul>
                                    </nav>
                                  </div>
                                </div>
                              </div>
                            ) : null} */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <div
                  // className={`${isSidebarVisible ? "sidebar-mini" : ""} ${
                  //   !isFreeTrialVisible ? "no-free-trial" : ""
                  // }`}
                  style={sidebarStyleone}
                >
                  <nav
                    className="navbar navbar-expand-lg main-navbar sticky"
                    style={navbarStyle}
                  >
                    <div className="sidebar-arrow">
                      <a
                        href="#"
                        data-toggle="sidebar"
                        onClick={(e) => {
                          toggleSidebar();

                          e.preventDefault();
                        }}
                      >
                        <img src="assets/img/sidebar-toggle.webp" alt="" />
                      </a>
                    </div>
                    <div className="header-title mr-auto">
                      <h4>
                        Products{" "}
                        <span className="count">
                          {allProduct?.length} Products
                        </span>
                      </h4>
                    </div>
                    <SideUl />
                  </nav>
                  <MainSidebar />
                  <div
                    className="main-content tableback"
                    style={mainContentStyle}
                  >
                    <div className="common_filter_sec">
                      <div className="filter_col">
                        <div className="filtermanagecustmdiv filter_tble_btn">
                          <div className="dropdown customtabledropdownbox">
                            <button
                              type="button"
                              className="btn btn-secondary dropdown-toggle customdropdowntablebtnsetting"
                              href="#"
                              role="button"
                              id="dropdownMenuLink"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              {selectedFilter === "default"
                                ? "Filter Table"
                                : selectedFilter}
                            </button>
                            <img
                              src="assets/img/filter-icon.webp"
                              alt=""
                              className="filtericonimgboxsetting"
                            />
                            <ul
                              className="dropdown-menu customdropdownulboxsetting"
                              aria-labelledby="dropdownMenuLink"
                            >
                              <li>
                                <a
                                  className="dropdown-item"
                                  href="#"
                                  onClick={handleFilterChange1}
                                >
                                  Filter Table
                                </a>
                              </li>
                              <li>
                                <a
                                  className="dropdown-item"
                                  href="#"
                                  onClick={handleFilterChange1}
                                >
                                  Filter By Date
                                </a>
                              </li>
                              <li>
                                <a
                                  className="dropdown-item"
                                  href="#"
                                  onClick={handleFilterChange1}
                                >
                                  Filter By Price
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="manage_btn">
                          <a href="#">Manage</a>
                        </div>
                        <div className="search_field">
                          <div className="search_icon">
                            <img src="assets/img/search-icon.webp" alt="" />
                          </div>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Find Products"
                            value={searchTerm}
                            onChange={handleSearchTermChange}
                          />
                        </div>
                        <div className="imp_exp_btn export_btn">
                          <CSVLink
                            data={data}
                            headers={headers}
                            filename={"table-data.csv"}
                          >
                            <button type="button">
                              <img src="assets/img/export-icon.webp" alt="" />{" "}
                              Export
                            </button>
                          </CSVLink>
                          {/* <button type="button">
                  <img src="assets/img/export-icon.webp" alt="" /> Export
                </button> */}
                        </div>
                        <div className="imp_exp_btn import_btn">
                          <button type="button" onClick={handleImport}>
                            <img src="assets/img/import-icon.webp" alt="" />{" "}
                            Import
                          </button>
                          <input
                            type="file"
                            id="fileInput"
                            style={{ display: "none" }}
                            onChange={handleFileChange}
                          />
                        </div>
                        {/* <div className="imp_exp_btn import_btn">
                          <button type="button">
                            <img src="assets/img/import-icon.webp" alt="" />{" "}
                            Import
                          </button>
                        </div> */}

                        <div
                          className="add_customer_btn"
                          // onClick={() => setShowModal(true)}
                        >
                          <a
                            href="#"
                            // data-toggle="modal"
                            // data-target="#createproductModal"
                            data-toggle="modal"
                            data-target="#newproductModal"
                            onClick={() => setShowModal3(true)}
                          >
                            New Products <i className="fa fa-plus" />
                          </a>
                        </div>
                      </div>
                      <div className="filter_select_option">
                        <div className="ft_select_option category_option option_15 customdropdownulboxcate">
                          <div className="dropdown customtabledropdownbox1">
                            <button
                              type="button"
                              className="btn btn-secondary dropdown-toggle customdropdowntablebtnsetting textaligncustmtext"
                              role="button"
                              id="dropdownMenuLink"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              {selectedCategory1 === "default"
                                ? "Select a category"
                                : selectedCategory1}
                            </button>
                            <ul
                              className="dropdown-menu customdropdownulboxsetting ulcustmrepairdropdowncss"
                              aria-labelledby="dropdownMenuLink"
                            >
                              <li>
                                <a
                                  className="dropdown-item"
                                  href="#"
                                  onClick={() =>
                                    handleCategoryChange("default")
                                  }
                                >
                                  Select a category
                                </a>
                              </li>
                              {categories.map((category) => (
                                <li key={category.id}>
                                  <a
                                    className="dropdown-item"
                                    href="#"
                                    onClick={() =>
                                      handleCategoryChange(category?.name)
                                    }
                                  >
                                    {category?.name}
                                  </a>
                                </li>
                              ))}
                            </ul>
                            <img src="assets/img/select-arrow.webp" alt="" />
                          </div>
                        </div>
                        <div className="ft_select_option brand_option option_15 customdropdownulboxcate">
                          <div className="dropdown customtabledropdownbox1">
                            <button
                              type="button"
                              className="btn btn-secondary dropdown-toggle customdropdowntablebtnsetting textaligncustmtext"
                              role="button"
                              id="dropdownMenuLink"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              {selectedCategory2 === "default"
                                ? "Select a brand"
                                : selectedCategory2}
                            </button>
                            <ul
                              className="dropdown-menu customdropdownulboxsetting ulcustmrepairdropdowncss"
                              aria-labelledby="dropdownMenuLink"
                            >
                              <li>
                                <a
                                  className="dropdown-item"
                                  href="#"
                                  onClick={() => handleBrandChange("default")}
                                >
                                  Select a brand
                                </a>
                              </li>
                              {brands.map((item) => (
                                <li key={item.id}>
                                  <a
                                    className="dropdown-item"
                                    href="#"
                                    onClick={() => handleBrandChange(item.name)}
                                  >
                                    {item?.name}
                                  </a>
                                </li>
                              ))}
                            </ul>
                            <img src="assets/img/select-arrow.webp" alt="" />
                          </div>
                        </div>
                        <div className="ft_select_option supplier_option option_20 customdropdownulboxsuppl">
                          <div className="dropdown customtabledropdownboxsuppl">
                            <button
                              type="button"
                              className="btn btn-secondary dropdown-toggle customdropdowntablebtnsetting textaligncustmtext"
                              role="button"
                              id="dropdownMenuLink"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              {selectedCategory3 === "default"
                                ? "Select a Manufacturer"
                                : selectedCategory3}
                            </button>
                            <ul
                              className="dropdown-menu customdropdownulboxsetting ulcustmrepairdropdowncss"
                              aria-labelledby="dropdownMenuLink"
                            >
                              <li>
                                <a
                                  className="dropdown-item"
                                  href="#"
                                  onClick={() =>
                                    handleSupplierChange("default")
                                  }
                                >
                                  Select a Manufacturer
                                </a>
                              </li>
                              {suppliers.map((item) => (
                                <li key={item.id}>
                                  <a
                                    className="dropdown-item"
                                    href="#"
                                    onClick={() =>
                                      handleSupplierChange(item?.name)
                                    }
                                  >
                                    {item?.name}
                                  </a>
                                </li>
                              ))}
                            </ul>
                            <img src="assets/img/select-arrow.webp" alt="" />
                          </div>
                        </div>
                        <div className="search_field">
                          <div className="search_icon">
                            <img src="assets/img/search-icon.webp" alt="" />
                          </div>
                          <input
                            type="text"
                            className="form-control purchasecustminpt"
                            placeholder="Search Purchase Order number"
                          />
                        </div>
                        <div className="ft_select_option status_option option_20 customdropdownulboxsuppl">
                          <div className="dropdown customtabledropdownboxsuppl">
                            <button
                              type="button"
                              className="btn btn-secondary dropdown-toggle customdropdowntablebtnsetting textaligncustmtext"
                              role="button"
                              id="dropdownMenuLink"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              {getStatusName(selectedCategory4)}
                            </button>
                            <ul
                              className="dropdown-menu customdropdownulboxsetting ulcustmrepairdropdowncss"
                              aria-labelledby="dropdownMenuLink"
                            >
                              <li>
                                <a
                                  className="dropdown-item"
                                  href="#"
                                  onClick={() => handleStatusChange("default")}
                                >
                                  Select a status
                                </a>
                              </li>

                              <li>
                                <a
                                  className="dropdown-item"
                                  href="#"
                                  onClick={() => handleStatusChange(1)}
                                >
                                  Active
                                </a>
                              </li>
                              <li>
                                <a
                                  className="dropdown-item"
                                  href="#"
                                  onClick={() => handleStatusChange(0)}
                                >
                                  Inactive
                                </a>
                              </li>
                              <li>
                                <a
                                  className="dropdown-item"
                                  href="#"
                                  onClick={() => handleStatusChange("draft")}
                                >
                                  Draft
                                </a>
                              </li>
                            </ul>
                            <img src="assets/img/select-arrow.webp" alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="clearfix" />
                    <div className="row">
                      <div className="col-lg-12 col-md-12">
                        <div className="customertablemain">
                          <table className="table table-striped  customdatatable">
                            <thead className="customtheadpadding">
                              <tr>
                                <th scope="col" className="checkbtnthsetting">
                                  <input
                                    className="form-check-input customcheckboxbtnsetting"
                                    type="checkbox"
                                    value=""
                                    id="flexCheckDefault"
                                    onChange={handleSelectAll}
                                    checked={selectAll}
                                  />
                                </th>

                                <th
                                  scope="col"
                                  className="customnameth  customnamethactive"
                                >
                                  Product{" "}
                                  {sortState === 0 ? (
                                    <span
                                      className="upperarrow"
                                      onClick={() => {
                                        sortProduct();
                                        setSortState(0);
                                      }}
                                    >
                                      <i
                                        className="fa fa-long-arrow-up active"
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  ) : (
                                    <span
                                      className="upperarrow"
                                      onClick={() => {
                                        sortProduct();
                                        setSortState(0);
                                      }}
                                    >
                                      <i
                                        className="fa fa-long-arrow-up"
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  )}
                                  {sortState === 1 ? (
                                    <span
                                      className="lowerarrow"
                                      onClick={() => {
                                        sortProduct1();
                                        setSortState(1);
                                      }}
                                    >
                                      <i
                                        className="fa fa-long-arrow-down active "
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  ) : (
                                    <span
                                      className="lowerarrow"
                                      onClick={() => {
                                        sortProduct1();
                                        setSortState(1);
                                      }}
                                    >
                                      <i
                                        className="fa fa-long-arrow-down "
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  )}
                                </th>
                                <th scope="col" className="customnameth">
                                  Category{" "}
                                  {sortState === 2 ? (
                                    <span
                                      className="upperarrow"
                                      onClick={() => {
                                        sortCategory();
                                        setSortState(2);
                                      }}
                                    >
                                      <i
                                        className="fa fa-long-arrow-up active"
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  ) : (
                                    <span
                                      className="upperarrow"
                                      onClick={() => {
                                        sortCategory();
                                        setSortState(2);
                                      }}
                                    >
                                      <i
                                        className="fa fa-long-arrow-up"
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  )}
                                  {sortState === 3 ? (
                                    <span
                                      className="lowerarrow"
                                      onClick={() => {
                                        sortCategory1();
                                        setSortState(3);
                                      }}
                                    >
                                      <i
                                        className="fa fa-long-arrow-down active "
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  ) : (
                                    <span
                                      className="lowerarrow"
                                      onClick={() => {
                                        sortCategory1();
                                        setSortState(3);
                                      }}
                                    >
                                      <i
                                        className="fa fa-long-arrow-down "
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  )}
                                </th>
                                <th scope="col" className="customnameth">
                                  Brand{" "}
                                  {sortState === 4 ? (
                                    <span
                                      className="upperarrow"
                                      onClick={() => {
                                        sortBrand();
                                        setSortState(4);
                                      }}
                                    >
                                      <i
                                        className="fa fa-long-arrow-up active"
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  ) : (
                                    <span
                                      className="upperarrow"
                                      onClick={() => {
                                        sortBrand();
                                        setSortState(4);
                                      }}
                                    >
                                      <i
                                        className="fa fa-long-arrow-up"
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  )}
                                  {sortState === 5 ? (
                                    <span
                                      className="lowerarrow"
                                      onClick={() => {
                                        sortBrand1();
                                        setSortState(5);
                                      }}
                                    >
                                      <i
                                        className="fa fa-long-arrow-down active "
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  ) : (
                                    <span
                                      className="lowerarrow"
                                      onClick={() => {
                                        sortBrand1();
                                        setSortState(5);
                                      }}
                                    >
                                      <i
                                        className="fa fa-long-arrow-down "
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  )}
                                </th>
                                <th scope="col" className="customnameth">
                                  Supplier
                                  {sortState === 6 ? (
                                    <span
                                      className="upperarrow"
                                      onClick={() => {
                                        sortSupplier();
                                        setSortState(6);
                                      }}
                                    >
                                      <i
                                        className="fa fa-long-arrow-up active"
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  ) : (
                                    <span
                                      className="upperarrow"
                                      onClick={() => {
                                        sortSupplier();
                                        setSortState(6);
                                      }}
                                    >
                                      <i
                                        className="fa fa-long-arrow-up"
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  )}
                                  {sortState === 7 ? (
                                    <span
                                      className="lowerarrow"
                                      onClick={() => {
                                        sortSupplier1();
                                        setSortState(7);
                                      }}
                                    >
                                      <i
                                        className="fa fa-long-arrow-down active "
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  ) : (
                                    <span
                                      className="lowerarrow"
                                      onClick={() => {
                                        sortSupplier1();
                                        setSortState(7);
                                      }}
                                    >
                                      <i
                                        className="fa fa-long-arrow-down "
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  )}
                                </th>
                                <th scope="col" className="customnameth">
                                  SKU/BARCODE{" "}
                                  {sortState === 8 ? (
                                    <span
                                      className="upperarrow"
                                      onClick={() => {
                                        sortSKUCode();
                                        setSortState(8);
                                      }}
                                    >
                                      <i
                                        className="fa fa-long-arrow-up active"
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  ) : (
                                    <span
                                      className="upperarrow"
                                      onClick={() => {
                                        sortSKUCode();
                                        setSortState(8);
                                      }}
                                    >
                                      <i
                                        className="fa fa-long-arrow-up"
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  )}
                                  {sortState === 9 ? (
                                    <span
                                      className="lowerarrow"
                                      onClick={() => {
                                        sortSKUCode1();
                                        setSortState(9);
                                      }}
                                    >
                                      <i
                                        className="fa fa-long-arrow-down active "
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  ) : (
                                    <span
                                      className="lowerarrow"
                                      onClick={() => {
                                        sortSKUCode1();
                                        setSortState(9);
                                      }}
                                    >
                                      <i
                                        className="fa fa-long-arrow-down "
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  )}
                                </th>
                                <th scope="col" className="customnameth">
                                  Inventory{" "}
                                  {sortState === 10 ? (
                                    <span
                                      className="upperarrow"
                                      onClick={() => {
                                        setSortState(10);
                                      }}
                                    >
                                      <i
                                        className="fa fa-long-arrow-up active"
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  ) : (
                                    <span
                                      className="upperarrow"
                                      onClick={() => {
                                        setSortState(10);
                                      }}
                                    >
                                      <i
                                        className="fa fa-long-arrow-up"
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  )}
                                  {sortState === 11 ? (
                                    <span
                                      className="lowerarrow"
                                      onClick={() => {
                                        setSortState(11);
                                      }}
                                    >
                                      <i
                                        className="fa fa-long-arrow-down active "
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  ) : (
                                    <span
                                      className="lowerarrow"
                                      onClick={() => {
                                        setSortState(11);
                                      }}
                                    >
                                      <i
                                        className="fa fa-long-arrow-down "
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  )}
                                </th>
                                <th scope="col" className="customnameth">
                                  Unit Cost{" "}
                                  {sortState === 12 ? (
                                    <span
                                      className="upperarrow"
                                      onClick={() => {
                                        sortUnitCost();
                                        setSortState(12);
                                      }}
                                    >
                                      <i
                                        className="fa fa-long-arrow-up active"
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  ) : (
                                    <span
                                      className="upperarrow"
                                      onClick={() => {
                                        sortUnitCost();
                                        setSortState(12);
                                      }}
                                    >
                                      <i
                                        className="fa fa-long-arrow-up"
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  )}
                                  {sortState === 13 ? (
                                    <span
                                      className="lowerarrow"
                                      onClick={() => {
                                        sortUnitCost1();
                                        setSortState(13);
                                      }}
                                    >
                                      <i
                                        className="fa fa-long-arrow-down active "
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  ) : (
                                    <span
                                      className="lowerarrow"
                                      onClick={() => {
                                        sortUnitCost1();
                                        setSortState(13);
                                      }}
                                    >
                                      <i
                                        className="fa fa-long-arrow-down "
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  )}
                                </th>
                                <th scope="col" className="customnameth">
                                  Retail price{" "}
                                  {sortState === 14 ? (
                                    <span
                                      className="upperarrow"
                                      onClick={() => {
                                        sortPrice();
                                        setSortState(14);
                                      }}
                                    >
                                      <i
                                        className="fa fa-long-arrow-up active"
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  ) : (
                                    <span
                                      className="upperarrow"
                                      onClick={() => {
                                        sortPrice();
                                        setSortState(14);
                                      }}
                                    >
                                      <i
                                        className="fa fa-long-arrow-up"
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  )}
                                  {sortState === 15 ? (
                                    <span
                                      className="lowerarrow"
                                      onClick={() => {
                                        sortPrice1();
                                        setSortState(15);
                                      }}
                                    >
                                      <i
                                        className="fa fa-long-arrow-down active "
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  ) : (
                                    <span
                                      className="lowerarrow"
                                      onClick={() => {
                                        sortPrice1();
                                        setSortState(15);
                                      }}
                                    >
                                      <i
                                        className="fa fa-long-arrow-down "
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  )}
                                </th>
                                <th scope="col" className="customnameth">
                                  Status{" "}
                                  {sortState === 16 ? (
                                    <span
                                      className="upperarrow"
                                      onClick={() => {
                                        setSortState(16);
                                      }}
                                    >
                                      <i
                                        className="fa fa-long-arrow-up active"
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  ) : (
                                    <span
                                      className="upperarrow"
                                      onClick={() => {
                                        setSortState(16);
                                      }}
                                    >
                                      <i
                                        className="fa fa-long-arrow-up"
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  )}
                                  {sortState === 17 ? (
                                    <span
                                      className="lowerarrow"
                                      onClick={() => {
                                        setSortState(17);
                                      }}
                                    >
                                      <i
                                        className="fa fa-long-arrow-down active "
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  ) : (
                                    <span
                                      className="lowerarrow"
                                      onClick={() => {
                                        setSortState(17);
                                      }}
                                    >
                                      <i
                                        className="fa fa-long-arrow-down "
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  )}
                                </th>
                                <th scope="col" className="customnameth">
                                  Action
                                </th>
                              </tr>
                            </thead>
                            <tbody className="customtheadpadding">
                              {currentData.length ? (
                                <>
                                  {currentData?.map((item, index) => (
                                    <>
                                      <tr
                                        key={index.id}
                                        className={
                                          index % 2 === 0 ? "" : "customeven"
                                        }
                                      >
                                        <td
                                          key={index.id}
                                          className={
                                            index % 2 === 0 ? "" : "bgcolor"
                                          }
                                        >
                                          <input
                                            key={index.id}
                                            className="form-check-input customcheckboxbtnsetting"
                                            type="checkbox"
                                            value=""
                                            id={`flexCheckDefault${index}`}
                                            onChange={() =>
                                              handleSelectRow(item?.id)
                                            }
                                            checked={selectedItems.includes(
                                              item?.id
                                            )}
                                          />
                                        </td>
                                        <td
                                          key={index.id}
                                          className={
                                            index % 2 === 0
                                              ? ""
                                              : " prod_img bgcolor"
                                          }
                                        >
                                          {item?.image ? (
                                            <img
                                              key={index.id}
                                              className="imglistcstm"
                                              src={item?.image}
                                              alt=""
                                            />
                                          ) : (
                                            <img
                                              src="assets/img/defaultimg.webp"
                                              className="imglistcstm"
                                              style={{ marginRight: "10px" }}
                                              alt=""
                                            />
                                          )}
                                          <span>{item?.title}</span>
                                        </td>
                                        <td
                                          key={index.id}
                                          className={
                                            index % 2 === 0 ? "" : "bgcolor"
                                          }
                                        >
                                          {item?.itemCategory?.name}
                                        </td>
                                        <td
                                          key={index.id}
                                          className={
                                            index % 2 === 0 ? "" : "bgcolor"
                                          }
                                        >
                                          {item?.brand?.name}
                                        </td>
                                        <td
                                          key={index.id}
                                          className={
                                            index % 2 === 0 ? "" : "bgcolor"
                                          }
                                        >
                                          {item?.supplier?.name}
                                        </td>
                                        <td
                                          key={index.id}
                                          className={
                                            index % 2 === 0 ? "" : "bgcolor"
                                          }
                                        >
                                          {item?.skuCode}
                                        </td>
                                        <td
                                          key={index.id}
                                          className={
                                            index % 2 === 0 ? "" : "bgcolor"
                                          }
                                        >
                                          {item?.inventory}
                                        </td>
                                        <td
                                          key={index.id}
                                          className={
                                            index % 2 === 0 ? "" : "bgcolor"
                                          }
                                        >
                                          ${item?.unitCost?.toFixed(2)}
                                        </td>
                                        <td
                                          key={index.id}
                                          className={
                                            index % 2 === 0 ? "" : "bgcolor"
                                          }
                                        >
                                          ${item?.sellingPrice.toFixed(2)}
                                        </td>

                                        <td
                                          key={index.id}
                                          className={
                                            index % 2 === 0
                                              ? ""
                                              : "status_td st_inactive bgcolor"
                                          }
                                          //className="status_td st_inactive"
                                        >
                                          {item?.draft === 1 ? (
                                            <span
                                              className="spanactiveinactivecstm2"
                                              key={index.id}
                                            >
                                              Draft
                                            </span>
                                          ) : item?.activeInactive === 1 ? (
                                            <span
                                              className="spanactiveinactivecstm1"
                                              key={index.id}
                                            >
                                              Active
                                            </span>
                                          ) : (
                                            <span
                                              className="spanactiveinactivecstm"
                                              key={index.id}
                                            >
                                              Inactive
                                            </span>
                                          )}
                                        </td>
                                        <td
                                          key={index.id}
                                          className={
                                            index % 2 === 0 ? "" : "bgcolor"
                                          }
                                        >
                                          {item?.productCategoryId === 2 ? (
                                            <Link
                                              key={index.id}
                                              to="/editdevice"
                                              state={{ data: item }}
                                              className="edit_action_btn"
                                            >
                                              <img
                                                src="assets/img/action-edit-icon.webp"
                                                alt=""
                                              />
                                            </Link>
                                          ) : (
                                            <Link
                                              key={index.id}
                                              to="/editproduct"
                                              state={{ data: item }}
                                              className="edit_action_btn"
                                            >
                                              <img
                                                src="assets/img/action-edit-icon.webp"
                                                alt=""
                                              />
                                            </Link>
                                          )}
                                          {/* <Link
                                            key={index.id}
                                            to="/editproduct"
                                            state={{ data: item }}
                                            className="edit_action_btn"
                                          >
                                            <img
                                              src="assets/img/action-edit-icon.webp"
                                              alt=""
                                            />
                                          </Link> */}

                                          <i
                                            className="fa-solid fa-trash deletecustmtb"
                                            onClick={() =>
                                              HandleDelete(item?.id)
                                            }
                                          ></i>
                                        </td>
                                      </tr>
                                    </>
                                  ))}
                                </>
                              ) : (
                                <>
                                  <tr id="noDataMessage">
                                    <td colSpan="10" className="nodataav">
                                      No data available
                                    </td>
                                  </tr>
                                </>
                              )}
                            </tbody>
                          </table>
                          {allProduct.length > 0 ? (
                            <div className="row">
                              <div className="col-md-12">
                                <div className="custombottompaginationbox">
                                  <nav aria-label="Page navigation example">
                                    <ul className="pagination">
                                      <li
                                        className="page-item custompaginationli  paginationleftbtnbox"
                                        style={{ cursor: "pointer" }}
                                      >
                                        <button
                                          type="button"
                                          className="page-link"
                                          onClick={() =>
                                            handlePageClick(currentPage - 1)
                                          }
                                          disabled={currentPage === 1}
                                        >
                                          <img src="assets/img/previous.webp" />
                                        </button>
                                      </li>

                                      {Array.from(
                                        { length: totalPages },
                                        (_, index) => index + 1
                                      ).map((page, key) => (
                                        <li
                                          className="page-item custompaginationli"
                                          key={key}
                                        >
                                          <a
                                            key={key}
                                            className={
                                              currentPage === page
                                                ? "page-link active customcountpagination"
                                                : "page-link customcountpagination"
                                            }
                                            onClick={() =>
                                              handlePageClick(page)
                                            }
                                            disabled={currentPage === page}
                                            style={{ cursor: "pointer" }}
                                          >
                                            {page}
                                          </a>
                                        </li>
                                      ))}

                                      <li
                                        className="page-item custompaginationli paginationrightbtnbox"
                                        style={{ cursor: "pointer" }}
                                      >
                                        <button
                                          type="button"
                                          className="page-link"
                                          href="#"
                                          onClick={() =>
                                            handlePageClick(currentPage + 1)
                                          }
                                          disabled={currentPage === totalPages}
                                        >
                                          <img src="assets/img/next.webp" />
                                        </button>
                                      </li>
                                    </ul>
                                  </nav>
                                </div>
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          ) : (
            <div
              // className={`${isSidebarVisible ? "sidebar-mini" : ""} ${
              //   !isFreeTrialVisible ? "no-free-trial" : ""
              // }`}
              style={sidebarStyleone}
            >
              <nav
                className="navbar navbar-expand-lg main-navbar sticky"
                style={navbarStyle}
              >
                <div className="sidebar-arrow">
                  <a
                    href="#"
                    data-toggle="sidebar"
                    onClick={(e) => {
                      toggleSidebar();

                      e.preventDefault();
                    }}
                  >
                    <img src="assets/img/sidebar-toggle.webp" alt="" />
                  </a>
                </div>
                <div className="header-title mr-auto">
                  <h4>
                    Products{" "}
                    <span className="count">{allProduct?.length} Products</span>
                  </h4>
                </div>
                <SideUl />
              </nav>
              <MainSidebar />
              <div className="main-content tableback" style={mainContentStyle}>
                <div className="common_filter_sec">
                  <div className="filter_col">
                    <div className="filtermanagecustmdiv filter_tble_btn">
                      <div className="dropdown customtabledropdownbox">
                        <button
                          type="button"
                          className="btn btn-secondary dropdown-toggle customdropdowntablebtnsetting"
                          href="#"
                          role="button"
                          id="dropdownMenuLink"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          {selectedFilter === "default"
                            ? "Filter Table"
                            : selectedFilter}
                        </button>
                        <img
                          src="assets/img/filter-icon.webp"
                          alt=""
                          className="filtericonimgboxsetting"
                        />
                        <ul
                          className="dropdown-menu customdropdownulboxsetting"
                          aria-labelledby="dropdownMenuLink"
                        >
                          <li>
                            <a
                              className="dropdown-item"
                              href="#"
                              onClick={handleFilterChange1}
                            >
                              Filter Table
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              href="#"
                              onClick={handleFilterChange1}
                            >
                              Filter By Date
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              href="#"
                              onClick={handleFilterChange1}
                            >
                              Filter By Price
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="manage_btn">
                      <a href="#">Manage</a>
                    </div>
                    <div className="search_field">
                      <div className="search_icon">
                        <img src="assets/img/search-icon.webp" alt="" />
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Find Products"
                        value={searchTerm}
                        onChange={handleSearchTermChange}
                      />
                    </div>
                    <div className="imp_exp_btn export_btn">
                      <CSVLink
                        data={data}
                        headers={headers}
                        filename={"table-data.csv"}
                      >
                        <button type="button">
                          <img src="assets/img/export-icon.webp" alt="" />{" "}
                          Export
                        </button>
                      </CSVLink>
                      {/* <button type="button">
                  <img src="assets/img/export-icon.webp" alt="" /> Export
                </button> */}
                    </div>
                    <div className="imp_exp_btn import_btn">
                      <button type="button" onClick={handleImport}>
                        <img src="assets/img/import-icon.webp" alt="" /> Import
                      </button>
                      <input
                        type="file"
                        id="fileInput"
                        style={{ display: "none" }}
                        onChange={handleFileChange}
                      />
                    </div>

                    <div
                      className="add_customer_btn"
                      // onClick={() => setShowModal(true)}
                    >
                      <a
                        href="#"
                        // data-toggle="modal"
                        // data-target="#createproductModal"
                        data-toggle="modal"
                        data-target="#newproductModal"
                        onClick={() => setShowModal3(true)}
                      >
                        New Products <i className="fa fa-plus" />
                      </a>
                    </div>
                  </div>
                  <div className="filter_select_option">
                    <div className="ft_select_option category_option option_15 customdropdownulboxcate">
                      <div className="dropdown customtabledropdownbox1">
                        <button
                          type="button"
                          className="btn btn-secondary dropdown-toggle customdropdowntablebtnsetting textaligncustmtext"
                          role="button"
                          id="dropdownMenuLink"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          {selectedCategory1 === "default"
                            ? "Select a category"
                            : selectedCategory1}
                        </button>
                        <ul
                          className="dropdown-menu customdropdownulboxsetting ulcustmrepairdropdowncss"
                          aria-labelledby="dropdownMenuLink"
                        >
                          <li>
                            <a
                              className="dropdown-item"
                              href="#"
                              onClick={() => handleCategoryChange("default")}
                            >
                              Select a category
                            </a>
                          </li>
                          {categories.map((category) => (
                            <li key={category.id}>
                              <a
                                className="dropdown-item"
                                href="#"
                                onClick={() =>
                                  handleCategoryChange(category?.name)
                                }
                              >
                                {category?.name}
                              </a>
                            </li>
                          ))}
                        </ul>
                        <img src="assets/img/select-arrow.webp" alt="" />
                      </div>
                    </div>
                    <div className="ft_select_option brand_option option_15 customdropdownulboxcate">
                      <div className="dropdown customtabledropdownbox1">
                        <button
                          type="button"
                          className="btn btn-secondary dropdown-toggle customdropdowntablebtnsetting textaligncustmtext"
                          role="button"
                          id="dropdownMenuLink"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          {selectedCategory2 === "default"
                            ? "Select a brand"
                            : selectedCategory2}
                        </button>
                        <ul
                          className="dropdown-menu customdropdownulboxsetting ulcustmrepairdropdowncss"
                          aria-labelledby="dropdownMenuLink"
                        >
                          <li>
                            <a
                              className="dropdown-item"
                              href="#"
                              onClick={() => handleBrandChange("default")}
                            >
                              Select a brand
                            </a>
                          </li>
                          {brands.map((item) => (
                            <li key={item.id}>
                              <a
                                className="dropdown-item"
                                href="#"
                                onClick={() => handleBrandChange(item.name)}
                              >
                                {item?.name}
                              </a>
                            </li>
                          ))}
                        </ul>
                        <img src="assets/img/select-arrow.webp" alt="" />
                      </div>
                    </div>
                    <div className="ft_select_option supplier_option option_20 customdropdownulboxsuppl">
                      <div className="dropdown customtabledropdownboxsuppl">
                        <button
                          type="button"
                          className="btn btn-secondary dropdown-toggle customdropdowntablebtnsetting textaligncustmtext"
                          role="button"
                          id="dropdownMenuLink"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          {selectedCategory3 === "default"
                            ? "Select a Manufacturer"
                            : selectedCategory3}
                        </button>
                        <ul
                          className="dropdown-menu customdropdownulboxsetting ulcustmrepairdropdowncss"
                          aria-labelledby="dropdownMenuLink"
                        >
                          <li>
                            <a
                              className="dropdown-item"
                              href="#"
                              onClick={() => handleSupplierChange("default")}
                            >
                              Select a Manufacturer
                            </a>
                          </li>
                          {suppliers.map((item) => (
                            <li key={item.id}>
                              <a
                                className="dropdown-item"
                                href="#"
                                onClick={() => handleSupplierChange(item?.name)}
                              >
                                {item?.name}
                              </a>
                            </li>
                          ))}
                        </ul>
                        <img src="assets/img/select-arrow.webp" alt="" />
                      </div>
                    </div>
                    <div className="search_field">
                      <div className="search_icon">
                        <img src="assets/img/search-icon.webp" alt="" />
                      </div>
                      <input
                        type="text"
                        className="form-control purchasecustminpt"
                        placeholder="Search Purchase Order number"
                      />
                    </div>
                    <div className="ft_select_option status_option option_20 customdropdownulboxsuppl">
                      <div className="dropdown customtabledropdownboxsuppl">
                        <button
                          type="button"
                          className="btn btn-secondary dropdown-toggle customdropdowntablebtnsetting textaligncustmtext"
                          role="button"
                          id="dropdownMenuLink"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          {getStatusName(selectedCategory4)}
                        </button>
                        <ul
                          className="dropdown-menu customdropdownulboxsetting ulcustmrepairdropdowncss"
                          aria-labelledby="dropdownMenuLink"
                        >
                          <li>
                            <a
                              className="dropdown-item"
                              href="#"
                              onClick={() => handleStatusChange("default")}
                            >
                              Select a status
                            </a>
                          </li>

                          <li>
                            <a
                              className="dropdown-item"
                              href="#"
                              onClick={() => handleStatusChange(1)}
                            >
                              Active
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              href="#"
                              onClick={() => handleStatusChange(0)}
                            >
                              Inactive
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              href="#"
                              onClick={() => handleStatusChange("draft")}
                            >
                              Draft
                            </a>
                          </li>
                        </ul>
                        <img src="assets/img/select-arrow.webp" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="clearfix" />
                <div className="row">
                  <div className="col-lg-12 col-md-12">
                    <div className="customertablemain">
                      <table className="table table-striped  customdatatable">
                        <thead className="customtheadpadding">
                          <tr>
                            <th scope="col" className="checkbtnthsetting">
                              <input
                                className="form-check-input customcheckboxbtnsetting"
                                type="checkbox"
                                value=""
                                id="flexCheckDefault"
                                onChange={handleSelectAll}
                                checked={selectAll}
                              />
                            </th>

                            <th
                              scope="col"
                              className="customnameth  customnamethactive"
                            >
                              Product{" "}
                              {sortState === 0 ? (
                                <span
                                  className="upperarrow"
                                  onClick={() => {
                                    sortProduct();
                                    setSortState(0);
                                  }}
                                >
                                  <i
                                    className="fa fa-long-arrow-up active"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              ) : (
                                <span
                                  className="upperarrow"
                                  onClick={() => {
                                    sortProduct();
                                    setSortState(0);
                                  }}
                                >
                                  <i
                                    className="fa fa-long-arrow-up"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              )}
                              {sortState === 1 ? (
                                <span
                                  className="lowerarrow"
                                  onClick={() => {
                                    sortProduct1();
                                    setSortState(1);
                                  }}
                                >
                                  <i
                                    className="fa fa-long-arrow-down active "
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              ) : (
                                <span
                                  className="lowerarrow"
                                  onClick={() => {
                                    sortProduct1();
                                    setSortState(1);
                                  }}
                                >
                                  <i
                                    className="fa fa-long-arrow-down "
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              )}
                            </th>
                            <th scope="col" className="customnameth">
                              Category{" "}
                              {sortState === 2 ? (
                                <span
                                  className="upperarrow"
                                  onClick={() => {
                                    sortCategory();
                                    setSortState(2);
                                  }}
                                >
                                  <i
                                    className="fa fa-long-arrow-up active"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              ) : (
                                <span
                                  className="upperarrow"
                                  onClick={() => {
                                    sortCategory();
                                    setSortState(2);
                                  }}
                                >
                                  <i
                                    className="fa fa-long-arrow-up"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              )}
                              {sortState === 3 ? (
                                <span
                                  className="lowerarrow"
                                  onClick={() => {
                                    sortCategory1();
                                    setSortState(3);
                                  }}
                                >
                                  <i
                                    className="fa fa-long-arrow-down active "
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              ) : (
                                <span
                                  className="lowerarrow"
                                  onClick={() => {
                                    sortCategory1();
                                    setSortState(3);
                                  }}
                                >
                                  <i
                                    className="fa fa-long-arrow-down "
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              )}
                            </th>
                            <th scope="col" className="customnameth">
                              Brand{" "}
                              {sortState === 4 ? (
                                <span
                                  className="upperarrow"
                                  onClick={() => {
                                    sortBrand();
                                    setSortState(4);
                                  }}
                                >
                                  <i
                                    className="fa fa-long-arrow-up active"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              ) : (
                                <span
                                  className="upperarrow"
                                  onClick={() => {
                                    sortBrand();
                                    setSortState(4);
                                  }}
                                >
                                  <i
                                    className="fa fa-long-arrow-up"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              )}
                              {sortState === 5 ? (
                                <span
                                  className="lowerarrow"
                                  onClick={() => {
                                    sortBrand1();
                                    setSortState(5);
                                  }}
                                >
                                  <i
                                    className="fa fa-long-arrow-down active "
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              ) : (
                                <span
                                  className="lowerarrow"
                                  onClick={() => {
                                    sortBrand1();
                                    setSortState(5);
                                  }}
                                >
                                  <i
                                    className="fa fa-long-arrow-down "
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              )}
                            </th>
                            <th scope="col" className="customnameth">
                              Supplier
                              {sortState === 6 ? (
                                <span
                                  className="upperarrow"
                                  onClick={() => {
                                    sortSupplier();
                                    setSortState(6);
                                  }}
                                >
                                  <i
                                    className="fa fa-long-arrow-up active"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              ) : (
                                <span
                                  className="upperarrow"
                                  onClick={() => {
                                    sortSupplier();
                                    setSortState(6);
                                  }}
                                >
                                  <i
                                    className="fa fa-long-arrow-up"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              )}
                              {sortState === 7 ? (
                                <span
                                  className="lowerarrow"
                                  onClick={() => {
                                    sortSupplier1();
                                    setSortState(7);
                                  }}
                                >
                                  <i
                                    className="fa fa-long-arrow-down active "
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              ) : (
                                <span
                                  className="lowerarrow"
                                  onClick={() => {
                                    sortSupplier1();
                                    setSortState(7);
                                  }}
                                >
                                  <i
                                    className="fa fa-long-arrow-down "
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              )}
                            </th>
                            <th scope="col" className="customnameth">
                              SKU/BARCODE{" "}
                              {sortState === 8 ? (
                                <span
                                  className="upperarrow"
                                  onClick={() => {
                                    sortSKUCode();
                                    setSortState(8);
                                  }}
                                >
                                  <i
                                    className="fa fa-long-arrow-up active"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              ) : (
                                <span
                                  className="upperarrow"
                                  onClick={() => {
                                    sortSKUCode();
                                    setSortState(8);
                                  }}
                                >
                                  <i
                                    className="fa fa-long-arrow-up"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              )}
                              {sortState === 9 ? (
                                <span
                                  className="lowerarrow"
                                  onClick={() => {
                                    sortSKUCode1();
                                    setSortState(9);
                                  }}
                                >
                                  <i
                                    className="fa fa-long-arrow-down active "
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              ) : (
                                <span
                                  className="lowerarrow"
                                  onClick={() => {
                                    sortSKUCode1();
                                    setSortState(9);
                                  }}
                                >
                                  <i
                                    className="fa fa-long-arrow-down "
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              )}
                            </th>
                            <th scope="col" className="customnameth">
                              Inventory{" "}
                              {sortState === 10 ? (
                                <span
                                  className="upperarrow"
                                  onClick={() => {
                                    setSortState(10);
                                  }}
                                >
                                  <i
                                    className="fa fa-long-arrow-up active"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              ) : (
                                <span
                                  className="upperarrow"
                                  onClick={() => {
                                    setSortState(10);
                                  }}
                                >
                                  <i
                                    className="fa fa-long-arrow-up"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              )}
                              {sortState === 11 ? (
                                <span
                                  className="lowerarrow"
                                  onClick={() => {
                                    setSortState(11);
                                  }}
                                >
                                  <i
                                    className="fa fa-long-arrow-down active "
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              ) : (
                                <span
                                  className="lowerarrow"
                                  onClick={() => {
                                    setSortState(11);
                                  }}
                                >
                                  <i
                                    className="fa fa-long-arrow-down "
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              )}
                            </th>
                            <th scope="col" className="customnameth">
                              Unit Cost{" "}
                              {sortState === 12 ? (
                                <span
                                  className="upperarrow"
                                  onClick={() => {
                                    sortUnitCost();
                                    setSortState(12);
                                  }}
                                >
                                  <i
                                    className="fa fa-long-arrow-up active"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              ) : (
                                <span
                                  className="upperarrow"
                                  onClick={() => {
                                    sortUnitCost();
                                    setSortState(12);
                                  }}
                                >
                                  <i
                                    className="fa fa-long-arrow-up"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              )}
                              {sortState === 13 ? (
                                <span
                                  className="lowerarrow"
                                  onClick={() => {
                                    sortUnitCost1();
                                    setSortState(13);
                                  }}
                                >
                                  <i
                                    className="fa fa-long-arrow-down active "
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              ) : (
                                <span
                                  className="lowerarrow"
                                  onClick={() => {
                                    sortUnitCost1();
                                    setSortState(13);
                                  }}
                                >
                                  <i
                                    className="fa fa-long-arrow-down "
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              )}
                            </th>
                            <th scope="col" className="customnameth">
                              Retail price{" "}
                              {sortState === 14 ? (
                                <span
                                  className="upperarrow"
                                  onClick={() => {
                                    sortPrice();
                                    setSortState(14);
                                  }}
                                >
                                  <i
                                    className="fa fa-long-arrow-up active"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              ) : (
                                <span
                                  className="upperarrow"
                                  onClick={() => {
                                    sortPrice();
                                    setSortState(14);
                                  }}
                                >
                                  <i
                                    className="fa fa-long-arrow-up"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              )}
                              {sortState === 15 ? (
                                <span
                                  className="lowerarrow"
                                  onClick={() => {
                                    sortPrice1();
                                    setSortState(15);
                                  }}
                                >
                                  <i
                                    className="fa fa-long-arrow-down active "
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              ) : (
                                <span
                                  className="lowerarrow"
                                  onClick={() => {
                                    sortPrice1();
                                    setSortState(15);
                                  }}
                                >
                                  <i
                                    className="fa fa-long-arrow-down "
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              )}
                            </th>
                            <th scope="col" className="customnameth">
                              Status{" "}
                              {sortState === 16 ? (
                                <span
                                  className="upperarrow"
                                  onClick={() => {
                                    setSortState(16);
                                  }}
                                >
                                  <i
                                    className="fa fa-long-arrow-up active"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              ) : (
                                <span
                                  className="upperarrow"
                                  onClick={() => {
                                    setSortState(16);
                                  }}
                                >
                                  <i
                                    className="fa fa-long-arrow-up"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              )}
                              {sortState === 17 ? (
                                <span
                                  className="lowerarrow"
                                  onClick={() => {
                                    setSortState(17);
                                  }}
                                >
                                  <i
                                    className="fa fa-long-arrow-down active "
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              ) : (
                                <span
                                  className="lowerarrow"
                                  onClick={() => {
                                    setSortState(17);
                                  }}
                                >
                                  <i
                                    className="fa fa-long-arrow-down "
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              )}
                            </th>
                            <th scope="col" className="customnameth">
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody className="customtheadpadding">
                          {currentData.length ? (
                            <>
                              {currentData?.map((item, index) => (
                                <>
                                  <tr
                                    key={index.id}
                                    className={
                                      index % 2 === 0 ? "" : "customeven"
                                    }
                                  >
                                    <td
                                      key={index.id}
                                      className={
                                        index % 2 === 0 ? "" : "bgcolor"
                                      }
                                    >
                                      <input
                                        key={index.id}
                                        className="form-check-input customcheckboxbtnsetting"
                                        type="checkbox"
                                        value=""
                                        id={`flexCheckDefault${index}`}
                                        onChange={() =>
                                          handleSelectRow(item?.id)
                                        }
                                        checked={selectedItems.includes(
                                          item?.id
                                        )}
                                      />
                                    </td>
                                    <td
                                      key={index.id}
                                      className={
                                        index % 2 === 0
                                          ? ""
                                          : " prod_img bgcolor"
                                      }
                                    >
                                      {item?.image ? (
                                        <img
                                          key={index.id}
                                          className="imglistcstm"
                                          src={item?.image}
                                          alt=""
                                        />
                                      ) : (
                                        <img
                                          src="assets/img/defaultimg.webp"
                                          className="imglistcstm"
                                          style={{ marginRight: "10px" }}
                                          alt=""
                                        />
                                      )}
                                      <span>{item?.title}</span>
                                    </td>
                                    <td
                                      key={index.id}
                                      className={
                                        index % 2 === 0 ? "" : "bgcolor"
                                      }
                                    >
                                      {item?.itemCategory?.name}
                                    </td>
                                    <td
                                      key={index.id}
                                      className={
                                        index % 2 === 0 ? "" : "bgcolor"
                                      }
                                    >
                                      {item?.brand?.name}
                                    </td>
                                    <td
                                      key={index.id}
                                      className={
                                        index % 2 === 0 ? "" : "bgcolor"
                                      }
                                    >
                                      {item?.supplier?.name}
                                    </td>
                                    <td
                                      key={index.id}
                                      className={
                                        index % 2 === 0 ? "" : "bgcolor"
                                      }
                                    >
                                      {item?.skuCode}
                                    </td>
                                    <td
                                      key={index.id}
                                      className={
                                        index % 2 === 0 ? "" : "bgcolor"
                                      }
                                    >
                                      {item?.inventory}
                                    </td>
                                    <td
                                      key={index.id}
                                      className={
                                        index % 2 === 0 ? "" : "bgcolor"
                                      }
                                    >
                                      ${item?.unitCost?.toFixed(2)}
                                    </td>
                                    <td
                                      key={index.id}
                                      className={
                                        index % 2 === 0 ? "" : "bgcolor"
                                      }
                                    >
                                      ${item?.sellingPrice.toFixed(2)}
                                    </td>

                                    <td
                                      key={index.id}
                                      className={
                                        index % 2 === 0
                                          ? ""
                                          : "status_td st_inactive bgcolor"
                                      }
                                      //className="status_td st_inactive"
                                    >
                                      {item?.draft === 1 ? (
                                        <span
                                          className="spanactiveinactivecstm2"
                                          key={index.id}
                                        >
                                          Draft
                                        </span>
                                      ) : item?.activeInactive === 1 ? (
                                        <span
                                          className="spanactiveinactivecstm1"
                                          key={index.id}
                                        >
                                          Active
                                        </span>
                                      ) : (
                                        <span
                                          className="spanactiveinactivecstm"
                                          key={index.id}
                                        >
                                          Inactive
                                        </span>
                                      )}
                                    </td>
                                    <td
                                      key={index.id}
                                      className={
                                        index % 2 === 0 ? "" : "bgcolor"
                                      }
                                    >
                                      {item?.productCategoryId === 2 ? (
                                        <Link
                                          key={index.id}
                                          to="/editdevice"
                                          state={{ data: item }}
                                          className="edit_action_btn"
                                        >
                                          <img
                                            src="assets/img/action-edit-icon.webp"
                                            alt=""
                                          />
                                        </Link>
                                      ) : (
                                        <Link
                                          key={index.id}
                                          to="/editproduct"
                                          state={{ data: item }}
                                          className="edit_action_btn"
                                        >
                                          <img
                                            src="assets/img/action-edit-icon.webp"
                                            alt=""
                                          />
                                        </Link>
                                      )}
                                      {/* <Link
                                        key={index.id}
                                        to="/editproduct"
                                        state={{ data: item }}
                                        className="edit_action_btn"
                                      >
                                        <img
                                          src="assets/img/action-edit-icon.webp"
                                          alt=""
                                        />
                                      </Link> */}

                                      <i
                                        className="fa-solid fa-trash deletecustmtb"
                                        onClick={() => HandleDelete(item?.id)}
                                      ></i>
                                    </td>
                                  </tr>
                                </>
                              ))}
                            </>
                          ) : (
                            <>
                              <tr id="noDataMessage">
                                <td colSpan="10" className="nodataav">
                                  No data available
                                </td>
                              </tr>
                            </>
                          )}
                        </tbody>
                      </table>
                      {allProduct.length > 0 ? (
                        <div className="row">
                          <div className="col-md-12">
                            <div className="custombottompaginationbox">
                              <nav aria-label="Page navigation example">
                                <ul className="pagination">
                                  <li
                                    className="page-item custompaginationli  paginationleftbtnbox"
                                    style={{ cursor: "pointer" }}
                                  >
                                    <button
                                      type="button"
                                      className="page-link"
                                      onClick={() =>
                                        handlePageClick(currentPage - 1)
                                      }
                                      disabled={currentPage === 1}
                                    >
                                      <img src="assets/img/previous.webp" />
                                    </button>
                                  </li>
                                  {pages.map((page, key) => {
                                    if (
                                      page === 1 ||
                                      page === totalPages ||
                                      (page >= startPage && page <= endPage)
                                    ) {
                                      return (
                                        <li
                                          className="page-item custompaginationli"
                                          key={key}
                                        >
                                          <a
                                            className={
                                              currentPage === page
                                                ? "page-link active customcountpagination"
                                                : "page-link customcountpagination"
                                            }
                                            onClick={() =>
                                              handlePageClick(page)
                                            }
                                            disabled={currentPage === page}
                                            style={{ cursor: "pointer" }}
                                          >
                                            {page}
                                          </a>
                                        </li>
                                      );
                                    } else if (
                                      page === startPage - 1 ||
                                      page === endPage + 1
                                    ) {
                                      return (
                                        <li
                                          className="page-item custompaginationli"
                                          key={key}
                                        >
                                          <a
                                            className="page-link customcountpagination"
                                            style={{ cursor: "pointer" }}
                                          >
                                            ...
                                          </a>
                                        </li>
                                      );
                                    } else {
                                      return null;
                                    }
                                  })}
                                  {/* {Array.from(
                                        { length: totalPages },
                                        (_, index) => index + 1
                                      ).map((page, key) => (
                                        <li
                                          className="page-item custompaginationli"
                                          key={key}
                                        >
                                          <a
                                            key={key}
                                            className={
                                              currentPage === page
                                                ? "page-link active customcountpagination"
                                                : "page-link customcountpagination"
                                            }
                                            onClick={() =>
                                              handlePageClick(page)
                                            }
                                            disabled={currentPage === page}
                                            style={{ cursor: "pointer" }}
                                          >
                                            {page}
                                          </a>
                                        </li>
                                      ))} */}

                                  <li
                                    className="page-item custompaginationli paginationrightbtnbox"
                                    style={{ cursor: "pointer" }}
                                  >
                                    <button
                                      type="button"
                                      className="page-link"
                                      href="#"
                                      onClick={() =>
                                        handlePageClick(currentPage + 1)
                                      }
                                      disabled={currentPage === totalPages}
                                    >
                                      <img src="assets/img/next.webp" />
                                    </button>
                                  </li>
                                </ul>
                              </nav>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {/* {allProduct.length > 0 ? (
                        <div className="row">
                          <div className="col-md-12">
                            <div className="custombottompaginationbox">
                              <nav aria-label="Page navigation example">
                                <ul className="pagination">
                                  <li
                                    className="page-item custompaginationli  paginationleftbtnbox"
                                    style={{ cursor: "pointer" }}
                                  >
                                    <button
                                      type="button"
                                      className="page-link"
                                      onClick={() =>
                                        handlePageClick(currentPage - 1)
                                      }
                                      disabled={currentPage === 1}
                                    >
                                      <img src="assets/img/previous.webp" />
                                    </button>
                                  </li>

                                  {Array.from(
                                    { length: totalPages },
                                    (_, index) => index + 1
                                  ).map((page, key) => (
                                    <li
                                      className="page-item custompaginationli"
                                      key={key}
                                    >
                                      <a
                                        key={key}
                                        className={
                                          currentPage === page
                                            ? "page-link active customcountpagination"
                                            : "page-link customcountpagination"
                                        }
                                        onClick={() => handlePageClick(page)}
                                        disabled={currentPage === page}
                                        style={{ cursor: "pointer" }}
                                      >
                                        {page}
                                      </a>
                                    </li>
                                  ))}

                                  <li
                                    className="page-item custompaginationli paginationrightbtnbox"
                                    style={{ cursor: "pointer" }}
                                  >
                                    <button
                                      type="button"
                                      className="page-link"
                                      href="#"
                                      onClick={() =>
                                        handlePageClick(currentPage + 1)
                                      }
                                      disabled={currentPage === totalPages}
                                    >
                                      <img src="assets/img/next.webp" />
                                    </button>
                                  </li>
                                </ul>
                              </nav>
                            </div>
                          </div>
                        </div>
                      ) : null} */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* <MainSidebar /> */}

          {/* Main Content */}

          <Footer />
        </div>

        {showModal === true ? (
          <div
            className="modal common_modal createproduct_modal"
            id="createproductModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="createproductModalLabel"
            aria-hidden="true"
          >
            <ProductModalList onpress={onpress} onload={GetAllProducts} />
          </div>
        ) : null}
        {showModal3 === true ? (
          <>
            <div
              className="modal common_modal newproduct_modal"
              id="newproductModal"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="newproductModalLabel"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-dialog-centered"
                role="document"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="newproductModalLabel">
                      New Product
                    </h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={() => {
                        {
                          setShowModal3(false);

                          document
                            .querySelectorAll(".modal-backdrop")
                            .forEach((el) =>
                              el.classList.remove("modal-backdrop")
                            );
                        }
                      }}
                    >
                      <span className="close_icon"></span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <div className="product_list">
                      <div
                        className="product_col"
                        onClick={() => {
                          setShowModal4(true);
                          setShowModal3(false);
                        }}
                      >
                        <a
                          href="#"
                          data-toggle="modal"
                          data-target="#createproductModal"
                        >
                          <h5>Standard</h5>
                          <p>
                            Most Physical product and services including parts,
                            except devices with Imei/ESN
                          </p>
                        </a>
                      </div>
                      <div
                        className="product_col"
                        onClick={() => {
                          setShowModal5(true);
                          setShowModal3(false);
                        }}
                      >
                        <a
                          href="#"
                          data-toggle="modal"
                          data-target="#createdeviceModal"
                        >
                          <h5>Devices</h5>
                          <p>Products with serial, IMEI, ESN, MEID</p>
                        </a>
                      </div>
                      <div className="product_col coming_product">
                        <a href="#">
                          <img src="assets/img/comingsoon-img.webp" alt="" />
                          <h5>Coming Soon...</h5>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : null}

        {showModal4 === true ? (
          <>
            <div
              className="modal common_modal createproduct_modal"
              id="createproductModal"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="createproductModalLabel"
              aria-hidden="true"
            >
              <ProductDeviceModal onpress={onpress1} onload={GetAllProducts} />
            </div>
          </>
        ) : null}

        {showModal5 === true ? (
          <>
            <div
              className="modal common_modal createdevice_modal"
              id="createdeviceModal"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="createdeviceModalLabel"
              aria-hidden="true"
            >
              <DeviceProductModal onpress={onpress2} onload={GetAllProducts} />
            </div>
          </>
        ) : null}
      </div>
    </>
  );
};
export default ProductList;
